<template>
  <div :class="`insight-tile-outer-container elevation-1 ${highlightMe}`">
    <v-row>
      <v-col cols="10" class="pt-0 insight-title" :title="insight.title + ' (' + insight.id + ')'">
        {{insight.title}}
      </v-col>
      <v-col cols="2" class="pt-0 pb-1 d-flex justify-end">
        <v-btn icon x-small color="primary"><v-icon @click="toggleExpansion">mdi-arrow-expand</v-icon></v-btn>
        <v-tooltip max-width="30rem"  bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isProcessing" color="primary" icon x-small v-bind="attrs" v-on="on">
              <v-icon
                class="processing"
                v-text="'mdi-autorenew'"
                small
              ></v-icon>
            </v-btn>
            <v-btn v-else color="primary" icon x-small v-bind="attrs" v-on="on"><v-icon>mdi-information-outline</v-icon></v-btn>
          </template>
          <span>
            <span v-if="isProcessing">{{insightProcessingMessage}}</span><br v-if="isProcessing"/>
            <span v-if="checkMinTalktime">{{ formatHMS(insight.info.minimumTalkTime) }} minimum talk time</span><span v-else>No minimum talk time</span><br/>
            <span v-if="checkMaxTalktime">{{ formatHMS(insight.info.maximumTalkTime) }} maximum talk time</span><span v-else>No maximum talk time</span><br/>
            Last published: {{ insight.info.updated_at }}<br/>
            Insight ID: {{insight.id}}<br>
            Created by: {{insight.owner}}
            <span v-if="properDescription">
              <br>
              Description: {{insight.description}}
            </span>
          </span>
        </v-tooltip>
        <v-btn :color="pinColor" icon x-small><v-icon @click="pinFavorite">mdi-pin</v-icon></v-btn>
      </v-col>
    </v-row>
    <div v-if="usingSnapshot" class="insight-tile-inner-container">
      <div class="insight-data-tile">
        <v-icon dark :class="vIconClass">mdi-finance</v-icon>
        <span v-if="isLoading" :class="tileValueClass">
          <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
        </span>
        <span v-else :class="tileValueClass">{{thousands(insight.info.totalCalls)}}</span>
        <span :class="tileTextClass">Total Calls</span>
      </div>

      <div class="insight-data-tile">
        <v-icon dark :class="vIconClass">mdi-calendar-multiselect</v-icon>
        <span v-if="isLoading" :class="tileValueClass">
          <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
        </span>
        <span v-else :class="tileValueClass">{{thousands(rounded(insight.info.averageCallsOverTime,0))}}</span>
        <span :class="tileTextClass">Calls/{{capitalize(grouping)}}</span>
      </div>

      <div class="insight-data-tile">
        <v-icon dark :class="vIconClass">mdi-clock-outline</v-icon>
        <span v-if="isLoading" :class="tileValueClass">
          <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
        </span>
        <span v-else :class="tileValueClass">{{thousands(rounded(insight.info.totalHours,0))}}</span>
        <span :class="tileTextClass">Hours</span>
      </div>

      <div class="insight-data-tile">
        <v-icon dark :class="vIconClass">mdi-headset</v-icon>
        <span v-if="isLoading" :class="tileValueClass">
          <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
        </span>
        <span v-else :class="tileValueClass">{{formatHMS(rounded(insight.info.averageDuration,0))}}</span>
        <span :class="tileTextClass">Talk Time</span>
      </div>

    </div>
    <div v-else class="insight-tile-inner-container">
      <div class="insight-data-tile"><v-icon dark :class="vIconClass">mdi-finance</v-icon><span :class="tileValueClass">{{thousands(insight.info.totalCalls)}}</span><span :class="tileTextClass">Total Calls</span></div>
      <div class="insight-data-tile"><v-icon dark :class="vIconClass">mdi-plus-circle</v-icon><span :class="tileValueClass">{{sentimentPositiveCalc}}%</span><span :class="tileTextClass">Positive</span></div>
      <div class="insight-data-tile"><v-icon dark :class="vIconClass">mdi-record-circle</v-icon><span :class="tileValueClass">{{sentimentNeutralCalc}}%</span><span :class="tileTextClass">Neutral</span></div>
      <div class="insight-data-tile"><v-icon dark :class="vIconClass">mdi-minus-circle</v-icon><span :class="tileValueClass">{{sentimentNegativeCalc}}%</span><span :class="tileTextClass">Negative</span></div>
    </div>
    <div class="actions">
        <v-btn v-if="insight.info.dynalabel_count>1" text small color="primary" :to="{ name: 'insights-details', params: { id: insight.id }, query: $route.query }">Select Insight</v-btn>
          <v-btn v-else text small color="primary" :to="{ name: 'insights-dynalabels-agents', params: { id: insight.id, dynalabelId: 0 }, query: $route.query }">Select Insight</v-btn>
    </div>

  </div>
</template>

<script>

import {mapGetters,mapActions} from "vuex";
import moment from "moment";
import CacheHandler from "@/utils/CacheHandler";

export default {
  props: {
    processing: Number,
    insight: Object,
    favorite: Number,
    grid: Boolean,
    expanded: Number,
    grouping: String,
    loading: Boolean,
  },
  components: {
    // do stuff
  },
  data() {
    return {
      showTooltip: false,
      err: {},
      usingSnapshot: false,
      insightProcessingMessage: process.env.VUE_APP_INSIGHT_PROCESSING_MESSAGE
    }
  },
  computed: {
    ...mapGetters('insights', [
      'lastChartSelected',
      'metricSelected',
    ]),
    ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),
    isProcessing() {
      return this.processing > 0;
    },
    isLoading() {
      return this.loading;
    },
    sChCC() {
      return this.insight.info.single_channel_call_count
    },
    dChCC() {
      return this.insight.info.dual_channel_call_count
    },
    singleChannel() {
      return this.sChCC>0
    },
    mixedChannel() {
      return this.sChCC>0&&this.dChCC>0
    },
    properDescription() {
      return (this.insight.description && this.insight.description.length>0 && this.insight.description.toLowerCase() != 'description')
    },
    checkMaxTalktime() {
      return (this.insight.info.maximumTalkTime!=null)
    },
    checkMinTalktime() {
      return (this.insight.info.minimumTalkTime!=null)
    },
    chartSelected() {
      if (this.mixedChannel || (!this.singleChannel && this.dChCC > 0)) {
        if (this.lastChartSelected == 0) {
          return "Customer";
        } else {
          return "Agent";
        }
      } else {
        return "Overall";
      }
    },
    pinColor() {
      return (this.favorite!=this.insight.id) ? 'accent' : 'warning'
    },
    tileTextClass() {
      return 'tile-text tile-text-'+this.$vuetify.breakpoint.name
    },
    tileValueClass() {
      return 'tile-value tile-value-'+this.$vuetify.breakpoint.name
    },
    vIconClass() {
      return 'v-icon-'+this.$vuetify.breakpoint.name
    },
    highlightMe() {
      return (this.expanded==this.insight.id) ? 'highlight-bar' : ''
    },
     sentimentPositiveCalc() {
      let sentiment = this.chartSelected
      let insightMetrics = this.insight.info.sentiment_metrics
      if (sentiment=='Overall') {
        return Number(Math.round(insightMetrics.sentiment_positive_overall*100))
      } else if (sentiment=='Customer') {
        return Number(Math.round(insightMetrics.sentiment_positive_customer*100))
      } else {
        return Number(Math.round(insightMetrics.sentiment_positive_agent*100))
      }
    },
    sentimentNeutralCalc() {
      let sentiment = this.chartSelected
      let insightMetrics = this.insight.info.sentiment_metrics
      if (sentiment=='Overall') {
        return Number(Math.round(insightMetrics.sentiment_neutral_overall*100))
      } else if (sentiment=='Customer') {
        return Number(Math.round(insightMetrics.sentiment_neutral_customer*100))
      } else {
        return Number(Math.round(insightMetrics.sentiment_neutral_agent*100))
      }
    },
    sentimentNegativeCalc() {
      let sentiment = this.chartSelected
      let insightMetrics = this.insight.info.sentiment_metrics
      if (sentiment=='Overall') {
        return Number(Math.round(insightMetrics.sentiment_negative_overall*100))
      } else if (sentiment=='Customer') {
        return Number(Math.round(insightMetrics.sentiment_negative_customer*100))
      } else {
        return Number(Math.round(insightMetrics.sentiment_negative_agent*100))
      }
    },
  },
  methods: {
    ...mapActions('insights', [
      'setfavoriteInsightDefinitionId',
    ]),
    metricCheck() {
      let metric = (CacheHandler.getItem('metric_selected')||'Snapshot')
      if (metric=='Snapshot') {
        this.usingSnapshot = true
      } else {
        this.usingSnapshot=false
      }
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    toggleExpansion() {
      CacheHandler.setItem("last_viewed_insight_definition",this.insight.id)
      this.$emit('toggle-expansion', {'id': this.insight.id})
    },
    pinFavorite() {
      if(this.insight.id==this.favorite) {
        this.setfavoriteInsightDefinitionId(0)
      } else {
        this.setfavoriteInsightDefinitionId(this.insight.id)
      }
    },
    thousands(num) {
      return Number(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    rounded(num,places) {
      return Number(num).toFixed(places)
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
  },
  updated() {
  },
  mounted() {
    this.metricCheck()
  },
  watch: {
    // do stuff
    metricSelected: "metricCheck"
  },
};
</script>

<style>

  div.insight-tile-outer-container {
    padding: .5rem 1rem .25rem 1rem;
    margin-right: 1rem;
  }

  div.insight-tile-outer-container div.actions {
    padding: .25rem;
    text-align: right;
  }

  div.insight-tile-outer-container div.insight-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 99%;
    color: rgba(1, 106, 175, 1);
    font-weight: bold;
  }

  div.insight-tile-inner-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  div.insight-data-tile {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;
  }

  div.insight-data-tile i.v-icon {
    opacity: .20;
    position: absolute;
    bottom: 0;
  }

  div.insight-data-tile span.tile-value {
    font-weight: bold;
    text-align: right;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(1, 106, 175, 1);
    border-radius: .25rem .25rem 0 0;
  }

  div.insight-data-tile span.tile-text {
    text-align: right;
    color: rgba(1, 106, 175, 1);
    background-color: rgba(1, 106, 175, .25);
    border-radius: 0 0 .25rem .25rem;
  }

  div.insight-data-tile i.v-icon-xs {
    font-size: 4.5rem;
    left: -.75rem;
  }
  div.insight-data-tile i.v-icon-sm {
    font-size: 4.5rem;
    left: -.75rem;
  }
  div.insight-data-tile i.v-icon-md {
    font-size: 4.5rem;
    left: -.75rem;
  }
  div.insight-data-tile i.v-icon-lg {
    font-size: 4.5rem;
    left: -.75rem;
  }
  div.insight-data-tile i.v-icon-xl {
    font-size: 7.5rem;
    left: -1.5rem;
  }

  div.insight-data-tile span.tile-text-xs {
    padding: .5rem;
    font-size: .75rem;
  }
  div.insight-data-tile span.tile-text-sm {
    padding: .5rem;
    font-size: .75rem;
  }
  div.insight-data-tile span.tile-text-md {
    padding: .5rem;
    font-size: .75rem;
  }
  div.insight-data-tile span.tile-text-lg {
    padding: .5rem;
    font-size: .7rem;
  }
  div.insight-data-tile span.tile-text-xl {
    padding: 1rem;
    font-size: .9rem;
  }

  div.insight-data-tile span.tile-value-xs {
    padding: .5rem;
    font-size: 1.2rem;
  }
  div.insight-data-tile span.tile-value-sm {
    padding: .5rem;
    font-size: 1.2rem;
  }
  div.insight-data-tile span.tile-value-md {
    padding: .5rem;
    font-size: 1.2rem;
  }
  div.insight-data-tile span.tile-value-lg {
    padding: .5rem;
    font-size: 1.2rem;
  }
  div.insight-data-tile span.tile-value-xl {
    padding: 1rem;
    font-size: 1.8rem;
  }

  div.highlight-bar {
    border-left: 4px solid rgb(34, 124, 213);
  }

  i.processing {
  animation: rotation 6s infinite linear;
  }


</style>
