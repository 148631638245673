<template>
    <v-content v-if="loadTranscript && loginReqPB == 'dont_log_in'">
      <v-container pl-8 pr-8 fluid fill-height>
          <template>
            <TranscriptNoModal
              v-if="userDataLoadingStatus=='loaded' || this.loginReqPB == 'dont_log_in'"
              :viewTranscript=true
              :callId="selectedCallId"
              :key="selectedCallId"
              :selfSignedTranscript="true"
              :context="context"
              :contextId="parseInt(contextId)"
              :contextDynalabelId="parseInt(contextDynalabelId)"
              :contextAgentId="parseInt(contextAgentId)"
              @HIDE_MODAL="hideModal"
            ></TranscriptNoModal>
          </template>
        </v-container>
    </v-content>
</template>

<style></style>

<script>
import axios from "@/utils/AxiosInstance.js";
import TranscriptNoModal from "@/components/Transcripts/NoModal";
import { mapActions, mapGetters} from "vuex";
import DemoHandler from '../utils/DemoHandler';

export default {
  components: {
    TranscriptNoModal,
  },
  data() {
    return {
      showModal: false,
      selectedCallId: this.$route.params.id,
      callId: this.$route.params.id,
      transcriptData: {},
      eecNameXref: {
        name: ""
      },
      callIn: {
        adjusteddatetime: "",
        orignmbr: "",
        acctnmbr: ""
      },
      custProf: {
        name: "",
        insights: "",
      },
      callOut: "",
      searchResultsExist: false,
      loading: true,
      playHeadPosition: 0,
      playFromPosition: 0,
    };
  },
  created() {
  },
  computed: {
    ...mapGetters("authentication", ["loginReqPB"]),
    ...mapGetters('users', ['userDataLoadingStatus']),
    callIdPresent() {
      return this.$route.params.id > 0;
    },
    isLegacy() {
      return this.$route.name === "transcripts-lg";
    },
    // header
    agentName() {
      let name = "Agent";
      if (this.eecNameXref) {
        name = this.eecNameXref.name;
      }
      return name;
    },
    callerNumber() {
      return this.callIn.orignmbr || "";
    },
    calledNumber() {
      return this.callOut.pots || "";
    },
    callDate() {
      return this.callIn.adjusteddatetime || "";
    },
    callDirection() {
      return this.callIn.call_direction || "";
    },
    accountName() {
      return this.custProf.name || "";
    },
    accountNumber() {
      return Number(this.callIn.acctnmbr) || 0;
    },
    insights() {
      return Boolean(this.custProf.insights == 1) || false;
    },
    sentiment() {
      return this.transcriptData.analyses[0].sentiment || "";
    },
    loadTranscript() {
        return ((!localStorage.getItem("bearer") || localStorage.getItem("bearer") == ""))
    },
    bearerSet() {
        return localStorage.getItem("bearer") && localStorage.getItem("bearer") != ""
    },
    context() {
      return this.$route.query.context || "";
    },
    contextId() {
      return this.$route.query.context_id || 0;
    },
    contextDynalabelId() {
      return this.$route.query.context_dynalabel_id || 0;
    },
    contextAgentId() {
      return this.$route.query.context_agent_id || 0;
    },
  },
  methods: {
    ...mapActions({
        checkLoginReqPB: "authentication/checkLoginReqPB",
        setIsAuthenticated: "authentication/setIsAuthenticated"
    }),
    hideModal(hideModal) {
      this.showModal = hideModal
    },
    getTranscript() {
      if (this.callId) {
        this.loading = true;
        let self = this;
        let call_records_url = process.env.VUE_APP_CALL_RECORDS + this.callId;

        let transcript_url = process.env.VUE_APP_TRANSCRIPTS + this.callId;
        axios
          .get(call_records_url, {
            params: {
              fields:
                "eecnamexref.name,callin.channel_count,callin.orignmbr,callin.adjusteddatetime,callin.adjusteddatetime,callin.call_direction,callin.acctnmbr,custprof.name,custprof.insights,callout.pots"
            }
          })
          .then(response => {
            if (response && response.data) {
              self.callIn = response.data.callin;
              self.eecNameXref = response.data.eecnamexref;
              self.custProf = response.data.custprof;

              axios
                .get(transcript_url)
                .then(response => {
                  if (response && response.data) {
                    self.transcriptData = response.data;
                    self.loading = false;
                  }
              });
            }
          });
      }
    },
    setResultsExistance(value) {
      this.searchResultsExist = value;
    },
    transcriptExists() {
      return (
        this.transcriptData.analyses && this.transcriptData.analyses.length > 0
      );
    },
    updatePlayHeadPosition(e) {
      this.playHeadPosition = e;
    },
    updatePlayFromPosition(e) {
      this.playFromPosition = e;
    },
    toSignin() {
        // send them to signin if loginreqpb is true unless theyre already logged in
        let routeQuery = this.$route.query
        routeQuery.prev_path = '/transcripts/' + this.$route.params.id
        if(this.loginReqPB == 'log_in' && !(this.bearerSet)) {
            this.setIsAuthenticated('not_loaded')
            this.$router.push({path:'/signin',query:routeQuery}).catch(err => {})
        }
    }
  },
  mounted() {
    if(this.isLegacy) {
      this.getTranscript()
    }
    // if theres a bearer use that
    if(this.bearerSet) {
        this.$router.push({path:'/transcripts/' + this.$route.params.id,query:this.$route.query}).catch(err => {})
    } else {
        this.checkLoginReqPB({userid: this.$route.params.userid, reckey: this.$route.params.reckey, call_id: this.$route.params.id});
    }
  },
  watch: {
    loginReqPB: 'toSignin'
  }

};
</script>

<style>

</style>
