<template>
  <v-container pl-8 pr-8 fluid fill-height style="height: 70vh;">
    <v-row>
      <v-col class="text-center justify-center">
        <div class="big-type indigo--text text--darken-4">Welcome To</div>
        <div class="d-flex flex-column justify-space-between align-center">
          <v-img src="@/assets/img/CallFinder_Spot_Color2.png" width="80vw" max-width="1200px"></v-img>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center justify-center">
        <v-alert v-if="message" :type="alertType" prominent>
          <v-row class="d-flex">
            <v-col class="d-flex" v-html="message"></v-col>
            <v-col class="d-flex justify-end"><v-progress-circular size="24" indeterminate v-if="type=='loading'"></v-progress-circular></v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  components: {
  },
  props: {
    message: String,
    type: String,
  },
  data: () => ({
  }),
  methods: {
  },
  computed: {
    alertType() {
      switch(this.type) {
        case 'error':
          return 'error'
        case 'nodata':
          return 'warning'
        case 'loading':
        default:
          return 'info'
      }
    }
  },
}
</script>

<style>

  .big-type { font-size: 5rem !important; }

</style>
