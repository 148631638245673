<template>
  <v-container pl-8 pr-8 fluid>
    <v-row no-gutters>
      <v-col class="text-center justify-center" >
        <v-alert v-if="message" :type="alertType" prominent >
          <v-row class="d-flex">
            <v-col class="d-flex" v-html="message"></v-col>
            <v-col v-if="type == 'loading'" class="d-flex justify-end"
              ><v-progress-circular
                size="24"
                indeterminate
              ></v-progress-circular
            ></v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    message: String,
    type: String
  },
  data: () => ({}),
  methods: {},
  computed: {
    alertType() {
      switch (this.type) {
        case "error":
          return "error";
        case "nodata":
          return "warning";
        case "loading":
        default:
          return "info";
      }
    }
  }
};
</script>

<style>
.big-type {
  font-size: 5rem !important;
}
</style>
