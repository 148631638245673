var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('v-container',[(_vm.scorecardIsLoading)?_c('scorecard-loading-status',{attrs:{"message":_vm.scorecardMessageType,"type":_vm.scorecardLoadingType}}):_c('div',[_c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('scorecard-breadcrumbs',{attrs:{"scorecardHeader":_vm.scorecardV2TeamData.scorecardTitle,"dynaLabelHeader":_vm.scorecardV2TeamData.dynalabelTitle,"singleDynaCount":_vm.scorecardV2TeamData.dynalabelCount < 2,"agentHeader":_vm.scorecardV2TeamData.agentName,"callId":_vm.$route.params.callId,"level":'printable-team',"linkEnabled":true},on:{"go-to-page":_vm.selectPage}})],1)],1),_c('v-row',{staticClass:"header"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-flex',{attrs:{"fill-height":""}},[_c('h1',[_vm._v(_vm._s(_vm.getScorecardName))]),_c('h2',[_vm._v(_vm._s(_vm.getScorecardSubtitle))]),_c('h3',[_vm._v("Overview")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-flex',{staticClass:"text-center card-container"},[_c('p',{staticClass:"caption"},[_vm._v("Calls/"+_vm._s(_vm.capitalize(_vm.getUnit)))]),_c('h1',{staticClass:"numbers pt-4"},[_vm._v("\n                "+_vm._s(_vm.getTeamACV)+" "),_c('span',{staticClass:"suffix"})])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-flex',{staticClass:"text-center card-container"},[_c('p',{staticClass:"caption"},[_vm._v(_vm._s(_vm.timeDisplayWords))]),_c('h1',{staticClass:"numbers pt-4"},[_vm._v("\n                "+_vm._s(_vm.formatHMS(
                    this.scorecardV2TeamData.items[0].metrics.averageDuration
                  ))+"\n              ")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-flex',{staticClass:"text-center card-container"},[_c('p',{staticClass:"caption"},[_vm._v("Score")]),_c('h1',{staticClass:"numbers pt-4"},[_vm._v(_vm._s(_vm.getTeamACSLastScore)+"%")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":6}},[_c('h3',[_vm._v("Top 5 Scoring Agents")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-right"},[_vm._v("Total Calls")]),_c('th',{staticClass:"text-right"},[_vm._v("Score")]),_c('th',{staticClass:"text-left"},[_vm._v("Score Distribution")])])]),_c('tbody',_vm._l((_vm.getTopAgents),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"agent-name"},[_vm._v(_vm._s(item.agentName))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.metrics.totalCalls))]),_c('td',{class:'text-right score-block-' +
                          _vm.calcScoreBlockNumber(
                            Math.round(item.metrics.callScoreAvg * 100)
                          )},[_vm._v("\n                      "+_vm._s(Math.round(item.metrics.callScoreAvg * 100))+"%\n                    ")]),_c('td',{staticClass:"histogram-small"},_vm._l((_vm.formatDistribution(
                          _vm.scorecardPrintableDistributionData[item.id]
                        )),function(obs,index){return _c('div',{key:index,class:'pill-small pill-block-' + index,style:('height: ' +
                            _vm.calcObservationHeight(
                              _vm.formatDistribution(
                                _vm.scorecardPrintableDistributionData[item.id]
                              ),
                              obs
                            ) +
                            'px')})}),0)])}),0)]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":6}},[_c('h3',[_vm._v("Bottom 5 Scoring Agents")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-right"},[_vm._v("Total Calls")]),_c('th',{staticClass:"text-right"},[_vm._v("Score")]),_c('th',{staticClass:"text-left"},[_vm._v("Score Distribution")])])]),_c('tbody',_vm._l((_vm.getBottomAgents),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"agent-name"},[_vm._v(_vm._s(item.agentName))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.metrics.totalCalls))]),_c('td',{class:'text-right score-block-' +
                          _vm.calcScoreBlockNumber(
                            Math.round(item.metrics.callScoreAvg * 100)
                          )},[_vm._v("\n                      "+_vm._s(Math.round(item.metrics.callScoreAvg * 100))+"%\n                    ")]),_c('td',{staticClass:"histogram-small"},_vm._l((_vm.formatDistribution(
                          _vm.scorecardPrintableDistributionData[item.id]
                        )),function(obs,index){return _c('div',{key:index,class:'pill-small pill-block-' + index,style:('height: ' +
                            _vm.calcObservationHeight(
                              _vm.formatDistribution(
                                _vm.scorecardPrintableDistributionData[item.id]
                              ),
                              obs
                            ) +
                            'px')})}),0)])}),0)]},proxy:true}])})],1)],1)],1),_c('section',{attrs:{"flat":""}},[[_c('scorecard-view',{attrs:{"agentId":0,"format":'printable-team'}})]],2),_c('section',[_c('v-row',{staticClass:"header"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-flex',{attrs:{"fill-height":""}},[_c('h1',[_vm._v(_vm._s(_vm.getScorecardName))]),_c('h2',[_vm._v(_vm._s(_vm.getScorecardSubtitle))]),_c('h3',[_vm._v("Agent Statistics")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-flex',{staticClass:"text-center card-container"},[_c('p',{staticClass:"caption"},[_vm._v("Calls/"+_vm._s(_vm.capitalize(_vm.getUnit)))]),_c('h1',{staticClass:"numbers pt-4"},[_vm._v("\n                "+_vm._s(_vm.getTeamACV)+" "),_c('span',{staticClass:"suffix"})])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-flex',{staticClass:"text-center card-container"},[_c('p',{staticClass:"caption"},[_vm._v(_vm._s(_vm.timeDisplayWords))]),_c('h1',{staticClass:"numbers pt-4"},[_vm._v("\n                "+_vm._s(_vm.formatHMS(
                    this.scorecardV2TeamData.items[0].metrics.averageDuration
                  ))+"\n              ")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-flex',{staticClass:"text-center card-container"},[_c('p',{staticClass:"caption"},[_vm._v("Score")]),_c('h1',{staticClass:"numbers pt-4"},[_vm._v(_vm._s(_vm.getTeamACSLastScore)+"%")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-right"},[_vm._v("Total Calls")]),_c('th',{staticClass:"text-right"},[_vm._v("\n                      Calls/"+_vm._s(_vm.capitalize(_vm.getUnit))+"\n                    ")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.timeDisplayWords))]),_c('th',{staticClass:"text-right"},[_vm._v("Score")]),_c('th',{staticClass:"text-left"},[_vm._v("Score Distribution")])])]),_c('tbody',_vm._l((_vm.allAgentsSorted),function(item){return _c('tr',{key:item.id},[(item.metrics.call_count != 0)?[_c('td',{staticClass:"agent-name"},[_vm._v(_vm._s(item.agentName))]),_c('td',{staticClass:"att"},[_vm._v(_vm._s(item.metrics.totalCalls))]),_c('td',{staticClass:"acv"},[_vm._v("\n                        "+_vm._s(Math.round(item.metrics.averageCallVolume))+"\n                      ")]),_c('td',{staticClass:"att"},[_vm._v("\n                        "+_vm._s(_vm.getATThms(item.metrics.averageDuration))+"\n                      ")]),_c('td',{class:'acs score-block-' +
                            _vm.calcScoreBlockNumber(item.metrics.callScoreAvg)},[_vm._v("\n                        "+_vm._s(_vm.formatScore(item.metrics.callScoreAvg))+"\n                      ")]),_c('td',{staticClass:"histogram-large"},_vm._l((_vm.formatDistribution(
                            _vm.scorecardPrintableDistributionData[item.id]
                          )),function(obs,index){return _c('div',{key:index,class:'pill-large pill-block-' + index,style:('height: ' +
                              _vm.calcObservationHeight(
                                _vm.formatDistribution(
                                  _vm.scorecardPrintableDistributionData[item.id]
                                ),
                                obs
                              ) +
                              'px')})}),0)]:_vm._e()],2)}),0)]},proxy:true}])})],1)],1)],1),(_vm.desiredContentLength && _vm.includeAllAgents)?_c('div',_vm._l((_vm.allAgentsSorted),function(item){return _c('section',{key:item.id},[_c('scorecard-view',{attrs:{"agentId":item.id,"format":'printable-team'}})],1)}),0):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }