<template>
  <svg
    style="filter: drop-shadow(0px 3px 3px rgb(0 0 0 / 0.3));"
    :height="svgBoxSize"
    :width="svgBoxSize"
  >
    <circle
      stroke-linecap="round"
      fill="rgba(1, 106, 175, 1)"
      :cx="radius"
      :cy="radius"
      :r="innerRadius"
      stroke="rgba(245, 245, 245, 1)"
      :stroke-width="strokeWidth"
      :stroke-dasharray="strokeBedArray"
      :transform="strokeTransform"
    />
    ‍
    <circle
      class="animate-stroke"
      stroke-linecap="round"
      fill="transparent"
      :cx="radius"
      :cy="radius"
      :r="innerRadius"
      :stroke="strokeColor"
      :stroke-width="strokeWidth"
      :stroke-dasharray="strokeDashArray"
      :stroke-dashoffset="strokeDashOffset"
      :transform="strokeTransform"
    />
    ‍
    <text
      style="filter: drop-shadow(0px 3px 3px rgb(0 0 0 / 0.3));"
      fill="white"
      font-family="Roboto"
      font-weight="900"
      :font-size="radius / 2.5"
      :x="radius"
      :y="radius"
      text-anchor="middle"
      dominant-baseline="central"
    >
      {{ formattedScoreAsPercent }}
    </text>
  </svg>
</template>

<script>
import colors from "@/mixins/colors";

export default {
  mixins: [colors],
  props: {
    score: {
      type: Number
    },
    radius: {
      type: Number,
      default: 50
    }
  },
  data: () => ({
    strokeWeight: 0.3,
  }),
  computed: {
    svgBoxSize() {
      return this.radius * 2;
    },
    formattedScoreAsPercent() {
      return Math.round(this.score * 100) + "%";
    },
    strokeBedArray() {
      const circumference = this.innerRadius * 2 * Math.PI;
      const degrees = 360;
      const arc = circumference * (degrees / 360);
      const bedArray = `${arc} ${circumference}`;
      return bedArray;
    },
    strokeDashArray() {
      const circumference = this.innerRadius * 2 * Math.PI;
      const degrees = this.score * 360;
      const arc = circumference * (degrees / 360);
      const dashArray = `${arc} ${circumference}`;
      return dashArray;
    },
    strokeDashOffset() {
      return this.score * 360;
    },
    strokeTransform() {
      const transform = `rotate(-90, ${this.radius}, ${this.radius})`;
      return transform;
    },
    strokeColor() {
      let score = this.score ? this.score : 0; // prevents NaN
      return this.getColorValue(score)
    },
    strokeWidth() {
      const strokeWidth = this.radius * this.strokeWeight;
      return strokeWidth;
    },
    innerRadius() {
      const innerRadius = this.radius - this.strokeWidth / 2;
      return innerRadius;
    }
  },
  methods: {
    getRadialOptions: function() {
      return this.radialoptions;
    }
  }
};
</script>
<style scoped>
.animate-stroke {
  animation: dash-animation 600ms linear forwards;
}

@keyframes dash-animation {
  to {
    stroke-dashoffset: 0;
  }
}

i.processing {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
