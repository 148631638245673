<template>
  <div>
    <dashboard-content></dashboard-content>
  </div>
</template>
<style scoped lang="css">
.nav-element { display: none; }
.v-content { padding: 0 !important; }
.col { flex-basis: auto; width: 500px; }
html { font-size: 10pt !important; }
</style>
<script>
import DashboardContent from "./MainContent.vue";
export default {
  components: {
    DashboardContent,
  },
};
</script>
