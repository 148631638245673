<template>
    <div class="adHocContainer">
      <v-row>
        <div class="search-history">
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" :disabled="searchQueries.length==0" icon>
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                  v-for="(item, index) in searchQueriesSorted"
                  :key="index"
                  @click="populateSearchField(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="search-field px-3">
          <v-row>
            <v-text-field
              v-model="search"
              ref="searchQuery"
              solo
              placeholder="Search Transcripts"
              clearable
              @keyup.enter="performSearch()"
              class="d-flex"
              dense
              counter="255"
              :rules="searchFieldRule"
              :hide-details="!searchIsTooLong"
              >
            </v-text-field>
          </v-row>
          <v-row class="mt-1">
            <div class="search-help">
              <v-card flat>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col class="pt-0">
									<span v-if="isExpanded">
										<v-row>
											<v-col cols="auto" class="pt-0 px-0" align="center">
												<v-tooltip :open-on-hover="false" open-on-click bottom z-index="9999">
													<template v-slot:activator="{ on }">
														<v-btn @click="on.click" v-on="on" icon>
															<v-icon color="primary">mdi-information-outline</v-icon>
														</v-btn>
													</template>
													<span v-html="tipMessage"> </span>
												</v-tooltip>
											</v-col>
											<v-col class="pt-0">
												<span class="hintText" v-html="hints"></span>
											</v-col>
										</v-row>
									</span>
                      <span v-else style="font-size: .75rem;">
										Use quotes to search for phrases, i.e. "Thank you for calling"
									</span>
                    </v-col>
                    <v-col class="pt-0 d-flex pr-0">
                      <v-spacer></v-spacer>
                      <v-btn text x-small color="primary" @click="toggleHints" class="pr-0">
										<span v-if="isExpanded">
											less search help
											<v-icon small right>mdi-chevron-up</v-icon>
										</span>
                        <span v-else>
											more search help
											<v-icon small right>mdi-chevron-down</v-icon>
										</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
        </div>
        <div class="search-activator">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" :color="searchIconColor" :disabled="searchIsEmpty||searchIsTooLong" @click="performSearch()">
                <v-icon>{{ searchIcon }}</v-icon>
              </v-btn>
            </template>
          <span>{{ searchIconText }}</span>
          </v-tooltip>

        </div>
        <div class="search-channels mt-2 pb-0" v-show="is2Channel">
          <v-btn depressed text small @click="showSearchChannels=!showSearchChannels">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ searchButtonText }}
          </v-btn>
          <div class="searchChannelDiv">
            <div v-show="showSearchChannels" @mouseleave="showSearchChannels=false" class="searchChannels">
              <v-btn small color="primary" @click="switchChannel('agent')">Search By Agent</v-btn><br />
              <v-btn small color="primary" @click="switchChannel('customer')">Search By Customer</v-btn><br />
              <v-btn small color="primary" @click="switchChannel('both')">Search Both</v-btn>
            </div>
          </div>
        </div>
      </v-row>
    </div>
</template>

<script>

import CacheHandler from '@/utils/CacheHandler';
import { mapGetters} from "vuex";

export default {
  props: {
    channels: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      search: '',
      searchFieldRule: [v => (v) && v.length <= 255 || 'Maximum characters for search is 255'],
      searchButtonText: 'Add Channel Search',
      showSearchChannels: false,
      searchChannel: '',
      searchQueries: [],
      isExpanded: false,
			isShowLess: false,
			hints:  `
              Use search option to narrow in on results for a small number of entered keywords.<br />
              Use quotes to search for a specific order of phrases.<br />
              Use reserved words AND, OR, NOT to constrain your search.<br />
              Returned results (without NOT) will contain calls with at least one segment match across all transcription segments.<br />
              Returned results (with NOT) will contain calls with no match on keywords entered after NOT across all transcription segments.<br />
              Search criteria entries are not case sensitive.<br />
              <b>Click the info icon for examples</b>
              `,
			tipMessage: `Examples:
                  <p>
                  Checking account (Checking OR account) – <br />
                  If a call has checking or account within any segment,<br />
                  a call would be considered a match.<br />
                  Example of a matched segment: I am checking in about my balance.
                  </p>
                  <p>
                  Checking AND account – <br />
                  If both checking and account are found within one segment,<br />
                  a call would be considered a match.<br />
                  Example of a matched segment: I am checking in about my account.
                  </p>
                  <p>
                  “Checking account” – <br />
                  If “checking account” was found within one segment, <br />
                  a call would be considered a match.<br />
                  Example of a matched segment: I am calling about my checking account.
                  </p>
                  <p>
                  NOT checking account (NOT checking OR account) – <br />
                  If checking or account are not found across all segments, <br />
                  a call would be considered a match. <br />
                  Example of a matched segment: I am calling about a loan.
                  </p>
                  <p>
                  NOT checking AND account – <br />
                  If checking and account are not found within one segment,<br />
                  a call would be considered a match. <br />
                  Example of a matched segment: I am calling about my account.
                  </p>
                  <p>
                  NOT “checking account” - <br />
                  Example of a matched segment: I am calling about my account.
                  </p>`,
    };
  },
  computed: {
    searchIconText() {
      return this.searchIsResetable ? "Clear Search and Reload Page" : "Activate Search"
    },
    searchIconColor() {
      return this.searchIsResetable ? "grey lighten-2" : "primary"
    },
    searchIcon() {
      return this.searchIsResetable ? "mdi-magnify-close" : "mdi-magnify"
    },
    searchIsResetable() {
      return (
        this.$route.query.phrase == this.search
        && typeof this.search == 'string'
        && this.search.trim()!=''
        && ((typeof this.searchChannel == 'string' && this.searchChannel == this.$route.query.speaker)
           || (typeof this.searchChannel == 'undefined' && typeof this.$route.query.speaker == 'undefined')
           || (typeof this.searchChannel == 'string' && this.searchChannel=='' && typeof this.$route.query.speaker == 'undefined'))
      )
    },
    searchIsDirty() {
      return (
        this.$route.query.phrase != this.search
        || (typeof this.searchChannel == 'string' && this.searchChannel != "" && this.searchChannel != this.$route.query.speaker)
      )
    },
    searchIsTooLong() {
      return (typeof this.search == 'string' && this.search.length>255)
    },
    searchIsEmpty() {
      return (typeof this.search == 'object' || typeof this.search == 'undefined' || (typeof this.search == 'string' && this.search.trim()==''))
    },
    is2Channel() {
      return (this.channels==2)
    },
    searchQueriesSorted() {
      // remove dupes, take top 10 and display in reverse order
      let searches = [...new Set(this.searchQueries)]
      return searches.slice(-10).reverse()
    },
    ...mapGetters("users", [
      "userDataLoadingStatus",
    ]),
  },
  methods: {

    populateSearchField(val) {
      this.search = val
      this.$refs["searchQuery"].focus()
    },

    toggleHints() { this.isExpanded = !this.isExpanded },

    clearSearch() {
      const query = { ...this.$route.query }
      delete query.phrase
      delete query.speaker
      this.search = ''
      this.searchChannel = ''
      this.$router.replace({ query })
      this.switchChannel('both')
    },

    clearSearchChannel() {
      const query = { ...this.$route.query }
      delete query.speaker
      this.searchChannel = ''
      this.$router.replace({ query })
    },

    performSearch() {
      this.$refs["searchQuery"].blur();
      this.$nextTick(() => {
        if(this.searchIsResetable) {
          this.$emit("searchChanged", 'off')
          this.clearSearch()
        } else if (this.searchIsDirty) {
          this.$emit("searchChanged", 'on')
          let source = { phrase: this.search }
          if (this.searchChannel) source.speaker = this.searchChannel
          if (this.search && this.search != '') {
            this.searchQueries.push(this.search)
            CacheHandler.setItem('searchQueries', JSON.stringify(this.searchQueries))
          }
          this.$router.replace({ query: Object.assign({}, this.$route.query, source) })
        } else
          this.clearSearchChannel()
      })
    },
    switchChannel(channel) {
      switch (channel) {
        case 'customer':
          this.searchButtonText = 'Searching Customer Channel'
          this.searchChannel = 'customer'
          break;
        case 'agent':
          this.searchButtonText = 'Searching Agent Channel'
          this.searchChannel = 'agent'
          break;
        default:
          this.searchButtonText = 'Searching Both Channels'
          this.searchChannel = ''
          break;
      }
    },
    getStoredQueries() {
      let storedQueries = JSON.parse(CacheHandler.getItem('searchQueries'));
      if (storedQueries) this.searchQueries = storedQueries;
    },
  },
  mounted() {
    if (this.userDataLoadingStatus=='loaded') {
      this.getStoredQueries()
    }
    this.search = this.$route.query.phrase;
    if (this.$route.query.speaker) this.switchChannel(this.$route.query.speaker);
  },
  watch: {
    userDataLoadingStatus: function(status) {
      if(status=='loaded') {
        this.getStoredQueries()
      }
    },
  }
};
</script>

<style scoped>

.adHocContainer {
  padding-bottom: 0;
}

.searchChannels {
  position: absolute;
  border: 1px solid #C0C0C0;
  margin: 10px;
  padding: 10px;
  width: 250px;
  min-height: 100px;
  z-index: 100;
  background-color: #FFF;
}
.searchChannels button {
  margin: 2px;
}

.hintText {
	color: rgb(25, 118, 210);
	line-height: 1rem;
}

.search-field {
  margin-right: .5rem;
  width: 68%;
}

.search-history {
  margin-right: .5rem;
}

.search-activator {
  margin-right: .5rem;
}

.search-help {
  width: 100%;
  margin-right: 1rem;
}

</style>
