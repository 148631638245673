<template>
  <div>
    <v-row v-if="scoringType == 0" align="center">
      <v-col cols="12">
        <fieldset class="mb-2">
          <legend>Subcategory Searches</legend>
          <v-data-table
            :loading="searchDataLoadingStatus == 'loading'"
            loading-text="Loading... Please wait"
            v-model="searchesSelected"
            :headers="searchHeaders"
            :items="selectableSearches"
            :search="searchesSearch"
            :hide-default-footer="true"
            @item-selected="selectItem"
            @toggle-select-all="selectAll"
            item-key="id"
            show-select
            disable-pagination
            class="dl-dt"
            dense
            height="20vh"
          >
            <template v-slot:header.data-table-select="{ props, on }">
              <v-simple-checkbox
                :value="props.value || props.indeterminate"
                v-on="on"
                :indeterminate="props.indeterminate"
                color="primary"
                :ripple="false"
              />
            </template>

            <template
              v-if="scoringType == 1"
              v-slot:item.data-table-select="{ on, props }"
            >
              <v-simple-checkbox
                :disabled="true"
                v-bind="props"
                v-on="on"
                :ripple="false"
              ></v-simple-checkbox>
            </template>
            <template v-slot:top>
              <v-text-field
                v-model="searchesSearch"
                label="Find Searches..."
                class="mx-4"
                single-line
                dense
                hide-details
                clearable
                append-icon="mdi-magnify"
              ></v-text-field>
            </template>
            <template v-slot:item.disabled="{ item }">
              <v-icon v-if="item.disabled" small class="mr-2">
                mdi-check
              </v-icon>
            </template>
            <template v-slot:header.search_class="{ header }">
              {{ header.text }}
            </template>
            <template v-slot:item.search_class="{ item }">
              <v-icon v-if="item.search_class=='phonetic'" color="purple" name='phonetic-search' title="Search Type: Phonetic">mdi-access-point</v-icon>
              <v-icon v-else-if="item.search_class=='transcription'" color="teal" title="Search Type: Transcript">mdi-text-box</v-icon>
              <v-icon v-else color="primary" title="Search Type could not be identified">mdi-help-circle-outline</v-icon>
            </template>
          </v-data-table>
        </fieldset>
        <span class="d-inline caption ml-2"
          >Select at least one search to assign to this subcategory
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-btn
          x-small
          color="normal"
          class="ma-2"
          :disabled="disabledButtons"
          @click="cancelEdits"
          ><span v-if="subCategory.new">discard</span>
          <span v-else>cancel edits</span></v-btn
        >
      <v-spacer></v-spacer>
        <v-btn
          x-small
          color="error"
          class="ma-2"
          @click="deleteSubCategoryFromCategory"
          :disabled="subCategory.new"
          >delete subcategory</v-btn
        >
      </v-col>
    </v-row>
    <confirm ref="confirm"></confirm>
    <confirm-alert ref="confirmalert"> </confirm-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AccessRoleModal from "../AccessRoles/AccessRoleModal.vue";
import Confirm from "../Widgets/Confirm.vue";
import ConfirmAlert from "../Widgets/ConfirmAlert.vue";

export default {
  props: {
    subCategory: Object,
    subCategoryOrig: Object,
    selectedSearches: Array,
    selectedWeight: Number,
    scorecardId: Number,
    categoryId: Number,
    subCategoryCount: Number,
    canCancelAndApply: Boolean,
    scoringType: Number
  },
  components: {
    AccessRoleModal,
    Confirm,
    ConfirmAlert
  },
  data() {
    return {
      searchesSelected: [],
      selectableSearches: [],
      disabledButtons: this.subCategory.cancel ? false : true,
      mySearches: [],
      searchesSearch: "",
      searchHeaders: [
        {
          text: "Searches",
          align: "start",
          sortable: false,
          value: "title"
        },
        {
          text: "Disabled",
          align: "end",
          sortable: false,
          value: "disabled"
        },
        {
          text: "Search Type",
          align: "center",
          sortable: false,
          value: "search_class"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("resources", ["searchData", "searchDataLoadingStatus"]),
    ...mapGetters("scorecardDefinitions", ["originalSubcategoryInfo"])
  },
  methods: {
    ...mapActions({
      deleteSubCategory: "scorecardDefinitions/deleteSubCategory",
      setOriginalSubcategoryInfo:
        "scorecardDefinitions/setOriginalSubcategoryInfo",
      removeNewSubcategory: "scorecardDefinitions/removeNewSubcategory"
    }),
    async deleteSubCategoryFromCategory() {
      if (this.subCategoryCount < 2) {
        if (
          await this.$refs.confirmalert.open(
            "Cannot Delete Subcategory",
            "Sorry, you may not remove the only remaining subcategory within a category.",
            { color: "red" }
          )
        ) {
        }
      } else {
        if (
          await this.$refs.confirm.open(
            "Delete Subcategory?",
            "About to delete subcategory. This action cannot be undone. Are you sure you want to proceed?",
            { color: "red" }
          )
        ) {
          let params = {
            scorecard_id: this.scorecardId,
            category_id: this.categoryId,
            sub_category_id: this.subCategory.id
          };
          this.disabledButtons = true;
          this.deleteSubCategory(params);
          this.$emit("deleteSubCategory");
        }
      }
    },
    async cancelEdits() {
      if (
        await this.$refs.confirm.open(
          "Cancel Edits?",
          "About to cancel subcategory edits. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        this.searchesSelected = [];
        this.selectableSearches = [];
        this.disabledButtons = true;
        let info = {
          scorecard_id: this.scorecardId,
          subcategory_id: this.subCategory.id,
          category_id: this.categoryId
        };
        if (this.subCategory.new && this.subCategoryCount > 1) {
          this.removeNewSubcategory(info);
          this.$emit("deleteSubCategory");
        } else {
          this.setOriginalSubcategoryInfo(info);
          let orig = Object.assign({}, this.originalSubcategoryInfo);
          this.$emit("cancelSelections", orig);
          this.loadOriginalSearchSelections(orig);
        }
      }
    },
    getSortedSearches(options, selections) {
      let selected = options
        .filter(d => selections.some(s => d.id == s.search_id))
        .sort((a, b) => (a.title > b.title ? 1 : -1));
      let unselected = options
        .filter(d => !selections.some(s => d.id == s.search_id))
        .sort((a, b) => (a.title > b.title ? 1 : -1));
      return Array(...selected, ...unselected);
    },
    getSearches(options, selections) {
      let selected = options.filter(d =>
        selections.some(s => d.id == s.search_id)
      );
      let unselected = options.filter(
        d => !selections.some(s => d.id == s.search_id)
      );
      return Array(...selected, ...unselected);
    },
    loadOriginalSearchSelections(orig) {
      let searchOptions = [];
      this.mySearches = orig.searches;
      searchOptions = this.searchData.map(obj => {
        return {
          id: obj.id,
          new: false,
          title: obj.name + " (" + obj.id + ")",
          disabled: obj.status == 1 ? false : true,
          search_class: obj.search_class
        };
      });
      //need a way to send the database id back as well - so we can update the record there
      searchOptions.forEach((option, index) => {
        this.mySearches.forEach((selected, index) => {
          if (selected.search_id == option.id && selected.id) {
            option.db_id = selected.id;
          }
        });
      });

      this.selectableSearches = this.getSortedSearches(
        searchOptions,
        orig.searches
      );

      this.searchesSelected = this.selectableSearches.filter(d =>
        this.mySearches.some(s => d.id == s.search_id)
      );

    },
    loadSearchSelections() {
      let searchOptions = [];
      this.mySearches = this.selectedSearches;
      searchOptions = this.searchData.map(obj => {
        return {
          id: obj.id,
          new: true,
          title: obj.name + " (" + obj.id + ")",
          disabled: obj.status == 1 ? false : true,
          search_class: obj.search_class
        };
      });

      //need a way to send the database id back as well - so we can update the record there
      searchOptions.forEach((option, index) => {
        this.mySearches.forEach((selected, index) => {
          if (selected.search_id == option.id && selected.id) {
            option.new = false;
            option.db_id = selected.id;
          }
        });
      });

      this.selectableSearches = this.getSortedSearches(
        searchOptions,
        this.selectedSearches
      );

      this.searchesSelected = this.selectableSearches.filter(d =>
        this.mySearches.some(s => d.id == s.search_id)
      );
    },
    selectItem(value) {
      this.subCategory.cancel = true;
      this.disabledButtons = false;
    },
    selectAll() {
      this.subCategory.cancel = true;
      this.disabledButtons = false;
    },
    updateSearches() {
      this.$emit("updateSearchSelections", this.searchesSelected);
    },
    buttonsChanged() {
      this.disabledButtons = this.canCancelAndApply;
    },
    checkSearch() {
      if (this.selectedSearches.length < 1 && this.scoringType == 1) {
        this.searchesSelected = [];
      }
    }
  },
  mounted() {
    this.loadSearchSelections();
  },
  watch: {
    searchesSelected: "updateSearches",
    canCancelAndApply: "buttonsChanged",
    selectedSearches: "checkSearch"
  }
};
</script>

<style scoped>

  .fieldset-padding {
    margin: 0.2rem;
    width: 100%;
    height: 100%;
  }

</style>
