<template>
  <div class="loading-mask" :class="getLoadingMaskClass()"></div>
</template>

<script>
export default {
  methods: {
    getLoadingMaskClass: function() {
      var cls = {};

      if (this.addMargin) {
        cls['loading-mask-margin'] = true;
      }

      if (this.isDarkTheme) {
        cls['loading-mask-dark'] = true;
      }

      if (this.isTransparent) {
        cls['loading-mask-transparent'] = true;
      } else if (this.isSemiTransparent) {
        cls['loading-mask-semi-transparent'] = true;
      } else if (this.inheritBackground) {
        cls['loading-mask-inherit-background'] = true;
      }

      return cls;
    }
  },
  props: {
    addMargin: {
      type: Boolean,
      default: false
    },
    inheritBackground: {
      type: Boolean,
      default: false
    },
    isDarkTheme: {
      type: Boolean,
      default: false
    },
    isSemiTransparent: {
      type: Boolean,
      default: false
    },
    isTransparent: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.loading-mask::after {
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}
</style>
