<template>
  <div>
    <!-- insights -->
    <div :class="breadcrumbCls" @click="goBack('insights')">Insights</div>
    <div class="breadcrumbs">></div>

    <!-- dynalabel -->
    <template v-if="level == 'dynalabel'">
      <template v-if="!hasPhrase">
        <div class="breadcrumbs">{{ insightHeader }}</div>
      </template>

      <template v-else-if="hasPhrase">
        <div v-if="!searchFieldIsEmpty" class="breadcrumbs">
          {{ insightHeader }} {{ searchPhraseText }}
        </div>
        <div
          v-else
          :class="breadcrumbExtCls"
          @click="reApplySearchPhrase"
        >
          {{ insightHeader }} {{ searchPhraseText }}
        </div>
      </template>
    </template>

    <!-- agent -->
    <template v-else-if="level == 'agent'">
      <template v-if="singleDynaCount && !hasPhrase">
        <div class="breadcrumbs">
          {{ insightHeader }} / {{ dynaLabelHeader }}
        </div>
      </template>
      <template v-else-if="!singleDynaCount">
        <div :class="breadcrumbCls" @click="goBack('dynalabels')">
          {{ insightHeader }}
        </div>
        <div class="breadcrumbs">></div>
      </template>

      <template v-if="hasPhrase">
        <div v-if="!searchFieldIsEmpty" class="breadcrumbs">
          {{ agentLevelHeader }}
        </div>
        <div
          v-else
          :class="breadcrumbExtCls"
          @click="reApplySearchPhrase"
        >
          {{ agentLevelHeader }}
        </div>
      </template>

      <template v-else>
        <div class="breadcrumbs" v-if="!singleDynaCount">
          {{ dynaLabelHeader }}
        </div>
      </template>
    </template>

    <!-- call/transcript -->
    <template v-else-if="level == 'call' || level == 'call-transcript'">
      <template v-if="singleDynaCount">
        <div :class="breadcrumbCls" @click="goBack('agents')">
          {{ insightHeader }} / {{ dynaLabelHeader }}
        </div>
      </template>

      <template v-if="!singleDynaCount">
        <div :class="breadcrumbCls" @click="goBack('dynalabels')">
          {{ insightHeader }}
        </div>
        <div class="breadcrumbs">></div>
        <div :class="breadcrumbCls" @click="goBack('agents')">
          {{ dynaLabelHeader }}
        </div>
      </template>

      <div class="breadcrumbs">></div>

      <!-- call -->
      <template v-if="level == 'call'">
        <span v-if="hasPhrase">
          <div v-if="!searchFieldIsEmpty" class="breadcrumbs">
            {{ agentHeader }} {{ searchPhraseText }}
          </div>
          <div
            v-else
            :class="breadcrumbExtCls"
            @click="reApplySearchPhrase"
          >
            {{ agentHeader }} {{ searchPhraseText }}
          </div>
        </span>
        <span v-else>
          <div class="breadcrumbs">{{ agentHeader }}</div>
        </span>
      </template>

      <!-- transcript -->
      <template v-else-if="level == 'call-transcript'">
        <div :class="breadcrumbExtCls" @click="goBack('calls')">
          {{ agentHeader }}
          <span v-if="hasPhrase && !useContext">{{ searchPhraseText }}</span>
        </div>

        <div class="breadcrumbs">></div>
        <div class="breadcrumbs">{{ callId }}</div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    level: String,
    insightHeader: String,
    dynaLabelHeader: String,
    singleDynaCount: Boolean,
    searchFieldIsEmpty: Boolean,
    agentHeader: String,
    callId: String,
    useContext: Boolean,
    linkEnabled: Boolean
  },
  computed: {
    breadcrumbCls() {
      return this.linkEnabled ? "linkbreadcrumbs" : "breadcrumbs";
    },
    breadcrumbExtCls() {
      return this.linkEnabled ? "linkbreadcrumbs extendedlink" : "breadcrumbs";
    },
    agentLevelHeader() {
      if (this.singleDynaCount) {
        return (
          this.insightHeader +
          " / " +
          this.dynaLabelHeader +
          " " +
          this.searchPhraseText
        );
      } else {
        return this.dynaLabelHeader + " " + this.searchPhraseText;
      }
    },
    hasPhrase() {
      return (
        this.$route.query.hasOwnProperty("phrase") &&
        this.$route.query.phrase != ""
      );
    },
    searchPhraseText() {
      return "(search results for: " + this.$route.query["phrase"] + ")";
    }
  },
  methods: {
    goBack(location) {
      if(this.linkEnabled) {
        this.$emit("go-to-page", location);
      }
    },
    reApplySearchPhrase() {
      this.$emit("re-apply-search-phrase");
    }
  }
};
</script>

<style scoped></style>
