<template>
  <div>
    <!-- scorecards -->
    <div :class="breadcrumbCls" @click="goBack('dashboard')">
      Scorecards
    </div>
    <div class="breadcrumbs">></div>

    <!-- dynalabel level -->
    <template v-if="level == 'dynalabel'">
      <div class="breadcrumbs">{{ scorecardHeader }}</div>
    </template>

    <!-- agent level -->
    <template v-else-if="level == 'agent'">
      <template v-if="!singleDynaCount">
        <div :class="breadcrumbCls" @click="goBack('dynalabels')">
          {{ scorecardHeader }}
        </div>
        <div class="breadcrumbs">></div>
        <div class="breadcrumbs">{{ dynaLabelHeader }}</div>
      </template>
      <template v-else>
        <div class="breadcrumbs">
          {{ scorecardHeader }} / {{ dynaLabelHeader }}
        </div>
      </template>
    </template>

    <!-- call/transcript -->
    <template v-else-if="baseLevel">
      <template v-if="!singleDynaCount">
        <div :class="breadcrumbCls" @click="goBack('dynalabels')">
          {{ scorecardHeader }}
        </div>
        <div class="breadcrumbs">></div>
        <div :class="breadcrumbCls" @click="goBack('agent')">
          {{ dynaLabelHeader }}
        </div>
      </template>

      <template v-if="singleDynaCount">
        <div
          v-if="singleDynaCount"
          :class="breadcrumbCls"
          @click="goBack('agent')"
        >
          {{ scorecardHeader }} / {{ dynaLabelHeader }}
        </div>
      </template>

      <div class="breadcrumbs">></div>

      <!-- call -->
      <template v-if="callLvl || printableLvl">
        <div v-if="callLvl" class="breadcrumbs">{{ agentHeader }}</div>
        <div
          v-if="printableLvl"
          :class="breadcrumbCls"
          @click="goBack('calls')"
        >
          {{ agentHeader }}
        </div>
        <template v-if="printableLvl">
          <div class="breadcrumbs">></div>
          <div class="breadcrumbs">
            <span v-if="printableTeamLvl">Scorecard</span>
            <span v-if="printableAgentLvl">Agent Scorecard</span>
          </div>
        </template>
      </template>

      <!-- call transcript/scorecard -->
      <template v-else>
        <div :class="breadcrumbCls" @click="goBack('calls')">
          {{ agentHeader }}
        </div>
        <div class="breadcrumbs">></div>
        <div class="breadcrumbs">{{ callId }}</div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    level: String,
    scorecardHeader: String,
    dynaLabelHeader: String,
    singleDynaCount: Boolean,
    searchFieldIsEmpty: Boolean,
    agentHeader: String,
    callId: String,
    linkEnabled: Boolean
  },
  computed: {
    printableLvl() {
      return this.printableAgentLvl || this.printableTeamLvl;
    },
    printableAgentLvl() {
      return this.level == "printable-agent";
    },
    printableTeamLvl() {
      return this.level == "printable-team";
    },
    callLvl() {
      return this.level == "call";
    },
    baseLevel() {
      return (
        this.callLvl ||
        this.printableLvl ||
        this.level == "call-transcript" ||
        this.level == "call-scorecard"
      );
    },
    breadcrumbCls() {
      return this.linkEnabled ? "linkbreadcrumbs" : "breadcrumbs";
    }
  },
  methods: {
    goBack(location) {
      if(this.linkEnabled) {
        this.$emit("go-to-page", location);
      }
    }
  }
};
</script>

<style scoped></style>
