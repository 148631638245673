<template>
  <div class="insight-expanded-outer-container elevation-1">
    <v-row style="border-bottom: 1px dotted rgb(204, 204, 204);" class="ml-1 mr-1 pb-2 pt-2">
      <v-col :cols="titleCols"  class="pt-0 pb-1 insight-title expanded-highlight-bar" :title="insight.title + ' (' + insight.id + ')'">
        {{insight.title}}
      </v-col>
      <v-col :cols="iconCols" class="pt-0 pb-1 d-flex justify-end">
        <v-btn v-if="insight.info.dynalabel_count>1" id="selectInsightEx" text small color="primary" :to="{ name: 'insights-details', params: { id: insight.id }, query: $route.query }">Select Insight</v-btn>
        <v-btn v-else text small color="primary" id="selectInsightEx" :to="{ name: 'insights-dynalabels-agents', params: { id: insight.id, dynalabelId: 0 }, query: $route.query }">Select Insight</v-btn>
        <v-btn icon small color="primary"><v-icon @click="toggleExpansion">mdi-arrow-collapse</v-icon></v-btn>
        <v-tooltip max-width="30rem" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isProcessing" color="primary" icon small v-bind="attrs" v-on="on">
              <v-icon
                class="processing"
                v-text="'mdi-autorenew'"
              ></v-icon>
            </v-btn>
            <v-btn v-else color="primary" icon small v-bind="attrs" v-on="on"><v-icon>mdi-information-outline</v-icon></v-btn>
          </template>
          <span>
            <span v-if="isProcessing">{{insightProcessingMessage}}</span><br v-if="isProcessing"/>
            <span v-if="checkMinTalktime">{{ formatHMS(insight.info.minimumTalkTime) }} minimum talk time</span><span v-else>No minimum talk time</span><br/>
            <span v-if="checkMaxTalktime">{{ formatHMS(insight.info.maximumTalkTime) }} maximum talk time</span><span v-else>No maximum talk time</span><br/>
            Last published: {{ insight.info.updated_at }}<br/>
            Insight ID: {{insight.id}} <br>
            Created by: {{insight.owner}}
            <span v-if="properDescription">
              <br>
              Description: {{insight.description}}
            </span>
          </span>
        </v-tooltip>
        <v-btn :color="pinColor" icon small><v-icon @click="pinFavorite">mdi-pin</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-2 pb-0">
        <insights-carousel  style="border-bottom: 1px dotted rgb(204, 204, 204); "  @update-chart="chartChanged" :charts="charts" :insight="insight" :chartHeight="Math.round(expandedContainerHeight*.65)" :chartWidth="Math.round(expandedContainerWidth*.60)"></insights-carousel>
        <v-row align="center" justify="center">
          <v-col sm="2">
          <v-btn-toggle v-model="metricSelection" mandatory>
            <v-btn id="metricSnapshot" @click="setMetric('Snapshot')" small>snapshot</v-btn>
            <v-btn id="metricSentiment" @click="setMetric('Sentiment')" small>sentiment</v-btn>
          </v-btn-toggle>
          </v-col>
        </v-row>
        <div v-if="usingSnapshot" class="expanded-insight-tile-inner-container pt-2 pb-2" :style="{ 'height': (expandedContainerHeight*.25) + 'px'}">

          <div class="expanded-insight-data-tile">
            <v-icon dark :class="vIconClass">mdi-finance</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else id="insightTotalCallsEx" :class="tileValueClass">{{thousands(insight.info.totalCalls)}}</span>
            <span :class="tileTextClass">Total Calls</span>
          </div>

          <div class="expanded-insight-data-tile">
            <v-icon dark :class="vIconClass">mdi-calendar-multiselect</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else id="callVolumeEx" :class="tileValueClass">{{thousands(rounded(insight.info.averageCallsOverTime,0))}}</span>
            <span :class="tileTextClass">Calls/{{capitalize(grouping)}}</span>
          </div>

          <div class="expanded-insight-data-tile">
            <v-icon dark :class="vIconClass">mdi-clock-outline</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else id="totalHoursEx" :class="tileValueClass">{{thousands(rounded(insight.info.totalHours,0))}}</span>
            <span :class="tileTextClass">Hours</span>
          </div>

          <div class="expanded-insight-data-tile">
            <v-icon dark :class="vIconClass">mdi-headset</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else id="averageDurationEx" :class="tileValueClass">{{formatHMS(rounded(insight.info.averageDuration,0))}}</span>
            <span :class="tileTextClass">Talk Time</span>
          </div>

        </div>
        <div v-else class="expanded-insight-tile-inner-container pt-2 pb-2" :style="{ 'height': (expandedContainerHeight*.25) + 'px'}">

          <div class="expanded-insight-data-tile">
            <v-icon dark :class="vIconClass">mdi-finance</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else id="totalCallsEx" :class="tileValueClass">{{thousands(insight.info.totalCalls)}}</span>
            <span :class="tileTextClass">Total Calls</span>
          </div>

          <div class="expanded-insight-data-tile">
            <v-icon dark :class="vIconClass">mdi-plus-circle</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else id="sentimentPositiveEx" :class="tileValueClass">{{sentimentPositiveCalc}}%</span>
            <span :class="tileTextClass">Positive</span>
          </div>

          <div class="expanded-insight-data-tile">
            <v-icon dark :class="vIconClass">mdi-record-circle</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else id="sentimentNeutralEx" :class="tileValueClass">{{sentimentNeutralCalc}}%</span>
            <span :class="tileTextClass">Neutral</span>
          </div>

          <div class="expanded-insight-data-tile">
            <v-icon dark :class="vIconClass">mdi-minus-circle</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-2 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else id="sentimentNegativeEx" :class="tileValueClass">{{sentimentNegativeCalc}}%</span>
            <span :class="tileTextClass">Negative</span>
          </div>

        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import InsightsCarousel from "@/components/Insights/InsightsCarousel.vue"
import {mapGetters,mapActions} from "vuex";
import moment from "moment";
import CacheHandler from "@/utils/CacheHandler";

export default {
  props: {
    insight: Object,
    favorite: Number,
    grid: Boolean,
    expanded: Number,
    grouping: String,
    loading: Boolean,
  },
  components: {
    InsightsCarousel,
  },
  data() {
    return {
      showTooltip: false,
      heightTimeout: null,
      widthTimeout: null,
      debouncedHeight: 0,
      debouncedWidth: 0,
      usingSnapshot: false,
      metricSelection: 0,
      err: {},
      selection: "Snapshot",
      chartId: 0,
      e: null,
      insightProcessingMessage: process.env.VUE_APP_INSIGHT_PROCESSING_MESSAGE
    }
  },
  computed: {
    ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),
    ...mapGetters('insights', [
      'lastChartSelected',
      'metricSelected',
    ]),
    isProcessing() {
      return this.insight.info.processing > 0;
    },
    isLoading() {
      return this.loading;
    },
    properDescription() {
      return (this.insight.description && this.insight.description.length>0 && this.insight.description.toLowerCase() != 'description')
    },
    checkMaxTalktime() {
      return (this.insight.info.maximumTalkTime!=null)
    },
    checkMinTalktime() {
      return (this.insight.info.minimumTalkTime!=null)
    },
    chartSelected() {
      if (this.mixedChannel || (!this.singleChannel && this.dChCC > 0)) {
        if (this.lastChartSelected == 0) {
          return "Customer";
        } else {
          return "Agent";
        }
      } else {
        return "Overall";
      }
    },
    charts() {
      if (this.sChCC == 0 && this.dChCC > 0) {
        return ["Customer", "Agent"];
      } else if (this.sChCC > 0) {
        return ["Overall"];
      } else {
        return [""];
      }
    },
    singleChannel() {
      return this.sChCC>0
    },
    mixedChannel() {
      return this.sChCC>0&&this.dChCC>0
    },
    sChCC() {
      return this.insight.info.single_channel_call_count
    },
    dChCC() {
      return this.insight.info.dual_channel_call_count
    },
    titleCols() {
      if(this.$vuetify.breakpoint.mdAndDown) return 8
      else if(this.$vuetify.breakpoint.lgAndDown) return 9
      else return 10
    },
    iconCols() {
      if(this.$vuetify.breakpoint.mdAndDown) return 4
      else if(this.$vuetify.breakpoint.lgAndDown) return 3
      else return 2
    },
    pinColor() {
      return (this.favorite != this.insight.id) ? 'accent' : 'warning'
    },
    myHeight: {
      get() {
        return this.debouncedHeight;
      },
      set(val) {
        if (this.heightTimeout) clearTimeout(this.heightTimeout);
        this.heightTimeout = setTimeout(() => {
          this.debouncedHeight = val;
        }, 25);
      },
    },
    myWidth: {
      get() {
        return this.debouncedWidth;
      },
      set(val) {
        if (this.widthTimeout) clearTimeout(this.widthTimeout);
        this.widthTimeout = setTimeout(() => {
          this.debouncedWidth = val;
        }, 25);
      },
    },
    expandedContainerHeight() {
      return Math.round(this.myHeight- 310)
    },
    expandedContainerWidth() {
      return Math.round(this.myWidth - 310)
    },
    tileTextClass() {
      return 'tile-text tile-text-'+this.$vuetify.breakpoint.name
    },
    tileValueClass() {
      return 'tile-value tile-value-'+this.$vuetify.breakpoint.name
    },
    tileValueSubClass() {
      return 'tile-value'
    },
    vIconClass() {
      return 'v-icon-'+this.$vuetify.breakpoint.name
    },
    sentimentPositiveCalc() {
      let sentiment = this.chartSelected
      let insightMetrics = this.insight.info.sentiment_metrics
      if (sentiment=='Overall') {
        return Number(Math.round(insightMetrics.sentiment_positive_overall*100))
      } else if (sentiment=='Customer') {
        return Number(Math.round(insightMetrics.sentiment_positive_customer*100))
      } else {
        return Number(Math.round(insightMetrics.sentiment_positive_agent*100))
      }
    },
    sentimentNeutralCalc() {
      let sentiment = this.chartSelected
      let insightMetrics = this.insight.info.sentiment_metrics
      if (sentiment=='Overall') {
        return Number(Math.round(insightMetrics.sentiment_neutral_overall*100))
      } else if (sentiment=='Customer') {
        return Number(Math.round(insightMetrics.sentiment_neutral_customer*100))
      } else {
        return Number(Math.round(insightMetrics.sentiment_neutral_agent*100))
      }
    },
    sentimentNegativeCalc() {
      let sentiment = this.chartSelected
      let insightMetrics = this.insight.info.sentiment_metrics
      if (sentiment=='Overall') {
        return Number(Math.round(insightMetrics.sentiment_negative_overall*100))
      } else if (sentiment=='Customer') {
        return Number(Math.round(insightMetrics.sentiment_negative_customer*100))
      } else {
        return Number(Math.round(insightMetrics.sentiment_negative_agent*100))
      }
    },
  },
  methods: {
    ...mapActions('insights', [
      'setfavoriteInsightDefinitionId',
      'setLastChartSelected',
      'setMetricSelected'
    ]),
    chartChanged(id) {
      this.chartId = id
      this.setLastChartSelected(id)
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    toggleExpansion() {
      this.$emit('toggle-expansion', {'id': 0 })
    },
    pinFavorite() {
      if(this.insight.id==this.favorite) {
        this.setfavoriteInsightDefinitionId(0)
      } else {
        this.setfavoriteInsightDefinitionId(this.insight.id)
      }
    },
    getClass(position) {
      return position
    },
    thousands(num) {
      return Number(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    rounded(num,places) {
      return Number(num).toFixed(places)
    },
    formatHMS(seconds) {

      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    resizeHandler() {
      this.myHeight = window.innerHeight;
      this.myWidth = window.innerWidth;
    },
    updateMetric() {
      this.metricCheck(this.selection)
    },
    setMetric(metric) {
      this.selection = metric
      this.metricCheck(this.selection)
    },
    metricCheck(metric) {
      this.setMetricSelected(this.selection)
      if (metric=='Sentiment') {
        this.metricSelection=1
        this.usingSnapshot = false
      } else {
        this.usingSnapshot = true
      }
    }
  },
  updated() {
    // do stuff
  },
  mounted() {
    this.selection = (CacheHandler.getItem('metric_selected') || 'Snapshot')
    this.metricCheck(this.selection)

    this.myHeight = window.innerHeight;
    this.myWidth = window.innerWidth;

    this.setLastChartSelected(Number(CacheHandler.getItem("last_chart_selected") || 0))
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {
    selection: "updateMetric"
    // do stuff
  },
};

</script>

<style>

  div.insight-expanded-outer-container {
    padding: .5rem 1rem .25rem 1rem;
  }

  div.insight-expanded-outer-container div.actions {
    padding: .25rem;
    text-align: center;
  }

  div.insight-expanded-outer-container div.insight-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 99%;
    color: rgba(1, 106, 175, 1);
    font-weight: bold;
    cursor: pointer;
  }

  div.expanded-insight-tile-inner-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  div.expanded-insight-data-tile {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    overflow: hidden;
  }

  div.expanded-insight-data-tile i.v-icon {
    opacity: .20;
    position: absolute;
  }

  div.expanded-insight-data-tile span.tile-value {
    font-weight: bold;
    text-align: right;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(1, 106, 175, 1);
    border-radius: .25rem .25rem 0 0;
  }

  div.expanded-insight-data-tile span.tile-text {
    text-align: right;
    color: rgba(1, 106, 175, 1);
    background-color: rgba(1, 106, 175, .25);
    border-radius: 0 0 .25rem .25rem;
  }

  div.expanded-insight-data-tile i.v-icon-xs {
    font-size: 4.5rem;
    left: -.75rem;
    bottom: 1rem;
  }
  div.expanded-insight-data-tile i.v-icon-sm {
    font-size: 4.5rem;
    left: -.75rem;
    bottom: 1rem;
  }
  div.expanded-insight-data-tile i.v-icon-md {
    font-size: 4.5rem;
    left: -.75rem;
    bottom: 1rem;
  }
  div.expanded-insight-data-tile i.v-icon-lg {
    font-size: 4.5rem;
    left: -.75rem;
    bottom: 1rem;
  }
  div.expanded-insight-data-tile i.v-icon-xl {
    font-size: 7.5rem;
    left: -1.5rem;
    bottom: 0;
  }

  div.expanded-insight-data-tile span.tile-text-xs {
    padding: .5rem;
    font-size: 1rem;
  }
  div.expanded-insight-data-tile span.tile-text-sm {
    padding: .5rem;
    font-size: 1rem;
  }
  div.expanded-insight-data-tile span.tile-text-md {
    padding: .5rem;
    font-size: .9rem;
  }
  div.expanded-insight-data-tile span.tile-text-lg {
    padding: .5rem;
    font-size: 1rem;
  }
  div.expanded-insight-data-tile span.tile-text-xl {
    padding: 1rem;
    font-size: 1.2rem;
  }

  div.expanded-insight-data-tile span.tile-value-xs {
    padding: .5rem;
    font-size: 1.2rem;
  }
  div.expanded-insight-data-tile span.tile-value-sm {
    padding: .5rem;
    font-size: 1.2rem;
  }
  div.expanded-insight-data-tile span.tile-value-md {
    padding: 1.25rem .75rem 0 .75rem;
    font-size: 1.75rem;
  }
  div.expanded-insight-data-tile span.tile-value-lg {
    padding: .75rem .75rem 0;
    font-size: 1.6rem;
  }
  div.expanded-insight-data-tile span.tile-value-xl {
    padding: .5rem .75rem 0;
    font-size: 2.8rem;
  }

  div.expanded-highlight-bar {
    border-left: 4px solid rgb(34, 124, 213);
  }



</style>
