<template>
  <v-parallax dark class="cf-background">
    <v-row align="center" justify="center">
      <v-col v-if="appStatus.message" class="text-center" cols="12">
        <img src="@/assets/cf-logo.png" alt="">
        <h1 class="heading white--text">The CallFinder<sup>&reg;</sup> Application is Offline</h1>
        <h2 class="subheading white--text" v-html="appStatus.message"></h2>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
  name: 'Offline',
  data() {
      return {
      }
  },
  computed: {
    ...mapGetters({
      appStatus: "authentication/appStatus",
      appStatusLoadingStatus: "authentication/appStatusLoadingStatus",
    }),
  },
  methods: {
    ...mapActions({
      getAppStatus: "authentication/getAppStatus",
    }),
  },
}

</script>

<style>
</style>
