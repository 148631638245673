<template>
  <div>
    <v-carousel class="carousel" v-if="charts.length>1" prev-icon="mdi-arrow-left-bold-circle" :height="chartHeight"  next-icon="mdi-arrow-right-bold-circle" v-model="model" show-arrows-on-hover hide-delimiter-background hide-delimiters>
      <v-carousel-item v-for="(chart, i) in charts" :key="i">
        <v-row align="center" justify="center">
          <insights-chart-ending-sentiment :chartType="chart" :insight="insight" :height="chartHeight" :width="chartWidth"></insights-chart-ending-sentiment>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <insights-chart-ending-sentiment v-else :chartType="charts[0]" :insight="insight" :height="chartHeight" :width="chartWidth"></insights-chart-ending-sentiment>
  </div>
</template>

<script>

import InsightsChartEndingSentiment from "@/components/Insights/InsightsChartEndingSentiment.vue";
import {mapGetters,mapActions} from "vuex";
import CacheHandler from "@/utils/CacheHandler";

export default {
  components: {
    InsightsChartEndingSentiment
  },
  props: {
    insight: Object,
    chartHeight: Number,
    chartWidth: Number,
    charts: Array,
  },
  data: () => ({
    model: 0,
  }),
  methods: {
    updateChart() {
      this.setLastChartSelected(this.model)
      this.$emit('update-stats', this.model);
    },
    ...mapActions('insights', [
      'setfavoriteInsightDefinitionId',
      'setLastChartSelected',
    ]),
  },
  mounted() {
    this.model = Number(CacheHandler.getItem('last_chart_selected') || 0)
    this.$emit('update-chart', this.model);
    this.setLastChartSelected(this.model)
  },
  computed: {
    ...mapGetters('insights', [
      'lastChartSelected',
    ]),
  },
  watch: {
    model: "updateChart"
  },
}
</script>

<style >
 .carousel .v-btn .v-btn__content .v-icon {
    color: #016AAF;
  }
  .big-type { font-size: 5rem !important; }

</style>
