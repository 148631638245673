<template>
    <v-container fluid mt-0 pt-4 pr-12 pb-8 pl-8>
          <v-row>
            <v-col :cols="columnCounts[0]" class="px-8">
              <h1 class="ml-4">Agent List</h1>
              <v-card class="mt-0" elevation="0">
                <v-card-title class="pt-0">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    clearable
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>

                <v-data-table
                  v-model="selectedAgents"
                  :search="search"
                  :headers="agentHeaders"
                  :items="sortedAgentItems"
                  :loading="this.agentDataLoadingStatus != 'loaded'"
                  :show-select="false"
                >
                  <template v-slot:item.email="{ item, index }">
                    <inline-email-editor :origEmail="item.email" :id="item.id" @update-agent="updateAgent($event)"></inline-email-editor>
                  </template>
                </v-data-table>

              </v-card>
            </v-col>

<!--            TODO: replace this stub when the concept of teams is introduced -->
<!--            <v-col v-if="selectedAgents.length>0" :cols="columnCounts[1]" class="px-8">-->
<!--              <h1 class="ml-4">Agent Properties</h1>-->
<!--              <v-card class="mt-12">-->
<!--                <v-card-title class="pt-0">Team Assignments</v-card-title>-->
<!--                <v-card-text>-->
<!--                  control for selecting team membership-->
<!--                </v-card-text>-->
<!--              </v-card>-->
<!--              <v-card class="mt-12">-->
<!--                <v-card-title class="pt-0">Another Property</v-card-title>-->
<!--                <v-card-text>-->
<!--                  controls for selecting another property-->
<!--                </v-card-text>-->
<!--              </v-card>-->
<!--            </v-col>-->

          </v-row>
    </v-container>
</template>

import inlineEmailEditor from "@/components/Widgets/inlineEmailEditor.vue";

<script>
    import {mapActions, mapGetters} from "vuex"
    import InlineEmailEditor from "@/components/Widgets/inlineEmailEditor.vue";
    export default {
    name: "AgentSetup",
    props: {
    },
    components: {
      InlineEmailEditor
    },
    data() {
      return {
        agentHeaders: [
          { text: 'Agent', value: 'name', 'width': '45%' },
          { text: 'Email', value: 'email', 'width': '55%'},
        ],
        selectedAgents: [],
        search: '',
        }
    },
    methods: {
      ...mapActions("resources", [
        "retrieveAgentData",
        "updateAgentEmail"
      ]),
      updateAgent(payload) {
        this.updateAgentEmail(payload)
      },
    },
    computed: {
      ...mapGetters("resources", [
        'agentData',
        'agentDataLoadingStatus',
        'agentDataLoadingError',
        'agentDataLoadingErrorStatus',
      ]),
      sortedAgentItems() {
        return [...this.agentData].sort((a, b) => a.name.localeCompare(b.name));
      },
      columnCounts() {
        return (this.selectedAgents.length>0) ? [9,3] : [12,0]
      }
    },
    mounted() {
      this.retrieveAgentData({ email: true })
    },
  };
  </script>

<style>

</style>
