<template>
  <div class="wrapper">
    <div class="main-panel">
      <main-content></main-content>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import MainContent from "./MainContent.vue";
export default {
  components: {
    MainContent
  }
};
</script>
