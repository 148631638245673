<template>
  <div
    :data-starts_at="phrase.starts_at"
    :data-ends_at="phrase.ends_at"
    :data-confidence="phrase.confidence"
    :class="makeClassName(transformSentiment)"
  >
    <v-col cols="2" :class="speakerClass">
      <span class="person">{{ speakerText }}</span>
      <icon-base v-if="!isLegacy && insights"
            width="20"
            height="20"
            icon-name="sentiment"
            class="svg-sentiment-info"
            :iconTitle="transformSentiment + ' Sentiment'"
            :class="'svg-' + makeClassName(transformSentiment)"
          ><icon-sentiment /></icon-base>
    </v-col>

    <v-col cols="1" align="center">
      <v-icon
        width="20"
        height="20"
        icon-name="play"
        @click="playFromPosition(phrase.starts_at)"
      >mdi-play-circle-outline</v-icon>
    </v-col>

    <v-col cols="9">
      <span
        v-for="(word, wIndex) in phrase.words"
        :key="wIndex"
        class="word"
        :data-starts_at="word.starts_at"
        :data-ends_at="word.ends_at"
        :data-confidence="word.confidence"
        :class="{ highlight: isWordHighlighted(word) }"
      >{{ word.text }}&nbsp;</span>
    </v-col>
  </div>
</template>

<script>
import IconBase from '@/components/IconBase.vue';
import IconSentiment from '@/components/Icons/IconSentiment.vue';

export default {
  name: "Phrase",
  components: {
    IconBase,
    IconSentiment,
  },

  props: {
    phrase: Object,
    channel_count: Number,
    insights: Boolean,
  },

  methods: {
    makeClassName: function (sentiment) {
      if (sentiment !== null && typeof sentiment !== "undefined") {
        return "sentiment-" + sentiment.replace(/\s+/g, "-").toLowerCase();
      } else {
        return "sentiment-neutral";
      }
    },
    isWordHighlighted(word) {
      if (this.$store.getters['transcript/hits']) {
        var hits = this.$store.getters['transcript/hits']
        if (hits === Array) {
          return hits.some(function (key) {
            return word.starts_at > key[0] && word.ends_at < key[1]
          })
        }
        setTimeout(() => {
          this.setPosition(hits)
        }, 2000)
      }
      return false
    },
    playFromPosition(position) {
      this.$emit('update-play-from-position', position);
    },
    setPosition(position) {
      this.$emit('update-set-position', position);
    },
  },

  computed: {
    speakerClass() {
      if(this.channel_count>1) {
        return 'speaker ' + this.phrase.speaker.toLowerCase()
      } else {
        return (this.phrase.channel==1) ? 'speaker agent' : 'speaker customer'
      }
    },
    speakerText() {
      if(this.channel_count>1) {
        return this.phrase.speaker
      } else {
        return (this.phrase.channel==1) ? 'Speaker 1' : 'Speaker 2'
      }
    },
    phrases() {
      // Phrases from Voci Analysis
      let transcript = this.transcript;
      if (transcript) {
        if (transcript.analyses) {
          return transcript.analyses[0].phrases;
        } else {return [];}
      } else {return [];}
    },
    isLegacy() {
      return this.$route.name === "transcripts-lg";
    },
    transformSentiment() {
      let response = ''
      switch (this.phrase.sentiment) {
        case 'Positive':
          response = 'Strong Positive';
          break;
        case 'Mostly Positive':
          response = 'Positive';
          break;
        case 'Mostly Negative':
          response = 'Negative';
          break;
        case 'Negative':
          response = 'Strong Negative';
          break;
        case 'Mixed':
          response = 'Mixed';
          break;
        default:
          response = "Neutral"
      }
      return response;
    },
  },
};

</script>

<style scoped>

  .word {
    display: inline-block;
    line-height: 2em;
  }

  .speaker {
    line-height: 2em;
    font-size: 1rem;
    color: rgba(0,0,0,.5);
    font-weight: 900;
    font-variant: all-petite-caps;
    width: 10%;
  }

  .speaker span.person {
    display: inline-block;
    width: 75%;
  }

  .speaker.agent span.person {
    opacity: 0.66;
  }

  .speaker.customer span.person {
    opacity: 1;
  }

  .transcript-modal > .content,

  .legacyrow {
    width: 99% !important;
  }

  .legacycol {
    height: 53vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .highlight {
    background-color: yellow !important;
    font-weight: bold !important;
  }

  .svg-sentiment-strong-positive {
    --color-chevron-north-upper: green;
    --color-chevron-north-middle: lightgreen;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-positive {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: green;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-neutral {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-negative {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: orangered;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-strong-negative {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: orange;
    --color-chevron-south-lower: orangered;
  }

  .svg-sentiment-mixed {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgreen;
    --color-chevron-south-middle: orange;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-info {
    cursor: help;
  }

  .border-light {
    border: 1px solid rgba(0,0,0,.3);
  }

</style>
