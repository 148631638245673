<template>
  <multiselect
    v-model="value"
    :options="options"
    :close-on-select="false"
    :clear-on-select="false"
    :preserve-search="true"
    :placeholder="display"
    label="display"
    track-by="display"
    :preselect-first="false"
  >
    <template slot="singleLabel" slot-scope="{ option }">
      <strong>{{ option.display }}</strong> selected
    </template>
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Dropdown",
  components: { Multiselect },
  props: {
    filter: {
      type: String,
      default: "value"
    },
    feature: {
      type: String,
      default: "value"
    },
    display: {
      type: String,
      default: "display"
    },
    supplementalText: {
      type: String,
      default: "supplementalText"
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      value: this.selected()
    };
  },
  watch: {
    value() {
      let accessor =
        "filters/set" +
        this.filter.charAt(0).toUpperCase() +
        this.filter.slice(1);
      this.$store.commit(accessor, this.computedValue);
      this.$root.$emit("FILTER_UPDATED");
    }
  },
  computed: {
    computedValue() {
      let value = [];
      if (this.value) {
        value = { [this.filter]: this.value.value };
      }
      return value;
    }
  },
  methods: {
    selected() {
      const selected = this.options.find(option => {
        let storedValue = this.$store.getters["filters/" + this.filter];
        let value = undefined;
        if (storedValue) {
          value = storedValue[this.filter];
        }
        return option.value === value;
      });
      return selected;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
