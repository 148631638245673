<template>
  <div class="border-light">
    <v-row class="ma-0 pa-0" v-show="this.summaryData.hasOwnProperty('summary') && summaryIsReady">
      <v-col class="content pa-0">
        <v-card elevation="0">
          <v-card-title>Overall Summary<v-spacer></v-spacer><custom-icon name='powered-by-open-ai'></custom-icon></v-card-title>
          <v-card-text class="body-1 text--primary">{{this.summaryData.summary}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-show="this.summaryData.hasOwnProperty('agent') && summaryIsReady">
      <v-col class="content pa-0">
        <v-card elevation="0">
          <v-card-title>Agent Summary</v-card-title>
          <v-card-text class="body-1 text--primary">{{this.summaryData.agent}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-show="this.summaryData.hasOwnProperty('customer') && summaryIsReady">
      <v-col class="content pa-0">
        <v-card elevation="0">
          <v-card-title>Customer Summary</v-card-title>
          <v-card-text class="body-1 text--primary">{{this.summaryData.customer}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "Transcript",
  props: {
    summaryIsLoading: {
      type: Boolean,
    },
    summaryIsReady: {
      type: Boolean,
    },
    summaryData: {
      type: Object,
    }
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>

  .border-light {
    border: 1px solid rgba(0,0,0,.3);
  }

</style>
