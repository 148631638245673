<template>
    <v-content>
      <v-container fill-height>
          <v-row justify="center">
            <v-container fill-height>
              <v-row justify="center">
                <div class="display-4 font-weight-black">
                  403
                </div>
              </v-row>
              <v-row justify="center">
                <div class="display-2">
                  Forbidden
                </div>
              </v-row>
            </v-container>
          </v-row>
          <v-row justify="center">
            <div class="title text-center">
              You are not permitted to access the page or resource.<br/>
              Your permissions may need to be adjusted; contact your administrator.
            </div>
          </v-row>
          <v-row justify="center">
            <div class="font-weight-light">
              <a href="/">Go Back to the Homepage</a>
            </div>
          </v-row>
      </v-container>
    </v-content>
</template>

<script>
export default {};
</script>
