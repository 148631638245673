<template>
  <fieldset>
    <legend> <setup-tip :section="'access_selections'"> </setup-tip> Access Role User Selections</legend>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="selectableUsers"
        :search="search"
        @item-selected="selectUser"
        @toggle-select-all="selectAll"
        :hide-default-footer="true"
        item-key="id"
        show-select
        height="30vh"
        disable-pagination
        class="dl-dt"
        :loading="userDataListLoadingStatus == 'loading'|| accessRolesListLoadingStatus!='loaded' || accessRoleDataLoadingStatus!='loaded'"
        loading-text="Loading... Please wait"
        dense
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            single-line
            hide-details
            clearable
            append-icon="mdi-magnify"
          ></v-text-field>
        </template>
      </v-data-table>
  </fieldset>
</template>

<script>
import { mapGetters } from "vuex";
import SetupTip from "@/components/SetupTip.vue";


export default {
  name: "access-role-definition",
  components: {
      SetupTip
    },
  data() {
    return {
      selected: [],
      selectableUsers: [],
      mySelection: [],
      search: "",
      headers: [
        {
          text: "Selectable Users",
          align: "start",
          sortable: false,
          value: "name"
        }
      ]
    };
  },
  props: {
    accessRoleId: Number,
    userInfo: Array,
    cancelEdits: Boolean
  },
  computed: {
    ...mapGetters("accessRoles", [
      "accessRoleData",
      "accessRoleDataLoadingStatus",
      "accessRoleDataLoadingError",
      "accessRoleDataLoadingErrorStatus",
      "accessRolesListLoadingStatus"
    ]),
    ...mapGetters("users", [
      "userDataListLoadingStatus",
      "userDataListLoadingError",
      "userDataListLoadingErrorStatus",
      "userDataList"
    ])
  },
  methods: {
    selectUser(value) {
      this.$emit("updateInfo");
    },
    selectAll() {
      this.$emit("updateInfo");
    },
    resetInfo() {
      this.selected = [];
      this.selectableUsers = [];
      this.loadSelections()
      this.$emit("updatesCancelled", false);
    },
    setInfo() {
      this.$emit("setInfo");
    },
    updateUsers() {
      this.$emit("updateUserSelections", this.selected);
    },
    getAlphaSortSelectedUnselected(options) {
      // filter dupe user ids
      options = [...new Map(options.map(item => [item['id'], item])).values()];
      let selected = options
        .filter(d => this.userInfo.some(s => d.id == s.user_id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      let unselected = options
        .filter(d => !this.userInfo.some(s => d.id == s.user_id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
      return Array(...selected, ...unselected);
    },
    loadSelections() {
      let options = [];
      this.mySelection = this.userInfo;

      options = this.userDataList.map(obj => {
        return {
          name: obj.firstname + " " + obj.lastname + " (" + obj.userid + ")",
          firstname: obj.firstname,
          lastname: obj.lastname,
          id: obj.userid
        };
      });
      this.selectableUsers = this.getAlphaSortSelectedUnselected(options);
      this.selected = this.selectableUsers.filter(d =>
        this.mySelection.some(s => d.id == s.user_id)
      );
    }
  },
  updated() {},
  mounted() {
    this.loadSelections();
  },
  watch: {
    cancelEdits: "resetInfo",
    selected: "updateUsers",
    accessRoleId: "loadSelections",
    userDataListLoadingStatus: "loadSelections"
  }
};
</script>

<style scoped>

fieldset {
  -moz-border-radius: .2rem;
  border-radius: .2rem;
  -webkit-border-radius: .2rem;
  border: 1px solid #c2c2c2;
  padding: .4rem;
}

legend {
  margin-left: .4rem;
  padding-left: .3rem;
  padding-right: .3rem;
  font-size: .8rem;
  color: #bababa;
}

</style>
