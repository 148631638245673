<template>
  <v-col class="text-center">
    <h2 class="ma-0 align-center justify-center">{{ chartTitle }}</h2>
    <v-row>
      <v-col cols="12">
        <echart :option="eChartOptions" autoresize :style="{ 'height': height - 60 + 'px' }" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

import date from "@/mixins/date";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  mixins: [date],
  props: {
    chartType: String,
    insight: Object,
    height: Number,
    width: Number,
  },
  data: () => ({
    model: 0,
    totalDocumentCount: 0,
    negativeSentimentCount: 0,
    strongNegativeSentimentCount: 0,
    positiveSentimentCount: 0,
    strongPositiveSentimentCount: 0,
    neutralSentimentCount: 0,
    type: "sentiment",
    unit: "day",
    hasTranscriptsAccess: 'authorizing',
    transcriptDataStatus: 'loading',
    accountSelections: [],
  }),
  methods: {
    // Get all relevant filters from vuex and merge into one object.
    // Yes, this should all be handled in vuex
    getParams() {
      let params = {
        grouping: this.getUnit,
        start_date: moment(this.start_date).format(),
        end_date: moment(this.end_date).format()
      }
      if (this.accounts != null) {
        params.accounts = this.accounts
      }
      if (this.agents != null) {
        params.agents = this.agents
      }
      return params;
    },
  },
  computed: {

    ...mapGetters('users', [
      'userData',
      'userDataLoadingStatus',
      'userDataLoadingError',
      'userDataLoadingErrorStatus',
    ]),

    ...mapGetters('dateRange', [
      'start_date',
      'end_date',
      'date_range_english',
    ]),

    ...mapGetters('filters', [
      'accounts',
      'agents',
    ]),

    eChartOptions() {
      let myThis = this
      return {
        legend: {},
        color: ['#C87B6B', '#949494', '#8CC63F'],
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let tooltipLegend = ''
            let val = params[0].name
            switch(myThis.getUnit) {
              case 'hour': {
                let dateParts = val.split(' ')
                let day = dateParts[0]
                let hour = parseInt(dateParts[1])
                tooltipLegend = moment(day).format("MMMM D") + ' '
                if (hour == 0) tooltipLegend += 'Midnight'
                else tooltipLegend += (hour == 12) ? '12pm' : (hour > 12) ? hour - 12 + 'pm' : hour + 'am'
                break;
              }
              case 'day':
              default:
                tooltipLegend = val.split(' ')[0]
            }
            let html = `
<table class='custom-tooltip'>
    <thead>
        <tr>
            <th colspan="3" style="text-align: left;">${tooltipLegend}</th>
        </tr>
    </thead>
    <tbody>
`
           params.forEach((o)=>{
              html += `
        <tr>
          <td style="text-align: left; font-weight: 700; color: ${o.color};">${o.seriesName}</td>
          <td>&nbsp;</td>
          <td style="text-align: right;">${Math.round(o.value*100)}%</td>
        </tr>
`
            })
            html += `    </tbody>
</table>
            `;

            return html
          }
        },
        xAxis: this.xAxis,
        yAxis: [
          {
            name: 'Percent of Calls',
            min: 0,
            max: 1,
            nameTextStyle: {
              fontWeight: 'bold'
            },
            type: 'value',
            axisLabel: {
              formatter: function (value) {
                return Math.round(value * 100) + '%'
              }
            }
          }
        ],
        series: this.eChartSeries
      }
    },

    eChartSeries() {
      // Guard against no data in response
      if (this.insight.statistics.length === 0) {
        return [{'name':'', 'data':[]}];
      }
      const series = []
      const seriesLabels = ["Negative", "Neutral", "Positive"];
      const allData = this.insight.statistics
      for (let seriesLabel of seriesLabels) {
        let observations = {
          name: seriesLabel,
          lineStyle: {
            width: 4
          },
          symbolSize: 8,
          type: 'line',
          smooth: 0.5,
          areaStyle: { opacity: .5 },
          emphasis: {
            focus: 'series'
          },
          data: []
        }
        Object.entries(allData).forEach(entry => {
          let rowData = entry[1];
          let val = 0
          if(rowData.hasOwnProperty('subjectiveStats')) {
            val = (
              (
                Number(rowData.subjectiveStats['sentiment_' + this.scopeWord.toLowerCase() + '_first_half_negative_second_half_' + seriesLabel.toLowerCase()]) +
                Number(rowData.subjectiveStats['sentiment_' + this.scopeWord.toLowerCase() + '_first_half_neutral_second_half_' + seriesLabel.toLowerCase()]) +
                Number(rowData.subjectiveStats['sentiment_' + this.scopeWord.toLowerCase() + '_first_half_positive_second_half_' + seriesLabel.toLowerCase()])
              )
              / rowData.totalCalls)
          }
          observations.data.push(val)
        })
        series.push(observations)
      }
      return series
    },

    xAxis() {
      let xAxis = [{
        type: 'category',
        boundaryGap: false,
        data: this.insight.statistics.map(o => o.datetime),
        axisLabel: this.xAxisLabelFormatter,
        name: 'Sentiment By ' + this.getUnit.titleCase(),
        nameLocation: 'center',
        nameGap: 40,
        nameTextStyle: {
          fontWeight: 'bold'
        }
      }]
      return xAxis
    },

    xAxisLabelFormatter() {
      let formatter = {}
      switch(this.getUnit) {
        case 'hour':
          formatter = {
            'formatter':
              value => {
                if (value === undefined) return null
                let dateParts = value.split(' ')
                let day = dateParts[0]
                let hour = parseInt(dateParts[1])
                let hourFormatted = (hour == 12) ? '12 pm' : (hour > 12) ? hour - 12 + ' pm' : hour + ' am'
                if (hour == '00') return moment(day).format("MMM D")
                else return hourFormatted
              },
            'rotateAlways': true,
            'rotate': 45,
          }
          break;
        case 'day':
        default:
          formatter = {
            'formatter':
              value => {
                if (value) return value.split(' ')[0]
              },
          }
      }
      return formatter
    },

    scopeWord() {
      return this.chartType
    },

    getUnit() {
      return (moment(this.end_date).diff(moment(this.start_date),'days')>1) ? 'day' : 'hour'
    },

    chartTitle() {
      return this.scopeWord + " Ending Sentiment"
    },

  },
  mounted() {
  },
  watch: {
  },
}
</script>

<style scoped>

</style>
