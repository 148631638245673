<template>
  <v-container
    v-if="insightIsProcessing"
    :class="paddingTop"
    pb-0
    fluid
    pr-12
    pl-12
  >
    <v-row no-gutters>
      <v-col>
        <v-alert :type="'warning'" height="75%">
          {{ insightProcessingMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    type: String
  },
  data: () => ({
    insightProcessingMessage: process.env.VUE_APP_INSIGHT_PROCESSING_MESSAGE
  }),
  methods: {
    agentLevelCheck() {
      return (
        this.insightsDynaLabelsAgentsTeamDataLoadingStatus == "loaded" &&
        !this.insightsDynaLabelsAgentsTeamDataLoadingError &&
        this.insightsDynaLabelsAgentsTeamData.length>0 &&
        this.insightsDynaLabelsAgentsTeamData[0].hasOwnProperty('processing') &&
        this.insightsDynaLabelsAgentsTeamData[0].processing > 0
      );
    },
    dynalabelLevelCheck() {
      return (
        this.insightsAggregatedDynaLabelDataLoadingStatus == "loaded" &&
        !this.insightsAggregatedDynaLabelDataLoadingError &&
        this.insightsAggregatedDynaLabelData.length > 0 &&
        this.insightsAggregatedDynaLabelData[0].hasOwnProperty('processing') &&
        this.insightsAggregatedDynaLabelData[0].processing > 0
      );
    },

  },
  computed: {
    ...mapGetters("insights", [
      "insightsDynaLabelsAgentsTeamData",
      "insightsDynaLabelsAgentsTeamDataLoadingStatus",
      "insightsDynaLabelsAgentsTeamDataLoadingErrorStatus",
      "insightsDynaLabelsAgentsTeamDataLoadingError",

      "insightsAggregatedDynaLabelDataLoadingErrorStatus",
      "insightsAggregatedDynaLabelDataLoadingError",
      "insightsAggregatedDynaLabelDataLoadingStatus",
      "insightsAggregatedDynaLabelData",

    ]),
    paddingTop() {
      return this.type == "call" ? "pt-4" : "pt-8";
    },
    insightIsProcessing() {
      switch (this.type) {
        case "agent":
          return this.agentLevelCheck();
        case "dynalabel":
          return this.dynalabelLevelCheck();
        default:
          return this.agentLevelCheck();
      }
    }
  }
};
</script>

<style>
.big-type {
  font-size: 5rem !important;
}
</style>
