import axios from "@/utils/AxiosInstance.js";
import CacheHandler from "@/utils/CacheHandler";
import router from "../../router";

export const insights = {
  namespaced: true,
  state: {

    insightsAdHocSearchPath: '',
    favoriteInsightDefinitionId: 0,
    lastChartSelected: 0,
    metricSelected: 'Snapshot',
    agentDynalabelData: {},

    // agent data
    insightsAgentSnapshotDataLoadingErrorStatus: false,
    insightsAgentSnapshotDataLoadingError: "",
    insightsAgentSnapshotDataLoadingStatus: "notLoading",
    insightsAgentSnapshotData: [],
    insightsTeamSnapshotData: [],

    // insight data
    insightsListDataLoadingErrorStatus: false,
    insightsListDataLoadingError: "",
    insightsListDataLoadingStatus: "notLoading",
    insightsListData: [],

    // call data
    insightsCallDataLoadingErrorStatus: false,
    insightsCallDataLoadingError: "",
    insightsCallDataLoadingStatus: "notLoading",
    insightsCallData: [],

    // dynalabel team data
    insightsDynaLabelsAgentsTeamDataLoadingErrorStatus: false,
    insightsDynaLabelsAgentsTeamDataLoadingError: "",
    insightsDynaLabelsAgentsTeamDataLoadingStatus: "notLoading",
    insightsDynaLabelsAgentsTeamData: [],

    // dynalabel team data
    insightsDynaLabelsAgentsAgentDataLoadingErrorStatus: false,
    insightsDynaLabelsAgentsAgentDataLoadingError: "",
    insightsDynaLabelsAgentsAgentDataLoadingStatus: "notLoading",
    insightsDynaLabelsAgentsAgentData: [],

    insightsAggregatedDynaLabelDataLoadingErrorStatus: false,
    insightsAggregatedDynaLabelDataLoadingError: "",
    insightsAggregatedDynaLabelDataLoadingStatus: "notLoading",
    insightsAggregatedDynaLabelData: [],

    insightsGroupedDynaLabelDataLoadingErrorStatus: false,
    insightsGroupedDynaLabelDataLoadingError: "",
    insightsGroupedDynaLabelDataLoadingStatus: "notLoading",
    insightsGroupedDynaLabelData: [],

    // agent ids
    insightsAgentIdsLoadingErrorStatus: false,
    insightsAgentIdsLoadingError: "",
    insightsAgentIdsLoadingStatus: "notLoading",
    insightsAgentIds: [],

    insightsAgentCallDataLoadingErrorStatus: false,
    insightsAgentCallDataLoadingError: "",
    insightsAgentCallDataLoadingStatus: "notLoading",
    insightsAgentCallData: [],
    insightsAgentCallDataUrl: "",

    insightsDefinitionsListLoadingErrorStatus: false,
    insightsDefinitionsListLoadingError: "",
    insightsDefinitionsListLoadingStatus: "notLoading",
    insightsDefinitionsList: [],

    insightsDefinitionsListItemDataLoadingErrorStatus: false,
    insightsDefinitionsListItemDataLoadingError: "",
    insightsDefinitionsListItemDataLoadingStatus: "notLoading",
    insightsDefinitionsListItemData: {},

    insightDefinitionNewIdLoadingErrorStatus: false,
    insightDefinitionNewIdLoadingError: "",
    insightDefinitionNewIdLoadingStatus: "notLoading",
    insightDefinitionNewId: {},

    insightCallLogSkeleton: {},
    insightCallLogSkeletonLoadingStatus: "notLoading",
    insightCallLogSkeletonLoadingError: "",
    insightCallLogSkeletonLoadingErrorStatus: false,

    insightCallContext: {},
    insightCallContextLoadingStatus: "notLoading",
    insightCallContextLoadingError: "",
    insightCallContextLoadingErrorStatus: false,

    insightLoadingItem: null,
    insightsLoadingItemErrorStatus: "",

    displayFormats: [],

    insightProcessingStatus: 0,

  },
  getters: {
    displayFormats: state => state.displayFormats,

    insightProcessingStatus: state => state.insightProcessingStatus,

    favoriteInsightDefinitionId: state => state.favoriteInsightDefinitionId,
    lastChartSelected: state => state.lastChartSelected,
    insightsAdHocSearchPath: state => state.insightsAdHocSearchPath,
    metricSelected: state => state.metricSelected,
    agentDynalabelData: state => state.agentDynalabelData,
    insightLoadingItem: state => state.insightLoadingItem,
    insightsLoadingItemErrorStatus: state => state.insightsLoadingItemErrorStatus,

    insightsAgentSnapshotDataLoadingErrorStatus: state => state.insightsAgentSnapshotDataLoadingErrorStatus,
    insightsAgentSnapshotDataLoadingError: state => state.insightsAgentSnapshotDataLoadingError,
    insightsAgentSnapshotDataLoadingStatus: state => state.insightsAgentSnapshotDataLoadingStatus,
    insightsAgentSnapshotData: state => state.insightsAgentSnapshotData,
    insightsTeamSnapshotData: state => state.insightsTeamSnapshotData,

    insightsAgentCallDataLoadingErrorStatus: state => state.insightsAgentCallDataLoadingErrorStatus,
    insightsAgentCallDataLoadingError: state => state.insightsAgentCallDataLoadingError,
    insightsAgentCallDataLoadingStatus: state => state.insightsAgentCallDataLoadingStatus,
    insightsAgentCallData: state => state.insightsAgentCallData,
    insightsAgentCallDataUrl: state => state.insightsAgentCallDataUrl,

    insightsCallDataLoadingErrorStatus: state => state.insightsCallDataLoadingErrorStatus,
    insightsCallDataLoadingError: state => state.insightsCallDataLoadingError,
    insightsCallDataLoadingStatus: state => state.insightsCallDataLoadingStatus,
    insightsCallData: state => state.insightsCallData,

    insightsDynaLabelsAgentsTeamDataLoadingErrorStatus: state => state.insightsDynaLabelsAgentsTeamDataLoadingErrorStatus,
    insightsDynaLabelsAgentsTeamDataLoadingError: state => state.insightsDynaLabelsAgentsTeamDataLoadingError,
    insightsDynaLabelsAgentsTeamDataLoadingStatus: state => state.insightsDynaLabelsAgentsTeamDataLoadingStatus,
    insightsDynaLabelsAgentsTeamData: state => state.insightsDynaLabelsAgentsTeamData,

    insightsDynaLabelsAgentsAgentDataLoadingErrorStatus: state => state.insightsDynaLabelsAgentsAgentDataLoadingErrorStatus,
    insightsDynaLabelsAgentsAgentDataLoadingError: state => state.insightsDynaLabelsAgentsAgentDataLoadingError,
    insightsDynaLabelsAgentsAgentDataLoadingStatus: state => state.insightsDynaLabelsAgentsAgentDataLoadingStatus,
    insightsDynaLabelsAgentsAgentData: state => state.insightsDynaLabelsAgentsAgentData,

    insightsAggregatedDynaLabelDataLoadingErrorStatus: state => state.insightsAggregatedDynaLabelDataLoadingErrorStatus,
    insightsAggregatedDynaLabelDataLoadingError: state => state.insightsAggregatedDynaLabelDataLoadingError,
    insightsAggregatedDynaLabelDataLoadingStatus: state => state.insightsAggregatedDynaLabelDataLoadingStatus,
    insightsAggregatedDynaLabelData: state => state.insightsAggregatedDynaLabelData,

    insightsGroupedDynaLabelDataLoadingErrorStatus: state => state.insightsGroupedDynaLabelDataLoadingErrorStatus,
    insightsGroupedDynaLabelDataLoadingError: state => state.insightsGroupedDynaLabelDataLoadingError,
    insightsGroupedDynaLabelDataLoadingStatus: state => state.insightsGroupedDynaLabelDataLoadingStatus,
    insightsGroupedDynaLabelData: state => state.insightsGroupedDynaLabelData,

    insightsAgentIdsLoadingErrorStatus: state => state.insightsAgentIdsLoadingErrorStatus,
    insightsAgentIdsLoadingError: state => state.insightsAgentIdsLoadingError,
    insightsAgentIdsLoadingStatus: state => state.insightsAgentIdsLoadingStatus,
    insightsAgentIds: state => state.insightsAgentIds,

    insightsDefinitionsListLoadingErrorStatus: state => state.insightsDefinitionsListLoadingErrorStatus,
    insightsDefinitionsListLoadingError: state => state.insightsDefinitionsListLoadingError,
    insightsDefinitionsListLoadingStatus: state => state.insightsDefinitionsListLoadingStatus,
    insightsDefinitionsList: state => state.insightsDefinitionsList,

    insightsDefinitionsListItemDataLoadingErrorStatus: state => state.insightsDefinitionsListItemDataLoadingErrorStatus,
    insightsDefinitionsListItemDataLoadingError: state => state.insightsDefinitionsListItemDataLoadingError,
    insightsDefinitionsListItemDataLoadingStatus: state => state.insightsDefinitionsListItemDataLoadingStatus,
    insightsDefinitionsListItemData: state => state.insightsDefinitionsListItemData,

    insightDefinitionNewId: state => state.insightDefinitionNewId,
    insightDefinitionNewIdLoadingStatus: state => state.insightDefinitionNewIdLoadingStatus,
    insightDefinitionNewIdLoadingError: state => state.insightDefinitionNewIdLoadingError,
    insightDefinitionNewIdLoadingErrorStatus: state => state.insightDefinitionNewIdLoadingErrorStatus,

    insightsListDataLoadingErrorStatus: state => state.insightsListDataLoadingErrorStatus,
    insightsListDataLoadingError: state => state.insightsListDataLoadingError,
    insightsListDataLoadingStatus: state => state.insightsListDataLoadingStatus,
    insightsListData: state => state.insightsListData,

    insightCallLogSkeletonLoadingErrorStatus: state => state.insightCallLogSkeletonLoadingErrorStatus,
    insightCallLogSkeletonLoadingError: state => state.insightCallLogSkeletonLoadingError,
    insightCallLogSkeletonLoadingStatus: state => state.insightCallLogSkeletonLoadingStatus,
    insightCallLogSkeleton: state => state.insightCallLogSkeleton,

    insightCallContextLoadingErrorStatus: state => state.insightCallContextLoadingErrorStatus,
    insightCallContextLoadingError: state => state.insightCallContextLoadingError,
    insightCallContextLoadingStatus: state => state.insightCallContextLoadingStatus,
    insightCallContext: state => state.insightCallContext,

  },
  mutations: {

    SET_INSIGHT_PROCESSING_STATUS(state, val) {
      state.insightProcessingStatus = val
    },
    SET_DISPLAY_FORMATS(state, displayFormats) {
      state.displayFormats = displayFormats
    },
    UPDATE_CALL_NOTE(state, payload) {
      if(typeof state.insightsAgentCallData.find === "function") {
        state.insightsAgentCallData.find(call => {
          if (call.id == payload.call_id) {
            Object.assign(call, {'note_id': payload.noteId})
            Object.assign(call, {'note_is_blank': payload.note_is_blank})
          }
        })
      }
    },
    UPDATE_CALL_HAS_TAGS(state, payload) {
      if(typeof state.insightsAgentCallData.find === "function") {
        state.insightsAgentCallData.find(call => {
          if (call.id == payload.call_id) {
            Object.assign(call, {'has_tags': payload.has_tags})
          }
        })
      }
    },

    SET_FAVORITE_INSIGHT_DEFINITION(state, id) {
      state.favoriteInsightDefinitionId = id;
    },
    SET_LAST_CHART_SELECTED(state, id) {
      state.lastChartSelected = id;
    },
    SET_INSIGHT_AD_HOC_SEARCH_PATH(state, val) {
      state.insightsAdHocSearchPath = val
    },
    SET_METRIC_SELECTED(state, metric) {
      state.metricSelected = metric;
    },
    SET_AGENT_DYNALABEL_DATA(state, agentDynalabelData) {
      state.agentDynalabelData = agentDynalabelData;
    },
    SET_INSIGHT_LOADING_ITEM(state, item) {
      state.insightLoadingItem = item;
    },
    SET_INSIGHTS_ITEM_DATA_ERROR_STATUS(state, status) {
      state.insightsLoadingItemErrorStatus = status
    },

    SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsAgentSnapshotDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_ERROR(state, error) {
      state.insightsAgentSnapshotDataLoadingError = error;
    },
    SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_STATUS(state, status) {
      state.insightsAgentSnapshotDataLoadingStatus = status;
    },
    SET_INSIGHTS_AGENT_SNAPSHOT_DATA(state, data) {
      state.insightsAgentSnapshotData = data;
    },
    SET_INSIGHTS_TEAM_SNAPSHOT_DATA(state, data) {
      state.insightsTeamSnapshotData = data;
    },


    SET_INSIGHTS_AGENT_CALL_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsAgentCallDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_AGENT_CALL_DATA_LOADING_ERROR(state, error) {
      state.insightsAgentCallDataLoadingError = error;
    },
    SET_INSIGHTS_AGENT_CALL_DATA_LOADING_STATUS(state, status) {
      state.insightsAgentCallDataLoadingStatus = status;
    },
    SET_INSIGHTS_AGENT_CALL_DATA(state, data) {
      state.insightsAgentCallData = data;
    },
    SET_INSIGHTS_AGENT_CALL_DATA_URL(state, data) {
      state.insightsAgentCallDataUrl = data;
    },


    SET_INSIGHTS_LIST_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsListDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_LIST_DATA_LOADING_ERROR(state, error) {
      state.insightsListDataLoadingError = error;
    },
    SET_INSIGHTS_LIST_DATA_LOADING_STATUS(state, status) {
      state.insightsListDataLoadingStatus = status;
    },
    SET_INSIGHTS_LIST_DATA(state, insightsListData) {
      state.insightsListData = insightsListData;
    },


    SET_INSIGHTS_CALL_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsCallDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_CALL_DATA_LOADING_ERROR(state, error) {
      state.insightsCallDataLoadingError = error;
    },
    SET_INSIGHTS_CALL_DATA_LOADING_STATUS(state, status) {
      state.insightsCallDataLoadingStatus = status;
    },
    SET_INSIGHTS_CALL_DATA(state, insightsCallData) {
      state.insightsCallData[insightsCallData.id] = insightsCallData;
    },

    SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsDynaLabelsAgentsTeamDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_ERROR(state, error) {
      state.insightsDynaLabelsAgentsTeamDataLoadingError = error;
    },
    SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_STATUS(state, status) {
      state.insightsDynaLabelsAgentsTeamDataLoadingStatus = status;
    },
    SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA(state, insightsDynaLabelsAgentsTeamData) {
      state.insightsDynaLabelsAgentsTeamData = insightsDynaLabelsAgentsTeamData
    },

    SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsDynaLabelsAgentsAgentDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_ERROR(state, error) {
      state.insightsDynaLabelsAgentsAgentDataLoadingError = error;
    },
    SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_STATUS(state, status) {
      state.insightsDynaLabelsAgentsAgentDataLoadingStatus = status;
    },
    SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA(state, insightsDynaLabelsAgentsAgentData) {
      state.insightsDynaLabelsAgentsAgentData = insightsDynaLabelsAgentsAgentData
    },

    SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsAggregatedDynaLabelDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR(state, error) {
      state.insightsAgentIdsLoadingError = error;
    },
    SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS(state, status) {
      state.insightsAggregatedDynaLabelDataLoadingStatus = status;
    },
    SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA(state, insightsAggregatedDynaLabelData) {
      state.insightsAggregatedDynaLabelData = insightsAggregatedDynaLabelData
    },

    SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsGroupedDynaLabelDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_ERROR(state, error) {
      state.insightsGroupedDynaLabelDataLoadingError = error;
    },
    SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_STATUS(state, status) {
      state.insightsGroupedDynaLabelDataLoadingStatus = status;
    },
    SET_INSIGHTS_GROUPED_DYNALABEL_DATA(state, insightsGroupedDynaLabelData) {
      state.insightsGroupedDynaLabelData = insightsGroupedDynaLabelData
    },

    SET_INSIGHTS_AGENT_IDS_LOADING_ERROR_STATUS(state, status) {
      state.insightsAgentIdsLoadingErrorStatus = status;
    },
    SET_INSIGHTS_AGENT_IDS_LOADING_ERROR(state, error) {
      state.insightsAgentIdsLoadingError = error;
    },
    SET_INSIGHTS_AGENT_IDS_LOADING_STATUS(state, status) {
      state.insightsAgentIdsLoadingStatus = status;
    },
    SET_INSIGHTS_AGENT_IDS(state, insightsAgentIds) {
      state.insightsAgentIds = insightsAgentIds
    },

    SET_INSIGHTS_DEFINITIONS_LIST_LOADING_ERROR_STATUS(state, status) {
      state.insightsDefinitionsListLoadingErrorStatus = status;
    },
    SET_INSIGHTS_DEFINITIONS_LIST_LOADING_ERROR(state, error) {
      state.insightsDefinitionsListLoadingError = error;
    },
    SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS(state, status) {
      state.insightsDefinitionsListLoadingStatus = status;
    },
    SET_INSIGHTS_DEFINITIONS_LIST(state, insightsDefinitionsList) {
      state.insightsDefinitionsList = insightsDefinitionsList;
    },

    SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_ERROR_STATUS(state, status) {
      state.insightsDefinitionsListItemDataLoadingErrorStatus = status;
    },
    SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_ERROR(state, error) {
      state.insightsDefinitionsListItemDataLoadingError = error;
    },
    SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_STATUS(state, status) {
      state.insightsDefinitionsListItemDataLoadingStatus = status;
    },
    SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA(state, insightsDefinitionsListItemData) {
      state.insightsDefinitionsListItemData = insightsDefinitionsListItemData;
    },

    SET_INSIGHT_DEFINITION_NEW_ID_LOADING_ERROR_STATUS(state, status) {
      state.insightDefinitionNewIdLoadingErrorStatus = status;
    },
    SET_INSIGHT_DEFINITION_NEW_ID_LOADING_ERROR(state, error) {
      state.insightDefinitionNewIdLoadingError = error;
    },
    SET_INSIGHT_DEFINITION_NEW_ID_LOADING_STATUS(state, status) {
      state.insightDefinitionNewIdLoadingStatus = status;
    },
    SET_INSIGHT_DEFINITION_NEW_ID(state, insightDefinitionNewId) {
      state.insightDefinitionNewId = insightDefinitionNewId;
    },

    SET_INSIGHT_CALL_LOG_SKELETON(state, skeleton) {
      state.insightCallLogSkeleton = skeleton;
    },
    SET_INSIGHT_CALL_LOG_SKELETON_LOADING_STATUS(state, status) {
      state.insightCallLogSkeletonLoadingStatus = status;
    },
    SET_INSIGHT_CALL_LOG_SKELETON_LOADING_ERROR(state, error) {
      state.insightCallLogSkeletonLoadingError = error;
    },
    SET_INSIGHT_CALL_LOG_SKELETON_LOADING_ERROR_STATUS(state, status) {
      state.insightCallLogSkeletonLoadingErrorStatus = status;
    },

    SET_INSIGHT_CALL_CONTEXT(state, context) {
      state.insightCallContext = context;
    },
    SET_INSIGHT_CALL_CONTEXT_LOADING_STATUS(state, status) {
      state.insightCallContextLoadingStatus = status;
    },
    SET_INSIGHT_CALL_CONTEXT_LOADING_ERROR(state, error) {
      state.insightCallContextLoadingError = error;
    },
    SET_INSIGHT_CALL_CONTEXT_LOADING_ERROR_STATUS(state, status) {
      state.insightCallContextLoadingErrorStatus = status;
    },

  },
  actions: {
    setInsightsAdHocSearchPath(context, val) {
      context.commit("SET_INSIGHT_AD_HOC_SEARCH_PATH", val);
    },

    setInsightProcessingStatus(context, val) {
      context.commit("SET_INSIGHT_PROCESSING_STATUS", val);
    },

    updateCallNote(context,payload) {
      context.commit("UPDATE_CALL_NOTE", payload)
    },

    updateCallHasTags(context,payload) {
      context.commit("UPDATE_CALL_HAS_TAGS", payload)
    },

    retrieveInsightsCallData(context, params) {
      let call_id = params['call_id']
      let url = process.env.VUE_APP_INSIGHTS_CALLS
      let axios_params = {
        params: {}
      }
      if(params.selfSignedTranscript) {
        url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        axios_params['params']['route'] = 'calls'
        axios_params['headers'] = {"Authorization": params.userid + " " + params.reckey}
      }

      context.commit("SET_INSIGHTS_CALL_DATA_LOADING_STATUS", "loading");
      axios
        .get(url + call_id + '/',axios_params)
        .then(response => {
          context.commit("SET_INSIGHTS_CALL_DATA", response.data);
          context.commit("SET_INSIGHTS_CALL_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_CALL_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_CALL_DATA_LOADING_STATUS", "loaded");
          // console.log('# SUCCESS vuex loaded insights  data', response);
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_CALL_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_CALL_DATA", []);
          context.commit("SET_INSIGHTS_CALL_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_CALL_DATA_LOADING_ERROR", 'retrieveInsightsCallData(): ' + error_message);
        });

    },

    setfavoriteInsightDefinitionId(context, id) {
      let key = 'favorite_insight_definition'
      context.commit("SET_FAVORITE_INSIGHT_DEFINITION", id);
      if (CacheHandler.hasOwnProperty(key)) {
        CacheHandler.removeItem(key)
      }
      CacheHandler.setItem(key, id)
    },

    setLastChartSelected(context, id) {
      let key = 'last_chart_selected'
      context.commit("SET_LAST_CHART_SELECTED", id);
      if (CacheHandler.hasOwnProperty(key)) {
        CacheHandler.removeItem(key)
      }
      CacheHandler.setItem(key, id)
    },

    setMetricSelected(context, metric) {
      let key = 'metric_selected'
      context.commit("SET_METRIC_SELECTED", metric);
      if (CacheHandler.hasOwnProperty(key)) {
        CacheHandler.removeItem(key)
      }
      CacheHandler.setItem(key, metric)
    },

    retrieveInsightsAgentSnapshotData(context, payload) {
      let parameters = {
        start_date: payload.starts_at,
        end_date: payload.ends_at,
      };

      if (Array.isArray(payload.agent_ids) && payload.agent_ids.length > 0) Object.assign(parameters, { agent_ids: payload.agent_ids })
      if (Array.isArray(payload.account_ids) && payload.account_ids.length > 0) Object.assign(parameters, { account_ids: payload.account_ids })
      if (Array.isArray(payload.subjective) && payload.subjective.length > 0) Object.assign(parameters, { subjective: payload.subjective })
      if (payload.phrase) Object.assign(parameters, { search_phrase: payload.phrase });
      if (payload.speaker) Object.assign(parameters, { speaker: payload.speaker });

      context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA", []);
      context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_STATUS", "loading");

      axios
        .post(process.env.VUE_APP_INSIGHTS_SNAPSHOTS, parameters)
        .then(response => {
          context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA", response.data.data.agent_insights);
          context.commit("SET_INSIGHTS_TEAM_SNAPSHOT_DATA", (response.data.data.agent_insights.length > 0) ? response.data.data.team_insights : []);
          context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_STATUS", "loaded");
          // console.log('# SUCCESS vuex loaded insights agent data', response);
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA", []);
          context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_AGENT_SNAPSHOT_DATA_LOADING_ERROR", 'retrieveInsightsAgentSnapshotData(): ' + error_message);
          // console.log('# ERROR vuex loading insights agent data', error);
        });
    },

    retrieveInsightsAgentCallData(context, payload) {
      context.commit("SET_INSIGHTS_AGENT_CALL_DATA", []);
      context.commit("SET_INSIGHTS_AGENT_CALL_DATA_LOADING_STATUS", "loading");
      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at
      if (payload.order_by) {
        urlParams += "&order_by=" + payload.order_by;
        urlParams += (payload.order_direction) ? "&order_direction=" + payload.order_direction : "";
      }
      urlParams += (payload.page) ? "&page=" + payload.page : "";
      urlParams += (payload.page_size) ? "&page_size=" + payload.page_size : "";
      urlParams += (payload.search_calls) ? "&search_calls=" + payload.search_calls : "";

      if (payload.get_audit_info) urlParams += '&get_audit_info=true';

      if (payload.phrase) urlParams += '&search_phrase=' + payload.phrase;
      if (payload.speaker) urlParams += '&speaker=' + payload.speaker;
      if (Array.isArray(payload.agent_ids)) {
        if (payload.agent_ids.length > 0) {
          urlParams += '&agent_ids=' + payload.agent_ids.join(',')
        }
      }
      if (Array.isArray(payload.account_ids)) {
        if (payload.account_ids.length > 0) {
          urlParams += '&account_ids=' + payload.account_ids.join(',')
        }
      }
      if (payload.subjective) {
        payload.subjective.forEach(value => {
          urlParams += '&subjective=' + JSON.stringify(value)
        })
      }
      let path = payload.insight_id + '/dynalabels/' + payload.dynalabel_id + '/agents/' + payload.agent_id + '/calls/' + urlParams;
      axios
        .get(process.env.VUE_APP_INSIGHTS + path)
        .then(response => {
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA", response.data.call_insights);
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA_URL", process.env.VUE_APP_INSIGHTS_INTERNAL + path);
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA_URL",  "");
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA", []);
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_AGENT_CALL_DATA_LOADING_ERROR", 'An error occurred retrieving insights agent call data. Please try again.');
        });
    },

    retrieveInsightsAggregatedDynaLabelData(context, payload) {
      context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS", "loading");

      let useAggregateResult = true

      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at

      if (payload.phrase) {
        useAggregateResult = false
        urlParams += '&search_phrase=' + payload.phrase;
      }
      if (payload.speaker) {
        useAggregateResult = false
        urlParams += '&speaker=' + payload.speaker;
      }

      if (Array.isArray(payload.agent_ids)) {
        if (payload.agent_ids.length > 0) {
          useAggregateResult = false
          urlParams += '&agent_ids=' + payload.agent_ids.join(',')
        }
      }
      if (Array.isArray(payload.account_ids)) {
        if (payload.account_ids.length > 0) {
          useAggregateResult = false
          urlParams += '&account_ids=' + payload.account_ids.join(',')
        }
      }
      if (payload.subjective) {
        payload.subjective.forEach(value => {
          useAggregateResult = false
          urlParams += '&subjective=' + JSON.stringify(value)
        })
      }

      if (useAggregateResult) urlParams += "&aggregate_results=" +  useAggregateResult

      axios
        .get(process.env.VUE_APP_INSIGHTS + payload.insight_id + '/dynalabels/' + urlParams)
        .then(response => {
            let display_formats = [];
            let presentation = payload.presentation
            presentation.forEach((presented, index) => {
                if (presented.source_key.includes('dynalabel_insights[]')) {
                    let key = presented.source_key.substr(presented.source_key.indexOf('.') + 1);
                    let info = {}
                    info[key] = {
                        display: presented.display_format,
                        data_type: presented.data_type,
                    }

                    display_formats.push(info)
                }
            })
          context.commit("SET_DISPLAY_FORMATS", display_formats)
          context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA", response.data.dynalabel_insights);
          context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA", {});
          context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR", 'retrieveinsightsAggregatedDynaLabelData(): ' + error_message)
        });
    },
    retrieveInsightsGroupedDynaLabelData(context, payload) {
      context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_STATUS", "loading");
      let useAggregateResult = true
      let url = process.env.VUE_APP_INSIGHTS + payload.insight_id + '/dynalabels/'
      let axios_params = {
        params: {}
      }
      axios_params.params.start_date = payload.starts_at
      axios_params.params.end_date = payload.ends_at
      axios_params.params.group_by_dynalabel = true

      if (payload.phrase) {
        useAggregateResult = false
        axios_params.params.search_phrase = payload.phrase
      }
      if (payload.speaker) {
        useAggregateResult = false
        axios_params.params.speaker = payload.speaker
      }

      if (Array.isArray(payload.agent_ids)) {
        if (payload.agent_ids.length > 0) {
          useAggregateResult = false
          axios_params.params.agent_ids = payload.agent_ids.join(',')
        }
      }
      if (Array.isArray(payload.account_ids)) {
        if (payload.account_ids.length > 0) {
          useAggregateResult = false
          axios_params.params.account_ids = payload.account_ids.join(',')
        }
      }

      if (payload.subjective) {
        axios_params.params.subjective = ""
        payload.subjective.forEach(value => {
          useAggregateResult = false
          axios_params.params.subjective += JSON.stringify(value)
        })
      }

      if (useAggregateResult) axios_params.params.aggregate_results = useAggregateResult

      if(payload.selfSigned) {
        url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.callId
        axios_params.params.route = 'insight_dynalabel_info'
        axios_params.params.insight_id = payload.insight_id
        axios_params.headers = {"Authorization": payload.userid + " " + payload.reckey}
      }

      axios
        .get(url, axios_params)
        .then(response => {
          context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA", response.data.dynalabel_insights);
          context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA", {});
          context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_GROUPED_DYNALABEL_DATA_LOADING_ERROR", 'retrieveInsightsGroupedDynaLabelData(): ' + error_message)
        });
    },
    retrieveInsightsAgentsAgentData(context, payload) {

      context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_STATUS", "loading");

      let useAggregateResult = true

      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at
      urlParams += '&group_by_agent=true'

      if (payload.phrase) {
        useAggregateResult = false
        urlParams += '&search_phrase=' + payload.phrase;
      }
      if (payload.speaker) {
        useAggregateResult = false
        urlParams += '&speaker=' + payload.speaker;
      }

      if (Array.isArray(payload.agent_ids)) {
        if (payload.agent_ids.length > 0) {
          useAggregateResult = false
          urlParams += '&agent_ids=' + payload.agent_ids.join(',')
        }
      }
      if (Array.isArray(payload.account_ids)) {
        if (payload.account_ids.length > 0) {
          useAggregateResult = false
          urlParams += '&account_ids=' + payload.account_ids.join(',')
        }
      }
      if (payload.subjective) {
        payload.subjective.forEach(value => {
          useAggregateResult = false
          urlParams += '&subjective=' + JSON.stringify(value)
        })
      }

      if (useAggregateResult) urlParams += "&aggregate_results=" +  useAggregateResult
      axios
        .get(process.env.VUE_APP_INSIGHTS + payload.insight_id + '/dynalabels/' + payload.dynalabel_id + '/agents/' + urlParams)
        .then(response => {

          if (JSON.stringify(response.data.agent_insights[0]) === '{}') {
            context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA", []);
          } else {
            context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA", response.data.agent_insights);
          }
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA", {});
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_AGENT_DATA_LOADING_ERROR", 'retrieveInsightsAgentsAgentData(): ' + error_message)
        });
    },

    setAggregatedDynalabelLoadingStatus(context, status) {
      context.commit("SET_INSIGHTS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS", status);
    },
    setAggregatedAgentsLoadingStatus(context, status) {
      context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_STATUS", status)
    },
    retrieveInsightsAgentsTeamData(context, payload) {
      context.commit("SET_AGENT_DYNALABEL_DATA", {})
      context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_STATUS", "loading");

      let useAggregateResult = true

      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at

      if (payload.phrase) {
        useAggregateResult = false
        urlParams += '&search_phrase=' + payload.phrase;
      }
      if (payload.speaker) {
        useAggregateResult = false
        urlParams += '&speaker=' + payload.speaker;
      }

      if (Array.isArray(payload.agent_ids)) {
        if (payload.agent_ids.length > 0) {
          useAggregateResult = false
          urlParams += '&agent_ids=' + payload.agent_ids.join(',')
        }
      }
      if (Array.isArray(payload.account_ids)) {
        if (payload.account_ids.length > 0) {
          useAggregateResult = false
          urlParams += '&account_ids=' + payload.account_ids.join(',')
        }
      }
      if (payload.subjective) {
        payload.subjective.forEach(value => {
          useAggregateResult = false
          urlParams += '&subjective=' + JSON.stringify(value)
        })
      }
      if (useAggregateResult) urlParams += "&aggregate_results=" +  useAggregateResult

      axios
        .get(process.env.VUE_APP_INSIGHTS + payload.insight_id + '/dynalabels/' + payload.dynalabel_id + '/agents/' + urlParams)
        .then(response => {

          let display_formats = [];
          let presentation = payload.presentation
          presentation.forEach((presented, index) => {
            if (presented.source_key.includes('agent_insights[]')) {
                let key = presented.source_key.substr(presented.source_key.indexOf('.') + 1);
                let info = {}
                info[key] = {
                    display: presented.display_format,
                    data_type: presented.data_type,
                }

                display_formats.push(info)
            }
          })
          context.commit("SET_DISPLAY_FORMATS", display_formats)

          let callCount = response.data.agent_insights[0].call_count
          let dynalabelData = {
            dynalabelCount: response.data.agent_insights[0].dynalabel_count,
            dynalabelTitle: response.data.agent_insights[0].dynalabel_title,
            insightTitle: response.data.agent_insights[0].insight_title,
            mediaType: response.data.agent_insights[0].insight_media_type,
          }
          context.commit("SET_AGENT_DYNALABEL_DATA", dynalabelData)

          if (callCount == 0) {
            context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA", []);
          } else {
            context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA", response.data.agent_insights);
          }

          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_STATUS", "loaded");
          // console.log('# SUCCESS vuex loaded insights  data', response);
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA", {});
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_DYNALABEL_AGENTS_TEAM_DATA_LOADING_ERROR", 'retrieveInsightsAgentsTeamData(): ' + error_message)
        });
    },

    publishInsightDefinition(context, params) {
      // check if published is false
      if (!params.published) {

        axios
          .post(process.env.VUE_APP_INSIGHTS, params)
          .then(response => {

            let insightsDefinitionsList = context.getters.insightsDefinitionsList
            for (let i = 0; i < insightsDefinitionsList.length; i++) {
              if (!insightsDefinitionsList[i].published) {
                insightsDefinitionsList[i].published = true
              }
            }

            context.commit("SET_INSIGHTS_DEFINITIONS_LIST", insightsDefinitionsList)
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS", "notLoading")

            context.commit("SET_INSIGHT_DEFINITION_NEW_ID", response.data.definition_id)
            context.commit("SET_INSIGHT_DEFINITION_NEW_ID_LOADING_ERROR_STATUS", false)
            context.commit("SET_INSIGHT_DEFINITION_NEW_ID_LOADING_ERROR", "")
            context.commit("SET_INSIGHT_DEFINITION_NEW_ID_LOADING_STATUS", "loaded")


            // router.replace('/setup/insight-definitions/' + response.data.data).catch(err => { })


            context.dispatch('retrieveInsightsEditorList')
          })
          .catch(function (error) {
            context.commit("SET_INSIGHT_DEFINITION_NEW_ID_LOADING_STATUS", "failed");
            context.commit("SET_INSIGHT_DEFINITION_NEW_ID", {});
            context.commit("SET_INSIGHT_DEFINITION_NEW_ID_LOADING_ERROR_STATUS", true);
            let error_message = JSON.stringify(error)
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
            context.commit("SET_INSIGHT_DEFINITION_NEW_ID", 'publishInsightDefinition(): ' + error_message)
          });
      } else {
        // if it is, get rid of the published property and ID then do a post - create a new access role
        // else - get rid of published property and keep the id because it will be an update
        axios
          .put(process.env.VUE_APP_INSIGHTS + params.id, params)
          .then(() => {
            router.replace('/setup/insight-definitions/' + params.id).catch(err => { })
            // context.dispatch('retrieveInsightsEditorList')
          })
          .catch(function (error) {
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_STATUS", "failed");
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA", {});
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_ERROR_STATUS", true);
            let error_message = JSON.stringify(error)
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
            if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA", 'publishInsightDefinition(): ' + error_message)
          });
      }

    },
    createNewInsightDefinition(context, params) {
      let insightsDefinitionsList = context.getters.insightsDefinitionsList
      let newLabelCount = insightsDefinitionsList.filter(label => label.title.slice(0, 15) == "New Insight Definition").length
      let titleSuffix = (newLabelCount > 0) ? " (copy " + (newLabelCount + 1) + ")" : ""
      let did = -Date.now()
      let ISO = (timeStamp = did) => { return new Date(timeStamp - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T') }
      let dt = ISO()
      let insightDefinition = {
        id: params.id,
        accessroles: [],
        dynalabels: [],
        user_id: params.user_id,
        published: false,
        cancel: true,
        title: "New Insight Definition" + titleSuffix,
        description: "Description",
        min_talktime_length: null,
        max_talktime_length: null,
        media_type: 'Call Recording',
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }

      insightsDefinitionsList.push(insightDefinition)

      context.commit("SET_INSIGHTS_DEFINITIONS_LIST", insightsDefinitionsList);
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA", insightDefinition);
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_ERROR_STATUS", false);
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_ERROR", "");
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_STATUS", "loaded");

    },
    setInsightListLoadingStatus(context, status) {
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS", status)
    },
    retrieveInsightsEditorList(context) {
      if (context.getters.insightsDefinitionsListItemData != undefined) {
        if (!context.getters.insightsDefinitionsListItemData.published) {
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS", "loading")
        }
      }

      axios
        .get(process.env.VUE_APP_INSIGHTS + '?&insights_editor=true')
        .then(response => {
          let insightDefinitionList = response.data.insight_definitions
          insightDefinitionList = insightDefinitionList.map(obj => ({ ...obj, published: true, cancel: false }))
          insightDefinitionList.sort((a, b) => a.title > b.title && 1 || -1)
          let insightsStateList = this.state.insights.insightsDefinitionsList
          let unpublishedRole = false
          let cancelRole = false

          for (let i = 0; i < insightsStateList.length; i++) {
            if (!insightsStateList[i].published) {
              unpublishedRole = true
            }
            if (insightsStateList[i].cancel) {
              cancelRole = true
              insightsStateList[i].cancel = false
            }
          }
          if (!unpublishedRole || cancelRole) {
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST", insightDefinitionList);
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_ERROR_STATUS", false);
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_ERROR", "");
            context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS", "loaded");
          }
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST", {});
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST", 'retrieveInsightsEditorList(): ' + error_message)
        });
    },
    cloneInsightDefinition(context, params) {

      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_STATUS", "notLoading");
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS", "notLoading");

      axios
        .put(process.env.VUE_APP_INSIGHTS + params.id + '?action=clone', params)
        .then(response => {
          if (response !== undefined) {
            if (response.status >= 400) {
              alert('The insight definition  (' + params.id + ') was not cloned. The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
            } else {
              router.push('/setup/insight-definitions/' + response.data.data).catch(() => { })
              context.dispatch('retrieveInsightsEditorList')
            }
          } else {
            alert('The insight definition (' + params.id + ') was not cloned - probably a cross origin request denial');
          }
        })
        .catch(error => {
          // Error
          alert('The insight definition (' + params.id + ') was not cloned. The server returned an error: "' + error);
        })
        .then(function () {
          // console.log('now, do after-clone (POST) stuff here')
        })

    },
    deleteInsightDefinition(context, insight_definition_id) {

      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_STATUS", "loading")
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS", "loading")

      axios
        .delete(process.env.VUE_APP_INSIGHTS + insight_definition_id + '?insights_editor=true')
        .then(() => {
          context.dispatch('retrieveInsightsEditorList')
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST", {});
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST", 'deleteInsightDefinition(): ' + error_message)
        });
    },
    retrieveInsightsEditorListItem(context, insight_definition_id) {
      if (typeof context.getters.insightsDefinitionsListItemData.published === "undefined" || context.getters.insightsDefinitionsListItemData.published) {
      axios
        .get(process.env.VUE_APP_INSIGHTS + insight_definition_id + '?&insights_editor=true')
        .then(response => {
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA", response.data.definition);
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_STATUS", "loaded");
          // console.log('# SUCCESS vuex loaded insights agent data', response);
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA", {});
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA", 'retrieveInsightsEditorListItem(): ' + error_message)
        });
      }
    },
    retrieveInsightDefinition(context, payload) {

      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at
      urlParams += '&grouping=' + payload.grouping

      let useAggregateResult = true

      if (payload.phrase) urlParams += '&search_phrase=' + payload.phrase;
      if (payload.speaker) urlParams += '&speaker=' + payload.speaker;

      if (payload.subjective) {
        payload.subjective.forEach(value => {
          urlParams += '&subjective=' + JSON.stringify(value)
        })
        useAggregateResult = false
      }

      if (Array.isArray(payload.agent_ids)) {
        if (payload.agent_ids.length > 0) {
          useAggregateResult = false
          urlParams += '&agent_ids=' + payload.agent_ids.join(',')
        }
      }
      if (Array.isArray(payload.account_ids)) {
        if (payload.account_ids.length > 0) {
          useAggregateResult = false
          urlParams += '&account_ids=' + payload.account_ids.join(',')
        }
      }

      if (payload.grouping!='day') useAggregateResult = false
      if (useAggregateResult) urlParams += '&aggregate_results=' + useAggregateResult;

      axios
        .get(process.env.VUE_APP_INSIGHTS + payload.insight.id + urlParams)
        .then(response => {
          let localList = context.getters.insightsListData
          let itemData = response.data.insights[0]
          itemData.loading = false

          let indexOfObject = localList.findIndex(object => {
            return object.id == payload.insight.id;
          });
          if (indexOfObject !== -1) {
            localList[indexOfObject] = itemData
          }
          context.commit("SET_INSIGHT_LOADING_ITEM", itemData)
        })
        .catch(function (error) {
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_ITEM_DATA_ERROR_STATUS", 'retrieveInsightDefinition(): ' + error_message)
        })

    },
    retrieveInsightsList(context, payload) {
      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at

      if (payload.grouping) urlParams += '&grouping=' + payload.grouping
      if (payload.phrase) urlParams += '&search_phrase=' + payload.phrase;
      if (payload.speaker) urlParams += '&speaker=' + payload.speaker;

      if (payload.subjective) {
        payload.subjective.forEach(value => {
          urlParams += '&subjective=' + JSON.stringify(value)
        })
      }
      if (Array.isArray(payload.agent_ids)) {
        if (payload.agent_ids.length > 0) {
          urlParams += '&agent_ids=' + payload.agent_ids.join(',')
        }
      }
      if (Array.isArray(payload.account_ids)) {
        if (payload.account_ids.length > 0) {
          urlParams += '&account_ids=' + payload.account_ids.join(',')
        }
      }

      context.commit("SET_INSIGHTS_LIST_DATA_LOADING_STATUS", "loading");
      axios
        .get(process.env.VUE_APP_INSIGHTS + urlParams)
        .then(response => {
          let insightDefinitionList = response.data.insights
          insightDefinitionList = insightDefinitionList.map(obj => ({ ...obj, loading: true }))
          context.commit("SET_INSIGHTS_LIST_DATA", insightDefinitionList);
          context.commit("SET_INSIGHTS_LIST_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_INSIGHTS_LIST_DATA_LOADING_ERROR", "");
          context.commit("SET_INSIGHTS_LIST_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_INSIGHTS_LIST_DATA_LOADING_STATUS", "failed");
          context.commit("SET_INSIGHTS_LIST_DATA", {});
          context.commit("SET_INSIGHTS_LIST_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_INSIGHTS_LIST_DATA_LOADING_ERROR", 'retrieveInsightsEditorListItem(): ' + error_message)
        });
    },
    updateInsightDefinition(context, insightDefinition) {
      insightDefinition.cancel = true
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA", insightDefinition);
    },
    updateInsightDefinitionClone(context, insightDefinition) {
      insightDefinition.published = false
      context.commit("SET_INSIGHTS_DEFINITIONS_LIST_ITEM_DATA", insightDefinition);
    },
    retrieveCallLogSkeleton(context, payload) {
      context.commit(
        "SET_INSIGHT_CALL_LOG_SKELETON_LOADING_STATUS",
        "loading"
      );
      let useAggregateResult = true;
      let urlParams = "?";
      urlParams += "&start_date=" + payload.starts_at;
      urlParams += "&end_date=" + payload.ends_at;
      urlParams += "&skeleton=true";

      if (payload.search_calls) {
        useAggregateResult = false
        urlParams += "&search_calls=" + payload.search_calls;
      }
      if (payload.phrase) {
        useAggregateResult = false
        urlParams += '&search_phrase=' + payload.phrase;
      }
      if (payload.speaker) {
        useAggregateResult = false
        urlParams += '&speaker=' + payload.speaker;
      }
      if (Array.isArray(payload.subjective)) {
          if (payload.subjective.length > 0) {
            useAggregateResult = false
            payload.subjective.forEach(value => {
              urlParams += '&subjective=' + JSON.stringify(value)
            })
        }
      }

      if (useAggregateResult) urlParams += '&aggregate_results=' + useAggregateResult;
      axios
        .get(
          process.env.VUE_APP_INSIGHTS +
          payload.insight_id +
            "/dynalabels/" +
            payload.dynalabel_id +
            "/agents/" +
            payload.agent_id +
            "/calls/" +
            urlParams
        )
        .then(response => {
          let skeleton = response.data;
          context.commit("SET_INSIGHT_CALL_LOG_SKELETON", skeleton);
          context.commit(
            "SET_INSIGHT_CALL_LOG_SKELETON_LOADING_ERROR_STATUS",
            false
          );
          context.commit("SET_INSIGHT_CALL_LOG_SKELETON_LOADING_ERROR", "");
          context.commit(
            "SET_INSIGHT_CALL_LOG_SKELETON_LOADING_STATUS",
            "loaded"
          );
        })
        .catch(function(error) {
          context.commit("SET_INSIGHT_CALL_LOG_SKELETON", []);
          context.commit(
            "SET_INSIGHT_CALL_LOG_SKELETON_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_INSIGHT_CALL_LOG_SKELETON_LOADING_ERROR",
            " retrieveCallLogSkeleton()"
          );
          context.commit(
            "SET_INSIGHT_CALL_LOG_SKELETON_LOADING_STATUS",
            "failed"
          );
        });
    },

    updateInsightProcessingFlags(context) {

      let currentId = null
      let localList = Object.assign([], context.getters.insightsDefinitionsList)
      let serviceList = []
      let insightData = context.getters.insightsDefinitionsListItemData
      if (insightData.hasOwnProperty('id')) currentId = insightData.id
      axios
        .get(process.env.VUE_APP_INSIGHTS + '?insights_editor=true')
        .then(response => {
          serviceList = response.data.insight_definitions
          localList.forEach((item, index) => {
            let insight = serviceList.find(obj => { return obj.id == item.id })
            if (insight && insight.hasOwnProperty('processing')) {
              localList[index].processing = insight.processing
              if (item.id == currentId) {
                context.commit("SET_INSIGHT_PROCESSING_STATUS", insight.processing);
              }
            }
          })
          context.commit("SET_INSIGHTS_DEFINITIONS_LIST", localList)
        })
        .catch(function (error) { });
    },
    retrieveInsightCallContext(context, payload) {
      context.commit(
        "SET_INSIGHT_CALL_CONTEXT_LOADING_STATUS",
        "loading"
      );

      let axios_url = process.env.VUE_APP_INSIGHTS +
        payload.params.insight_id +
        "/dynalabels/" +
        payload.params.dynalabel_id +
        "/agents/" +
        payload.params.agent_id +
        "/calls/" +
        payload.params.call_id

      if(payload.selfSigned) {
        axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE + payload.params.call_id
      }

      axios
        .get(
          axios_url, payload
        )
        .then(response => {
          let data = response.data.context;
          context.commit("SET_INSIGHT_CALL_CONTEXT", data);
          context.commit(
            "SET_INSIGHT_CALL_CONTEXT_LOADING_ERROR_STATUS",
            false
          );
          context.commit("SET_INSIGHT_CALL_CONTEXT_LOADING_ERROR", "");
          context.commit(
            "SET_INSIGHT_CALL_CONTEXT_LOADING_STATUS",
            "loaded"
          );
        })
        .catch(function(error) {
          context.commit("SET_INSIGHT_CALL_CONTEXT", {});
          context.commit(
            "SET_INSIGHT_CALL_CONTEXT_LOADING_ERROR_STATUS",
            true
          );
          context.commit(
            "SET_INSIGHT_CALL_CONTEXT_LOADING_ERROR",
            " retrieveInsightCallContext()"
          );
          context.commit(
            "SET_INSIGHT_CALL_CONTEXT_LOADING_STATUS",
            "failed"
          );
        });
    }
  }
};
