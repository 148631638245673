let keyColors = [
  "#c20a36",
  "#c23a26",
  "#e26a16",
  "#e29a06",
  "#e2ba00",
  "#f4bf22",
  "#d5b800",
  "#b5b800",
  "#96b800",
  "#77b800"
];
export default {
  methods: {
    getColorValue(value) {
      // force the index to always be between 0 and 9
      let colorIndex = Math.min(Math.max(Math.ceil(value / .10) - 1, 0), 9);
      return keyColors[colorIndex < 0 ? 0 : colorIndex];
    },
  }
};
