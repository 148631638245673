<template>
  <v-card class="pt-0 mt-0 ml-4 mr-6" flat v-if="resultsCount>0||wasSearched">
    <v-card-title class="pt-0 mt-0">
      <h2>Search Results</h2>
    </v-card-title>
    <v-card-text v-if="resultsCount>0">
      <v-row class="mb-8">
        <v-col>
          <Paginator
            ref="paginatorTop"
            v-if="resultsCount>0"
            :totalItems="resultsCount"
            :maxVisiblePages="maxVisiblePages"
            :loading="loading"
            @PAGE_CHANGED="pageChanged"
          ></Paginator>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <SearchResult v-for="(result, index) in results" :key="index"
            :callId="index"
            :analysis="result.analyses"
            :callRecord="result.call_record"
            :phrases="result.phrases"
          ></SearchResult>
        </v-col>
      </v-row>
      <v-row class="mb-8" v-if="resultsCount>4">
        <v-col>
          <Paginator
            ref="paginatorBottom"
            v-if="resultsCount>0"
            :totalItems="resultsCount"
            :maxVisiblePages="maxVisiblePages"
            :loading="loading"
            @PAGE_CHANGED="pageChanged"
          ></Paginator>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else-if="wasSearched&&resultsCount===0">
      <v-row>
        <v-col>
          <p>No transcripts were found that met your search request</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SearchResult from "./Result.vue";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "SearchResults",
  components: {
    SearchResult,
    Paginator
  },
  data() {
    return {
    };
  },
  props: {
    results: Object,
    loading: {
      type: Boolean,
      default: false
    },
    resultsCount: {
      type: Number,
      default: 0
    },
    wasSearched: {
      type: Boolean,
      default: false,
    },
    maxVisiblePages: Number
  },
  methods: {
    pageChanged(page) {
      this.$refs.paginatorTop.page = page;
      this.$refs.paginatorBottom.page = page;
      this.$emit("PAGE_CHANGED", page);
    }
  },
};
</script>
