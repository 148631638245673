<template>
  <v-card flat>
      <div v-for="(hit, index) in hits" :key="index">
        <v-divider v-if="index > 0" class="ma-0"></v-divider>
        <SearchHit
          :channel="hit.channel"
          :confidenc="hit.confidence"
          :emotion="hit.emotion"
          :endsAt="hit.ends_at"
          :text="hit.phrase"
          :sentiment="hit.sentiment"
          :startsAt="hit.startsAt"
          @load-modal="$emit('load-modal')"
        >
        </SearchHit>
      </div>
  </v-card>
</template>

<script>
import SearchHit from "./Hit.vue";

export default {
  name: "SearchHits",
  components: {
    SearchHit
  },
  props: {
    hits: Array
  }
};
</script>
