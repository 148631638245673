export default {
  methods: {
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    kebabCase(str) {
      return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
          ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
          : letter;
      }).join('');
    },
    getDynaLabelSelectorColor(selector) {
      let c = ''
      let simplified_selector = selector.replace(/[0-9]/g, '')
      switch(simplified_selector) {
        case 'acctnmbr':
        case 'acd_group':
        case 'adjusteddatetime':
        case 'agent_id':
        case 'campaign':
        case 'channel_count':
        case 'direction':
        case 'disposition':
        case 'handle_time':
        case 'hold_time':
        case 'language':
        case 'skill_name':
        case 'transfer':
        case 'wrapup_time':
        case 'media_type':
          c = "rgb(147,184,92)"
          break
        case 'extra':
          c = "rgba(147,184,92,0.6)"
          break
        case 'numextra':
          c = "rgba(147,184,92,0.3)"
          break
        default:
          // 24
          c = "rgb(122,143,204)"
      }
      return c
    }
  }
};
