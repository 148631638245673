import Vue from "vue";
import App from "./App";
import router from "./router/index"
import vuetify from './plugins/vuetify'
import Loading from "vue-loading-overlay";

// echarts - load specific modules
import { use } from 'echarts/core'
import { LineChart } from 'echarts/charts'
import { BarChart } from 'echarts/charts'
import { LegendComponent, TooltipComponent, GridComponent } from 'echarts/components'
import { SVGRenderer } from 'echarts/renderers'
use([
  LegendComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  BarChart,
  SVGRenderer
])

import VueECharts from "vue-echarts"; // vue/apache echarts support
import StringPrototypes from "@/prototypes/string";
import './assets/css/dyna-labels.css';
import './assets/css/breadcrumbs.css';
import './assets/css/main.css';

import { store } from "@/store/index.js";

// suppress erroneous js error caused by component we have no control over
Vue.config.warnHandler = function(msg) {
  if (msg !== "The .native modifier for v-on is only valid on components but it was used on <div>.") {
    // eslint-disable-next-line
    console.error(msg)
  }
}

Vue.config.productionTip = false;
Vue.component("echart", VueECharts); // make a simple component name for reuse
Vue.component("CustomIcon", require("./components/Icons/CustomIcon.vue").default)

Vue.use(Loading, {
  loader: "dots",
  backgroundColor: "#ccc"
});
Vue.use(StringPrototypes);

import VCalendar from "v-calendar";
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});

// resolves 'Failed to resolve directive: ripple' bug
import { Ripple } from 'vuetify/lib/directives'
Vue.use(vuetify, {
  directives: {
    Ripple
  }
});

// Timer for the timeout in a page. The time is in milliseconds
Vue.prototype.$logoutTimer = setTimeout(() => void(0), Vue.prototype.$timeBeforeLogout);
Vue.prototype.$timeBeforeLogout = process.env.VUE_APP_LOGOUT_TIMER;

Vue.mixin({
  methods: {
    resetTimer() {
      this.stopTimer();
      this.startTimer();
    },
    stopTimer() {
      clearTimeout(Vue.prototype.$logoutTimer);
    },
    startTimer() {
      if(window.location.href != "/signin"){
        Vue.prototype.$logoutTimer = setTimeout(() => window.location.href = "signin" + "?prev_path=" + String(window.location.pathname + window.location.search + window.location.hash), Vue.prototype.$timeBeforeLogout);
      }
    },
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");


