const validateDate = function(ymd) {

  let currVal = ymd;

  if (currVal == '' || typeof ymd == "undefined")
    return false

  let rxDatePattern = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/ //Declare Regex
  let dtArray = currVal.match(rxDatePattern); // is format OK?

  if (dtArray == null)
    return false

  // Checks for mm/dd/yyyy format.
  let dtYear = dtArray[1]
  let dtMonth = dtArray[3]
  let dtDay = dtArray[5]

  // Validate the actual date
  if (dtMonth < 1 || dtMonth > 12) {
    return false
  } else if (dtDay < 1 || dtDay > 31) {
    return false
  } else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31) {
    return false
  } else if (dtMonth == 2) {
    let isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
    if (dtDay> 29 || (dtDay ==29 && !isleap)) {
      return false
    }
  }
  return true

}

export default validateDate;
