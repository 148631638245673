import axios from "@/utils/AxiosInstance.js";
import router from "../../router";

export const scorecardsV2Aggregates = {
    namespaced: true,
    state: {
        aggregatedScorecardDynalabelData: {},
        aggregatedScorecardDynalabelDataLoadingStatus: 'notLoading',
        aggregatedScorecardDynalabelDataLoadingError: '',
        aggregatedScorecardDynalabelDataLoadingErrorStatus: false,

        groupedScorecardDynalabelData: {},
        groupedScorecardDynalabelDataLoadingStatus: 'notLoading',
        groupedScorecardDynalabelDataLoadingError: '',
        groupedScorecardDynalabelDataLoadingErrorStatus: false,

        aggregatedScorecardAgentData: {},
        aggregatedScorecardAgentDataLoadingStatus: 'notLoading',
        aggregatedScorecardAgentDataLoadingError: '',
        aggregatedScorecardAgentDataLoadingErrorStatus: false,

        groupedScorecardAgentData: {},
        groupedScorecardAgentDataLoadingStatus: 'notLoading',
        groupedScorecardAgentDataLoadingError: '',
        groupedScorecardAgentDataLoadingErrorStatus: false,

        groupedDynalabelCategoryLoadCount: 0,
        aggregatedDynalabelLoadCount: 0,
        groupedAgentCategoryLoadCount: 0,
        aggregatedAgentCategoryLoadCount: 0,

        displayFormats: []
    },
    getters: {
        aggregatedScorecardDynalabelData: state => state.aggregatedScorecardDynalabelData,
        aggregatedScorecardDynalabelDataLoadingStatus: state => state.aggregatedScorecardDynalabelDataLoadingStatus,
        aggregatedScorecardDynalabelDataLoadingError: state => state.aggregatedScorecardDynalabelDataLoadingError,
        aggregatedScorecardDynalabelDataLoadingErrorStatus: state => state.aggregatedScorecardDynalabelDataLoadingErrorStatus,

        groupedDynalabelCategoryLoadCount: state => state.groupedDynalabelCategoryLoadCount,
        aggregatedDynalabelLoadCount: state => state.aggregatedDynalabelLoadCount,
        groupedAgentCategoryLoadCount: state => state.groupedAgentCategoryLoadCount,
        aggregatedAgentCategoryLoadCount: state => state.aggregatedAgentCategoryLoadCount,

        groupedScorecardDynalabelData: state => state.groupedScorecardDynalabelData,
        groupedScorecardDynalabelDataLoadingStatus: state => state.groupedScorecardDynalabelDataLoadingStatus,
        groupedScorecardDynalabelDataLoadingError: state => state.groupedScorecardDynalabelDataLoadingError,
        groupedScorecardDynalabelDataLoadingErrorStatus: state => state.groupedScorecardDynalabelDataLoadingErrorStatus,

        aggregatedScorecardAgentData: state => state.aggregatedScorecardAgentData,
        aggregatedScorecardAgentDataLoadingStatus: state => state.aggregatedScorecardAgentDataLoadingStatus,
        aggregatedScorecardAgentDataLoadingError: state => state.aggregatedScorecardAgentDataLoadingError,
        aggregatedScorecardAgentDataLoadingErrorStatus: state => state.aggregatedScorecardAgentDataLoadingErrorStatus,

        groupedScorecardAgentData: state => state.groupedScorecardAgentData,
        groupedScorecardAgentDataLoadingStatus: state => state.groupedScorecardAgentDataLoadingStatus,
        groupedScorecardAgentDataLoadingError: state => state.groupedScorecardAgentDataLoadingError,
        groupedScorecardAgentDataLoadingErrorStatus: state => state.groupedScorecardAgentDataLoadingErrorStatus,

        displayFormats: state => state.displayFormats,

    },
    mutations: {
        SET_DISPLAY_FORMATS(state, displayFormats) {
            state.displayFormats = displayFormats
        },

        SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA(state, aggregatedData) {
            state.aggregatedScorecardDynalabelData = aggregatedData
        },
        SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS(state, status) {
            state.aggregatedScorecardDynalabelDataLoadingStatus = status
        },
        SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR(state, error) {
            state.aggregatedScorecardDynalabelDataLoadingError = error
        },
        SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR_STATUS(state, status) {
            state.aggregatedScorecardDynalabelDataLoadingErrorStatus = status
        },

        SET_SCORECARDS_GROUPED_DYNALABEL_DATA(state, groupedData) {
            state.groupedScorecardDynalabelData = groupedData
        },
        SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_STATUS(state, status) {
            state.groupedScorecardDynalabelDataLoadingStatus = status
        },
        SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_ERROR(state, error) {
            state.groupedScorecardDynalabelDataLoadingError = error
        },
        SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_ERROR_STATUS(state, status) {
            state.groupedScorecardDynalabelDataLoadingErrorStatus = status
        },

        SET_SCORECARDS_AGGREGATED_AGENT_DATA(state, aggregatedData) {
            state.aggregatedScorecardAgentData = aggregatedData
        },
        SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_STATUS(state, status) {
            state.aggregatedScorecardAgentDataLoadingStatus = status
        },
        SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_ERROR(state, error) {
            state.aggregatedScorecardAgentDataLoadingError = error
        },
        SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_ERROR_STATUS(state, status) {
            state.aggregatedScorecardAgentDataLoadingErrorStatus = status
        },

        SET_SCORECARDS_GROUPED_AGENT_DATA(state, groupedData) {
            state.groupedScorecardAgentData = groupedData
        },
        SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_STATUS(state, status) {
            state.groupedScorecardAgentDataLoadingStatus = status
        },
        SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_ERROR(state, error) {
            state.groupedScorecardAgentDataLoadingError = error
        },
        SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_ERROR_STATUS(state, status) {
            state.groupedScorecardAgentDataLoadingErrorStatus = status
        },


        SET_GROUPED_DYNALABEL_CATEGORY_LOAD_COUNT(state, count) {
            state.groupedDynalabelCategoryLoadCount = count
        },
        SET_AGGREGATED_DYNALABEL_CATEGORY_LOAD_COUNT(state, count) {
            state.aggregatedDynalabelLoadCount = count
        },
        SET_GROUPED_AGENT_CATEGORY_LOAD_COUNT(state, count) {
            state.groupedAgentCategoryLoadCount = count
        },
        SET_AGGREGATED_AGENT_CATEGORY_LOAD_COUNT(state, count) {
            state.aggregatedAgentCategoryLoadCount = count
        },


    },
    actions: {

        retrieveScorecardsAggregatedDynaLabelData(context, payload) {

            let urlParams = "?"
            urlParams += '&start_date=' + payload.start_date
            urlParams += '&end_date=' + payload.end_date
            urlParams += '&grouping=' + payload.grouping
            urlParams += '&scorecards_editor=false'
            urlParams += '&call_type=' + payload.call_type
            context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS", "loading")
            context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA", [])
            axios
                .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.scorecard_id + '/dynalabels' + urlParams)
                .then(response => {
                    let aggregatedDynalabels = response.data.data
                    let display_formats = [];
                    let presentation = payload.presentation
                    presentation.forEach((presented, index) => {
                        if (presented.source_key.includes('data[].items[]')) {
                            let key = presented.source_key.substr(presented.source_key.lastIndexOf('.') + 1);
                            let info = {}
                            info[key] = {
                                display: presented.display_format,
                                data_type: presented.data_type,
                            }
                            display_formats.push(info)
                        } 
                    })
                    
                    context.commit("SET_DISPLAY_FORMATS", display_formats)
                    aggregatedDynalabels.categories = aggregatedDynalabels.categories.map(obj => ({ ...obj, loading: true }))
                    aggregatedDynalabels.categories = aggregatedDynalabels.categories.sort(
                        (a, b) => (a.sort_order > b.sort_order && 1) || -1
                    );
                    context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA", aggregatedDynalabels)
                    context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR_STATUS", false)
                    context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR", "")
                    context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS", "loaded")
                })
                .catch(function (error) {
                    context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS", "failed")
                    context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA", {})
                    context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR_STATUS", true)
                    context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_ERROR", 'retrieveScorecardsAggregatedDynaLabelData() ')
                })
        },

        setGroupedDynalabelLoadingStatus(context, status) {
            context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_STATUS", status)
        },
        setAggregateDynalabelLoadingStatus(context, status) {
            context.commit("SET_SCORECARDS_AGGREGATED_DYNALABEL_DATA_LOADING_STATUS", status)
        },
        setGroupedAgentLoadingStatus(context, status) {
            context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_STATUS", status)
        },
        setAggregatedAgentLoadingStatus(context, status) {
            context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_STATUS", status)
        },
        retrieveScorecardsGroupedDynaLabelData(context, payload) {
            let axios_params = {
                headers: {},
                params: {
                    start_date: payload.start_date,
                    end_date: payload.end_date,
                    grouping: payload.grouping,
                    scorecards_editor: false,
                    group_by_dynalabel: true,
                    call_type: payload.call_type,
                }
            }
            let axios_url = process.env.VUE_APP_SCORECARDS_V2 + "/" + payload.scorecard_id + "/dynalabels"
    
            if(payload.selfSigned) {
                axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
                axios_params['headers'] = {"Authorization": payload.userid + " " + payload.reckey}
                axios_params['params']['scorecard_id'] = payload.scorecard_id
                axios_params['params']['route'] = "scorecard_dynalabel_info"
                axios_params['params']['call_id'] = payload.call_id
            } 


            context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_STATUS", "loading")
            context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA", [])
            axios
                .get(axios_url, axios_params)
                .then(response => {
                    let groupedDynalabels = response.data.data
                    groupedDynalabels.categories = groupedDynalabels.categories.map(obj => ({ ...obj, loading: true }))
                    groupedDynalabels.items = groupedDynalabels.items.sort(
                        (a, b) => (a.dynalabelTitle > b.dynalabelTitle && 1) || -1
                    );
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA", groupedDynalabels)
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_ERROR_STATUS", false)
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_ERROR", "")
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_STATUS", "loaded")
                })
                .catch(function (error) {
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_STATUS", "failed")
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA", [])
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_ERROR_STATUS", true)
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA_LOADING_ERROR", 'retrieveScorecardsGroupedDynaLabelData()')
                })
        },

        retrieveScorecardsAggregatedAgentData(context, payload) {
            let urlParams = "?"
            urlParams += '&start_date=' + payload.start_date
            urlParams += '&end_date=' + payload.end_date
            urlParams += '&grouping=' + payload.grouping
            urlParams += '&scorecards_editor=false'
            urlParams += '&call_type=' + payload.call_type
          
            context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_STATUS", "loading")
            context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA", [])
            axios
                .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.scorecard_id + '/dynalabels/' + payload.dynalabel_id + '/agents/' + urlParams)
                .then(response => {
                    let aggregatedAgents = response.data.data
                    let display_formats = [];
                    let presentation = payload.presentation
                    presentation.forEach((presented, index) => {
                        if (presented.source_key.includes('data[].items[]')) {
                            let key = presented.source_key.substr(presented.source_key.lastIndexOf('.') + 1);
                            let info = {}
                            info[key] = {
                                display: presented.display_format,
                                data_type: presented.data_type,
                            }

                            display_formats.push(info)
                        } 
                    })
                    
                    context.commit("SET_DISPLAY_FORMATS", display_formats)
                    aggregatedAgents.categories = aggregatedAgents.categories.map(obj => ({ ...obj, loading: true }))
                    aggregatedAgents.categories = aggregatedAgents.categories.sort(
                        (a, b) => (a.sort_order > b.sort_order && 1) || -1
                    );
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA", aggregatedAgents)
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_ERROR_STATUS", false)
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_ERROR", "")
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_STATUS", "loaded")
                })
                .catch(function (error) {
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_STATUS", "failed")
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA", {})
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_ERROR_STATUS", true)
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA_LOADING_ERROR", 'retrieveScorecardsAggregatedAgentData()')
                })
        },

        retrieveScorecardsGroupedAgentData(context, payload) {

            let urlParams = "?"
            urlParams += '&start_date=' + payload.start_date
            urlParams += '&end_date=' + payload.end_date
            urlParams += '&grouping=' + payload.grouping
            urlParams += '&scorecards_editor=false'
            urlParams += '&group_by_agent=true'
            urlParams += '&call_type=' + payload.call_type
          
            context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_STATUS", "loading")
            context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA", [])
            axios
                .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.scorecard_id + '/dynalabels/' + payload.dynalabel_id + '/agents/' + urlParams)
                .then(response => {
                    let groupedAgents = response.data.data
                    groupedAgents.categories = groupedAgents.categories.map(obj => ({ ...obj, loading: true }))
                    groupedAgents.items = groupedAgents.items.sort(
                        (a, b) => (a.agentName > b.agentName && 1) || -1
                    );
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA", groupedAgents)
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_ERROR_STATUS", false)
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_ERROR", "")
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_STATUS", "loaded")
                })
                .catch(function (error) {
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_STATUS", "failed")
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA", [])
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_ERROR_STATUS", true)
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA_LOADING_ERROR", 'retrieveScorecardsGroupedAgentData()')
                })
        },

        retrieveAggregatedDynalabelCategories(context, payload) {
            let urlParams = "?"
            urlParams += '&start_date=' + payload.start_date
            urlParams += '&end_date=' + payload.end_date
            urlParams += '&grouping=' + payload.grouping
            urlParams += '&scorecards_editor=false'
            urlParams += '&loadmore=categories'
            urlParams += '&call_type=' + payload.call_type
           
            if (payload.categories) {
                payload.categories.forEach(value => {
                    urlParams += '&category_ids[]=' + JSON.stringify(value)
                })
            }
            axios
                .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.scorecard_id + '/dynalabels' + urlParams)
                .then(response => {
                    //once this loads, splice the list and update it
                    let currentList = context.getters.aggregatedScorecardDynalabelData
                    let aggregatedCount = context.getters.aggregatedDynalabelLoadCount
                    let categoriesRetrieved = response.data.data
                   
                    currentList.categories.forEach((category, index) => {
                        categoriesRetrieved.forEach((categoryResponse, responseIndex) => {
                            if (category.id == categoryResponse.id) {
                                categoryResponse.loading = false
                                //splice is a requirement to update the index
                                currentList.categories.splice(index, 1, categoryResponse)
                            }

                        })
                    })

                    context.commit("SET_AGGREGATED_DYNALABEL_CATEGORY_LOAD_COUNT", aggregatedCount + 1)
                })
                .catch(function (error) {

                })

        },
        retrieveGroupedDynalabelCategories(context, payload) {
            let urlParams = "?"
            urlParams += '&start_date=' + payload.start_date
            urlParams += '&end_date=' + payload.end_date
            urlParams += '&grouping=' + payload.grouping
            urlParams += '&scorecards_editor=false'
            urlParams += '&loadmore=categories'
            urlParams += '&call_type=' + payload.call_type
            if (payload.categories) {
                payload.categories.forEach(value => {
                    urlParams += '&category_ids[]=' + JSON.stringify(value)
                })
            }

            if (payload.group_by_item) {
                urlParams += '&group_by_dynalabel=true'
            }
            axios
                .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.scorecard_id + '/dynalabels' + urlParams)
                .then(response => {
                    //once this loads, splice the list and update it
                    let groupedCount = context.getters.groupedDynalabelCategoryLoadCount
                    let itemsRetrieved = response.data.data
                    let currentList = context.getters.groupedScorecardDynalabelData
                    let loadedCategories = []
                    currentList.items.forEach((item, index) => {
                        let retrievedCats = itemsRetrieved[item.id]
                        if (retrievedCats != undefined) {
                            retrievedCats.forEach((catRetrieved, retrievedIndex) => {
                                item.metrics.categories.forEach((catItem, catIndex) => {
                                    if (catItem.id == catRetrieved.id) {
                                        catItem.loading = false
                                        item.metrics.categories.splice(catIndex, 1, catRetrieved)
                                        loadedCategories.push(catRetrieved.id)
                                    }
                                })
                            })
                        }
                    })
                    currentList.categories.forEach((category, index) => {
                        loadedCategories.forEach((id) => {
                            if (id == category.id) {
                                category.loading = false
                            }
                        })
                    })
                    
                    context.commit("SET_GROUPED_DYNALABEL_CATEGORY_LOAD_COUNT", groupedCount + 1)
                    context.commit("SET_SCORECARDS_GROUPED_DYNALABEL_DATA", currentList)
                })
                .catch(function (error) {

                })
        },

        retrieveAggregatedAgentCategories(context, payload) {
            let urlParams = "?"
            urlParams += '&start_date=' + payload.start_date
            urlParams += '&end_date=' + payload.end_date
            urlParams += '&grouping=' + payload.grouping
            urlParams += '&scorecards_editor=false'
            urlParams += '&loadmore=categories'
            urlParams += '&call_type=' + payload.call_type
           
            if (payload.categories) {
                payload.categories.forEach(value => {
                    urlParams += '&category_ids[]=' + JSON.stringify(value)
                })
            }

            axios
                .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.scorecard_id + '/dynalabels/' + payload.dynalabel_id + '/agents' + urlParams)
                .then(response => {
                    //once this loads, splice the list and update it
                    let aggregatedCount = context.getters.aggregatedAgentCategoryLoadCount
                    let categoriesRetrieved = response.data.data
                    let currentList = context.getters.aggregatedScorecardAgentData
                    currentList.categories.forEach((category, index) => {
                        categoriesRetrieved.forEach((categoryResponse, responseIndex) => {
                            if (category.id == categoryResponse.id) {
                                categoryResponse.loading = false
                                category.loading = false
                                //splice is a requirement to update the index
                                currentList.categories.splice(index, 1, categoryResponse)
                            }

                        })
                    })

                    context.commit("SET_AGGREGATED_AGENT_CATEGORY_LOAD_COUNT", aggregatedCount + 1)
                    context.commit("SET_SCORECARDS_AGGREGATED_AGENT_DATA", currentList)
                })
                .catch(function (error) {

                })

        },
        retrieveGroupedAgentCategories(context, payload) {
            let urlParams = "?"
            urlParams += '&start_date=' + payload.start_date
            urlParams += '&end_date=' + payload.end_date
            urlParams += '&grouping=' + payload.grouping
            urlParams += '&scorecards_editor=false'
            urlParams += '&loadmore=categories'
            urlParams += '&call_type=' + payload.call_type
            if (payload.categories) {
                payload.categories.forEach(value => {
                    urlParams += '&category_ids[]=' + JSON.stringify(value)
                })
            }
            if (payload.group_by_item) {
                urlParams += '&group_by_agent=true'
            }

            axios
                .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.scorecard_id + '/dynalabels/' + payload.dynalabel_id + '/agents' + urlParams)
                .then(response => {
                    //once this loads, splice the list and update it
                    let groupedCount = context.getters.groupedAgentCategoryLoadCount
                    let itemsRetrieved = response.data.data
                    let currentList = context.getters.groupedScorecardAgentData
                    let loadedCategories = []
                    currentList.items.forEach((item, index) => {
                        let retrievedCats = itemsRetrieved[item.id]
                        if (retrievedCats != undefined) {
                            retrievedCats.forEach((catRetrieved, retrievedIndex) => {
                                item.metrics.categories.forEach((catItem, catIndex) => {
                                    if (catItem.id == catRetrieved.id) {
                                        catItem.loading = false
                                        item.metrics.categories.splice(catIndex, 1, catRetrieved)
                                        loadedCategories.push(catRetrieved.id)
                                    }
                                })
                            })
                        }
                    })
                    currentList.categories.forEach((category, index) => {
                        loadedCategories.forEach((id) => {
                            if (id == category.id) {
                                category.loading = false
                            }
                        })
                    })
                  
                    context.commit("SET_GROUPED_AGENT_CATEGORY_LOAD_COUNT", groupedCount + 1)
                    context.commit("SET_SCORECARDS_GROUPED_AGENT_DATA", currentList)
                })
                .catch(function (error) {

                })
        },

        

        setGroupedDynalabelLoadCounter(context, count) {
            context.commit("SET_GROUPED_DYNALABEL_CATEGORY_LOAD_COUNT", count)
        },
        setAggregatedDynalabelLoadCounter(context, count) {
            context.commit("SET_AGGREGATED_DYNALABEL_CATEGORY_LOAD_COUNT", count)
        },
        setGroupedAgentLoadCounter(context, count) {
            context.commit("SET_GROUPED_AGENT_CATEGORY_LOAD_COUNT", count)
        },
        setAggregatedAgentLoadCounter(context, count) {
            context.commit("SET_AGGREGATED_AGENT_CATEGORY_LOAD_COUNT", count)
        },

    }
}
