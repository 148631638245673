<template>
  <div class="control-container">
    <v-row>
      <v-col cols="2" sm="4" md="2" class="pt-4">
        <h2 class="filter-type ml-4">{{ filter }} <span>({{ selected.length }}&nbsp;selected)</span></h2>
      </v-col>
      <v-col cols="10" sm="8" md="10" class="pt-3 pr-8">
        <v-text-field
          small
          dense
          v-model="search"
          :placeholder="`Find ` + filter + `...`"
          append-icon="mdi-magnify"
          clearable
          @click:clear="clearSearch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row pa-0>
      <v-col>
        <v-flex class="option-container">
          <v-chip
            label
            small
            v-for="item in filteredItemList"
            :key="item.id"
            active-class="primary"
            :input-value="item.selected"
            @click="toggleSelected(item)"
          >
            <span class="item-chip" :title="getTitle(item)" :style="'width:' + chipWidth" v-html="getTitle(item)"></span>
          </v-chip>
        </v-flex>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn text small color="primary" @click="selectAll">Select All</v-btn>
        <v-btn text small color="primary" @click="unselectAll">Clear All</v-btn>
        <v-btn text small color="primary" @click="showAll">Show All</v-btn>
        <v-btn text small color="primary" @click="showSelected">Show Selected</v-btn>
        <v-btn small color="primary" class="mr-4 elevation-0" :loading="loading" @click="applyAndReload">Apply & Reload</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  export default {
    name: "ContainerMultiselect",
    props: {
      filter: {
        type: String,
        default: "value"
      },
      feature: {
        type: String,
        default: "value"
      },
      display: {
        type: String,
        default: "display"
      },
      supplementalText: {
        type: String,
        default: "supplementalText"
      },
      options: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },
    data() {
      return {
        selected: this.getSelected(),
        itemList: [],
        itemsSelected: [],
        search: '',
        showOnlySelected: false,
        loading: false,
      };
    },
    watch: {
      selected() {
        this.storeValues(this.selected);
        this.$root.$emit("FILTER_UPDATED");
      },
    },
    computed: {
      filteredItemList() {
        // bug in v-model for search - clearing search sets it to null, not an empty string, so fix it!
        let mySearch = (this.search===null) ? '' : this.search
        return (mySearch==='') ?
          (this.showOnlySelected) ? this.itemList.filter(item => { return item.selected === true })
            : this.itemList
          :
          (this.showOnlySelected) ? this.itemList.filter(item => { return this.getTitle(item).toLowerCase().includes(this.search.toLowerCase()) && item.selected})
            : this.itemList.filter(item => { return this.getTitle(item).toLowerCase().includes(this.search.toLowerCase()) })
      },
      chipWidth() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "80px;"
          case "sm":
            return "100px;"
          case "md":
            return '120px;'
          case "lg":
            return '140px;'
          case "xl":
            return "160px;"
          default:
            return "160px;"
        }
      },
    },
    methods: {
      applyAndReload() {
        this.loading = true
        this.$emit("filter-updated-reload");
        this.$emit("account-filter-updated-reload")
        this.loading = false

      },
      getTitle(item) {
        let r = item.display
        // swap parenthetical qualifiers and initial text to enhance scan-ability
        if(item.display.match(/\(.+\)/)!==null) {
          r = item.display.replace(/(.+)(\(.+\)).*/, "$2 $1")
        }
        return r + ' (' + item.value + ')'
      },
      toggleSelected(item) {
        var idx = this.selected.indexOf(item.value)
        if(idx > -1) { // remove it and update stored
          item.selected = false
          this.selected.splice(idx,1)
          this.storeValues(this.selected)
        } else { // add it and update stored values
          item.selected = true
          this.selected.push(item.value)
          this.storeValues(this.selected)
        }
      },
      storeValues(arr) {
        let accessor =
          "filters/set" +
          this.filter.charAt(0).toUpperCase() +
          this.filter.slice(1);
        let selectedObject = { [this.filter]: arr }
        this.$store.commit(accessor, selectedObject);
      },
      getSelected() {
        const filter = this.filter;
        const storedValues = this.$store.getters["filters/" + filter] || [];
        if (storedValues.length === 0) { return []; }
        let selected = this.options.filter(option => {
          return storedValues[filter].includes(option.value);
        });
        return selected.map(a => a.value);
      },
      selectAll() {
        this.itemList.forEach(function(val,idx,arr){
          if(val.selected===false) {
            arr[idx].selected = true
          }
        })
        this.storeValues(this.itemList.map(a => a.value))
        this.selected = this.getSelected()
      },
      unselectAll() {
        this.itemList.forEach(function(val,idx,arr) {
          arr[idx].selected = false
        })
        this.storeValues([])
        this.selected = this.getSelected()
      },
      showAll() {
        this.showOnlySelected = false
      },
      showSelected() {
        this.showOnlySelected = true
      },
      clearSearch() {
        this.search = ''
      },
    },
    mounted() {

      if(this.feature=='scorecards') {
        this.itemList = this.options
          .map(item => { return {'display': item.name, 'value': item.id, 'selected': (this.getSelected().indexOf(item.id) >= 0)} })
          .sort((a, b) => (a.display > b.display) ? 1 : -1)
      } else if(this.feature=='insights') {
        this.itemList = this.options
          .map(item => {
            return {'display': item.display, 'value': item.value, 'selected': (this.getSelected().indexOf(item.value) >= 0)}
          })
          .sort((a, b) => (a.display > b.display) ? 1 : -1)
      } else {
        this.itemList = this.options;
      }

    }
  };

</script>

<style scoped>

  .control-container {
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .2rem;
    background-color: rgba(255, 255, 255, 1);
    min-height: 260px;
  }

  .filter-type {
    font-size: 1rem !important;
    line-height: 100% !important;
    color: rgba(25, 118, 210, 1);
    text-transform: capitalize;
  }

  .filter-type span {
    font-size: .75rem !important;
    color: rgba(0, 0, 0, .4);
    text-transform: lowercase;
  }

  .option-container {
    min-height: 100px;
    max-height: 180px;
    overflow-y: scroll;
    padding: 1rem;
    margin: 0 1rem 0 1rem;
    border: 1px dotted rgba(0, 0, 0, .2);
  }

  .v-chip {
    margin: 2px 4px;
  }

  .item-chip {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p.title {
    font-size: 1rem !important;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1rem;
  }

  p.title span {
    font-size: small;
    font-weight: normal;
  }

</style>
