<template>
  <v-content>
    <v-container>
      <scorecard-loading-status
        v-if="scorecardIsLoading"
        :message="scorecardMessageType"
        :type="scorecardLoadingType"
      >
      </scorecard-loading-status>
      <scorecard-view
        v-else
        :format="'printable-agent'"
        :agentId="Number($route.params.agentId)"
      ></scorecard-view>
    </v-container>
  </v-content>
</template>

<script>
import ScorecardLoadingStatus from "../../components/Scorecards/ScorecardLoadingStatus.vue";
import ScorecardView from "../../components/Scorecards/ScorecardView.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  components: { ScorecardLoadingStatus, ScorecardView },
  computed: {
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    ...mapGetters("scorecardsV2Calls", [
      "scorecardV2Data",
      "scorecardV2DataLoadingStatus",
      "scorecardV2DataLoadingError",
      "scorecardV2DataLoadingErrorStatus"
    ]),
    callAdjustmentsFilter() {
      return this.$route.query.hasOwnProperty("call_type")
        ? this.$route.query.call_type
        : "all";
    },
    getUnit() {
      return moment(this.end_date).diff(moment(this.start_date), "days") > 1
        ? "day"
        : "hour";
    },
    scorecardIsLoading() {
      return (
        this.scorecardV2DataLoadingStatus != "loaded" ||
        this.scorecardV2DataLoadingErrorStatus ||
        !this.scorecardAvailable
      );
    },
    scorecardAvailable() {
      //we need to access 'items' like this since presentation-service looks at an array at the aggregate level
      return this.scorecardV2Data.hasOwnProperty("items") &&
        this.scorecardV2Data.items.length > 0
        ? this.scorecardV2Data.items[0].metrics.hasOwnProperty("totalCalls")
        : false;
    },
    scorecardLoadingType() {
      if (this.scorecardV2DataLoadingErrorStatus) return "error";
      else if (
        this.scorecardV2DataLoadingStatus == "loaded" &&
        !this.scorecardAvailable
      ) {
        return "nodata";
      } else return "loading";
    },
    scorecardMessageType() {
      if (this.scorecardV2DataLoadingErrorStatus) {
        return "There was an error loading this scorecard...";
      } else if (
        this.scorecardV2DataLoadingStatus == "loaded" &&
        !this.scorecardAvailable
      ) {
        return "Scorecards are not available for the date-range filters you've selected. Please change your filter settings.";
      } else return "Scorecard loading, please wait...";
    }
  },
  methods: {
    ...mapActions({
      retrieveScorecard: "scorecardsV2Calls/retrieveScorecard"
    }),
    retrieveData() {
      if (this.$route.name == "printable-agent-scorecard-v2") {
        let params = {
          scorecard_id: this.$route.params.scorecardId,
          dynalabel_id: this.$route.params.dynalabelId,
          agent_id: this.$route.params.agentId,
          grouping: this.getUnit,
          start_date: moment(this.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.end_date).format("YYYY-MM-DD"),
          call_type: this.callAdjustmentsFilter
            ? this.callAdjustmentsFilter
            : "all"
        };
        this.retrieveScorecard(params);
      }
    }
  },
  mounted() {
    this.retrieveData();
  }
};
</script>