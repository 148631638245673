<template>
  <div
    v-if="!disabledZone"
    @drop="onDrop($event, sortOrder, dropZoneNumber, bottom)"
    @dragover="onDragOver($event)"
    @dragenter="onDragEnter($event)"
    @dragleave="onDragLeave($event)"
    :class="type + '-drop-zone ' + dropZoneHover"
  ></div>

</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    dropZoneNumber: Number,
    sortOrder: Number,
    bottom: Boolean,
    type: String,
    subCategories: Array,
    category: Object,
    scorecardId: Number,
    categories: Array,
    itemBeingDragged: String,
    disabledZone: Boolean
  },
  components: {},
  data() {
    return {
      dropzone: false
    };
  },
  computed: {
    dropZoneHover() {
      if (this.dropzone && this.itemBeingDragged == "category") {
        return "category-drop-zone-hover in-drop-zone";
      } else if (this.dropzone && this.itemBeingDragged == "subcategory") {
        return "subcategory-drop-zone-hover in-drop-zone";
      } else {
        return "";
      }
    }
  },
  methods: {
    onDragOver(event) {
      event.preventDefault();
    },
    onDragEnter(event) {
      this.dropzone = true;
      event.preventDefault();
    },
    onDragLeave(event) {
      this.dropzone = false;
      event.preventDefault();
    },
    ...mapActions({
      reSortSubCategories: "scorecardDefinitions/reSortSubCategories",
      reSortCategories: "scorecardDefinitions/reSortCategories"
    }),
    onDrop(evt, sortOrder, dropZoneNum, bottom) {
      if (this.itemBeingDragged == "category") {
        this.onCategoryDrop(evt, sortOrder, dropZoneNum, bottom);
      } else if (this.itemBeingDragged == "subcategory") {
        this.onSubCategoryDrop(evt, sortOrder, dropZoneNum, bottom);
      }
      this.dropzone = false;
      this.$emit("setDisabledZone", false);
    },
    onCategoryDrop(evt, sortOrder, dropZoneNum, bottom) {
      const itemID = evt.dataTransfer.getData("catID");
      const item = this.categories.find(item => item.id == itemID);
      let movedDown = false;
      item.sort_order = dropZoneNum;

      if (sortOrder > item.sort_order) {
        movedDown = true;
      }
      let params = {
        id: this.category.id,
        scorecard_id: this.scorecardId,
        category_id: this.category.id,
        item_id: item.id,
        bottom: bottom,
        moved_down: movedDown
      };

      //re-sort
      this.reSortCategories(params);
      this.$emit("dropCompleteSetPublish", true);

    },
    onSubCategoryDrop(evt, sortOrder, dropZoneNum, bottom) {
      const itemID = evt.dataTransfer.getData("subCatID");
      const item = this.subCategories.find(item => item.id == itemID);
      if (item.category_id == this.category.id) {
        let movedDown = false;
        item.sort_order = dropZoneNum;
        if (sortOrder > item.sort_order) {
          movedDown = true;
        }
        let params = {
          id: this.category.id,
          scorecard_id: this.scorecardId,
          category_id: this.category.id,
          item_id: item.id,
          bottom: bottom,
          moved_down: movedDown
        };

        //re-sort
        this.reSortSubCategories(params);
        this.$emit("dropCompleteSetPublish", true);
        this.$emit("disabledDropZone", false);
      }
    }
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>

.category-drop-zone {
  position: absolute;
  top: -1rem;
  height: 12rem;
  width: 100%;
}

.category-drop-zone-hover {
  background-color: rgba(179, 212, 252, 1);
  border: 0.5px dotted lightgray;
  opacity: 0.8;
  height: 12rem;
  width: 100%;
  border-radius: .25rem;
}

.subcategory-drop-zone {
  position: absolute;
  top: -1rem;
  min-height: 1rem;
  width: 100%;
}

.subcategory-drop-zone-hover {
  background-color: rgba(179, 212, 252, 1);
  border: 0.5px dotted lightgray;
  opacity: 0.8;
  height: 6rem;
  width: 100%;
  border-radius: .25rem;
}

.in-drop-zone {
  position: relative;
}

</style>
