<template>
  <v-card class="card-border mb-4 elevation-0">
    <v-card-text>
    <v-row class="mb-0 pb-0">
      <v-col cols="3">
        <SmallTranscriptHeader
          :agent="callRecord.agent_name"
          :callerNumber="callRecord.orignmbr"
          :calledNumber="callRecord.pots"
          :callDate="callRecord.adjusteddatetime"
          :callDirection="callRecord.call_direction"
          :accountNumber="callRecord.acctnmbr"
          :accountName="callRecord.account_name"
          :sentiment="analysis.sentiment"
          :callId="callId"
        ></SmallTranscriptHeader>
      </v-col>
      <v-col cols="8" class="mb-0 pb-0">
        <SearchHits :hits="phrases"></SearchHits>
      </v-col>
      <v-col
        cols="1"
        style="min-width: 100px; max-width: 100%;"
        class="flex-grow-0 flex-shrink-0 text-center pt-4">
        <v-btn
          title="View Transcript"
          icon
          small
          @click="loadModal"
        ><v-icon>mdi-message-text</v-icon></v-btn>
      </v-col>
      <template slot="header">
        <TranscriptHeader
          slot="header"
          :agent="callRecord.agent_name"
          :callerNumber="callRecord.orignmbr"
          :calledNumber="callRecord.pots"
          :callDate="callRecord.adjusteddatetime"
          :callDirection="callRecord.call_direction"
          :accountNumber="callRecord.acctnmbr"
          :accountName="callRecord.account_name"
          :sentiment="analysis.sentiment"
          :callId="callId"
          :insights="false"
        ></TranscriptHeader>
      </template>
      <TranscriptModal
        :showModal="showModal"
        :callId="callId"
        :key="callId"
        @HIDE_MODAL="hideModal"
      ></TranscriptModal>
    </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TranscriptHeader from "@/components/Transcripts/Header";
import SmallTranscriptHeader from "@/components/Transcripts/SmallHeader";
import TranscriptModal from "@/components/Transcripts/Modal";
import SearchHits from "@/components/Transcripts/Search/Hits";

export default {
  name: "Transcript",
  components: {
    TranscriptHeader,
    SmallTranscriptHeader,
    TranscriptModal,
    SearchHits,
  },

  data() {
    return {
      showModal: false,
    };
  },

  props: {
    analysis: {
      type: Object,
      default: () => {}
    },
    callRecord: {
      type: Object,
      default: () => {}
    },
    phrases: {
      type: Array,
      default: () => []
    },
    callId: {
      type: String,
      required: true,
    },
  },
  methods: {
    loadModal() {
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    }
  }
};
</script>

<style scoped>

  .phrase {
    line-height: 2em;
  }
  .speaker {
    color: #0066ff;
    font-variant: small-caps;
  }
  .speaker.agent {
    opacity: 0.6;
  }
  .card-border {
    border: 1px dotted rgba(0,0,0,.25)
  }

</style>
