import moment from "moment";

export const dateRange = {
  namespaced: true,
  state: {
    start_date: moment()
      .subtract(1, "day")
      .startOf("day")
      .format(),
    end_date: moment()
      .subtract(1, "day")
      .endOf("day")
      .format()
  },
  // I appoligize for the snake case in this file.
  // search service wants snake case. we can update it later.
  mutations: {
    setStartDate(state, start_date) {
      state.start_date = start_date;
    },
    setEndDate(state, end_date) {
      state.end_date = end_date;
    }
  },
  getters: {
    start_date: state => {
      return state.start_date;
    },
    end_date: state => {
      return state.end_date;
    },
    date_range_english: state => {
      return moment(state.start_date).format("LL") !=
        moment(state.end_date).format("LL")
        ? moment(state.start_date).format("LL") +
            " to " +
            moment(state.end_date).format("LL")
        : moment(state.start_date).format("LL");
    }
  }
};
