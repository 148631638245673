<template>
  <insights-dashboard></insights-dashboard>
</template>

<script>

  import InsightsDashboard from '@/pages/Insights/InsightsDashboard.vue';
  import moment from "moment";
  import {mapGetters, mapActions} from "vuex";

  export default {
    components: {
      InsightsDashboard,
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters('users', [
        'userData',
        'userDataLoadingStatus',
        'userDataLoadingError',
        'userDataLoadingErrorStatus',
      ]),
    },
    methods: {

      ...mapActions({
        retrieveUserData: "users/retrieveUserData",
      }),

    },
    mounted() {
      this.retrieveUserData;
      this.$root.$on('DATE_RANGE_UPDATED', () => {
        this.$router.push(
          { query:
              Object.assign(
                {},
                this.$route.query,
                { start_date: String(moment(this.$store.getters["dateRange/start_date"]).format("YYYY-MM-DD")) },
                { end_date: String(moment(this.$store.getters["dateRange/end_date"]).format("YYYY-MM-DD")) }
              )
          }
        ).catch(err => {})
        this.retrieveUserData;
      })
    },
    watch: {
    },
  }

</script>

<style scoped>
  h1, h2, h3, h4 { width: 100%; }
  th span {
    font-size: .8rem;
    color: rgba(0,0,0,.75);
  }
  th.dim span {
    font-size: .7rem;
    /* color: rgba(0,0,0,.4); */
  }
  td > span.name {
    font-size: 1.1rem;
    cursor: pointer;
  }
  td > span.tData {
    font-size: 1rem;
    cursor: help;
    text-align: center;
  }
  td span.dim {
    font-size: .9rem;
    /* color: rgba(0,0,0,.4); */
    cursor: help;
  }
  h1 {
    line-height: 1.2rem !important;
    font-size: 1.2rem !important;
  }
  h1.main {
    line-height: 1.5rem !important;
    font-size: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  h1.main > span.dim {
    font-size: 1.2rem !important;
    color: rgba(0,0,0,.4);
  }
  h2 {
    margin-top: .75rem;
    line-height: 1.2rem !important;
    font-size: 1.1rem !important;
    color: rgba(0,0,0,.9) !important;
  }
  h3 {
    margin-top: 1rem;
    position: relative;
    line-height: 1.2rem !important;
    font-size: .9rem !important;
    color: rgba(0,0,0,.9) !important;
  }
  h3 > span.dim {
    font-size: .75rem;
    /* color: rgba(0,0,0,.4); */
    color:black;
    display: inline-block;
    position: absolute;
    left: 7rem;
  }
  h4 {
    position: relative;
    margin-top: .75rem;
  }
  h4 > span.large {
    font-size: 2rem;
    color: rgba(0,0,0,.8) !important;
    cursor: help;
  }
  h4 > span.medium {
    font-size: 1.5rem;
    color: rgba(0,0,0,.8) !important;
    cursor: help;
  }
  h4 span.dim {
    padding-left: .75rem;
    font-size: .9rem;
    color: black;
    display: inline-block;
    position: absolute;
    left: 6.25rem;
    cursor: help;
  }
  .progress {
    font-size: .9rem;
    color: rgba(0, 0, 0, .5);
  }
  .dont-wrap { white-space: nowrap; }
  .bubble { width: 100%; display: inline-block; padding: 4px; border-radius: 4px; color: #fff; white-space: nowrap; }
  .SentimentStrongPositive { background-color: rgba(140, 199, 63, 1); }
  .SentimentPositive { background-color: rgba(174, 212, 124, 1); }
  .SentimentNeutral { background-color: rgba(184, 184, 184, 1); }
  .SentimentNegative { background-color: rgba(201, 123, 107, 1); }
  .SentimentStrongNegative { background-color: rgba(179, 63, 38, 1); }
  h5.footnote {
    margin-bottom: .5rem;
  }
  sup.footnote {
    vertical-align: baseline;
    position: relative;
    top: 0rem;
    font-size: 1rem;
    color: rgba(179, 63, 38, 1);
  }
  h5.footnote sup.footnote {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  div.mix-alert {
    margin-top: 2rem;
    padding: 0 2rem 0 0;
  }
  div.mix-alert p {
    font-size: .9rem;
  }

  .float-subjective {
      width: 50%;
      float: left;
  }

  .float-container {
    /* border: 3px solid #fff; */
    /* padding: 20px; */
}

  .float-child {
    width: 50%;
    float: left;
    /* padding: 20px;
    border: 2px solid red; */
}

</style>
