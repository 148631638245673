<template>
  <v-card tile elevation="0" :class="[(isRoot ? 'is-root-' : 'is-child-') + container.kind]">
    <v-card-text class="pa-0 ma-0 text-center" :class="[(isRoot ? 'root-' : 'child-') + 'background']">
      <glue-button-and-or
        v-if="idx>0"
        :glue="glue"
        :pathToParent="pathToParent"
      ></glue-button-and-or>
      <v-row :class="getClasses">
        <v-col cols="11">
          <v-label v-if="container.kind!='container'"><span class="dl-label">{{ itemDescription }}</span></v-label>
          <template v-if="container.parts && container.parts.length > 0">
            <search-container
              v-for="(child,idx) in container.parts"
              :key="idx"
              :container="child"
              :idx="idx"
              :glue="container.glue"
              :peerCount="container.parts.length"
              :isRoot="false"
              :pathToParent="pathToMe"
              :pathToMe="pathToMe + 'parts.' + String(idx) + '.'"
            ></search-container>
          </template>
        </v-col>
        <kebab-menu
          :dynaLabelId="dynaLabelId"
          :container="container"
          :name="container.kind"
          :items="getMenuItems()"
          :isRoot="isRoot"
          :pathToParent="pathToMe"
          :pathToMe="pathToMe + 'parts.' + String(idx) + '.'"
          :selector="getStringVal('kind')"
          :selection="getArrayVals('searchId')"
          :glue="getStringVal('glue')"
          :kind="getStringVal('kind')"
          :type="getStringVal('type')"
          :containerKind="'search'"
          :peerCount="peerCount"
        ></kebab-menu>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

  import KebabMenu from "@/components/DynaLabels/KebabMenu.vue"
  import GlueButtonAndOr from "@/components/DynaLabels/GlueButtonAndOr.vue"
  import {mapGetters} from "vuex";
  import utils from "@/mixins/utils";

  export default {
    name: "search-container",
    components: {
      KebabMenu,
      GlueButtonAndOr,
    },
    props: {
      dynaLabelId: Number,
      container: Object,
      peerCount: Number,
      glue: String,
      isRoot: Boolean,
      idx: Number,
      pathToParent: String,
      pathToMe: String,
    },
    mixins: [
      utils
    ],
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters('dynaLabels', [
        'dynaLabelData',
        'dynaLabelsSelectors',
      ]),
      ...mapGetters('resources', [
        'searchData'
      ]),
      itemDescription() {
        let s = this.searchData.find(s=>s.id==this.container.searchId) || {id:this.container.searchId,name:'Search Not Found'}
        return s.name + ' (' + s.id + ') / (' + this.container.glue + 's)'
      },
      searchIsValid() {
        return this.searchData.some(s=>s.id==this.container.searchId)
      },
      itemBackgroundClass() {
        if(this.container.hasOwnProperty('searchId')) {
          return (this.searchIsValid) ? 'dl-valid-search' : 'dl-invalid-search'
        } else {
          return ''
        }
      },
      getClasses() {
        if(this.isRoot) {
          return 'is-root-' + this.container.kind + '-wrapper pl-8'
        } else {
          return 'is-child-' + this.container.kind + '-wrapper pl-8 mt-1 mb-1 ' + this.itemBackgroundClass
        }
      },
    },
    methods: {
      getMenuItems() {
        let items = []
        switch (this.container.kind) {
          case 'container':
            if(this.isRoot) items.push({ title: 'Add', action: 'add' })
            break;
          case 'search':
            items = [
              { title: 'Edit', action: 'edit' },
              { title: 'Delete', action: 'delete' },
            ]
            break;
        }
        return items;
      },
      getStringVal(str) {
        return this.container.hasOwnProperty(str) ? this.container[str] : ''
      },
      getArrayVals(str) {
        return this.container.hasOwnProperty(str) ? [this.container[str]] : []
      },
    },
    mounted() {
    },
  }

</script>

<style>

  .is-root-container-wrapper {
    border-radius: 3px;
    background-color: rgba(253, 253, 253, 1);
    color: rgba(0,0,0,.66);
    min-height: 10vh;
  }

  .is-child-container-wrapper {
    border: 1px solid rgba(173, 206, 255, 1);
    border-radius: 3px;
    background-color: rgba(252, 252, 252, 1);
    color: rgba(0,0,0,.66);
  }

  .is-child-dynalabel-wrapper {
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: rgba(224, 224, 224, 1);
  }

  .root-background {
    background-color: rgba(253, 253, 253, 1);
  }

  .child-background {
    background-color: rgba(253, 253, 253, 1);
  }

  span.dl-label {
    color: rgba(0,0,0,1) !important;
  }


</style>
