<template>
  <span>
    <span v-if="displayType == 'text'">
      <span v-if="prefix">{{prefix}} </span> <span :class="'bubble Sentiment'+getSentimentText(sentiment).replace(' ','')"> {{ getSentimentText(sentiment) }}</span>
    </span>
    <span v-else-if="displayType == 'symbol'">
      <span v-if="prefix">{{prefix}} </span> <span :class="'symbolBubble Sentiment'+getSentimentText(sentiment).replace(' ','')"> {{ getSentimentSymbol(sentiment) }}</span>
    </span>
  </span>
</template>

<script>
  export default {
    name: "sentiment-chip",
    props: {
      sentiment: {
        type: Number,
        required: true
      },
      prefix: {
        type: String,
        required: false,
        default: "",
      },
      displayType: {
        type: String,
        required: false,
        default: "text",
      }
    },
    data() {
      return {}
    },
    methods: {
      getSentimentText(x) {
        let strings = ['Strong Negative', 'Negative', 'Neutral', 'Positive', 'Strong Positive']
        let idx = (x==0) ? 0 : Math.ceil((x*10)/2) - 1
        return strings[idx]
      },
      getSentimentSymbol(x) {
        let symbols = ['--', '-', '==', '+', '++']
        let idx = (x==0) ? 0 : Math.ceil((x*10)/2) - 1
        return symbols[idx]
      },
    }
  }
</script>

<style>
  .bubble { width: 100%; display: inline-block; padding: 4px; border-radius: 4px; color: #fff; white-space: nowrap; text-align: center}
  .symbolBubble { width: 20%; display: inline-block; padding: 4px; border-radius: 4px; color: #fff; white-space: nowrap; text-align: center}
  .SentimentStrongPositive { background-color: rgba(140, 199, 63, 1); }
  .SentimentPositive { background-color: rgba(174, 212, 124, 1); }
  .SentimentNeutral { background-color: rgba(184, 184, 184, 1); }
  .SentimentNegative { background-color: rgba(201, 123, 107, 1); }
  .SentimentStrongNegative { background-color: rgba(179, 63, 38, 1); }

</style>
