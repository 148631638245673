<template>
  <div>
    <span v-if="userData.name!==''" :title="userData.username + ` (` + userData.id + `)`">{{userData.firstName}} {{userData.lastName}}</span>
    <span v-else>Please Sign In</span>
  </div>
</template>

<script>
export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style scoped>

  span {
    display: inline-block;
    margin: 0 .5rem;
    cursor: help;
  }

</style>
