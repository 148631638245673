<template>
  <div class="scorecard-subcategory-outer-container-sub">
    <v-hover v-slot="{ hover }">
      <v-card :elevation="hover ? 1 : 0 || subCategory.expanded ? 1 : 0" class="mx-6 my-1 pr-2" :color="subcategoryColor">
        <v-row>
          <v-col cols="11">
            <v-text-field
              class="pl-2"
              ref="titleField"
              :rules="titleRules"
              v-model="subCategory.sub_category_title"
              counter
              dense
              outlined
              :maxlength="maxNameLength"
              :hint="subcategoryHint"
              persistent-hint
              label="Subcategory Name"
              placeholder="subcategory name goes here"
              @input="updateTitle"
              background-color="rgba(153, 153, 204, .1)"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn
              x-small
              color="blue-grey"
              class="white--text"
              @click="expandAll"
              v-if="!subCategory.expanded"
            >
              Expand
              <span title="Expand editor"> </span>
            </v-btn>
            <v-btn
              x-small
              color="blue-grey"
              class="white--text"
              @click="collapseAll"
              v-if="subCategory.expanded"
            >
              Collapse
            </v-btn>
          </v-col>
        </v-row>
        <v-row :class="subCategory.id != lastSubCatId ? 'border-class' : ''">
          <v-row
            v-if="categoryScoringRule == 0 && subCategory.expanded"
            align="center"
          >
            <v-col cols="4" class="pt-0 pb-0">
              <h4 class="selection-headers float-left pl-12 pt-2">
                Scoring Rule Weight
              </h4>
              <v-text-field
                dense
                v-model="selectedWeight"
                @input="activateButtons"
                single-line
                type="number"
                label="0-10"
                class="pl-4"
                outlined
                :max="10"
                :min="0"
                :disabled="categoryScoringRule == 1"
                :rules="numberRule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-if="subCategory.expanded">
          <v-col class="pl-5 ml-5 pr-12 mr-12 pt-0 pb-0">
            <subcategory-editor
              :selectedSearches="subCategory.searches"
              :selectedWeight="Number(selectedWeight)"
              :scoringType="Number(scoringType)"
              :subCategory="subCategoryInfo"
              :subCategoryOrig="subCategoryOrig"
              :categoryId="categoryId"
              :scorecardId="scorecardId"
              :subCategoryCount="subCategoryCount"
              :canCancelAndApply="disabledButtons"
              @updateSearchSelections="updateSubCategorySearches"
              @cancelSelections="cancelSelections"
              @deleteSubCategory="deleteSubCategory"
            >
            </subcategory-editor>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import SubcategoryEditor from "./SubcategoryEditor.vue";

export default {
  props: {
    subCategory: Object,
    categoryId: Number,
    scorecardId: Number,
    subCategoryCount: Number,
    categoryScoringRule: Number,
    expanded: Boolean,
    lastSubCatId: Number,
    catgoryManualScoring: Number,
    scoringType: Number,
    totalWeight: Number,
  },
  components: {
    SubcategoryEditor,
  },
  data() {
    return {
      selectedWeight: 1,
      maxNameLength: 120,
      title: "",
      subCategoryInfo: Object.assign({}, this.subCategory),
      subCategoryOrig: Object.assign({}, this.subCategory),
      titleRules: [(value) => !!value || "A title is required."],
      disabledButtons: this.subCategory.cancel ? false : true,
      numberRule: [
        (v) =>
          (!isNaN(parseFloat(v)) && v >= 0 && v <= 10 && Number(v) % 1 === 0) ||
          "Number must be between 0-10",
      ],
    };
  },
  computed: {
    subcategoryHint() {
      if(!this.subCategory.expanded) {
        if(this.categoryScoringRule == 0) {
          return 'Scoring Rule Weight = ' + this.subCategory.weight + ' (' + this.getSubcategoryPercentage() + ')'
        } else {
          let pl = (this.subCategory.searches.length != 1) ? 'es' : ''
          return this.subCategory.searches.length + ` search${pl} selected`
        }
      } else {
        return ''
      }
    },
    subcategoryColor() {
      return (this.subCategory.expanded) ? 'rgba(179, 212, 252, .15)' : ''
    }
  },
  methods: {
    getSubcategoryPercentage() {
      let percent = 0;
      if (this.totalWeight > 0) {
        percent = this.selectedWeight / this.totalWeight;
      }
      return Math.round(percent * 100) + "%";
    },
    activateButtons() {
      this.disabledButtons = false;
    },
    updateTitle() {
      let params = this.getParams();
      params.only_title = true;
      this.$emit("updateSubCategory", params);
    },
    deleteSubCategory() {
      this.collapseAll();
      this.$emit("deleteSubCategory");
    },
    updateSubCategorySearches(searches) {
      let updatedSearches = searches.map((a) => a.id).sort();
      let currentSearches = this.subCategoryInfo.searches.map((a) => a.search_id).sort();
      let searchCheck = this.checkSearchDifference(updatedSearches, currentSearches);
      if (!searchCheck) {
        this.subCategoryInfo.searches = searches.map((obj) => ({
          search_id: obj.id,
          id: obj.db_id,
          new: obj.new
        }));

        this.sendSubcategoryUpdate();
      }
    },
    checkSearchDifference(updateSearches, currentSearches) {
      return (
        updateSearches.length === currentSearches.length &&
        updateSearches.every((v, i) => v === currentSearches[i])
      );
    },
    cancelSelections(params) {
      this.subCategoryInfo = Object.assign({}, params);
      this.subCategoryInfo.searches = params.searches;
      this.selectedWeight = Number(params.weight);
      this.subCategoryInfo.cancel = false;
      this.disabledButtons = true;
      this.sendSubcategoryUpdate();
    },
    subCategoryChanged() {
      this.subCategoryInfo = Object.assign({}, this.subCategory);
    },
    updateWeight() {
      if (Number(this.selectedWeight) != this.subCategoryOrig.weight) {
        this.subCategoryInfo.weight = this.selectedWeight;
        this.subCategoryInfo.cancel = true;
        this.disabledButtons = false;
        this.sendSubcategoryUpdate();
      }
    },
    collapseAll() {
      this.subCategoryInfo.expanded = false;
      this.disabledButtons = true;
      this.$emit("updateSubCategoryExpanded", {
        value: this.subCategoryInfo.expanded,
        id: this.subCategoryInfo.id,
      });
    },
    expandAll() {
      this.subCategoryInfo.expanded = true;
      this.$emit("updateSubCategoryExpanded", {
        value: this.subCategoryInfo.expanded,
        id: this.subCategoryInfo.id,
      });
    },
    categoryManualScoringChanged() {
      if (this.catgoryManualScoring == 0) {
        this.sendSubcategoryUpdate();
      }
    },
    sendSubcategoryUpdate() {
      let params = this.getParams();
      this.$emit("updateSubCategory", params);
    },
    getParams() {
      return {
        id: this.subCategory.id,
        scorecard_id: this.scorecardId,
        category_id: this.categoryId,
        subcategory_id: this.subCategory.id,
        sub_category_info: this.subCategoryInfo,
        sub_category_title: this.subCategory.sub_category_title,
      };
    },
  },
  updated() {},
  mounted() {
    this.subCategoryInfo = Object.assign({}, this.subCategory);
    this.subCategoryInfo.expanded = this.expanded;
    this.selectedWeight =
      Number(this.subCategory.weight) || Number(this.subCategory.weight) == 0
        ? Number(this.subCategory.weight)
        : 1;
    this.disabledButtons = this.subCategory.cancel ? false : true;
    this.title = this.subCategory.sub_category_title;
  },
  watch: {
    selectedWeight: "updateWeight",
    catgoryManualScoring: "categoryManualScoringChanged",
  },
};
</script>

<style scoped>

  h4 {
    color: rgb(0, 0, 0);
  }

  div.scorecard-subcategory-outer-container-sub {
    padding: 0.5rem 5rem 0.25rem 2rem;
  }

</style>
