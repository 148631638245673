<template>
    <v-container class="pb-12">
    <v-card flat>
        <v-row>
          <v-card-title style="color:rgb(1, 68, 135);" class="pl-8 py-1">
          {{title}}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-actions class='pr-4 py-1'>
            <v-btn small color = "primary" @click="clearArray">
              Collapse All
            </v-btn>
            <v-btn small color = "primary" @click="expandArray">
              Expand All
            </v-btn>
          </v-card-actions>
        </v-row>
    </v-card>
  <v-expansion-panels
    v-model="panels"
    ref="panels"
    accordion 
    multiple>
    <v-expansion-panel
      v-for="(item,i) in items"
      :key="i"
    >
      <v-expansion-panel-header>
        <div>
          <span class="pa-0" v-show="item.titleIcon">
            <custom-icon class="panelIcon" :name="item.titleIcon"></custom-icon>
          </span>  
          <span v-show="item.question" v-html="item.question"></span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-card-text 
            v-html="item.answer">
          </v-card-text>
          <v-card-text v-show="item.icon">
            <custom-icon :name="item.icon"></custom-icon>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  </v-container>
</template>

<script>
export default{
  props: {
    items: Array,
    title: String,
  },

  data () {
		return {
      panels: [],
    }
  },
  methods: {

    clearArray() {
      this.panels = [];
    },
    expandArray() {
      this.panels = [];
      for(let i=0; i<this.items.length; i++) {
        this.panels.push(i);
      }
    }
  }
}


</script>

<style>
.panelIcon {
    color:rgba(0,0,0,.54);
    font-size: 1.8rem !important;
    text-align: center !important;
  }
</style>