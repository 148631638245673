<template>
    <v-menu offset-y bottom right open-on-hover close-delay="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab x-small color="primary" v-bind="attrs" v-on="on" class="mb-4">
          <v-icon >mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <div v-if="!isLegacy" :title="getMetadataTitle" >
            <v-list-item link @click="$emit('menuClick', 'customMetadataClick')" v-bind:disabled="customMetadataDisabled">
                <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon :class="getMetadataClasses">mdi-folder-information-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                <v-list-item-title v-if="!customMetadataDisabled" class="grey--text text--darken-1">Custom Metadata</v-list-item-title>
                <v-list-item-title v-else class="grey--text text--darken-1">Custom Metadata Unavailable</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </div>

        <div :title="notesTitle">
            <v-list-item link @click="$emit('menuClick', 'notesClick')">
            <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon v-if="notePriv==='NONE'" color="rgba(0, 0, 0, 0.08)">mdi-note</v-icon>
                <v-icon v-else-if="Boolean(showNotesAsEmpty)" color="rgba(0, 0, 0, 0.3)">mdi-note-outline</v-icon>
                <v-icon v-else color="rgba(25, 118, 210, 0.6)">mdi-note-text</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="grey--text text--darken-1">Notes</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </div>

        <div :title="tagsTitle">
            <v-list-item link @click="$emit('menuClick', 'tagsClick')" >
            <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon v-if="tagPriv==='NONE'" color="rgba(0, 0, 0, 0.08)" >mdi-tag</v-icon>
                <v-icon v-else-if="showTagsAsEmpty" color="rgba(0, 0, 0, 0.3)" >mdi-tag-outline</v-icon>
                <v-icon v-else color="rgba(25, 118, 210, 0.6)" >mdi-tag-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="grey--text text--darken-1">Tags</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </div>

        <div v-if="menuLocation!='printableCallScorecard' && !selfSignedTranscript" :title="emailTitle">
            <v-list-item link @click="$emit('menuClick', 'sendEmailClick')" >
            <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon>mdi-email-arrow-right-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-if="menuLocation=='transcript'" class="grey--text text--darken-1">Email Transcript</v-list-item-title>
                <v-list-item-title v-else-if="menuLocation=='callScorecard' || 'printableCallScorecard'" class="grey--text text--darken-1">Send Email</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </div>

        <div v-if="!isLegacy && transcriptExports && (menuLocation=='transcript' || menuLocation=='callScorecardTranscript' ) && transcriptExists" :title="exportTitle" >
            <v-list-item v-if="!selfSignedTranscript" link @click="$emit('menuClick', 'exportTranscriptClick')" v-bind:disabled="transcriptProcessStatus">
            <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon v-if="!transcriptProcessStatus">mdi-tray-arrow-down</v-icon>
                <v-icon v-else disabled>mdi-tray-arrow-down </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="grey--text text--darken-1">Download Transcript</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </div>

        <div v-if="menuLocation=='callScorecard' || menuLocation=='callScorecardTranscript'" :title="'Open printable scorecard in a new tab...'" >
            <v-list-item link @click="openTab">
            <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon v-if="true" class="float-right" >mdi-newspaper-variant-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="grey--text text--darken-1">Open Printable Scorecard</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </div>

        <div v-if="menuLocation=='callScorecard' || menuLocation=='printableCallScorecard'" :title="transcriptsTitle" >
            <v-list-item link @click="transcriptClick">
            <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon v-if="transcriptions" class="float-right" >mdi-message-text-outline</v-icon>
                <v-icon v-else disabled class="float-right" >mdi-message-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title v-if="transcriptions" class="grey--text text--darken-1">Open Transcript</v-list-item-title>
                <v-list-item-title v-else disabled class="grey--text text--darken-1">Transcript Disabled</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </div>
        <div v-if="menuLocation=='printableCallScorecard'" :title="'Print call scorecard'" >
            <v-list-item link @click="printPage">
            <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon class="float-right" >mdi-printer</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="grey--text text--darken-1">Print Call Scorecard</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </div>

        <div v-if="isTranscriptModal" :title="'Open Transcript Window in a New Tab'" >
            <v-list-item link @click="$emit('menuClick', 'openTranscriptInNewTabClick')">
            <v-list-item-icon class="grey--text text--darken-1 mr-2">
                <v-icon class="float-right" >mdi-open-in-new</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="grey--text text--darken-1">Open in New Tab</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </div>


      </v-list>
    </v-menu>
  </template>
  <script>
import moment from 'moment';

export default {
    props: {
        isLegacy: {
            type: Boolean,
            required: true,
        },
        tagPriv: {
            type: String,
            required: true,
        },
        hasTags: {
            type: Boolean,
            required: true
        },
        notePriv: {
            required: true,
        },
        noteIsBlank: {
            type: Number,
            required: true
        },
        menuLocation: {
            type: String,
            required: true
        },
        selfSignedTranscript: {
            type: Boolean,
            required: false,
            default: false,
        },
        customMetadataDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        transcriptProcessStatus: {
            type: Boolean,
            required: false,
            default: false,
        },
        transcriptExports: {
            type: Boolean,
            required: false,
            default: true,
        },
        printableScorecardUrl: {
            type: String,
            required: false,
            default: "",
        },
        transcriptions: {
            type: Boolean,
            required: false,
            default: false,
        },
        transcriptExists: {
            type: Boolean,
            required: false,
            default: true,
        },
        isTranscriptModal: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        showTagsAsEmpty: true,
        showNotesAsEmpty: true,
    }),
    computed: {
        getMetadataClasses: function() {
            // if in the insights call log, don't float right
            let cls = ""
            if (this.customMetadataDisabled) {
                cls += " no-metadata";
            } else {
                cls += " view-metadata";
            }
            return cls;
        },

        getMetadataTitle: function() {
            if (this.customMetadataDisabled) {
                return "No custom metadata for this call...";
            } else {
                return "Custom metadata for this call...";
            }
        },

        notesTitle() {
            if(this.notePriv==='NONE') {
                return "You are not authorized to read or write notes"
            } else {
                return "Notes"
            }
        },

        tagsTitle() {
            if(this.tagPriv==='NONE') {
                return "You are not authorized to read, assign or create tags"
            } else {
                return "Tags"
            }
            
        },

        emailTitle() {
            if(this.menuLocation=='transcript') {
                return "Email Transcript..."
            } else {
                return "Send Email..."
            }
        },

        exportTitle() {
            if(!this.transcriptProcessStatus) {
                return "Add to export queue..."
            } else {
                return "Already in queue..."
            }
        },
        transcriptsTitle() {
            if(this.transcriptions) {
                return "Open Transcript..."
            } else {
                return "Transcript access is not enabled for this user/account"
            }
        },
        printableCallScorecardURL() {
            let url = ""
            if(this.selfSignedTranscript) {
                url = process.env.VUE_APP_BASE_URL + "/scorecards-v2-signed/" + this.$route.params.scorecardId + "/dynalabels/" + this.$route.params.dynalabelId + "/agents/" + this.$route.params.agentId + "/calls/" + this.$route.params.callId + "/printable-call-scorecard/" + this.$route.params.reckey + "/" + this.$route.params.userid + "?start_date=" + moment(this.$route.query.start_date).format("YYYY-MM-DD") + "&end_date=" + moment(this.$route.query.end_date).format("YYYY-MM-DD")
                url += this.$store.getters["filters/scorecardAdjustments"]
                ? "&call_type=" + this.$store.getters["filters/scorecardAdjustments"]
                : "all";
            } else {
                let routeHash = this.$route.hash
                let callId = routeHash.replace("#call-scorecard-",'')
                url = process.env.VUE_APP_BASE_URL + "/scorecards-v2/" + this.$route.params.scorecardId + "/dynalabels/" + this.$route.params.dynalabelId + "/agents/" + this.$route.params.agentId + "/calls/" + callId + "/printable-call-scorecard/" + "?start_date=" + moment(this.$route.query.start_date).format("YYYY-MM-DD") + "&end_date=" + moment(this.$route.query.end_date).format("YYYY-MM-DD")
                url += this.$store.getters["filters/scorecardAdjustments"]
                ? "&call_type=" + this.$store.getters["filters/scorecardAdjustments"]
                : "all";
            }
            return url
        },

    },
    methods: {
        openTab() {
            window.open(this.printableCallScorecardURL, "_blank")
        },
        printPage() {
            // sleep to have the menu close
            this.sleep(100).then(() => this.printFunction())
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        printFunction() {
            window.print()
        },
        transcriptClick() {
            if(this.transcriptions) {
                this.$emit('menuClick', 'openTranscriptsClick')
            }
        }

    },
    watch: {
        hasTags: {
            handler: function () {
                this.showTagsAsEmpty = !this.hasTags
            }
        },
        noteIsBlank: {
            handler: function () {
                this.showNotesAsEmpty = this.noteIsBlank
            }
        }

    },
    mounted() {
      this.showTagsAsEmpty = !this.hasTags
      this.showNotesAsEmpty = this.noteIsBlank
    },

}
  </script>