import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("insights", ["insightsAdHocSearchPath"])
  },
  methods: {
    handleAdHocSearch(path, query, refs) {
      if (!this.insightsAdHocSearchPath.includes("/calls")) {
        // if the new path doesn't include the search path as a root, then reset the search
        if (!path.includes(this.insightsAdHocSearchPath)) {
          delete query.phrase;
          delete query.speaker;
          if (
            refs &&
            refs.hasOwnProperty("adHocSearch") &&
            refs.adHocSearch.hasOwnProperty("search")
          ) {
            refs.adHocSearch.search = "";
          }
        }
      }
    },
    selectInsightPage(location, useContext, refs) {
      let dynalabel_id = useContext
        ? parseInt(this.$route.query.context_dynalabel_id)
        : this.$route.params.dynalabelId;
      let insight_id = useContext
        ? parseInt(this.$route.query.context_id)
        : this.$route.params.id;

      if (location == "calls") {
        if (useContext) {
          let agent_id = parseInt(this.$route.query.context_agent_id);
          let path =
            "/insights/" +
            insight_id +
            "/dynalabels/" +
            dynalabel_id +
            "/agents/" +
            agent_id +
            "/calls/";
          this.redirectRoute(path, this.$route.query);
        }
      } else if (location == "agents") {
        let path =
          "/insights/" +
          insight_id +
          "/dynalabels/" +
          dynalabel_id +
          "/agents/";
        let query = this.$route.query;

        this.handleAdHocSearch(path, query, refs);
        this.redirectRoute(path, query);
      } else if (location == "dynalabels") {
        let path = "/insights/" + insight_id + "/dynalabels/";
        let query = this.$route.query;

        this.handleAdHocSearch(path, query, refs);
        this.redirectRoute(path, query);
      } else {
        if (this.$refs.adHocSearch && this.$refs.adHocSearch.search) {
          this.$refs.adHocSearch.search = "";
        }
        this.$router.replace({ "query.phrase": null, "query.speaker": null });
        this.$router.push({ name: "insights-dashboard" }).catch(err => {});
        setTimeout(function() {
          if (this.$vuetify) this.$vuetify.goTo(0);
        }, 1500);
      }
    },
    selectScorecardPage(location, useContext) {

      let dynalabel_id =
        useContext == "transcript"
          ? parseInt(this.$route.query.context_dynalabel_id)
          : this.$route.params.dynalabelId;
      let scorecard_id =
        useContext == "transcript"
          ? parseInt(this.$route.query.context_id)
          : this.$route.params.scorecardId;

      if (location == "calls") {
        if (this.$route.name == "printable-call-scorecard-v2") {
          this.redirectRoute(
            "/scorecards-v2/" +
              scorecard_id +
              "/dynalabels/" +
              dynalabel_id +
              "/agents/" +
              this.$route.params.agentId +
              "/calls/",
            this.$route.query
          );
        }
        if (useContext) {
          let path = "";
          let agent_id = 0;
          if (
            useContext == "transcript" &&
            this.$route.name != "printable-call-scorecard-v2"
          ) {
            agent_id = parseInt(this.$route.query.context_agent_id);
          } else if (useContext == "scorecard") {
            agent_id = this.$route.params.agentId;
          }
          path =
            "/scorecards-v2/" +
            scorecard_id +
            "/dynalabels/" +
            dynalabel_id +
            "/agents/" +
            agent_id +
            "/calls/";
          this.redirectRoute(path, this.$route.query);
        }
      } else if (location == "dashboard") {
        this.redirectRoute("/scorecards-v2/", this.$route.query);
      } else if (location == "dynalabels") {
        this.redirectRoute(
          "/scorecards-v2/" + scorecard_id + "/dynalabels/",
          this.$route.query
        );
      } else {
        this.redirectRoute(
          "/scorecards-v2/" +
            scorecard_id +
            "/dynalabels/" +
            dynalabel_id +
            "/agents/",
          this.$route.query
        );
      }
    },
    redirectRoute(path, query) {
      this.$router.push({ path: path, query: query }).catch(err => {});
      setTimeout(function() {
        if (this.$vuetify) this.$vuetify.goTo(0);
      }, 1500);
    }
  }
};
