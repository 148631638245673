<template>
  <div>

    <v-badge color="red" overlap>
      <v-icon v-if="filterNotifications.length > 0" dark x-small slot="badge">{{
        filterNotifications.length
      }}</v-icon>
      <v-btn depressed icon :ripple="false" @click="openNotificationsMenu">
        <v-icon
          v-if="notificationsList.length > 0"
          :title="notificationsList.length + ' items in export queue '"
          >mdi-tray-full</v-icon
        >
        <v-icon title="Export queue is empty" v-else>mdi-tray</v-icon>
      </v-btn>
    </v-badge>
    <v-menu
      v-model="showMenu"
      absolute
      offset-y
      :position-x="menuPosition.x"
      :position-y="menuPosition.y"
      style="max-width: 400px"
    >
      <v-list dense elevation="1">
        <v-list-item v-for="item in notificationsList" :key="getItemId(item)">
          <v-list-item-content>
            <template v-if="item.type == 'transcript'">
              <v-list-item-title>
                {{ item.agent }}
                <span v-if="item.processing"> (processing) </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div>
                  {{
                    formatCallNumber(
                      item.callDirection,
                      item.callerNumber,
                      item.calledNumber,
                      item.id
                    )
                  }}
                </div>
                <div>{{ formatDate(item.callDate) }}</div>
                <div>
                  {{ formatAccount(item.accountName, item.accountNumber) }}
                </div>
              </v-list-item-subtitle>
            </template>
            <template v-else>
              <v-list-item-title>
                {{ item.title }}
                <span v-if="item.processing"> (processing) </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div>{{ item.dateRange }}</div>
              </v-list-item-subtitle>
            </template>
          </v-list-item-content>
          <v-list-item-action>
            <a v-if="!item.processing" download :href="item.url">
              <v-btn color="primary" @click="notificationAction(item)" text>
                DOWNLOAD
              </v-btn>
            </a>
            <v-progress-linear
              v-else
              class="downloading"
              indeterminate
              style="width:6rem"
              color="blue darken-2"
            ></v-progress-linear>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import moment from "moment";

export default {
  data() {
    return {
      toggleSelect: false,
      showMenu: false,
      menuPosition: {
        x: 0,
        y: 0
      },
      fileContents: ""
    };
  },
  computed: {
    filterNotifications() {
      return this.notificationsList.filter(notification => {
        return !notification.processing;
      });
    },
    ...mapGetters("notifications", ["notificationsList", "transcriptFileInfo", "insightsFileInfo", "scorecardFileInfo"]),
    ...mapGetters("users", ["userData"]),
    ...mapGetters("authentication", ["isAuthenticated"]),
    userId() {
      return this.userData.id;
    },
  },
  methods: {
    ...mapActions({
      updateNotificationsList: "notifications/updateNotificationsList",
      removeNotificationsListItem: "notifications/removeNotificationsListItem",
      retrieveNotificationList: "notifications/retrieveNotificationList",
      clearNotificationsList: "notifications/clearNotificationsList"
    }),
    openNotificationsMenu(e) {
      this.showMenu = true;
      this.menuPosition.x = e.clientX - 110;
      if (e.clientY < 60) {
        this.menuPosition.y = 60;
      } else {
        this.menuPosition.y = e.clientY;
      }
    },
    notificationAction(notification) {
      if (notification.type == "transcript" || notification.type == 'insights' || notification.type == 'scorecard') {
        this.removeNotificationsListItem(notification);
      }
    },
    updateTranscript(transcript) {
      if (!transcript.processing) {
        let params = {
          id: transcript.id,
          processing: false,
          user_id: this.userId,
          date: transcript.date,
          url: this.transcriptFileInfo.url,
          accountNumber: transcript.accountNumber,
          accountName: transcript.accountName,
          agent: transcript.agent,
          callerNumber: transcript.callerNumber,
          callDirection: transcript.callDirection,
          calledNumber: transcript.calledNumber,
          callDate: transcript.callDate,
          type: "transcript"
        };
        this.updateNotificationsList(params);
      }
    },
    updateInsights(insights) {
      if (!insights.processing) {
        let params = {
          id: insights.id,
          guid: insights.guid,
          processing: false,
          url: this.insightsFileInfo.url,
          user_id: this.userId,
          title: insights.title,
          dateRange: insights.dateRange,
          type: 'insights'
        };
        this.updateNotificationsList(params);
      }
    },
    updateScorecards(scorecard) {
      if (!scorecard.processing) {
        let params = {
          id: scorecard.id,
          guid: scorecard.guid,
          processing: false,
          url: this.scorecardFileInfo.url,
          user_id: this.userId,
          title: scorecard.title,
          dateRange: scorecard.dateRange,
          type: 'scorecard'
        };
        this.updateNotificationsList(params);
      }
    },
    checkAuthentication() {
      if (!this.isAuthenticated) {
        this.clearNotificationsList();
      }
    },
    formatDate: function(date) {
      var displayDate = moment(date);
      if (displayDate.isValid()) {
        return displayDate.format("dddd, MMMM D, YYYY, h:mmA") || "";
      }
    },
    formatNumber: function(direction, number, callId) {
      var num = parsePhoneNumberFromString(String(number), "US");
      if (num) {
        if (direction == "Inbound") {
          return (
            direction +
            " call from " +
            num.formatNational() +
            " - (" +
            callId +
            ")"
          );
        } else {
          return (
            direction +
            " call to " +
            num.formatNational() +
            " - (" +
            callId +
            ")"
          );
        }
      }
    },
    formatAccount: function(name, number) {
      if (name && number) return "For " + name + " (" + number + ")";
    },
    formatCallNumber(direction, callerNumber, calledNumber, id) {
      direction = direction == "in" ? "Inbound" : "Outbound";
      if (direction == "Inbound") {
        return this.formatNumber(direction, callerNumber, id);
      } else {
        return this.formatNumber(direction, calledNumber, id);
      }
    },
    getItemId(item) {
      return (item.hasOwnProperty('guid') ? item.guid : item.id)
    },
  },
  mounted() {
    this.retrieveNotificationList(this.userId);
  },
  watch: {
    isAuthenticated: "checkAuthentication",
    transcriptFileInfo: function() {
      this.updateTranscript(this.transcriptFileInfo);
    },
    insightsFileInfo: function() {
      this.updateInsights(this.insightsFileInfo);
    },
    scorecardFileInfo: function() {
      this.updateScorecards(this.scorecardFileInfo);
    },
  }
};
</script>
<style scoped>
a {
  color: inherit;
  text-decoration: none;
}

.downloading {
  width: 4rem;
  margin-right: 1rem;
  margin-left: 1rem;
}
</style>
