<template>
  <div>
    <v-icon v-if="tagPriv==='NONE' && displaySoup" color="rgba(0, 0, 0, 0.08)" title="You are not authorized to read, assign or create tags">mdi-tag</v-icon>
    <v-dialog v-else v-model="tagsDialog" persistent max-width="600px">
      <template  v-if="displaySoup" v-slot:activator="{ on }">
          <v-icon v-on="on" v-if="showAsEmpty" color="rgba(0, 0, 0, 0.3)" title="Tags" @click="loadTags">mdi-tag-outline</v-icon>
          <v-icon v-on="on" v-else color="rgba(25, 118, 210, 0.6)" title="Tags"  @click="loadTags">mdi-tag-text-outline</v-icon>
      </template>
      <v-card>
        <v-progress-linear
          indeterminate
          :active="loading||saving"
        ></v-progress-linear>
        <v-form>
          <v-card-title>
            <span class="headline">{{ dialogPrefix }} Tags on Call Id {{callId}}</span>
            <v-spacer></v-spacer>
            <v-btn x-small text icon color="primary" @click="tagsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="loadingError!=''">
          </v-card-text>
          <v-card-text v-else>
            <v-container fluid>
              <v-row v-if="tagPriv=='WRITE'||tagPriv=='ASSIGN'">
                <v-col cols="3" class="pt-1 pb-0">
                  <p class="title">Filter by Tag<br/><span>({{ selectedTagsCount }} tags selected)</span></p>
                </v-col>
                <v-col cols="9" class="pt-0 pb-0">
                  <v-text-field
                    dense
                    v-model="search"
                    placeholder="Find Tags..."
                    append-icon="mdi-magnify"
                    clearable
                    @click:clear="clearSearch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pa-0">
                  <div class="list-container">
                    <template v-if="tagPriv=='WRITE'||tagPriv=='ASSIGN'">
                      <v-chip
                        label
                        small
                        v-for="item in filteredTagsList"
                        :key="item.id"
                        :active-class="(item.selected) ? 'primary':''"
                        :input-value="item.selected"
                        @click="item.selected = !item.selected"
                      >
                        <span class="tag-name" :title="item.name">{{item.name}}</span>
                      </v-chip>
                    </template>
                    <template v-if="tagPriv=='READ'">
                      <v-chip
                        label
                        small
                        v-for="item in selectedTagsList"
                        :key="item.id"
                        :active-class="(item.selected) ? 'primary':''"
                        :input-value="item.selected"
                      >
                        <span class="tag-name" :title="item.name">{{item.name}}</span>
                      </v-chip>
                    </template>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="tagPriv=='WRITE'||tagPriv=='ASSIGN'">
                <v-col cols="12" class="pa-0">
                  <v-btn text small color="primary" @click="selectAllTags">Select All</v-btn>
                  <v-btn text small color="primary" @click="unselectAllTags">Clear All</v-btn>
                  <v-btn text small color="primary" @click="showAll">Show All</v-btn>
                  <v-btn text small color="primary" @click="showSelected">Show Selected</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="tagPriv==='WRITE'">
                <v-col cols="12" pt-6 pb-2>
                  <v-form v-model="isValid">
                    <v-layout>
                      <v-text-field
                        v-model="newTag"
                        dense
                        placeholder="type a new tag"
                        :disabled="loading||saving"
                        :rules="[ isDupe ]"
                        class="pr-1"
                      >
                        <template v-slot:append>
                          <v-btn
                            x-small
                            :color="isValid ? 'primary' : ''"
                            :disabled="!isValid||saving"
                            @click="addTag"
                            :loading="addingTag"
                            class="ma-0">Add Tag</v-btn>
                        </template>
                      </v-text-field>
                    </v-layout>
                  </v-form>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="tagPriv=='WRITE'||tagPriv=='ASSIGN'" x-small color="primary" :disabled="saving" @click="tagsDialog = false">Cancel</v-btn>
            <v-btn v-if="tagPriv=='WRITE'||tagPriv=='ASSIGN'" x-small color="primary" :disabled="saving" @click="saveTags" :loading="saving">Save</v-btn>
            <v-btn v-if="tagPriv=='READ'" x-small color="primary" @click="tagsDialog = false">Close</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import axios from "@/utils/AxiosInstance.js";

  export default {
    props: {
      hasTags: {
        type: Boolean,
        required: true
      },
      callId: {
        type: Number,
        required: true
      },
      tagPriv: {
        type: String,
        required: true,
      },
      selfSignedTranscript: {
        type: Boolean,
        required: false,
        default: false,
      },
      displaySoup: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data: () => ({
      addingTag: false,
      newTag: '',
      tagsDialog: false,
      loading: true,
      loadingError: '',
      saving: false,
      mode: '',
      tags: [],
      search: '',
      showOnlySelected: false,
      isValid: true,
      showAsEmpty: true,
    }),
    watch: {
      hasTags: {
        handler: function () {
            this.showAsEmpty = !this.hasTags
        }
      },
      callId: {
        handler: function () {
          this.showAsEmpty = !this.hasTags
        }
      },
    },
    mounted() {
      this.showAsEmpty = !this.hasTags
    },
    computed: {
      filteredTagsList() {
        // bug in v-model for search - clearing search sets it to null, not an empty string, so fix it!
        if(this.search==null) this.search=''
        return (this.search==='') ?
          (this.showOnlySelected) ? this.tags.filter(tag => { return tag.selected === true })
            : this.tags
          :
          (this.showOnlySelected) ? this.tags.filter(tag => { return tag.name.toLowerCase().includes(this.search.toLowerCase()) && tag.selected})
            : this.tags.filter(tag => { return tag.name.toLowerCase().includes(this.search.toLowerCase()) })
      },
      selectedTagsList() {
        return this.tags.filter(tag => { return tag.selected === true })
      },
      selectedTagsCount() {
        return this.tags.filter(tag => { return tag.selected === true }).length
      },
      dialogPrefix() {
        return (this.tagPriv=='READ')
          ? 'View' : (this.tagPriv=='ASSIGN')
          ? 'Assign' : 'Edit'
      }
    },
    methods: {
      openDialog() {
        this.tagsDialog = !this.tagsDialog
        this.loadTags()
      },
      isDupe(v) {
        return this.tags.filter(tag => {return tag.name.toLowerCase() === v.toLowerCase()}).length==0 || "duplicate tags cannot be added"
      },
      addTag() {
        this.addingTag = true
        this.tags.push(
          {
            id: (this.tags.length + 1),
            name: this.newTag,
            selected: true,
          }
        )
        this.newTag = ''
        this.addingTag = false
      },
      loadTags() {
        this.tags = []
        this.loading = true
        let self = this

        let tags_url = process.env.VUE_APP_CALL_TAGS
        let axios_params = {
          params: {'call_id': this.callId}
        }
        if(this.selfSignedTranscript) {
          tags_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
          axios_params['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey} 
          axios_params['params']['route'] = 'tags'
        } 

        axios
          .get(tags_url, axios_params)
          .then(response => {
            if(response.status>=400) {
              this.loading = false
            } else {
              self.tags = []
              response.data.data.availableTags.data.forEach(function(val,indx){
                self.tags.push({'id': indx, 'name': val, 'selected': response.data.data.callTags.data.includes(val)})
              })
              self.tags.sort((a, b) => {
                if (a.selected < b.selected) {
                  return 1
                }
                if (a.selected > b.selected) {
                  return -1
                }
                return 0
              })
              this.loading = false
            }
          })
          .catch(error => {
            this.loading = false
          })
      },
      selectAllTags() {
        this.tags.forEach(function(val,idx,arr){
          if(val.selected===false) {
            arr[idx].selected = true
          }
        })
      },
      unselectAllTags() {
        this.tags.forEach(function(val,idx,arr) {
          arr[idx].selected = false
        })
      },
      showAll() {
        this.showOnlySelected = false
      },
      showSelected() {
        this.showOnlySelected = true
      },
      clearSearch() {
        this.search = ''
      },
      saveTags: function(e) {
        // ah, promises
        this.saving = true
        let self = this
        let tagList = this.tags.filter(tag => { return tag.selected === true }).map(seltag => { return seltag.name })

        let tags_url = process.env.VUE_APP_CALL_TAGS
        let axios_params = { 'call_id': this.callId, 'tags': tagList }
        let config = {headers:{}}
        
        if(this.selfSignedTranscript) {
          tags_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
          config['headers'] = {"Authorization": this.$route.params.userid + " " + this.$route.params.reckey} 
          axios_params['route'] = 'tags'
        } 

        axios
          .post(tags_url, axios_params, config)
          .then(response => {
            if (response !== undefined) {
              if (response.status >= 400) {
                alert('Tags not saved! The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
              } else {
                let pkg = { 'call_id': self.callId, 'has_tags': (self.tags.filter(tag => { return tag.selected === true }).length>0) }
                self.showAsEmpty = !pkg.has_tags ? 1 : 0
                this.$emit('update-call-has-tags', pkg)
              }
            } else {
              alert('Tags not saved! An un-catchable error occurred - probably a cross origin request denial');
            }
          })
          .catch(error => {
            // Error
            alert('Tags not saved! The server returned an error: "' + error);
          })
          .then(function () {
            self.tagsDialog = false
            self.saving = false
            self.showOnlySelected = false
          })

        e.preventDefault();

      }
    },
  }

</script>

<style scoped>

  .headline {
    color: #1976d2;
  }

  .list-container {
    min-height: 150px;
    max-height: 250px;
    overflow-y: scroll;
    padding: 8px;
    margin: 8px;
    border: 1px solid #eee;
  }

  .v-chip {
    margin: 2px 4px;
  }

  .tag-name {
    display: inline-block;
    width: 145px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p.title {
    font-size: 1rem !important;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1rem;
  }

  p.title span {
    font-size: small;
    font-weight: normal;
  }

</style>
