<template>
  <v-content style="height: calc(100%); max-height: calc(100%);" id="my-content">
    <v-container fluid pa-4 style="max-height: calc(100%);">
      <v-alert v-if="this.getDeliveryError" type="error" class="alert-message">
        There was an error while loading the deliveries. Please try again later.
      </v-alert>
      <v-row v-if="this.deliveryListLoadingStatus == 'loaded' && this.agentDataLoadingStatus == 'loaded'">
        <v-card width="100%" class="ma-4 mb-0" elevation="0" v-if="this.showFilters && this.filterableColumnFeatures.length>0">
          <v-card-title class="pt-0 pb-0">
            Filter By &hellip;
          </v-card-title>
          <v-card-text>
            <template>
              <v-row align="center" justify="center">
                <v-col v-for="(item, idx) in this.filterableColumnFeatures" :key="'acf'+idx">
                  <v-select
                    :ref="'sel_'+item"
                    v-model="selectVModels[item]"
                    :items="columnFeatures[item]"
                    :menu-props="{ maxHeight: '400' }"
                    :label="filterLabels[item]"
                    multiple
                    hint="Filter delivery list by selected items"
                    persistent-hint
                    clearable
                    dense
                    @input="updateFilteredMutatedDeliveryList"
                  ></v-select>
                </v-col>
                <v-col cols="1">
                  <v-btn color="primary" small @click="clearFilters">Clear Filters</v-btn>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>

        <v-card width="100%" class="mt-0 mb-0 ml-4" elevation="0">
          <v-card-title class="pt-0 pb-2">
            Scheduled Deliveries &hellip;
          </v-card-title>
        </v-card>

        <v-card width="100%" class="ma-4 mt-0 ml-8 mr-8">
          <v-card-title class="pt-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-alert v-if="this.filteredDeliveries.length == 0" type="warning">
              No Scheduled Deliveries were found meeting this search criteria. Please reset your filters and try a new search.
            </v-alert>
          </v-card-text>
          <v-data-table
            :headers="deliveryHeaders"
            :items="filteredMutatedDeliveryList"
            :items-per-page="15"
            :search="search"
            item-key="schedule_id"
            class="elevation-1"
          >

            <template v-slot:item.email="{ item }">
              <span>{{ item.email.join(", ") }}</span>
            </template>

            <template v-slot:item.name="{ item }">
              <span :title=" '(Delivery ID: ' + item.id + ')' " style="cursor: help;">{{item.name}}</span>
            </template>

            <template v-slot:item.export_type="{ item }">
              <span>{{item.export_type}}</span>
            </template>

            <template v-slot:item.dynalabel_name="{ item }">
              <span>{{item.dynalabel_name}}</span>
            </template>

            <template v-slot:item.agent_name="{ item }">
              <span>{{item.agent_name}}</span>
            </template>

            <template v-slot:item.delivery_hour="{ item }">
                {{item.delivery_hour}}{{item.ampm}}
            </template>

            <template v-slot:item.created_at="{ item }">
              <span :title="item.created_at">{{formatDate(item.created_at)}}</span>
            </template>

            <template v-slot:item.updated_at="{ item }">
              <span :title="item.updated_at">{{formatDate(item.updated_at)}}</span>
            </template>

            <template v-slot:item.editButton="{ item }">
              <v-btn icon x-small><v-icon @click="editScheduledItem(item.id)">mdi-pencil</v-icon></v-btn>
            </template>

          </v-data-table>
        </v-card>
      </v-row>
      <div v-else>
        loading...
      </div>
      <div>
        <scheduled-exports-modal
          :showScheduledExportsModal="showScheduledExportsModal"
          :target="target"
          :isNew="false"
          @HIDE_SCHEDULED_EXPORTS_MODAL="hideModal"
        ></scheduled-exports-modal>
      </div>
    </v-container>
  </v-content>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import ScheduledExportsModal from "@/components/Widgets/ScheduledExportsModal.vue";
import moment from "moment";

export default {
  name: "ScheduledDeliveries",
  props: {},
  components: {
    ScheduledExportsModal,
  },
  data() {
    return {
      selectVModels: {},
      search: '',
      scheduledItems: [],
      filteredDeliveries: [],
      filteredMutatedDeliveryList: [],
      searchModifiedDeliveries: [],
      frequencyList: ['Daily', 'Weekly', 'Monthly'],
      selectedFrequencies: [],
      emailList: [],
      selectedEmails: [],
      searchString: '',
      target: null,
      tab: 0,
      showScheduledExportsModal: false,
      showMenu: false,
      menuPosition: {
        x: 0,
        y: 0
      },
      deliveryHistory: [],
      deliveryHeaders: [
        {text: 'Name', align: 'start', sortable: true, value: 'name'},
        {text: 'Frequency', align: 'start', sortable: true, value: 'frequency'},
        {text: 'Time', align: 'start', sortable: true, value: 'delivery_hour'},
        {text: 'Day', align: 'start', sortable: true, value: 'delivery_day'},
        {text: 'Time Zone', align: 'start', sortable: true, value: 'timezone'},
        {text: 'Delivery Method', align: 'start', sortable: true, value: 'delivery_type'},
        {text: 'Export Type', align: 'start', sortable: true, value: 'export_type'},
        {text: 'Dynamic Label', align: 'start', sortable: true, value: 'dynalabel_name'},
        {text: 'Agent', align: 'start', sortable: true, value: 'agent_name'},
        {text: 'Grouping', align: 'start', sortable: true, value: 'aggregate_type'},
        {text: 'Recipient', align: 'start', sortable: true, value: 'email'},
        {text: 'Created', align: 'start', sortable: true, value: 'created_at'},
        {text: 'Updated', align: 'start', sortable: true, value: 'updated_at'},
        {text: 'Edit', value: 'editButton' },
      ],
      singleExpand: false,
      expanded: [],
      columnFeatures: {},
      columnFeaturesKeys: [],
      filterableColumnFeatures: [],
      showFilters: false,
      filterLabels: {},
    }
  },
  methods: {
    ...mapActions("deliveries", [
      "getDeliveryList",
      "setUpdateDeliveryFlag",
      "setReGetDeliveries",
      "setGetDeliveryError",
      "setRemoveDelivery",
    ]),
    ...mapActions("resources", [
      "retrieveAgentData",
    ]),
    ...mapActions("dynaLabels", [
      "retrieveDynaLabelsList",
    ]),
    ...mapActions("insights", [
      "retrieveInsightsList",
    ]),
    ...mapActions("scorecardsV2", [
      "retrieveScorecardsList",
    ]),
    formatDate(time) {
      return moment(time).format("M/D/YY");
    },
    clearFilters() {
      this.selectVModels = {}
      this.updateFilteredMutatedDeliveryList()
    },
    editScheduledItem(id) {
      // set the target and wait for the next tick before opening the dialog (prevents race condition)
      this.target = this.mutatedDeliveryList.find(o => o.id == id)
      this.$nextTick(() => {
        this.showScheduledExportsModal = true
      })
    },
    hideModal() {
      this.showScheduledExportsModal = false
    },
    setEmailList() {
      for (let i = 0; i < this.scheduledItems.length; i++) {
        if (this.scheduledItems[i].delivery_type == "Email") {
          this.scheduledItems[i].email.forEach(email => {
            this.emailList.includes(email) ? null : this.emailList.push(email)
          });
        }
      }
    },
    sortedScheduledItems() {
      return [...this.scheduledItems].sort(function (a, b) {
        if (a.name == b.name) {
          return (a.id > b.id) ? 1 : -1
        } else {
          return (a.name > b.name) ? 1 : -1
        }
      })
    },
    getDeliveries() {
      if (this.reGetDeliveries) {
        this.setReGetDeliveries(false)
        this.getDeliveryList();
        this.hideModal()
      }
    },
    deriveColumnFeatures() {
      const features = {}
      const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
      this.columnFeaturesKeys = Object.keys(this.mutatedDeliveryList[0])
      this.mutatedDeliveryList.forEach((o) => {
        this.columnFeaturesKeys.forEach((key) => {
          const value = o[key]
          if(!(value===null)) {
            // build feature array
            if (!features.hasOwnProperty(key)) {
              switch(key) {
                case 'email':
                  features[key] = [...value]
                  if (typeof this.deliveryHeaders.find( o => o.value == key) !== 'undefined') this.filterLabels[key] = this.deliveryHeaders.find( o => o.value == key)['text']
                  break
                default:
                  features[key] = [value]
                  this.selectVModels[key] = []
                  if (typeof this.deliveryHeaders.find( o => o.value == key) !== 'undefined') this.filterLabels[key] = this.deliveryHeaders.find( o => o.value == key)['text']
                  break
              }
            } else if (!features[key].includes(value)) {
              switch(key) {
                case 'email':
                  features[key] = [...features[key],...value]
                  break
                default:
                  features[key].push(value)
                  break
              }
            }
            // sort as we go since we're adding them as arrays
            features[key].sort(collator.compare)
            // ensure uniqueness for columns adding by array
            if(key === 'email') {
              features[key] = [...new Set(features[key])]
            }
          }
        })
      })
      this.columnFeatures = features
      this.filterableColumnFeatures = this.columnFeaturesKeys.filter( o => (
        this.columnFeatures.hasOwnProperty(o)
        && this.columnFeatures[o].length>1
        && ((this.columnFeatures[o].length !== this.scheduledItems.length
        && typeof this.filterLabels[o] !== 'undefined'))
        || o == 'email')
      )
      this.showFilters = true
    },
    updateFilteredMutatedDeliveryList() {
      let myList = [...this.mutatedDeliveryList]
      let keys = Object.keys(this.selectVModels)
      let filters = []
      keys.forEach((key) => {
        if(this.selectVModels[key].length>0) {
          filters.push([[key], this.selectVModels[key]])
        }
      })
      this.filteredMutatedDeliveryList = this.filterObjects(myList, filters);
    },
    filterObjects(objects, filters) {
      if (filters.length === 0) {
        return objects
      }
      const [filterKey, filterValues] = filters[0]
      const filteredObjects = objects.filter(obj => {
        let found = false
        if (!obj.hasOwnProperty(filterKey)) {
          return false // If object doesn't have the filter key, exclude it
        }
        switch(filterKey[0]) {
          case 'email':
            obj[filterKey].forEach(o => {
              if(filterValues.includes(o)) found = true
            })
            return found
          default:
            return filterValues.includes(obj[filterKey])
        }
      });
      // recursively call filterObjects with the remaining filters
      return this.filterObjects(filteredObjects, filters.slice(1))
    },
    doDeliveries() {
      if (this.deliveryListLoadingStatus == 'loaded' && this.agentDataLoadingStatus == 'loaded') {
        this.scheduledItems = this.deliveryList
        this.scheduledItems = this.sortedScheduledItems()
        this.filteredDeliveries = this.scheduledItems
        this.filteredMutatedDeliveryList = this.mutatedDeliveryList
        this.setEmailList()
        this.deriveColumnFeatures()
      }
    }
  },
  computed: {
    ...mapGetters('deliveries', [
      'deliveryList',
      'deliveryListLoadingStatus',
      'updateDeliveriesFlag',
      'reGetDeliveries',
      'getDeliveryError',
      'removeDelivery',
    ]),
    ...mapGetters('resources', [
      'agentData',
      'agentDataLoadingStatus',
    ]),
    ...mapGetters('dynaLabels', [
      'dynaLabelsList',
      'dynaLabelsListLoadingStatus',
    ]),
    ...mapGetters('insights', [
      'insightsListData',
      'insightsListDataLoadingStatus',
    ]),
    ...mapGetters('scorecardsV2', [
      'newScorecardsList',
      'newScorecardsListLoadingStatus',
    ]),
    mutatedDeliveryList() {
      return this.deliveryList.map( o => {
        let agent_name = ''
        let dynalabel_name = ''
        let export_type = ''
        let agentObject = this.agentData.find( p => p.id==o.agent_id)
        let dynalabelObject = this.dynaLabelsList.find( d => d.id == o.dynalabel_id)
        let aggregate_type = ''
        switch(Number(o.export_path) || 0) {
          case 1:
            aggregate_type = "By Dynamic Label"
            break
          case 2:
            aggregate_type = "By Agent"
            break
          case 0:
          default:
            aggregate_type = "By Call"
            break
        }
        export_type = (o.type == 0) ? "Scorecard" : "Insight"
        agent_name = (o.agent_id == 0 || typeof agentObject == 'undefined' ) ? ( o.export_path == 1 ? "" : "All Agents") : agentObject.name
        dynalabel_name = (o.dynalabel_id == 0 || typeof dynalabelObject == 'undefined' ) ? "All Dynamic Labels" : dynalabelObject.title
        return { ...o, agent_name: agent_name, export_type: export_type, dynalabel_name: dynalabel_name, aggregate_type: aggregate_type }
      })
    },
  },
  watch: {
    deliveryListLoadingStatus: "doDeliveries",
    agentDataLoadingStatus: "doDeliveries",
    reGetDeliveries: "getDeliveries",
  },
  mounted() {
    this.getDeliveryList()
    this.updateFilteredMutatedDeliveryList()
  }

};
</script>

<style scoped>

.export-list-container {
  padding: 1rem;
  width: 450px;
}

.export-list-container ::v-deep .v-window-item {
  border-bottom: 2px solid rgba(0, 0, 0, .1);
}

.list-size {
  max-height: 80vh;
  overflow-y: scroll;
}

.card-style {
  background-color: rgb(52, 136, 191);
}

.card-title {
  font-size: 1rem;
}

.card-subtitle {
  padding-top: 0;
  font-size: .8rem;
  line-height: 1rem;
  margin-bottom: .5rem;
}

.card-subtitle-details {
  font-weight: 900;
  margin-bottom: .5rem;
}

.card-subtitle-pseudobreadcrumb {
  margin-bottom: .5rem;
}

.edit-button {
  background-color: rgb(1, 106, 175) !important;
}

.v-menu__content {
  background-color: white;
}

.alert-message {
  margin: 2rem;
  width: 100%;
}

</style>
