<template>
  <v-col class="text-center">
    <v-row>
      <v-col cols="12">
        <echart :option="eChartOptions" autoresize :style="{ 'height': chartHeight - 60 + 'px' }" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import date from "@/mixins/date";
import moment from "moment";
import { mapGetters } from "vuex";
import colors from "@/mixins/colors";

export default {
  mixins: [date, colors],
  props: {
    scorecard: Object,
    chartHeight: Number,
    chartWidth: Number
  },
  data: () => ({
    model: 0,
    totalDocumentCount: 0,
    negativeSentimentCount: 0,
    strongNegativeSentimentCount: 0,
    positiveSentimentCount: 0,
    strongPositiveSentimentCount: 0,
    neutralSentimentCount: 0,
    type: "sentiment",
    unit: "day",
    hasTranscriptsAccess: "authorizing",
    transcriptDataStatus: "loading",
    accountSelections: [],
    timeSeriesData: [],
    thisThis:this
  }),
  methods: {},
  computed: {
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingError",
      "userDataLoadingErrorStatus"
    ]),
    ...mapGetters("dateRange", [
      "start_date",
      "end_date",
      "date_range_english"
    ]),
    ...mapGetters("filters", ["accounts", "agents"]),

    eChartOptions() {
      let myThis = this
      return {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let tooltipLegend = ''
            if(params[0].value==0) return tooltipLegend
            let val = params[0].name
            switch(myThis.getUnit) {
              case 'hour': {
                let dateParts = val.split(' ')
                let day = dateParts[0]
                let hour = parseInt(dateParts[1])
                tooltipLegend = moment(day).format("MMMM D") + ', '
                if (hour == 0) tooltipLegend += 'Midnight'
                else tooltipLegend += (hour == 12) ? '12pm' : (hour > 12) ? hour - 12 + 'pm' : hour + 'am'
                break;
              }
              case 'day':
              default:
                tooltipLegend = val.split(' ')[0]
            }
            let html = `
<table class='custom-tooltip'>
    <thead>
        <tr>
            <th colspan="3" style="text-align: left;">${tooltipLegend}</th>
        </tr>
    </thead>
    <tbody>
`
            params.forEach((o)=>{
              html += `
        <tr>
          <td style="text-align: left; font-weight: 700; color: ${o.color};">${o.seriesName}</td>
          <td>&nbsp;</td>
          <td style="text-align: right;">${Math.round(o.value*100)}%</td>
        </tr>
`
            })
            html += `    </tbody>
</table>
            `;

            return html
          }
        },
        xAxis: this.xAxis,
        yAxis: [
          {
            name: 'Average Score',
            min: 0,
            max: 1,
            nameTextStyle: {
              fontWeight: 'bold'
            },
            type: 'value',
            axisLabel: {
              formatter: function (value) {
                return Math.round(value * 100) + '%'
              }
            }
          }
        ],
        series: this.eChartSeries
      }
    },

    eChartSeries() {
      // Guard against no data in response
      if (!this.scorecard.hasOwnProperty("statistics") || this.scorecard.statistics.length === 0) {
        return [{'name':'', 'data':[]}];
      }
      const series = [];
      const seriesLabels = ["Score"];
      const allData = this.scorecard.statistics;
      for (let seriesLabel of seriesLabels) {
        let observations = {
          name: seriesLabel,
          lineStyle: {
            width: 4
          },
          symbolSize: 8,
          type: 'bar',
          itemStyle: {
            color: (o) => {
                  let keyColors = [
                    "#c20a36",
                    "#c23a26",
                    "#e26a16",
                    "#e29a06",
                    "#e2ba00",
                    "#f4bf22",
                    "#d5b800",
                    "#b5b800",
                    "#96b800",
                    "#77b800"
                  ];
                  let colorIndex = Math.ceil(o.data * 10) - 1;
                  return keyColors[colorIndex < 0 ? 0 : colorIndex];
            }
          },
          smooth: 0.5,
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: []
        }
        Object.entries(allData).forEach(entry => {
          let rowData = entry[1];
          let val = 0;
          if (rowData.hasOwnProperty("callScoreStats")) {
            val = rowData.callScoreStats;
          }
          observations.data.push(val);
        });
        series.push(observations);
      }
      return series;
    },

    xAxis() {
      let xAxis = [{
        type: 'category',
        data: this.scorecard.statistics.map(o => o.datetime),
        axisLabel: this.xAxisLabelFormatter,
        name: 'Average Scores By ' + this.getUnit.titleCase(),
        nameLocation: 'center',
        nameGap: 40,
        nameTextStyle: {
          fontWeight: 'bold'
        }
      }]
      return xAxis
    },

    xAxisLabelFormatter() {
      let formatter = {}
      switch(this.getUnit) {
        case 'hour':
          formatter = {
            'formatter':
              value => {
                if (value === undefined) return null
                let dateParts = value.split(' ')
                let day = dateParts[0]
                let hour = parseInt(dateParts[1])
                let hourFormatted = (hour == 12) ? '12 pm' : (hour > 12) ? hour - 12 + ' pm' : hour + ' am'
                if (hour == '00') return moment(day).format("MMM D")
                else return hourFormatted
              },
            'rotateAlways': true,
            'rotate': 45,
          }
          break;
        case 'day':
        default:
          formatter = {
            'formatter':
              value => {
                if (value) return value.split(' ')[0]
              },
          }
      }
      return formatter
    },

    getUnit() {
      return moment(this.end_date).diff(moment(this.start_date), "days") > 1
        ? "day"
        : "hour";
    },

  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>

</style>
