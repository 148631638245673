<template>
  <div>
    <v-row>
      <v-col cols="6">
        <fieldset>
          <legend> <setup-tip :section="'insight_dynalabel'"> </setup-tip> Dynamic Label Selections</legend>
          <v-data-table
            id="dynamicLabelsDataTbl"
            :loading="tablesLoading"
            loading-text="Loading... Please wait"
            v-model="dynaLabelsSelected"
            :headers="dynaLabelHeaders"
            :items="selectableDynaLabels"
            :search="dynaLabelSearch"
            :hide-default-footer="true"
            @item-selected="selectItem"
            @toggle-select-all="selectAll"
            item-key="id"
            show-select
            disable-pagination
            class="dl-dt"
            dense
            height="25vh"
          >
            <template v-slot:top>
              <v-text-field
                v-model="dynaLabelSearch"
                label="Find Dynamic Labels..."
                class="mx-4"
                single-line
                hide-details
                clearable
                append-icon="mdi-magnify"
              ></v-text-field>
            </template>
          </v-data-table>
        </fieldset>
      </v-col>
      <v-col cols="6">
        <fieldset>
          <legend> <setup-tip :section="'insight_accessrole'"> </setup-tip> Access Role Selections</legend>
          <v-data-table
            id="accessRolesDataTbl"
            :loading="tablesLoading"
            loading-text="Loading... Please wait"
            v-model="accessRolesSelected"
            :headers="accessRoleHeaders"
            :items="selectableAccessRoles"
            :search="accessRoleSearch"
            :hide-default-footer="true"
            @item-selected="selectItem"
            @toggle-select-all="selectAll"
            item-key="id"
            @click:row="rowSelected"
            show-select
            disable-pagination
            class="dl-dt row-pointer"
            dense
            height="25vh"
          >
            <template v-slot:top>
              <v-text-field
                v-model="accessRoleSearch"
                label="Find Access Roles..."
                class="mx-4"
                single-line
                hide-details
                clearable
                append-icon="mdi-magnify"
              ></v-text-field>
            </template>
          </v-data-table>
        </fieldset>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <fieldset>
          <legend> <setup-tip :section="'insight_talktime'"> </setup-tip> Talk Time Limits</legend>
          <v-row>
            <v-col cols="6" class="d-flex justify-center align-center">
              <h5 style="padding-right:1rem;">Minimum</h5>
              <!-- min -->
              <vue-timepicker
                format="HH:mm:ss"
                v-model="minTime"
                :class="minTalktimeError"
                @change="minTalkTimeHandler"
              ></vue-timepicker>
            </v-col>
            <v-col cols="6" class="d-flex justify-center align-center">
              <h5 style="padding-right:1rem;">Maximum</h5>
              <!-- max -->
              <vue-timepicker
                format="HH:mm:ss"
                v-model="maxTime"
                :class="maxTalktimeError"
                @change="maxTalkTimeHandler"
              ></vue-timepicker>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="pa-0">
            <v-spacer></v-spacer>
            <v-col cols="4" class="pa-0">
              <p v-if="minInputError" class="input-invalid pa-0">
                The Minimum Talk Time Limit must be less than the Maximum Talk
                Time Limit
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" class="pa-0">
              <p v-if="maxInputError" class="input-invalid pb-0">
                The Maximum Talk Time Limit must be more than the Minimum Talk
                Time Limit
              </p>
            </v-col>
          </v-row>
        </fieldset>
      </v-col>
    </v-row>

    <access-role-modal
      :showModal="showModal"
      :accessRoleId="String(accessRoleId)"
      @HIDE_MODAL="hideModal"
      @updateAccessRoleList="loadSelections"
    ></access-role-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import AccessRoleModal from "../AccessRoles/AccessRoleModal.vue";
import SetupTip from "@/components/SetupTip.vue";
import moment from "moment";

export default {
  name: "insight-definition",
  computed: {},
  components: {
    VueTimepicker,
    AccessRoleModal,
    SetupTip
  },
  data() {
    return {
      showModal: false,
      dynaLabelsSelected: [],
      accessRolesSelected: [],
      selectableDynaLabels: [],
      selectableAccessRoles: [],
      dynaLabelSelections: [],
      accessRoleSelections: [],
      accessRoleSearch: "",
      myAccessRoles: [],
      myDynaLabels: [],
      maxTalkLength: null,
      minTalkLength: null,
      minInputError: false,
      maxInputError: false,
      dynaLabelSearch: "",
      accessRoleId: "",
      minTime: {
        HH: "",
        mm: "",
        ss: ""
      },
      maxTime: {
        HH: "",
        mm: "",
        ss: ""
      },
      dynaLabelHeaders: [
        {
          text: "Dynamic Labels",
          align: "start",
          sortable: false,
          value: "title"
        }
      ],
      accessRoleHeaders: [
        {
          text: "Share With",
          align: "start",
          sortable: false,
          value: "title"
        }
      ]
    };
  },
  props: {
    insightDefinitionId: String,
    cancelEdits: Boolean,
    selectedDynaLabels: Array,
    selectedAccessRoles: Array,
    minTalkTime: Number,
    maxTalkTime: Number,
    mediaType: String
  },
  computed: {
    tablesLoading() {
      return (
        this.accessRolesListLoadingStatus == "loading" ||
        this.insightsDefinitionsListItemDataLoadingStatus == "loading" ||
        this.dynaLabelsListLoadingStatus == "loading" ||
        this.insightsDefinitionsListLoadingStatus == "loading"
      );
    },
    ...mapGetters("insights", [
      "insightsDefinitionsList",
      "insightsDefinitionsListLoadingStatus",
      "insightsDefinitionsListLoadingError",
      "insightsDefinitionsListErrorStatus",
      "insightsDefinitionsListItemData",
      "insightsDefinitionsListItemDataLoadingStatus",
      "insightsDefinitionsListItemDataLoadingError",
      "insightsDefinitionsListItemDataLoadingErrorStatus",
      "insightsListDataLoadingStatus"
    ]),
    ...mapGetters("accessRoles", [
      "accessRolesList",
      "accessRolesListLoadingStatus"
    ]),
    ...mapGetters("dynaLabels", [
      "dynaLabelsList",
      "dynaLabelsListLoadingStatus",
      "dynaLabelsListLoadingError",
      "dynaLabelsListLoadingErrorStatus",

      "dynaLabelsEditorList"
    ]),
    minTalktimeError() {
      return this.minInputError ? "input-error" : "";
    },
    maxTalktimeError() {
      return this.maxInputError ? "input-error" : "";
    }
  },
  methods: {
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .split(":");
    },
    loadModal() {
      this.showModal = true;
    },
    hideModal(hideModal) {
      this.showModal = hideModal;
    },
    rowSelected(value) {
      this.loadModal();
      this.accessRoleId = value.id;
    },
    minTalkTimeHandler(event) {
      if (event.displayTime == "") {
        this.minTalkLength = null;
      } else {
        this.minTalkLength = this.convertTimeToSeconds(event.displayTime);
      }

      this.minInputError =
        this.minTalkLength >= this.maxTalkLength &&
        (this.maxTalkLength != null && this.minTalkLength != null)
          ? true
          : false;
      if (!this.minInputError) {
        this.maxInputError = false;
      }

      this.$emit("updateMinTalkTimeLength", this.minTalkLength);
    },
    maxTalkTimeHandler(event) {
      if (event.displayTime == "") {
        this.maxTalkLength = null;
      } else {
        this.maxTalkLength = this.convertTimeToSeconds(event.displayTime);
      }

      this.maxInputError =
        this.minTalkLength >= this.maxTalkLength &&
        (this.maxTalkLength != null && this.minTalkLength != null)
          ? true
          : false;
      if (!this.maxInputError) {
        this.minInputError = false;
      }
      this.$emit("updateMaxTalkTimeLength", this.maxTalkLength);
    },
    convertTimeToSeconds(time) {
      return moment(time.replace(/[Hms]/g, 0), "HH:mm:ss").diff(
        moment().startOf("day"),
        "seconds"
      );
    },
    selectItem(value) {
      this.$emit("updateInfo");
    },
    selectAll() {
      this.$emit("updateInfo");
    },
    updateDynaLabels() {
      this.$emit("updateDynaLabelSelections", this.dynaLabelsSelected);
    },
    updateAccessRoles() {
      this.$emit("updateAccessRoleSelections", this.accessRolesSelected);
    },
    resetInfo() {
      this.refreshLists();
      this.$emit("updatesCancelled", false);
    },
    setInfo() {
      this.$emit("setInfo");
    },
    refreshLists() {
      this.accessRolesSelected = [];
      this.dynaLabelsSelected = [];
      this.loadSelections();
    },
    getTalkTimes() {
      let min = this.formatHMS(this.minTalkTime);

      let defaultDisplay = {
        HH: "",
        mm: "",
        ss: ""
      };
      if (this.minTalkTime == null) {
        this.minTime = defaultDisplay;
      } else {
        this.minTime = {
          HH: min[0],
          mm: min[1],
          ss: min[2]
        };
      }

      let max = this.formatHMS(this.maxTalkTime);
      if (this.maxTalkTime == null) {
        this.maxTime = defaultDisplay;
      } else {
        this.maxTime = {
          HH: max[0],
          mm: max[1],
          ss: max[2]
        };
      }
    },
    getSortedDynamicLabels(options, selections) {
      let selected = options
        .filter(d => selections.some(s => d.id == s.dynalabel_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      let unselected = options
        .filter(d => !selections.some(s => d.id == s.dynalabel_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      return Array(...selected, ...unselected);
    },
    getSortedAccessRoles(options, selections) {
      let selected = options
        .filter(d => selections.some(s => d.id == s.accessrole_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      let unselected = options
        .filter(d => !selections.some(s => d.id == s.accessrole_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      return Array(...selected, ...unselected);
    },
    loadAccessRoleSelections() {
      let accessroleOptions = [];
      this.myAccessRoles = this.selectedAccessRoles;
      accessroleOptions = this.accessRolesList.map(obj => {
        return {
          id: obj.id,
          title: obj.title + " (" + obj.users.length + " users) ",
          description: obj.description
        };
      });

      this.selectableAccessRoles = this.getSortedAccessRoles(
        accessroleOptions,
        this.selectedAccessRoles
      );
      this.accessRolesSelected = this.selectableAccessRoles.filter(d =>
        this.myAccessRoles.some(s => d.id == s.accessrole_id)
      );
    },
    loadDynaLabelSelections() {
      let dynalabelOptions = []
      this.myDynaLabels = this.selectedDynaLabels

      dynalabelOptions = this.dynaLabelsEditorList.map(obj => {
        return {
          id: obj.id,
          title: obj.title + " (" + obj.id + ")",
          description: obj.description,
          media_type: obj.media_type
        }
      })

      //filter on media type
      dynalabelOptions = dynalabelOptions.filter(d => 
        d.media_type == this.mediaType
      )

      this.selectableDynaLabels = this.getSortedDynamicLabels(
        dynalabelOptions,
        this.selectedDynaLabels
      );
      this.dynaLabelsSelected = this.selectableDynaLabels.filter(d =>
        this.myDynaLabels.some(s => d.id == s.dynalabel_id)
      )
    },
    loadSelections() {
      this.loadAccessRoleSelections();
      this.loadDynaLabelSelections();
      this.getTalkTimes();
    },
  },
  updated() {},
  mounted() {
    this.loadSelections();
  },
  watch: {
    insightsDefinitionsListItemData: "setInfo",
    cancelEdits: "resetInfo",
    dynaLabelsSelected: "updateDynaLabels",
    accessRolesSelected: "updateAccessRoles",
    insightDefinitionId: "loadSelections",
    dynaLabelsListLoadingStatus: "loadSelections",
    accessRolesListLoadingStatus: "loadSelections",
    mediaType: "loadDynaLabelSelections"
  }
};
</script>

<style scoped>
.vue__time-picker {
  border: 1px solid rgb(220, 222, 224);
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

fieldset {
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
  -webkit-border-radius: 0.2rem;
  border: 1px solid #c2c2c2;
  padding: 0.4rem;
}

legend {
  margin-left: 0.4rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-size: 0.8rem;
  color: #bababa;
}

.input-error {
  border-color: red;
}

.input-invalid {
  color: red;
  font-size: 9px;
}
</style>
