
export default {
    methods: {
        gatherSubjectiveNumbers(subjectiveCountsFromCall, callCount, type) {

            let secondHalfNegativeFirstHalfNegativeCount = Number(subjectiveCountsFromCall[type].first_half_negative_second_half_negative)
            let secondHalfNegativeFirstHalfNeutralCount = Number(subjectiveCountsFromCall[type].first_half_neutral_second_half_negative)
            let secondHalfNegativeFirstHalfPositiveCount = Number(subjectiveCountsFromCall[type].first_half_positive_second_half_negative)
            let secondHalfNeutralFirstHalfNegativeCount = Number(subjectiveCountsFromCall[type].first_half_negative_second_half_neutral)
            let secondHalfNeutralFirstHalfNeutralCount = Number(subjectiveCountsFromCall[type].first_half_neutral_second_half_neutral)
            let secondHalfNeutralFirstHalfPositiveCount = Number(subjectiveCountsFromCall[type].first_half_positive_second_half_neutral)
            let secondHalfPositiveFirstHalfNegativeCount = Number(subjectiveCountsFromCall[type].first_half_negative_second_half_positive)
            let secondHalfPositiveFirstHalfNeutralCount = Number(subjectiveCountsFromCall[type].first_half_neutral_second_half_positive)
            let secondHalfPositiveFirstHalfPositiveCount = Number(subjectiveCountsFromCall[type].first_half_positive_second_half_positive)
            let secondHalfNegativeCount = secondHalfNegativeFirstHalfNegativeCount + secondHalfNegativeFirstHalfNeutralCount + secondHalfNegativeFirstHalfPositiveCount
            let secondHalfNeutralCount = secondHalfNeutralFirstHalfNegativeCount + secondHalfNeutralFirstHalfNeutralCount + secondHalfNeutralFirstHalfPositiveCount
            let secondHalfPositiveCount = secondHalfPositiveFirstHalfNegativeCount + secondHalfPositiveFirstHalfNeutralCount + secondHalfPositiveFirstHalfPositiveCount
      
            return {
      
              callCount: callCount,
              secondHalfNegativeCount: secondHalfNegativeCount,
              secondHalfNeutralCount: secondHalfNeutralCount,
              secondHalfPositiveCount: secondHalfPositiveCount,
      
              secondHalfNegativeFraction: this.calculateSubjectivePercent(secondHalfNegativeCount,callCount),
              secondHalfNeutralFraction: this.calculateSubjectivePercent(secondHalfNeutralCount,callCount),
              secondHalfPositiveFraction: this.calculateSubjectivePercent(secondHalfPositiveCount,callCount),
      
              secondHalfNegativeFirstHalfNegativeFraction: this.calculateSubjectivePercent(secondHalfNegativeFirstHalfNegativeCount,secondHalfNegativeCount),
              secondHalfNegativeFirstHalfNeutralFraction: this.calculateSubjectivePercent(secondHalfNegativeFirstHalfNeutralCount,secondHalfNegativeCount),
              secondHalfNegativeFirstHalfPositiveFraction: this.calculateSubjectivePercent(secondHalfNegativeFirstHalfPositiveCount,secondHalfNegativeCount),
      
              secondHalfNeutralFirstHalfNegativeFraction: this.calculateSubjectivePercent(secondHalfNeutralFirstHalfNegativeCount,secondHalfNeutralCount),
              secondHalfNeutralFirstHalfNeutralFraction: this.calculateSubjectivePercent(secondHalfNeutralFirstHalfNeutralCount,secondHalfNeutralCount),
              secondHalfNeutralFirstHalfPositiveFraction: this.calculateSubjectivePercent(secondHalfNeutralFirstHalfPositiveCount,secondHalfNeutralCount),
      
              secondHalfPositiveFirstHalfNegativeFraction: this.calculateSubjectivePercent(secondHalfPositiveFirstHalfNegativeCount,secondHalfPositiveCount),
              secondHalfPositiveFirstHalfNeutralFraction: this.calculateSubjectivePercent(secondHalfPositiveFirstHalfNeutralCount,secondHalfPositiveCount),
              secondHalfPositiveFirstHalfPositiveFraction: this.calculateSubjectivePercent(secondHalfPositiveFirstHalfPositiveCount,secondHalfPositiveCount),
      
              secondHalfNegativeFirstHalfNegativeCount: secondHalfNegativeFirstHalfNegativeCount,
              secondHalfNegativeFirstHalfNeutralCount: secondHalfNegativeFirstHalfNeutralCount,
              secondHalfNegativeFirstHalfPositiveCount: secondHalfNegativeFirstHalfPositiveCount,
      
              secondHalfNeutralFirstHalfNegativeCount:secondHalfNeutralFirstHalfNegativeCount,
              secondHalfNeutralFirstHalfNeutralCount: secondHalfNeutralFirstHalfNeutralCount,
              secondHalfNeutralFirstHalfPositiveCount:secondHalfNeutralFirstHalfPositiveCount,
      
              secondHalfPositiveFirstHalfNegativeCount:secondHalfPositiveFirstHalfNegativeCount,
              secondHalfPositiveFirstHalfNeutralCount: secondHalfPositiveFirstHalfNeutralCount,
              secondHalfPositiveFirstHalfPositiveCount: secondHalfPositiveFirstHalfPositiveCount,
            }
          },
          calculateSubjectivePercent(s,t) {
            if (t == 0) {
              return 0
            } else {
              return (s/t*100)/100
            }
          },
    },
    
}