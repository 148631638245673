<template>
  <v-content>
    <v-container fill-height>
      <v-row justify="center">
        <v-container fill-height>
          <v-row justify="center">
            <div class=WordSection1>

              <p class=MsoNormal>Privacy Policy</p>

              <p class=MsoNormal>Last updated: June 14, 2024</p>

              <p class=MsoNormal>This Privacy Policy describes Our policies and procedures on
                the collection, use and disclosure of Your information when You use the Service
                and tells You about Your privacy rights and how the law protects You.</p>

              <p class=MsoNormal>We use Your Personal data to provide and improve the
                Service. By using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>Interpretation and Definitions</p>

              </div>

              <h2>Interpretation</h2>

              <p class=MsoNormal>The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following definitions
                shall have the same meaning regardless of whether they appear in singular or in
                plural.</p>

              <h2>Definitions</h2>

              <p class=MsoNormal>For the purposes of this Privacy Policy:</p>

              <p class=MsoListParagraphCxSpFirst><b>Account</b> means a unique account
                created for You to access our Service or parts of our Service.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Business</b>, for the purpose of the
                CCPA (California Consumer Privacy Act), refers to the Company as the legal
                entity (1) that collects Consumers' personal information and determines the
                purposes and means of the processing of Consumers' personal information, or on
                behalf of which such information is collected (2) that alone, or jointly with
                others, determines the purposes and means of the processing of consumers'
                personal information, and (3) that does business in the State of California.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Company</b> (referred to as either
                &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
                this Agreement) refers to 800 Response Marketing LLC dba CallFinder, 38 Eastwood Drive, 
                Suite 401, South Burlington, VT, 05403.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Consumer</b>, for the purpose of the
                CCPA (California Consumer Privacy Act), means a natural person who is a
                California resident. A resident, as defined in the law, includes (1) every
                individual who is in the USA for other than a temporary or transitory purpose,
                and (2) every individual who is domiciled in the USA who is outside the USA for
                a temporary or transitory purpose.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Cookies</b> are small files that are
                placed on Your computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website among its many
                uses.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Country</b> refers to: Vermont, United
                States</p>

              <p class=MsoListParagraphCxSpMiddle><b>Data Controller</b>, for the purposes of
                the GDPR (General Data Protection Regulation), refers to the Company as the legal
                person which alone or jointly with others determines the purposes and means of
                the processing of Personal Data.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Device</b> means any device that can
                access the Service such as a computer, a cellphone or a digital tablet.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Do Not Track</b> (DNT) is a concept that
                has been promoted by US regulatory authorities, in particular the U.S. Federal
                Trade Commission (FTC), for the Internet industry to develop and implement a
                mechanism for allowing Internet users to control the tracking of their online
                activities across websites.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Personal Data</b> is any information
                that relates to an identified or identifiable individual.</p>

              <p class=MsoListParagraphCxSpMiddle>For the purposes of the GDPR, Personal Data
                means any information relating to You such as a name, an identification number,
                location data, online identifier or to one or more factors specific to the
                physical, physiological, genetic, mental, economic, cultural or social
                identity.</p>

              <p class=MsoListParagraphCxSpMiddle>For the purposes of the CCPA, Personal Data
                means any information that identifies, relates to, describes or is capable of
                being associated with, or could reasonably be linked, directly or indirectly,
                with You.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Sale</b>, for the purpose of the CCPA
                (California Consumer Privacy Act), means selling, renting, releasing,
                disclosing, disseminating, making available, transferring, or otherwise
                communicating orally, in writing, or by electronic or other means, a Consumer's
                personal information to another business or a third party for monetary or other
                valuable consideration.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Service</b> refers to the Website.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Service Provider</b> means any natural
                or legal person who processes data on behalf of the Company. It refers to
                third-party companies or individuals employed by the Company to facilitate the
                Service, to provide the Service on behalf of the Company, to perform services
                related to the Service or to assist the Company in analyzing how the Service is
                used. For the purpose of the GDPR, Service Providers are considered Data Processors.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Third-party Social Media Service</b>
                refers to any website or any social network website through which a User can
                log in or create an account to use the Service.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Usage Data</b> refers to data collected
                automatically, either generated by the use of the Service or from the Service
                infrastructure itself (for example, the duration of a page visit).</p>

              <p class=MsoListParagraphCxSpMiddle><b>Website</b> refers to CallFinder,
                accessible from <a href="https://www.findmycalls.com/"><span style='color:windowtext;
text-decoration:none'>https://www.findmycalls.com/</span></a></p>

              <p class=MsoListParagraphCxSpMiddle><b>You</b> (referred to as either “You” or
                “Your” in this Agreement) means the individual accessing or using the Service,
                or the company, or other legal entity on behalf of which such individual is
                accessing or using the Service, as applicable.</p>

              <p class=MsoListParagraphCxSpLast>For purposes of the GDPR (General Data
                Protection Regulation), the Data Subject and the User are You. </p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>Collecting and Using Your Personal Data</p>

              </div>

              <h2>Types of Data Collected</h2>

              <h3>Personal Data</h3>

              <p class=MsoNormal>While using the Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to contact or
                identify You. Personally identifiable information may include, but is not
                limited to:</p>

              <p class=MsoListParagraphCxSpFirst>Email address</p>

              <p class=MsoListParagraphCxSpLast>Usage Data</p>

              <h3>Usage Data</h3>

              <p class=MsoNormal>Usage Data is collected automatically when using the
                Service.</p>

              <p class=MsoNormal>Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type, browser version, the
                pages of our Service that You visit, the time and date of Your visit, the time
                spent on specific pages, unique device identifiers and other diagnostic data.</p>

              <p class=MsoNormal>When You access the Service by or through a mobile device,
                We may collect certain information automatically, including, but not limited
                to, the type of mobile device You use, Your mobile device unique ID, the IP
                address of Your mobile device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other diagnostic data.</p>

              <p class=MsoNormal>We may also collect information that Your browser sends
                whenever You visit our Service or when You access the Service by or through a
                mobile device.</p>

              <h3>Tracking Technologies and Cookies</h3>

              <p class=MsoNormal>We use Cookies and similar tracking technologies to track
                the activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and track information
                and to improve and analyze Our Service. The technologies We use may include:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Cookies or Browser Cookies.</b> A cookie is a small file
                placed on Your Device. You can instruct Your browser to refuse all Cookies or
                to indicate when a Cookie is being sent. However, if You do not accept Cookies,
                You may not be able to use some parts of our Service. Unless You have adjusted Your
                browser setting so that it will refuse Cookies, our Service may use Cookies.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Web Beacons.</b> Certain sections of our Service and our
                emails may contain small electronic files known as web beacons (also referred
                to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
                for example, to count users who have visited those pages or opened an email and
                for other related website statistics (for example, recording the popularity of
                a certain section and verifying system and server integrity).</p>

              <p class=MsoNormal>Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
                Cookies. Persistent Cookies remain on Your personal computer or mobile device
                when You go offline, while Session Cookies are deleted as soon as You close Your
                web browser. Learn more about cookies: <a
                  href="https://www.privacypolicies.com/blog/cookies/"><span style='color:windowtext;
text-decoration:none'>What Are Cookies?</span></a>.</p>

              <p class=MsoNormal>We use both Session and Persistent Cookies for the purposes
                set out below:</p>

              <p class=MsoListParagraphCxSpFirst><b>Necessary / Essential Cookies</b></p>

              <p class=MsoListParagraphCxSpMiddle>Type: Session Cookies</p>

              <p class=MsoListParagraphCxSpMiddle>Administered by: Us</p>

              <p class=MsoListParagraphCxSpMiddle>Purpose: These Cookies are essential to
                provide You with services available through the Website and to enable You to
                use some of its features. They help to authenticate users and prevent
                fraudulent use of user accounts. Without these Cookies, the services that You
                have asked for cannot be provided, and We only use these Cookies to provide You
                with those services.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Cookies Policy / Notice Acceptance
                Cookies</b></p>

              <p class=MsoListParagraphCxSpMiddle>Type: Persistent Cookies</p>

              <p class=MsoListParagraphCxSpMiddle>Administered by: Us</p>

              <p class=MsoListParagraphCxSpMiddle>Purpose: These Cookies identify whether
                users have accepted the use of cookies on the Website.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Functionality Cookies</b></p>

              <p class=MsoListParagraphCxSpMiddle>Type: Persistent Cookies</p>

              <p class=MsoListParagraphCxSpMiddle>Administered by: Us</p>

              <p class=MsoListParagraphCxSpMiddle>Purpose: These Cookies allow us to remember
                choices You make when You use the Website, such as remembering Your login
                details or language preference. The purpose of these Cookies is to provide You
                with a more personal experience and to avoid You having to re-enter Your
                preferences every time You use the Website.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Tracking and Performance Cookies</b></p>

              <p class=MsoListParagraphCxSpMiddle>Type: Persistent Cookies</p>

              <p class=MsoListParagraphCxSpMiddle>Administered by: Third-Parties</p>

              <p class=MsoListParagraphCxSpLast>Purpose: These Cookies are used to track
                information about traffic to the Website and how users use the Website. The
                information gathered via these Cookies may directly or indirectly identify You
                as an individual visitor. This is because the information collected is
                typically linked to a pseudonymous identifier associated with the device You
                use to access the Website. We may also use these Cookies to test new pages,
                features or new functionality of the Website to see how Our users react to
                them.</p>

              <p class=MsoNormal>For more information about the cookies we use and Your
                choices regarding cookies, please visit our Cookies Policy or the Cookies
                section of our Privacy Policy.</p>

              <h2>Use of Your Personal Data</h2>

              <p class=MsoNormal>The Company may use Personal Data for the following
                purposes:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>To provide and maintain our Service</b>, including to monitor
                the usage of our Service.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>To manage Your Account:</b> to manage Your registration as a
                user of the Service. The Personal Data You provide can give You access to
                different functionalities of the Service that are available to You as a
                registered user.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>For the performance of a contract:</b> the development,
                compliance and undertaking of the purchase contract for the products, items or
                services You have purchased or of any other contract with Us through the
                Service.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>To contact You:</b> To contact You by email, telephone calls,
                SMS, or other equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or informative
                communications related to the functionalities, products or contracted services,
                including the security updates, when necessary or reasonable for their
                implementation.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>To provide You</b> with news, special offers and general
                information about other goods, services and events which we offer that are
                similar to those that You have already purchased or enquired about unless You
                have opted not to receive such information.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>To manage Your requests:</b> To attend and manage Your
                requests to Us.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>To deliver targeted advertising to You</b>: We may use Your
                information to develop and display content and advertising (and work with
                third-party vendors who do so) tailored to Your interests and/or location and
                to measure its effectiveness.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>For business transfers:</b> We may use Your information to
                evaluate or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or similar proceeding,
                in which Personal Data held by Us about our Service users is among the assets
                transferred.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>For other purposes</b>: We may use Your information for other
                purposes, such as data analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and improve our
                Service, products, services, marketing and Your experience.</p>

              <p class=MsoNormal>We may share Your personal information in the following
                situations:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>With Service Providers:</b> We may share Your personal
                information with Service Providers to monitor and analyze the use of our Service,
                to advertise on third party websites to You after You visited our Service, to
                contact You.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>For business transfers:</b> We may share or transfer Your
                personal information in connection with, or during negotiations of, any merger,
                sale of Company assets, financing, or acquisition of all or a portion of Our
                business to another company.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>With Affiliates:</b> We may share Your information with Our
                affiliates, in which case we will require those affiliates to honor this
                Privacy Policy. Affiliates include Our parent company and any other
                subsidiaries, joint venture partners or other companies that We control or that
                are under common control with Us.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>With business partners:</b> We may share Your information
                with Our business partners to offer You certain products, services or
                promotions.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>With other users:</b> when You share personal information or
                otherwise interact in the public areas with other users, such information may
                be viewed by all users and may be publicly distributed outside. If You interact
                with other users or register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your name, profile,
                pictures and description of Your activity. Similarly, other users will be able
                to view descriptions of Your activity, communicate with You and view Your
                profile.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>With Your consent</b>: We may disclose Your personal
                information for any other purpose with Your consent.</p>

              <h2>Retention of Your Personal Data</h2>

              <p class=MsoNormal>The Company will retain Your Personal Data only for as long
                as is necessary for the purposes set out in this Privacy Policy. We will retain
                and use Your Personal Data to the extent necessary to comply with our legal
                obligations (for example, if we are required to retain Your data to comply with
                applicable laws), resolve disputes, and enforce our legal agreements and
                policies.</p>

              <p class=MsoNormal>The Company will also retain Usage Data for internal
                analysis purposes. Usage Data is generally retained for a shorter period of
                time, except when this data is used to strengthen the security or to improve
                the functionality of Our Service, or We are legally obligated to retain this
                data for longer time periods.</p>

              <h2>Transfer of Your Personal Data</h2>

              <p class=MsoNormal>Your information, including Personal Data, is processed at
                the Company's operating offices and in any other places where the parties
                involved in the processing are located. It means that this information may be
                transferred to — and maintained on — computers located outside of Your state,
                province, country or other governmental jurisdiction where the data protection
                laws may differ than those from Your jurisdiction.</p>

              <p class=MsoNormal>Your consent to this Privacy Policy followed by Your
                submission of such information represents Your agreement to that transfer.</p>

              <p class=MsoNormal>The Company will take all steps reasonably necessary to
                ensure that Your data is treated securely and in accordance with this Privacy
                Policy and no transfer of Your Personal Data will take place to an organization
                or a country unless there are adequate controls in place including the security
                of Your data and other personal information.</p>

              <h2>Disclosure of Your Personal Data</h2>

              <h3>Business Transactions</h3>

              <p class=MsoNormal>If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide notice before Your
                Personal Data is transferred and becomes subject to a different Privacy Policy.</p>

              <h3>Law enforcement</h3>

              <p class=MsoNormal>Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in response to valid
                requests by public authorities (e.g. a court or a government agency).</p>

              <h3>Other legal requirements</h3>

              <p class=MsoNormal>The Company may disclose Your Personal Data in the good
                faith belief that such action is necessary to:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Comply with a legal obligation</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Protect and defend the rights or property of the Company</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Prevent or investigate possible wrongdoing in connection with
                the Service</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Protect the personal safety of Users of the Service or the
                public</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Protect against legal liability</p>

              <h2>Security of Your Personal Data</h2>

              <p class=MsoNormal>The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or method of
                electronic storage is 100% secure. While We strive to use commercially
                acceptable means to protect Your Personal Data, We cannot guarantee its
                absolute security.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>Detailed Information on the Processing of Your Personal Data</p>

              </div>

              <p class=MsoNormal>The Service Providers We use may have access to Your
                Personal Data. These third-party vendors collect, store, use, process and
                transfer information about Your activity on Our Service in accordance with
                their Privacy Policies.</p>

              <h2>Analytics</h2>

              <p class=MsoNormal>We may use third-party Service providers to monitor and
                analyze the use of our Service.</p>

              <p class=MsoListParagraphCxSpFirst><b>Google Analytics</b></p>

              <p class=MsoListParagraphCxSpMiddle>Google Analytics is a web analytics service
                offered by Google that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data is shared with
                other Google services. Google may use the collected data to contextualize and
                personalize the ads of its own advertising network.</p>

              <p class=MsoListParagraphCxSpMiddle>You can opt-out of having made Your
                activity on the Service available to Google Analytics by installing the Google
                Analytics opt-out browser add-on. The add-on prevents the Google Analytics
                JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google
                Analytics about visits activity.</p>

              <p class=MsoListParagraphCxSpLast>For more information on the privacy practices
                of Google, please visit the Google Privacy &amp; Terms web page: <a
                  href="https://policies.google.com/privacy"><span style='color:windowtext;
text-decoration:none'>https://policies.google.com/privacy</span></a></p>

              <h2>Behavioral Remarketing</h2>

              <p class=MsoNormal>The Company uses remarketing services to advertise to You
                after You accessed or visited our Service. We and Our third-party vendors use
                cookies and non-cookie technologies to help Us recognize Your Device and
                understand how You use our Service so that We can improve our Service to
                reflect Your interests and serve You advertisements that are likely to be of
                more interest to You.</p>

              <p class=MsoNormal>These third-party vendors collect, store, use, process and
                transfer information about Your activity on Our Service in accordance with
                their Privacy Policies and to enable Us to:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Measure and analyze traffic and browsing activity on Our Service</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Show advertisements for Our products and/or services to You on
                third-party websites or apps</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Measure and analyze the performance of Our advertising campaigns</p>

              <p class=MsoNormal>Some of these third-party vendors may use non-cookie
                technologies that may not be impacted by browser settings that block cookies. Your
                browser may not permit You to block such technologies. You can use the
                following third-party tools to decline the collection and use of information
                for the purpose of serving You interest-based advertising:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The NAI's opt-out platform: <a
                href="http://www.networkadvertising.org/choices/"><span style='color:windowtext;
text-decoration:none'>http://www.networkadvertising.org/choices/</span></a></p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The EDAA's opt-out platform <a
                href="http://www.youronlinechoices.com/"><span style='color:windowtext;
text-decoration:none'>http://www.Youronlinechoices.com/</span></a></p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The DAA's opt-out platform: <a
                href="http://optout.aboutads.info/?c=2&amp;lang=EN"><span style='color:windowtext;
text-decoration:none'>http://optout.aboutads.info/?c=2&amp;lang=EN</span></a></p>

              <p class=MsoNormal>You may opt-out of all personalized advertising by enabling
                privacy features on Your mobile device such as Limit Ad Tracking (iOS) and Opt
                Out of Ads Personalization (Android). See Your mobile device Help system for
                more information.</p>

              <p class=MsoNormal>We may share information, such as hashed email addresses (if
                available) or other online identifiers collected on Our Service with these
                third-party vendors. This allows Our third-party vendors to recognize and
                deliver You ads across devices and browsers. To read more about the
                technologies used by these third-party vendors and their cross-device
                capabilities please refer to the Privacy Policy of each vendor listed below.</p>

              <p class=MsoNormal>The third-party vendors We use are:</p>

              <p class=MsoListParagraphCxSpFirst><b>Google Ads (AdWords)</b></p>

              <p class=MsoListParagraphCxSpMiddle>Google Ads (AdWords) remarketing service is
                provided by Google Inc.</p>

              <p class=MsoListParagraphCxSpMiddle>You can opt-out of Google Analytics for
                Display Advertising and customize the Google Display Network ads by visiting
                the Google Ads Settings page: <a href="http://www.google.com/settings/ads"><span
                  style='color:windowtext;text-decoration:none'>http://www.google.com/settings/ads</span></a></p>

              <p class=MsoListParagraphCxSpMiddle>Google also recommends installing the
                Google Analytics Opt-out Browser Add-on - <a
                  href="https://tools.google.com/dlpage/gaoptout"><span style='color:windowtext;
text-decoration:none'>https://tools.google.com/dlpage/gaoptout</span></a> - for
                Your web browser. Google Analytics Opt-out Browser Add-on provides visitors
                with the ability to prevent their data from being collected and used by Google
                Analytics.</p>

              <p class=MsoListParagraphCxSpLast>For more information on the privacy practices
                of Google, please visit the Google Privacy &amp; Terms web page: <a
                  href="https://policies.google.com/privacy"><span style='color:windowtext;
text-decoration:none'>https://policies.google.com/privacy</span></a></p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>GDPR Privacy</p>

              </div>

              <h2>Legal Basis for Processing Personal Data under GDPR</h2>

              <p class=MsoNormal>We may process Personal Data under the following conditions:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Consent:</b> You have given Your consent for processing
                Personal Data for one or more specific purposes.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Performance of a contract:</b> Provision of Personal Data is
                necessary for the performance of an agreement with You and/or for any
                pre-contractual obligations thereof.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Legal obligations:</b> Processing Personal Data is necessary
                for compliance with a legal obligation to which the Company is subject.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Vital interests:</b> Processing Personal Data is necessary in
                order to protect Your vital interests or those of another natural person.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Public interests:</b> Processing Personal Data is related to
                a task that is carried out in the public interest or in the exercise of
                official authority vested in the Company.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Legitimate interests:</b> Processing Personal Data is
                necessary for the purposes of the legitimate interests pursued by the Company.</p>

              <p class=MsoNormal>In any case, the Company will gladly help to clarify the
                specific legal basis that applies to the processing, and in particular whether
                the provision of Personal Data is a statutory or contractual requirement, or a
                requirement necessary to enter into a contract.</p>

              <h2>Your Rights under the GDPR</h2>

              <p class=MsoNormal>The Company undertakes to respect the confidentiality of Your
                Personal Data and to guarantee You can exercise Your rights.</p>

              <p class=MsoNormal>You have the right under this Privacy Policy, and by law if You
                are within the EU, to:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Request access to Your Personal Data.</b> The right to
                access, update or delete the information We have on You. Whenever made
                possible, You can access, update or request deletion of Your Personal Data
                directly within Your account settings section. If You are unable to perform
                these actions Yourself, please contact Us to assist You. This also enables You
                to receive a copy of the Personal Data We hold about You.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Request correction of the Personal Data that We hold about You.</b>
                You have the right to to have any incomplete or inaccurate information We hold
                about You corrected.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Object to processing of Your Personal Data.</b> This right
                exists where We are relying on a legitimate interest as the legal basis for Our
                processing and there is something about Your particular situation, which makes You
                want to object to our processing of Your Personal Data on this ground. You also
                have the right to object where We are processing Your Personal Data for direct
                marketing purposes.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Request erasure of Your Personal Data.</b> You have the right
                to ask Us to delete or remove Personal Data when there is no good reason for Us
                to continue processing it.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Request the transfer of Your Personal Data.</b> We will
                provide to You, or to a third-party You have chosen, Your Personal Data in a
                structured, commonly used, machine-readable format. Please note that this right
                only applies to automated information which You initially provided consent for
                Us to use or where We used the information to perform a contract with You.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Withdraw Your consent.</b> You have the right to withdraw Your
                consent on using Your Personal Data. If You withdraw Your consent, We may not
                be able to provide You with access to certain specific functionalities of the
                Service.</p>

              <h2>Exercising of Your GDPR Data Protection Rights</h2>

              <p class=MsoNormal>You may exercise Your rights of access, rectification,
                cancellation and opposition by contacting Us. Please note that we may ask You
                to verify Your identity before responding to such requests. If You make a
                request, We will try our best to respond to You as soon as possible.</p>

              <p class=MsoNormal>You have the right to complain to a Data Protection
                Authority about Our collection and use of Your Personal Data. For more
                information, if You are in the European Economic Area (EEA), please contact Your
                local data protection authority in the EEA.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>CCPA Privacy</p>

              </div>

              <p class=MsoNormal>This privacy notice section for California residents
                supplements the information contained in Our Privacy Policy and it applies
                solely to all visitors, users, and others who reside in the State of
                California.</p>

              <h2>Categories of Personal Information Collected</h2>

              <p class=MsoNormal>We collect information that identifies, relates to,
                describes, references, is capable of being associated with, or could reasonably
                be linked, directly or indirectly, with a particular Consumer or Device. The
                following is a list of categories of personal information which we may collect
                or may have been collected from California residents within the last twelve
                (12) months.</p>

              <p class=MsoNormal>Please note that the categories and examples provided in the
                list below are those defined in the CCPA. This does not mean that all examples
                of that category of personal information were in fact collected by Us, but
                reflects our good faith belief to the best of our knowledge that some of that
                information from the applicable category may be and may have been collected.
                For example, certain categories of personal information would only be collected
                if You provided such personal information directly to Us.</p>

              <p class=MsoListParagraphCxSpFirst><b>Category A: Identifiers.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: A real name, alias, postal
                address, unique personal identifier, online identifier, Internet Protocol
                address, email address, account name, driver's license number, passport number,
                or other similar identifiers.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: Yes.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category B: Personal information
                categories listed in the California Customer Records statute (Cal. Civ. Code §
                1798.80(e)).</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: A name, signature, Social
                Security number, physical characteristics or description, address, telephone
                number, passport number, driver's license or state identification card number,
                insurance policy number, education, employment, employment history, bank
                account number, credit card number, debit card number, or any other financial
                information, medical information, or health insurance information. Some
                personal information included in this category may overlap with other
                categories.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: Yes.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category C: Protected classification
                characteristics under California or federal law.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Age (40 years or older), race,
                color, ancestry, national origin, citizenship, religion or creed, marital
                status, medical condition, physical or mental disability, sex (including
                gender, gender identity, gender expression, pregnancy or childbirth and related
                medical conditions), sexual orientation, veteran or military status, genetic
                information (including familial genetic information).</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: No.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category D: Commercial information.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Records and history of products
                or services purchased or considered.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: No.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category E: Biometric information.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Genetic, physiological,
                behavioral, and biological characteristics, or activity patterns used to
                extract a template or other identifier or identifying information, such as,
                fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke,
                gait, or other physical patterns, and sleep, health, or exercise data.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: No.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category F: Internet or other similar
                network activity.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Interaction with our Service or
                advertisement.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: Yes.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category G: Geolocation data.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Approximate physical location.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: No.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category H: Sensory data.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Audio, electronic, visual,
                thermal, olfactory, or similar information.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: No.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category I: Professional or
                employment-related information.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Current or past job history or
                performance evaluations.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: No.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category J: Non-public education
                information (per the Family Educational Rights and Privacy Act (20 U.S.C.
                Section 1232g, 34 C.F.R. Part 99)).</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Education records directly
                related to a student maintained by an educational institution or party acting
                on its behalf, such as grades, transcripts, class lists, student schedules,
                student identification codes, student financial information, or student
                disciplinary records.</p>

              <p class=MsoListParagraphCxSpMiddle>Collected: No.</p>

              <p class=MsoListParagraphCxSpMiddle><b>Category K: Inferences drawn from other
                personal information.</b></p>

              <p class=MsoListParagraphCxSpMiddle>Examples: Profile reflecting a person's
                preferences, characteristics, psychological trends, predispositions, behavior,
                attitudes, intelligence, abilities, and aptitudes.</p>

              <p class=MsoListParagraphCxSpLast>Collected: No.</p>

              <p class=MsoNormal>Under CCPA, personal information does not include:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Publicly available information from government records</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Deidentified or aggregated consumer information</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Information excluded from the CCPA's scope, such as: </p>

              <p class=MsoListBullet2CxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Health or medical information covered by the Health Insurance
                Portability and Accountability Act of 1996 (HIPAA) and the California
                Confidentiality of Medical Information Act (CMIA) or clinical trial data</p>

              <p class=MsoListBullet2CxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Personal Information covered by certain sector-specific privacy
                laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley
                Act (GLBA) or California Financial Information Privacy Act (FIPA), and the
                Driver's Privacy Protection Act of 1994</p>

              <h2>Sources of Personal Information</h2>

              <p class=MsoNormal>We obtain the categories of personal information listed
                above from the following categories of sources:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Directly from You</b>. For example, from the forms You
                complete on our Service, preferences You express or provide through our
                Service.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Indirectly from You</b>. For example, from observing Your
                activity on our Service.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>Automatically from You</b>. For example, through cookies We
                or our Service Providers set on Your Device as You navigate through our
                Service.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>From Service Providers</b>. For example, third-party vendors
                to monitor and analyze the use of our Service, third-party vendors to deliver
                targeted advertising to You, or other third-party vendors that We use to
                provide the Service to You.</p>

              <h2>Use of Personal Information for Business Purposes or Commercial Purposes</h2>

              <p class=MsoNormal>We may use or disclose personal information We collect for
                &quot;business purposes&quot; or &quot;commercial purposes&quot; (as defined
                under the CCPA), which may include the following examples:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>To operate our Service and provide You with our Service.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>To provide You with support and to respond to Your inquiries,
                including to investigate and address Your concerns and monitor and improve our
                Service.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>To fulfill or meet the reason You provided the information. For
                example, if You share Your contact information to ask a question about our
                Service, We will use that personal information to respond to Your inquiry.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>To respond to law enforcement requests and as required by
                applicable law, court order, or governmental regulations.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>As described to You when collecting Your personal information or
                as otherwise set forth in the CCPA.</p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>For internal administrative and auditing purposes.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>To detect security incidents and protect against malicious,
                deceptive, fraudulent or illegal activity, including, when necessary, to
                prosecute those responsible for such activities.</p>

              <p class=MsoNormal>Please note that the examples provided above are
                illustrative and not intended to be exhaustive. For more details on how we use
                this information, please refer to the &quot;Use of Your Personal Data&quot;
                section.</p>

              <p class=MsoNormal>If We decide to collect additional categories of personal
                information or use the personal information We collected for materially
                different, unrelated, or incompatible purposes We will update this Privacy
                Policy.</p>

              <h2>Disclosure of Personal Information for Business Purposes or Commercial
                Purposes</h2>

              <p class=MsoNormal>We may use or disclose and may have used or disclosed in the
                last twelve (12) months the following categories of personal information for
                business or commercial purposes:</p>

              <p class=MsoListParagraphCxSpFirst>Category A: Identifiers</p>

              <p class=MsoListParagraphCxSpMiddle>Category B: Personal information categories
                listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</p>

              <p class=MsoListParagraphCxSpLast>Category F: Internet or other similar network
                activity</p>

              <p class=MsoNormal>Please note that the categories listed above are those
                defined in the CCPA. This does not mean that all examples of that category of
                personal information were in fact disclosed, but reflects our good faith belief
                to the best of our knowledge that some of that information from the applicable
                category may be and may have been disclosed.</p>

              <p class=MsoNormal>When We disclose personal information for a business purpose
                or a commercial purpose, We enter a contract that describes the purpose and
                requires the recipient to both keep that personal information confidential and
                not use it for any purpose except performing the contract.</p>

              <h2>Sale of Personal Information</h2>

              <p class=MsoNormal>As defined in the CCPA, &quot;sell&quot; and
                &quot;sale&quot; mean selling, renting, releasing, disclosing, disseminating,
                making available, transferring, or otherwise communicating orally, in writing,
                or by electronic or other means, a consumer's personal information by the
                business to a third party for valuable consideration. This means that We may
                have received some kind of benefit in return for sharing personal information,
                but not necessarily a monetary benefit.</p>

              <p class=MsoNormal>Please note that the categories listed below are those
                defined in the CCPA. This does not mean that all examples of that category of
                personal information were in fact sold, but reflects our good faith belief to
                the best of our knowledge that some of that information from the applicable
                category may be and may have been shared for value in return.</p>

              <p class=MsoNormal>We may sell and may have sold in the last twelve (12) months
                the following categories of personal information:</p>

              <p class=MsoListParagraphCxSpFirst>Category A: Identifiers</p>

              <p class=MsoListParagraphCxSpMiddle>Category B: Personal information categories
                listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</p>

              <p class=MsoListParagraphCxSpLast>Category F: Internet or other similar network
                activity</p>

              <h2>Share of Personal Information</h2>

              <p class=MsoNormal>We may share Your personal information identified in the
                above categories with the following categories of third parties:</p>

              <p class=MsoListParagraphCxSpFirst>Service Providers</p>

              <p class=MsoListParagraphCxSpMiddle>Our affiliates</p>

              <p class=MsoListParagraphCxSpMiddle>Our business partners</p>

              <p class=MsoListParagraphCxSpLast>Third party vendors to whom You or Your
                agents authorize Us to disclose Your personal information in connection with
                products or services We provide to You</p>

              <h2>Sale of Personal Information of Minors Under 16 Years of Age</h2>

              <p class=MsoNormal>We do not knowingly collect personal information from minors
                under the age of 16 through our Service, although certain third party websites
                that we link to may do so. These third-party websites have their own terms of
                use and privacy policies and we encourage parents and legal guardians to
                monitor their children's Internet usage and instruct their children to never
                provide information on other websites without their permission.</p>

              <p class=MsoNormal>We do not sell the personal information of Consumers We
                actually know are less than 16 years of age, unless We receive affirmative
                authorization (the &quot;right to opt-in&quot;) from either the Consumer who is
                between 13 and 16 years of age, or the parent or guardian of a Consumer less
                than 13 years of age. Consumers who opt-in to the sale of personal information
                may opt-out of future sales at any time. To exercise the right to opt-out, You
                (or Your authorized representative) may submit a request to Us by contacting
                Us.</p>

              <p class=MsoNormal>If You have reason to believe that a child under the age of
                13 (or 16) has provided Us with personal information, please contact Us with
                sufficient detail to enable Us to delete that information.</p>

              <h2>Your Rights under the CCPA</h2>

              <p class=MsoNormal>The CCPA provides California residents with specific rights
                regarding their personal information. If You are a resident of California, You
                have the following rights:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>The right to notice.</b> You have the right to be notified
                which categories of Personal Data are being collected and the purposes for
                which the Personal Data is being used.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol;color:red;
background:yellow'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><b>The right to request.</b> Under CCPA, You
                have the right to request that We disclose information to You about Our
                collection, use, sale, disclosure for business purposes and share of personal
                information.</p>

              <p class=MsoListBullet2CxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The categories of personal information We collected about You</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The categories of sources for the personal information We
                collected about You</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Our business or commercial purpose for collecting or selling
                that personal information</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The categories of third parties with whom We share that personal
                information</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The specific pieces of personal information We collected about You</p>

              <p class=MsoListBullet2CxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>If we sold Your personal information or disclosed Your personal
                information for a business purpose, We will disclose to You: </p>

              <p class=MsoListBullet3CxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The categories of personal information categories sold</p>

              <p class=MsoListBullet3CxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The categories of personal information categories disclosed</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>The right to say no to the sale of Personal Data (opt-out).</b>
                You have the right to direct Us to not sell Your personal information. To
                submit an opt-out request please contact Us.</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>The right to delete Personal Data.</b> You have the right to
                request the deletion of Your Personal Data, subject to certain exceptions. Once
                We receive and confirm Your request, We will delete (and direct Our Service
                Providers to delete) Your personal information from Our records, unless an
                exception applies. We may deny Your deletion request if retaining the
                information is necessary for Us or Our Service Providers to: </p>

              <p class=MsoListBullet2CxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Complete the transaction for which We collected the personal
                information, provide a good or service that You requested, take actions
                reasonably anticipated within the context of our ongoing business relationship
                with You, or otherwise perform our contract with You.</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity, or prosecute those responsible for such
                activities.</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Debug products to identify and repair errors that impair
                existing intended functionality.</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Exercise free speech, ensure the right of another consumer to
                exercise their free speech rights, or exercise another right provided for by
                law.</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Comply with the California Electronic Communications Privacy Act
                (Cal. Penal Code § 1546 et. seq.).</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all other applicable
                ethics and privacy laws, when the information's deletion may likely render
                impossible or seriously impair the research's achievement, if You previously
                provided informed consent.</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Enable solely internal uses that are reasonably aligned with
                consumer expectations based on Your relationship with Us.</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Comply with a legal obligation.</p>

              <p class=MsoListBullet2CxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Make other internal and lawful uses of that information that are
                compatible with the context in which You provided it.</p>

              <p class=MsoListBullet><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span><b>The right not to be discriminated against.</b> You have the right
                not to be discriminated against for exercising any of Your consumer's rights,
                including by: </p>

              <p class=MsoListBullet2CxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Denying goods or services to You</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Charging different prices or rates for goods or services,
                including the use of discounts or other benefits or imposing penalties</p>

              <p class=MsoListBullet2CxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Providing a different level or quality of goods or services to You</p>

              <p class=MsoListBullet2CxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Suggesting that You will receive a different price or rate for
                goods or services or a different level or quality of goods or services</p>

              <h2>Exercising Your CCPA Data Protection Rights</h2>

              <p class=MsoNormal>In order to exercise any of Your rights under the CCPA, and
                if You are a California resident, You can contact Us:</p>

              <p class=MsoListParagraphCxSpFirst>By email: customerservice@mycallfinder.com</p>

              <p class=MsoListParagraphCxSpMiddle>By phone number: 1-800-639-1700</p>

              <p class=MsoListParagraphCxSpLast>By mail: 38 Eastwood Drive, Suite 401,
                South Burlington, VT 05403</p>

              <p class=MsoNormal>Only You, or a person registered with the California
                Secretary of State that You authorize to act on Your behalf, may make a
                verifiable request related to Your personal information.</p>

              <p class=MsoNormal>Your request to Us must:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Provide sufficient information that allows Us to reasonably
                verify You are the person about whom We collected personal information or an
                authorized representative</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Describe Your request with sufficient detail that allows Us to
                properly understand, evaluate, and respond to it</p>

              <p class=MsoNormal>We cannot respond to Your request or provide You with the
                required information if we cannot:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>Verify Your identity or authority to make the request</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>And confirm that the personal information relates to You</p>

              <p class=MsoNormal>We will disclose and deliver the required information free
                of charge within 45 days of receiving Your verifiable request. The time period
                to provide the required information may be extended once by an additional 45
                days when reasonably necessary and with prior notice.</p>

              <p class=MsoNormal>Any disclosures We provide will only cover the 12-month
                period preceding the verifiable request's receipt.</p>

              <p class=MsoNormal>For data portability requests, We will select a format to
                provide Your personal information that is readily useable and should allow You
                to transmit the information from one entity to another entity without
                hindrance.</p>

              <h2>Do Not Sell My Personal Information</h2>

              <p class=MsoNormal>You have the right to opt-out of the sale of Your personal
                information. Once We receive and confirm a verifiable consumer request from You,
                we will stop selling Your personal information. To exercise Your right to
                opt-out, please contact Us.</p>

              <p class=MsoNormal>The Service Providers we partner with (for example, our
                analytics or advertising partners) may use technology on the Service that sells
                personal information as defined by the CCPA law. If You wish to opt out of the
                use of Your personal information for interest-based advertising purposes and
                these potential sales as defined under CCPA law, You may do so by following the
                instructions below.</p>

              <p class=MsoNormal>Please note that any opt out is specific to the browser You
                use. You may need to opt out on every browser that You use.</p>

              <h3>Website</h3>

              <p class=MsoNormal>You can opt out of receiving ads that are personalized as
                served by our Service Providers by following our instructions presented on the
                Service:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The NAI's opt-out platform: <a
                href="http://www.networkadvertising.org/choices/"><span style='color:windowtext;
text-decoration:none'>http://www.networkadvertising.org/choices/</span></a></p>

              <p class=MsoListBulletCxSpMiddle><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The EDAA's opt-out platform <a
                href="http://www.youronlinechoices.com/"><span style='color:windowtext;
text-decoration:none'>http://www.Youronlinechoices.com/</span></a></p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>The DAA's opt-out platform: <a
                href="http://optout.aboutads.info/?c=2&amp;lang=EN"><span style='color:windowtext;
text-decoration:none'>http://optout.aboutads.info/?c=2&amp;lang=EN</span></a></p>

              <p class=MsoNormal>The opt out will place a cookie on Your computer that is
                unique to the browser You use to opt out. If You change browsers or delete the
                cookies saved by Your browser, You will need to opt out again.</p>

              <h3>Mobile Devices</h3>

              <p class=MsoNormal>Your mobile device may give You the ability to opt out of
                the use of information about the apps You use in order to serve You ads that
                are targeted to Your interests:</p>

              <p class=MsoListBulletCxSpFirst><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>&quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of
                Ads Personalization&quot; on Android devices</p>

              <p class=MsoListBulletCxSpLast><span style='font-family:Symbol'>·<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
                dir=LTR></span>&quot;Limit Ad Tracking&quot; on iOS devices</p>

              <p class=MsoNormal>You can also stop the collection of location information
                from Your mobile device by changing the preferences on Your mobile device.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>&quot;Do Not Track&quot; Policy as Required by California
                  Online Privacy Protection Act (CalOPPA)</p>

              </div>

              <p class=MsoNormal>Our Service does not respond to Do Not Track signals.</p>

              <p class=MsoNormal>However, some third party websites do keep track of Your
                browsing activities. If You are visiting such websites, You can set Your
                preferences in Your web browser to inform websites that You do not want to be
                tracked. You can enable or disable DNT by visiting the preferences or settings
                page of Your web browser.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>Children's Privacy</p>

              </div>

              <p class=MsoNormal>Our Service does not address anyone under the age of 13. We
                do not knowingly collect personally identifiable information from anyone under
                the age of 13. If You are a parent or guardian and You are aware that Your
                child has provided Us with Personal Data, please contact Us. If We become aware
                that We have collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that information from
                Our servers.</p>

              <p class=MsoNormal>If We need to rely on consent as a legal basis for
                processing Your information and Your country requires consent from a parent, We
                may require Your parent's consent before We collect and use that information.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>Your California Privacy Rights (California's Shine the Light
                  law)</p>

              </div>

              <p class=MsoNormal>Under California Civil Code Section 1798 (California's Shine
                the Light law), California residents with an established business relationship
                with us can request information once a year about sharing their Personal Data
                with third parties for the third parties' direct marketing purposes.</p>

              <p class=MsoNormal>If You'd like to request more information under the
                California Shine the Light law, and if You are a California resident, You can
                contact Us using the contact information provided below.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>California Privacy Rights for Minor Users (California
                  Business and Professions Code Section 22581)</p>

              </div>

              <p class=MsoNormal>California Business and Professions Code section 22581 allow
                California residents under the age of 18 who are registered users of online
                sites, services or applications to request and obtain removal of content or
                information they have publicly posted.</p>

              <p class=MsoNormal>To request removal of such data, and if You are a California
                resident, You can contact Us using the contact information provided below, and
                include the email address associated with Your account.</p>

              <p class=MsoNormal>Be aware that Your request does not guarantee complete or
                comprehensive removal of content or information posted online and that the law
                may not permit or require removal in certain circumstances.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>Links to Other Websites</p>

              </div>

              <p class=MsoNormal>Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be directed to
                that third party's site. We strongly advise You to review the Privacy Policy of
                every site You visit.</p>

              <p class=MsoNormal>We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites or services.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>Changes to this Privacy Policy</p>

              </div>

              <p class=MsoNormal>We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on this page.</p>

              <p class=MsoNormal>We will let You know via email and/or a prominent notice on
                Our Service, prior to the change becoming effective and update the &quot;Last
                updated&quot; date at the top of this Privacy Policy.</p>

              <p class=MsoNormal>You are advised to review this Privacy Policy periodically
                for any changes. Changes to this Privacy Policy are effective when they are
                posted on this page.</p>

              <div style='border:none;border-bottom:solid #4F81BD 1.0pt;padding:0in 0in 4.0pt 0in'>

                <p class=MsoTitle>Contact Us</p>

              </div>

              <p class=MsoNormal>If You have any questions about this Privacy Policy, You can
                contact us:</p>

              <p class=MsoListParagraphCxSpFirst>By email: customerservice@mycallfinder.com</p>

              <p class=MsoListParagraphCxSpMiddle>By phone number: 1-800-639-1700</p>

              <p class=MsoListParagraphCxSpLast>By mail: 38 Eastwood Drive, Suite 401,
                South Burlington, VT 05403</p>

            </div>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
export default{}
</script>

<style>
/* Font Definitions */
@font-face
{font-family:Courier;
  panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
{font-family:"MS Mincho";
  panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
{font-family:"MS Gothic";
  panose-1:2 11 6 9 7 2 5 8 2 4;}
@font-face
{font-family:"Cambria Math";
  panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
{font-family:Calibri;
  panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
{font-family:Cambria;
  panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
{font-family:"\@MS Mincho";
  panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
{font-family:"\@MS Gothic";
  panose-1:2 11 6 9 7 2 5 8 2 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:0in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
h2
{mso-style-link:"Heading 2 Char";
  margin-top:10.0pt;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:0in;
  line-height:115%;
  page-break-after:avoid;
  font-size:13.0pt;
  font-family:"Calibri",sans-serif;
  color:#4F81BD;}
h3
{mso-style-link:"Heading 3 Char";
  margin-top:10.0pt;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:0in;
  line-height:115%;
  page-break-after:avoid;
  font-size:11.0pt;
  font-family:"Calibri",sans-serif;
  color:#4F81BD;}
p.MsoListBullet, li.MsoListBullet, div.MsoListBullet
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:.25in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBulletCxSpFirst, li.MsoListBulletCxSpFirst, div.MsoListBulletCxSpFirst
{margin-top:0in;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:.25in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBulletCxSpMiddle, li.MsoListBulletCxSpMiddle, div.MsoListBulletCxSpMiddle
{margin-top:0in;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:.25in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBulletCxSpLast, li.MsoListBulletCxSpLast, div.MsoListBulletCxSpLast
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:.25in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBullet2, li.MsoListBullet2, div.MsoListBullet2
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:.5in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBullet2CxSpFirst, li.MsoListBullet2CxSpFirst, div.MsoListBullet2CxSpFirst
{margin-top:0in;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:.5in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBullet2CxSpMiddle, li.MsoListBullet2CxSpMiddle, div.MsoListBullet2CxSpMiddle
{margin-top:0in;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:.5in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBullet2CxSpLast, li.MsoListBullet2CxSpLast, div.MsoListBullet2CxSpLast
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:.5in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBullet3, li.MsoListBullet3, div.MsoListBullet3
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:.75in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBullet3CxSpFirst, li.MsoListBullet3CxSpFirst, div.MsoListBullet3CxSpFirst
{margin-top:0in;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:.75in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBullet3CxSpMiddle, li.MsoListBullet3CxSpMiddle, div.MsoListBullet3CxSpMiddle
{margin-top:0in;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:.75in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListBullet3CxSpLast, li.MsoListBullet3CxSpLast, div.MsoListBullet3CxSpLast
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:.75in;
  text-indent:-.25in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
{mso-style-link:"Title Char";
  margin-top:0in;
  margin-right:0in;
  margin-bottom:15.0pt;
  margin-left:0in;
  border:none;
  padding:0in;
  font-size:26.0pt;
  font-family:"Calibri",sans-serif;
  color:#17365D;
  letter-spacing:.25pt;}
p.MsoTitleCxSpFirst, li.MsoTitleCxSpFirst, div.MsoTitleCxSpFirst
{mso-style-link:"Title Char";
  margin:0in;
  border:none;
  padding:0in;
  font-size:26.0pt;
  font-family:"Calibri",sans-serif;
  color:#17365D;
  letter-spacing:.25pt;}
p.MsoTitleCxSpMiddle, li.MsoTitleCxSpMiddle, div.MsoTitleCxSpMiddle
{mso-style-link:"Title Char";
  margin:0in;
  border:none;
  padding:0in;
  font-size:26.0pt;
  font-family:"Calibri",sans-serif;
  color:#17365D;
  letter-spacing:.25pt;}
p.MsoTitleCxSpLast, li.MsoTitleCxSpLast, div.MsoTitleCxSpLast
{mso-style-link:"Title Char";
  margin-top:0in;
  margin-right:0in;
  margin-bottom:15.0pt;
  margin-left:0in;
  border:none;
  padding:0in;
  font-size:26.0pt;
  font-family:"Calibri",sans-serif;
  color:#17365D;
  letter-spacing:.25pt;}
a:link, span.MsoHyperlink
{color:blue;
  /*text-decoration:underline;*/ }
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:.5in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
{margin-top:0in;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:.5in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
{margin-top:0in;
  margin-right:0in;
  margin-bottom:0in;
  margin-left:.5in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
{margin-top:0in;
  margin-right:0in;
  margin-bottom:10.0pt;
  margin-left:.5in;
  line-height:115%;
  font-size:11.0pt;
  font-family:"Cambria",serif;}
span.Heading2Char
{mso-style-name:"Heading 2 Char";
  mso-style-link:"Heading 2";
  font-family:"Calibri",sans-serif;
  color:#4F81BD;
  font-weight:bold;}
span.Heading3Char
{mso-style-name:"Heading 3 Char";
  mso-style-link:"Heading 3";
  font-family:"Calibri",sans-serif;
  color:#4F81BD;
  font-weight:bold;}
span.TitleChar
{mso-style-name:"Title Char";
  mso-style-link:Title;
  font-family:"Calibri",sans-serif;
  color:#17365D;
  letter-spacing:.25pt;}
span.1
{mso-style-name:Гиперссылка1;
  color:blue;
  /*text-decoration:underline; */ }
.MsoChpDefault
{font-size:11.0pt;
  font-family:"Cambria",serif;}
.MsoPapDefault
{margin-bottom:10.0pt;
  line-height:115%;}
@page WordSection1
{size:8.5in 11.0in;
  margin:1.0in 1.25in 1.0in 1.25in;}
div.WordSection1
{page:WordSection1;}
/* List Definitions */
ol
{margin-bottom:0in;}
ul
{margin-bottom:0in;}

</style>
