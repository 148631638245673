<template>
  <v-container fluid mt-0 pt-4 pr-6 pb-8 pl-12>
    <v-row>
      <v-btn fab small dark color="primary" href="/setup-faq/0" target="_blank" title="Click for Setup Help and FAQs" class="ml-auto">
        <v-icon dark>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-row>
    <v-row v-for="row in chunkedSetupMenuItems" :key="row.index" justify-space-between>
      <v-col v-for="card in row" :key="card.index" :cols="(12/colCount)" class="d-flex flex-column">
        <v-card v-if="card.title!='Scorecard Definitions'" class="mr-8 pa-1 setup-card flex d-flex flex-column" outlined tile>
          <v-card-title>{{card.title}}</v-card-title>
          <v-card-text>
            <p>{{card.description}}</p>
            <p v-if="setupMenuItemsLists[card.type + 'List'].length==0">There are no {{card.title}} defined. <a @click="$emit('makeNew',card.type)">Go ahead and define one &hellip;</a></p>
            <p class="list-container" v-else>
              <v-chip
                link
                color="primary"
                label
                small
                v-for="item in setupMenuItemsLists[card.type + 'List'].slice(0,listLimit)"
                :key="item.id"
                @click="openMenuAndSelectTarget(card.type, item.id)"
              >
                <span class="tag-name" :title="item.title + ' (' + item.id + ')' ">{{item.title}}</span>
              </v-chip>

              <v-chip v-if="setupMenuItemsLists[card.type + 'List'].length>listLimit"
                link
                color="accent"
                label
                small
                @click="openMenu(card.type)"
              >
                <span class="tag-name">+ {{ setupMenuItemsLists[card.type + 'List'].length - setupMenuItemsLists[card.type + 'List'].slice(0,listLimit).length }} more &hellip;</span>
              </v-chip>
            </p>
          </v-card-text>
        </v-card>
        <v-card v-else-if="card.title=='Scorecard Definitions'" class="mr-8 pa-1 setup-card flex d-flex flex-column" outlined tile>
          <v-card-title>{{card.title}}</v-card-title>
          <v-card-text>
            <p>{{card.description}}</p>
            <p v-if="setupMenuItemsLists[card.type + 'List'].length==0">There are no {{card.title}} defined. <a @click="$emit('makeNew',card.type)">Go ahead and define one &hellip;</a></p>
            <p class="list-container" v-else>
              <v-chip
                link
                color="primary"
                label
                small
                v-for="item in setupMenuItemsLists[card.type + 'List'].slice(0,listLimit)"
                :key="item.id"
                @click="openMenuAndSelectTarget(card.type, item.id)"
              >
                <span class="tag-name" :title="item.title + ' (' + item.id + ')' ">{{item.title}}</span>
              </v-chip>

              <v-chip v-if="setupMenuItemsLists[card.type + 'List'].length>listLimit"
                link
                color="accent"
                label
                small
                @click="openMenu(card.type)"
              >
                <span class="tag-name">+ {{ setupMenuItemsLists[card.type + 'List'].length - setupMenuItemsLists[card.type + 'List'].slice(0,listLimit).length }} more &hellip;</span>
              </v-chip>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
  import {mapGetters} from "vuex"
  export default {
    name: 'DefaultPage',
    data() {
      return {
        listLimit: 2,
        colCount: 2,
      }
    },
    props: {
      setupMenuItems: {
        type: Array,
        default: [],
      },
      setupMenuItemsLists: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapGetters('users',[
        'userData',
        'userDataLoadingStatus',
        'cfOnlyUser',
        'administratorUser',
      ]),
      chunkedSetupMenuItems() {
        let chunkedSetupMenuItems = [];
        let index = 0;
        while (index < this.setupMenuItems.length) {
          let tmparr = this.setupMenuItems.slice(index, this.colCount + index);
          chunkedSetupMenuItems.push(tmparr);
          index += this.colCount;
        }
        return chunkedSetupMenuItems;
      },
    },
    methods: {
      openMenu(type) {
        this.$emit('openMenu', type)
      },
      openMenuAndSelectTarget(type, id) {
        this.openMenu(type)
        this.$emit('selectTarget', { 'type':type, 'id':id, 'preventProp':true })
      }
    },
    mounted() {
    }
  }

</script>

<style scoped>
  .list-container {
    overflow-y: scroll;
    padding: 8px;
    margin: 8px;
  }

  .v-chip {
    margin: 2px 4px;
  }

  .tag-name {
    display: inline-block;
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

</style>
