<template>
  <div>
    <v-icon
      v-if="!processStatus && displaySoup"
      @click="exportTranscript"
      title="Add to export queue..."
      >mdi-tray-arrow-down
    </v-icon>
    <span v-else-if="displaySoup" title="Already in queue...">
      <v-icon title="Already in queue..." disabled>mdi-tray-arrow-down </v-icon>
    </span>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      Export is processing
        <v-btn color="blue" text @click="snackbar = false">
          Close
        </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/utils/AxiosInstance.js";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: {
    callId: {
      type: String,
      required: true
    },
    usingInsights: {
      type: Boolean,
      required: true
    },
    accountName: {
      type: String
    },
    accountNumber: {
      type: Number
    },
    callerNumber: {
      type: String
    },
    calledNumber: {
      type: String
    },
    agent: {
      type: String
    },
    callDirection: {
      type: String,
      required: true
    },
    callDate: {
      type: String,
      required: true
    },
    displaySoup: {
        type: Boolean,
        required: false,
        default: true,
    },
  },
  data: () => ({
    processChecker: 0,
    processing: false,
    date: new Date(),
    interval: {},
    value: 0,
    timerStarted: false,
    snackbar: false,
    timeout: 5000
  }),
  computed: {
    ...mapGetters("notifications", [
      "notificationsList",
      "notificationsListLoadingStatus",
      "notificationsListLoadingError",
      "notificationsListLoadingErrorStatus"
    ]),
    ...mapGetters("users", ["userData", "userDataLoadingStatus"]),
    userId() {
      return this.userData.id;
    },
    processStatus() {
      if (this.notificationsList.find(x => (x.type == 'insights' ? x.guid : x.id) === this.callId) == undefined) {
        this.value = 0;
      }
      let rtn_val = this.notificationsList.find(x => (x.type == 'insights' ? x.guid : x.id) === this.callId) == undefined
        ? false
        : this.notificationsList.find(x => (x.type == 'insights' ? x.guid : x.id) === this.callId).processing;
      
      this.$emit('transcriptDownloadSetter', rtn_val);
      return rtn_val
    }
  },
  methods: {
    startTimer() {
      this.interval = setInterval(() => {
        this.value += 10;
        if (this.value == 30) {
          this.timerStarted = false;
          clearInterval(this.interval);
        }
      }, 1000);
    },
    ...mapActions({
      updateNotificationsList: "notifications/updateNotificationsList",
      removeNotificationsListItem: "notifications/removeNotificationsListItem"
    }),
    checkCompletion() {
      let params = {
        id: this.callId,
        processing: true,
        user_id: this.userId,
        date: this.date,
        accountName: this.accountName,
        accountNumber: this.accountNumber,
        agent: this.agent,
        callerNumber: this.callerNumber,
        callDirection: this.callDirection,
        calledNumber: this.calledNumber,
        callDate: this.callDate,
        url: "",
        type: "transcript"
      };
      this.updateNotificationsList(params);
    },

    exportTranscript() {
      this.snackbar = true;
      this.timerStarted = true;
      this.processing = true;
      this.startTimer();
      let parameters = {
        id: this.callId,
        insights: this.usingInsights
      };
      let call_recording_url = process.env.VUE_APP_TRANSCRIPT_EXPORTS;
      axios.post(call_recording_url, parameters).then(response => {});
      this.checkCompletion();
    }
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>
.float-right {
  margin-left: 0.5rem;
}
</style>
