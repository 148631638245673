<template>
  <div :class="`scorecard-tile-outer-container elevation-1 ${highlightMe}`">
    <v-row>
      <v-col
        cols="10"
        class="pt-0 scorecard-title"
        :title="scorecard.title + ' (' + scorecard.id + ')'"
      >
        {{ scorecard.title }}
      </v-col>
      <v-col cols="2" class="pt-0 pb-1 d-flex justify-end">
        <v-btn icon x-small color="primary">
          <v-icon @click="toggleExpansion">mdi-arrow-expand</v-icon>
        </v-btn>
        <v-tooltip max-width="30rem" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isProcessing" color="primary" icon x-small v-bind="attrs" v-on="on">
              <v-icon
                class="processing"
                v-text="'mdi-autorenew'"
                small
              ></v-icon>
            </v-btn>
            <v-btn v-else color="primary" icon x-small v-bind="attrs" v-on="on">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>
            <span v-if="isProcessing">{{scorecardProcessingMessage}}</span><br v-if="isProcessing"/>
            <span v-if="checkMinTalktime"
              >{{ formatHMS(scorecard.info.minimumTalkTime) }} </span
            ><span v-else>No </span>minimum talk time<br />
            <span v-if="checkMaxTalktime"
              >{{ formatHMS(scorecard.info.maximumTalkTime) }} </span
            ><span v-else>No </span>maximum talk time<br />
            <span v-if="checkMinCallScore"
              >{{ scorecard.info.minimumCallScore }}% </span
            ><span v-else>No </span>minimum call score<br />
            <span v-if="checkMaxCallScore"
              >{{ scorecard.info.maximumCallScore }}% </span
            ><span v-else>No </span>maximum call score<br />
            Shared with: {{ sharedAccessRoles }}<br />
            Last published: {{ scorecard.info.updated_at }}<br />
            Scorecard ID: {{ scorecard.id }} <br />
            Created by: {{ scorecard.owner }}
            <span v-if="properDescription">
              <br />
              Description: {{ scorecard.description }}
            </span>
          </span>
        </v-tooltip>
        <v-btn :color="pinColor" icon x-small>
          <v-icon @click="pinFavorite">mdi-pin</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12">
        <div class="scorecard-tile-inner-container">
          <div ref="infoBox1" class="scorecard-score-tile scorecard-data-tile">

            <div :class="'scorecard-score-block '">
              <span v-if="isLoading">
                <v-progress-circular style="opacity: .25;" class="my-8 mr-2" color="white" indeterminate></v-progress-circular>
              </span>
              <score-radial v-else
                :score="scorecard.info.scorecardScore"
                :radius="computeRadius"
              ></score-radial>
            </div>
            <v-icon dark :class="vScoreIconClass">mdi-bullseye-arrow</v-icon>
            <span :class="tileTextClass">Average Score</span>
          </div>

          <div class="scorecard-totalcalls-tile scorecard-data-tile">
            <v-icon dark :class="vIconClass">mdi-finance</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-1 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else :class="tileValueClass">{{thousands(scorecard.info.totalCalls)}}</span>
            <span :class="tileTextClass">Total Calls</span>
          </div>

          <div class="scorecard-callsperday-tile scorecard-data-tile">
            <v-icon dark :class="vIconClass">mdi-calendar-multiselect</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-1 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else :class="tileValueClass">{{thousands(rounded(scorecard.info.averageCallsOverTime, 0))}}</span>
            <span :class="tileTextClass">Calls/{{ capitalize(grouping) }}</span>
          </div>

          <div class="scorecard-talktime-tile scorecard-data-tile">
            <v-icon dark :class="vIconClass">mdi-headset</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
              <v-progress-circular style="opacity: .25;" class="my-1 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span  v-else :class="tileValueClass">{{formatHMS(scorecard.info.averageDuration)}}</span>
            <span :class="tileTextClass">{{timeDisplayWords}}</span>
          </div>

        </div>

      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12">
        <div class="actions">
          <v-btn
            v-if="dynalabelCount > 1"
            text
            small
            color="primary"
            :to="{
              name: 'scorecards-v2-dynalabels',
              params: { scorecardId: scorecard.id },
              query: $route.query
            }"
            >Select Scorecard</v-btn
          >
          <v-btn
            v-else
            text
            small
            color="primary"
            :to="{
              name: 'scorecards-v2-agents',
              params: { scorecardId: scorecard.id, dynalabelId: 0 },
              query: $route.query
            }"
            >Select Scorecard</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ScoreRadial from "../Scorecards/ScoreRadial.vue";

export default {
  props: {
    processing: Number,
    loading: Boolean,
    scorecard: Object,
    favorite: Number,
    expanded: Number,
    grouping: String
  },
  components: {
    ScoreRadial
    // do stuff
  },
  data() {
    return {
      showTooltip: false,
      err: {},
      widgetSize: 30,
      scorecardProcessingMessage: process.env.VUE_APP_SCORECARD_PROCESSING_MESSAGE
    };
  },
  computed: {
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingError",
      "userDataLoadingErrorStatus"
    ]),
    dynalabelCount() {
      return this.scorecard.info.dynalabelCount
    },
    vScoreIconClass() {
      return "v-score-icon-" + this.$vuetify.breakpoint.name;
    },
    computeRadius() {
      return this.widgetSize;
    },
    sharedAccessRoles() {
      if(!this.scorecard.shares) return ""
      let roles = "";
      this.scorecard.shares.forEach((item, index) => {
        if (index < this.scorecard.shares.length - 1) {
          roles += item.title + ", ";
        } else {
          roles += item.title;
        }
      });
      return roles;
    },
    isProcessing() {
      return this.processing > 0;
    },
    isLoading() {
      return this.loading;
    },
    properDescription() {
      return (
        this.scorecard.description &&
        this.scorecard.description.length > 0 &&
        this.scorecard.description.toLowerCase() != "description"
      );
    },
    checkMaxTalktime() {
      return this.scorecard.info.maximumTalkTime != null;
    },
    checkMinTalktime() {
      return this.scorecard.info.minimumTalkTime != null;
    },
    checkMaxCallScore() {
      return this.scorecard.info.maximumCallScore != null;
    },
    checkMinCallScore() {
      return this.scorecard.info.minimumCallScore != null;
    },
    timeDisplayWords() {
      // 0 = talk time, 1 = handle time
      return (this.scorecard.info.timeDisplay) ? 'Handle Time' : 'Talk Time'
    },
    pinColor() {
      return this.favorite != this.scorecard.id ? "accent" : "warning";
    },
    tileTextClass() {
      return "tile-text tile-text-" + this.$vuetify.breakpoint.name;
    },
    tileValueClass() {
      return "tile-value tile-value-" + this.$vuetify.breakpoint.name;
    },
    vIconClass() {
      return "v-icon-" + this.$vuetify.breakpoint.name;
    },
    highlightMe() {
      return this.expanded == this.scorecard.id ? "highlight-bar" : "";
    }
  },
  methods: {
    ...mapActions("scorecardsV2", ["setFavoriteScorecardId"]),
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    toggleExpansion() {
      this.$emit("toggle-expansion", { id: this.scorecard.id });
    },
    pinFavorite() {
      if (this.scorecard.id == this.favorite) {
        this.setFavoriteScorecardId(0);
      } else {
        this.setFavoriteScorecardId(this.scorecard.id);
      }
    },
    getClass(position) {
      return position;
    },
    thousands(num) {
      return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    rounded(num, places) {
      return Number(num).toFixed(places);
    },
    formatHMS(seconds) {
      seconds = Math.round(seconds);
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    }
  },
  updated() {},
  mounted() {
    this.widgetSize = this.$refs.infoBox1.clientHeight * 0.2;
  },
  watch: {
    // do stuff
  }
};
</script>

<style  >
div.scorecard-tile-outer-container {
  padding: 0.5rem 1rem 0.25rem 1rem;
  margin-right: 1rem;
}

div.scorecard-tile-outer-container div.actions {
  padding: 0.25rem;
  text-align: right;
}

div.scorecard-tile-outer-container div.scorecard-tile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
  color: rgba(1, 106, 175, 1);
  font-weight: bold;
}

div.scorecard-tile-inner-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

div.scorecard-data-tile {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
}

div.scorecard-data-tile i.v-icon {
  opacity: 0.2;
  position: absolute;
  bottom: 0;
}

div.scorecard-data-tile span.tile-value {
  font-weight: bold;
  text-align: right;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(1, 106, 175, 1);
  border-radius: 0.25rem 0.25rem 0 0;
}

div.scorecard-data-tile span.tile-text {
  text-align: right;
  color: rgba(1, 106, 175, 1);
  background-color: rgba(1, 106, 175, 0.25);
  border-radius: 0 0 0.25rem 0.25rem;
}

div.scorecard-data-tile i.v-icon-xs {
  font-size: 4.5rem;
  left: -0.75rem;
}
div.scorecard-data-tile i.v-icon-sm {
  font-size: 4.5rem;
  left: -0.75rem;
}
div.scorecard-data-tile i.v-icon-md {
  font-size: 4.5rem;
  left: -0.75rem;
}
div.scorecard-data-tile i.v-icon-lg {
  font-size: 4.5rem;
  left: -0.75rem;
}
div.scorecard-data-tile i.v-icon-xl {
  font-size: 7.5rem;
  left: -1.5rem;
}

div.scorecard-data-tile span.tile-text-xs {
  padding: 0.5rem;
  font-size: 0.75rem;
}
div.scorecard-data-tile span.tile-text-sm {
  padding: 0.5rem;
  font-size: 0.75rem;
}
div.scorecard-data-tile span.tile-text-md {
  padding: 0.5rem;
  font-size: 0.75rem;
}
div.scorecard-data-tile span.tile-text-lg {
  padding: 0.5rem;
  font-size: 0.7rem;
}
div.scorecard-data-tile span.tile-text-xl {
  padding: 1rem;
  font-size: 0.9rem;
}

div.scorecard-data-tile span.tile-value-xs {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-sm {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-md {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-lg {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-xl {
  padding: 1rem;
  font-size: 1.8rem;
}

div.highlight-bar {
  border-left: 4px solid rgb(34, 124, 213);
}

div.scorecard-tile-outer-container {
  padding: 1.25rem 1rem 0.25rem 1rem;
  margin-right: 1rem;
}

div.scorecard-tile-outer-container div.actions {
  padding: 0.25rem;
  text-align: right;
}

div.scorecard-tile-outer-container div.scorecard-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
  color: rgba(1, 106, 175, 1);
  font-weight: bold;
}

div.scorecard-tile-inner-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "a c"
    "a d";
  grid-gap: 0.5rem;
}

div.scorecard-score-tile {
  grid-area: a;
}

div.scorecard-totalcalls-tile {
  grid-area: b;
}

div.scorecard-callsperday-tile {
  grid-area: c;
}

div.scorecard-talktime-tile {
  grid-area: d;
}

div.scorecard-data-tile {
  position: relative;
  overflow: hidden;
  display: grid;
}

div.scorecard-data-tile i.v-icon {
  opacity: 0.2;
  position: absolute;
  bottom: 0;
}

div.scorecard-data-tile span.tile-value {
  font-weight: bold;
  text-align: right;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(1, 106, 175, 0.85);
  border-radius: 0.25rem 0.25rem 0 0;
}

div.scorecard-data-tile span.tile-text {
  text-align: right;
  color: rgba(1, 106, 175, 1);
  background-color: rgba(1, 106, 175, 0.25);
  border-radius: 0 0 0.25rem 0.25rem;
}

div.scorecard-data-tile i.v-score-icon-xs {
  font-size: 5rem;
  left: -1rem;
}
div.scorecard-data-tile i.v-score-icon-sm {
  font-size: 6rem;
  left: -1.5rem;
}
div.scorecard-data-tile i.v-score-icon-md {
  font-size: 8rem;
  left: -2rem;
}
div.scorecard-data-tile i.v-score-icon-lg {
  font-size: 12rem;
  left: -4rem;
}
div.scorecard-data-tile i.v-score-icon-xl {
  font-size: 18rem;
  left: -3rem;
}

div.scorecard-data-tile i.v-icon-xs {
  font-size: 4.5rem;
  left: -0.75rem;
}
div.scorecard-data-tile i.v-icon-sm {
  font-size: 4.5rem;
  left: -0.75rem;
}
div.scorecard-data-tile i.v-icon-md {
  font-size: 4.5rem;
  left: -0.75rem;
}
div.scorecard-data-tile i.v-icon-lg {
  font-size: 4.5rem;
  left: -0.75rem;
}
div.scorecard-data-tile i.v-icon-xl {
  font-size: 7.5rem;
  left: -1.5rem;
}

div.scorecard-data-tile span.tile-text-xs {
  padding: 0.5rem;
  font-size: 0.75rem;
}
div.scorecard-data-tile span.tile-text-sm {
  padding: 0.5rem;
  font-size: 0.75rem;
}
div.scorecard-data-tile span.tile-text-md {
  padding: 0.5rem;
  font-size: 0.75rem;
}
div.scorecard-data-tile span.tile-text-lg {
  padding: 0.5rem;
  font-size: 0.7rem;
}
div.scorecard-data-tile span.tile-text-xl {
  padding: 1rem;
  font-size: 0.9rem;
}

div.scorecard-data-tile span.tile-value-xs {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-sm {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-md {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-lg {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-xl {
  padding: 1rem;
  font-size: 1.8rem;
}

div.highlight-bar {
  border-left: 4px solid rgb(34, 124, 213);
}

.scorecard-score-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 106, 175, 0.85);
  padding: 1rem;
}

.skeleton-dark {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 900 !important;
  background-color: rgba(180, 180, 180, 1) !important;
}

.skeleton-light {
  color: rgba(255, 255, 255, 1) !important;
  background-color: rgba(204, 204, 204, 1) !important;
}


i.processing {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
