import moment from "moment";
export default {
  methods: {
    dateRangeLabel() {
      let startDate = this.$store.getters['dateRange/start_date'];
      let endDate = this.$store.getters['dateRange/end_date'];
      // the date range has changed, so, figure out if we have a "picked" range
      let dateRangeStart = moment(startDate).format("MM/DD/YYYY");
      let dateRangeEnd = moment(endDate).format("MM/DD/YYYY");
      let label = '';

      if (
        dateRangeStart == moment().format("MM/DD/YYYY") &&
        dateRangeEnd == moment().format("MM/DD/YYYY")
      ) label = "Today";

      else if (
        dateRangeStart == moment().subtract(1, 'days').format("MM/DD/YYYY") &&
        dateRangeEnd == moment().subtract(1, 'days').format("MM/DD/YYYY")
      ) label = 'Yesterday';

      else if (
        dateRangeStart == moment().startOf("week").format("MM/DD/YYYY") &&
        dateRangeEnd == moment().format("MM/DD/YYYY")
      ) label = 'This Week';

      else if (
        dateRangeStart == moment().subtract(1, "week").startOf("week").format("MM/DD/YYYY") &&
        dateRangeEnd == moment().subtract(1, "week").endOf("week").format("MM/DD/YYYY")
      ) label = 'Last Week';

      else if (
        dateRangeStart == moment().startOf("month").format("MM/DD/YYYY") &&
        dateRangeEnd == moment().format("MM/DD/YYYY")
      ) label = 'This Month';

      else if (
        dateRangeStart == moment().subtract(1, "month").startOf("month").format("MM/DD/YYYY") &&
        dateRangeEnd == moment().subtract(1, "month").endOf("month").format("MM/DD/YYYY")
      ) label = 'Last Month';

      else if (
        dateRangeStart == moment().startOf("week").subtract(12, "weeks").format("MM/DD/YYYY") &&
        dateRangeEnd == moment().endOf("week").subtract(1, "week").format("MM/DD/YYYY")
      ) label = 'Last Twelve Weeks';

      else label = 'From ' + dateRangeStart + ' to ' + dateRangeEnd;

      return label;
    },
    isDateValid(ymd) {

      let currVal = ymd;

      if (currVal == '')
        return false

      let rxDatePattern = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/ //Declare Regex
      let dtArray = currVal.match(rxDatePattern); // is format OK?

      if (dtArray == null)
        return false

      // Checks for mm/dd/yyyy format.
      let dtYear = dtArray[1]
      let dtMonth = dtArray[3]
      let dtDay = dtArray[5]

      // Validate the actual date
      if (dtMonth < 1 || dtMonth > 12) {
        return false
      } else if (dtDay < 1 || dtDay > 31) {
        return false
      } else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31) {
        return false
      } else if (dtMonth == 2) {
        let isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
        if (dtDay> 29 || (dtDay ==29 && !isleap)) {
          return false
        }
      }
      return true

    }
  }
};
