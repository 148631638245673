import axios from "axios";



export const authentication = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    signInComplete: false,
    signOutComplete: false,
    authenticationErrorMessage: '',
    newCSRFToken: '',
    updateCSRF: false,
    toCSRFInvalid: false,
    goToCheckEmailFtl: false,
    goToCheckEmailFyp: false,
    goToPasswordSuccess:false,
    mfaImage: "",
    mfaCodePassed: "Not Verified",
    mfaStatus: "",
    mfaCheckedAndValid: "",
    mfaErrorMessage: "",
    goToSigninPage: false,
    setUserToken: false,
    mfaSigninComplete: false,
    appStatus: {
      start_datetime: "0000-00-00 00:00:00",
      end_datetime: "0000-00-00 00:00:00",
      message: "",
      status: "active",
    },
    loginReqPB: 'not_loaded',
  },

  getters: {
    isAuthenticated: state => state.isAuthenticated,
    signInComplete: state => state.signInComplete,
    signOutComplete: state => state.signOutComplete,
    authenticationErrorMessage: state => state.authenticationErrorMessage,
    newCSRFToken: state => state.newCSRFToken,
    updateCSRF: state => state.updateCSRF,
    toCSRFInvalid: state => state.toCSRFInvalid,
    goToCheckEmailFtl: state => state.goToCheckEmailFtl,
    goToCheckEmailFyp: state => state.goToCheckEmailFyp,
    goToPasswordSuccess: state => state.goToPasswordSuccess,
    mfaImage: state => state.mfaImage,
    mfaCodePassed: state => state.mfaCodePassed,
    mfaStatus: state => state.mfaStatus,
    mfaCheckedAndValid: state => state.mfaCheckedAndValid,
    mfaErrorMessage: state => state.mfaErrorMessage,
    goToSigninPage: state => state.goToSigninPage,
    getUserToken: state => state.setUserToken,
    appStatus: state => state.appStatus,
    mfaSigninComplete: state => state.mfaSigninComplete,
    loginReqPB: state => state.loginReqPB,
  },
  mutations: {
    SET_SIGNIN_COMPLETE(state, status) {
      state.signInComplete = status
    },
    SET_SIGNOUT_COMPLETE(state, status) {
      state.signOutComplete = status
    },
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
      // clean out local storage if unauthenticated
      if (!isAuthenticated) {
        localStorage.setItem("bearer", "");
        localStorage.setItem("authKey", "");
      }
    },
    SET_UPDATE_CSRF(state, status) {
      state.updateCSRF = status
    },
    SET_TO_CSRF_INVALID(state, status) {
      state.toCSRFInvalid = status
    },
    SET_GO_CHECK_EMAIL_FTL(state, status) {
      state.goToCheckEmailFtl = status
    },
    SET_GO_CHECK_EMAIL_FYP(state, status) {
      state.goToCheckEmailFyp = status
    },
    SET_AUTHENICATION_ERROR_MESSAGE(state, message) {
      state.authenticationErrorMessage = message
    },
    SET_NEW_CSRF_TOKEN(state,token) {
      state.newCSRFToken = token
      document.getElementById("csrf_token").value = token;
    },
    SET_GO_TO_PASSWORD_SUCCESS(state, status) {
      state.goToPasswordSuccess = status;
    },
    SET_MFA_IMAGE(state,image) {
      state.mfaImage = image;
    },
    MFA_CODE_PASSED(state, status) {
      state.mfaCodePassed = status
    },
    SET_MFA_STATUS(state, status) {
      state.mfaStatus = status
    },
    SET_MFA_CHECKED_AND_VALID(state, status) {
      state.mfaCheckedAndValid = status
    },
    SET_MFA_ERROR_MESSAGE(state, message) {
      state.mfaErrorMessage = message
    },
    SET_GO_TO_SIGNIN_PAGE(state,status) {
      state.goToSigninPage = status;
    },
    SET_USER_TOKEN(state, token) {
      state.newCSRFToken = token
      document.getElementById("user_token").value = token;
    },
    SET_USER_TOKEN_FLAG(state, status) {
      state.setUserToken = status;
    },
    SET_APP_STATUS(state, statusObject) {
      state.appStatus = statusObject
    },
    SET_MFA_SIGNIN_COMPLETE(state, status) {
      state.mfaSigninComplete = status
    },
    SET_LOGIN_REQ_PB(state, status) {
      state.loginReqPB = status
    },
  },
  actions: {
    setSignInComplete(context, status) {
      context.commit("SET_SIGNIN_COMPLETE", status);
    },
    setSignOutComplete(context, status) {
      context.commit("SET_SIGNOUT_COMPLETE", status);
    },
    setIsAuthenticated(context, isAuthenticated) {
      context.commit("SET_IS_AUTHENTICATED", isAuthenticated);
    },
    setUpdateCSRF(context,status) {
      context.commit("SET_UPDATE_CSRF", status)
    },
    setToCSRFInvalid(context,status) {
      context.commit("SET_TO_CSRF_INVALID", status)
    },
    setGoToCheckEmailFtl(context,status) {
      context.commit("SET_GO_CHECK_EMAIL_FTL", status)
    },
    setGoToCheckEmailFyp(context,status) {
      context.commit("SET_GO_CHECK_EMAIL_FYP", status)
    },
    setAuthenticationErrorMessage(context,errormessage) {
      context.commit("SET_AUTHENICATION_ERROR_MESSAGE", errormessage);
    },
    setNewCSRFToken(context, token){
      context.commit("SET_NEW_CSRF_TOKEN", token)
    },
    setGoToPasswordSuccess(context, status){
      context.commit("SET_GO_TO_PASSWORD_SUCCESS",status);
    },
    setMfaCodePassed(context, status) {
      context.commit("MFA_CODE_PASSED", status)
    },
    setMfaCheckedAndValid(context,status) {
      context.commit("SET_MFA_CHECKED_AND_VALID", status)
    },
    setMfaErrorMessage(context,message) {
      context.commit("SET_MFA_ERROR_MESSAGE", message)
    },
    setGoToSigninPage(context, status) {
      context.commit("SET_GO_TO_SIGNIN_PAGE",status);
    },
    setUserToken(context, status) {
      context.commit("SET_USER_TOKEN_FLAG",status);
    },
    setMfaSigninComplete(context, status) {
      context.commit("SET_MFA_SIGNIN_COMPLETE", status)
    },
    setLoginReqPB(context, status) {
      context.commit("SET_LOGIN_REQ_PB", status);
    },
    signOut(context) {
      context.commit("SET_IS_AUTHENTICATED", false);
      localStorage.setItem("authKey", '')
    },
    validateAndEmail(context,credentials) {
      const givenEmail = String(credentials.email);
      const givenCSRFToken = String(credentials.csrfToken);
      const givenSource = String(credentials.callSource);
      const passwordCSRF = process.env.VUE_APP_CSRF_PASSWORD
      axios
        .post(passwordCSRF,{
          csrf_token: givenCSRFToken,
          action: "email",
          email: givenEmail,
          source: givenSource
        })
        .then(response => {
          context.commit("SET_NEW_CSRF_TOKEN", String(response.data.csrf_token));
          context.commit("SET_UPDATE_CSRF",true);
          if (givenSource == "FTL") {
            context.commit("SET_GO_CHECK_EMAIL_FTL", true);
          } else if (givenSource == "FYP") {
            context.commit("SET_GO_CHECK_EMAIL_FYP", true);
          }
        }).catch(error => {
          // try to get error message, otherwise set to default
          let error_message = process.env.VUE_APP_SIGN_IN_FAILED

          // try to get csrf_token, otherwise set to ""
          let csrf_token = "";
          try {
            csrf_token = JSON.parse(error.response.data.error.message).csrf_token
          } catch(err) {
            csrf_token = ""
          }

          // if csrf_token is set. we probably threw the error
          if(csrf_token != "") {
            context.commit("SET_NEW_CSRF_TOKEN", String(csrf_token));
            context.commit("SET_UPDATE_CSRF",true);
          }
          // if csrf_token is blank and the error code is a 403, probably bad csrf token
          if (csrf_token == "" && error.response.status == 403) {
            context.commit("SET_TO_CSRF_INVALID", true);
          }

          context.commit("SET_AUTHENICATION_ERROR_MESSAGE", error_message);
        })
    },
    validateAndPassword(context, credentials) {
      const givenUserToken = String(credentials.userToken);
      const givenCSRFToken = String(credentials.csrfToken);
      const givenPassword = String(credentials.password)
      const passwordCSRF = process.env.VUE_APP_CSRF_PASSWORD

      axios
        .post(passwordCSRF,{
          user_token: givenUserToken,
          csrf_token: givenCSRFToken,
          action: "save",
          password: givenPassword,
        })
        .then(response => {
          context.commit("SET_NEW_CSRF_TOKEN", String(response.data.csrf_token))
          context.commit("SET_UPDATE_CSRF",true);
          // password is validated just send them back to the signin page
          context.commit("SET_GO_TO_PASSWORD_SUCCESS",true);
        }).catch(error => {
          // try to get error message, otherwise set to default
          let error_message = process.env.VUE_APP_SIGN_IN_FAILED

          // try to get csrf_token, otherwise set to ""
          let csrf_token = ""
          try {
            csrf_token = JSON.parse(error.response.data.error.message).csrf_token
          } catch(err) {
            csrf_token = ""
          }

          // if csrf_token is set. we probably threw the error
          if(csrf_token != "") {
            context.commit("SET_NEW_CSRF_TOKEN", String(csrf_token))
            context.commit("SET_UPDATE_CSRF",true)
          }
          // if csrf_token is blank and the error code is a 403, probably bad csrf token
          if (csrf_token == "" && error.response.status == 403) {
            context.commit("SET_TO_CSRF_INVALID", true)
          }

          context.commit("SET_AUTHENICATION_ERROR_MESSAGE", error_message);
        })
    },

    setToken(context, credentials) {
      // change password request from csi
      const userKey = String(credentials.key);
      const givenCSRFToken = String(credentials.csrfToken);
      const type = String(credentials.type)
      const passwordCSRF = process.env.VUE_APP_CSRF_PASSWORD
      axios
        .post(passwordCSRF,{
          key: userKey,
          csrf_token: givenCSRFToken,
          action: "setToken",
          type: type,
        })
        .then(response => {
          context.commit("SET_NEW_CSRF_TOKEN", String(response.data.csrf_token));
          context.commit("SET_UPDATE_CSRF",true);
          // update user token and enable setpassword page.
          context.commit("SET_USER_TOKEN", response.data.userToken)
        }).catch(error => {
          let errorMessage = '';
          let error_message = error.response.data.error.message
          let errData = {};
          try {
            error_message = JSON.parse(error_message)
            errData = { status: error.response.status, csrf_token: error_message.csrf_token, message: process.env.VUE_APP_SIGN_IN_FAILED }
          } catch(err) {
            // no csrf token returned
            errData = { status: error.response.status, csrf_token: "", message: process.env.VUE_APP_SIGN_IN_FAILED }
          }
          // if there is a csrf token
          if(errData.csrf_token != "") {
            errorMessage = process.env.VUE_APP_SIGN_IN_FAILED
            context.commit("SET_NEW_CSRF_TOKEN", String(errData.csrf_token));
            context.commit("SET_UPDATE_CSRF",true);
          } else {
            errorMessage = process.env.VUE_APP_SIGN_IN_FAILED
          }
          context.commit("SET_AUTHENICATION_ERROR_MESSAGE", errorMessage)
          // if there is an error send them to the signin page. they wont be able to set password anyway
          context.commit("SET_GO_TO_SIGNIN_PAGE",true)
        })
    },
    signIn(context, credentials) {
      const givenUsername = String(credentials.username);
      const givenPassword = String(credentials.password);
      const givenCSRFToken = String(credentials.csrfToken);
      const credentialsAuth = process.env.VUE_APP_AUTH_CREDENTIALS;
      const unique_id = localStorage.getItem("mfa_uniqueid");
      axios
        .post(credentialsAuth, {
          username: givenUsername,
          password: givenPassword,
          csrf_token: givenCSRFToken,
          unique_id: unique_id,
        })
        .then(response => {
          if (!response.data) return;
          const responseData = response.data;

          // if there is a csrf in the request and not an error then mfa is needed. dont set the bearer until after mfa.
          if (responseData.csrf_token) {
            context.commit("SET_NEW_CSRF_TOKEN",responseData.csrf_token)
            context.commit("SET_UPDATE_CSRF",true);
          } 

          let mfaStatus = responseData.mfa_status
          if (mfaStatus == "off") {
            if(responseData.bearer_token){
              localStorage.setItem("bearer", responseData.bearer_token)
              context.commit("SET_IS_AUTHENTICATED", true);
            }
          } 
          context.commit("SET_SIGNIN_COMPLETE",true);
          context.commit("SET_MFA_STATUS",mfaStatus)
          
        })
        .catch(error => {
          let error_message = process.env.VUE_APP_SIGN_IN_FAILED

          // try to get csrf_token, otherwise set to ""
          let csrf_token = "";
          try {
            csrf_token = JSON.parse(error.response.data.error.message).csrf_token
          } catch(err) {
            csrf_token = ""
          }

          // if csrf_token is set. we probably threw the error
          if(csrf_token != "") {
            context.commit("SET_NEW_CSRF_TOKEN", String(csrf_token));
            context.commit("SET_UPDATE_CSRF",true);
          }
          // if csrf_token is blank and the error code is a 403, probably bad csrf token
          if (csrf_token == "" && error.response.status == 403) {
            context.commit("SET_TO_CSRF_INVALID", true);
          }

          context.commit("SET_AUTHENICATION_ERROR_MESSAGE", error_message);
          context.dispatch('signOut');
        })

    },
    getMfaImage(context, input) {
      const givenUsername = String(encodeURIComponent(input.username))
      const givenPassword = String(encodeURIComponent(input.password))
      const givenCsrf = String(encodeURIComponent(input.csrfToken))
      const mfaImage = process.env.VUE_APP_MFA + "/?action=image&csrf_token=" + givenCsrf + "&username=" + givenUsername + "&password=" + givenPassword
      axios
        .get(mfaImage)
        .then(response => {
          if (response.data.csrf_token) {
            context.commit("SET_NEW_CSRF_TOKEN",response.data.csrf_token)
            context.commit("SET_UPDATE_CSRF",true)
          } 
          context.commit("SET_MFA_IMAGE", response.data.qr_image)
        })
        .catch(error => {
          let errorMessage = process.env.VUE_APP_SIGN_IN_FAILED
          if(error.response.status === 403) {
            // csrf token must have expired
            context.commit("SET_TO_CSRF_INVALID", true)
          }
          context.commit("SET_MFA_ERROR_MESSAGE", errorMessage)
          context.commit("SET_AUTHENICATION_ERROR_MESSAGE", errorMessage)
        })

    },
    
    verifyMfaCode(context, input) {
      const code = String(input.code)
      const givenUsername = String(encodeURIComponent(input.username));
      const givenPassword = String(encodeURIComponent(input.password));
      const givenCsrf = String(encodeURIComponent(input.csrfToken));
      const mfaVerify = process.env.VUE_APP_MFA + "/?action=verify&csrf_token=" + givenCsrf + "&username=" + givenUsername + "&password=" + givenPassword + "&code=" + code;

      axios
        .get(mfaVerify)
        .then(response => {
          if (response.data.csrf_token) {
            context.commit("SET_NEW_CSRF_TOKEN",response.data.csrf_token)
            context.commit("SET_UPDATE_CSRF",true);
          } 
          if(response.data.success) {
            context.commit("MFA_CODE_PASSED","Verified");
            // need to set the new uniqueid in localstorage
            localStorage.setItem("mfa_uniqueid", response.data.unique_id)
          } else {
            context.commit("MFA_CODE_PASSED","Not Verified");
          }
          
          // if a bearer is sent back send them through.
          if(response.data.bearer_token){
            localStorage.setItem("bearer", response.data.bearer_token)
            context.commit("SET_IS_AUTHENTICATED", true);
            context.commit("SET_MFA_SIGNIN_COMPLETE", true);
          }
        })
        .catch(error => {
          let errorMessage = process.env.VUE_APP_SIGN_IN_FAILED
          let error_message = error.response.data.error.message
          let errData = {};

          try {
            error_message = JSON.parse(error_message)
            errData = { status: error.response.status, csrf_token: error_message.csrf_token, message: errorMessage }
          } catch(err) {
            // no csrf token returned
            errData = { status: error.response.status, csrf_token: "", message: errorMessage }
          }
          //special case for 403 errors
          if(errData.status == 403) {
            if(errData.csrf_token!= "") {
              context.commit("SET_NEW_CSRF_TOKEN", String(errData.csrf_token));
              context.commit("SET_UPDATE_CSRF",true);
            } else {
              // no csrf given
              context.commit("SET_TO_CSRF_INVALID", true)
            }

          }
          // if its not a 403 and a csrf was returned rotate token
          if(errData.status !== 403 && errData.csrf_token != "") {
            context.commit("SET_NEW_CSRF_TOKEN", String(errData.csrf_token));
            context.commit("SET_UPDATE_CSRF",true);
          }
          context.commit("SET_MFA_ERROR_MESSAGE", errorMessage);
          context.commit("SET_AUTHENICATION_ERROR_MESSAGE", errorMessage);
        })
    },
    getAppStatus(context) {
      var appStatusURL = process.env.VUE_APP_STATUS_CHECK;
      axios
        .get(appStatusURL)
        .then(response => {
          if (!response.data) return
          context.commit("SET_APP_STATUS", response.data)
        })
        .catch(error => {
          // there shouldn't ever be an error with this. no csrf either which is fun
          // if there magically is an error just display the response
          let errorMessage = process.env.VUE_APP_SIGN_IN_FAILED
          let error_message = error.response.data.error.message
          try {
            error_message = JSON.parse(error_message)
            errData = { status: error.response.status, csrf_token: error_message.csrf_token, message: errorMessage }
          } catch(err) {
            // no csrf token returned
            errData = { status: error.response.status, csrf_token: "", message: errorMessage }
          }
          context.commit("SET_AUTHENICATION_ERROR_MESSAGE", errorMessage);
        })
    },
    checkLoginReqPB(context, params) {
      let axios_params = {
        params: {}
      }
      let url = process.env.VUE_APP_AUTHENTICATE_ROUTE + params.userid
      axios_params['params']['route'] = 'login-req-pb'
      axios_params['params']['call_id'] = params.call_id
      axios_params['headers'] = {"Authorization": params.userid + " " + params.reckey} 

      axios
      .get(url,axios_params)
      .then(response => {
        if(response.data.loginreqpb) {
          context.commit("SET_LOGIN_REQ_PB", "log_in");
        } else {
          context.commit("SET_LOGIN_REQ_PB", "dont_log_in");
        }
      }).catch(error => {
        if(error.response.data.error.message == "Invalid reckey") {
          // display invalid link
          context.commit("SET_LOGIN_REQ_PB", "dont_log_in");
        } else {
          // on other errors log in
          context.commit("SET_LOGIN_REQ_PB", "log_in");
        }
      })
    },
    deAuthConsumer(context) {
      if(localStorage.getItem("bearer")) {
        let url = process.env.VUE_APP_DE_AUTH_CONSUMER
        let bearerToken = localStorage.getItem("bearer")
        let params = {
          headers: {"authorization": "bearer " + bearerToken}
        }
        axios
          .delete(url, params)
          .finally(() => {
            localStorage.setItem("bearer", '')
          })
      } 
    }
  },

};
