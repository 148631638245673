<template>
  <v-container fluid mt-0 style="max-height: calc(100%);">
    <v-row style="max-height: calc(100%);">
      <v-col style="max-height: calc(100%); padding: 0; position: relative; overflow-y: scroll;">
        <SearchBox @SUBMIT_SEARCH="sendNewSearch" :loading="loading"></SearchBox>
        <SearchResults
          :results="transcripts"
          :loading="loading"
          :resultsCount="resultsCount"
          :wasSearched="wasSearched"
          :maxVisiblePages="maxVisiblePages"
          @PAGE_CHANGED="changePage"
        ></SearchResults>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchBox from "@/components/Transcripts/Search/SearchBox.vue";
import SearchResults from "@/components/Transcripts/Search/Results.vue";

import axios from "@/utils/AxiosInstance.js";

export default {
  components: {
    SearchBox,
    SearchResults
  },
  data() {
    return {
      errors: [],
      transcripts: {},
      loading: false,
      resultsCount: 0,
      wasSearched: false,
      maxVisiblePages: 3,
      limit: 5,
      offset: 0,
    };
  },
  methods: {
    changePage(page) {
      this.offset = page * this.limit;
      this.sendRequest();
    },
    sendNewSearch() {
      this.offset = 0;
      this.loading = true
      this.wasSearched = false
      this.resultsCount = 0
      this.$root.$emit("RESET_PAGINATION");
      this.sendRequest();
    },

    sendRequest() {
      // let loader = this.$loading.show();
      const params = this.getParams();

      let self = this;
      const url = process.env.VUE_APP_SEARCH_TRANSCRIPTS;
      axios
        .get(url, {
          params: params
        })
        .then(response => {
          if (response && response.data) {
            self.transcripts = response.data.transcripts;
            self.resultsCount = response.data.hits;
            self.wasSearched = true
            this.loading = false
            this.$emit("SEARCH_RESULTS_EXIST", response.data.hits > 0);
          }
        })
        .catch(error => {
          // TODO: need to figure out a better/single way to handle our errors
          alert(error);
        })
        .then(() => {
          this.loading = false
        });
    },

    // Get all relevent filters from vuex and merge into one object.
    // Yes, this should all be handled in vuex
    getParams() {
      let params = {
        start_date: this.$store.getters["dateRange/start_date"],
        end_date: this.$store.getters["dateRange/end_date"],
        limit: this.limit,
        offset: this.offset
      };
      const selectedFilters = this.$store.getters["filters/request"];
      const query = {
        query: this.$store.getters["searchQuery/query"]
      };

      Object.assign(params, selectedFilters);
      Object.assign(params, query);

      return params;
    }
  }
};
</script>
<style></style>
