<template>
  <v-content>
    <v-container>
      <!-- team overview block -->
      <scorecard-loading-status
        v-if="scorecardIsLoading"
        :message="scorecardMessageType"
        :type="scorecardLoadingType"
      >
      </scorecard-loading-status>
      <div v-else>
        <section>
          <!-- title block -->
          <v-row>
            <v-col cols="12">
              <scorecard-breadcrumbs
                :scorecardHeader="scorecardV2TeamData.scorecardTitle"
                :dynaLabelHeader="scorecardV2TeamData.dynalabelTitle"
                :singleDynaCount="scorecardV2TeamData.dynalabelCount < 2"
                :agentHeader="scorecardV2TeamData.agentName"
                :callId="$route.params.callId"
                :level="'printable-team'"
                :linkEnabled="true"
                @go-to-page="selectPage"
              ></scorecard-breadcrumbs>
            </v-col>
          </v-row>
          <v-row class="header">
            <v-col cols="6">
              <v-flex fill-height>
                <h1>{{ getScorecardName }}</h1>
                <h2>{{ getScorecardSubtitle }}</h2>
                <h3>Overview</h3>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex class="text-center card-container">
                <p class="caption">Calls/{{ capitalize(getUnit) }}</p>
                <h1 class="numbers pt-4">
                  {{ getTeamACV }} <span class="suffix"></span>
                </h1>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex class="text-center card-container">
                <p class="caption">{{ timeDisplayWords }}</p>
                <h1 class="numbers pt-4">
                  {{
                    formatHMS(
                      this.scorecardV2TeamData.items[0].metrics.averageDuration
                    )
                  }}
                </h1>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex class="text-center card-container">
                <p class="caption">Score</p>
                <h1 class="numbers pt-4">{{ getTeamACSLastScore }}%</h1>
              </v-flex>
            </v-col>
          </v-row>

          <!-- body block -->
          <v-row>
            <v-col :cols="6">
              <h3>Top 5 Scoring Agents</h3>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-right">Total Calls</th>
                      <th class="text-right">Score</th>
                      <th class="text-left">Score Distribution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in getTopAgents" :key="item.id">
                      <td class="agent-name">{{ item.agentName }}</td>
                      <td class="text-right">{{ item.metrics.totalCalls }}</td>
                      <td
                        :class="
                          'text-right score-block-' +
                            calcScoreBlockNumber(
                              Math.round(item.metrics.callScoreAvg * 100)
                            )
                        "
                      >
                        {{ Math.round(item.metrics.callScoreAvg * 100) }}%
                      </td>
                      <td class="histogram-small">
                        <div
                          :class="'pill-small pill-block-' + index"
                          v-for="(obs, index) in formatDistribution(
                            scorecardPrintableDistributionData[item.id]
                          )"
                          :key="index"
                          :style="
                            'height: ' +
                              calcObservationHeight(
                                formatDistribution(
                                  scorecardPrintableDistributionData[item.id]
                                ),
                                obs
                              ) +
                              'px'
                          "
                        ></div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col :cols="6">
              <h3>Bottom 5 Scoring Agents</h3>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-right">Total Calls</th>
                      <th class="text-right">Score</th>
                      <th class="text-left">Score Distribution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in getBottomAgents" :key="item.id">
                      <td class="agent-name">{{ item.agentName }}</td>
                      <td class="text-right">{{ item.metrics.totalCalls }}</td>
                      <td
                        :class="
                          'text-right score-block-' +
                            calcScoreBlockNumber(
                              Math.round(item.metrics.callScoreAvg * 100)
                            )
                        "
                      >
                        {{ Math.round(item.metrics.callScoreAvg * 100) }}%
                      </td>
                      <td class="histogram-small">
                        <div
                          :class="'pill-small pill-block-' + index"
                          v-for="(obs, index) in formatDistribution(
                            scorecardPrintableDistributionData[item.id]
                          )"
                          :key="index"
                          :style="
                            'height: ' +
                              calcObservationHeight(
                                formatDistribution(
                                  scorecardPrintableDistributionData[item.id]
                                ),
                                obs
                              ) +
                              'px'
                          "
                        ></div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </section>

        <!-- team scorecard -->
        <section flat>
          <template>
            <scorecard-view
              :agentId="0"
              :format="'printable-team'"
            ></scorecard-view>
          </template>
        </section>

        <!-- agent statistics block -->
        <section>
          <!-- title block -->
          <v-row class="header">
            <v-col cols="6">
              <v-flex fill-height>
                <h1>{{ getScorecardName }}</h1>
                <h2>{{ getScorecardSubtitle }}</h2>
                <h3>Agent Statistics</h3>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex class="text-center card-container">
                <p class="caption">Calls/{{ capitalize(getUnit) }}</p>
                <h1 class="numbers pt-4">
                  {{ getTeamACV }} <span class="suffix"></span>
                </h1>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex class="text-center card-container">
                <p class="caption">{{ timeDisplayWords }}</p>
                <h1 class="numbers pt-4">
                  {{
                    formatHMS(
                      this.scorecardV2TeamData.items[0].metrics.averageDuration
                    )
                  }}
                </h1>
              </v-flex>
            </v-col>
            <v-col cols="2">
              <v-flex class="text-center card-container">
                <p class="caption">Score</p>
                <h1 class="numbers pt-4">{{ getTeamACSLastScore }}%</h1>
              </v-flex>
            </v-col>
          </v-row>

          <!-- body block -->
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-right">Total Calls</th>
                      <th class="text-right">
                        Calls/{{ capitalize(getUnit) }}
                      </th>
                      <th class="text-right">{{ timeDisplayWords }}</th>
                      <th class="text-right">Score</th>
                      <th class="text-left">Score Distribution</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in allAgentsSorted" :key="item.id">
                      <template v-if="item.metrics.call_count != 0">
                        <td class="agent-name">{{ item.agentName }}</td>
                        <td class="att">{{ item.metrics.totalCalls }}</td>
                        <td class="acv">
                          {{ Math.round(item.metrics.averageCallVolume) }}
                        </td>
                        <td class="att">
                          {{ getATThms(item.metrics.averageDuration) }}
                        </td>
                        <td
                          :class="
                            'acs score-block-' +
                              calcScoreBlockNumber(item.metrics.callScoreAvg)
                          "
                        >
                          {{ formatScore(item.metrics.callScoreAvg) }}
                        </td>
                        <td class="histogram-large">
                          <div
                            :class="'pill-large pill-block-' + index"
                            v-for="(obs, index) in formatDistribution(
                              scorecardPrintableDistributionData[item.id]
                            )"
                            :key="index"
                            :style="
                              'height: ' +
                                calcObservationHeight(
                                  formatDistribution(
                                    scorecardPrintableDistributionData[item.id]
                                  ),
                                  obs
                                ) +
                                'px'
                            "
                          ></div>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </section>

        <!-- agent scorecards -->
        <div v-if="desiredContentLength && includeAllAgents">
          <section v-for="item in allAgentsSorted" :key="item.id">
            <scorecard-view
              :agentId="item.id"
              :format="'printable-team'"
            ></scorecard-view>
          </section>
        </div>
      </div>
    </v-container>
  </v-content>
</template>

<script>
import ScorecardLoadingStatus from "../../components/Scorecards/ScorecardLoadingStatus.vue";
import ScorecardView from "../../components/Scorecards/ScorecardView.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ScorecardBreadcrumbs from "../../components/Breadcrumbs/ScorecardBreadcrumbs.vue";
import selectPage from "@/mixins/selectPage";

const MAX_CONTENT_LENGTH = process.env.VUE_APP_MAX_SCORECARD_CONTENT;

export default {
  mixins: [selectPage],
  components: {
    ScorecardLoadingStatus,
    ScorecardView,
    ScorecardBreadcrumbs
  },
  data() {
    return {
      histogramHeight: 30
    };
  },
  computed: {
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    ...mapGetters("dateRange", ["start_date", "end_date"]),
    ...mapGetters("scorecardsV2Calls", [
      "scorecardV2Data",
      "scorecardV2DataLoadingStatus",
      "scorecardV2DataLoadingError",
      "scorecardV2DataLoadingErrorStatus",

      "scorecardContentLength",

      "scorecardV2TeamData",
      "scorecardV2TeamDataLoadingStatus",
      "scorecardV2TeamDataLoadingError",
      "scorecardV2TeamDataLoadingErrorStatus",

      "scorecardPrintableDistributionData",
      "scorecardPrintableDistributionDataLoadingStatus",
      "scorecardPrintableDistributionDataLoadingError",
      "scorecardPrintableDistributionDataLoadingErrorStatus"
    ]),
    desiredContentLength() {
      return Number(this.scorecardContentLength) <= Number(MAX_CONTENT_LENGTH);
    },
    getUnit() {
      return moment(this.end_date).diff(moment(this.start_date), "days") > 1
        ? "day"
        : "hour";
    },
    timeDisplayWords() {
      // 0 = talk time, 1 = handle time
      return this.scorecardV2TeamData.items[0].metrics.timeDisplay
        ? "Handle Time"
        : "Talk Time";
    },
    callAdjustmentsFilter() {
      return this.$route.query.hasOwnProperty("call_type")
        ? this.$route.query.call_type
        : "all";
    },
    scorecardIsLoading() {
      return (
        this.scorecardV2DataLoadingStatus != "loaded" ||
        this.scorecardV2DataLoadingErrorStatus ||
        this.scorecardV2TeamDataLoadingStatus != "loaded" ||
        this.scorecardPrintableDistributionDataLoadingStatus != "loaded" ||
        !this.scorecardAvailable
      );
    },
    includeAllAgents() {
      return this.scorecardV2Data.includeAllAgents;
    },
    scorecardAvailable() {
      //we need to access 'items' like this since presentation-service looks at an array at the aggregate level
      return this.scorecardV2Data.hasOwnProperty("items") &&
        this.scorecardV2Data.items.length > 0
        ? this.scorecardV2Data.items[0].metrics.hasOwnProperty("totalCalls")
        : false;
    },
    getScorecardName() {
      return this.scorecardV2TeamData.scorecardTitle;
    },
    getTeamACV() {
      return Math.round(
        this.scorecardV2TeamData.items[0].metrics.averageCallVolume
      );
    },
    getTeamACSLastScore() {
      return Math.round(
        this.scorecardV2TeamData.items[0].metrics.callScoreAvg * 100
      );
    },
    allAgentsSorted() {
      return this.alphaSort(
        this.scorecardV2Data.items.slice(0).filter(function(i) {
          // if the agent has 1 call or more
          return i.metrics.totalCalls > 0;
        })
      );
    },
    scorecardLoadingType() {
      if (this.scorecardV2DataLoadingErrorStatus) return "error";
      else if (
        this.scorecardV2DataLoadingStatus == "loaded" &&
        !this.scorecardAvailable
      ) {
        return "nodata";
      } else return "loading";
    },
    scorecardMessageType() {
      if (this.scorecardV2DataLoadingErrorStatus) {
        return "There was an error loading this scorecard...";
      } else if (
        this.scorecardV2DataLoadingStatus == "loaded" &&
        !this.scorecardAvailable
      ) {
        return "Scorecards are not available for the date-range filters you've selected. Please change your filter settings.";
      } else return "Scorecard loading, please wait...";
    },
    getScorecardSubtitle() {
      return this.buildScorecardSubtitle(
        this.scorecardV2TeamData.items[0].metrics.totalCalls
      );
    },
    getTopAgents() {
      let scoredAgentData = this.scorecardV2Data.items
        .slice(0)
        .filter(function(i) {
          return i.metrics.callScoreAvg >= 0 && i.metrics.totalCalls > 0;
        });
      scoredAgentData.sort(function(a, b) {
        return b.metrics.callScoreAvg - a.metrics.callScoreAvg;
      });
      return scoredAgentData.slice(0, 5);
    },
    getBottomAgents() {
      let scoredAgentData = this.scorecardV2Data.items
        .slice(0)
        .filter(function(i) {
          return i.metrics.callScoreAvg >= 0 && i.metrics.totalCalls > 0;
        });
      scoredAgentData.sort(function(a, b) {
        return a.metrics.callScoreAvg - b.metrics.callScoreAvg;
      });
      return scoredAgentData.slice(0, 5);
    },
  },
  methods: {
    ...mapActions({
      retrieveScorecard: "scorecardsV2Calls/retrieveScorecard",
      retrieveTeamScorecard: "scorecardsV2Calls/retrieveTeamScorecard",
      retrievePrintableScorecardDistributions:
        "scorecardsV2Calls/retrievePrintableScorecardDistributions"
    }),
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    formatScore(score) {
      score = score ? score : 0; // prevents NaN
      return Math.round(score * 100) + "%";
    },
    calcObservationHeight: function(a, count) {
      const max = Math.max(...a);
      if (count == 0) return 1;
      else return Math.round(this.histogramHeight * (count / max));
    },
    formatDistribution(distributionObject) {
      return Object.values(distributionObject);
    },
    formatHMS(seconds) {
      seconds = Math.round(seconds);
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    getATThms: function(attVal) {
      var mins = Math.floor(attVal / 60).toString();
      var secs = Math.floor(attVal - mins * 60).toString();
      if (secs.length < 2) secs = "0" + secs;
      return mins + ":" + secs;
    },
    calcScoreBlockNumber: function(num) {
      const blockNumber = Math.ceil(num / 0.1) - 1;
      return blockNumber;
    },
    alphaSort(agentList) {
      agentList.sort(function(a, b) {
        if (a.agentName.toUpperCase() < b.agentName.toUpperCase()) {
          return -1;
        } else if (a.agentName.toUpperCase() > b.agentName.toUpperCase()) {
          return 1;
        } else {
          return 0;
        }
      });
      return agentList;
    },
    formatDate(date) {
      return moment(date).format("MMMM D, YYYY");
    },
    buildScorecardSubtitle: function(callCount) {
      const startDate = this.formatDate(this.start_date);
      const endDate = this.formatDate(this.end_date);
      if (startDate == endDate) {
        return callCount + " calls on " + startDate;
      } else {
        return callCount + " calls from " + startDate + " to " + endDate;
      }
    },
    retrieveData() {
      if (this.$route.name == "printable-team-scorecard-v2") {
        let params = {
          scorecard_id: this.$route.params.scorecardId,
          dynalabel_id: this.$route.params.dynalabelId,
          agent_id: this.$route.params.agentId,
          grouping: this.getUnit,
          distributions: "printable-team",
          start_date: moment(this.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.end_date).format("YYYY-MM-DD"),
          call_type: this.callAdjustmentsFilter
            ? this.callAdjustmentsFilter
            : "all",
          all_agents: true
        };
        this.retrieveScorecard(params);
        this.retrieveTeamScorecard(params);
        this.retrievePrintableScorecardDistributions(params);
      }
    },
    selectPage(page) {
      this.selectScorecardPage(page, "scorecard");
    }
  },
  mounted() {
    this.retrieveData();
  }
};
</script>

<style scoped>
/* page layout commands */
@page {
  size: landscape;
  margin: 0.2in;
}

::v-deep table {
  page-break-inside: auto !important;
}
::v-deep table thead {
  display: table-header-group !important;
}
::v-deep table tfoot {
  display: table-footer-group !important;
}

/* print specific adjustments */
@media print {
  body {
    zoom: 81%;
  }

  ::v-deep table {
    page-break-inside: auto !important;
  }
  ::v-deep table thead {
    display: table-header-group !important;
  }
  ::v-deep table tfoot {
    display: table-footer-group !important;
  }
}

.scorecard-table {
  width: 100%;
  border-collapse: collapse;
}

.scorecard-table th {
  background-color: rgba(3, 35, 102, 0.7);
  color: rgba(255, 255, 255, 0.95);
  padding: 8pt;
  font-weight: 700;
  text-align: left;
  font-size: 11pt;
}
.scorecard-table th.category-score {
  text-align: right;
}
.scorecard-table td {
  padding: 4pt 8pt;
  font-size: 10pt;
  vertical-align: top;
}
.scorecard-table td.category {
  width: 30%;
}
.scorecard-table td.category:empty {
  page-break-before: avoid !important;
}
.scorecard-table td.sub-category {
  width: 30%;
}
.scorecard-table td.category-score {
  width: 25%;
  text-align: right;
}

.scorecard-table tr.foot td {
  background-color: rgba(3, 35, 102, 0.1);
  color: rgba(3, 35, 102, 0.7);
  padding: 8pt;
}

.pill-small {
  position: relative;
  display: inline-block;
  width: 10pt;
  height: 1px;
  cursor: help;
  border-radius: 2pt;
}
.pill-large {
  position: relative;
  display: inline-block;
  width: 20pt;
  height: 1px;
  cursor: help;
  border-radius: 2pt;
}

section {
  page-break-before: always;
}

.score-block-0 {
  color: #be4328;
  font-weight: 900;
}
.score-block-1 {
  color: #be5e24;
  font-weight: 900;
}
.score-block-2 {
  color: #be6424;
  font-weight: 900;
}
.score-block-3 {
  color: #be7821;
  font-weight: 900;
}
.score-block-4 {
  color: #be7c28;
  font-weight: 900;
}
.score-block-5 {
  color: #ccaa28;
  font-weight: 900;
}
.score-block-6 {
  color: #b4b328;
  font-weight: 900;
}
.score-block-7 {
  color: #9ebe28;
  font-weight: 900;
}
.score-block-8 {
  color: #86b422;
  font-weight: 900;
}
.score-block-9 {
  color: #5baa20;
  font-weight: 900;
}

.pill-block-0 {
  background-color: #be4328;
}
.pill-block-1 {
  background-color: #be5e24;
}
.pill-block-2 {
  background-color: #be6424;
}
.pill-block-3 {
  background-color: #be7821;
}
.pill-block-4 {
  background-color: #be7c28;
}
.pill-block-5 {
  background-color: #ccaa28;
}
.pill-block-6 {
  background-color: #b4b328;
}
.pill-block-7 {
  background-color: #9ebe28;
}
.pill-block-8 {
  background-color: #86b422;
}
.pill-block-9 {
  background-color: #5baa20;
}

.v-breadcrumbs {
  padding-left: 0 !important;
}
</style>
