<template>
  <v-container pl-8 pr-8 fluid class="scorecards-tile-grid">
    <v-row pb-12>
      <v-col :cols="expandedCols" v-if="checkExpanded">
        <span>
          <scorecards-expanded
            :loading="expandedScorecardLoadingStatus"
            :scorecard="getExpandedScorecard"
            :grouping="grouping"
            :grid="false"
            :favorite="favorite"
            :expanded="Number(expanded)"
            @toggle-expansion="toggleExpansion"
          ></scorecards-expanded>
        </span>
      </v-col>
      <v-col
        :cols="gridCols"
        v-if="scorecards.length > 1 || !this.checkExpanded"
      >
        <div class="scorecards-page-container" :style="getSpecialStyles">
          <span v-for="scorecard in scorecards" :key="scorecard.id">
              <scorecards-tile
                :processing="scorecard.processing"
                :loading="scorecard.loading"
                :grouping="grouping"
                :scorecard="scorecard"
                :favorite="favorite"
                :expanded="Number(expanded)"
                @toggle-expansion="toggleExpansion"
              ></scorecards-tile>
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ScorecardsExpanded from "./ScorecardsExpanded.vue";
import ScorecardsTile from "./ScorecardsTile.vue";
import CacheHandler from "@/utils/CacheHandler";

export default {
  props: {
    scorecards: Array,
    favorite: Number,
    grouping: String,
    scorecardsLoading: Boolean
  },
  components: {
    ScorecardsExpanded,
    ScorecardsTile,
  },
  data() {
    return {
      heightTimeout: null,
      debouncedHeight: 0,
      expanded: 0,
      isMounted: false,
      err: {}
    };
  },
  computed: {
    ...mapGetters({
      userData: state => state.users.userData,
      userDataLoadingStatus: state => state.users.userDataLoadingStatus,
      userDataLoadingError: state => state.users.userDataLoadingError,
      userDataLoadingErrorStatus: state =>
        state.users.userDataLoadingErrorStatus
    }),
    checkExpanded() {
      return Number(this.expanded) > 0 || false;
    },
    getExpandedScorecard() {
      return this.scorecards.find(i => i.id == this.expanded);
    },
    expandedScorecardLoadingStatus() {
      return this.scorecards.find(i => i.id == this.expanded).loading;
    },
    getSpecialStyles() {
      return this.checkExpanded
        ? "overflow-y: scroll; overflow-x: visible; padding: 1px 1px 1px 1px; margin-top -1px; height: " +
            this.expandedContainerHeight +
            "px;"
        : "";
    },
    myHeight: {
      get() {
        return this.debouncedHeight;
      },
      set(val) {
        if (this.heightTimeout) clearTimeout(this.heightTimeout);
        this.heightTimeout = setTimeout(() => {
          this.debouncedHeight = val;
        }, 25);
      }
    },
    expandedContainerHeight() {
      return Math.round(this.myHeight - 230);
    },
    expandedCols() {
      if (this.scorecards.length == 1) return 12;
      if (this.$vuetify.breakpoint.lgAndDown) return 8;
      else return 8;
    },
    gridCols() {
      if (!this.checkExpanded) return 12;
      if (this.$vuetify.breakpoint.lgAndDown) return 4;
      else return 4;
    }
  },
  methods: {
    resizeHandler() {
      this.myHeight = window.innerHeight;
    },
    toggleExpansion(e) {
      this.expanded = e.id;
    },
    getDefaultId() {
      let fav = Number(CacheHandler.getItem("favorite_scorecard")) || 0;
      let last = Number(CacheHandler.getItem("last_viewed_scorecard")) || 0;
      fav = this.scorecards.some(o => o.id == fav) ? fav : 0;
      last = this.scorecards.some(o => o.id == last) ? last : 0;
      if (fav) {
        let scorecard = this.scorecards.find(o => o.id == fav);
        if (scorecard.processing == 0) {
          this.expanded = fav;
        } else {
          let scorecard = this.scorecards.find(o => o.processing != 1);
          this.expanded = scorecard ? scorecard.id : null;
        }
      } else if (last) {
        let scorecard = this.scorecards.find(o => o.id == last);
        if (scorecard.processing == 0) {
          this.expanded = last;
        } else {
          let scorecard = this.scorecards.find(o => o.processing != 1);
          this.expanded = scorecard ? scorecard.id : null;
        }
      } else {
        let scorecard = this.scorecards.find(o => o.processing != 1);
        this.expanded = scorecard ? scorecard.id : null;
      }
    }
  },
  mounted() {
    this.myHeight = window.innerHeight;
    this.getDefaultId();
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {}
};
</script>

<style>
div.scorecards-page-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 1rem;
}

.scorecards-tile-grid {
  padding-bottom: 4rem;
}
</style>
