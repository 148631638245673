<template>

  <div class="subjective-container">
    <div class="subjective-observations" :style="{ 'padding-bottom': bottomPadding }">
      <v-tooltip top>
        <template>{{display}}</template>
        <template v-slot:activator="{ on, attrs }" >
          <div v-bind="attrs" v-on="on" class="subjective-observation-container">
            <div :class="'subjective-observation ' +  type + '-negative'" :style="'height:'+Math.round(info.secondHalfNegativeFraction*scale)+'px'"></div>
          </div>
        </template>
        <div>
          <table class="hover-table">
            <tr>
              <th colspan="2">Out of {{info.callCount}} calls, {{info.secondHalfNegativeCount}} ended negative</th><th>({{formatPercent(info.secondHalfNegativeFraction)}})</th>
            </tr>
            <tr>
              <td colspan="3">Of those &hellip;</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfNegativeFirstHalfNegativeCount}}</td><td>started negative</td><td class="r">{{formatValue(subjectiveErrorCorrectedNegativeValues.secondHalfNegativeFirstHalfNegativePercent)}}</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfNegativeFirstHalfNeutralCount}}</td><td>started neutral</td><td class="r">{{formatValue(subjectiveErrorCorrectedNegativeValues.secondHalfNegativeFirstHalfNeutralPercent)}}</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfNegativeFirstHalfPositiveCount}}</td><td>started positive</td><td class="r">{{formatValue(subjectiveErrorCorrectedNegativeValues.secondHalfNegativeFirstHalfPositivePercent)}}</td>
            </tr>
          </table>
        </div>
      </v-tooltip>
      <v-tooltip top>
        <template>{{display}}</template>
        <template v-slot:activator="{ on, attrs }" >
          <div v-bind="attrs" v-on="on" class="subjective-observation-container">
            <div :class="'subjective-observation ' + type + '-neutral'" :style="'height:'+Math.round(info.secondHalfNeutralFraction*scale)+'px'"></div>
          </div>
        </template>
        <div>
          <table class="hover-table">
            <tr>
              <th colspan="2">Out of {{info.callCount}} calls, {{info.secondHalfNeutralCount}} ended neutral</th><th>({{formatPercent(info.secondHalfNeutralFraction)}})</th>
            </tr>
            <tr>
              <td colspan="3">Of those &hellip;</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfNeutralFirstHalfNegativeCount}}</td><td>started negative</td><td class="r">{{formatValue(subjectiveErrorCorrectedNeutralValues.secondHalfNeutralFirstHalfNegativePercent)}}</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfNeutralFirstHalfNeutralCount}}</td><td>started neutral</td><td class="r">{{formatValue(subjectiveErrorCorrectedNeutralValues.secondHalfNeutralFirstHalfNeutralPercent)}}</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfNeutralFirstHalfPositiveCount}}</td><td>started positive</td><td class="r">{{formatValue(subjectiveErrorCorrectedNeutralValues.secondHalfNeutralFirstHalfPositivePercent)}}</td>
            </tr>
          </table>
        </div>
      </v-tooltip>

      <v-tooltip top>
        <template>{{display}}</template>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="subjective-observation-container">
            <div :class="'subjective-observation ' + type + '-positive'" :style="'height:'+Math.round(info.secondHalfPositiveFraction*scale)+'px'"></div>
          </div>
        </template>
        <div >
          <table class="hover-table">
            <tr>
              <th colspan="2">Out of {{info.callCount}} calls, {{info.secondHalfPositiveCount}} ended positive</th><th>({{formatPercent(info.secondHalfPositiveFraction)}})</th>
            </tr>
            <tr>
              <td colspan="3">Of those &hellip;</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfPositiveFirstHalfNegativeCount}}</td><td>started negative</td><td class="r">{{formatValue(subjectiveErrorCorrectedPositiveValues.secondHalfPositiveFirstHalfNegativePercent)}}</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfPositiveFirstHalfNeutralCount}}</td><td>started neutral</td><td class="r">{{formatValue(subjectiveErrorCorrectedPositiveValues.secondHalfPositiveFirstHalfNeutralPercent)}}</td>
            </tr>
            <tr>
              <td class="r">{{info.secondHalfPositiveFirstHalfPositiveCount}}</td><td>started positive</td><td class="r">{{formatValue(subjectiveErrorCorrectedPositiveValues.secondHalfPositiveFirstHalfPositivePercent)}}</td>
            </tr>
          </table>
        </div>
      </v-tooltip>

    </div>
  </div>
</template>

<script>
  export default {
    props: {
      info: {
        type: Object,
        required: true
      },
      scale: {
        type: Number,
        required: true
      },
      display: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
    },
    data: () => ({
      isActive: false
    }),
    computed: {
      bottomPadding() {
        // 1.2 rem is normal - larger scale requires smaller 'rem' bottom padding

        return String((.5 / (this.scale/100))) + "rem"
      },
      subjectiveErrorCorrectedNegativeValues() {
        let subjectiveFloorValues = {
          'secondHalfNegativeFirstHalfNegativePercent': (this.info.secondHalfNegativeCount>0) ? Math.floor(this.info.secondHalfNegativeFirstHalfNegativeCount/this.info.secondHalfNegativeCount*100) : 0,
          'secondHalfNegativeFirstHalfNeutralPercent': (this.info.secondHalfNegativeCount>0) ? Math.floor(this.info.secondHalfNegativeFirstHalfNeutralCount/this.info.secondHalfNegativeCount*100) : 0,
          'secondHalfNegativeFirstHalfPositivePercent': (this.info.secondHalfNegativeCount>0) ? Math.floor(this.info.secondHalfNegativeFirstHalfPositiveCount/this.info.secondHalfNegativeCount*100) : 0,
        }
        let subjectiveFractionValues = {
          'secondHalfNegativeFirstHalfNegativePercent': (this.info.secondHalfNegativeCount>0) ? (this.info.secondHalfNegativeFirstHalfNegativeCount/this.info.secondHalfNegativeCount*100) - Math.floor(this.info.secondHalfNegativeFirstHalfNegativeCount/this.info.secondHalfNegativeCount*100) : 0,
          'secondHalfNegativeFirstHalfNeutralPercent': (this.info.secondHalfNegativeCount>0) ? (this.info.secondHalfNegativeFirstHalfNeutralCount/this.info.secondHalfNegativeCount*100) - Math.floor(this.info.secondHalfNegativeFirstHalfNeutralCount/this.info.secondHalfNegativeCount*100) : 0,
          'secondHalfNegativeFirstHalfPositivePercent': (this.info.secondHalfNegativeCount>0) ? (this.info.secondHalfNegativeFirstHalfPositiveCount/this.info.secondHalfNegativeCount*100) - Math.floor(this.info.secondHalfNegativeFirstHalfPositiveCount/this.info.secondHalfNegativeCount*100) : 0,
        }
        let subjectiveFloorError = 100 - (
          subjectiveFloorValues.secondHalfNegativeFirstHalfNegativePercent
          + subjectiveFloorValues.secondHalfNegativeFirstHalfNeutralPercent
          + subjectiveFloorValues.secondHalfNegativeFirstHalfPositivePercent
        )
        let errorCorrection =  Object.entries(subjectiveFractionValues)
          .sort((a,b)=>b[1]-a[1])
          .map(el=>el[0]).slice(0,subjectiveFloorError)

        if(subjectiveFloorError==100) errorCorrection = []

        return {
          'secondHalfNegativeFirstHalfNegativePercent': subjectiveFloorValues.secondHalfNegativeFirstHalfNegativePercent + (errorCorrection.includes('secondHalfNegativeFirstHalfNegativePercent') ? 1 : 0),
          'secondHalfNegativeFirstHalfNeutralPercent': subjectiveFloorValues.secondHalfNegativeFirstHalfNeutralPercent + (errorCorrection.includes('secondHalfNegativeFirstHalfNeutralPercent') ? 1 : 0),
          'secondHalfNegativeFirstHalfPositivePercent': subjectiveFloorValues.secondHalfNegativeFirstHalfPositivePercent + (errorCorrection.includes('secondHalfNegativeFirstHalfPositivePercent') ? 1 : 0),
        }
      },
      subjectiveErrorCorrectedNeutralValues() {

        let subjectiveFloorValues = {
          'secondHalfNeutralFirstHalfNegativePercent': (this.info.secondHalfNeutralCount>0) ? Math.floor(this.info.secondHalfNeutralFirstHalfNegativeCount/this.info.secondHalfNeutralCount*100) : 0,
          'secondHalfNeutralFirstHalfNeutralPercent': (this.info.secondHalfNeutralCount>0) ? Math.floor(this.info.secondHalfNeutralFirstHalfNeutralCount/this.info.secondHalfNeutralCount*100) : 0,
          'secondHalfNeutralFirstHalfPositivePercent': (this.info.secondHalfNeutralCount>0) ? Math.floor(this.info.secondHalfNeutralFirstHalfPositiveCount/this.info.secondHalfNeutralCount*100) : 0,
        }
        let subjectiveFractionValues = {
          'secondHalfNeutralFirstHalfNegativePercent': (this.info.secondHalfNeutralCount>0) ? (this.info.secondHalfNeutralFirstHalfNegativeCount/this.info.secondHalfNeutralCount*100) - Math.floor(this.info.secondHalfNeutralFirstHalfNegativeCount/this.info.secondHalfNeutralCount*100) : 0,
          'secondHalfNeutralFirstHalfNeutralPercent': (this.info.secondHalfNeutralCount>0) ? (this.info.secondHalfNeutralFirstHalfNeutralCount/this.info.secondHalfNeutralCount*100) - Math.floor(this.info.secondHalfNeutralFirstHalfNeutralCount/this.info.secondHalfNeutralCount*100) : 0,
          'secondHalfNeutralFirstHalfPositivePercent': (this.info.secondHalfNeutralCount>0) ? (this.info.secondHalfNeutralFirstHalfPositiveCount/this.info.secondHalfNeutralCount*100) - Math.floor(this.info.secondHalfNeutralFirstHalfPositiveCount/this.info.secondHalfNeutralCount*100) : 0,
        }
        let subjectiveFloorError = 100 - (
          subjectiveFloorValues.secondHalfNeutralFirstHalfNegativePercent
          + subjectiveFloorValues.secondHalfNeutralFirstHalfNeutralPercent
          + subjectiveFloorValues.secondHalfNeutralFirstHalfPositivePercent
        )

        let errorCorrection =  Object.entries(subjectiveFractionValues)
          .sort((a,b)=>b[1]-a[1])
          .map(el=>el[0]).slice(0,subjectiveFloorError)

        if(subjectiveFloorError==100) errorCorrection = []

        return {
          'secondHalfNeutralFirstHalfNegativePercent': subjectiveFloorValues.secondHalfNeutralFirstHalfNegativePercent + (errorCorrection.includes('secondHalfNeutralFirstHalfNegativePercent') ? 1 : 0),
          'secondHalfNeutralFirstHalfNeutralPercent': subjectiveFloorValues.secondHalfNeutralFirstHalfNeutralPercent + (errorCorrection.includes('secondHalfNeutralFirstHalfNeutralPercent') ? 1 : 0),
          'secondHalfNeutralFirstHalfPositivePercent': subjectiveFloorValues.secondHalfNeutralFirstHalfPositivePercent + (errorCorrection.includes('secondHalfNeutralFirstHalfPositivePercent') ? 1 : 0),
        }
      },
      subjectiveErrorCorrectedPositiveValues() {

        let subjectiveFloorValues = {
          'secondHalfPositiveFirstHalfNegativePercent': (this.info.secondHalfPositiveCount>0) ? Math.floor(this.info.secondHalfPositiveFirstHalfNegativeCount/this.info.secondHalfPositiveCount*100) : 0,
          'secondHalfPositiveFirstHalfNeutralPercent': (this.info.secondHalfPositiveCount>0) ? Math.floor(this.info.secondHalfPositiveFirstHalfNeutralCount/this.info.secondHalfPositiveCount*100) : 0,
          'secondHalfPositiveFirstHalfPositivePercent': (this.info.secondHalfPositiveCount>0) ? Math.floor(this.info.secondHalfPositiveFirstHalfPositiveCount/this.info.secondHalfPositiveCount*100) : 0,
        }
        let subjectiveFractionValues = {
          'secondHalfPositiveFirstHalfNegativePercent': (this.info.secondHalfPositiveCount>0) ? (this.info.secondHalfPositiveFirstHalfNegativeCount/this.info.secondHalfPositiveCount*100) - Math.floor(this.info.secondHalfPositiveFirstHalfNegativeCount/this.info.secondHalfPositiveCount*100) : 0,
          'secondHalfPositiveFirstHalfNeutralPercent': (this.info.secondHalfPositiveCount>0) ? (this.info.secondHalfPositiveFirstHalfNeutralCount/this.info.secondHalfPositiveCount*100) - Math.floor(this.info.secondHalfPositiveFirstHalfNeutralCount/this.info.secondHalfPositiveCount*100) : 0,
          'secondHalfPositiveFirstHalfPositivePercent': (this.info.secondHalfPositiveCount>0) ? (this.info.secondHalfPositiveFirstHalfPositiveCount/this.info.secondHalfPositiveCount*100) - Math.floor(this.info.secondHalfPositiveFirstHalfPositiveCount/this.info.secondHalfPositiveCount*100) : 0,
        }
        let subjectiveFloorError = 100 - (
          subjectiveFloorValues.secondHalfPositiveFirstHalfNegativePercent
          + subjectiveFloorValues.secondHalfPositiveFirstHalfNeutralPercent
          + subjectiveFloorValues.secondHalfPositiveFirstHalfPositivePercent
        )
        let errorCorrection =  Object.entries(subjectiveFractionValues)
          .sort((a,b)=>b[1]-a[1])
          .map(el=>el[0]).slice(0,subjectiveFloorError)

        if(subjectiveFloorError==100) errorCorrection = []

        return {
          'secondHalfPositiveFirstHalfNegativePercent': subjectiveFloorValues.secondHalfPositiveFirstHalfNegativePercent + (errorCorrection.includes('secondHalfPositiveFirstHalfNegativePercent') ? 1 : 0),
          'secondHalfPositiveFirstHalfNeutralPercent': subjectiveFloorValues.secondHalfPositiveFirstHalfNeutralPercent + (errorCorrection.includes('secondHalfPositiveFirstHalfNeutralPercent') ? 1 : 0),
          'secondHalfPositiveFirstHalfPositivePercent': subjectiveFloorValues.secondHalfPositiveFirstHalfPositivePercent + (errorCorrection.includes('secondHalfPositiveFirstHalfPositivePercent') ? 1 : 0),
        }
      },
    },
    methods: {
      formatValue(val) {
        return String(Math.round(val)) + "%";
      },
      formatPercent(val) {
        return String(Math.round(val * 100)) + "%";
      },
    }
  }
</script>

<style scoped>

  .subjective-container { min-height: 100px; display: table; width: 100%; height: 100%; }
  .subjective-observations { min-height: 100px; display: table-cell; vertical-align: bottom; }
  .subjective-observation { display: inline-block; width: 100%; border-radius: 3px; margin: 0 1px; margin-top: .33rem; min-height: 1px; }

  .subjective-observation-container { display: inline-block; width: 16%; border-radius: 3px; margin: 0 1px; margin-top: .25rem; min-height: 1px; }

  .emotion-positive { background-color: rgb(183, 189, 77); }
  .emotion-neutral { background-color: rgba(194, 194, 194, 1); }
  .emotion-negative { background-color: rgba(205, 95, 9, 1); }
  .sentiment-positive { background-color: rgba(140, 199, 63, 1); }
  .sentiment-neutral { background-color: rgba(184, 184, 184, 1); }
  .sentiment-negative { background-color: rgba(179, 63, 38, 1); }

  .hover-table th { padding: .25rem .5rem; background-color: rgba(255,255,255,.1); }
  .hover-table td { padding: .25rem .5rem; }
  .hover-table td.r { text-align: right; }


</style>
