
export default {
    methods: {
        customSort(items, index, isDesc) {
            let xIndex = (Array.isArray(index)) ? index[0] : index
            let xIsDesc = (Array.isArray(isDesc)) ? isDesc[0] : isDesc
            items.sort((a, b) => {
              if (xIndex === "name") {
                if (!xIsDesc) {
                  return new Intl.Collator('en').compare(a.name, b.name)
                } else {
                  return new Intl.Collator('en').compare(b.name, a.name)
                }
              } else if (xIndex === "talkTimeAveragePercent") {
                if (!xIsDesc) {
                  return parseFloat(a['talkTimeAveragePercent']) < parseFloat(b['talkTimeAveragePercent']) ? -1 : 1;
                } else {
                  return parseFloat(b['talkTimeAveragePercent']) < parseFloat(a['talkTimeAveragePercent']) ? -1 : 1;
                }
              } else if (xIndex === "silenceAveragePercent") {
                if (!xIsDesc) {
                  return parseFloat(a['silenceAveragePercent']) < parseFloat(b['silenceAveragePercent']) ? -1 : 1;
                } else {
                  return parseFloat(b['silenceAveragePercent'])  < parseFloat(a['silenceAveragePercent']) ? -1 : 1;
                }
              } else if (xIndex === "overtalkAveragePercent") {
                if (!xIsDesc) {
                  return parseFloat(a['overtalkAveragePercent']) < parseFloat(b['overtalkAveragePercent']) ? -1 : 1;
                } else {
                  return parseFloat(b['overtalkAveragePercent'])  < parseFloat(a['overtalkAveragePercent']) ? -1 : 1;
                }
              } else if (xIndex === "callCount") {
                if (!xIsDesc) {
                  return Number(a['callCount']) < Number(b['callCount']) ? -1 : 1;
                } else {
                  return Number(b['callCount']) < Number(a['callCount']) ? -1 : 1;
                }
              } else if (xIndex === "callDuration_formatted") {
                  if (!xIsDesc) {
                    return Number(a['callDuration']) < Number(b['callDuration']) ? -1 : 1;
                  } else {
                    return Number(b['callDuration']) < Number(a['callDuration']) ? -1 : 1;
                  }
              } else if (xIndex === "averageDuration_formatted") {
                if (!xIsDesc) {
                  return Number(a['averageDuration']) < Number(b['averageDuration']) ? -1 : 1;
                } else {
                  return Number(b['averageDuration']) < Number(a['averageDuration']) ? -1 : 1;
                }
              } else if (xIndex === "silenceExtra") {
                if (!xIsDesc) {
                  return parseFloat(a['silencePercentAgent']) < parseFloat(b['silencePercentAgent']) ? -1 : 1;
                } else {
                  return parseFloat(b['silencePercentAgent']) < parseFloat(a['silencePercentAgent']) ? -1 : 1;
                }
              } else if (xIndex === "overtalkExtra") {
                if (!xIsDesc) {
                  return parseFloat(a['overtalkPercentAgent']) < parseFloat(b['overtalkPercentAgent']) ? -1 : 1;
                } else {
                  return parseFloat(b['overtalkPercentAgent']) < parseFloat(a['overtalkPercentAgent']) ? -1 : 1;
                }
              } else if (xIndex === "talkTimeExtra") {
                if (!xIsDesc) {
                  return parseFloat(a['talkTimePercentAgent']) < parseFloat(b['talkTimePercentAgent']) ? -1 : 1;
                } else {
                  return parseFloat(b['talkTimePercentAgent']) < parseFloat(a['talkTimePercentAgent']) ? -1 : 1;
                }
              } else if (xIndex === "call_customer_sentiment") {
                if (!xIsDesc) {
                  return a['sentimentCustomer'] < b['sentimentCustomer'] ? -1 : 1;
                } else {
                  return b['sentimentCustomer'] < a['sentimentCustomer'] ? -1 : 1;
                }
              }else if (xIndex === "call_agent_sentiment") {
                if (!xIsDesc) {
                  return a['sentimentAgent'] < b['sentimentAgent'] ? -1 : 1;
                } else {
                  return b['sentimentAgent'] < a['sentimentAgent'] ? -1 : 1;
                }
              }else if (xIndex === "call_overall_sentiment") {
                if (!xIsDesc) {
                  return a['sentimentOverall'] < b['sentimentOverall'] ? -1 : 1;
                } else {
                  return b['sentimentOverall'] < a['sentimentOverall'] ? -1 : 1;
                }
              }else if (xIndex === "call_customer_emotion") {
                if (!xIsDesc) {
                  return a['emotionCustomer'] < b['emotionCustomer'] ? -1 : 1;
                } else {
                  return b['emotionCustomer'] < a['emotionCustomer'] ? -1 : 1;
                }
              }else if (xIndex === "call_agent_emotion") {
                if (!xIsDesc) {
                  return a['emotionAgent'] < b['emotionAgent'] ? -1 : 1;
                } else {
                  return b['emotionAgent'] < a['emotionAgent'] ? -1 : 1;
                }
              }else if (xIndex === "call_overall_emotion") {
                if (!xIsDesc) {
                  return a['emotionOverall'] < b['emotionOverall'] ? -1 : 1;
                } else {
                  return b['emotionOverall'] < a['emotionOverall'] ? -1 : 1;
                }
              }else if (this.channelCount !==2 && (xIndex === "team_sentiment" || xIndex === "agent_sentiment")) {
                if (!xIsDesc) {
                  return this.sentimentSeeSaw(a) < this.sentimentSeeSaw(b) ? -1 : 1;
                } else {
                  return this.sentimentSeeSaw(b) < this.sentimentSeeSaw(a) ? -1 : 1;
                }
              } else if (xIndex === "team_emotion" || xIndex === "agent_emotion") {
                if (!xIsDesc) {
                  return this.emotionSeeSaw(a) < this.emotionSeeSaw(b) ? -1 : 1;
                } else {
                  return this.emotionSeeSaw(b) < this.emotionSeeSaw(a) ? -1 : 1;
                }
              } else if (xIndex === "team_sentiment" || xIndex === "agent_sentiment") {
                if (!xIsDesc) {
                  return this.sentimentSeeSaw(a) < this.sentimentSeeSaw(b) ? -1 : 1;
                } else {
                  return this.sentimentSeeSaw(b) < this.sentimentSeeSaw(a) ? -1 : 1;
                }
              } else {
                if (!xIsDesc) {
                  return a[xIndex] < b[xIndex] ? -1 : 1;
                } else {
                  return b[xIndex] < a[xIndex] ? -1 : 1;
                }
              }
            });
            return items;
          },
          emotionSeeSaw(o) {
            return (o.emotionCounts.secondHalfNegativeCount * -2) + (o.emotionCounts.secondHalfNeutralCount * 0) + (o.emotionCounts.secondHalfPositiveCount * 2)
          },
          sentimentSeeSaw(o) {
            return (o.sentimentCounts.secondHalfNegativeCount * -2) + (o.sentimentCounts.secondHalfNeutralCount * 0) + (o.sentimentCounts.secondHalfPositiveCount * 2)
          },
    },
}
