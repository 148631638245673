<template>
  <div>
    <div v-if="editing">
      <v-text-field
        ref="emailInput"
        v-model="emailInput"
        :rules="emailRules"
        label="Email"
        hide-details
        @keyup.esc="cancel"
        @keydown.enter="submit"
        autofocus
        @blur="cancel"
      >
        <template v-slot:append>
          <v-btn x-small icon @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn x-small icon @click="submit">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>
    <div v-else @click="edit">
      <span v-if="newEmail!=''">{{ newEmail }}</span>
      <span v-else class="grey--text">Click to add/remove email</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    origEmail: String,
    id: Number,
  },
  data() {
    return {
      focus: false,
      editing: false,
      emailInput: '',
      emailRules: [
        v => (/.+@.+\..+/.test(v) || v=="") || 'Email must be blank or valid',
      ],
      newEmail: ''
    }
  },
  methods: {
    edit() {
      this.emailInput = this.newEmail
      this.editing = true
    },
    submit() {
      if (this.$refs.emailInput.validate()) {
        this.newEmail = this.emailInput
        this.editing = false
        this.$emit('update-agent', {'id': this.id, 'email': this.newEmail})
      }
    },
    cancel() {
      this.editing = false;
      this.newEmail = this.origEmail
    },
  },
  mounted() {
    this.newEmail = this.origEmail
  }
};
</script>

