var hashlookup = {
    'nn' : 0,
    'nb' : 1,
    'np' : 2,
    'bn' : 3,
    'bb' : 4,
    'bp' : 5,
    'pn' : 6,
    'pb' : 7,
    'pp' : 8,
}
export default {
    methods: {
        getEmotionIndex(b, e) {
            if (b == undefined || e == undefined) {
                return -1
            }
            let bh = (b < .5) ? 'n' : (b <= .54 ? 'b' : 'p')
            let eh = (e < .5) ? 'n' : (e <= .54 ? 'b' : 'p')
            let hash = bh + eh

            return hashlookup[hash]
        }
    }
}