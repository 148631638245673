<template>
  <v-content style="height: 100vh; max-height: calc(100%);">
    <v-alert
      :value="true"
      color="warning"
      icon="mdi-message-alert-outline"
      class="ma-2"
      dark
    >
      Your user permissions do not allow access to this page
    </v-alert>
  </v-content>
</template>
<script>
export default {
    
};
</script>
