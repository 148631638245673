<template>
  <v-content style="height: 100vh; max-height: calc(100%);">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
      <v-row>
        <v-col
          style="height: 100vh; max-height: calc(100%); padding: 0; position: relative; align: center"
        >
          <Filters
            ref="myInsightsFilters"
            feature="insights"
            @filters-loaded="filtersLoaded"
            @agents-filter-updated-reload="filterUpdated"
            @subjectiveSet-filter-updated-reload="filterUpdated"
            @accounts-filter-updated-reload="filterUpdated"
          ></Filters>
          <insights-tile-grid
            v-if="
              insightDefinitionsList.length > 0 &&
                insightsListDataLoadingStatus == 'loaded'
            "
            :grouping="getUnit"
            :insightDefinitions="insightDefinitionsList"
            :favorite="favoriteInsightDefinitionId"
            :insightsLoading="insightsLoading"
          ></insights-tile-grid>
          <insights-loading-status
            v-else
            :message="insightsLoadingMessage"
            :type="insightsLoadingMessageType"
          ></insights-loading-status>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import Filters from "@/components/Filters.vue";
import InsightsTileGrid from "@/components/Insights/InsightsTileGrid.vue";
import InsightsLoadingStatus from "@/components/Insights/InsightsLoadingStatus.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CacheHandler from "@/utils/CacheHandler";

export default {
  components: {
    Filters,
    InsightsTileGrid,
    InsightsLoadingStatus
  },
  data() {
    return {
      insightDefinitionsList: [],
      insightsDefinitionsListLoadingStatus: "loaded",
      err: {},
      channelCount: 0,
      insightsLoading: false,
      listIndex: 0
    };
  },
  computed: {
    ...mapGetters("insights", [
      "insightsListData",
      "insightsListDataLoadingErrorStatus",
      "insightsListDataLoadingError",
      "insightsListDataLoadingStatus",
      "favoriteInsightDefinitionId",

      "insightLoadingItem"
    ]),
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingError",
      "userDataLoadingErrorStatus"
    ]),
    ...mapGetters("dateRange", [
      "start_date",
      "end_date",
      "date_range_english"
    ]),
    myRouteName() {
      return this.$route.name;
    },
    getUnit() {
      return moment(this.end_date).diff(moment(this.start_date), "days") > 1
        ? "day"
        : "hour";
    },
    getFavoriteId() {
      return this.favoriteInsightDefinitionId;
    },
    insightsLoadingMessage() {
      if (!localStorage.getItem("bearer")) {
        return "";
      }
      if (this.insightsListDataLoadingErrorStatus)
        return (
          "We encountered an error while retrieving insights (" +
          JSON.stringify(this.insightsListDataLoadingError) +
          ")."
        );
      else if (this.insightsListDataLoadingStatus == "loading")
        return "Retrieving insights, please wait&hellip;";
      else if (
        this.insightsListDataLoadingStatus == "loaded" &&
        this.insightsListData.length == 0
      )
        return "No Insights found.";
      else if (
        this.insightsListDataLoadingStatus == "loaded" &&
        this.insightsListData.length > 0
      )
        return "Successfully retrieved insights!";
    },
    insightsLoadingMessageType() {
      if (!localStorage.getItem("bearer")) {
        return null;
      }
      if (this.insightsListDataLoadingErrorStatus) return "error";
      else if (
        this.insightsListDataLoadingStatus == "loaded" &&
        this.insightsListData.length == 0
      )
        return "nodata";
      else return this.insightsListDataLoadingStatus;
    }
  },
  methods: {
    ...mapActions({
      retrieveInsightsList: "insights/retrieveInsightsList",
      retrieveInsightDefinition: "insights/retrieveInsightDefinition"
    }),
    ...mapActions("insights", ["setfavoriteInsightDefinitionId"]),
    setChannelCount() {
      return this.insightsListData.map(obj => {
        let objectInfo = obj.info;
        let channelCount = 0;
        if (
          objectInfo.single_channel_call_count > 0 &&
          objectInfo.dual_channel_call_count > 0
        ) {
          channelCount = 3;
        } else {
          channelCount = objectInfo.single_channel_call_count > 0 ? 1 : 2;
        }
        this.channelCount = channelCount;
      });
    },
    sortedListFavOnTop() {
      let myList = [...this.insightsListData].sort((a, b) => new Intl.Collator('en').compare(a.title, b.title));
      let found = myList.findIndex(
        e => e.id == this.favoriteInsightDefinitionId
      );
      if (this.favoriteInsightDefinitionId == 0 || found < 0) {
        this.insightDefinitionsList = myList;
      } else {
        let first = myList[found];
        myList.splice(found, 1);
        this.insightDefinitionsList = [first, ...myList];
      }
    },
    filtersLoaded(f) {
      this.agentsInScope = (f.find(x=> x.value=='agents')) ? f.find(x=> x.value=='agents').options : []
      this.accountsInScope = (f.find(x=> x.value=='accounts')) ? f.find(x=> x.value=='accounts').options : []
    },
    goBack() {
      this.agentId = 0;
      this.$router.push("/insights/").catch(() => {});
      setTimeout(function() {
        if (this.$vuetify) this.$vuetify.goTo(0);
      }, 1500);
    },
    filterUpdated() {
      this.loadInsights();
    },
    loadInsights() {
      let accountsFilterObject = this.$store.getters["filters/accounts"] || {
        accounts: []
      };
      let agentFilterObject = this.$store.getters["filters/agents"] || {
        agents: []
      };
      let subjectiveFilterObject = this.$store.getters[
        "filters/subjectiveSet"
      ] || { subjectiveSet: [] };
      let parameters = {
        grouping: this.getUnit,
        starts_at: moment(this.start_date).format("YYYY-MM-DD"),
        ends_at: moment(this.end_date).format("YYYY-MM-DD"),
        agent_ids: agentFilterObject.agents,
        account_ids: accountsFilterObject.accounts
      };
      if (this.channelCount > 1) {
        parameters.subjective = subjectiveFilterObject.subjectiveSet;
      }
      this.retrieveInsightsList(parameters);
    },
    listItemLoaded() {
      let indexOfObject = this.insightDefinitionsList.findIndex(object => {
        return object.id == this.insightLoadingItem.id;
      });
      if (indexOfObject !== -1) {
        this.insightDefinitionsList.splice(
          indexOfObject,
          1,
          this.insightLoadingItem
        );
      }
    },
    loadTile(chunk) {
      let accountsFilterObject = this.$store.getters["filters/accounts"] || {
        accounts: []
      };
      let agentFilterObject = this.$store.getters["filters/agents"] || {
        agents: []
      };
      let parameters = {
        grouping: this.getUnit,
        starts_at: moment(this.start_date).format("YYYY-MM-DD"),
        ends_at: moment(this.end_date).format("YYYY-MM-DD"),
        agent_ids: agentFilterObject.agents,
        account_ids: accountsFilterObject.accounts,
        speaker: this.$route.query.speaker,
        phrase: this.$route.query.phrase,
        insight: chunk
      };
      this.retrieveInsightDefinition(parameters);
    },
    loadMore() {
      let loadedCount = this.insightDefinitionsList.filter(
        value => !value.loading
      ).length;

      if (
        loadedCount >= this.listIndex &&
        this.insightsListDataLoadingStatus == "loaded" &&
        this.insightDefinitionsList.length > 0 &&
        this.insightDefinitionsList.some(element => element.loading)
      ) {
        this.loadInsightTiles();
      }
    },
    loadInsightTiles() {
      let localList = this.insightDefinitionsList;
      for (let i = this.listIndex; i < this.listIndex + 3; i++) {
        if (localList[i] != undefined) {
          this.loadTile(localList[i]);
        }
      }
      this.listIndex += 3;
    }
  },
  mounted() {
    if (this.userDataLoadingStatus == "loaded") {
      this.loadInsights();
      this.setfavoriteInsightDefinitionId(
        Number(CacheHandler.getItem("favorite_insight_definition") || 0)
      );
      this.sortedListFavOnTop();
    }
    this.$root.$on("DATE_RANGE_UPDATED", () => {
      this.listIndex = 0
      this.insightDefinitionsList = []
      this.$router
        .push({
          query: Object.assign(
            {},
            this.$route.query,
            {
              start_date: String(
                moment(this.$store.getters["dateRange/start_date"]).format(
                  "YYYY-MM-DD"
                )
              )
            },
            {
              end_date: String(
                moment(this.$store.getters["dateRange/end_date"]).format(
                  "YYYY-MM-DD"
                )
              )
            }
          )
        })
        .catch(err => {
          this.err = err;
        });

      if (this.userDataLoadingStatus == "loaded"&&this.myRouteName=='insights-dashboard') {
        this.loadInsights();
      }
    });

  },
  watch: {
    userDataLoadingStatus: function(status) {
      if(status=='loaded') {
        this.loadInsights()
        this.setfavoriteInsightDefinitionId(
          Number(CacheHandler.getItem("favorite_insight_definition") || 0)
        );
        this.sortedListFavOnTop();
      }
    },
    insightLoadingItem: "listItemLoaded",
    insightsListDataLoadingStatus: "sortedListFavOnTop",
    insightDefinitionsList: "loadMore"
  }
};
</script>


<style scoped>

.progress {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5);
}
</style>
