<template>
  <v-btn-toggle mandatory v-model="toggleGroup">
    <v-btn small value="hit">
      hit
    </v-btn>
    <v-btn small value="non-hit">
      non-hit
    </v-btn>
  </v-btn-toggle>
</template>

<script>

  import {mapActions} from "vuex"

  export default {
    props: {
      glue: String,
      pathToParent: String,
    },
    data() {
      return {
        toggleGroup: ''
      }
    },
    methods: {
      updateVModel() {
        this.toggleGroup = this.glue
      }
    },
    mounted() {
      this.updateVModel()
    },
    watch: {
      glue: 'updateVModel'
    }
  }
</script>

<style>

</style>
