<template>
  <div>
    <v-row>
      <v-col cols="6">
        <fieldset>
          <legend>
            <setup-tip :section="'dynalabels'"></setup-tip> Dynamic
            Label Selections
          </legend>
          <v-data-table
            id="dynamicLabelsDataTbl"
            :loading="tablesLoading"
            loading-text="Loading... Please wait"
            v-model="dynaLabelsSelected"
            :headers="dynaLabelHeaders"
            :items="selectableDynaLabels"
            :search="dynaLabelSearch"
            :hide-default-footer="true"
            @item-selected="selectItem"
            @toggle-select-all="selectAll"
            item-key="id"
            show-select
            disable-pagination
            class="dl-dt row-pointer ma-3"
            dense
            height="20vh"
          >
            <template v-slot:header.data-table-select="{ props, on }">
              <v-simple-checkbox
                :value="props.value || props.indeterminate"
                v-on="on"
                :indeterminate="props.indeterminate"
                color="primary"
                :ripple="false"
              />
            </template>
            <template v-slot:top>
              <v-text-field
                v-model="dynaLabelSearch"
                label="Find Dynamic Labels..."
                class="mx-4"
                single-line
                dense
                hide-details
                clearable
                append-icon="mdi-magnify"
              ></v-text-field>
            </template>
          </v-data-table>
        </fieldset>
      </v-col>
      <v-col cols="6">
        <fieldset>
          <legend>
            <setup-tip :section="'accessroles'"></setup-tip> Access Role
            Selections
          </legend>
          <v-data-table
            id="accessRolesDataTbl"
            :loading="tablesLoading"
            loading-text="Loading... Please wait"
            v-model="accessRolesSelected"
            :headers="accessRoleHeaders"
            :items="selectableAccessRoles"
            :search="accessRoleSearch"
            :hide-default-footer="true"
            @item-selected="selectItem"
            @toggle-select-all="selectAll"
            item-key="id"
            @click:row="rowSelected"
            show-select
            disable-pagination
            class="dl-dt row-pointer ma-3"
            dense
            height="20vh"
          >
            <template v-slot:header.data-table-select="{ props, on }">
              <v-simple-checkbox
                :value="props.value || props.indeterminate"
                v-on="on"
                :indeterminate="props.indeterminate"
                color="primary"
                :ripple="false"
              />
            </template>
            <template v-slot:top>
              <v-text-field
                v-model="accessRoleSearch"
                label="Find Access Roles..."
                class="mx-4"
                single-line
                dense
                hide-details
                clearable
                append-icon="mdi-magnify"
              ></v-text-field>
            </template>
          </v-data-table>
        </fieldset>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6">
        <fieldset>
          <legend>
            <setup-tip :section="'talktime'"></setup-tip> Talk Time
            Limits
          </legend>
          <v-row align="center">
            <v-col cols="6" class="d-flex justify-center align-center">
              <h5>Minimum</h5>
              <!-- min -->
              <vue-timepicker
                class="mx-4"
                :class="minTalktimeError"
                format="HH:mm:ss"
                v-model="minTime"
                @change="minTalkTimeHandler"
              ></vue-timepicker>
            </v-col>
            <v-col cols="6" class="d-flex justify-center align-center">
              <h5>Maximum</h5>
              <!-- max -->
              <vue-timepicker
                class="mx-4"
                :class="maxTalktimeError"
                format="HH:mm:ss"
                v-model="maxTime"
                @change="maxTalkTimeHandler"
              ></vue-timepicker>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="pa-0">
            <v-spacer></v-spacer>
            <v-col cols="4" class="pa-0">
              <p v-if="minInputError" class="input-invalid pa-0">
                The Minimum Talk Time Limit must be less than the Maximum Talk
                Time Limit
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" class="pa-0">
              <p v-if="maxInputError" class="input-invalid pb-0">
                The Maximum Talk Time Limit must be more than the Minimum Talk
                Time Limit
              </p>
            </v-col>
          </v-row>
        </fieldset>
      </v-col>
      <v-col cols="6">
        <fieldset>
          <legend>
            <setup-tip :section="'callscore'"></setup-tip> Call Score
            Limits
          </legend>
          <v-row>
            <v-col cols="6" class="d-flex justify-center align-center">
              <h5>Minimum</h5>
              <!-- min -->
              <v-text-field
                clearable
                dense
                single-line
                hide-details
                class="mx-4"
                width="10rem"
                v-model="callMinValue"
                type="number"
                label="0-100"
                style="max-width:10rem;"
                outlined
                :class="{ 'call-score-invalid': minCallScoreError }"
                :max="maxValue"
                :min="minValue"
                :rules="minCallScoreRule"
                @input="minCallScoreHandler"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="d-flex justify-center align-center">
              <h5>Maximum</h5>
              <!-- max -->
              <v-text-field
                single-line
                clearable
                dense
                class="mx-4"
                hide-details
                v-model="callMaxValue"
                type="number"
                label="0-100"
                style="max-width:10rem;"
                :class="{ 'call-score-invalid': maxCallScoreError }"
                outlined
                :max="maxValue"
                :min="minValue"
                :rules="maxCallScoreRule"
                @input="maxCallScoreHandler"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="pa-0">
            <v-spacer></v-spacer>
            <v-col cols="4" class="pa-0">
              <p v-if="minCallScoreError" class="input-invalid pa-0">
                The Minimum Call Score Limit must be less than the Maximum Call
                Score Limit
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" class="pa-0">
              <p v-if="maxCallScoreError" class="input-invalid pb-0">
                The Maximum Call Score Limit must be more than the Minimum Call
                Score Limit
              </p>
            </v-col>
          </v-row>
        </fieldset>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <fieldset>
          <legend>
            <setup-tip :section="'settings'"></setup-tip> Scorecard Settings
          </legend>
          <v-row>
            <v-col cols="4" class="d-flex justify-center align-center">
              <strong class="settings">
                Display talk time or handle time</strong
              >
              <v-btn-toggle
                style="width:50%;"
                class="mx-4"
                v-model="displayTalkOrHandle"
                mandatory
              >
                <v-btn @click="setTalkOrHandle(0)" x-small>talk time</v-btn>
                <v-btn @click="setTalkOrHandle(1)" x-small>handle time</v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="4" class="d-flex justify-center align-center">
              <strong class="settings">Non-applicable category scoring</strong>
              <v-btn-toggle
                v-model="nonApplicableCatChoice"
                mandatory
                class="mx-4"
              >
                <v-btn @click="setOmittedCategoryScoring(0)" x-small
                  >Redistribute weight</v-btn
                >
                <v-btn @click="setOmittedCategoryScoring(1)" x-small
                  >score 100%</v-btn
                >
              </v-btn-toggle>
            </v-col>
            <v-col cols="4" class="d-flex justify-center align-center">
              <strong class="settings">Include agent scorecards</strong>
              <v-btn-toggle
                v-model="invertIncludeAllAgentsChoice"
                mandatory
                class="mx-4"
              >
                <v-btn @click="setIncludeAllAgents(1)" x-small
                >Yes</v-btn
                >
                <v-btn @click="setIncludeAllAgents(0)" x-small
                >No</v-btn
                >
              </v-btn-toggle>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </fieldset>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <fieldset class="fieldset-container">
          <legend>Categories</legend>
          <div class="floating-button">
            <v-btn
              @click="expandCategories"
              color="blue-grey"
              class="white--text"
              v-if="canExpand"
              x-small
              >Expand all</v-btn
            >
            <v-btn
              color="blue-grey"
              class="white--text"
              @click="collapseCategories"
              v-if="canCollapse"
              x-small
              >Collapse all</v-btn
            >
          </div>
          <v-row>
            <v-col cols="12" class="d-flex justify-center align-center pt-0">
              <scorecard-category-editor
                :scorecardId="scorecardDefinitionId"
                :categories="categories"
                :mediaType="mediaType"
                @setPublish="setPublish"
                @updateSubCategory="updateSubCategory"
                @updatesCancelled="cancelUpdates"
                @checkSubCategorySearches="checkSubCategorySearches"
              >
              </scorecard-category-editor>
            </v-col>
          </v-row>
        </fieldset>
      </v-col>
    </v-row>
    <access-role-modal
      :showModal="showModal"
      :accessRoleId="String(accessRoleId)"
      @HIDE_MODAL="hideModal"
      @updateAccessRoleList="loadSelections"
    ></access-role-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import AccessRoleModal from "../AccessRoles/AccessRoleModal.vue";
import moment from "moment";
import ScorecardCategoryEditor from "./ScorecardCategoryEditor.vue";
import SetupTip from "@/components/SetupTip.vue";

export default {
  components: {
    VueTimepicker,
    AccessRoleModal,
    ScorecardCategoryEditor,
    SetupTip
  },
  data() {
    return {
      minCallScoreRule: [
        v => (v >= 0 && v <= 100) || "Number must be between 0-100",
        v => Number(v) % 1 === 0 || "Number must be between 0-100",

      ],
      maxCallScoreRule: [
        v => (v >= 0 && v <= 100) || "Number must be between 0-100",
        v => Number(v) % 1 === 0 || "Number must be between 0-100",
      ],
      showScorecardSettings: false,
      showModal: false,
      displayHoldTime: false,
      categoriesSelected: [],
      dynaLabelsSelected: [],
      accessRolesSelected: [],
      selectableDynaLabels: [],
      selectableAccessRoles: [],
      dynaLabelSelections: [],
      accessRoleSelections: [],
      accessRoleSearch: "",
      myAccessRoles: [],
      minValue: 0,
      maxValue: 100,
      myDynaLabels: [],
      maxTalkLength: null,
      minTalkLength: null,
      dynaLabelSearch: "",
      accessRoleId: "",
      callMinValue: null,
      callMaxValue: null,
      displayTalkOrHandle: 0,
      nonApplicableCatChoice: 0,
      includeAllAgentsChoice: 1,
      minInputError: false,
      maxInputError: false,
      minCallScoreError: false,
      maxCallScoreError: false,
      minTime: {
        HH: "",
        mm: "",
        ss: ""
      },
      maxTime: {
        HH: "",
        mm: "",
        ss: ""
      },
      dynaLabelHeaders: [
        {
          text: "Dynamic Labels",
          align: "start",
          sortable: false,
          value: "title"
        }
      ],
      accessRoleHeaders: [
        {
          text: "Share With",
          align: "start",
          sortable: false,
          value: "title"
        }
      ]
    };
  },
  props: {
    scorecardDefinitionId: String,
    selectedDynaLabels: Array,
    selectedAccessRoles: Array,
    minTalkTime: Number,
    maxTalkTime: Number,
    minCallScore: Number,
    maxCallScore: Number,
    displayHandleTimeOrTalkTime: Number,
    nonApplicableCat: Number,
    includeAllAgents: Number,
    mediaType: String,
    categories: Array,
    cancel: Boolean
  },
  computed: {
    invertIncludeAllAgentsChoice() {
      return (this.includeAllAgentsChoice) ? 0 : 1;
    },
    canExpand() {
      let expand = true;
      if (this.categories.length > 0) {
        this.categories.forEach((category, index) => {
          category.sub_categories.forEach((sub_category, index) => {
            if (sub_category.expanded) {
              expand = false;
              return;
            }
          });
          if (category.expanded) {
            expand = false;
            return;
          }
        });
      }
      return expand;
    },
    canCollapse() {
      let collapse = false;
      if (this.categories.length > 0) {
        this.categories.forEach((category, index) => {
          category.sub_categories.forEach((sub_category, index) => {
            if (sub_category.expanded) {
              collapse = true;
              return;
            }
          });
          if (category.expanded) {
            collapse = true;
            return;
          }
        });
      }
      return collapse;
    },
    tablesLoading() {
      return (
        this.accessRolesListLoadingStatus == "loading" ||
        this.scorecardsDefinitionsEditorListItemLoadingStatus == "loading" ||
        this.dynaLabelsListLoadingStatus == "loading" ||
        this.scorecardDefinitionsListLoadingStatus == "loading"
      );
    },
    ...mapGetters("scorecardDefinitions", [
      "scorecardDefinitionsList",
      "scorecardDefinitionsListLoadingStatus",
      "scorecardDefinitionsListLoadingError",
      "scorecardDefinitionsListLoadingErrorStatus",
      "scorecardsDefinitionsEditorListItem",
      "scorecardsDefinitionsEditorListItemLoadingStatus",
      "scorecardsDefinitionsEditorListItemLoadingError",
      "scorecardsDefinitionsEditorListItemLoadingErrorStatus"
    ]),
    ...mapGetters("accessRoles", [
      "accessRolesList",
      "accessRolesListLoadingStatus"
    ]),
    ...mapGetters("dynaLabels", [
      "dynaLabelsList",
      "dynaLabelsListLoadingStatus",
      "dynaLabelsListLoadingError",
      "dynaLabelsListLoadingErrorStatus",
      "dynaLabelsEditorList"
    ]),
    minTalktimeError() {
      return this.minInputError ? "input-error" : "";
    },
    maxTalktimeError() {
      return this.maxInputError ? "input-error" : "";
    }
  },
  methods: {
    expandCategories() {
      if (this.categories.length > 0) {
        this.categories.forEach((category, index) => {
          category.sub_categories.forEach((sub_category, index) => {
            sub_category.expanded = true;
          });
          category.expanded = true;
        });
      }
    },
    collapseCategories() {
      if (this.categories.length > 0) {
        this.categories.forEach((category, index) => {
          category.sub_categories.forEach((sub_category, index) => {
            sub_category.expanded = false;
          });
          category.expanded = false;
        });
      }
    },
    checkSubCategorySearches(value) {
      this.$emit("checkSubCategorySearches", value);
    },
    setTalkOrHandle(value) {
      this.displayTalkOrHandle = value;
      this.$emit("changeTimeDisplayed", this.displayTalkOrHandle);
    },
    setOmittedCategoryScoring(value) {
      this.nonApplicableCatChoice = value;
      this.$emit("setNonApplicableCatChoice", this.nonApplicableCatChoice);
    },
    setIncludeAllAgents(value) {
      this.includeAllAgentsChoice = value;
      this.$emit("setIncludeAllAgentsChoice", this.includeAllAgentsChoice);
    },
    updateSubCategory() {
      this.$emit("updateSubCategory");
    },
    setPublish(value) {
      this.$emit("allowPublish", value);
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .split(":");
    },
    loadModal() {
      this.showModal = true;
    },
    hideModal(hideModal) {
      this.showModal = hideModal;
    },
    rowSelected(value) {
      this.loadModal();
      this.accessRoleId = value.id;
    },
    minTalkTimeHandler(event) {
      if (event.displayTime == "") {
        this.minTalkLength = null;
      } else {
        this.minTalkLength = this.convertTimeToSeconds(event.displayTime);
      }

      this.minInputError =
        this.minTalkLength >= this.maxTalkLength &&
        (this.maxTalkLength != null && this.minTalkLength != null)
          ? true
          : false;
      if (!this.minInputError) {
        this.maxInputError = false;
      }
      this.$emit("updateMinTalkTimeLength", this.minTalkLength);
    },
    maxTalkTimeHandler(event) {
      if (event.displayTime == "") {
        this.maxTalkLength = null;
      } else {
        this.maxTalkLength = this.convertTimeToSeconds(event.displayTime);
      }

      this.maxInputError =
        this.minTalkLength >= this.maxTalkLength &&
        (this.maxTalkLength != null && this.minTalkLength != null)
          ? true
          : false;
      if (!this.maxInputError) {
        this.minInputError = false;
      }
      this.$emit("updateMaxTalkTimeLength", this.maxTalkLength);
    },

    minCallScoreHandler(value) {
      if (value == null || !value) {
        this.$emit("updateMinCallScore", null);
      } else {
        this.$emit("updateMinCallScore", Number(value).toFixed(2));
      }
      this.minCallScoreError =
        Number(this.callMinValue) >= Number(this.callMaxValue) &&
        (this.callMaxValue != null &&
          this.callMaxValue != null &&
          this.callMinValue &&
          this.callMaxValue)
          ? true
          : false;
      if (!this.minCallScoreError) {
        this.maxCallScoreError = false;
      }
    },
    maxCallScoreHandler(value) {
      if (value == null || !value) {
        this.$emit("updateMaxCallScore", null);
      } else {
        this.$emit("updateMaxCallScore", Number(value).toFixed(2));
      }
      this.maxCallScoreError =
        Number(this.callMaxValue) <= Number(this.callMinValue) &&
        (this.callMinValue != null &&
          this.callMaxValue != null &&
          this.callMinValue &&
          this.callMaxValue)
          ? true
          : false;
      if (!this.maxCallScoreError) {
        this.minCallScoreError = false;
      }
    },
    convertTimeToSeconds(time) {
      return moment(time.replace(/[Hms]/g, 0), "HH:mm:ss").diff(
        moment().startOf("day"),
        "seconds"
      );
    },
    selectItem(value) {
      this.$emit("updateInfo");
    },
    selectAll() {
      this.$emit("updateInfo");
    },
    updateDynaLabels() {
      this.$emit("updateDynaLabelSelections", this.dynaLabelsSelected);
    },
    updateAccessRoles() {
      this.$emit("updateAccessRoleSelections", this.accessRolesSelected);
    },
    resetInfo() {
      this.refreshLists();
      this.cancelUpdates();
    },
    cancelUpdates() {
      this.$emit("updatesCancelled", false);
    },
    setInfo() {
      this.$emit("setInfo");
    },
    refreshLists() {
      this.accessRolesSelected = [];
      this.dynaLabelsSelected = [];
      this.callMinValue = null;
      this.callMaxValue = null;
      this.loadSelections();
    },
    getTalkTimes() {
      let min = this.formatHMS(this.minTalkTime);

      let defaultDisplay = {
        HH: "",
        mm: "",
        ss: ""
      };
      if (this.minTalkTime == null) {
        this.minTime = defaultDisplay;
      } else {
        this.minTime = {
          HH: min[0],
          mm: min[1],
          ss: min[2]
        };
      }

      let max = this.formatHMS(this.maxTalkTime);
      if (this.maxTalkTime == null) {
        this.maxTime = defaultDisplay;
      } else {
        this.maxTime = {
          HH: max[0],
          mm: max[1],
          ss: max[2]
        };
      }
    },
    getSortedDynamicLabels(options, selections) {
      let selected = options
        .filter(d => selections.some(s => d.id == s.dynalabel_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      let unselected = options
        .filter(d => !selections.some(s => d.id == s.dynalabel_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );

      return Array(...selected, ...unselected);
    },
    getSortedAccessRoles(options, selections) {
      let selected = options
        .filter(d => selections.some(s => d.id == s.accessrole_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      let unselected = options
        .filter(d => !selections.some(s => d.id == s.accessrole_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      return Array(...selected, ...unselected);
    },
    loadAccessRoleSelections() {
      let accessroleOptions = [];
      this.myAccessRoles = this.selectedAccessRoles;
      accessroleOptions = this.accessRolesList.map(obj => {
        return {
          id: obj.id,
          new: true,
          title: obj.title + " (" + obj.users.length + " users) ",
          description: obj.description
        };
      });

      //need a way to send the database id back as well - so we can update the record there
      accessroleOptions.forEach((option, index) => {
        this.myAccessRoles.forEach((selected, index) => {
          if (selected.accessrole_id == option.id && selected.id) {
            option.new = false;
            option.db_id = selected.id;
          }
        });
      });

      this.selectableAccessRoles = this.getSortedAccessRoles(
        accessroleOptions,
        this.selectedAccessRoles
      );

      this.accessRolesSelected = this.selectableAccessRoles.filter(d =>
        this.myAccessRoles.some(s => d.id == s.accessrole_id)
      );
    },
    loadDynaLabelSelections() {
      let dynalabelOptions = []
      this.myDynaLabels = this.selectedDynaLabels

      dynalabelOptions = this.dynaLabelsEditorList.map(obj => {
        return {
          id: obj.id,
          new: true,
          title: obj.title + " (" + obj.id + ")",
          description: obj.description,
          media_type: obj.media_type
        }
      })
      
      //filter on media type
      dynalabelOptions = dynalabelOptions.filter(d => 
        d.media_type == this.mediaType
      )

      //need a way to send the database id back as well - so we can update the record there
      dynalabelOptions.forEach((option, index) => {
        this.myDynaLabels.forEach((selected, index) => {
          if (selected.dynalabel_id == option.id && selected.id) {
            option.new = false;
            option.db_id = selected.id;
          }
        })
      })

      this.selectableDynaLabels = this.getSortedDynamicLabels(
        dynalabelOptions,
        this.selectedDynaLabels
      )
      this.dynaLabelsSelected = this.selectableDynaLabels.filter(d =>
        this.myDynaLabels.some(s => d.id == s.dynalabel_id)
      )
    },
    getCallScores() {
      if (this.minCallScore != null) {
        this.callMinValue = this.minCallScore;
      }
      if (this.maxCallScore != null) {
        this.callMaxValue = this.maxCallScore;
      }
    },
    setHoldTimeDisplay(value) {
      this.$emit("changeTimeDisplayed", value);
    },
    loadSelections() {
      this.loadAccessRoleSelections();
      this.loadDynaLabelSelections();
      this.getTalkTimes();
      this.getCallScores();
      this.displayTalkOrHandle = this.displayHandleTimeOrTalkTime;
      this.nonApplicableCatChoice = this.nonApplicableCat;
      this.includeAllAgentsChoice = this.includeAllAgents;
    },
    setErrorFlags() {
      this.minInputError =
        this.maxTalkTime <= this.minTalkTime &&
        (this.minTalkTime != null && this.maxTalkTime != null)
          ? true
          : false;

      this.maxInputError =
        this.minTalkTime >= this.maxTalkTime &&
        (this.minTalkTime != null && this.maxTalkTime != null)
          ? true
          : false;

      this.maxCallScoreError =
        Number(this.minCallScore) >= Number(this.maxCallScore) &&
        (this.minCallScore != null &&
          this.maxCallScore != null &&
          this.minCallScore &&
          this.maxCallScore)
          ? true
          : false;

      this.minCallScoreError =
        Number(this.maxCallScore) <= Number(this.minCallScore) &&
        (this.minCallScore != null &&
          this.maxCallScore != null &&
          this.minCallScore &&
          this.maxCallScore)
          ? true
          : false;
    }
  },
  updated() {},
  mounted() {
    this.setErrorFlags();
    this.loadSelections();
  },
  watch: {
    cancel: "resetInfo",
    dynaLabelsSelected: "updateDynaLabels",
    accessRolesSelected: "updateAccessRoles",
    scorecardDefinitionId: "loadSelections",
    dynaLabelsListLoadingStatus: "loadSelections",
    accessRolesListLoadingStatus: "loadSelections",
    mediaType: "loadDynaLabelSelections"
  }
};
</script>

<style scoped>
.vue__time-picker {
  border: 1px solid rgb(220, 222, 224);
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.my-checkbox .v-label {
  font-size: 10px;
}

fieldset {
  -moz-border-radius: 0.2rem;
  border-radius: 0.2rem;
  -webkit-border-radius: 0.2rem;
  border: 1px solid #c2c2c2;
  padding: 0.4rem;
}

legend {
  margin-left: 0.4rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  font-size: 0.8rem;
  color: #bababa;
}

.select {
  max-width: 5rem;
  max-height: 1.3rem;
  margin-bottom: 1rem;
  margin-top: 0rem;
}
.settings {
  font-size: 12px;
  color: rgb(0, 0, 0);
}

.fieldset-container {
  position: relative;
}

.floating-button {
  position: absolute;
  right: 1.5rem !important;
  top: -1.5rem !important;
}

.input-error {
  border-color: red;
}

.input-invalid {
  color: red;
  font-size: 9px;
}

.call-score-invalid.v-text-field--outlined >>> fieldset {
  border-color: red;
}
</style>
