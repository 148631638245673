<template>
  <div class="subjective-glyph-container">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-if="info!=-1"  v-bind="attrs" v-on="on" class="subjective-glyph">
          <div class="subjective-valence"><div :class="getClass(info, 'first', type)">{{ getSymbol(info, "first") }}</div></div>
          <div class="subjective-valence"><div :class="getClass(info, 'second', type)">{{ getSymbol(info, "second") }}</div></div>
        </div>
      </template>
      <span>{{getPhrase(info)}}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    props: {
      info: {
        type: Number,
        required: false
      },
      scale: {
        type: Number,
        required: true
      },
      type: {
        type: String,
        required: true
      }
    },
    data: () => ({
    }),
    computed: {
      bottomPadding() {
        // 1.2 rem is normal - larger scale requires smaller 'rem' bottom padding
        return String((.5 / (this.scale/100))) + "rem"
      }
    },
    methods: {
      getClass(val, portion, type) {
        if (portion == 'first') {
          
          switch(val) {
            case 0:
            case 1:
            case 2:
              return type + '-negative';
            case 3:
            case 4:
            case 5:
              return type + '-neutral';
            case 6:
            case 7:
            case 8:
              return type + '-positive';
          }
        } else {
          switch(val) {
            case 0:
            case 3:
            case 6:
              return type + '-negative';
            case 1:
            case 4:
            case 7:
              return type + '-neutral';
            case 2:
            case 5:
            case 8:
              return type + '-positive';
          }
        }
      },
      getSymbol(val, portion) {
        if (portion == "first") {
           switch(val) {
            case 0:
            case 1:
            case 2:
              return '-';
            case 3:
            case 4:
            case 5:
              return '•';
            case 6:
            case 7:
            case 8:
              return '+';
          }
        } else {
          switch(val) {
            case 0:
            case 3:
            case 6:
              return '-';
            case 1:
            case 4:
            case 7:
              return '•';
            case 2:
            case 5:
            case 8:
              return '+';
          }
        }
      },
      getPhrase(info) {
        let phrase = ''
        if (info==0) {
          phrase = "Call started negative and ended negative"
        } else if (info==1) {
          phrase = "Call started negative and ended neutral"
        }
        else if (info==2) {
          phrase = "Call started negative and ended positive"
        }
        else if (info==3) {
          phrase = "Call started neutral and ended negative"
        }
        else if (info==4) {
          phrase = "Call started neutral and ended neutral"
        }
        else if (info==5) {
          phrase = "Call started neutral and ended positive"
        }
        else if (info==6) {
          phrase = "Call started positive and ended negative"
        }
        else if (info==7) {
          phrase = "Call started positive and ended neutral"
        }
        else if (info==8) {
          phrase = "Call started positive and ended positive"
        }
        return phrase
      },
    }
  }
</script>

<style scoped>

  .subjective-glyph-container { min-height: 50px; display: table; width: 100%; height: 100%; margin-top: 1rem; }
  .subjective-glyph { margin-top: .75rem; min-width: 5rem;}
  .subjective-valence { aspect-ratio: 1/1; display: inline-block; min-height: 1.3rem; text-align: center; }
  .subjective-valence div { font-size: 1rem; font-weight: bold; color: rgba(255,255,255,1); vertical-align: text-bottom; border-radius: 3px; min-width:25px; }
  .emotion-positive { background-color: rgb(183, 189, 77); }
  .emotion-neutral { background-color: rgba(194, 194, 194, 1); }
  .emotion-negative { background-color: rgba(205, 95, 9, 1); }
  .sentiment-positive { background-color: rgba(140, 199, 63, 1); }
  .sentiment-neutral { background-color: rgba(184, 184, 184, 1); }
  .sentiment-negative { background-color: rgba(179, 63, 38, 1); }

</style>