var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adHocContainer"},[_c('v-row',[_c('div',{staticClass:"search-history"},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.searchQueries.length==0,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.searchQueriesSorted),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.populateSearchField(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('div',{staticClass:"search-field px-3"},[_c('v-row',[_c('v-text-field',{ref:"searchQuery",staticClass:"d-flex",attrs:{"solo":"","placeholder":"Search Transcripts","clearable":"","dense":"","counter":"255","rules":_vm.searchFieldRule,"hide-details":!_vm.searchIsTooLong},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.performSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',{staticClass:"mt-1"},[_c('div',{staticClass:"search-help"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[(_vm.isExpanded)?_c('span',[_c('v-row',[_c('v-col',{staticClass:"pt-0 px-0",attrs:{"cols":"auto","align":"center"}},[_c('v-tooltip',{attrs:{"open-on-hover":false,"open-on-click":"","bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":on.click}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information-outline")])],1)]}}],null,false,3806781919)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.tipMessage)}})])],1),_c('v-col',{staticClass:"pt-0"},[_c('span',{staticClass:"hintText",domProps:{"innerHTML":_vm._s(_vm.hints)}})])],1)],1):_c('span',{staticStyle:{"font-size":".75rem"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\tUse quotes to search for phrases, i.e. \"Thank you for calling\"\n\t\t\t\t\t\t\t\t\t")])]),_c('v-col',{staticClass:"pt-0 d-flex pr-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"pr-0",attrs:{"text":"","x-small":"","color":"primary"},on:{"click":_vm.toggleHints}},[(_vm.isExpanded)?_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\tless search help\n\t\t\t\t\t\t\t\t\t\t\t"),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-up")])],1):_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\tmore search help\n\t\t\t\t\t\t\t\t\t\t\t"),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-down")])],1)])],1)],1)],1)],1)],1)])],1),_c('div',{staticClass:"search-activator"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.searchIconColor,"disabled":_vm.searchIsEmpty||_vm.searchIsTooLong},on:{"click":function($event){return _vm.performSearch()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.searchIcon))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.searchIconText))])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is2Channel),expression:"is2Channel"}],staticClass:"search-channels mt-2 pb-0"},[_c('v-btn',{attrs:{"depressed":"","text":"","small":""},on:{"click":function($event){_vm.showSearchChannels=!_vm.showSearchChannels}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v("\n            "+_vm._s(_vm.searchButtonText)+"\n          ")],1),_c('div',{staticClass:"searchChannelDiv"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearchChannels),expression:"showSearchChannels"}],staticClass:"searchChannels",on:{"mouseleave":function($event){_vm.showSearchChannels=false}}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.switchChannel('agent')}}},[_vm._v("Search By Agent")]),_c('br'),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.switchChannel('customer')}}},[_vm._v("Search By Customer")]),_c('br'),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.switchChannel('both')}}},[_vm._v("Search Both")])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }