<template>
    <v-content>
      <v-container fill-height>
          <v-row justify="center">
            <v-container fill-height>
              <v-row justify="center">
                <div class="display-4 font-weight-black">
                  409
                </div>
              </v-row>
              <v-row justify="center">
                <div class="display-2">
                  Conflict Error
                </div>
              </v-row>
            </v-container>
          </v-row>
          <v-row justify="center">
            <div class="title text-center">
              Sorry, your definition was not saved due to recent updates we've made to our definitions.<br/> 
              Check back in a little while or contact <a href="mailto:customerservice@mycallfinder.com">customerservice@mycallfinder.com</a> if the problem persists.<br/>
              <small><i>Sometimes a hard refresh will resolve the problem. To perform a hard refresh, hold the Ctrl key (for a PC) or Command key (for a Mac) while clicking the refresh button on the browser once or twice.</i></small>
            </div>
          </v-row>
          <v-row justify="center">
            <div class="font-weight-light">
              <a href="/">Go Back to the Homepage</a>
            </div>
          </v-row>
      </v-container>
    </v-content>
</template>

<script>
export default {};
</script>
