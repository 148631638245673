<template>
  <v-card outlined class="pl-2 pt-2 pb-0">
    <v-card-text class="pa-0">
      <label>{{ translate(display) }}</label>
    <vue-timepicker
      v-model="selected"
      @change="changeHandler"
      format="HH:mm:ss"
    ></vue-timepicker>
    </v-card-text>
  </v-card>
</template>

<script>
import VueTimepicker from "vue2-timepicker";

export default {
  name: "Duration",
  components: { VueTimepicker },
  data() {
    return {
      selected: this.storedTime()
    };
  },
  props: {
    filter: {
      type: String,
      default: "value"
    },
    feature: {
      type: String,
      default: "value"
    },
    display: {
      type: String,
      default: "display"
    },
    supplementalText: {
      type: String,
      default: "supplementalText"
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  methods: {
    translate(d) {
      let r = d
      switch (d) {
        case 'Call Length (minimum)':
          r = 'Minimum Talk Time'
          break;

        case 'Call Length (maximum)':
          r = 'Maximum Talk Time'
          break;
      }
      return r
    },
    changeHandler(eventData) {
      let minutes =
        this.calculateMinutes(eventData) || Number(this.options[0].value);
      let duration = { [this.filter]: minutes };
      const filterName =
        this.filter.charAt(0).toUpperCase() + this.filter.slice(1);

      this.$store.commit("filters/set" + filterName, duration);
      this.$root.$emit("FILTER_UPDATED");
    },

    calculateMinutes(eventData) {
      let data = eventData.data;
      let hours = parseInt(data.HH || 0) * 60;
      let minutes = parseInt(data.mm || 0);
      let seconds = parseInt(data.ss || 0) / 60;
      return hours + minutes + seconds;
    },

    storedTime() {
      // get time from store
      const storedValue = this.$store.getters['filters/'+this.filter];
      if (!storedValue) {
        return undefined;
      }

      const storedTime = storedValue[this.filter];
      //convert to hours
      const hours = storedTime / 60;
      const HH = Math.floor(hours);
      const decimalMinutes = hours - HH;
      const minutes = decimalMinutes * 60;
      const mm = Math.floor(minutes);
      const decimalSeconds = minutes - mm;
      const ss = Math.round(decimalSeconds * 60);

      const time = {
        HH: ("0" + HH.toString()).slice(-2),
        mm: ("0" + mm.toString()).slice(-2),
        ss: ("0" + ss.toString()).slice(-2)
      };
      return time;
      // convert to hours, minutes and seconds
      // return object
    }
  }
};
</script>

<style lang="scss">

  label {
    color: #35495e;
    opacity: .5;
  }

  input.display-time {
    border: none !important;
  }

</style>
