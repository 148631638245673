<template>
  <v-row>
    <v-col cols="12" class="d-flex flex-column">
      <search-container
        :dynaLabelId="dynaLabelId"
        :container="container"
        :glue="container.glue"
        :peerCount="container.parts.length"
        :idx="0"
        :isRoot="true"
        :pathToParent="pathToParent"
        :pathToMe="pathToMe"
      ></search-container>
    </v-col>
  </v-row>
</template>

<script>

  import SearchContainer from "@/components/DynaLabels/SearchContainer.vue";

  export default {
    name: 'search-root',
    data() {
      return {
      }
    },
    components: {
      SearchContainer,
    },
    props: {
      dynaLabelId: Number,
      container: Object,
      pathToParent: String,
      pathToMe: String,
    },
    methods: {
    },
    mounted() {
      // console.log(this.container)
    }
  }

</script>

<style>

</style>
