<template>
  <v-content style="height: 100vh; max-height: calc(100%)">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%)">
      <v-row>
        <v-col
          style="
            height: 100vh;
            max-height: calc(100%);
            padding: 0;
            position: relative;
            align: center;
          "
        >
          <Filters
            ref="myScorecardsFilters"
            feature="scorecards"
            @filters-loaded="filtersLoaded"
            @agents-filter-updated-reload="filterUpdated"
            @accounts-filter-updated-reload="filterUpdated"
          ></Filters>
          <scorecards-tile-grid
            v-if="
              scorecardTileList.length > 0 &&
              newScorecardsListLoadingStatus == 'loaded'
            "
            :grouping="getUnit"
            :scorecards="scorecardTileList"
            :favorite="Number(favoriteScorecardId)"
            :scorecardsLoading="scorecardsLoading"
          ></scorecards-tile-grid>
          <scorecard-loading-status
            v-else
            :message="scorecardsLoadingMessage"
            :type="scorecardsLoadingMessageType"
          ></scorecard-loading-status>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import Filters from "@/components/Filters.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ScorecardsTileGrid from "@/components/Scorecards/ScorecardsTileGrid.vue";
import ScorecardLoadingStatus from '@/components/Scorecards/ScorecardLoadingStatus.vue';
import CacheHandler from "@/utils/CacheHandler";

export default {
  components: {
    Filters,
    ScorecardsTileGrid,
    ScorecardLoadingStatus,
  },
  data() {
    return {
      scorecardTileList: [],
      err: {},
      channelCount: 0,
      scorecardsLoading: false,
      listIndex: 0,
      polling: null,
    };
  },
  computed: {
    ...mapGetters("scorecardsV2", [
      "newScorecardsList",
      "newScorecardsListLoadingErrorStatus",
      "newScorecardsListLoadingError",
      "newScorecardsListLoadingStatus",
      "favoriteScorecardId",

      "scorecardsProcessing",
      "scorecardLoadingItem",
      "doneProcessingList",
    ]),

    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingError",
      "userDataLoadingErrorStatus",
    ]),
    ...mapGetters("dateRange", [
      "start_date",
      "end_date",
      "date_range_english",
    ]),
    myRouteName() {
      return this.$route.name;
    },
    getUnit() {
      return moment(this.end_date).diff(moment(this.start_date), "days") > 1
        ? "day"
        : "hour";
    },
    getFavoriteId() {
      return this.favoriteScorecardId;
    },
    scorecardsLoadingMessage() {
      if (!localStorage.getItem("bearer")) {
        return "";
      }
      if (this.newScorecardsListLoadingErrorStatus)
        return (
          "We encountered an error while retrieving scorecards (" +
          JSON.stringify(this.newScorecardsListLoadingError) +
          ")."
        );
      else if (this.newScorecardsListLoadingStatus == "loading")
        return "Retrieving scorecards, please wait&hellip;";
      else if (
        this.newScorecardsListLoadingStatus == "loaded" &&
        this.newScorecardsList.length == 0
      )
        return "No scorecards found.";
      else if (
        this.newScorecardsListLoadingStatus == "loaded" &&
        this.newScorecardsList.length > 0
      )
        return "Successfully retrieved scorecards!";
    },
    scorecardsLoadingMessageType() {
      if (!localStorage.getItem("bearer")) {
        return null;
      }
      if (this.newScorecardsListLoadingErrorStatus) return "error";
      else if (
        this.newScorecardsListLoadingStatus == "loaded" &&
        this.newScorecardsList.length == 0
      )
        return "nodata";
      else return this.newScorecardsListLoadingStatus;
    },
  },

  methods: {
    ...mapActions({
      checkScorecardProcessingFlags:
        "scorecardsV2/checkScorecardProcessingFlags",
      retrieveScorecardsList: "scorecardsV2/retrieveScorecardsList",
      retrieveScorecard: "scorecardsV2/retrieveScorecard",
    }),
    ...mapActions("scorecardsV2", ["setFavoriteScorecardId"]),
    sortedListFavOnTop() {
      let myList = [...this.newScorecardsList].sort((a, b) => new Intl.Collator('en').compare(a.title, b.title));
      let found = myList.findIndex((e) => e.id == this.favoriteScorecardId);
      if (this.favoriteScorecardId == 0 || found < 0) {
        this.scorecardTileList = myList;
      } else {
        let first = myList[found];
        myList.splice(found, 1);
        this.scorecardTileList = [first, ...myList];
      }
    },
    filtersLoaded(f) {
      this.agentsInScope = f.find((x) => x.value == "agents")
        ? f.find((x) => x.value == "agents").options
        : [];
      this.accountsInScope = f.find((x) => x.value == "accounts")
        ? f.find((x) => x.value == "accounts").options
        : [];
    },
    goBack() {
      this.agentId = 0;
      this.$router.push("/scorecards-v2/").catch(() => {});
      setTimeout(function () {
        if (this.$vuetify) this.$vuetify.goTo(0);
      }, 1500);
    },
    filterUpdated() {
      this.loadScorecards();
    },
    loadScorecards() {
      let parameters = {
        grouping: this.getUnit,
        starts_at: moment(this.start_date).format("YYYY-MM-DD"),
        ends_at: moment(this.end_date).format("YYYY-MM-DD"),
      };
      this.retrieveScorecardsList(parameters);
    },
    listItemLoaded() {
      let indexOfObject = this.scorecardTileList.findIndex((object) => {
        return object.id == this.scorecardLoadingItem.id;
      });
      if (indexOfObject !== -1) {
        this.scorecardTileList.splice(
          indexOfObject,
          1,
          this.scorecardLoadingItem
        );
      }
    },
    loadMore() {
      let loadedCount = this.scorecardTileList.filter(
        (value) => !value.loading
      ).length;

      if (
        loadedCount >= this.listIndex &&
        this.newScorecardsListLoadingStatus == "loaded" &&
        this.scorecardTileList.length > 0 &&
        this.scorecardTileList.some((element) => element.loading)
      ) {
        this.loadScorecardTiles();
      }
    },
    updateTileListWhenDoneProcessing() {
      this.doneProcessingList.forEach((item) => {
        let indexOfObject = this.scorecardTileList.findIndex((object) => {
          return object.id == item.id;
        });
        if (indexOfObject !== -1) {
          this.scorecardTileList.splice(indexOfObject, 1, item);
        }
      });
    },
    loadScorecardTiles() {
      let localList = this.scorecardTileList;
      for (let i = this.listIndex; i < this.listIndex + 3; i++) {
        if (localList[i] != undefined) {
          this.loadTile(localList[i]);
        }
      }
      this.listIndex += 3;
    },
    loadTile(chunk) {
      let accountsFilterObject = this.$store.getters["filters/accounts"] || {
        accounts: [],
      };
      let agentFilterObject = this.$store.getters["filters/agents"] || {
        agents: [],
      };
      let parameters = {
        grouping: this.getUnit,
        starts_at: moment(this.start_date).format("YYYY-MM-DD"),
        ends_at: moment(this.end_date).format("YYYY-MM-DD"),
        agent_ids: agentFilterObject.agents,
        account_ids: accountsFilterObject.accounts,
        speaker: this.$route.query.speaker,
        phrase: this.$route.query.phrase,
        scorecard: chunk,
      };
      this.retrieveScorecard(parameters);
    },
    initCheckScorecardProcessing() {
      this.polling = setInterval(() => {
        if (this.scorecardsProcessing.length < 1) {
          clearInterval(this.polling);
        } else {
          let parameters = {
            grouping: this.getUnit,
            start_date: moment(this.start_date).format("YYYY-MM-DD"),
            end_date: moment(this.end_date).format("YYYY-MM-DD"),
          };
          this.scorecardsProcessing.forEach((item) => {
            parameters.id = item.id
            //this function is polled - runs every 15 seconds checking to see if remaining scorecards are finished processing
            this.checkScorecardProcessingFlags(parameters);
          });
        }
      }, 15000);
    },
  },
  beforeDestroy() {
    if (this.polling !== null) clearInterval(this.polling);
  },
  mounted() {
    if (this.userDataLoadingStatus == "loaded") {
      this.loadScorecards();
      this.setFavoriteScorecardId(
        Number(CacheHandler.getItem("favorite_scorecard") || 0)
      );
      this.sortedListFavOnTop();
    }
    this.$root.$on("DATE_RANGE_UPDATED", () => {
      this.listIndex = 0;
      this.scorecardTileList = [];
      this.$router
        .push({
          query: Object.assign(
            {},
            this.$route.query,
            {
              start_date: String(
                moment(this.$store.getters["dateRange/start_date"]).format(
                  "YYYY-MM-DD"
                )
              ),
            },
            {
              end_date: String(
                moment(this.$store.getters["dateRange/end_date"]).format(
                  "YYYY-MM-DD"
                )
              ),
            }
          ),
        })
        .catch((err) => {
          this.err = err;
        });

      if (
        this.userDataLoadingStatus == "loaded" &&
        this.myRouteName == "scorecards-v2"
      ) {
        this.loadScorecards();
      }
    });

  },
  watch: {
    doneProcessingList: function () {
      if (this.doneProcessingList.length > 0) {
        this.updateTileListWhenDoneProcessing();
      }
    },
    userDataLoadingStatus: function (status) {
      if (status == "loaded") {
        this.loadScorecards();
        this.setFavoriteScorecardId(
          Number(CacheHandler.getItem("favorite_scorecard") || 0)
        );
        this.sortedListFavOnTop();
      }
    },
    scorecardLoadingItem: "listItemLoaded",
    newScorecardsListLoadingStatus: function (status) {
      if (status == "loaded") {
        //once the no-data scorecard call is loaded, check scorecards to see if they are done processing
        this.initCheckScorecardProcessing();
      }
      this.sortedListFavOnTop();
    },
    scorecardTileList: "loadMore",
  },
};
</script>

<style scoped>
.progress {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5);
}
</style>
