<template>
  <div class="control-container">
    <v-row>
      <v-col cols="2" sm="4" md="2" class="pt-4">
        <h2 class="filter-type ml-4">Emotion</h2>
      </v-col>
      <v-col cols="10" sm="8" md="10" class="pt-3 pr-8"> </v-col>
    </v-row>
    <v-row pa-0>
      <v-col>
        <v-flex class="option-container">
          <div class="options">
            <template v-if="channelCount<=1 && itemList.length>0"> 
               <SubjectiveSelector
              :instance="`overallStartEmotion`"
              :selectedValue="overallStartEmotionSelection"
              :options="overallStartEmotionOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>
            <v-btn-toggle class="conditional" v-model="emotionConditional" mandatory>
              <v-btn @click="setEmotionConditionalValue(0)" small>AND</v-btn>
              <v-btn @click="setEmotionConditionalValue(1)" small>OR</v-btn>
            </v-btn-toggle>
             <SubjectiveSelector
              :instance="`overallEndEmotion`"
              :selectedValue="overallEndEmotionSelection"
              :options="overallEndEmotionOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>
            </template>

          <template v-else-if="itemList.length>0 && channelCount>1">
            <SubjectiveSelector
              :instance="`customerStartEmotion`"
              :selectedValue="customerStartEmotionSelection"
              :options="customerStartEmotionOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>

            <SubjectiveSelector
              :instance="`customerEndEmotion`"
              :selectedValue="customerEndEmotionSelection"
              :options="customerEndEmotionOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>

            <v-btn-toggle class="conditional" v-model="emotionConditional" mandatory>
              <v-btn @click="setEmotionConditionalValue(0)" small>AND</v-btn>
              <v-btn @click="setEmotionConditionalValue(1)" small>OR</v-btn>
            </v-btn-toggle>

            <SubjectiveSelector
              :instance="`agentStartEmotion`"
              :selectedValue="agentStartEmotionSelection"
              :options="agentStartEmotionOptions"
              @update-selections="toggleSelected"
              :channels="channels"
            ></SubjectiveSelector>

            <SubjectiveSelector
              :instance="`agentEndEmotion`"
              :selectedValue="agentEndEmotionSelection"
              :options="agentEndEmotionOptions"
              @update-selections="toggleSelected"
              :channels="channels"
            ></SubjectiveSelector>
          </template>
          </div>
        </v-flex>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" sm="4" md="2" class="pt-4">
        <h2 class="filter-type ml-4">Sentiment</h2>
      </v-col>
      <v-col cols="10" sm="8" md="10" class="pt-3 pr-8"> </v-col>
    </v-row>
    <v-row pa-0>
      <v-col>
        <v-flex class="option-container">
          <div class="options">
            <template v-if="channelCount<=1 && itemList.length>0"> 
               <SubjectiveSelector
              :instance="`overallStartSentiment`"
              :selectedValue="overallStartSentimentSelection"
              :options="overallStartSentimentOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>
            <v-btn-toggle class="conditional" v-model="sentimentConditional" mandatory>
              <v-btn @click="setSentimentConditionalValue(0)" small>AND</v-btn>
              <v-btn @click="setSentimentConditionalValue(1)" small>OR</v-btn>
            </v-btn-toggle>
             <SubjectiveSelector
              :instance="`overallEndSentiment`"
              :selectedValue="overallEndSentimentSelection"
              :options="overallEndSentimentOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>
            </template>
          <template v-else-if="itemList.length>0 && channelCount>1">
            <SubjectiveSelector
              :instance="`customerStartSentiment`"
              :selectedValue="customerStartSentimentSelection"
              :options="customerStartSentimentOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>

            <SubjectiveSelector
              :instance="`customerEndSentiment`"
              :selectedValue="customerEndSentimentSelection"
              :options="customerEndSentimentOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>

            <v-btn-toggle class="conditional" v-model="sentimentConditional" mandatory>
              <v-btn @click="setSentimentConditionalValue(0)" small>AND</v-btn>
              <v-btn @click="setSentimentConditionalValue(1)" small>OR</v-btn>
            </v-btn-toggle>

            <SubjectiveSelector
              :instance="`agentStartSentiment`"
              :selectedValue="agentStartSentimentSelection"
              :options="agentStartSentimentOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>

            <SubjectiveSelector
              :instance="`agentEndSentiment`"
              :selectedValue="agentEndSentimentSelection"
              :options="agentEndSentimentOptions"
              :channels="channels"
              @update-selections="toggleSelected"
            ></SubjectiveSelector>
          </template>
          </div>
        </v-flex>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn text small color="primary" @click="unselectAll">Clear All</v-btn>
        <v-btn
          small
          color="primary"
          class="mr-4 elevation-0"
          @click="applyAndReload"
          >Apply & Reload</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <span class="warning-text"
        >*All selection lists are multi-selection</span
      >
    </v-row>
  </div>
</template>

<script>

import SubjectiveSelector from "@/components/Filters/SubjectiveSelector.vue";
import {mapGetters} from "vuex"

export default {
  name: "SubjectiveDropdown",
  components: {
    SubjectiveSelector,
  },
  props: {
    channelCount: {
      type: Number,
      default: 0
    },
    filter: {
      type: String,
      default: "value"
    },
    feature: {
      type: String,
      default: "value"
    },
    display: {
      type: String,
      default: "display"
    },
    supplementalText: {
      type: String,
      default: "supplementalText"
    },
    title: {
      type: String,
      default: "supplementalText"
    },
    value: {
      type: String,
      default: "Neutral"
    },
    values: {
      type: Array,
      default: []
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      selected: {},
      itemList: [], // <<< this is the thing that contains the filter options as well as the "local" state of the filter
      emotionConditional: 0,
      sentimentConditional: 0,
    };
  },
  computed: {
    ...mapGetters('filters',
        [ 
          'subjectiveSet',
        ]),
    subjectiveValues() {
      return this.values.slice().reverse()
    },
    overallStartEmotionOptions() {
      return this.itemList.find( (item) => item.display == 'overallStartEmotion')['values']
    },
    overallEndEmotionOptions() {
      return this.itemList.find( (item) => item.display == 'overallEndEmotion')['values']
    },
    overallStartSentimentOptions() {
      return this.itemList.find( (item) => item.display == 'overallStartSentiment')['values']
    },
    overallEndSentimentOptions() {
      return this.itemList.find( (item) => item.display == 'overallEndSentiment')['values']
    },
    emotionConditionalSelected() {
      return this.itemList.find( (item) => item.display == 'emotionConditional')['values'][0]
    },
    customerStartEmotionOptions() {
      return this.itemList.find( (item) => item.display == 'customerStartEmotion')['values']
    },
    customerEndEmotionOptions() {
      return this.itemList.find( (item) => item.display == 'customerEndEmotion')['values']
    },
    agentStartEmotionOptions() {
      return this.itemList.find( (item) => item.display == 'agentStartEmotion')['values']
    },
    agentEndEmotionOptions() {
      return this.itemList.find( (item) => item.display == 'agentEndEmotion')['values']
    },
    customerStartEmotionSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['customerStartEmotion']): this.itemList.find( (item) => item.display == 'customerStartEmotion')['selected']
    },
    customerEndEmotionSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['customerEndEmotion']): this.itemList.find( (item) => item.display == 'customerEndEmotion')['selected']
    },
    agentStartEmotionSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['agentStartEmotion']): this.itemList.find( (item) => item.display == 'agentStartEmotion')['selected']
    },
    overallStartSentimentSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['overallStartSentiment']): this.itemList.find( (item) => item.display == 'overallStartSentiment')['selected']
    },
    overallEndSentimentSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['overallEndSentiment']): this.itemList.find( (item) => item.display == 'overallEndSentiment')['selected']
    },
    overallStartEmotionSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['overallStartEmotion']): this.itemList.find( (item) => item.display == 'overallStartEmotion')['selected']
    },
    overallEndEmotionSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['overallEndEmotion']): this.itemList.find( (item) => item.display == 'overallEndEmotion')['selected']
    },
    agentEndEmotionSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['agentEndEmotion']): this.itemList.find( (item) => item.display == 'agentEndEmotion')['selected']
    },
    sentimentConditionalSelected() {
      return this.itemList.find( (item) => item.display == 'sentimentConditional')['values'][0]
    },
    customerStartSentimentOptions() {
      return this.itemList.find( (item) => item.display == 'customerStartSentiment')['values']
    },
    customerEndSentimentOptions() {
      return this.itemList.find( (item) => item.display == 'customerEndSentiment')['values']
    },
    agentStartSentimentOptions() {
      return this.itemList.find( (item) => item.display == 'agentStartSentiment')['values']
    },
    agentEndSentimentOptions() {
      return this.itemList.find( (item) => item.display == 'agentEndSentiment')['values']
    },
    customerStartSentimentSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['customerStartSentiment']): this.itemList.find( (item) => item.display == 'customerStartSentiment')['selected']
    },
    customerEndSentimentSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['customerEndSentiment']): this.itemList.find( (item) => item.display == 'customerEndSentiment')['selected']
    },
    agentStartSentimentSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['agentStartSentiment']): this.itemList.find( (item) => item.display == 'agentStartSentiment')['selected']
    },
    agentEndSentimentSelection() {
      return (this.subjectiveSet != null) ? String(this.subjectiveSet['subjectiveSet'][0]['agentEndSentiment']): this.itemList.find( (item) => item.display == 'agentEndSentiment')['selected']
    },
    channels() {
      return this.channelCount==1?"single":""
    }
  },
  methods: {
    setEmotionConditionalValue(value) {
        this.itemList.forEach((item, index, array) => {
        if (this.itemList[index]['display'] == 'emotionConditional'){
          this.selected[this.itemList[index]['display']] = value
        }
      })
      this.emotionConditional = value
    },
    setSentimentConditionalValue(value) {
      this.itemList.forEach((item, index, array) => {
        if (this.itemList[index]['display'] == 'sentimentConditional'){
          this.selected[this.itemList[index]['display']] = value
        }
      })
      this.sentimentConditional = value
    },
    applyAndReload() {
      this.$emit("filter-updated-reload");
    },
    toggleSelected(item) {
      let key = Object.keys(item)[0]
      let val = item[key]
      
      let idx = this.itemList.findIndex((item => item.display == key))
      this.itemList[idx].selected = val


      this.itemList.forEach((item, index, array) => {
        if (this.itemList[index]['display'] == 'emotionConditional'){
          this.selected[this.itemList[index]['display']] = this.emotionConditional
        }else if (this.itemList[index]['display'] == 'sentimentConditional'){
          this.selected[this.itemList[index]['display']] = this.sentimentConditional
        }else { 
          this.selected[this.itemList[index]['display']] = this.itemList[index]['selected']
        }
      })
      this.selected['channels'] = this.channelCount
      this.storeValues([this.selected])

    },
    storeValues(arr) {
      let accessor =
        "filters/set" +
        this.filter.charAt(0).toUpperCase() +
        this.filter.slice(1);
      let selectedObject = { [this.filter]: arr };
      this.$store.commit(accessor, selectedObject);
    },
    unselectAll() {
      this.sentimentConditional = 0
      this.emotionConditional = 0
      this.itemList.forEach((item, index, array) => {
        array[index]['selected'] = array[index]['values'][0]
        this.selected[array[index]['display']] = array[index]['values'][0]
      })
      // call method that rebuild filter options for storage in filters.js -- was this.storeValues([this.selected]);
      let accessor =
        "filters/set" +
        this.filter.charAt(0).toUpperCase() +
        this.filter.slice(1);
        this.$store.commit(accessor, null);
    },
  },
  mounted() {
    if (this.subjectiveSet!=null) {
        this.emotionConditional = this.subjectiveSet['subjectiveSet'][0]['emotionConditional']
        this.sentimentConditional = this.subjectiveSet['subjectiveSet'][0]['sentimentConditional']
      }
    this.itemList = this.options
      .map(item => {
        let values = item.values.split(",").map(item => item.trim());
        return {
          display: item.display,
          values: values,
          selected: values[0]
        };
      })
      .sort((a, b) => (a.display > b.display ? 1 : -1));
  }
};
</script>

<style scoped>
.control-container {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
  background-color: rgba(255, 255, 255, 1);
  min-height: 260px;
}
.filter-type {
  font-size: 1rem !important;
  line-height: 100% !important;
  color: rgba(25, 118, 210, 1);
  text-transform: capitalize;
}
.option-container {
  margin: 0 1rem 0 1rem;
  border: 1px dotted rgba(0, 0, 0, 0.2);
}
.options {
  display: flex;
  flex-direction: row;
  min-height: 100px;
  max-height: 180px;
  padding-left: 3rem;
}
.conditional {
  margin: auto;
  width: 15%;
  padding: 10px;
  padding: 50px 10px 10px 10px;
}
.warning-text {
  float: left;
  padding-left: 2rem;
  padding-bottom: 1rem;
}

</style>
