// a page to hold examples of widgets (components) we're going to use in our
pages
<template>
  <v-content style="height: 100vh; max-height: calc(100%);">
    <v-container fluid pa-0 style="height: 100vh; max-height: calc(100%);">
      <v-row>
        <v-col
          style="height: 100vh; max-height: calc(100%); padding: 0; position: relative; align: center"
        >
          <v-container
            pl-5
            pr-5
            fluid
            style="height: 100vh; max-height: calc(100%);"
          >
            <!-- <call-scorecard></call-scorecard>-->
            <!-- <v-row>
              <v-col class="pl-6">
                <h1>Widgets</h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pl-6">
                <h3>Sentiment Distribution</h3>
              </v-col>
              <v-col v-for="idx in 5" :key="idx">
                <sentiment-distribution-widget :scale="75" :info="generateSentimentDistribution()"></sentiment-distribution-widget>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pl-6">
                <h3>Ending Emotion Distribution</h3>
              </v-col>
              <v-col v-for="idx in 5" :key="idx">
                <emotion-distribution-widget :scale="100" :info="generateEmotionDistribution()"></emotion-distribution-widget>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pl-6">
                <h3>Emotion Widgets</h3>
              </v-col>
              <v-col v-for="idx in 9" :key="idx">
                <emotion-glyph :scale="100" :info="emotions[idx-1]"></emotion-glyph>
              </v-col>
            </v-row> -->
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import CallScorecard from "../components/Scorecards/CallScorecard.vue";
// import SentimentDistributionWidget from "@/components/Widgets/SentimentDistributionWidget.vue"
// import EmotionDistributionWidget from "@/components/Widgets/EmotionDistributionWidget.vue"
// import EmotionGlyph from "@/components/Widgets/EmotionGlyph.vue";

export default {
  name: "Widgets",
  components: {
    CallScorecard,
    // EmotionGlyph,

    // SentimentDistributionWidget,
    // EmotionDistributionWidget,
  },
  data() {
    return {
      stuff: "stuff"
    };
  },
  methods: {
    generateSentimentDistribution() {
      let results = this.generateRandomDistribution(1, 0.3, 5);
      let callCount = Math.floor(Math.random() * 200) + 100;
      let strongNegativeCallCount = Math.round(results[0] * callCount);
      let negativeCallCount = Math.round(results[1] * callCount);
      let neutralCallCount = Math.round(results[2] * callCount);
      let positiveCallCount = Math.round(results[3] * callCount);
      let strongPositiveCallCount = Math.round(results[4] * callCount);

      let stats = {
        callCount: callCount,
        strongNegativeCallCount: strongNegativeCallCount,
        negativeCallCount: negativeCallCount,
        neutralCallCount: neutralCallCount,
        positiveCallCount: positiveCallCount,
        strongPositiveCallCount: strongPositiveCallCount,
        sentimentStrongNegative: results[0],
        sentimentNegative: results[1],
        sentimentNeutral: results[2],
        sentimentPositive: results[3],
        sentimentStrongPositive: results[4]
      };

      return stats;
    },
    generateEmotionDistribution() {
      let callCount = Math.floor(Math.random() * 200) + 100;
      let offset = Math.floor((this.callData.length - callCount) / 2);
      let myData = this.callData.slice(offset, offset + callCount);

      let res = myData.reduce(function(obj, v) {
        obj["secondHalf" + v.customerEmotionSecondHalf] =
          (obj["secondHalf" + v.customerEmotionSecondHalf] || 0) + 1;
        obj[
          "secondHalf" +
            v.customerEmotionSecondHalf +
            "FirstHalf" +
            v.customerEmotionFirstHalf
        ] =
          (obj[
            "secondHalf" +
              v.customerEmotionSecondHalf +
              "FirstHalf" +
              v.customerEmotionFirstHalf
          ] || 0) + 1;
        return obj;
      }, {});

      let stats = {
        callCount: callCount,

        secondHalfNegativeCount: res.secondHalfNegative,
        secondHalfNeutralCount: res.secondHalfNeutral,
        secondHalfPositiveCount: res.secondHalfPositive,

        secondHalfNegativeFraction: this.mp(res.secondHalfNegative, callCount),
        secondHalfNeutralFraction: this.mp(res.secondHalfNeutral, callCount),
        secondHalfPositiveFraction: this.mp(res.secondHalfPositive, callCount),

        secondHalfNegativeFirstHalfNegativeFraction: this.mp(
          res.secondHalfNegativeFirstHalfNegative,
          res.secondHalfNegative
        ),
        secondHalfNegativeFirstHalfNeutralFraction: this.mp(
          res.secondHalfNegativeFirstHalfNeutral,
          res.secondHalfNegative
        ),
        secondHalfNegativeFirstHalfPositiveFraction: this.mp(
          res.secondHalfNegativeFirstHalfPositive,
          res.secondHalfNegative
        ),

        secondHalfNeutralFirstHalfNegativeFraction: this.mp(
          res.secondHalfNeutralFirstHalfNegative,
          res.secondHalfNeutral
        ),
        secondHalfNeutralFirstHalfNeutralFraction: this.mp(
          res.secondHalfNeutralFirstHalfNeutral,
          res.secondHalfNeutral
        ),
        secondHalfNeutralFirstHalfPositiveFraction: this.mp(
          res.secondHalfNeutralFirstHalfPositive,
          res.secondHalfNeutral
        ),

        secondHalfPositiveFirstHalfNegativeFraction: this.mp(
          res.secondHalfPositiveFirstHalfNegative,
          res.secondHalfPositive
        ),
        secondHalfPositiveFirstHalfNeutralFraction: this.mp(
          res.secondHalfPositiveFirstHalfNeutral,
          res.secondHalfPositive
        ),
        secondHalfPositiveFirstHalfPositiveFraction: this.mp(
          res.secondHalfPositiveFirstHalfPositive,
          res.secondHalfPositive
        ),

        secondHalfNegativeFirstHalfNegativeCount:
          res.secondHalfNegativeFirstHalfNegative,
        secondHalfNegativeFirstHalfNeutralCount:
          res.secondHalfNegativeFirstHalfNeutral,
        secondHalfNegativeFirstHalfPositiveCount:
          res.secondHalfNegativeFirstHalfPositive,

        secondHalfNeutralFirstHalfNegativeCount:
          res.secondHalfNeutralFirstHalfNegative,
        secondHalfNeutralFirstHalfNeutralCount:
          res.secondHalfNeutralFirstHalfNeutral,
        secondHalfNeutralFirstHalfPositiveCount:
          res.secondHalfNeutralFirstHalfPositive,

        secondHalfPositiveFirstHalfNegativeCount:
          res.secondHalfPositiveFirstHalfNegative,
        secondHalfPositiveFirstHalfNeutralCount:
          res.secondHalfPositiveFirstHalfNeutral,
        secondHalfPositiveFirstHalfPositiveCount:
          res.secondHalfPositiveFirstHalfPositive
      };

      return stats;
    },
    mp(s, t) {
      return Math.round((s / t) * 100) / 100;
    },
    generateRandomDistribution(max, segmentMax, segments) {
      var tempResults = [],
        finalResults = [],
        remaining = max;

      //create a series of random numbers and push them into an array
      for (var i = 1; i <= segments; i++) {
        var r = Math.random() * segmentMax;
        if (i === segments) {
          // the final segment is just what's left after the other randoms are added up
          r = remaining;
        }
        tempResults.push(r);
        // subtract them from the total
        remaining -= r;
        // no segment can be larger than what's remaining
        segmentMax = remaining;
      }

      //randomly shuffle the array into a new array
      while (tempResults.length > 0) {
        var index = Math.floor(Math.random() * tempResults.length);
        finalResults = finalResults.concat(tempResults.splice(index, 1));
      }

      return finalResults;
    }
  },
  mounted() {}
};
</script>

<style></style>
