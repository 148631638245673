<template>
  <v-card :outlined="outlined" :loading="insightsCallDataLoadingStatus==='loading'" :color="(insightsCallDataLoadingError) ? `info`:``" :dark="insightsCallDataLoadingErrorStatus">

    <!-- if there's an error, then just say insights are unavailable - usually 404s -->
    <v-card-text v-if="insightsCallDataLoadingError">
      <v-icon>mdi-lock-pattern</v-icon> Agent Insights are not available for this call
    </v-card-text>

    <!-- if insights have loaded, show them -->
    <v-card-text v-if="insightsCallDataLoadingStatus=='loaded'&&typeof this.insightsCallData[this.callId] != 'undefined'">
      <table class="insights">
        <tr>
          <th  v-if="channelCount==2" class="pr-2 pl-2">Customer Sentiment</th>
          <th v-if="channelCount==2" class="pr-2 pl-2">Agent Sentiment</th>
          <th class="pr-2 pl-2">Overall Sentiment</th>
          <th v-if="channelCount==2" class="pr-2 pl-2">Customer Emotion</th>
          <th v-if="channelCount==2" class="pr-2 pl-2">Agent Emotion</th>
          <th class="pr-2 pl-2">Overall Emotion</th>
          <th class="pr-2 pl-2">Silence</th>
          <th class="dim pr-2 pl-2">agent/cust</th>
          <th class="pr-2 pl-2">Overtalk</th>
          <th class="dim pr-2 pl-2">agent/cust</th>
          <th class="dim pr-2 pl-2">Speaking Time</th>
          <th class="dim pr-2 pl-2">agent/cust</th>
        </tr>
        <tr>
          <td v-if="channelCount==2"> 
            <subjective-glyph
              :info="customerSentiment"
              :scale="50"
              :type="'sentiment'"
            >
            </subjective-glyph>
          </td>
          <td v-if="channelCount==2"> 
            <subjective-glyph
              :info="agentSentiment"
              :scale="50"
              :type="'sentiment'"
            >
            </subjective-glyph>
          </td>
          <td> 
            <subjective-glyph
              :info="overalLSentiment"
              :scale="50"
              :type="'sentiment'"
            >
            </subjective-glyph>
          </td>

          <td v-if="isChat">&mdash;</td>
          <td v-else-if="channelCount==2"> 
            <subjective-glyph
              :info="customerEmotion"
              :scale="50"
              :type="'sentiment'"
            >
            </subjective-glyph>
          </td>
          <td v-if="isChat">&mdash;</td>
          <td v-else-if="channelCount==2">  
            <subjective-glyph
              :info="agentEmotion"
              :scale="50"
              :type="'sentiment'"
            >
            </subjective-glyph>
          </td>
          <td v-if="isChat">&mdash;</td>
          <td v-else> 
            <subjective-glyph
              :info="overallEmotion"
              :scale="50"
              :type="'sentiment'"
            >
            </subjective-glyph>
          </td>

          <td v-if="isChat">&mdash;</td>
          <td v-else v-html="`<span>` + getSilencePercent() + `</span>`"></td>

          <td v-if="isChat">&mdash;</td>
          <td v-else class="dim">
            <span v-if="this.insightsCallData[this.callId].channelCount>1" class="dim" :title="'Silence: Agent owns ' + Math.round(this.insightsCallData[this.callId].silence.agentPercent*100) + '% / Customer owns ' + Math.round(this.insightsCallData[this.callId].silence.custpomerPercent*100) + '%'">({{Math.round(this.insightsCallData[this.callId].silence.agentPercent*100)}}/{{Math.round(this.insightsCallData[this.callId].silence.customerPercent*100)}})
              <template v-if="(this.insightsCallData[this.callId].silence.agentPercent + this.insightsCallData[this.callId].silence.customerPercent)>1"><sup class="footnote">*</sup></template>
            </span>
            <span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
          </td>

          <td v-if="isChat">&mdash;</td>
          <td v-else v-html="`<span>` + getOvertalkPercent() + `</span>`"></td>
          <td v-if="isChat">&mdash;</td>
          <td v-else class="dim">
            <span v-if="this.insightsCallData[this.callId].channelCount>1" class="dim" :title="'Overtalk: Agent owns ' + Math.round(this.insightsCallData[this.callId].overtalk.agentPercent*100) + '% / Customer owns ' + Math.round(this.insightsCallData[this.callId].overtalk.custpomerPercent*100) + '%'">({{Math.round(this.insightsCallData[this.callId].overtalk.agentPercent*100)}}/{{Math.round(this.insightsCallData[this.callId].overtalk.customerPercent*100)}})
              <template v-if="(this.insightsCallData[this.callId].overtalk.agentPercent + this.insightsCallData[this.callId].overtalk.customerPercent)>1"><sup class="footnote">*</sup></template>
            </span>
            <span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
          </td>

          <td v-if="isChat">&mdash;</td>
          <td v-else v-html="`<span>` + getSpeakingTime() + `</span>`"></td>
          <td v-if="isChat">&mdash;</td>
          <td v-else class="dim">
            <span v-if="this.insightsCallData[this.callId].channelCount>1" class="dim" :title="'Speaking Time: Agent owns ' + Math.round(this.insightsCallData[this.callId].talkTime.agentPercent*100) + '% / Customer owns ' + Math.round(this.insightsCallData[this.callId].talkTime.custpomerPercent*100) + '%'">({{Math.round(this.insightsCallData[this.callId].talkTime.agentPercent*100)}}/{{Math.round(this.insightsCallData[this.callId].talkTime.customerPercent*100)}})
              <template v-if="(this.insightsCallData[this.callId].talkTime.agentPercent + this.insightsCallData[this.callId].talkTime.customerPercent)>1"><sup class="footnote">*</sup></template>
            </span>
            <span v-else class="dim" title="Not available from single-channel audio">&mdash;</span>
          </td>
        </tr>
      </table>
    </v-card-text>

    <!-- If insights are loading, show a message -->
    <v-card-text v-if="!insightsCallDataLoadingError&&insightsCallDataLoadingStatus==='loading'">
      <v-icon>mdi-lock-pattern</v-icon> Loading Agent Insights
    </v-card-text>

  </v-card>
</template>

<script>

  import SentimentChip from "@/components/Widgets/SentimentChip";
  import { mapGetters } from "vuex";
  import emotion from "@/mixins/emotion";

import SubjectiveGlyph from '@/components/Widgets/SubjectiveGlyph.vue';

  export default {
    mixins: [emotion],
    name: "insights-widget",
    components: {
      SentimentChip,
      SubjectiveGlyph,
    },
    props: {
      callId: {
        type: String,
        required: true
      },
      outlined: {
        type: Boolean,
        default: false
      },
      selfSignedTranscript: {
        type: Boolean,
        required: false,
        default: false,
      },
      isChat: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters("insights", [
        "insightsCallData",
        "insightsCallDataLoadingStatus",
        "insightsCallDataLoadingError",
        "insightsCallDataLoadingErrorStatus",
      ]),
      customerSentiment() {
        let call = this.insightsCallData[this.callId]
        return this.getEmotionIndex(call.customer_sentiment.sentiment_first_half, call.customer_sentiment.sentiment_second_half)
      },
      agentSentiment() {
        let call = this.insightsCallData[this.callId]
        return this.getEmotionIndex(call.agent_sentiment.sentiment_first_half, call.agent_sentiment.sentiment_second_half)
      },
      overalLSentiment() {
        let call = this.insightsCallData[this.callId]
        return this.getEmotionIndex(call.overall_sentiment.sentiment_first_half, call.overall_sentiment.sentiment_second_half)
      },
      customerEmotion() {
        let call = this.insightsCallData[this.callId]
        return this.getEmotionIndex(call.customer_emotion.emotion_first_half, call.customer_emotion.emotion_second_half)
      },
      agentEmotion() {
        let call = this.insightsCallData[this.callId]
        return this.getEmotionIndex(call.agent_emotion.emotion_first_half, call.agent_emotion.emotion_second_half)
      },
      overallEmotion() {
        let call = this.insightsCallData[this.callId]
        return this.getEmotionIndex(call.overall_emotion.emotion_first_half, call.overall_emotion.emotion_second_half)
      },
      channelCount() {
        return this.insightsCallData[this.callId].channelCount
      },
    },
    methods: {
      getSentimentFloat() {
        return parseFloat(this.insightsCallData[this.callId].sentiment.percent)
      },
      getSilencePercent() {
        let val = this.insightsCallData[this.callId].silence.totalPercent
        return (val!==null) ? this.formatPercent(val) : '&mdash;'
      },
      getOvertalkPercent() {
        let val = this.insightsCallData[this.callId].overtalk.totalPercent
        return (val!==null) ? this.formatPercent(val) : '&mdash;'
      },
      getSpeakingTime() {
        let val = this.insightsCallData[this.callId].talkTime.totalPercent
        let percent = String(Math.round(val * 100)) ;
        if (Number(percent) > 100) {
            return "100%"
        } else {
          return percent + "%"
        }

      },
      formatPercent(val, limit) {
        let percent = String(Math.round(val * 100)) ;
        if (Number(percent) > 100) {
            return "100%"
        } else {
          return percent + "%"
        }
    },
      formatHMS(seconds) {
        return this.stripZeroes(new Date(seconds * 1000).toISOString().substr(11, 8));
      },
      stripZeroes(val) {
        if(val.length>5&&val.slice(0,3)==="00:") {
          return this.stripZeroes(val.slice(3))
        }
        else if(val.length>3) {
          if(val.slice(0,2)=="00") return val.slice(2)
          else if(val.slice(0,1)=="0") return val.slice(1)
          else return val
        }
      }
    },
    mounted() {
      let url = process.env.VUE_APP_LEGACY_USERS
      let params = {selfSignedTranscript: this.selfSignedTranscript,call_id: this.callId}
      if(this.selfSignedTranscript) {
        params['userid'] = this.$route.params.userid
        params['reckey'] = this.$route.params.reckey
      }
      this.$store.dispatch("insights/retrieveInsightsCallData", params)
    },
  }
</script>

<style>

  span.label {
    display: inline-block;
    font-weight: 900;
    margin-right: 1rem;
  }

  table.insights {
    width: 100%;
  }

  table.insights th,
  table.insights td {
    text-align: center !important;
  }

  table.insights th.dim {
    opacity: .5;
  }

  table.insights td span {
    font-size: 1rem;
  }

  sup.footnote {
    vertical-align: baseline;
    position: relative;
    top: 0rem;
    font-size: 1rem;
    color: rgba(179, 63, 38, 1);
  }

</style>
