<template>
    <v-container>
        <v-layout row wrap align-center>
            <v-flex class="text-center">
                <h1 class="light-blue--text text--darken-4">{{formatHMS(team_att)}}</h1>                    
                <p class="grey--text text--darken-1">{{ timeDisplayText }}</p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import moment from "moment";
export default {
    props: {
      team_att: {
            type: Number,
            required: true
      },
      timeDisplay: {
      type: Number,
      default: 0
      },
    },
    methods: {
        formatHMS(seconds) {
            seconds = Math.round(seconds);
            return moment("1900-01-01 00:00:00")
                .add(seconds, "seconds")
                .format("HH:mm:ss")
                .replace(/^0(?:0:0?)?/, "");
        }
    },
  computed: {
    timeDisplayText() {
      return this.timeDisplay ? "Handle Time" : "Talk Time"
    }
  }
}

</script>

<style scoped>

    h1 { font-size: 2.2rem; }
    span { font-size: 1rem; }
    p { font-size: .8rem; }

</style>
