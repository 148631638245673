export const searchQuery = {
  namespaced: true,
  state: {
    query: ""
  },
  mutations: {
    setQuery(state, query) {
      state.query = query;
    }
  },
  getters: {
    query: state => {
      return state.query;
    }
  }
};
