<template>

  <svg v-if="name==='download-export'" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="24">
    <path d="M6 2C4.9 2 4 2.9 4 4L4 20C4 21.1 4.9 22 6 22L18 22C19.1 22 20 21.1 20 20L20 8L14 2L6 2ZM6 4L13 4L13 9L18 9L18 20L6 20L6 4ZM11.3125 10.6562L8.5625 13.4375L11.3125 16.1875L9.25 18.2812L16.2812 18.2812L16.2812 11.25L14.0938 13.4375L11.3125 10.6562Z"/>
  </svg>

  <svg v-else-if="name==='email-export'" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="24">
    <path d="M6 2C4.9 2 4 2.9 4 4L4 20C4 21.1 4.9 22 6 22L18 22C19.1 22 20 21.1 20 20L20 18.3125C20.6291 18.3125 21.1563 17.8166 21.1562 17.1875L21.1562 12.0938C21.1562 11.4703 20.6291 10.9687 20 10.9688L20 8L14 2L6 2ZM6 4L13 4L13 9L18 9L18 10.9688L12.6562 10.9688C12.0328 10.9688 11.5 11.4703 11.5 12.0938L11.5 17.1875C11.5 17.8166 12.0328 18.3125 12.6562 18.3125L18 18.3125L18 20L6 20L6 4ZM12.6562 12.0938L20 12.0938L16.3125 13.9688L12.6562 12.0938ZM12.6562 13.0312L16.3125 14.9375L20 13.0312L20 17.1875L12.6562 17.1875L12.6562 13.0312ZM9.25 11.8125C8.93829 11.8125 8.6875 12.0633 8.6875 12.375C8.6875 12.6867 8.93829 12.9375 9.25 12.9375L10.375 12.9375L10.375 12.0938C10.375 11.9974 10.3949 11.9088 10.4062 11.8125L9.25 11.8125ZM8.6875 14.0625C8.37579 14.0625 8.09375 14.3445 8.09375 14.6562C8.09375 14.968 8.37579 15.2187 8.6875 15.2188L10.375 15.2188L10.375 14.0625L8.6875 14.0625ZM8.09375 16.3438C7.78091 16.3437 7.53125 16.5945 7.53125 16.9062C7.53125 17.218 7.78091 17.4688 8.09375 17.4688L10.4062 17.4688C10.3949 17.3724 10.375 17.2838 10.375 17.1875L10.375 16.3438L8.09375 16.3438Z"/>
  </svg>

  <svg v-else-if="name==='schedule-export'" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="24">
    <path d="M6 2C4.9 2 4 2.9 4 4L4 20C4 21.1 4.9 22 6 22L13.8125 22L16.3125 22L18 22C19.1 22 20 21.1 20 20L20 19.4375C20.5922 18.507 21.0313 17.479 21.0312 16.2812C21.0312 15.0782 20.5971 14.0268 20 13.0938L20 8L14 2L6 2ZM6 4L13 4L13 9L18 9L18 11.1562C17.1197 10.6514 16.1614 10.2812 15.0625 10.2812C11.7243 10.3668 9.0625 13.0286 9.0625 16.2812C9.0625 17.7274 9.63287 18.9709 10.4688 20L6 20L6 4ZM15.0625 12C16.2198 12 17.2364 12.4322 18 13.1562C18.8177 13.9316 19.3125 15.0419 19.3125 16.2812C19.3125 17.5206 18.8177 18.5997 18 19.375C17.7295 19.6315 17.3949 19.8219 17.0625 20C16.4565 20.3247 15.8099 20.5313 15.0625 20.5312C14.3151 20.5312 13.6372 20.3247 13.0312 20C11.694 19.2834 10.7813 17.9305 10.7812 16.2812C10.7812 13.8846 12.6658 12 15.0625 12ZM14.1875 12.8438L14.1875 17.125L17.2812 19L17.9688 17.9688L15.4688 16.5312L15.4688 12.8438L14.1875 12.8438Z"/>
  </svg>

  <svg v-else-if="name==='schedule-export-pdf'" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="24">
    <path d="M6 2C4.9 2 4 2.9 4 4L4 20C4 21.1 4.9 22 6 22L13.8125 22L16.3125 22L18 22C19.1 22 20 21.1 20 20L20 19.4375C20.5922 18.507 21.0313 17.479 21.0312 16.2812C21.0312 15.0782 20.5971 14.0268 20 13.0938L20 8L14 2L6 2ZM6 4L13 4L13 9L18 9L18 11.1562C17.1197 10.6514 16.1614 10.2812 15.0625 10.2812C11.7243 10.3668 9.0625 13.0286 9.0625 16.2812C9.0625 17.7274 9.63287 18.9709 10.4688 20L6 20L6 4ZM15.0625 12C16.2198 12 17.2364 12.4322 18 13.1562C18.8177 13.9316 19.3125 15.0419 19.3125 16.2812C19.3125 17.5206 18.8177 18.5997 18 19.375C17.7295 19.6315 17.3949 19.8219 17.0625 20C16.4565 20.3247 15.8099 20.5313 15.0625 20.5312C14.3151 20.5312 13.6372 20.3247 13.0312 20C11.694 19.2834 10.7813 17.9305 10.7812 16.2812C10.7812 13.8846 12.6658 12 15.0625 12ZM14.1875 12.8438L14.1875 17.125L17.2812 19L17.9688 17.9688L15.4688 16.5312L15.4688 12.8438L14.1875 12.8438Z"/>
  </svg>

  <svg v-else-if="name==='phonetic-search'" fill="currentColor" width="18px" height="18px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Search Type: Phonetic</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <text id="P" font-family="Roboto" font-size="72" font-weight="700" fill="#1565C0">
          <tspan x="1.27929688" y="67">P</tspan>
      </text>
      <path d="M23.6170432,55.6458169 C27.5058622,56.8502953 38.5037966,56.1199055 45.549227,51.8283502 C52.5946574,47.536795 52.5946574,39.4725385 52.5946574,33.7881621" id="Path" stroke="#1565C0" stroke-width="6" stroke-linecap="round"></path>
      <path d="M23.6170432,64.6656302 C23.6170432,64.6656302 43.1078284,67.6543204 55.178023,57.3782607 C61.7318309,51.7986223 63.5562368,41.7152924 61.7318309,30.003745" id="Path-2" stroke="#1565C0" stroke-width="6" stroke-linecap="round"></path>
    </g>
  </svg>

  <svg v-else-if="name==='open-ai-mark'" fill="currentColor" viewBox="0 0 320 320" xmlns="http://www.w3.org/2000/svg" height="20">
    <path d="m297.06 130.97c7.26-21.79 4.76-45.66-6.85-65.48-17.46-30.4-52.56-46.04-86.84-38.68-15.25-17.18-37.16-26.95-60.13-26.81-35.04-.08-66.13 22.48-76.91 55.82-22.51 4.61-41.94 18.7-53.31 38.67-17.59 30.32-13.58 68.54 9.92 94.54-7.26 21.79-4.76 45.66 6.85 65.48 17.46 30.4 52.56 46.04 86.84 38.68 15.24 17.18 37.16 26.95 60.13 26.8 35.06.09 66.16-22.49 76.94-55.86 22.51-4.61 41.94-18.7 53.31-38.67 17.57-30.32 13.55-68.51-9.94-94.51zm-120.28 168.11c-14.03.02-27.62-4.89-38.39-13.88.49-.26 1.34-.73 1.89-1.07l63.72-36.8c3.26-1.85 5.26-5.32 5.24-9.07v-89.83l26.93 15.55c.29.14.48.42.52.74v74.39c-.04 33.08-26.83 59.9-59.91 59.97zm-128.84-55.03c-7.03-12.14-9.56-26.37-7.15-40.18.47.28 1.3.79 1.89 1.13l63.72 36.8c3.23 1.89 7.23 1.89 10.47 0l77.79-44.92v31.1c.02.32-.13.63-.38.83l-64.41 37.19c-28.69 16.52-65.33 6.7-81.92-21.95zm-16.77-139.09c7-12.16 18.05-21.46 31.21-26.29 0 .55-.03 1.52-.03 2.2v73.61c-.02 3.74 1.98 7.21 5.23 9.06l77.79 44.91-26.93 15.55c-.27.18-.61.21-.91.08l-64.42-37.22c-28.63-16.58-38.45-53.21-21.95-81.89zm221.26 51.49-77.79-44.92 26.93-15.54c.27-.18.61-.21.91-.08l64.42 37.19c28.68 16.57 38.51 53.26 21.94 81.94-7.01 12.14-18.05 21.44-31.2 26.28v-75.81c.03-3.74-1.96-7.2-5.2-9.06zm26.8-40.34c-.47-.29-1.3-.79-1.89-1.13l-63.72-36.8c-3.23-1.89-7.23-1.89-10.47 0l-77.79 44.92v-31.1c-.02-.32.13-.63.38-.83l64.41-37.16c28.69-16.55 65.37-6.7 81.91 22 6.99 12.12 9.52 26.31 7.15 40.1zm-168.51 55.43-26.94-15.55c-.29-.14-.48-.42-.52-.74v-74.39c.02-33.12 26.89-59.96 60.01-59.94 14.01 0 27.57 4.92 38.34 13.88-.49.26-1.33.73-1.89 1.07l-63.72 36.8c-3.26 1.85-5.26 5.31-5.24 9.06l-.04 89.79zm14.63-31.54 34.65-20.01 34.65 20v40.01l-34.65 20-34.65-20z"/>
  </svg>

  <svg v-else-if="name==='powered-by-open-ai'" fill="currentColor" viewBox="0 0 280 54" xmlns="http://www.w3.org/2000/svg" height="32">
    <path d="m267.14 0h-254.27c-.49 0-.98 0-1.48 0-.41 0-.82.01-1.24.02-.91.01-1.81.09-2.71.24-.89.15-1.76.44-2.57.85s-1.54.95-2.19 1.59c-.64.64-1.18 1.38-1.59 2.19s-.69 1.68-.84 2.57-.23 1.8-.24 2.7c-.01.41-.01.83-.02 1.24v31.2c0 .42 0 .83.02 1.24.01.91.09 1.81.24 2.7s.43 1.76.84 2.57.95 1.54 1.59 2.18 1.38 1.18 2.19 1.59 1.67.7 2.57.85c.89.15 1.8.23 2.71.24.42 0 .83.01 1.24.01h1.48 254.26 1.46c.41 0 .83 0 1.24-.01.9-.01 1.81-.09 2.7-.24.9-.15 1.76-.44 2.58-.85.81-.41 1.55-.95 2.18-1.59.64-.64 1.18-1.37 1.6-2.18.41-.81.69-1.68.84-2.57s.23-1.8.25-2.7c0-.42 0-.83 0-1.24.01-.49.01-.98.01-1.48v-28.25c0-.49 0-.98-.01-1.47 0-.41 0-.83 0-1.24-.02-.91-.1-1.81-.25-2.7s-.43-1.76-.84-2.57c-.83-1.62-2.15-2.95-3.78-3.78-.81-.41-1.68-.69-2.58-.85-.89-.15-1.8-.23-2.7-.24-.41 0-.83-.01-1.24-.02-.49 0-.98 0-1.46 0z"/>
    <path d="m11.62 52.46c-.41 0-.8 0-1.2-.01-.83-.01-1.67-.08-2.49-.22-.77-.13-1.51-.38-2.21-.73-.69-.35-1.32-.81-1.86-1.35-.55-.54-1.01-1.17-1.36-1.86s-.6-1.44-.72-2.21c-.14-.83-.21-1.66-.22-2.5 0-.28-.02-1.22-.02-1.22v-30.74s.01-.92.02-1.19c.01-.84.08-1.67.22-2.49.13-.77.37-1.52.72-2.21s.8-1.32 1.35-1.86c.55-.55 1.18-1.01 1.87-1.36s1.44-.59 2.2-.72c.83-.14 1.66-.21 2.5-.22l1.2-.02h256.75l1.22.02c.83 0 1.66.08 2.47.22.77.13 1.52.38 2.22.73 1.38.71 2.51 1.84 3.21 3.22.35.69.59 1.43.71 2.19.14.83.22 1.67.23 2.51v1.18c.01.5.01.97.01 1.45v27.86c0 .48 0 .96-.01 1.43v1.24c-.02.83-.09 1.65-.23 2.47-.12.77-.37 1.52-.72 2.22-.35.68-.81 1.3-1.35 1.84-.54.55-1.17 1.01-1.86 1.36-.7.35-1.45.6-2.22.73-.82.13-1.65.21-2.49.22-.39 0-.8.01-1.19.01h-1.44s-255.32 0-255.32 0z" fill="#fff"/>
    <path d="m182.74 26.38c0-5.23 3.36-8.88 8.02-8.88s8.02 3.65 8.02 8.88-3.36 8.88-8.02 8.88-8.02-3.65-8.02-8.88zm12.96 0c0-3.74-2.04-6.17-4.94-6.17s-4.94 2.42-4.94 6.17 2.04 6.17 4.94 6.17 4.94-2.42 4.94-6.17zm11.72 8.88c-1.63 0-2.83-.65-3.62-1.58v5.64h-2.88v-16.92h2.88v1.34c.79-.94 1.99-1.58 3.62-1.58 3.53 0 5.54 2.98 5.54 6.55s-2.02 6.55-5.54 6.55zm-3.7-6.91v.74c0 2.33 1.34 3.65 3.12 3.65 2.09 0 3.22-1.63 3.22-4.03s-1.13-4.03-3.22-4.03c-1.78 0-3.12 1.3-3.12 3.67zm17.03 6.91c-3.6 0-6.12-2.66-6.12-6.55s2.5-6.55 6-6.55 5.59 2.76 5.59 6.22v.96h-8.83c.22 2.16 1.51 3.48 3.36 3.48 1.42 0 2.54-.72 2.93-2.02l2.47.94c-.89 2.21-2.88 3.53-5.4 3.53zm-.14-10.68c-1.49 0-2.64.89-3.07 2.59h5.78c-.02-1.39-.89-2.59-2.71-2.59zm7.78 10.42v-12.6h2.88v1.34c.72-.84 1.85-1.58 3.48-1.58 2.64 0 4.22 1.82 4.22 4.54v8.3h-2.88v-7.46c0-1.56-.62-2.69-2.21-2.69-1.3 0-2.62.96-2.62 2.76v7.39h-2.88zm19.02-17.23h3.48l6.53 17.23h-3.1l-1.49-3.94h-7.44l-1.46 3.94h-3.05zm1.68 3.41-2.69 7.2h5.42l-2.74-7.2zm13.27-3.41v17.23h-3.07v-17.23z"/>
    <path d="m175.7 24.09c.36-1.08.48-2.22.37-3.35s-.48-2.22-1.05-3.2c-.85-1.48-2.15-2.65-3.71-3.35s-3.3-.88-4.97-.52c-.75-.85-1.68-1.53-2.72-1.99s-2.16-.7-3.3-.69c-1.71 0-3.37.53-4.76 1.54-1.38 1-2.41 2.42-2.94 4.04-1.11.23-2.16.69-3.08 1.36s-1.69 1.52-2.25 2.51c-.86 1.48-1.22 3.19-1.04 4.89s.89 3.3 2.04 4.57c-.36 1.08-.48 2.22-.37 3.35.12 1.13.48 2.22 1.05 3.2.85 1.48 2.15 2.65 3.71 3.35s3.3.88 4.97.52c.75.85 1.68 1.53 2.72 1.99s2.16.7 3.3.69c1.71 0 3.38-.53 4.76-1.54 1.38-1 2.41-2.42 2.94-4.05 1.11-.23 2.16-.69 3.08-1.36s1.68-1.52 2.25-2.51c.86-1.48 1.22-3.19 1.04-4.89s-.89-3.3-2.04-4.56zm-11.96 16.81c-1.59 0-2.83-.49-3.9-1.39.05-.03.13-.07.19-.11l6.37-3.68c.16-.09.29-.22.38-.38s.14-.34.14-.52v-8.98l2.69 1.56s.03.02.04.03c0 .01.01.03.02.04v7.44c0 3.37-2.81 6-5.93 6zm-12.95-5.5c-.7-1.21-.96-2.64-.71-4.02.05.03.13.08.19.11l6.37 3.68c.16.09.34.14.52.14s.36-.05.52-.14l7.78-4.49v3.11s0 .03 0 .05c0 .01-.02.03-.03.04l-6.44 3.72c-1.38.79-3.01 1.01-4.55.6s-2.85-1.42-3.64-2.79zm-1.68-13.91c.7-1.22 1.81-2.15 3.12-2.63v7.58c0 .18.05.36.14.52s.22.29.38.38l7.78 4.49-2.69 1.56s-.03.01-.04.02c-.02 0-.03 0-.05 0l-6.44-3.72c-1.38-.8-2.38-2.11-2.79-3.64-.41-1.54-.2-3.17.59-4.55zm22.13 5.15-7.78-4.49 2.69-1.55s.03-.01.04-.02h.05l6.44 3.72c.99.57 1.79 1.41 2.32 2.42s.75 2.15.66 3.28c-.1 1.14-.52 2.22-1.21 3.12-.69.91-1.62 1.6-2.69 1.99v-7.58c0-.18-.05-.36-.14-.52s-.22-.29-.38-.38zm2.68-4.04s-.13-.08-.19-.11l-6.37-3.68c-.16-.09-.34-.14-.52-.14s-.37.05-.52.14l-7.78 4.49v-3.11s0-.03 0-.05c0-.01.02-.03.03-.04l6.44-3.72c.99-.57 2.11-.85 3.25-.8s2.24.42 3.17 1.07 1.66 1.56 2.1 2.61.57 2.21.38 3.33zm-16.85 5.55-2.69-1.56s-.03-.02-.04-.03c0-.01-.01-.03-.02-.04v-7.44c0-1.14.33-2.25.94-3.22.61-.96 1.48-1.73 2.52-2.21 1.03-.48 2.18-.66 3.31-.52 1.13.15 2.2.61 3.07 1.34-.05.03-.13.07-.19.11l-6.37 3.68c-.16.09-.29.22-.38.38s-.14.34-.14.52v8.98zm1.46-3.15 3.46-2 3.46 2v4l-3.46 2-3.46-2z"/>
    <path d="m20.7 28.33v6.67h-3.07v-17.23h6.89c3.84 0 6.24 1.8 6.24 5.28s-2.4 5.28-6.24 5.28zm0-2.64h3.65c2.21 0 3.38-.96 3.38-2.64s-1.18-2.64-3.38-2.64h-3.65zm22.59 3.02c0 3.91-2.52 6.55-6.07 6.55s-6.07-2.64-6.07-6.55 2.52-6.55 6.07-6.55 6.07 2.64 6.07 6.55zm-9.24 0c0 2.57 1.22 4.13 3.17 4.13s3.17-1.56 3.17-4.13-1.22-4.13-3.17-4.13-3.17 1.56-3.17 4.13zm17.13-6.31h2.38l2.26 8.35 2.23-8.35h2.86l-3.79 12.6h-2.5l-2.3-8.26-2.3 8.26h-2.5l-3.79-12.6h2.95l2.28 8.35 2.23-8.35zm16.25 12.86c-3.6 0-6.12-2.66-6.12-6.55s2.5-6.55 6-6.55 5.59 2.76 5.59 6.22v.96h-8.83c.22 2.16 1.51 3.48 3.36 3.48 1.42 0 2.54-.72 2.93-2.02l2.47.94c-.89 2.21-2.88 3.53-5.4 3.53zm-.14-10.68c-1.49 0-2.64.89-3.07 2.59h5.78c-.02-1.39-.89-2.59-2.71-2.59zm14.81-2.21v2.88c-.36-.05-.65-.07-1.06-.07-1.82 0-3.22 1.18-3.22 3.19v6.62h-2.88v-12.6h2.88v1.87c.55-1.18 1.85-1.94 3.46-1.94.34 0 .6.02.82.05zm6.59 12.89c-3.6 0-6.12-2.66-6.12-6.55s2.5-6.55 6-6.55 5.59 2.76 5.59 6.22v.96h-8.83c.22 2.16 1.51 3.48 3.36 3.48 1.42 0 2.54-.72 2.93-2.02l2.47.94c-.89 2.21-2.88 3.53-5.4 3.53zm-.14-10.68c-1.49 0-2.64.89-3.07 2.59h5.78c-.02-1.39-.89-2.59-2.71-2.59zm12.41 10.68c-3.53 0-5.54-2.98-5.54-6.55s2.02-6.55 5.54-6.55c1.63 0 2.83.65 3.62 1.58v-5.98h2.88v17.23h-2.88v-1.32c-.79.94-1.99 1.58-3.62 1.58zm3.7-6.91c0-2.38-1.34-3.67-3.12-3.67-2.09 0-3.22 1.63-3.22 4.03s1.13 4.03 3.22 4.03c1.78 0 3.12-1.32 3.12-3.65zm17.39 6.91c-1.63 0-2.83-.65-3.62-1.58v1.32h-2.88v-17.23h2.88v5.98c.79-.94 1.99-1.58 3.62-1.58 3.53 0 5.54 2.98 5.54 6.55s-2.02 6.55-5.54 6.55zm-3.7-6.91v.74c0 2.33 1.34 3.65 3.12 3.65 2.09 0 3.22-1.63 3.22-4.03s-1.13-4.03-3.22-4.03c-1.78 0-3.12 1.3-3.12 3.67zm16.58 7.82c-.72 1.92-1.82 3.24-4.44 3.24-.6 0-.77-.02-1.18-.07v-2.42c.38.05.6.07.96.07.96 0 1.42-.26 1.82-1.27l.48-1.18-4.58-12.14h3.02l3.1 8.83 3.02-8.83h2.98l-5.18 13.78z"/>
  </svg>

</template>

<script>
export default {
  props: ['name']
}
</script>

<style scoped>

.cls-1 {
  fill: #000;
  stroke-width: 0px;
}

</style>
