<template>
  <v-btn-toggle mandatory v-model="toggleGroup">
    <v-btn v-for="(glueOption, index) in glueOptions" :key="index" small :value="glueOption.value">
      {{ glueOption.description }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>

  export default {
    props: {
      glue: String,
      glueOptions: Array,
    },
    data() {
      return {
        toggleGroup: ''
      }
    },
    methods: {
      updateVModel() {
        this.toggleGroup = this.glue
      }
    },
    mounted() {
      this.updateVModel()
    },
    watch: {
      glue: 'updateVModel'
    }
  }
</script>

<style>

</style>
