<template>
    <v-content>
      <v-container fill-height>
          <v-row justify="center">
            <v-container fill-height>
              <v-row justify="center">
                <div class="display-4 font-weight-black">
                  502
                </div>
              </v-row>
              <v-row justify="center">
                <div class="display-2">
                  Bad Gateway
                </div>
              </v-row>
            </v-container>
          </v-row>
          <v-row justify="center">
            <div class="title text-center">
              Oops! One or more of our services are offline or unreachable.<br/>
              We will be back up and running shortly; try again in a few minutes.
            </div>
          </v-row>
          <v-row justify="center">
            <div class="font-weight-light">
              <a href="/">Go Back to the Homepage</a>
            </div>
          </v-row>
      </v-container>
    </v-content>
</template>

<script>
export default {};
</script>
