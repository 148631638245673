<template>
  <v-col cols="1" class="d-flex justify-center pa-0 ma-0" style="padding-top: 5px !important;">
    <v-menu v-if="items.length>1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="rgba(0,0,0,.6)"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ myIcon }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          dense
          v-for="(item, i) in items"
          :key="i"
          @click="doAction(item.action)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-else
      color="rgba(0,0,0,.6)"
      icon
      @click="doAction(items[0].action)"
    >
      <v-icon>{{ myIcon }}</v-icon>
    </v-btn>


    <confirm ref="confirm"></confirm>
    <dyna-label-editor v-if="containerKind=='dynalabel'"
      ref="editor"
      :mode="mode"
      :name="name"
      :isRoot="isRoot"
      :pathArray="pathArray"
      :glue="glue"
      :kind="kind"
      :type="type"
      :selector="selector"
      :selection="selection"
    ></dyna-label-editor>
    <search-editor v-if="containerKind=='search'"
      ref="editor"
      :mode="mode"
      :name="name"
      :isRoot="isRoot"
      :pathArray="pathArray"
      :glue="glue"
      :kind="kind"
      :type="type"
      :selector="selector"
      :selection="selection"
    ></search-editor>
  </v-col>
</template>

<script>

  import {mapState, mapActions} from "vuex";
  import confirm from "@/components/Widgets/Confirm.vue"
  import DynaLabelEditor from "@/components/DynaLabels/DynaLabelEditor.vue"
  import SearchEditor from "@/components/DynaLabels/SearchEditor.vue"

  export default {
    components: {
      confirm,
      DynaLabelEditor,
      SearchEditor,
    },
    props: {
      dynaLabelId: Number,
      container: Object,
      name: String,
      items: Array,
      isRoot: Boolean,
      pathToParent: String,
      pathToMe: String,
      glue: String,
      kind: String,
      type: String,
      selector: String,
      selection: Array,
      containerKind: String,
      peerCount: Number,
  },
    computed: {
      ...mapState('dynaLabels', [
        'dynaLabelData',
        'dynaLabelDataLoadingStatus',
        'dynaLabelsSelectors',
        'dynaLabelsSelectorsLoadingStatus',
      ]),
      myIcon() {
        return (this.items.length==1 && this.items[0].action=='add') ? 'mdi-plus' : 'mdi-dots-vertical'
      }
    },
    data() {
      return {
        mode: '',
        pathArray: [],
      }
    },
    methods: {
      ...mapActions('dynaLabels', [
        'addDynaLabelNode',
        'deleteDynaLabelNode',
        'updateDynaLabelRootProp',
      ]),
      doAction(action) {

        let path = ''
        let pathArray = Array.from(this.pathArray)
        let idx = 0
        this.mode = action

        switch(action) {

          case 'add':
            this.$refs.editor.dialog = true
            break;

          case 'edit':
            this.$refs.editor.dialog = true
            break;

          case 'delete':
            idx = pathArray.pop()
            path = pathArray.join('.')
            // if this is the only remaining dynalabel in the container
            // then move the request up to delete both the container and dynalabel
            if(this.container.kind=='dynalabel' && this.peerCount == 1) {
              // move up the path by 2 and re-compute idx & path
              idx = pathArray.pop()
              idx = pathArray.pop()
              path = pathArray.join('.')
              this.deleteNode(path, idx, 'container')
            } else {
              this.deleteNode(path, idx, this.container.kind)
            }
            break;
        }
      },
      async deleteNode(path, idx, kind) {
        if (await this.$refs.confirm.open('Delete this ' + kind + '?', 'About to delete this ' + kind + '. This action cannot be undone. Are you sure you want to delete it?', { color: 'red' })) {
          // yes
          this.deleteDynaLabelNode({pathToItem: path, idx: idx})
        }
        else {
          // cancel
        }
      },
    },
    mounted() {
      this.pathArray = this.pathToParent.replace(/\.$/,'').split('.')
      // if this is a brand new definition, pop the dialog
      if(this.isRoot
      && this.containerKind=='dynalabel'
      && this.dynaLabelData.hasOwnProperty('new')
      && this.dynaLabelData.new
      && this.dynaLabelDataLoadingStatus=='loaded'
      && this.dynaLabelsSelectorsLoadingStatus=='loaded'
      && !this.$refs.editor.dialog) {
        this.updateDynaLabelRootProp( {prop:'new', val:false})
        this.doAction('add')
      }
    },
  }
</script>

<style>

</style>
