<template>
  <v-container pl-8 pr-8 fluid class="insights-tile-grid">
    <v-row>
      <v-col :cols="expandedCols" v-if="checkExpanded">
        <span>
          <insights-expanded
            :loading="expandedInsightLoadingStatus"
            :insight="getExpandedInsight"
            :grouping="grouping"
            :grid="false"
            :favorite="favorite"
            :expanded="expanded"
            @toggle-expansion="toggleExpansion"
          ></insights-expanded>
        </span>
      </v-col>
      <v-col
        :cols="gridCols"
        v-if="insightDefinitions.length > 1 || !this.checkExpanded"
      >
        <div class="insights-page-container" :style="getSpecialStyles">
          <span v-for="insight in insightDefinitions" :key="insight.id">
            <span>
              <insights-tile
                :processing="insight.info.processing"
                :loading="insight.loading"
                :grouping="grouping"
                :grid="true"
                :insight="insight"
                :favorite="favorite"
                :expanded="expanded"
                @toggle-expansion="toggleExpansion"
              ></insights-tile>
            </span>
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InsightsTile from "@/components/Insights/InsightsTile.vue";
import InsightsExpanded from "@/components/Insights/InsightsExpanded.vue";
import { mapGetters } from "vuex";
import TileSkeletonView from '../Skeletons/TileSkeletonView.vue';
import ExpandedSkeletonView from '../Skeletons/ExpandedSkeletonView.vue';
import CacheHandler from "@/utils/CacheHandler";

export default {
  props: {
    insightDefinitions: Array,
    favorite: Number,
    grouping: String,
    insightsLoading: Boolean
  },
  components: {
    InsightsTile,
    InsightsExpanded,
  },
  data() {
    return {
      heightTimeout: null,
      debouncedHeight: 0,
      expanded: 0,
      isMounted: false,
      err: {}
    };
  },
  computed: {
    ...mapGetters({
      userData: state => state.users.userData,
      userDataLoadingStatus: state => state.users.userDataLoadingStatus,
      userDataLoadingError: state => state.users.userDataLoadingError,
      userDataLoadingErrorStatus: state =>
        state.users.userDataLoadingErrorStatus
    }),
    checkExpanded() {
      return Number(this.expanded) > 0 || false;
    },
    getExpandedInsight() {
      return this.insightDefinitions.find(i => i.id == this.expanded);
    },
    expandedInsightLoadingStatus() {
      return this.insightDefinitions.find(i => i.id == this.expanded).loading;
    },
    getSpecialStyles() {
      return this.checkExpanded
        ? "overflow-y: scroll; overflow-x: visible; padding: 1px 1px 1px 1px; margin-top -1px; height: " +
            this.expandedContainerHeight +
            "px;"
        : "";
    },
    myHeight: {
      get() {
        return this.debouncedHeight;
      },
      set(val) {
        if (this.heightTimeout) clearTimeout(this.heightTimeout);
        this.heightTimeout = setTimeout(() => {
          this.debouncedHeight = val;
        }, 25);
      }
    },
    expandedContainerHeight() {
      return Math.round(this.myHeight - 230);
    },
    expandedCols() {
      if (this.insightDefinitions.length == 1) return 12;
      if (this.$vuetify.breakpoint.lgAndDown) return 7;
      else return 8;
    },
    gridCols() {
      if (!this.checkExpanded) return 12;
      if (this.$vuetify.breakpoint.lgAndDown) return 5;
      else return 4;
    }
  },
  methods: {
    resizeHandler() {
      this.myHeight = window.innerHeight;
    },
    toggleExpansion(e) {
      this.expanded = e.id;
    },
    getDefaultId() {
      let fav =
        Number(CacheHandler.getItem("favorite_insight_definition")) || 0;
      let last =
        Number(CacheHandler.getItem("last_viewed_insight_definition")) || 0;
      let something =
        Array.isArray(this.insightDefinitions) &&
        this.insightDefinitions.length > 0
          ? this.insightDefinitions[0].id
          : 0;

      fav = this.insightDefinitions.some(o => o.id == fav) ? fav : 0;
      last = this.insightDefinitions.some(o => o.id == last) ? last : 0;
      if (fav) {
        let insight = this.insightDefinitions.find(o => o.id == fav);
        if (insight.processing == 0) {
          this.expanded = fav;
        } else {
          let insight = this.insightDefinitions.find(o => o.processing != 1);
          this.expanded = insight ? insight.id : null;
        }
      } else if (last) {
        let insight = this.insightDefinitions.find(o => o.id == last);
        if (insight.processing == 0) {
          this.expanded = last;
        } else {
          let insight = this.insightDefinitions.find(o => o.processing != 1);
          this.expanded = insight ? insight.id : null;
        }
      } else {
        let insight = this.insightDefinitions.find(o => o.processing != 1);
        this.expanded = insight ? insight.id : null;
      }
    }
  },
  updated() {
    // do stuff
  },
  mounted() {
    this.myHeight = window.innerHeight;
    this.getDefaultId();
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {
    insightDefinitions: function() {
      if (!this.insightsLoading) {
        if (this.expanded!=0) {
        this.getDefaultId()
        }
      }
    }
  }
};
</script>

<style>

div.insights-page-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 1rem;
}

.insights-tile-grid {
  padding-bottom: 4rem;
}
</style>
