<template>
  <fieldset>
    <legend>
      <setup-tip :section="'selections'"> </setup-tip> Search Selections
    </legend>
    <v-card class="pa-1 pl-5 pr-5 flex d-flex flex-column" elevation="0">
      <v-card-text v-if="dynaLabelDataLoadingStatus=='loaded'" class="pt-0 mt-0">
        <search-root
          :container="dynaLabelData.definition.searches"
          :pathToParent="'definition.searches.'"
          :pathToMe="'definition.searches.'"
          :dynaLabelId="dynaLabelId"
        ></search-root>
      </v-card-text>
    </v-card>
  </fieldset>
</template>

<script>

  import SearchRoot from "@/components/DynaLabels/SearchRoot.vue";
  import SetupTip from "@/components/SetupTip.vue";
  import {mapGetters} from "vuex";

  export default {
    name: 'search-definition',
    components: {
      SearchRoot,
      SetupTip,
    },
    data: function () {
      return {
      }
    },
    props: {
      dynaLabelId: Number,
    },
    computed: {
      ...mapGetters('dynaLabels',
        [ 'dynaLabelData',
          'dynaLabelDataLoadingStatus',
        ]),
    },
  }

</script>


<style scoped>

  fieldset {
    -moz-border-radius: .2rem;
    border-radius: .2rem;
    -webkit-border-radius: .2rem;
    border: 1px solid #c2c2c2;
  }

  legend {
    margin-left: .4rem;
    padding-left: .3rem;
    padding-right: .3rem;
    font-size: .8rem;
    color: #bababa;
  }

</style>
