<template>
  <v-dialog v-model="showModal" persistent width="90%">
    <v-card class="pa-md-4 mx-lg-auto">
      <div v-if="this.accessRoleDataLoadingStatus=='loaded'">
        <access-roles-page
          :accessRoleId="String(accessRoleId)"
          :description="'Access Role Description'"
          :usingModal="true"
          @updateAccessRoleList="updateAccessRoleList"
        ></access-roles-page>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="hideModal"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import AccessRolesPage from '../../pages/Setup/AccessRolesPage.vue';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AccessRoleModal",
  components: {
    AccessRolesPage
  },
  data() {
    return {
    };
  },
  props: {
    showModal: {
        type: Boolean,
        default: false
    },
    accessRoleId: {
        type: String,
        required: true
    }
  },
  computed: {
    ...mapGetters("accessRoles", [
      "accessRoleData",
      "accessRoleDataLoadingStatus",
      "accessRoleDataLoadingError",
      "accessRoleDataLoadingErrorStatus",
      "accessRolesListLoadingStatus"
    ]),
  },
  methods: {
    ...mapActions({
      retrieveAccessRoleData: "accessRoles/retrieveAccessRoleData"
    }),
    updateAccessRoleList() {
      this.$emit("updateAccessRoleList");
    },
    hideModal() {
      this.$emit("HIDE_MODAL", false);
    },
  },
  mounted() {
    this.retrieveAccessRoleData(this.accessRoleId)
  },

};
</script>
