<template>
  <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
    <v-row>
      <v-col
        cols="11"
        class="blue--text text--darken-4 pt-0 ml-1 mr-3"
        align-self="end"
      >
        {{ description }}
      </v-col>
      <v-col class="pa-0" style="text-align: right;">
        <v-btn
          fab
          small
          dark
          color="primary"
          href="/setup-faq/3"
          target="_blank"
          title="Click for Setup Help and FAQs"
        >
          <v-icon dark>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- alerts row -->
    <v-row v-if="okToRender && (showAlert || isDraft)">
      <v-col :cols="getCols" class="d-flex flex-column" v-if="showAlert">
        <v-alert
          dense
          border="left"
          type="warning"
          transition="scroll-y-reverse-transition"
          class="small-alert"
        >
          Scorecard Definition is being processed. You may make edits but may
          not publish them until processing is complete.
        </v-alert>
      </v-col>
      <v-col :cols="getCols" class="d-flex flex-column" v-if="isDraft">
        <v-alert dense text border="left" type="info" class="small-alert">
          Unpublished Draft (Will be stored for 24 hours from the last update)
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="this.userData.features.chat ? 4 : 6">
        <v-form v-model="validTitle">
          <v-text-field
            id="scorecardNameTxt"
            ref="titleField"
            v-model="scorecardDefinitionInfo.title"
            clearable
            :rules="titleRules"
            outlined
            counter
            dense
            :disabled="scorecardDefinitionsListLoadingStatus != 'loaded'"
            :maxlength="maxNameLength"
            hint="Your Scorecard Definition name will be visible by users included within the shared Access Role"
            label="Scorecard Definition Name"
            placeholder="Enter your scorecard definition name"
          ></v-text-field>
        </v-form>
      </v-col>
      <v-col :cols="this.userData.features.chat ? 5 : 6">
        <v-text-field
          v-model="scorecardDefinitionInfo.description"
          rows="2"
          outlined
          counter
          dense
          required
          clearable
          :disabled="scorecardDefinitionsListLoadingStatus != 'loaded'"
          :maxlength="maxDescriptionLength"
          hint="(Optional) Enter a brief description of the scorecard definition. <br />
                Your description will be visible by users included within the shared access roles."
          label="Description"
          placeholder="Describe your scorecard definition"
        ></v-text-field>
      </v-col>

      <v-col cols="3" class="d-flex justify-center" v-if="this.userData.features.chat">
        <v-btn-toggle
          @change="mediaTypeChange($event)"
          v-model="scorecardDefinitionInfo.media_type"
          mandatory
          class="mx-4"
          :sync="true"
        >
          <v-btn value="Call Recording"
            >Call Recording</v-btn
          >
          <v-btn value="Chat"
            >Chat</v-btn
          >
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- scorecard definition editor-->
    <scorecard-editor-definition
      v-if="listsLoaded"
      :scorecardDefinitionId="String(scorecardDefinitionId)"
      :selectedDynaLabels="dynalabels"
      :selectedAccessRoles="accessroles"
      :categories="categories"
      :minTalkTime="scorecardDefinitionInfo.min_talktime_length"
      :maxTalkTime="scorecardDefinitionInfo.max_talktime_length"
      :minCallScore="scorecardDefinitionInfo.min_call_score"
      :maxCallScore="scorecardDefinitionInfo.max_call_score"
      :cancel="cancelAll"
      :displayHandleTimeOrTalkTime="Number(displayHandleOrTalk)"
      :nonApplicableCat="handlingForNonApplicableCat"
      :includeAllAgents="handlingForIncludeAllAgents"
      :mediaType="scorecardDefinitionInfo.media_type"
      @updateAccessRoleSelections="updateAccessRoleSelections"
      @updateDynaLabelSelections="updateDynaLabelSelections"
      @updateMaxTalkTimeLength="updateMaxTalkTimeLength"
      @updateMinTalkTimeLength="updateMinTalkTimeLength"
      @setInfo="setInfo"
      @updateInfo="updateDefinition"
      @updatesCancelled="updatesCancelled"
      @updateMinCallScore="updateMinCallScore"
      @updateMaxCallScore="updateMaxCallScore"
      @allowPublish="updateDefinition"
      @updateSubCategory="updateDefinition"
      @changeTimeDisplayed="changeTimeDisplayed"
      @setNonApplicableCatChoice="updateNonApplicableChoice"
      @setIncludeAllAgentsChoice="updateIncludeAllAgentsChoice"
      @checkSubCategorySearches="checkSubCategorySearches"
    ></scorecard-editor-definition>

    <!-- buttons for saving, deleting, etc. - the visual layout is guided by UI/UX eye-tracking research -->
    <v-row>
      <v-col cols="12" class="d-flex ">
        <v-btn
          id="publishBtn"
          color="primary"
          class="ma-2"
          @click="publishDefinition"
          :disabled="canPublish"
        >Publish
        </v-btn
        >
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          @click="cloneDefinition"
          :disabled="canCloneOrDelete"
        >Clone
        </v-btn
        >
        <v-btn
          color="normal"
          class="ma-2"
          @click="cancelEdits"
          :disabled="canCancel"
        >Cancel Edits
        </v-btn>
        <div class="d-inline caption mt-4 ml-4">
          Created: {{ formatDate(scorecardDefinitionInfo.created_at) }} &mdash;
          Updated: {{ formatDate(scorecardDefinitionInfo.updated_at) }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          class="ma-2"
          @click="deleteDefinition"
          :disabled="canCloneOrDelete"
        >Delete
        </v-btn
        >
      </v-col>
    </v-row>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import confirm from "@/components/Widgets/Confirm.vue";
import ScorecardEditorDefinition from "../../components/ScorecardDefinitions/ScorecardEditorDefinition.vue";
import utils from "@/mixins/utils";
import moment from "moment";

export default {
  name: "ScorecardDefinitionsPage",
  mixins: [utils],
  props: {
    scorecardDefinitionId: String,
    description: String
  },
  computed: {
    ...mapGetters("scorecardDefinitions", [
      "scorecardsDefinitionsEditorListItem",
      "scorecardsDefinitionsEditorListItemLoadingStatus",
      "scorecardsDefinitionsEditorListItemLoadingError",
      "scorecardsDefinitionsEditorListItemLoadingErrorStatus",
      "scorecardDefinitionsList",
      "scorecardDefinitionsListLoadingStatus",
      "scorecardDefinitionsListLoadingError",
      "scorecardDefinitionsListLoadingErrorStatus",
      "scorecardDefinitionProcessingStatus"
    ]),
    ...mapGetters("users", ["userData"]),
    listsLoaded() {
      return (
        this.scorecardDefinitionInfo.dynalabels != undefined &&
        this.scorecardDefinitionInfo.accessroles != undefined &&
        this.scorecardDefinitionInfo.categories != undefined
      );
    },

    properTitles() {
      let properTitles = true;
      let tempTitle = [];
      if (this.scorecardDefinitionInfo.categories) {
        this.scorecardDefinitionInfo.categories.forEach((category, index) => {
          if (tempTitle.indexOf(category.category_title.trim()) < 0) {
            tempTitle.push(category.category_title.trim());
          } else {
            properTitles = false;
          }
          category.sub_categories.forEach((sub_category, index) => {
            if (sub_category.sub_category_title == "") {
              properTitles = false;
            }
          });
          if (category.category_title == "") {
            properTitles = false;
          }
        });
      }
      return properTitles;
    },
    isDraft() {
      return (
        Boolean(
          typeof this.scorecardDefinitionInfo === "object" &&
          this.scorecardDefinitionInfo.hasOwnProperty("draft") &&
          this.scorecardDefinitionInfo.draft
        ) || false
      );
    },
    isPublished() {
      return (
        Boolean(
          typeof this.scorecardDefinitionInfo === "object" &&
          this.scorecardDefinitionInfo.hasOwnProperty("published") &&
          this.scorecardDefinitionInfo.published
        ) || false
      );
    },
    isProcessing() {
      return this.scorecardDefinitionProcessingStatus > 0;
    },
    getCols() {
      return this.showAlert && this.isDraft ? 6 : 12;
    },
    canCloneOrDelete() {
      return !(
        this.scorecardDefinitionInfo.published &&
        this.accessRolesSelected.length > 0 &&
        this.dynaLabelsSelected.length > 0 &&
        !this.isDraft
      );
    },
    canCancel() {
      return !this.isDraft;
    },
    okToRender() {
      return Boolean(
        typeof this.scorecardDefinitionInfo === "object" &&
        this.scorecardDefinitionInfo !== null
      );
    },
    showAlert() {
      return Boolean(this.okToRender && this.isProcessing);
    },
    canPublish() {
      return !(
        this.isDraft &&
        !this.isProcessing &&
        this.validTitle &&
        this.accessRolesSelected.length > 0 &&
        this.dynaLabelsSelected.length > 0 &&
        this.categories.length > 0 &&
        this.properCallScoreRange &&
        this.properTalktimeRange &&
        this.properTitles &&
        this.properSearches &&
        this.properCatSubcatCounts
      );
    }
  },
  data() {
    return {
      properCallScoreRange: true,
      properTalktimeRange: true,
      properSearches: true,
      displayHandleOrTalk: false,
      handlingForNonApplicableCat: 0,
      handlingForIncludeAllAgents: 0,
      accessroles: [],
      dynalabels: [],
      categories: [],
      minTalkTimeLength: null,
      validTitle: false,
      maxTalkTimeLength: null,
      minCallScore: null,
      maxCallScore: null,
      scorecardDefinitionInfo: {},
      scorecardDefinitionInfoOrig: {},
      maxNameLength: 120,
      maxDescriptionLength: 255,
      dynaLabelsSelected: [],
      accessRolesSelected: [],
      cancelAll: false,
      isMounted: false,
      publishing: false,
      newId: false,
      titleRules: [
        value => !!value || "A unique name is required.",
        value => {
          if (!value) {
            return true;
          } else {
            return (
              !this.scorecardDefinitionsList.some(item => {
                return (
                  item.id != this.scorecardDefinitionId &&
                  item.title.trim().toUpperCase() == value.trim().toUpperCase()
                );
              }) || "Names must be unique."
            );
          }
        }
      ],
      properCatSubcatCounts: true
    };
  },
  components: {
    confirm,
    ScorecardEditorDefinition
  },
  methods: {
    ...mapActions({
      retrieveScorecardDefinitionsEditorList:
        "scorecardDefinitions/retrieveScorecardDefinitionsEditorList",
      retrieveScorecardEditorListItem:
        "scorecardDefinitions/retrieveScorecardEditorListItem",
      deleteScorecardDefinition:
        "scorecardDefinitions/deleteScorecardDefinition",
      cloneScorecardDefinition: "scorecardDefinitions/cloneScorecardDefinition",
      publishScorecardDefinition:
        "scorecardDefinitions/publishScorecardDefinition",
      updateScorecardLocalStorage:
        "scorecardDefinitions/updateScorecardLocalStorage",
      updateScorecardProcessingFlags:
        "scorecardDefinitions/updateScorecardProcessingFlags",
      cancelScorecardDefinitionEdits:
        "scorecardDefinitions/cancelScorecardDefinitionEdits",
      setScorecardListLoadingStatus:
        "scorecardDefinitions/setScorecardListLoadingStatus",
      setScorecardDefinitionProcessingStatus:
        "scorecardDefinitions/setScorecardDefinitionProcessingStatus",
      updateScorecardCategoryStorage:
        "scorecardDefinitions/updateScorecardCategoryStorage",
      deleteStrandedDeliveries:
        "deliveries/deleteStrandedDeliveries"
    }),
    formatDate(time) {
      return moment(time).format("l LT");
    },
    checkSubCategorySearches(value) {
      this.properSearches = value;
    },
    setListsStartProcessCheck() {
      this.newId = true;
      this.setInfo();
      this.dynalabels = this.scorecardDefinitionInfo.dynalabels;
      this.accessroles = this.scorecardDefinitionInfo.accessroles;
      this.categories = this.scorecardDefinitionInfo.categories;
    },
    setInfo() {
      if (this.scorecardDefinitionsList.length > 0) {
        this.scorecardDefinitionInfo = this.scorecardDefinitionsList.find(
          scorecardDefinition => {
            return (
              String(scorecardDefinition.id) ==
              String(this.scorecardDefinitionId)
            );
          }
        )

        this.setScorecardDefinitionProcessingStatus(
          this.scorecardDefinitionInfo.processing
        );
        this.categories = this.scorecardDefinitionInfo.categories;
      }
    },
    updateAccessRoleSelections(roles) {
      this.accessRolesSelected = roles.map(obj => ({
        accessrole_id: obj.id,
        new: obj.new,
        id: obj.db_id
      }));

      let updatedAccessRoles = this.accessRolesSelected
        .map(a => a.accessrole_id)
        .sort();
      let currentAccessRoles = this.scorecardDefinitionInfo.accessroles
        .map(a => a.accessrole_id)
        .sort();
      let accessRoleCheck = this.checkDifference(
        updatedAccessRoles,
        currentAccessRoles
      );
      if (!accessRoleCheck) {
        this.scorecardDefinitionInfo.accessroles = this.accessRolesSelected;
        this.updateDefinition();
      }
    },
    updateDynaLabelSelections(dynalabels) {
      this.dynaLabelsSelected = dynalabels.map(obj => ({
        dynalabel_id: obj.id,
        new: obj.new,
        id: obj.db_id
      }));
      let updatedDynalabels = this.dynaLabelsSelected
        .map(a => a.dynalabel_id)
        .sort();
      let currentDynalabels = this.scorecardDefinitionInfo.dynalabels
        .map(a => a.dynalabel_id)
        .sort();
      let dynalabelsCheck = this.checkDifference(
        updatedDynalabels,
        currentDynalabels
      );
      if (!dynalabelsCheck) {
        this.scorecardDefinitionInfo.dynalabels = this.dynaLabelsSelected;
        this.updateDefinition();
      }
    },
    checkDifference(selected, current) {
      return (
        selected.length === current.length &&
        selected.every((v, i) => v === current[i])
      );
    },
    updateMinCallScore(score) {
      let isNumValid =
        score != null
          ? Number(score) % 1 === 0 && score >= 0 && score <= 100
          : true;

      this.minCallScore = score == null ? null : Number(score);
      this.properCallScoreRange =
        (this.minCallScore >= this.maxCallScore && this.callRangeNotNull()) ||
        !isNumValid
          ? false
          : true;
      if (this.scorecardDefinitionInfo.min_call_score != score) {
        this.scorecardDefinitionInfo.min_call_score = this.minCallScore;
        this.updateDefinition();
      }
    },
    updateMaxCallScore(score) {
      let isNumValid =
        score != null
          ? Number(score) % 1 === 0 && score >= 0 && score <= 100
          : true;
      this.maxCallScore = score == null ? null : Number(score);
      this.properCallScoreRange =
        (this.maxCallScore <= this.minCallScore && this.callRangeNotNull()) ||
        !isNumValid
          ? false
          : true;
      if (this.scorecardDefinitionInfo.max_call_score != score) {
        this.scorecardDefinitionInfo.max_call_score = this.maxCallScore;
        this.updateDefinition();
      }
    },
    callRangeNotNull() {
      return this.minCallScore != null && this.maxCallScore != null;
    },
    changeTimeDisplayed(value) {
      this.displayHandleOrTalk = value;
      this.scorecardDefinitionInfo.display_talktime_or_handletime = this.displayHandleOrTalk;
      this.updateDefinition();
    },
    updateNonApplicableChoice(value) {
      this.handlingForNonApplicableCat = value;
      this.scorecardDefinitionInfo.handling_for_nonapplicable_category = this.handlingForNonApplicableCat;
      this.updateDefinition();
    },
    updateIncludeAllAgentsChoice(value) {
      this.handlingForIncludeAllAgents = value;
      this.scorecardDefinitionInfo.include_all_agents = this.handlingForIncludeAllAgents;
      this.updateDefinition();
    },
    updateMaxTalkTimeLength(length) {
      this.maxTalkTimeLength = length;
      this.properTalktimeRange =
        this.maxTalkTimeLength < this.minTalkTimeLength &&
        (this.maxTalkTimeLength != null && this.minTalkTimeLength != null)
          ? false
          : true;
      if (this.scorecardDefinitionInfo.max_talktime_length != length) {
        this.scorecardDefinitionInfo.max_talktime_length = this.maxTalkTimeLength;
        this.updateDefinition();
      }
    },
    updateMinTalkTimeLength(length) {
      this.minTalkTimeLength = length;
      this.properTalktimeRange =
        this.minTalkTimeLength > this.maxTalkTimeLength &&
        (this.maxTalkTimeLength != null && this.minTalkTimeLength != null)
          ? false
          : true;
      if (this.scorecardDefinitionInfo.min_talktime_length != length) {
        this.scorecardDefinitionInfo.min_talktime_length = this.minTalkTimeLength;
        this.updateDefinition();
      }
    },
    updatesCancelled() {
      this.cancelAll = false;
    },
    publishDefinition() {
      // after the dynalabel updates
      let info = Object.assign({}, this.scorecardDefinitionInfo);
      info.dynalabels = this.dynaLabelsSelected;
      info.accessroles = this.accessRolesSelected;
      this.publishScorecardDefinition(info);
      this.deleteStrandedDeliveries({
        requestType: 'update',
        deliveryType: 'scorecards',
        scorecardId: info.id,
        dynalabelIds: this.dynaLabelsSelected.map(item => item['dynalabel_id']),
      })
      this.publishing = true;
      this.setScorecardListLoadingStatus("loading");
      this.$router
        .push({path: "/setup/scorecard-definitions/"})
        .catch(err => {
        });
      setTimeout(function () {
        if (this.$vuetify) this.$vuetify.goTo(0);
      }, 1500);
      this.$emit("initCheckScorecardProcessing");
    },
    cloneDefinition() {
      let id = Date.now();
      let params = {
        id: id,
        definition: this.scorecardDefinitionInfo
      };
      this.cloneScorecardDefinition(params);
      this.selectTarget({type: "scorecardDefinitions", id: id});
    },
    selectTarget(obj) {
      let route = {
        name: "setup-" + this.kebabCase(obj.type),
        query: this.$route.query
      };
      if (obj.id !== "undefined") {
        route.params = {scorecardDefinitionId: Number(obj.id)};
      }
      this.$router.push(route).catch(err => {
      });
      setTimeout(function () {
        if (this.$vuetify) this.$vuetify.goTo(0);
      }, 1500);
    },
    async cancelEdits() {
      if (
        await this.$refs.confirm.open(
          "Cancel Edits?",
          "About to cancel edits and/or remove draft. This action cannot be undone. Are you sure you want to proceed?",
          {color: "red"}
        )
      ) {
        // yes
        this.cancelAll = true;
        let published = this.scorecardDefinitionInfo.published;
        let id = this.scorecardDefinitionInfo.id;
        this.cancelScorecardDefinitionEdits(id);
        if (!published) {
          this.$router
            .push({path: "/setup/scorecard-definitions/"})
            .catch(err => {
            });
          setTimeout(function () {
            if (this.$vuetify) this.$vuetify.goTo(0);
          }, 1500);
        } else {
          this.categories = [];
          this.publishing = false;
          this.cancelAll = true;
          this.retrieveScorecardDefinitionsEditorList();
          this.retrieveScorecardEditorListItem(id);
          this.$emit("openMenu", "scorecardDefinitions");
          this.$emit("selectTarget", {type: "scorecardDefinitions", id: id});
        }
      }
    },
    updateDefinition() {
      this.updateLocalScorecardData();
      this.checkCatSubcatCounts();
    },
    checkCatSubcatCounts() {
      let check = true;
      if (this.categories) {
        this.categories.forEach(cat => {
          let subcatCount = cat.sub_categories.length;
          if (
            cat.scoring_rule == 1 &&
            parseInt(cat.threshold_count) > subcatCount
          )
            check = false;
        });
      }
      this.properCatSubcatCounts = check;
    },
    checkForChanges() {
      if (!this.publishing) {
        if (
          !(
            JSON.stringify(this.scorecardDefinitionInfo) ==
            JSON.stringify(this.scorecardDefinitionInfoOrig)
          )
        ) {
          this.updateDefinition();
        }
      }
    },
    checkForChangesInData() {
      if (
        this.scorecardDefinitionInfo.hasOwnProperty("title") &&
        this.scorecardDefinitionInfo.hasOwnProperty("description")
      ) {
        if (
          this.scorecardDefinitionInfo.title !=
          this.scorecardsDefinitionsEditorListItem.title ||
          this.scorecardDefinitionInfo.description !=
          this.scorecardsDefinitionsEditorListItem.description
        ) {
          this.scorecardDefinitionInfo = Object.assign(
            {},
            this.scorecardsDefinitionsEditorListItem
          );
        }
      }
    },
    async deleteDefinition() {
      if (
        await this.$refs.confirm.open(
          "Delete this scorecard definition",
          "This action cannot be undone. Are you sure you want to delete?",
          {color: "red"}
        )
      ) {
        this.deleteScorecardDefinition(this.scorecardDefinitionInfo.id);
        this.deleteStrandedDeliveries({
          requestType: 'delete',
          deliveryType: 'scorecards',
          scorecardId: this.scorecardDefinitionInfo.id,
        })
        this.$router
          .push({path: "/setup/scorecard-definitions"})
          .catch(err => {
          });
        setTimeout(function () {
          if (this.$vuetify) this.$vuetify.goTo(0);
        }, 1500);
      }
    },
    updateLocalScorecardData() {
      if (this.scorecardDefinitionInfo) {
        this.updateScorecardLocalStorage({
          definition: this.scorecardDefinitionInfo,
          draftFlag: !this.checkCategoryExpanded()
        });
      }
    },
    checkCategoryExpanded() {
      let scorecardDefinitionInfoClone = JSON.parse(
        JSON.stringify(this.scorecardDefinitionInfo)
      );
      let scorecardDefinitionInfoOrigClone = JSON.parse(
        JSON.stringify(this.scorecardDefinitionInfoOrig)
      );

      // function to check if the only difference between the two is that the category was expanded. if so NO DRAFT
      scorecardDefinitionInfoClone.categories.forEach(object => {
        delete object["expanded"];
        object.sub_categories.forEach(sub_object => {
          delete sub_object["expanded"];
          delete sub_object["cancel"];
        });
      });
      delete scorecardDefinitionInfoClone["draft"];
      scorecardDefinitionInfoOrigClone.categories.forEach(object => {
        delete object["expanded"];
        object.sub_categories.forEach(sub_object => {
          delete sub_object["expanded"];
          delete sub_object["cancel"];
        });
      });
      delete scorecardDefinitionInfoOrigClone["draft"];

      return (
        JSON.stringify(scorecardDefinitionInfoClone) ===
        JSON.stringify(scorecardDefinitionInfoOrigClone)
      );
    },
    async mediaTypeChange(event) {
      if (this.dynalabels.length > 0) {
        if (
          await this.$refs.confirm.open(
            "Change Media Type?",
            "You are about to change media type. This will deselect all dynamic labels. Are you sure you want to proceed?",
            { color: "red" }
          )
        ) {
          this.dynalabels = []
        } else {
          this.scorecardDefinitionInfo.media_type = (event == 'Chat' ? 'Call Recording' : 'Chat')
          return false
        }
      }
      return true
    },
  },
  beforeUpdate() {
    if (this.newId) {
      if (typeof this.scorecardDefinitionInfo === "object")
        this.scorecardDefinitionInfoOrig = JSON.parse(
          JSON.stringify(this.scorecardDefinitionInfo)
        );
      this.checkForChanges();
    }
  },
  updated() {
    this.newId = false;
    if (
      this.scorecardDefinitionInfo &&
      this.scorecardDefinitionInfo.hasOwnProperty("title") &&
      this.scorecardDefinitionInfo.hasOwnProperty("description")
    ) {
      if (
        this.scorecardDefinitionInfo.title !=
        this.scorecardDefinitionInfoOrig.title ||
        this.scorecardDefinitionInfo.description !=
        this.scorecardDefinitionInfoOrig.description
      ) {
        this.setInfo();
        this.updateScorecardLocalStorage({
          definition: this.scorecardDefinitionInfo,
          draftFlag: !this.checkCategoryExpanded()
        });
      }
    }
  },
  mounted() {
    this.isMounted = true;
    this.checkForChangesInData();
    this.setInfo();
    this.minCallScore = this.scorecardDefinitionInfo.min_call_score;
    this.maxCallScore = this.scorecardDefinitionInfo.max_call_score;
    this.displayHandleOrTalk =
      this.scorecardDefinitionInfo.display_talktime_or_handletime == 0 ? 0 : 1;
    this.handlingForNonApplicableCat =
      this.scorecardDefinitionInfo.handling_for_nonapplicable_category == 0
        ? 0
        : 1;
    this.handlingForIncludeAllAgents =
      this.scorecardDefinitionInfo.include_all_agents == 0
        ? 0
        : 1;
    if (typeof this.scorecardDefinitionInfo === "object")
      this.scorecardDefinitionInfoOrig = JSON.parse(
        JSON.stringify(this.scorecardDefinitionInfo)
      );
    this.setListsStartProcessCheck();

    if (
      !this.scorecardDefinitionInfo.draft ||
      this.scorecardDefinitionInfo.new
    ) {
      let params = {
        id: this.scorecardDefinitionId,
        categories: this.categories
      };
      this.updateScorecardCategoryStorage(params);
    }
    this.newId = false;
    this.$emit("openMenu", "scorecardDefinitions");
    this.$emit("selectTarget", {
      type: "scorecardDefinitions",
      id: this.scorecardDefinitionId,
      preventProp: true
    });
  },
  watch: {
    scorecardDefinitionsList: "setInfo",
    scorecardDefinitionId: "setListsStartProcessCheck"
  }
};
</script>

<style scoped></style>
