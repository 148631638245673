<template>
    <v-container fluid>
        <v-row>
            <v-col class="caption center">
                0-10%
            </v-col>
            <v-col class="caption center">
                11-20%
            </v-col>
            <v-col class="caption center">
                21-30%
            </v-col>
            <v-col class="caption center">
                31-40%
            </v-col>
            <v-col class="caption center">
                41-50%
            </v-col>
            <v-col class="caption center">
                51-60%
            </v-col>
            <v-col class="caption center">
                61-70%
            </v-col>
            <v-col class="caption center">
                71-80%
            </v-col>
            <v-col class="caption center">
                81-90%
            </v-col>
            <v-col class="caption center">
                91-100%
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="bucket in distributiondata" :key="bucket.index" class="blue-grey lighten-5 ma-1 pa-2" style="height: 200px; overflow: hidden; overflow-y: scroll;">
                <v-chip
                        v-for="(item, i) in bucket"
                        :key="i"
                        small
                        :title=" item.id + ' ' + formatScore(item.score) "
                        :color="getColorValue(item.score)"
                        dark
                        @click="createCallScorecardTabInParent(item.id, 'call-score-distribution')"
                        :class="getClass(item.excluded || item.adjusted)"
                >
                <v-icon small v-if="item.excluded">mdi-close-box</v-icon><v-icon small v-else style="opacity: .5">mdi-box-outline</v-icon>
                <v-icon small v-if="item.adjusted">mdi-exclamation-thick</v-icon>
            </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="bucket in distributiondata" :key="bucket.index" class="ma-1 pa-1 center caption">
                {{ bucket.length }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import colors from "@/mixins/colors";
export default {
    mixins: [colors],
    data: () => ({}),
    props: {
      distributiondata: {
        type: Array,
        required: true
      },
      createCallScorecardTabInParent: {
        type: Function,
      }
    },
    methods: {
        formatScore: function(score) {
          score = score ? score : 0 // prevents NaN
            return Math.round(score*100) + "%"
        },
        getClass(x) {
            let c = (x) ? 'pr-1 pl-1' : 'pr-3 pl-3'
            return c
        },
    }
}

</script>

<style scoped>

    h1 { font-size: 2.2rem; }
    span { font-size: 1rem; }
    p { font-size: .8rem; }

    .center {
        text-align: center;
    }

</style>
