<template>
  <v-row class="ml-0 pa-2 mr-0 border-light" :class="{ legacyrow: isLegacy }">
    <v-col class="content" style="padding-bottom: 40px;" :class="{ legacycol: isLegacy }">
      <div v-if="transcript.analyses">
        <Phrase
          :id="'phrase-'+pIndex"
          class="row mb2 phrase"
          v-for="(phrase, pIndex) in phrases"
          :key="pIndex"
          :phrase="phrase"
          :insights="custProf.insights==1"
          :channel_count="callIn.channel_count"
          :class="{ highlighted: isPhraseHighlighted(pIndex, phrase) }"
          :isLegacy="isLegacy"
          @update-play-head-position="playHeadPosition"
          @update-play-from-position="playFromPosition"
          @update-set-position="setPosition"
        >
        </Phrase>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Phrase from "@/components/Transcripts/Phrase.vue";
import "@/assets/css/transcript.css";

export default {
  name: "Transcript",
  components: {
    Phrase,
  },

  props: {
    transcript: Object,
    callIn: Object,
    callId: String,
    eecNameXref: Object,
    custProf: Object,
    sentiment: String,
    isLegacy: {
      type: Boolean,
      required: false,
      default: false,
    },
    playHeadPosition: {
      type: Number,
      default: 0
    }
  },

  computed: {
    insights() {
      return Boolean(this.custProf.insights)
    },
    phrases() {
      // Phrases from Voci Analysis
      let transcript = this.transcript;
      if (transcript) {
        if (transcript.analyses) {
          return transcript.analyses[0].phrases;
        }
      }
    }
  },

  methods: {
    isPhraseHighlighted(pIndex, phrase) {
      let seconds = this.playHeadPosition.toFixed(2);
      if (seconds > 0) {
        if (seconds >= phrase.starts_at && seconds < phrase.ends_at) {
            this.$vuetify.goTo('#phrase-' + pIndex, { offset: 75, easing: 'linear', container: '.transcript-modal' });
        }
        return (seconds >= phrase.starts_at && seconds < phrase.ends_at);
      } else {
        return false;
      }
    },
    playFromPosition(e) {
      this.$emit('update-play-from-position', e);
    },
    setPosition(e) {
      this.$emit('update-set-position', e);
    },
  },
};
</script>

<style scoped>

  div.phrase {
    line-height: 2em;
    margin: 0.5em 1em 1em 0;
  }
  div.phrase.highlighted {
    background-color: #FFFF66;
  }

  .transcript-modal > .content,

  .legacyrow {
    width: 99% !important;
  }

  .legacycol {
    height: 53vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .highlight {
    background-color: yellow !important;
    font-weight: bold !important;
  }

  .svg-sentiment-strong-positive {
    --color-chevron-north-upper: green;
    --color-chevron-north-middle: lightgreen;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-positive {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: green;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-neutral {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-negative {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: orangered;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-strong-negative {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: orange;
    --color-chevron-south-lower: orangered;
  }

  .svg-sentiment-mixed {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgreen;
    --color-chevron-south-middle: orange;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-info {
    cursor: help;
  }

  .border-light {
    border: 1px solid rgba(0,0,0,.3);
  }

</style>
