export default {
    getCurrentCookieDomain() {
        let cookieDomain = window.location.hostname;
        let hostParts = cookieDomain.split(".");
        if(hostParts.length > 1){
        cookieDomain = "";
        for(let x  = 1; x < hostParts.length; x++){
            cookieDomain += "." + hostParts[x];
        }
        }
        return cookieDomain;
    },
    getCookieSecure() {
        return (window.location.protocol == "https:") ? ";secure" : "";
    },
    setCookie(key,value) {
        let fullCookieValue = key +"=" + value + ";domain=" + this.getCurrentCookieDomain() + ";path=/" + this.getCookieSecure();
        document.cookie = fullCookieValue;
    },
    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    },
    clearCookie(key) {
        let fullCookieValue = key +"=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + this.getCurrentCookieDomain() + ";path=/" + this.getCookieSecure();
        document.cookie = fullCookieValue;
    }
}