<template>
  <multiselect
    v-model="selected"
    :options="options"
    :multiple="true"
    :close-on-select="false"
    :clear-on-select="false"
    :placeholder="display"
    label="display"
    track-by="display"
    :preselect-first="false"
  >
    <template slot="selection" slot-scope="{ values, search, isOpen }">
      <span
        class="multiselect__single"
        v-if="values.length &amp;&amp; !isOpen"
      >
        {{ values.length }} {{ display }} selected
      </span>
    </template>
  </multiselect>
  <!-- <pre class="language-json"><code>{{ computedValue }}</code></pre> -->
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "DropdownMultiselect",
  components: { Multiselect },
  props: {
    filter: {
      type: String,
      default: "value"
    },
    feature: {
      type: String,
      default: "value"
    },
    display: {
      type: String,
      default: "display"
    },
    supplementalText: {
      type: String,
      default: "supplementalText"
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      selected: this.getSelected()
    };
  },
  watch: {
    selected() {
      let accessor =
        "filters/set" +
        this.filter.charAt(0).toUpperCase() +
        this.filter.slice(1);
      let computedSelected = this.computedSelected;
      this.$store.commit(accessor, computedSelected);
      this.$root.$emit("FILTER_UPDATED");
    }
  },
  computed: {
    computedSelected() {
      let value = null;
      if (this.selected.length !== 0) {
        value = { [this.filter]: this.selected.map(x => x.value) };
      }
      return value;
    }
  },
  methods: {
    getSelected() {
      const filter = this.filter;
      const storedValues = this.$store.getters["filters/" + filter] || [];

      if (storedValues.length === 0) { return []; }

      let selected = this.options.filter(option => {
        return storedValues[filter].includes(option.value);
      });

      return selected;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
