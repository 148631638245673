export const transcript = {
  namespaced: true,
  state: {
    Hits: []
  },
  mutations: {
    change(state, transcriptHits) {
      state.transcriptHits = transcriptHits;
    }
  },
  getters: {
    hits: state => {
      return state.transcriptHits;
    }
  }
};
