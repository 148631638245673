<template>
  <v-btn-toggle tile mandatory v-model="toggleGroup" @change="changeGlue()">
    <v-btn x-small value="and" class="py-3">
      and
    </v-btn>
    <v-btn x-small value="or" class="py-3">
      or
    </v-btn>
  </v-btn-toggle>
</template>

<script>

  import {mapActions} from "vuex"

  export default {
    props: {
      glue: String,
      pathToParent: String,
    },
    data() {
      return {
        toggleGroup: ''
      }
    },
    methods: {
      ...mapActions('dynaLabels', [
        'updateDynaLabelGlue'
      ]),
      changeGlue() {
        this.updateDynaLabelGlue({ glue: this.toggleGroup, pathToParent: this.pathToParent })
      },
      updateVModel() {
        this.toggleGroup = this.glue
      },
    },
    mounted() {
      this.updateVModel()
    },
    watch: {
      glue: 'updateVModel'
    }
  }
</script>

<style>

</style>

