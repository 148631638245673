<template>
  <v-container fluid mt-0 pt-4 pr-8 pb-8 pl-8>
    <v-row>
      <v-col
        cols="11"
        class="blue--text text--darken-4 py-0 ml-1 mr-3"
        align-self="end"
      >
        {{ description }}
      </v-col>
      <v-col class="pa-0" style="text-align: right;">
        <v-btn
          fab
          small
          dark
          color="primary"
          href="/setup-faq/2"
          target="_blank"
          title="Click for Setup Help and FAQs"
        >
          <v-icon dark>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isProcessing">
      <v-col cols="12" class="d-flex flex-column" v-if="isProcessing">
        <v-alert
          dense
          border="left"
          type="warning"
          transition="scroll-y-reverse-transition"
          class="small-alert"
        >
          Insight Definition is being processed.
        </v-alert>
      </v-col>
      </v-row>
    <v-row>
      <span v-if="isExpanded">
        <v-col cols="8" class="hintText py-0 ml-1 mr-3">
          {{ expandMessage }}
        </v-col>
      </span>
    </v-row>
    <v-row>
      <v-col class="pt-0 ml-1 mr-3">
        <v-btn text x-small color="primary" @click="toggleHints">
          <span v-if="isExpanded">
            hide example
            <v-icon small right>mdi-chevron-up</v-icon>
          </span>
          <span v-else>
            show example
            <v-icon small right>mdi-chevron-down</v-icon>
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="this.userData.features.chat ? 4 : 6">
        <v-text-field
          id="insightNameTxt"
          ref="titleField"
          v-model="insightDefinitionInfo.title"
          :rules="titleRules"
          outlined
          counter
          :disabled="insightsDefinitionsListLoadingStatus != 'loaded'"
          :maxlength="maxNameLength"
          hint="Your insight definition name will be visible by users included within the shared access roles."
          label="Insight Definition Name"
          placeholder="Enter your Insight Defininition Name"
        ></v-text-field>
      </v-col>
      <v-col :cols="this.userData.features.chat ? 5 : 6">
        <v-text-field
          v-model="insightDefinitionInfo.description"
          rows="2"
          outlined
          counter
          required
          :disabled="insightsDefinitionsListLoadingStatus != 'loaded'"
          :maxlength="maxDescriptionLength"
          hint="(Optional) Enter a brief description of the insight definition.  Your description will be visible by users included within the shared access roles."
          label="Description"
          placeholder="Describe your Insight Definition"
        ></v-text-field>
      </v-col>
      <v-col cols="3" class="d-flex justify-center" v-if="this.userData.features.chat">
        <v-btn-toggle
          @change="mediaTypeChange"
          v-model="insightDefinitionInfo.media_type"
          mandatory
          class="mx-4"
          :sync="true"
        >
          <v-btn value="Call Recording"
            >Call Recording</v-btn
          >
          <v-btn value="Chat"
            >Chat</v-btn
          >
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- insight definition editor-->
    <insight-editor-definition
      v-if="
        insightDefinitionInfo.dynalabels != undefined &&
          insightDefinitionInfo.accessroles != undefined
      "
      :insightDefinitionId="String(insightDefinitionId)"
      :selectedDynaLabels="dynalabels"
      :selectedAccessRoles="accessroles"
      :minTalkTime="insightDefinitionInfo.min_talktime_length"
      :maxTalkTime="insightDefinitionInfo.max_talktime_length"
      :cancelEdits="cancelAll"
      :mediaType="insightDefinitionInfo.media_type"
      @updateAccessRoleSelections="updateAccessRoleSelections"
      @updateDynaLabelSelections="updateDynaLabelSelections"
      @updateMaxTalkTimeLength="updateMaxTalkTimeLength"
      @updateMinTalkTimeLength="updateMinTalkTimeLength"
      @setInfo="setInfo"
      @updateInfo="updateInfo"
      @updatesCancelled="updatesCancelled"
    ></insight-editor-definition>

    <!-- buttons for saving, deleting, etc. - the visual layout is guided by UI/UX eye-tracking research -->
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-btn
          id="publishBtn"
          color="primary"
          class="ma-2"
          :disabled="canPublish || !properTalktimeRange || isProcessing"
          @click="publishDefinition"
          >Publish</v-btn
        >
        <v-btn
          color="blue-grey"
          class="ma-2 white--text"
          :disabled="canCloneOrDelete"
          @click="cloneDefinition"
          >Clone</v-btn
        >
        <v-btn
          color="normal"
          class="ma-2"
          :disabled="canPublish"
          @click="cancelEdits"
          >Cancel Edits</v-btn
        >
        <div class="d-inline caption mt-4 ml-4">
          Created: {{ formatDate(insightDefinitionInfo.created_at) }} &mdash;
          Updated: {{ formatDate(insightDefinitionInfo.updated_at) }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          class="ma-2"
          :disabled="canCloneOrDelete"
          @click="deleteDefinition"
          >Delete</v-btn
        >
      </v-col>
    </v-row>

    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import accessRoleDefinition from "@/components/AccessRoles/AccessRoleDefinition.vue";
import confirm from "@/components/Widgets/Confirm.vue";
import InsightEditorDefinition from "@/components/InsightDefinitions/InsightEditorDefinition.vue";
import DefinitionLoadingStatus from "./DefinitionLoadingStatus.vue";
import moment from "moment";

export default {
  name: "InsightDefinitionsPage",
  props: {
    insightDefinitionId: String,
    description: String,
    expandMessage: String
  },
  computed: {
    ...mapGetters("accessRoles", [
      "accessRolesList",
      "accessRolesListLoadingStatus",
      "accessRolesListLoadingError",
      "accessRolesListLoadingErrorStatus",
      "accessRoleData",
      "accessRoleDataLoadingStatus",
      "accessRoleDataLoadingError",
      "accessRoleDataLoadingErrorStatus",
      "authorizedUserList",
      "authorizedUserListLoadingStatus",
      "authorizedUserListLoadingError",
      "authorizedUserListLoadingErrorStatus"
    ]),
    ...mapGetters("dynaLabels", [
      "dynaLabelsList",
      "dynaLabelsListLoadingStatus",
      "dynaLabelsListLoadingError",
      "dynaLabelsListLoadingErrorStatus",
      "dynaLabelData",
      "dynaLabelDataLoadingStatus",
      "dynaLabelDataLoadingError",
      "dynaLabelDataLoadingErrorStatus",
      "dynaLabelsEditorListLoadingStatus"
    ]),
    ...mapGetters("insights", [
      "insightsDefinitionsList",
      "insightsDefinitionsListLoadingStatus",
      "insightsDefinitionsListLoadingError",
      "insightsDefinitionsListErrorStatus",
      "insightsDefinitionsListItemData",
      "insightsDefinitionsListItemDataLoadingStatus",
      "insightsDefinitionsListItemDataLoadingError",
      "insightsDefinitionsListItemDataLoadingErrorStatus",
      "insightsListDataLoadingStatus",
      "insightProcessingStatus"
    ]),
    ...mapGetters("users", ["userData"]),
    titleFieldIsValid() {
      if (this.isMounted) return !this.$refs.titleField.hasError;
      else return false;
    },
    canCloneOrDelete() {
      return !(
        this.insightDefinitionInfo.published &&
        this.accessRolesSelected.length > 0 &&
        this.dynaLabelsSelected.length > 0 &&
        !this.insightDefinitionInfo.cancel
      );
    },
    isPublished() {
      return (
        Boolean(
          typeof this.insightDefinitionInfo === "object" &&
          this.insightDefinitionInfo.hasOwnProperty("published") &&
          this.insightDefinitionInfo.published
        ) || false
      );
    },
    isProcessing() {
      return Boolean(this.insightProcessingStatus);
    },
    canPublish() {
      return !(
        this.insightDefinitionInfo.cancel &&
        this.titleFieldIsValid &&
        this.accessRolesSelected.length > 0 &&
        this.dynaLabelsSelected.length > 0
      );
    }
  },
  data() {
    return {
      isExpanded: false,
      accessRoleInfo: {},
      accessroles: [],
      dynalabels: [],
      minTalkTimeLength: null,
      maxTalkTimeLength: null,
      properTalktimeRange: true,
      insightDefinitionInfo: {},
      insightDefinitionInfoOrig: {},
      maxNameLength: 120,
      maxDescriptionLength: 255,
      dynaLabelsSelected: [],
      accessRolesSelected: [],
      cancelAll: false,
      isMounted: false,
      publishing: false,
      newId: false,
      polling: null,
      titleRules: [
        value => !!value || "A unique name is required.",
        value => {
          if (
            typeof this.insightDefinitionInfo === "object" &&
            this.insightDefinitionInfo.title != undefined
          ) {
            return (
              !this.insightsDefinitionsList.some(item => {
                return (
                  item.id != this.insightDefinitionId &&
                  item.title.trim().toUpperCase() == value.trim().toUpperCase()
                );
              }) || "Names must be unique."
            );
          } else {
            return "";
          }
        }
      ]
    };
  },
  components: {
    accessRoleDefinition,
    confirm,
    InsightEditorDefinition,
    DefinitionLoadingStatus
  },
  methods: {
    ...mapActions({
      updateInsightDefinition: "insights/updateInsightDefinition",
      retrieveInsightsEditorList: "insights/retrieveInsightsEditorList",
      deleteInsightDefinition: "insights/deleteInsightDefinition",
      cloneInsightDefinition: "insights/cloneInsightDefinition",
      publishInsightDefinition: "insights/publishInsightDefinition",
      updateInsightDefinitionClone: "insights/updateInsightDefinitionClone",
      updateInsightProcessingFlags: "insights/updateInsightProcessingFlags",
      setInsightProcessingStatus: "insights/setInsightProcessingStatus",
      deleteStrandedDeliveries: "deliveries/deleteStrandedDeliveries"
    }),
    toggleHints() {
      this.isExpanded = !this.isExpanded;
    },
    formatDate(time) {
      return moment(time).format("l LT");
    },
    setLabelsAndRoles() {
      this.newId = true;
      this.setInfo();
      this.dynalabels = this.insightDefinitionInfo.dynalabels;
      this.accessroles = this.insightDefinitionInfo.accessroles;
    },
    setInfo() {
      this.insightDefinitionInfo = this.insightsDefinitionsList.find(
        insightDefinition => {
          return (
            String(insightDefinition.id) == String(this.insightDefinitionId)
          );
        }
      );
      if (typeof this.insightDefinitionInfo === "object") {
        this.insightDefinitionInfoOrig = Object.assign(
          {},
          this.insightDefinitionInfo
        );
        this.setInsightProcessingStatus(this.insightDefinitionInfo.processing);
      }
    },
    updateInfo() {
      this.updateDefinition();
    },
    updateAccessRoleSelections(roles) {
      this.accessRolesSelected = roles.map(x => x.id);
    },
    updateDynaLabelSelections(dynalabels) {
      this.dynaLabelsSelected = dynalabels.map(x => x.id);
    },
    updateMaxTalkTimeLength(length) {
      this.maxTalkTimeLength = length;
      this.properTalktimeRange =
        this.maxTalkTimeLength < this.minTalkTimeLength &&
        (this.maxTalkTimeLength != null && this.minTalkTimeLength != null)
          ? false
          : true;
      if (this.insightDefinitionInfo.max_talktime_length != length) {
        this.updateDefinition();
      }
    },
    updateMinTalkTimeLength(length) {
      this.minTalkTimeLength = length;
      this.properTalktimeRange =
        this.minTalkTimeLength > this.maxTalkTimeLength &&
        (this.maxTalkTimeLength != null && this.minTalkTimeLength != null)
          ? false
          : true;
      if (this.insightDefinitionInfo.min_talktime_length != length) {
        this.updateDefinition();
      }
    },
    updatesCancelled() {
      this.cancelAll = false;
    },
    publishDefinition() {
      let info = this.insightDefinitionInfo;
      info.accessroles = this.accessRolesSelected;
      info.dynalabels = this.dynaLabelsSelected;
      info.min_talktime_length = this.minTalkTimeLength;
      info.max_talktime_length = this.maxTalkTimeLength;
      this.publishInsightDefinition(info);
      this.deleteStrandedDeliveries({
        requestType: 'update',
        deliveryType: 'insights',
        scorecardId: info.id,
        dynalabelIds: this.dynaLabelsSelected,
      })
      this.publishing = true;
    },
    cloneDefinition() {
      this.$emit("updateinsightDefinitionStatus", false);
      let params = {
        id: this.insightDefinitionInfo.id,
        user_id: this.userData.id,
        dynalabels: this.insightDefinitionInfo.dynalabels,
        accessroles: this.insightDefinitionInfo.accessroles
      };
      this.cloneInsightDefinition(params);
    },
    async cancelEdits() {
      if (
        await this.$refs.confirm.open(
          "Cancel Edits?",
          "About to cancel edits and/or remove draft. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        // yes
        this.cancelAll = true;
        let published = this.insightDefinitionInfo.published;
        let id = this.insightDefinitionInfo.id;

        if (!published) {
          this.$emit("updateinsightDefinitionStatus", false);
          this.$router
            .push({ path: "/setup/insight-definitions/" })
            .catch(err => {});
          setTimeout(function() {
            if (this.$vuetify) this.$vuetify.goTo(0);
          }, 1500);
        } else {
          this.publishing = false;
          this.retrieveInsightsEditorList();
          this.$emit("openMenu", "insightDefinitions");
          this.$emit("selectTarget", { type: "insightDefinitions", id: id });
        }
      }
    },
    updateDefinition() {
      this.updateInsightDefinition(this.insightDefinitionInfo);
    },
    checkForChanges() {
      if (!this.publishing) {
        if (
          !(
            JSON.stringify(this.insightDefinitionInfo) ==
            JSON.stringify(this.insightDefinitionInfoOrig)
          )
        ) {
          this.updateDefinition();
        }
      }
    },
    checkForChangesInData() {
      if (this.insightDefinitionInfo.title != undefined) {
        if (
          this.insightDefinitionInfo.title !=
            this.insightsDefinitionsListItemData.title ||
          this.insightDefinitionInfo.description !=
            this.insightsDefinitionsListItemData.description
        ) {
          this.insightDefinitionInfo = Object.assign(
            {},
            this.insightsDefinitionsListItemData
          );
        }
      }
    },
    async deleteDefinition() {
      if (
        await this.$refs.confirm.open(
          "Delete this insight definition",
          "This action cannot be undone. Are you sure you want to delete?",
          { color: "red" }
        )
      ) {
        this.deleteInsightDefinition(this.insightDefinitionInfo.id);
        this.deleteStrandedDeliveries({
          requestType: 'delete',
          deliveryType: 'insights',
          scorecardId: this.insightDefinitionInfo.id,
        })
        this.$router
          .push({ path: "/setup/insight-definitions" })
          .catch(err => {});
        setTimeout(function() {
          if (this.$vuetify) this.$vuetify.goTo(0);
        }, 1500);
      }
    },
    initCheckInsightProcessing() {
      this.polling = setInterval(() => {
        this.updateInsightProcessingFlags();
      }, 10000);
    },
    async mediaTypeChange(event) {
      if (this.dynalabels.length > 0) {
        if (
          await this.$refs.confirm.open(
            "Change Media Type?",
            "You are about to change media type. This will deselect all dynamic labels. Are you sure you want to proceed?",
            { color: "red" }
          )
        ) {
          this.dynalabels = []
        } else {
          this.insightDefinitionInfo.media_type = (event == 'Chat' ? 'Call Recording' : 'Chat')
          return false
        }
      }
      return true
    },
  },
  beforeDestroy() {
    if(this.polling!==null) clearInterval(this.polling)
  },
  watch: {
    insightsDefinitionsList: "setInfo",
    insightDefinitionId: "setLabelsAndRoles"
  },
  beforeUpdate() {
    if (this.newId) {
      if (typeof this.insightDefinitionInfo === "object")
        this.insightDefinitionInfoOrig = JSON.parse(
          JSON.stringify(this.insightDefinitionInfo)
        );
    }
    this.checkForChanges();
  },
  updated() {
    this.newId = false;
    this.setInfo();
  },
  mounted() {
    this.isMounted = true;
    this.initCheckInsightProcessing();
    this.checkForChangesInData();
    this.setInfo();
    if (typeof this.insightDefinitionInfo === "object")
      this.insightDefinitionInfoOrig = JSON.parse(
        JSON.stringify(this.insightDefinitionInfo)
      );
    this.setLabelsAndRoles();
    this.newId = false;
    this.$emit("openMenu", "insightDefinitions");
    this.$emit("selectTarget", {
      type: "insightDefinitions",
      id: this.insightDefinitionId,
      preventProp: true
    });
  }
};
</script>

<style scoped>
.hintText {
  color: rgb(25, 118, 210);
  font-size: 0.875rem;
  line-height: 1.5rem !important;
  padding-left: 1.3rem !important;
}
</style>
