<template>
    <v-container>
        <v-layout row wrap align-center>
            <v-flex class="text-center">
                <h1 class="light-blue--text text--darken-4">{{ team_acv }}<span class="grey--text text--lighten-1"></span></h1>
                <p class="grey--text text--darken-1">Calls/Day</p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

export default {
    props: {
        team_acv: {
            type: Number,
            required: true
        }
    }
}

</script>

<style scoped>

    h1 { font-size: 2.2rem; }
    span { font-size: 1rem; }
    p { font-size: .8rem; }

</style>
