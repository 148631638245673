<template>
  <date-range-picker
    ref="datepicker"
    :startDate="startDate"
    :endDate="endDate"
    @update="updateRange"
    :locale-data="locale"
    :opens="opens"
    class="float-left"
    :ranges="ranges"
  ></date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.js";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css";
import moment from "moment";

export default {
  name: "DatePicker",
  components: {
    DateRangePicker
  },

  data() {
    return {
      startDate: moment(this.$store.getters['dateRange/start_date']),
      endDate: moment(this.$store.getters['dateRange/end_date']),
      opens: "right", //which way the picker opens, default "center", can be "left"/"right"
      locale: {
        direction: "ltr", //direction of text
        format: "M/D/YYYY", //format of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 0, //ISO first day of week - see moment documenations for details
        showWeekNumbers: true //show week numbers on each row of the calendar
      },
      ranges: {
        Today: [moment().startOf("day"), moment().endOf("day")],
        Yesterday: [
          moment()
            .startOf("day")
            .subtract(1, "days"),
          moment()
            .endOf("day")
            .subtract(1, "days")
        ],
        "This week": [
          moment()
            .startOf("day")
            .startOf("week"),
          moment()
        ],
        "Last week": [
          moment()
            .startOf("day")
            .subtract(1, "week")
            .startOf("week"),
          moment()
            .endOf("day")
            .subtract(1, "week")
            .endOf("week")
        ],
        "This month": [
          moment()
            .startOf("day")
            .startOf("month"),
          moment()
            .endOf("day")
            .endOf("month")
        ],
        "Last month": [
          moment()
            .startOf("day")
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .endOf("day")
            .subtract(1, "month")
            .endOf("month")
        ],
        "Last twelve weeks": [
          moment()
            .startOf("day")
            .startOf("week")
            .subtract(12, "weeks"),
          moment()
            .endOf("day")
            .endOf("week")
            .subtract(1, "week")
        ]
      }
    };
  },

  methods: {
    updateRange(value) {
      const startDate = moment(value.startDate).startOf('day').format();
      const endDate = moment(value.endDate).endOf('day').format();
      this.$store.commit("dateRange/setStartDate", startDate);
      this.$store.commit("dateRange/setEndDate", endDate);
      this.$emit("DATE_RANGE_UPDATED", value);
      // Unlike the other areas that use filter, the Dashboard submits it's request
      // on the event of the date range changing rather than on a "submit" button.
      this.$root.$emit("DATE_RANGE_UPDATED");
    }
  },

  mounted() {
    this.updateRange({
      startDate: this.$refs.datepicker.startDate.toJSON(),
      endDate: this.$refs.datepicker.endDate.toJSON()
    });
  }
};
</script>

<style lang="css">
.drp-calendar.col { width: 240px; }
</style>
