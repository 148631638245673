<template>
  <DateRangePicker></DateRangePicker>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker.vue";

export default {
  name: "DateRange",
  components: {
    DateRangePicker
  }
};
</script>

<style></style>
