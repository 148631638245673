<template>
  <div :class="`tile-outer-container elevation-1`">
    <v-progress-linear indeterminate color="primary"></v-progress-linear>
    <div class="tile-inner-container-skeleton" v-if="type == 'insight'">
      <v-skeleton-loader
        class="skeleton-data-tile pl-4 mt-4"
        type="image"
        max-height="115"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="skeleton-data-tile pr-4 mt-4"
        max-height="115"
        type="image"
      ></v-skeleton-loader>
      <div class="insight-data-tile">
        <v-skeleton-loader
          class="skeleton-data-tile pl-4 mb-12"
          type="image"
          max-height="115"
        ></v-skeleton-loader>
      </div>
      <v-skeleton-loader
        class="skeleton-data-tile pr-4 mb-12"
        max-height="115"
        type="image"
      ></v-skeleton-loader>
    </div>
    <div class="tile-inner-container-skeleton" v-else>
      <v-col>
        <v-skeleton-loader
          class="skeleton-data-tile pl-4 mt-4"
          type="image"
          max-height="160"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="skeleton-data-tile pl-4 mt-0"
          type="image"
          max-height="160"
        ></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader
          class="skeleton-data-tile pr-4 mt-4"
          type="image"
          max-height="95"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="skeleton-data-tile pr-4 mt-4"
          max-height="95"
          type="image"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="skeleton-data-tile pr-4 mt-4 mb-12"
          max-height="95"
          type="image"
        ></v-skeleton-loader>
      </v-col>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String
  },
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped>
div.tile-outer-container div.insight-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
  color: rgba(1, 106, 175, 1);
  font-weight: bold;
}

div.tile-inner-container-skeleton {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

div.skeleton-data-tile {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
}
</style>
