<template>
  <div class="mr-4">

      <v-btn icon @click="openScheduledItemsMenu" stacked :title="scheduledItemsTitle()">
          <custom-icon name="schedule-export"></custom-icon> {{this.scheduledItems.length}}
      </v-btn>

    <v-menu
      v-model="showMenu"
      absolute
      offset-y
      :position-x="menuPosition.x"
      :position-y="menuPosition.y"
      :close-on-content-click="false"
      :close-on-click="false"
    >
      <v-tabs
        v-model="tab"
        background-color="primary"
        dark
        centered
        center-active
      >
        <v-tab>Scheduled Deliveries</v-tab>
        <!-- TODO: remove for history later <v-tab :disabled="isDHDisabled">Delivery History</v-tab> -->
        <v-spacer></v-spacer>
        <v-btn x-small icon dark @click="showMenu=!showMenu" style="margin: 1rem 1rem 0 0"><v-icon>mdi-close</v-icon></v-btn>
      </v-tabs>

      <v-tabs-items v-model="tab" class="export-list-container">
        <v-tab-item>
          <v-alert v-if="this.getDeliveryError" type="error" class="alert-message">
            {{ "There was an error while loading the deliveries. Please try again later." }}
          </v-alert>
          <v-list v-else dense class="list-size">
            <v-list-item v-if="this.scheduledItems.length == 0">
              <v-list-item-content>
                <v-alert dense text border="left" type="info" class="small-alert">
                  {{ "No scheduled deliveries" }}
                </v-alert>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <span style="display: inline;">
                  <v-icon color="primary">mdi-open-in-app</v-icon>
                  <a href="/scheduled-deliveries" class="pl-2">See all Scheduled Deliveries</a>
                </span>
              </v-list-item-content>
            </v-list-item>
            <template v-if="this.scheduledItems.length > 0" >
              <v-list-item v-for="(item, idx) in this.scheduledItems" :key="idx">
                <v-list-item-content>
                  <v-card class="card-style" dark>
                    <v-card-title class="card-title" :title="'Scheduled Delivery ID: ' + item.id">{{ item.name }}</v-card-title>
                    <v-card-subtitle class="pb-0">
                      <p class="card-subtitle">{{ item.description }}</p>
                      <p class="card-subtitle-details">
                        Delivered {{item.frequency}}
                        <template v-if="item.frequency=='Monthly'"><span v-html="`on the ` + getOrdinalWithSuffix(item.delivery_day_of_month)"></span></template>
                        <template v-if="item.frequency=='Weekly'"> (on {{item.delivery_day}})</template>
                        @ {{item.delivery_hour}} {{item.ampm}} {{item.timezone}} by {{item.delivery_type}}
                      </p>
                      <p class="card-subtitle-pseudobreadcrumb">{{ getPseudoBreadcrumbs[item.id] }}</p>
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn x-small class="edit-button" @click="editScheduledItem(idx)">Edit</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

        </v-tab-item>

        <v-tab-item v-if="deliveryHistory.length>0">

          <v-list dense class="list-size">
            <v-list-item v-for="(item, idx) in sortedDeliveryHistory" :key="idx">
              <v-list-item-icon class="mr-2">
                <v-icon small v-text="statusIcon(item.status)" :color="statusColor(item.status)"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-html="getHistoryItemTitle(item.id)"></v-list-item-title>
                <v-list-item-subtitle v-html="getHistoryItemSubtitle(item.id,item.sent)"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-tab-item>

      </v-tabs-items>


    </v-menu>

    <scheduled-exports-modal
      :showScheduledExportsModal="showScheduledExportsModal"
      :target="target"
      :isNew="false"
      @HIDE_SCHEDULED_EXPORTS_MODAL="hideModal"
    ></scheduled-exports-modal>

  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex"
import ScheduledExportsModal from "@/components/Widgets/ScheduledExportsModal.vue";
import moment from "moment";
import ordinals from "@/mixins/ordinals";

export default {
  name: "ScheduledExportsList",
  mixins: [ordinals],
  props: {
  },
  components: {
    ScheduledExportsModal,
  },
  data() {
    return {
      target: null,
      tab: 0,
      showScheduledExportsModal: false,
      showMenu: false,
      menuPosition: {
        x: 0,
        y: 0
      },
      deliveryHistory: [],
      scheduledItems: [],
      insightNames: [],
      dynalabelNames: [],
      agentNames: [],
    }
  },
  methods: {
    ...mapActions("deliveries", [
      "getDeliveryList",
      "setUpdateDeliveryFlag",
      "setReGetDeliveries",
      "setGetDeliveryError",
      "setRemoveDelivery",
    ]),
    ...mapActions("resources", [
      "retrieveAgentData",
    ]),
    ...mapActions("dynaLabels", [
      "retrieveDynaLabelsList",
    ]),
    ...mapActions("insights", [
      "retrieveInsightsList",
    ]),
    ...mapActions("scorecardsV2", [
      "retrieveScorecardsList",
    ]),
    getHistoryItemTitle(id) {
      let thing = this.scheduledItems.find(obj=>obj.id==id)
      return thing.name || 'Not Found'
    },
    getHistoryItemSubtitle(id, sent) {
      let thing = this.scheduledItems.find(obj=>obj.id==id)
      return (thing) ? "Delivered by " + thing.delivery_type + " " + moment(sent).format("dddd, MMMM D, YYYY [at] h:mmA") : "Not Found"
    },
    getDeliveryInfo() {
      this.setUpdateDeliveryFlag(false)
      this.getDeliveryList();
    },
    editScheduledItem(idx) {
      // set the target and wait for the next tick before opening the dialog (prevents race condition)
      this.target = this.sortedScheduledItems()[idx]
      this.$nextTick(() => {
        this.showScheduledExportsModal = true
      })
    },
    hideModal() {
      this.showScheduledExportsModal = false
    },
    openScheduledItemsMenu(e) {
      this.showMenu = true;
      this.menuPosition.x = e.clientX - 110;
      if (e.clientY < 35) {
        this.menuPosition.y = 35;
      } else {
        this.menuPosition.y = e.clientY;
      }
    },
    statusIcon(status) {
      return (status) ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline'
    },
    statusColor(status) {
      return (status) ? 'success' : 'error'
    },
    sortedScheduledItems() {
      return [...this.scheduledItems].sort(function (a, b) {
        if(a.name == b.name) {
            return (a.id > b.id) ? 1 : -1
        } else {
            return (a.name > b.name) ? 1 : -1
        }
      })
    },
    scheduledItemsTitle() {
      let count = this.scheduledItems.length
      let noun = (count>0) ? 'deliveries' : 'delivery'
      return `${count} scheduled ${noun}`
    },
    updateDeliveries() {
      if(this.updateDeliveriesFlag) {

        if(this.dynaLabelsListLoadingStatus=='notLoading') {
          this.retrieveDynaLabelsList({})
        }

        if(this.agentDataLoadingStatus=='notLoading') {
          this.retrieveAgentData({ email: true })
        }

        if(this.insightsListDataLoadingStatus=='notLoading') {
          this.retrieveInsightsList({
            starts_at: this.$route.query.start_date,
            ends_at:  this.$route.query.end_date
          })
        }

        if(this.newScorecardsListLoadingStatus=='notLoading') {
          this.retrieveScorecardsList({
            starts_at: this.$route.query.start_date,
            ends_at:  this.$route.query.end_date,
            grouping: moment(this.$route.query.end_date).diff(moment(this.$route.query.start_date), "days") > 1 ? "day" : "hour",
          })
        }

        this.setUpdateDeliveryFlag(false)
        this.scheduledItems = this.deliveryList
        this.scheduledItems = this.sortedScheduledItems()
        // get the arrays of ids for the breadcrumb names
        let dynalabelArray = []
        let agentArray = []

        for(let i=0; i<this.scheduledItems.length; i++) {
          dynalabelArray.push(this.scheduledItems[i].dynalabel_id)
          dynalabelArray.push(this.scheduledItems[i].dynalabel_id)
          agentArray.push(this.scheduledItems[i].agent_id)
        }

      }
    },
    getPseudoBreadcrumb(delivery){
      let rtn_string = ""
      if(this.insightsListDataLoadingStatus == "failed" || this.newScorecardsListLoadingStatus == "failed" || this.dynaLabelsListLoadingStatus == "failed" || this.agentDataLoadingStatus == "failed") {
        return ["There was an error while loading delivery data. Please try again later.",delivery.id]
      } else if (this.insightsListDataLoadingStatus!="loaded" || this.newScorecardsListLoadingStatus!="loaded" || this.dynaLabelsListLoadingStatus != "loaded" || this.agentDataLoadingStatus != "loaded") {
        return ["Loading...",delivery.id]
      }
      // weird breadcrumbs. ex: all calls for dynalabel name for agent name
      if(delivery.type == 0) {
        let scorecardName = this.newScorecardsList.find(function (entry) {if(entry.id == delivery.type_id) return entry.title })
        if(scorecardName == undefined) {
          rtn_string += "Scorecard Delivery: " + "Undefined Scorecard"
        }else if(delivery.pdf_delivery == 1) {
          rtn_string += "Scorecard PDF Delivery: " + scorecardName.title
        } else {
          rtn_string += "Scorecard Delivery: " + scorecardName.title
        }
      } else {
        let insightName = this.insightsListData.find(function (entry) {if(entry.id == delivery.type_id) return entry.title })
        if(insightName == undefined) {
          rtn_string += "Insight Delivery: " + "Undefined Insight"
        } else {
          rtn_string += "Insight Delivery: " + insightName.title
        }

      }

      if(delivery.export_path > 1 && delivery.dynalabel_id != 0) {
        let dynaName = this.dynaLabelsList.find(function (entry) {if(entry.id == delivery.dynalabel_id) return entry.title })
        if(dynaName != undefined) {
          rtn_string += " for " + dynaName.title
        } else {
          rtn_string += " for " + "Undefined Dynamic Label"
        }
      } else if (delivery.export_path > 1 && delivery.dynalabel_id == 0) {
        rtn_string += " for All Dynamic Labels"
      }

      if(delivery.export_path > 2 && delivery.agent_id != 0) {
        let agentName = this.agentData.find(function (entry) {if(entry.id == delivery.agent_id) return entry.name })
        if(agentName != undefined) {
          rtn_string += " for " + agentName.name
        } else {
          rtn_string += " for " + "Undefined Agent"
        }
      } else if (delivery.export_path > 2 && delivery.agent_id == 0) {
        rtn_string += " for All Agents"
      }
      return [rtn_string,delivery.id]

    },
    getDeliveries() {
      if(this.reGetDeliveries) {
        this.setReGetDeliveries(false)
        // close modal
        this.getDeliveryList();
        this.hideModal()
      }
    },
    deleteDelivery() {
      if(this.removeDelivery != false) {
        this.scheduledItems = this.scheduledItems.filter(item => item.id !== this.removeDelivery)
        this.setRemoveDelivery(false)
      }
    }
  },
  computed: {
    ...mapGetters('deliveries', [
      'updateDeliveriesFlag',
      'reGetDeliveries',
      'getDeliveryError',
      'removeDelivery',
    ]),
    ...mapGetters('resources', [
      'agentData',
      'agentDataLoadingStatus',
    ]),
    ...mapGetters('dynaLabels', [
      'dynaLabelsList',
      'dynaLabelsListLoadingStatus',
    ]),
    ...mapGetters('insights', [
      'insightsListData',
      'insightsListDataLoadingStatus',
    ]),
    ...mapGetters('scorecardsV2', [
      'newScorecardsList',
      'newScorecardsListLoadingStatus',
    ]),
    ...mapState({
      deliveryList: state => state.deliveries.deliveryList,
    }),

    sortedDeliveryHistory() {
      return [...this.deliveryHistory].sort((a, b) => (a.sent < b.sent) ? 1 : -1)
    },
    isDHDisabled() {
      return (this.deliveryHistory.length<1)
    },
    getPseudoBreadcrumbs() {
      let rtn_array = []
      let breadcrumb_arr = []
      for(let i=0;i<this.scheduledItems.length;i++) {
        breadcrumb_arr = this.getPseudoBreadcrumb(this.scheduledItems[i])
        rtn_array[breadcrumb_arr[1]] = breadcrumb_arr[0]
      }
      return rtn_array
    }
  },
  watch: {
    updateDeliveriesFlag: "updateDeliveries",
    reGetDeliveries: "getDeliveries",
    removeDelivery: "deleteDelivery",
  },
  mounted() {
    this.getDeliveryInfo();
    this.scheduledItems = this.deliveryList
  }
};
</script>

<style scoped>

  .export-list-container {
    padding: 1rem;
    width: 450px;
  }

  .export-list-container ::v-deep .v-window-item {
    border-bottom: 2px solid rgba(0, 0, 0, .1);
  }

  .list-size {
    max-height:80vh;
    overflow-y: scroll;
  }

  .card-style {
    background-color: rgb(52, 136, 191);
  }

  .card-title {
    font-size:1rem;
  }

  .card-subtitle {
    padding-top: 0;
    font-size: .8rem;
    line-height: 1rem;
    margin-bottom: .5rem;
  }

  .card-subtitle-details {
    font-weight: 900;
    margin-bottom: .5rem;
  }

  .card-subtitle-pseudobreadcrumb {
    margin-bottom: .5rem;
  }

  .edit-button {
    background-color: rgb(1, 106, 175) !important;
  }

</style>


