<template>
  <span>
    <span v-if="prefix">{{ prefix }}</span>
    <v-progress-circular
      v-if="scoreUndefined"
      :rotate="180"
      :size="24"
      :value="value"
      color="primary"
    >
    </v-progress-circular>
    <template v-else>
      <v-chip
        dark
        small
        label
        class="font-weight-black"
        :color="getColor(modScore)"
        :style="getStyle(modScore)"
      >
        <span v-if="isApplicable || (!isApplicable && nonAppCatHandling == 1)">
          {{ formatScore(modScore) }}
        </span>
        <span v-else>
          N/A
        </span>
        <v-tooltip
          v-if="calls_modified > 0 || modified_by"
          bottom
          :color="getColorDarker(modScore)"
          open-delay="750"
          close-delay="500"
          content-class="cat-stats-overlay"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              style="margin-left: .25rem;"
              :size="13"
              color="white"
              class="hover-help"
              >mdi-alert-circle-outline</v-icon
            >
          </template>

          <template v-if="calls_modified > 0">
            <p>
              {{ calls_modified }} call<template v-if="calls_modified > 1"
                >s</template
              >
              adjusted
            </p>
          </template>

          <template v-if="modified_by">
            <p>
              Adjusted by <i>{{ modified_by }}</i> on {{ modified_on }}
            </p>
          </template>
        </v-tooltip>
        <span v-else :style="'width:100%'"></span>
      </v-chip>
    </template>
  </span>
</template>

<script>
import colors from "@/mixins/colors";

export default {
  mixins: [colors],
  props: {
    score: {
      type: Number
    },
    prefix: {
      type: String,
      required: false,
      default: ""
    },
    calls_modified: {
      type: Number,
      default: 0
    },
    modified_by: {
      type: String,
      default: ""
    },
    modified_on: {
      type: String,
      default: ""
    },
    applicable: {
      type: String,
      default: ""
    },
    manual_applicable: {
      type: String,
      default: ""
    },
    category_chip: {
      type: Boolean,
      default: false
    },
    nonAppCatHandling: {
      type: Number,
      default: null
    },
    aggregate_cat: {
      type: Boolean,
      default: null
    },
    num_not_applicable: {
      type: Number,
      default: 0
    },
    total_calls: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    notApplicable: "#BDBDBD",
    interval: {},
    value: 0
  }),
  computed: {
    modScore() {
      return !this.isApplicable && this.nonAppCatHandling == 1 ? 1 : this.score;
    },
    scoreUndefined() {
      return typeof this.score == "undefined";
    },
    isApplicable() {
      if (this.aggregate_cat && this.nonAppCatHandling == 0) {
        // if the number of calls not applicable == the total number of calls, the category is not applicable
        return this.num_not_applicable != this.total_calls;
      } else {
        if (!this.category_chip) {
          return true;
        }
        if (
          this.manual_applicable != "null" &&
          this.manual_applicable != "undefined"
        ) {
          return this.manual_applicable == "1" ? true : false;
        } else {
          return this.applicable == "1" ? true : false;
        }
      }
    }
  },
  methods: {
    getColorDarker(score) {
      // return darker version of score color
      if (typeof score == "undefined" || score == null || score < 0)
        return "rgba(0,0,0,.1)";
      else {
        if (!this.isApplicable) {
          return this.notApplicable;
        }
        // force the index to always be between 0 and 9
        let hexColor = this.getColorValue(score);
        let pcnt = 0.18;
        let r = this.darken(this.hex2dec(hexColor.slice(1, 3)), pcnt);
        let g = this.darken(this.hex2dec(hexColor.slice(3, 5)), pcnt);
        let b = this.darken(this.hex2dec(hexColor.slice(5, 7)), pcnt);
        return "rgba(" + r + "," + g + "," + b + ",1)";
      }
    },
    getColor(score) {
      if (typeof score == "undefined" || score == null || score < 0)
        return "rgba(0,0,0,.1)";
      else {
        if (!this.isApplicable && this.nonAppCatHandling == 0) {
          return this.notApplicable;
        }
        return this.getColorValue(score);
      }
    },
    formatScore(score) {
      if (typeof score == "undefined" || score == null || score < 0)
        return "–––";
      else {
        score = score ? score : 0; // prevents NaN
        return Math.round(score * 100) + "%";
      }
    },
    getStyle(score) {
      if (typeof score == "undefined" || score == null || score < 0) return "";
      else if (this.category_chip && !this.isApplicable && !this.modified_by)
        return "padding:0 1.3rem; width:4rem";
      else if (this.calls_modified > 0 || this.modified_by) {
        return score < 0.995
          ? "width:4rem;padding-left:1rem;padding-right:1rem"
          : "width:4rem;padding-left:.7rem;padding-right:1rem";
      }
      return score < 0.095
        ? "padding:0 1rem 0 1.5rem;width:4rem"
        : score < 0.995
        ? "padding:0 .75rem 0 1.3rem;width:4rem"
        : "padding:0 1.2rem; width:4rem";
    },

    hex2dec(h) {
      // convert hex string to decimal integer
      return parseInt(h, 16);
    },
    darken(v, d) {
      // reduce v by d percent
      return Math.round(v * (1 - d));
    },

    scoreChanged() {
      if (!this.scoreUndefined) {
        clearInterval(this.interval);
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value >= 100) {
        return (this.value = 0);
      }
      this.value += 25;
    }, 500);
    if (!this.scoreUndefined) {
      clearInterval(this.interval);
    }
  },
  watch: {
    score: "scoreChanged"
  }
};
</script>

<style scoped>
.cat-stats-overlay p {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 0.5rem !important;
}

.hover-help {
  cursor: help !important;
}
</style>
