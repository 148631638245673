<template>
  <v-card class="ml-4 mr-6" flat>
    <v-form>
      <v-card-title class="pt-0 mt-0">
        <h2>Search Transcripts</h2>
      </v-card-title>
      <v-card-text :class="{ 'has-error': errors.length }">
        <v-row align="center"
               justify="center">
          <v-col cols="10" class="pa-2">
            <v-textarea
              ref="searchInput"
              rows="5"
              outlined
              class="form-control border-input"
              hint='Use the reserved words AND, OR and NOT to constrain your search. Use quotes to denote "a specific phrase"'
              placeholder='Construct your search here: "spacely sprockets" AND "george jetson"'
              v-model="text"
            ></v-textarea>
            <v-alert
              dense
              outlined
              type="error"
              v-if="errors.length>0"
            >
              <p class="text-danger" v-for="error in errors" :key="error">
                {{ error }}
              </p>
            </v-alert>
          </v-col>
          <v-col cols="2" class="pa-2 text-center">
            <v-btn
              dark
              color="primary"
              title="submit"
              :loading="loading"
              @click="emitText"
              class="mb-8"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>

import DateRange from "@/components/Filters/DateRange.vue";

export default {
  name: "SearchBox",

  props: {
    msg: String,
    loading: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      text: "",
      errors: []
    };
  },

  watch: {
    text() {
      const position = this.$refs.searchInput.selectionStart;
      const text = this.formatText(this.text);
      if (this.text !== text) {
        this.$nextTick(() => {
          this.$refs.searchInput.selectionEnd = position;
        });
      }
      this.text = text;
      this.validateInput();
      this.$store.commit("searchQuery/setQuery", this.text);
    }
  },

  methods: {
    validateInput() {
      this.errors = []; // clear errors

      const regex = /^(and|or|not|before|after|near)\b|\b(and|or|not|before|after|near)$/gi;
      if (regex.test(this.text)) {
        const error = "A search cannot begin or end with a reserved word";
        this.addError(error);
      }
    },

    formatText(text) {
      // using look behinds is a better solution but not yet supported by all browsers
      // const regex = /(?<=^([^"]|"[^"]*")*)\b(and|or|not|before|after|near)\b/gi;
      const regex = /\b(and|or|not|before|after|near)\b(?=(?:[^"]*"[^"]*")*[^"]*$)/gi;
      return text.toLowerCase().replace(regex, function(v) {
        return v.toUpperCase();
      });
    },

    emitText() {
      if (!this.text) {
        const error = 'Please add text to the search';
        this.addError(error);
        return;
      }
      this.$emit('SUBMIT_SEARCH', this.text);
      return;
    },

    addError(error) {
      if (this.errors.indexOf(error) === -1) {
        this.errors.push(error);
      }
    }
  }
}
</script>
