<template>
  <v-expansion-panels :multiple="false" v-model="panelOpen">
    <v-expansion-panel class="blue-grey lighten-5">
      <v-expansion-panel-header>
        <h4 id="datePicker">
          Selected Date Range: <em>{{ dateRangeString }}</em>
          <!-- Insert a status message if the filter is enabled -->
          <template v-if="showAdjustedCallsFilter">
            <span :class="getAdjustedCallsFilterStatusClass">
              (Adjusted Calls Filter:
              <em>{{ adjustedCallFilter.toUpperCase() }}</em
              >)</span
            >
            <v-spacer></v-spacer>
          </template>
        </h4>
        <!--  -->
        <h4
          class="right dim"
          v-html="
            panelOpen == null || typeof panelOpen === 'undefined'
              ? 'show filters'
              : 'hide filters'
          "
        ></h4>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container fluid pa-0>
          <div v-if="showDateRange" class="xs-12 sm-12 md-6 lg-6 xl-6 mb-2">
            <vc-date-picker
              mode="range"
              :theme="{dayNotInMonth: 'not-in-month'}"
              :available-dates="{ start: this.getMinimumDate(), end: new Date() }"
              v-model="selectedDate"
              @input="updateRange"
            >
            </vc-date-picker>
          </div>

          <div
            v-if="filtersPresent"
            v-for="(filter, key) in filters"
            :key="key"
            class="xs-12 sm-12 md-6 lg-6 xl-6 mb-2"
          >
            <component
              v-if="showFilter(filter) && filter.value != 'subjectiveSet'"
              :is="dynamicFilter(filter)"
              :feature="feature"
              :filter="filter.value"
              :display="filter.display"
              :supplementalText="filter.supplemental_text"
              :options="filter.options"
              @filter-updated-reload="filterUpdatedReload(filter)"
              @account-filter-updated-reload="sendNewSearch(filter)"
            ></component>
            <subjective-dropdown
              v-if="
                showFilter(filter) &&
                  filter.value == 'subjectiveSet' &&
                  channels > 0
              "
              :feature="feature"
              :channelCount="channels"
              :filter="filter.value"
              :display="filter.display"
              :supplementalText="filter.supplemental_text"
              :title="'subjective'"
              :options="filter.options"
              :values="getValueFormat(filter.options)"
              @filter-updated-reload="filterUpdatedReload(filter)"
            ></subjective-dropdown>
            <v-container fluid>
              <v-radio-group
                v-if="
                  showAdjustedCallsFilter &&
                    filter.value == 'scorecardAdjustments'
                "
                v-model="adjustedCallFilter"
                row
                label="Adjusted Calls:"
                @change="filterAdjustedCalls"
                dense
              >
                <v-radio label="All" value="all"></v-radio>
                <v-radio label="Adjusted" value="adjusted"></v-radio>
                <v-radio label="Not adjusted" value="not adjusted"></v-radio>
              </v-radio-group>
            </v-container>
          </div>
        </v-container>

        <v-dialog v-model="alert" width="500">
          <v-card>
            <v-card-text>
              {{ alertText }}
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="alert = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from "moment";
import axios from "@/utils/AxiosInstance.js";
import DropdownMultiselect from "@/components/Filters/DropdownMultiselect.vue";
import ContainerMultiselect from "@/components/Filters/ContainerMultiselect.vue";
import SubjectiveDropdown from "@/components/Filters/SubjectiveDropdown.vue";
import Dropdown from "@/components/Filters/Dropdown.vue";
import Duration from "@/components/Filters/Duration.vue";
import DateRange from "@/components/Filters/DateRange.vue";
import {mapGetters, mapState} from "vuex";
import CacheHandler from "../utils/CacheHandler";

export default {
  name: "Filters",
  components: {
    Dropdown,
    DropdownMultiselect,
    ContainerMultiselect,
    SubjectiveDropdown,
    Duration,
    DateRange
  },
  props: {
    feature: {
      type: String,
      default: ""
    },
    channels: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      /* add new properties */
      adjustedCallsFilterStatus: false,
      /* */
      panelOpen: null,
      filtersPresent: false,
      filters: [],
      accountSelections: [],
      selectedDate: {
        start: new Date(this.$store.getters["dateRange/start_date"]),
        end: new Date(this.$store.getters["dateRange/end_date"])
      },
      disabledDates: {
        start: new Date('0001-01-01T00:00:00Z'),
        end: this.getMinimumDate()
      },
      alert: false,
      alertText: "",
      typeExceptions: ["duration", "date_range"],
      adjustedCallFilter: "all"
    };
  },
  computed: {
    ...mapGetters({
      userData: 'users/userData',
    }),
    getAdjustedCallsFilterStatusClass() {
      return this.adjustedCallsFilterStatus ? "pl-4 light" : "pl-4 dim";
    },
    showAdjustedCallsFilter() {
      return this.feature == "scorecards-v2";
    },
    showDateRange() {
      return (
        this.feature == "scorecards" ||
        this.feature == "scorecards-v2" ||
        (this.filters &&
          this.filters.some(filter => filter.value === "date_range"))
      );
    },
    dateRangeString() {
      return (
        this.selectedDate.start.toLocaleDateString() +
        " - " +
        this.selectedDate.end.toLocaleDateString()
      );
    }
  },
  methods: {
    filterUpdatedReload(filter) {
      this.$emit(filter.value + "-filter-updated-reload");
      this.panelOpen = null;
    },
    showFilter(filter) {
      if (filter.options.length > 1) return true;
      else
        return this.typeExceptions.some(v => {
          return v === filter.type;
        });
    },
    updateRange() {
      const old_start_date = this.$store.getters["dateRange/start_date"];
      const old_end_date = this.$store.getters["dateRange/end_date"];
      const startDate = moment(this.selectedDate.start)
        .startOf("day")
        .format();
      const endDate = moment(this.selectedDate.end)
        .endOf("day")
        .format();

      // check the duration and let them only select a month
      let dateDiff = moment
        .duration(
          moment(this.selectedDate.end).diff(moment(this.selectedDate.start))
        )
        .asDays();
      if (dateDiff >= 31) {
        this.setAlert("You must select 31 days or less for a date range.");
        this.selectedDate.start = new Date(old_start_date);
        this.selectedDate.end = new Date(old_end_date);
      } else {
        this.$store.commit("dateRange/setStartDate", startDate);
        this.$store.commit("dateRange/setEndDate", endDate);

        if (old_start_date != startDate || old_end_date != endDate) {
          // collapse filters panel
          this.panelOpen = null;

          // This tells the component that instantiated Filters.vue that the date range changed
          this.$emit("DATE_RANGE_UPDATED", this.selectedDate);

          // Unlike the other areas that use filter, the Dashboard submits it's request
          // on the event of the date range changing rather than on a "submit" button.
          this.$root.$emit("DATE_RANGE_UPDATED");
        }
      }
    },
    sendNewSearch(filter) {
      this.accountSelections = [];
      if (filter.value == "accounts") {
        this.accountSelections = this.$store.getters["filters/accounts"];

        if (this.accountSelections != null) {
          this.accountSelections = this.$store.getters[
            "filters/accounts"
          ].accounts;
        }
        this.$root.$emit("RESET_PAGINATION");
        this.sendRequest();
      }
    },
    getParams() {
      let params = {
        start_date: this.$store.getters["dateRange/start_date"],
        end_date: this.$store.getters["dateRange/end_date"],
        accounts: this.accountSelections,
        limit: 5,
        offset: 0
      };
      const selectedFilters = this.$store.getters["filters/request"];
      Object.assign(params, selectedFilters);

      return params;
    },
    sendRequest() {
      this.$emit("account-filter-updated-reload", this.accountSelections);
    },
    getValueFormat(values) {
      let newValues = [];
      for (var i = 0; i < values.length; i++) {
        if (i == Math.floor(values.length / 2)) {
          newValues.push({ name: "conditional", value: "", text: "" });
        }
        if (values[i].display != "conditional") {
          let emotions = values[i].values.split(",");
          newValues.push({
            name: values[i].display,
            value: emotions,
            text: "Any"
          });
        }
      }
      return newValues;
    },
    // Filter types are saved in snakecase.
    // This converts snake_case and skewer-case to camel
    dynamicFilter(filter) {
      // Guard: Date Range is handled separately
      if (filter.type === "date_range") return;
      let filterType =
        filter.type.charAt(0).toUpperCase() + filter.type.slice(1);
      return filterType.replace(/([-_][a-z])/gi, string => {
        return string
          .toUpperCase()
          .replace("-", "")
          .replace("_", "");
      });
    },
    setAlert(text) {
      this.alertText = text;
      this.alert = true;
    },
    filterAdjustedCalls() {
      if (this.adjustedCallFilter == "not adjusted") {
        this.$store.commit("filters/setScorecardAdjustments", "not_adjusted");
      } else {
        this.$store.commit(
          "filters/setScorecardAdjustments",
          this.adjustedCallFilter
        );
      }

      this.filterUpdatedReload({ value: "adjustments" });
    },
    getAdjustedFilterText() {
      let filterString = this.$store.getters["filters/scorecardAdjustments"] || 'all'
      return filterString.replace(/_/g, ' ').toLowerCase()
    },
    getMinimumDate() {
      // if proxying and can manage searches, unrestrict available dates
      if (this.isProxying() && this.userData.privileges.callFinderManageSearches)
        return null
      var curDate = new Date()
      var horizon = localStorage.getItem("accountHorizon") || process.env.VUE_APP_DEFAULT_HORIZON
      curDate.setDate(curDate.getDate() - horizon)
      return curDate
    },
    isProxying() {
      return this.userData && this.userData.id != this.userData.csrep_userid || false;
    }
  },
  mounted() {
    let self = this;
    const filters_url = process.env.VUE_APP_FILTERS;
    this.adjustedCallFilter = this.getAdjustedFilterText();

    axios
      .get(filters_url, {
        params: {
          feature: this.feature
        }
      })
      .then(response => {
        if (response && response.data) {
          // set active filters
          self.filters = response.data.data;
          let active = [];
          if (self.filters) {
            active = self.filters.map(filter => filter.value);
          }
          this.$store.commit("filters/setActive", active);
          this.filtersPresent = self.filters.length > 0;
          if (this.filtersPresent) {
            this.$emit("filters-loaded", self.filters);
          }
        }
      });
  }
};
</script>

<style scoped>

::v-deep div.not-in-month span { color: rgba(0,0,0,.25) !important; }

.v-dialog > .v-card > .v-card__text {
  padding: 10px;
}

.dim {
  color: rgba(0, 0, 0, 0.3);
}

.light {
  font-weight: normal;
}

.right {
  text-align: end;
  padding-right: 0.5rem;
}

h5 {
  font-size: 0.9rem;
  color: rgba(1, 106, 175, 0.5);
}
</style>
