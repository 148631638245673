var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subjective-container"},[_c('div',{staticClass:"subjective-observations",style:({ 'padding-bottom': _vm.bottomPadding })},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"subjective-observation-container"},'div',attrs,false),on),[_c('div',{class:'subjective-observation ' +  _vm.type + '-negative',style:('height:'+Math.round(_vm.info.secondHalfNegativeFraction*_vm.scale)+'px')})])]}}])},[[_vm._v(_vm._s(_vm.display))],_c('div',[_c('table',{staticClass:"hover-table"},[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("Out of "+_vm._s(_vm.info.callCount)+" calls, "+_vm._s(_vm.info.secondHalfNegativeCount)+" ended negative")]),_c('th',[_vm._v("("+_vm._s(_vm.formatPercent(_vm.info.secondHalfNegativeFraction))+")")])]),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Of those …")])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfNegativeFirstHalfNegativeCount))]),_c('td',[_vm._v("started negative")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedNegativeValues.secondHalfNegativeFirstHalfNegativePercent)))])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfNegativeFirstHalfNeutralCount))]),_c('td',[_vm._v("started neutral")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedNegativeValues.secondHalfNegativeFirstHalfNeutralPercent)))])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfNegativeFirstHalfPositiveCount))]),_c('td',[_vm._v("started positive")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedNegativeValues.secondHalfNegativeFirstHalfPositivePercent)))])])])])],2),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"subjective-observation-container"},'div',attrs,false),on),[_c('div',{class:'subjective-observation ' + _vm.type + '-neutral',style:('height:'+Math.round(_vm.info.secondHalfNeutralFraction*_vm.scale)+'px')})])]}}])},[[_vm._v(_vm._s(_vm.display))],_c('div',[_c('table',{staticClass:"hover-table"},[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("Out of "+_vm._s(_vm.info.callCount)+" calls, "+_vm._s(_vm.info.secondHalfNeutralCount)+" ended neutral")]),_c('th',[_vm._v("("+_vm._s(_vm.formatPercent(_vm.info.secondHalfNeutralFraction))+")")])]),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Of those …")])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfNeutralFirstHalfNegativeCount))]),_c('td',[_vm._v("started negative")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedNeutralValues.secondHalfNeutralFirstHalfNegativePercent)))])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfNeutralFirstHalfNeutralCount))]),_c('td',[_vm._v("started neutral")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedNeutralValues.secondHalfNeutralFirstHalfNeutralPercent)))])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfNeutralFirstHalfPositiveCount))]),_c('td',[_vm._v("started positive")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedNeutralValues.secondHalfNeutralFirstHalfPositivePercent)))])])])])],2),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"subjective-observation-container"},'div',attrs,false),on),[_c('div',{class:'subjective-observation ' + _vm.type + '-positive',style:('height:'+Math.round(_vm.info.secondHalfPositiveFraction*_vm.scale)+'px')})])]}}])},[[_vm._v(_vm._s(_vm.display))],_c('div',[_c('table',{staticClass:"hover-table"},[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("Out of "+_vm._s(_vm.info.callCount)+" calls, "+_vm._s(_vm.info.secondHalfPositiveCount)+" ended positive")]),_c('th',[_vm._v("("+_vm._s(_vm.formatPercent(_vm.info.secondHalfPositiveFraction))+")")])]),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Of those …")])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfPositiveFirstHalfNegativeCount))]),_c('td',[_vm._v("started negative")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedPositiveValues.secondHalfPositiveFirstHalfNegativePercent)))])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfPositiveFirstHalfNeutralCount))]),_c('td',[_vm._v("started neutral")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedPositiveValues.secondHalfPositiveFirstHalfNeutralPercent)))])]),_c('tr',[_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.info.secondHalfPositiveFirstHalfPositiveCount))]),_c('td',[_vm._v("started positive")]),_c('td',{staticClass:"r"},[_vm._v(_vm._s(_vm.formatValue(_vm.subjectiveErrorCorrectedPositiveValues.secondHalfPositiveFirstHalfPositivePercent)))])])])])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }