<template>
    <v-content>
      <v-container fill-height>
          <v-row justify="center">
            <v-container fill-height>
              <v-row justify="center">
                <div class="display-4 font-weight-black">
                  404
                </div>
              </v-row>
              <v-row justify="center">
                <div class="display-2">
                  Page Not Found
                </div>
              </v-row>
            </v-container>
          </v-row>
          <v-row justify="center">
            <div class="title text-center">
              Sorry, the requested page or resource was not found.<br/>
              We may have moved some things around and the URL you were given is no longer valid.<br/>
              Try clicking through the menus to where you want to go.
            </div>
          </v-row>
          <v-row justify="center">
            <div class="font-weight-light">
              <a href="/">Go Back to the Dashboard</a>
            </div>
          </v-row>
      </v-container>
    </v-content>
</template>

<script>
export default {};
</script>
