import axios from "@/utils/AxiosInstance.js";

export const setup = {
  namespaced: true,
  state: {
    setupData: {},
    setupDataLoadingStatus: 'notLoading',
    setupDataLoadingError: '',
    setupDataLoadingErrorStatus: false,
  },
  getters: {
    setupData: state => state.setupData,
    setupDataLoadingStatus: state => state.setupDataLoadingStatus,
    setupDataLoadingError: state => state.setupDataLoadingError,
    setupDataLoadingErrorStatus: state => state.setupDataLoadingErrorStatus,
  },
  mutations: {
    SET_SETUP_DATA(state, setupData) {
      state.setupData = setupData
    },
    SET_SETUP_DATA_LOADING_STATUS(state, status) {
      state.setupDataLoadingStatus = status
    },
    SET_SETUP_DATA_LOADING_ERROR(state, error) {
      state.setupDataLoadingError = error
    },
    SET_SETUP_DATA_LOADING_ERROR_STATUS(state, status) {
      state.setupDataLoadingErrorStatus = status
    },
  },
  actions: {
    retrieveSetupData(context) {
      context.commit("SET_SETUP_DATA_LOADING_STATUS", 'loading');
      // prevent a race condition if a request is being made before
      // there's a bearer token or setupid, we're going to just set
      // an error and return so the rest of the app can do its work
      if (!localStorage.getItem("bearer")) {
        context.commit("SET_SETUP_DATA_LOADING_STATUS", "failed");
        context.commit("SET_SETUP_DATA", {});
        context.commit("SET_SETUP_DATA_LOADING_ERROR_STATUS", true);
        context.commit("SET_SETUP_DATA_LOADING_ERROR", 'missing setupid or bearer token');
      } else {
        axios
          .get(process.env.VUE_APP_SETUP)
          .then(response => {
            context.commit("SET_SETUP_DATA", response.data.data[0]);
            context.commit("SET_SETUP_DATA_LOADING_ERROR_STATUS", false);
            context.commit("SET_SETUP_DATA_LOADING_ERROR", '');
            context.commit("SET_SETUP_DATA_LOADING_STATUS", "loaded");
            // console.log('# SUCCESS vuex loaded setup data', response);
          })
          .catch(function (error) {
            context.commit("SET_SETUP_DATA_LOADING_STATUS", "failed");
            context.commit("SET_SETUP_DATA", {});
            context.commit("SET_SETUP_DATA_LOADING_ERROR_STATUS", true);
            let error_message = ''
            if(error.hasOwnProperty('response')) {
              if(typeof error.response != 'undefined') {
                if (error.response.hasOwnProperty('data')) {
                  if (error.response.data.hasOwnProperty('message')) {
                    error_message = error.response.data.message
                  } else if(error.response.data.hasOwnProperty('error')) {
                    if(error.response.data.error.hasOwnProperty('message')) {
                      error_message = error.response.data.error.message
                    } else {
                      error_message = JSON.stringify(error.response.data.error)
                    }
                  } else {
                    error_message = JSON.stringify(error.response.data)
                  }
                } else if(error.response.hasOwnProperty('message')) {
                  error_message = error.response.message
                } else {
                  error_message = JSON.stringify(error.response)
                }
              } else {
                error_message = "unable to connect to " + error.config.url
              }
            } else if(error.hasOwnProperty('message')) {
              error_message = error.message
            } else {
              error_message = JSON.stringify(error)
            }
            context.commit("SET_SETUP_DATA_LOADING_ERROR",  'retrieveSetupData(): ' + error_message);
            // console.log('# ERROR vuex loading setup data', error);
          });
      }
    },
    resetSetupData(context) {
      context.commit("SET_SETUP_DATA", {});
      context.commit("SET_SETUP_DATA_LOADING_STATUS", "notloading");
      context.commit("SET_SETUP_DATA_LOADING_ERROR", '');
      context.commit("SET_SETUP_DATA_LOADING_ERROR_STATUS", false);
    }
  }
}
