<template>
  <v-container
    v-if="scorecardIsProcessing"
    :class="paddingTop"
    pb-0
    fluid
    pr-12
    pl-12
  >
    <v-row no-gutters>
      <v-col>
        <v-alert :type="'warning'" height="75%">
          {{ scorecardProcessingMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    type: String
  },
  data: () => ({
    scorecardProcessingMessage: process.env.VUE_APP_SCORECARD_PROCESSING_MESSAGE
  }),
  methods: {
    agentLevelCheck() {
      return (
        this.aggregatedScorecardAgentDataLoadingStatus == "loaded" &&
        !this.aggregatedScorecardAgentDataLoadingError &&
        this.aggregatedScorecardAgentData.processing > 0
      );
    },
    dynalabelLevelCheck() {
      return (
        this.aggregatedScorecardDynalabelDataLoadingStatus == "loaded" &&
        !this.aggregatedScorecardDynalabelDataLoadingError &&
        this.aggregatedScorecardDynalabelData.processing > 0
      );
    },
    callLevelCheck() {
      return (
        this.scorecardV2DataLoadingStatus == "loaded" &&
        !this.scorecardV2DataLoadingError &&
        this.scorecardV2Data.processing > 0
      );
    }
  },
  computed: {
    ...mapGetters("scorecardsV2Aggregates", [
      "aggregatedScorecardAgentData",
      "aggregatedScorecardAgentDataLoadingStatus",
      "aggregatedScorecardAgentDataLoadingError",
      "aggregatedScorecardAgentDataLoadingErrorStatus",

      "aggregatedScorecardDynalabelData",
      "aggregatedScorecardDynalabelDataLoadingStatus",
      "aggregatedScorecardDynalabelDataLoadingError",
      "aggregatedScorecardDynalabelDataLoadingErrorStatus"
    ]),
    ...mapGetters("scorecardsV2Calls", [
      "scorecardV2Data",
      "scorecardV2DataLoadingStatus",
      "scorecardV2DataLoadingError",
      "scorecardV2DataLoadingErrorStatus"
    ]),
    paddingTop() {
      return this.type == "call" ? "pt-4" : "pt-8";
    },
    scorecardIsProcessing() {
      switch (this.type) {
        case "agent":
          return this.agentLevelCheck();
        case "dynalabel":
          return this.dynalabelLevelCheck();
        default:
          return this.callLevelCheck();
      }
    }
  }
};
</script>

<style>
.big-type {
  font-size: 5rem !important;
}
</style>
