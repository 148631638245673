import axios from "@/utils/AxiosInstance.js";

export const resources = {
  namespaced: true,
  state: {

    accountData: [],
    accountDataLoadingStatus: 'notLoading',
    accountDataLoadingError: '',
    accountDataLoadingErrorStatus: false,

    accountHorizon: process.env.VUE_APP_DEFAULT_HORIZON,
    accountHorizonLoadingStatus: 'notLoading',
    accountHorizonLoadingError: '',
    accountHorizonLoadingErrorStatus: false,

    agentData: [],
    agentDataLoadingStatus: 'notLoading',
    agentDataLoadingError: '',
    agentDataLoadingErrorStatus: false,

    searchData: [],
    searchDataLoadingStatus: 'notLoading',
    searchDataLoadingError: '',
    searchDataLoadingErrorStatus: false,

    updateAgentEmailError: '',
    updateAgentEmailErrorStatus: false,

  },
  getters: {

    accountData: state => state.accountData,
    accountDataLoadingStatus: state => state.accountDataLoadingStatus,
    accountDataLoadingError: state => state.accountDataLoadingError,
    accountDataLoadingErrorStatus: state => state.accountDataLoadingErrorStatus,

    accountHorizon: state => state.accountHorizon,
    accountHorizonLoadingStatus: state => state.accountHorizonLoadingStatus,
    accountHorizonLoadingError: state => state.accountHorizonLoadingError,
    accountHorizonLoadingErrorStatus: state => state.accountHorizonLoadingErrorStatus,

    agentData: state => state.agentData,
    agentDataLoadingStatus: state => state.agentDataLoadingStatus,
    agentDataLoadingError: state => state.agentDataLoadingError,
    agentDataLoadingErrorStatus: state => state.agentDataLoadingErrorStatus,

    searchData: state => state.searchData,
    searchDataLoadingStatus: state => state.searchDataLoadingStatus,
    searchDataLoadingError: state => state.searchDataLoadingError,
    searchDataLoadingErrorStatus: state => state.searchDataLoadingErrorStatus,

    updateAgentEmailError: state => state.updateAgentEmailError,
    updateAgentEmailErrorStatus: state => state.updateAgentEmailErrorStatus,


  },
  mutations: {

    SET_ACCOUNT_DATA(state, accountData) {
      state.accountData = accountData
    },
    SET_ACCOUNT_DATA_LOADING_STATUS(state, status) {
      state.accountDataLoadingStatus = status
    },
    SET_ACCOUNT_DATA_LOADING_ERROR(state, error) {
      state.accountDataLoadingError = error
    },
    SET_ACCOUNT_DATA_LOADING_ERROR_STATUS(state, status) {
      state.accountDataLoadingErrorStatus = status
    },

    SET_ACCOUNT_HORIZON(state, accountHorizon) {
      state.accountHorizon = accountHorizon
    },
    SET_ACCOUNT_HORIZON_LOADING_STATUS(state, status) {
      state.accountHorizonLoadingStatus = status
    },
    SET_ACCOUNT_HORIZON_LOADING_ERROR(state, error) {
      state.accountHorizonLoadingError = error
    },
    SET_ACCOUNT_HORIZON_LOADING_ERROR_STATUS(state, status) {
      state.accountHorizonLoadingErrorStatus = status
    },

    SET_AGENT_DATA(state, agentData) {
      state.agentData = agentData
    },
    SET_AGENT_DATA_LOADING_STATUS(state, status) {
      state.agentDataLoadingStatus = status
    },
    SET_AGENT_DATA_LOADING_ERROR(state, error) {
      state.agentDataLoadingError = error
    },
    SET_AGENT_DATA_LOADING_ERROR_STATUS(state, status) {
      state.agentDataLoadingErrorStatus = status
    },

    SET_SEARCH_DATA(state, searchData) {
      state.searchData = searchData
    },
    SET_SEARCH_DATA_LOADING_STATUS(state, status) {
      state.searchDataLoadingStatus = status
    },
    SET_SEARCH_DATA_LOADING_ERROR(state, error) {
      state.searchDataLoadingError = error
    },
    SET_SEARCH_DATA_LOADING_ERROR_STATUS(state, status) {
      state.searchDataLoadingErrorStatus = status
    },

    SET_UPDATE_AGENT_EMAIL_ERROR(state, error) {
      state.updateAgentEmailError = error
    },
    SET_UPDATE_AGENT_EMAIL_ERROR_STATUS(state, status) {
      state.updateAgentEmailErrorStatus = status
    },

  },
  actions: {

    retrieveAccountData(context) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_ACCOUNT_DATA_LOADING_STATUS", 'loading');
      axios
        .get(process.env.VUE_APP_AUTH_ACCOUNTS, { params: { paginate: false } })
        .then(response => {
          context.commit("SET_ACCOUNT_DATA", response.data.data);
          context.commit("SET_ACCOUNT_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_ACCOUNT_DATA_LOADING_ERROR", '');
          context.commit("SET_ACCOUNT_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_ACCOUNT_DATA_LOADING_STATUS", "failed");
          context.commit("SET_ACCOUNT_DATA", {});
          context.commit("SET_ACCOUNT_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_ACCOUNT_DATA_LOADING_ERROR",  'retrieveAccountData(): ' + error_message);
        });

    },

    retrieveAccountHorizon(context) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_ACCOUNT_HORIZON_LOADING_STATUS", 'loading');
      axios
        .get(process.env.VUE_APP_AUTH_HORIZON)
        .then(response => {
          localStorage.setItem("accountHorizon", response.data.horizon);
          context.commit("SET_ACCOUNT_HORIZON", response.data.horizon);
          context.commit("SET_ACCOUNT_HORIZON_LOADING_ERROR_STATUS", false);
          context.commit("SET_ACCOUNT_HORIZON_LOADING_ERROR", '');
          context.commit("SET_ACCOUNT_HORIZON_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_ACCOUNT_HORIZON_LOADING_STATUS", "failed");
          context.commit("SET_ACCOUNT_HORIZON_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_ACCOUNT_HORIZON_LOADING_ERROR",  'retrieveAccountHorizon(): ' + error_message);
        });

    },

    retrieveAgentData(context, payload) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      let params = { params: { paginate: false } }
      params.params.email = payload.email || false

      context.commit("SET_AGENT_DATA_LOADING_STATUS", 'loading');
      axios
        .get(process.env.VUE_APP_AUTH_AGENTS, params)
        .then(response => {
          context.commit("SET_AGENT_DATA", response.data.data);
          context.commit("SET_AGENT_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_AGENT_DATA_LOADING_ERROR", '');
          context.commit("SET_AGENT_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_AGENT_DATA_LOADING_STATUS", "failed");
          context.commit("SET_AGENT_DATA", {});
          context.commit("SET_AGENT_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_AGENT_DATA_LOADING_ERROR",  'retrieveAgentData(): ' + error_message);
        });

    },

    retrieveSearchListData(context) {

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/

      context.commit("SET_SEARCH_DATA_LOADING_STATUS", 'loading');
      axios
        .get(process.env.VUE_APP_SEARCH_LIST)
        .then(response => {
          context.commit("SET_SEARCH_DATA", response.data.data);
          context.commit("SET_SEARCH_DATA_LOADING_ERROR_STATUS", false);
          context.commit("SET_SEARCH_DATA_LOADING_ERROR", '');
          context.commit("SET_SEARCH_DATA_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_SEARCH_DATA_LOADING_STATUS", "failed");
          context.commit("SET_SEARCH_DATA", {});
          context.commit("SET_SEARCH_DATA_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_SEARCH_DATA_LOADING_ERROR",  'retrieveSearchData(): ' + error_message);
        });

    },

    updateAgentEmail(context, params) {

      axios
        .post(process.env.VUE_APP_AUTH_AGENTS_EMAIL, { 'id':params.id, 'email':params.email })
        .then(response => {
          if(response.status == 200) {
            context.dispatch('retrieveAgentData', {email: true})
          }
        })
        .catch(function (error) {
          let error_message = JSON.stringify(error)
          context.commit("SET_UPDATE_AGENT_EMAIL_ERROR_STATUS", true);
          context.commit("SET_UPDATE_AGENT_EMAIL_ERROR",  'updateAgentEmail(): ' + error_message);
        });

    }

  }
}
