<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="9">
        <v-container v-show="dataSetsLoaded" ref="timeSeriesChart">
          <echart :option="eChartOptions" autoresize :style="{ 'height': chartHeight - 60 + 'px' }" ref="eChartObject" @legendselectchanged="updateLegendState" />
        </v-container>
        <v-alert :type="trendsLoadingType" prominent v-show="!chartIsReady">
          <v-row class="d-flex">
            <v-col class="d-flex" v-html="trendsLoadingMessage"></v-col>
            <v-col v-if="trendsLoadingType == 'loading'" class="d-flex justify-end"
            ><v-progress-circular
              size="24"
              indeterminate
            ></v-progress-circular
            ></v-col>
          </v-row>
        </v-alert>
        <v-container v-show="dataSetsLoaded" class="text-center d-flex flex-column align-center justify-center">
          <v-card elevation="0">
            <v-card-text>
              <v-btn-toggle
                v-model="timeSpanControl"
                mandatory
              >
                <v-btn value="sevenday" small :title="((timeSpanControl=='sevenday') ? '7-Day Data' : 'Switch to 7-Day Data')" :hidden="disableSevenDay">
                  7-Day
                </v-btn>
                <v-btn value="day" small :title="((timeSpanControl=='day') ? 'Daily Data' : 'Switch to Daily Data')" :hidden="disableDay">
                  Daily
                </v-btn>
                <v-btn value="hour" small :title="((timeSpanControl=='hour') ? 'Hourly Data' : 'Switch to Hourly Data')" :hidden="disableHour">
                  Hourly
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="3" class="pl-12">
        <v-select
          ref="agentSelector"
          class="mt-4 mb-12"
          v-model="currentSelectedAgentIds"
          @input="sortSelected"
          multiple
          hint="Select All Agents AND up to two individual agents"
          label="Agents"
          persistent-hint
          :items="sortedAgents"
          item-text="agentName"
          item-value="agentId"
          :menu-props="{ maxHeight: '400' }"
          no-data-text="Loading agent list, please wait"
        >
          <template v-slot:prepend-item>
            <v-list-item v-show="agentsCopy.length>2">
              <v-list-item-content>
                <v-text-field v-model="agentSearch" placeholder="Find Agents" @input="searchAgents" clearable append-icon="mdi-magnify"></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2" v-show="agents.length>2"></v-divider>
          </template>
        </v-select>
        <v-select
          class="mt-4 mb-12"
          v-model="currentSelectedTrend"
          @input="trendSelected"
          hint="Select a trend"
          label="Trends"
          persistent-hint
          :items="trends"
          item-text="name"
          item-value="id"
          :menu-props="{ maxHeight: '400' }"
        ></v-select>

        <v-select
          v-show="currentSelectedTrend==1"
          class="mt-4 mb-12"
          v-model="selectedCategory"
          @input="categorySelected"
          hint="Select a category"
          label="Categories"
          persistent-hint
          :items="categories"
          item-text="categoryName"
          item-value="categoryId"
          :menu-props="{ maxHeight: '400' }"
          no-data-text="Loading categories, please wait"
        ></v-select>

        <v-btn class="mt-6 mb-6" color="primary" @click="retrieveData" :disabled="disableMe">update chart</v-btn>


      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import colors from "@/mixins/colors"
import moment from "moment"
import CacheHandler from '@/utils/CacheHandler'
import {mapActions, mapGetters} from "vuex"

export default {
  mixins: [colors],
  props: {
    dynalabelId: String,
    agentId: String,
    scorecardId: String,
  },
  data() {
    return {
      agentSearch: "",
      dataSetsLoaded: false,
      agentSeriesData: [],
      chartWidth: 400,
      chartHeight: 460,
      dataset: {},
      timeSpanControl: 'day',
      dateRangeDays: 0,
      selectedAgentIds: [],
      currentSelectedAgentIds: [],
      agents: [],
      agentsCopy: [],
      selectedTrend: 0,
      currentSelectedTrend: 0,
      trends: [
        { id: 0, name: "Call Score", endpoint: "call-score", dataSource: "callScore", trendType: 'percent' },
        { id: 1, name: "Category Score", endpoint: "category-score", dataSource: "categoryScore", trendType: 'percent' },
        { id: 2, name: "Talk Time", endpoint: "call-duration", dataSource: "duration", trendType: 'seconds' },
      ],
      selectedCategory: 0,
      categories: [],
      series: [],
      xaxis: [],
      xAxisCategories: [],
      title: {
        text: 'Graph Title Goes Here',
        align: 'left',
        offsetX: 110
      },
      colorList: [
        '#4b699f',
        '#669aa2',
        '#73b9bc',
        '#7a8da9',
      ],
      disableMe: true,
      legendState: {},
      englishTime: {
        'day':'Day',
        'hour': 'Hour',
        'sevenday': '7-Days'
      }
    }
  },
  computed: {
    sortedAgents() {
      let tippyTop = this.agents.filter(o => o.agentId === 0)
      let topOfList = this.agents.filter(o => this.currentSelectedAgentIds.includes(o.agentId) || o.agentId === 0)
      let bottomOfList = this.agents.filter(o => !this.currentSelectedAgentIds.includes(o.agentId) && o.agentId !== 0)
      return tippyTop.concat(topOfList.sort((a, b) => a.agentName.localeCompare(b.agentName)).concat(bottomOfList.sort((a, b) => a.agentName.localeCompare(b.agentName))))
    },
    getGroupingDefault() {
      let grouping = (this.dateRangeDays <= 2) ? 'hour' : 'day'
      return grouping
    },
    chartIsReady() {
      return (
        this.scorecardTrendsDataLoadingStatus == "loaded"
        && this.scorecardCallVolumeDataLoadingStatus == "loaded"
        && this.scorecardAllAgentTrendsDataLoadingStatus == "loaded"
        && this.scorecardAllAgentCallVolumeDataLoadingStatus == "loaded"
        && this.scorecardAgentsListLoadingStatus == "loaded"
        && this.scorecardV2DataLoadingStatus == "loaded"
      )
    },
    computeColorSet() {
      let colors = []
      let index = 1
      this.agents.forEach((agent) => {
        if(this.selectedAgentIds.indexOf(agent.agentId) !== -1) {
          if(agent.agentId===0) {
            colors.push(this.colorList[0]) // all agents trend color
            colors.push(this.colorList[0]) // all agents calls/day color
          } else {
            colors.push(this.colorList[index]) // trend color
            colors.push(this.colorList[index++]) // calls/day color
          }
        }
      })
      return colors
    },
    dataSource() {
      return this.trends[this.currentSelectedTrend].dataSource
    },
    trendsLoadingType() {
      if (this.scorecardTrendsDataLoadingErrorStatus||this.scorecardCallVolumeDataLoadingErrorStatus||this.scorecardAllAgentTrendsDataLoadingErrorStatus||this.scorecardAllAgentCallVolumeDataLoadingErrorStatus) {
        return "error";
      } else {
        return "info";
      }
    },
    trendsLoadingMessage() {
      if (this.scorecardTrendsDataLoadingErrorStatus||this.scorecardCallVolumeDataLoadingErrorStatus||this.scorecardAllAgentTrendsDataLoadingErrorStatus||this.scorecardAllAgentCallVolumeDataLoadingErrorStatus) {
        let message = "There was an error loading";
        message += (this.scorecardTrendsDataLoadingErrorStatus) ? " agent specific trends data," : ""
        message += (this.scorecardCallVolumeDataLoadingErrorStatus) ? " agent specific call volume data," : ""
        message += (this.scorecardAllAgentTrendsDataLoadingErrorStatus) ? " all agent trends data," : ""
        message += (this.scorecardAllAgentCallVolumeDataLoadingErrorStatus) ? " all agent call volume data," : ""
        return message.slice(0, -1) + "...";
      } else return "Call trends loading, please wait...";
    },
    eChartOptions() {
      let myThis = this
      return {
        legend: this.eChartLegend,
        color: this.computeColorSet,
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let trendType = myThis.trends[myThis.selectedTrend].trendType
            let tooltipLegend = ''
            let val = params[0].name
            switch(myThis.getUnit) {
              case 'hour': {
                let dateParts = val.split(' ')
                let day = dateParts[0]
                let hour = parseInt(dateParts[1])
                tooltipLegend = moment(day).format("MMMM D") + ' '
                if (hour == 0) tooltipLegend += 'Midnight'
                else tooltipLegend += (hour == 12) ? '12pm' : (hour > 12) ? hour - 12 + 'pm' : hour + 'am'
                break;
              }
              case 'sevenday':
                tooltipLegend = val
                break
              case 'day':
              default:
                tooltipLegend = val.split(' ')[0]
            }

            let html = `<table class='custom-tooltip'>
    <thead>
        <tr>
            <th colspan="3" style="text-align: left;">${tooltipLegend}</th>
        </tr>
    </thead>
    <tbody>`
            for (const [i, o] of params.entries()) {
              let v = (typeof o.value === "undefined") ? 0 : o.value
              // are we the regular entries
              if(!o.seriesName.includes('Calls/Day')&&!o.seriesName.includes('Calls/Hour')&&!o.seriesName.includes('Total Calls')) {
                switch (trendType) {
                  case 'percent':
                    html += `<tr>
            <td style="text-align: left; font-weight: 700; color: ${o.color};" data-index="${i}">${o.seriesName}</td>
            <td>&nbsp;</td>
            <td style="text-align: right;">${Math.round(v * 100)}%</td>
          </tr>`
                    break
                  case 'seconds':
                    html += `<tr>
            <td style="text-align: left; font-weight: 700; color: ${o.color};">${o.seriesName}</td>
            <td>&nbsp;</td>
            <td style="text-align: right;">${myThis.formatHMS(v)}</td>
          </tr>`
                    break
                }
                // or on the call volume (last entry)
              } else {
                html += `<tr>
            <td style="text-align: left; font-weight: 700; color: ${o.color};">${o.seriesName}</td>
            <td>&nbsp;</td>
            <td style="text-align: right;">${Math.round(v)}</td>
          </tr>`
              }

            }

            html += `    </tbody>
  </table>`

            return html
          }
        },
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
        aria: {
          enabled: true,
          decal: {
            show: true,
            decals: [
              {
                color: 'green',
                dashArrayX: [
                  1,
                  0
                ],
                dashArrayY: [
                  2,
                  5
                ],
                symbolSize: 1,
                rotation: Math.PI
                  /
                  6
              },
              {
                color: 'red',
                symbol: 'circle',
                dashArrayX: [
                  [
                    8,
                    8
                  ],
                  [
                    0,
                    8,
                    8,
                    0
                  ]
                ],
                dashArrayY: [
                  6,
                  0
                ],
                symbolSize: 0.8
              }
            ]
          }
        },
      }
    },

    eChartLegend() {
      let obj = { selected: {} }
      this.series.forEach( (o) => {
        if(o.name.includes('Calls/Day')||o.name.includes('Calls/Hour')) {
          obj.selected[eval("'" + o.name + "'")] = (this.legendState.hasOwnProperty(eval("'" + o.name + "'"))) ? this.legendState[eval("'" + o.name + "'")] : false
        } else {
          obj.selected[eval("'" + o.name + "'")] = (this.legendState.hasOwnProperty(eval("'" + o.name + "'"))) ? this.legendState[eval("'" + o.name + "'")] : true
        }
      })
      let chartLegend = (this.series) ? { data: this.series.map( o => o.name), selected: obj.selected } : {}
      return chartLegend
    },

    xAxis() {
      let xAxisCategories = []
      switch (this.timeSpanControl) {
        case "day": // build an empty x-axis categories array by day
          for(let i=0; i<this.dateRangeDays; i++) {
            let key = moment(this.start_date).add(i,'days').format("M/D")
            xAxisCategories.push(key)
          }
          break
        case "hour": // build an empty x-axis categories array by day-hour
          for(let i=0; i<this.dateRangeDays; i++) {
            let key = moment(this.start_date).add(i,'days').format("M/D")
            for(let h=0; h<24; h++) {
              let hourString = (h<10) ? '0'+h : h
              let newkey = key + ' ' + hourString
              xAxisCategories.push(newkey)
            }
          }
          break
        case "sevenday": // build an empty x-axis categories array by 7-Day blocks
          for(let i=0; i<this.dateRangeDays; i+=7) {
            let start = moment(this.start_date).add(i,'days').format("M/D")
            let inc = (i + 7 >= this.dateRangeDays) ? (this.dateRangeDays - 1) : (i + 6)
            let end = (inc>1) ? ' — ' + moment(this.start_date).add(inc,'days').format("M/D") : ''
            let key = start + end
            if(!xAxisCategories.find(o=> o.date == key)) xAxisCategories.push(key)
          }
          break
      }

      let xAxis = [{
        type: 'category',
        boundaryGap: true,
        data: xAxisCategories,
        axisLabel: this.xAxisLabelFormatter,
        name: 'Date/Time',
        nameLocation: 'center',
        nameGap: 50,
        nameTextStyle: {
          fontWeight: 'bold'
        }
      }]
      return xAxis
    },

    yAxis() {
      let yAxis = []
      switch(this.selectedTrend) {
        case 0: // call score
          yAxis = [
            {
              name: 'Call Score (percent)',
              nameLocation: 'middle',
              nameGap: 45,
              min: 0,
              max: 1,
              nameTextStyle: {
                fontWeight: 'bold'
              },
              type: 'value',
              axisLabel: {
                formatter: (v) => {
                  return (v) ? Math.round(v*100) + '%' : '0%';
                }
              },
              axisLine: {
                onZero: false,
              },
              offset: 1,
              position: 'left',
            },
            {
              name: 'Calls/' + this.englishTime[this.timeSpanControl],
              nameLocation: 'middle',
              nameGap: 50,
              nameTextStyle: {
                fontWeight: 'bold',
                padding: 20,
              },
              type: 'value',
              axisLabel: {
                formatter: '{value} calls'
              },
              position: 'right',
              alignTicks: true,
              axisLine: {
                onZero: false,
              },
            }
          ]
          break
        case 1: // category score
          yAxis = [
            {
              name: 'Category Score (percent)',
              nameLocation: 'middle',
              nameGap: 45,
              min: 0,
              max: 1,
              nameTextStyle: {
                fontWeight: 'bold'
              },
              type: 'value',
              axisLabel: {
                formatter: (v) => {
                  return (v) ? Math.round(v*100) + '%' : '0%';
                }
              },
              axisLine: {
                onZero: false,
              },
              offset: 1,
              position: 'left',
            },
            {
              name: 'Calls/' + this.englishTime[this.timeSpanControl],
              nameLocation: 'middle',
              nameGap: 50,
              nameTextStyle: {
                fontWeight: 'bold',
                padding: 20,
              },
              type: 'value',
              axisLabel: {
                formatter: '{value} calls'
              },
              position: 'right',
              alignTicks: true,
              axisLine: {
                onZero: false,
              },
            }
          ]
          break
        case 2: // talk time
          yAxis = [
            {
              name: 'Talk Time (h:m:s)',
              nameLocation: 'middle',
              nameGap: 45,
              nameTextStyle: {
                fontWeight: 'bold'
              },
              type: 'value',
              axisLabel: {
                formatter: (v) => {
                  return this.formatHMS(v)
                }
              },
              axisLine: {
                onZero: false,
              },
              offset: 1,
              position: 'left',
            },
            {
              name: 'Calls/' + this.englishTime[this.timeSpanControl],
              nameLocation: 'middle',
              nameGap: 50,
              nameTextStyle: {
                fontWeight: 'bold',
                padding: 20,
              },
              type: 'value',
              axisLabel: {
                formatter: '{value} calls'
              },
              position: 'right',
              alignTicks: true,
              axisLine: {
                onZero: false,
              },
            }
          ]
          break
      }
      return yAxis
    },

    xAxisLabelFormatter() {
      let formatter = {}
      switch(this.getUnit) {
        case 'hour':
          formatter = {
            'formatter':
              value => {
                if (value === undefined) return null
                let dateParts = value.split(' ')
                let day = dateParts[0]
                let hour = parseInt(dateParts[1])
                let hourFormatted = (hour == 12) ? '12 pm' : (hour > 12) ? hour - 12 + ' pm' : hour + ' am'
                if (hour == '00') return moment(day).format("MMM D")
                else return hourFormatted
              },
            'rotateAlways': true,
            'rotate': 45,
          }
          break
        case 'sevenday':
          break
        case 'day':
        default:
          formatter = {
            'formatter':
              value => {
                if (value) return value.split(' ')[0]
              },
          }
      }
      return formatter
    },

    ...mapGetters("dateRange", [
      "start_date",
      "end_date"
    ]),
    ...mapGetters("scorecardsV2", [
      "scorecardAgentsList",
      "scorecardAgentsListLoadingStatus",
      "scorecardAgentsListLoadingError",
      "scorecardAgentsListLoadingErrorStatus",
    ]),
    ...mapGetters("scorecardsV2Calls",[
      "scorecardTrendsData",
      "scorecardTrendsDataLoadingStatus",
      "scorecardTrendsDataLoadingError",
      "scorecardTrendsDataLoadingErrorStatus",
      "scorecardCallVolumeData",
      "scorecardCallVolumeDataLoadingStatus",
      "scorecardCallVolumeDataLoadingError",
      "scorecardCallVolumeDataLoadingErrorStatus",
      "scorecardAllAgentTrendsData",
      "scorecardAllAgentTrendsDataLoadingStatus",
      "scorecardAllAgentTrendsDataLoadingError",
      "scorecardAllAgentTrendsDataLoadingErrorStatus",
      "scorecardAllAgentCallVolumeData",
      "scorecardAllAgentCallVolumeDataLoadingStatus",
      "scorecardAllAgentCallVolumeDataLoadingError",
      "scorecardAllAgentCallVolumeDataLoadingErrorStatus",
      "scorecardV2Data",
      "scorecardV2DataLoadingStatus",
      "scorecardV2DataLoadingError",
      "scorecardV2DataLoadingErrorStatus",
    ]),

    getUnit() {
      return this.timeSpanControl
    },
    disableSevenDay() {
      return this.dateRangeDays<8
    },
    disableDay() {
      return this.dateRangeDays<2
    },
    disableHour() {
      return this.dateRangeDays>2
    },
    getChartWidth() {
      return this.chartWidth;
    },
    getChartHeight() {
      return this.chartHeight;
    },
  },
  methods: {
    ...mapActions("scorecardsV2",[
      "retrieveScorecardAgentsList",
    ]),
    ...mapActions("scorecardsV2Calls",[
      "retrieveScorecard",
      "retrieveScorecardTrends",
      "retrieveScorecardCallVolume",
      "retrieveScorecardAllAgentTrends",
      "retrieveScorecardAllAgentCallVolume",
    ]),
    searchAgents() {
      let agentList = []
      if (!this.agentSearch) {
        agentList = this.agentsCopy;
      } else {
        agentList = this.agentsCopy.filter((agent) => {
          return (agent['agentName'].toLowerCase().indexOf(this.agentSearch.toLowerCase()) > -1 || agent['agentId'] == 0);
        });
      }
      this.agents = agentList
    },
    updateLegendState(params) {
      this.legendState[params.name] = params.selected[params.name]
    },
    sortSelected() {
      this.agentSearch = ""
      let newOrderedList = []
      this.agentsCopy.forEach((agent) => {
        if (this.currentSelectedAgentIds.indexOf(agent.agentId) !== -1) {
          newOrderedList.push(agent.agentId)
        }
      })
      this.currentSelectedAgentIds = [...newOrderedList]
      // shuffle selected to top of list
      let topOfList = this.agentsCopy.filter(o => this.currentSelectedAgentIds.includes(o.agentId) || o.agentId === 0);
      let bottomOfList = this.agentsCopy.filter(o => !this.currentSelectedAgentIds.includes(o.agentId) && o.agentId !== 0)
      this.agents = topOfList.sort((a, b) => a.agentName.localeCompare(b.agentName)).concat(bottomOfList.sort((a, b) => a.agentName.localeCompare(b.agentName)))
      this.disableMe = (this.currentSelectedAgentIds.length==0) ? true : false
    },
    trendSelected() {
      this.disableMe = false
    },
    categorySelected() {
      this.disableMe = false
    },
    formatHMS(seconds) {
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    updateTimeSpanControl() {
      switch (this.timeSpanControl) {
        case "day":
          if(this.dateRangeDays==1) this.timeSpanControl = 'hour'
          break
        case "hour":
          if(this.dateRangeDays>2) this.timeSpanControl = 'day'
          break
        case "sevenday":
          if(this.dateRangeDays<8) this.timeSpanControl = 'day'
          break
        default:
          if(this.dateRangeDays<=2) this.timeSpanControl = 'hour'
          else this.timeSpanControl = 'day'
          break
      }
      this.updateDataset()
    },
    computeDateRangeDays() {
      let start_date = moment(this.start_date)
      let end_date = moment(this.end_date)
      this.dateRangeDays = end_date.diff(start_date,'days') + 1
    },
    getXAxisCategories() {
      let xAxisCategories = []
      switch (this.timeSpanControl) {
        case "day": // build an empty x-axis categories array by day
          for(let i=0; i<this.dateRangeDays; i++) {
            let key = moment(this.start_date).add(i,'d').format("YYYY-MM-DD")
            xAxisCategories.push({ date: key })
          }
          break
        case "hour": // build an empty x-axis categories array by day-hour
          for(let i=0; i<this.dateRangeDays; i++) {
            let key = moment(this.start_date).add(i,'d').format("YYYY-MM-DD")
            for(let h=0; h<24; h++) {
              let hourString = (h<10) ? '0'+h : h
              let newkey = key + ' ' + hourString
              xAxisCategories.push({ date: newkey })
            }
          }
          break
        case "sevenday": // build an empty x-axis categories array by 7-Day blocks
          for(let i=0; i<this.dateRangeDays; i+=7) {
            let key = moment(this.start_date).add(i,'d').format("YYYY-MM-DD")
            if(!xAxisCategories.find(o=> o.date == key)) xAxisCategories.push({ date: key })
          }
          break
      }
      return xAxisCategories
    },
    updateDataset() {
      this.xAxisCategories = this.getXAxisCategories()
      this.xaxis = this.xAxisCategories.map(o=>o.date)
      switch(this.selectedTrend) {
        case 0: // call score
          this.series = this.chartSeriesData('Call Score', 'percent')
          break
        case 1: // category score
          this.series = this.chartSeriesData('Category Score', 'percent')
          break
        case 2: // talk time
          this.series = this.chartSeriesData('Talk Time', 'value')
          break
      }
      this.computeChartDimensions()
    },
    chartSeriesData(name,type) {
      let series = []
      let rawSeriesData = []
      let rawCallVolumeData = []
      let rawCallCountData = []
      let rawAgentCountData = []
      let formattedSeriesData = []
      let formattedCallVolumeData = []
      if(!this.agentSeriesData.hasOwnProperty('callVolume')) return []
      this.agents.forEach((agent) => {
        // loop through all agents but only process the selected ones
        if (this.currentSelectedAgentIds.indexOf(agent.agentId) !== -1) {
          // get raw data
          rawSeriesData = this.agentSeriesData[this.dataSource]['agent' + agent.agentId]
          rawCallVolumeData = this.agentSeriesData['callVolume']['agent' + agent.agentId]
          rawCallCountData = this.agentSeriesData['callCount']['agent' + agent.agentId]
          rawAgentCountData = this.agentSeriesData['uniqueAgents']['agent' + agent.agentId]
          // special handling for roll-ups
          if(this.timeSpanControl == 'sevenday') {
            // if time span is set to sevenday, we need to roll up the data in seven-day blocks
            rawSeriesData = this.weightedAverageByCount(rawSeriesData,rawCallVolumeData,7)
            if(agent.agentId == 0) {
              rawCallVolumeData = this.averageAllAgentByCount(rawCallVolumeData,rawCallCountData,rawAgentCountData,7)
            } else {
              rawCallVolumeData = this.averageByCount(rawCallVolumeData,7)
            }
          } else if(this.timeSpanControl == 'day' && this.dateRangeDays>2) {
            if(agent.agentId == 0) {
              rawCallVolumeData = this.averageAllAgentByCount(rawCallVolumeData,rawCallCountData,rawAgentCountData,1)
            }
          } else if(this.timeSpanControl == 'day' && this.dateRangeDays<=2) {
            // else if we have two days or less AND time span is set to day, we need to roll up the 24-hour blocks
            rawSeriesData = this.weightedAverageByCount(rawSeriesData,rawCallVolumeData,24)
            if(agent.agentId == 0) {
              rawCallVolumeData = this.callsPerDayHoursToDays(rawCallVolumeData,rawCallCountData,rawAgentCountData,24)
            } else {
              rawCallVolumeData = this.sumByCountHoursToDays(rawCallVolumeData,24)
            }
          } else if(this.timeSpanControl == 'hour' && this.dateRangeDays<=2) {
            // else if we have two days or less AND time span is set to hours, we need to fix the all agent call volume data
            if(agent.agentId == 0) {
              rawCallVolumeData = this.callsPerDayHoursToDays(rawCallVolumeData,rawCallCountData,rawAgentCountData,1)
            }
          }
          // format rawSeriesData
          switch(type) {
            case 'value':
              formattedSeriesData = rawSeriesData.map( v => (v !== null) ? Math.round(v) : 0)
              break
            case 'percent':
              formattedSeriesData = rawSeriesData.map( v => (v !== null) ? Math.round(v*100)/100 : 0)
              break
          }
          formattedCallVolumeData = rawCallVolumeData.map( v => (v !== null) ? Math.round(v) : 0)
          // bar data (scores or talktime)
          series.push({
            name: agent.agentName + ` ${name}`,
            agentId: agent.agentId,
            type: 'bar',
            yAxisIndex: 0,
            data: formattedSeriesData,
          })
          // line data (call volume)
          let suffix = ''
          if(agent.agentId==0) {
            suffix = (this.timeSpanControl=='hour') ? ' Average Calls/Hour' : ' Average Calls/Day'
          } else {
            suffix = (this.timeSpanControl=='hour') ? ' Calls/Hour' : ' Calls/Day'
          }
          series.push({
            name: agent.agentName + suffix,
            agentId: agent.agentId,
            type: 'line',
            yAxisIndex: 1,
            symbolSize: 10,
            lineStyle: {
              width: 4,
              shadowColor: 'rgba(255, 255, 255, .8)',
              shadowBlur: 4,
            },
            data: formattedCallVolumeData,
            connectNulls: true,
          })
        }
      })
      return series
    },
    averageByCount(array,count) {
      if (!Array.isArray(array) || array.length === 0 || typeof count !== 'number' || count <= 0) return [] // Invalid input, nothing to do
      const result = []
      let sum = 0
      let denominator = 0
      let val = 0

      for (let i = 0; i < array.length; i++) {
        val = Number(array[i])
        if(val !== 0) denominator++
        sum += val
        if ((i + 1) % count === 0 || i === array.length - 1) {
          result.push((denominator!=0) ? sum / denominator : 0)
          denominator = 0
          sum = 0
        }
      }
      return result
    },
    averageAllAgentByCount(rawCallVolumeData, rawCallCountData, rawAgentCountData, count) {
      if (!Array.isArray(rawCallVolumeData) || rawCallVolumeData.length === 0 || typeof count !== 'number' || count <= 0) return [] // Invalid input, nothing to do
      const result = []
      let totalCalls = 0
      let daysWithCalls = 0
      let allAgentIds = []
      let uniqueAgentIds = []

      for (let i = 0; i < rawCallVolumeData.length; i++) {
        // sum call counts, sum days with calls, collect unique list of agent ids
        totalCalls += rawCallCountData[i]
        daysWithCalls += (rawCallCountData[i]>0) ? 1 : 0
        allAgentIds = allAgentIds.concat(rawAgentCountData[i])

        if ((i + 1) % count === 0 || i === rawCallVolumeData.length - 1) {
          uniqueAgentIds = [...new Set(allAgentIds)]
          result.push( (daysWithCalls!=0&&uniqueAgentIds.length!=0) ? (totalCalls/daysWithCalls)/uniqueAgentIds.length : 0)
          totalCalls = 0
          daysWithCalls = 0
          allAgentIds = []
        }
      }
      return result
    },
    callsPerDayHoursToDays(rawCallVolumeData, rawCallCountData, rawAgentCountData, hours){
      if (!Array.isArray(rawCallCountData) || rawCallCountData.length === 0) return [] // Invalid input, nothing to do
      const result = []

      let totalCalls = 0
      let allAgentIds = []
      let uniqueAgentIds = []

      for (let i = 0; i < rawCallVolumeData.length; i++) {

        uniqueAgentIds = [...new Set(allAgentIds)]
        totalCalls += rawCallCountData[i]
        allAgentIds = allAgentIds.concat(rawAgentCountData[i])

        if ((i + 1) % hours === 0 || i === rawCallVolumeData.length - 1) {
          uniqueAgentIds = [...new Set(allAgentIds)]

          if(totalCalls==0||uniqueAgentIds.length==0) {
            result.push(0)
          } else {
            result.push( (uniqueAgentIds.length!=0) ? totalCalls / uniqueAgentIds.length : 0)
          }
          totalCalls = 0
          allAgentIds = []

        }
      }
      return result
    },
    sumByCountHoursToDays(rawCallVolumeData,hours) {
      if (!Array.isArray(rawCallVolumeData) || rawCallVolumeData.length === 0 || typeof hours !== 'number' || hours <= 0) return [] // Invalid input, nothing to do
      const result = []
      let sum = 0
      // add up all the calls over the hours to get day totals
      for (let i = 0; i < rawCallVolumeData.length; i++) {
        sum += Number(rawCallVolumeData[i])
        if ((i + 1) % hours === 0 || i === rawCallVolumeData.length - 1) {
          result.push(sum)
          sum = 0
        }
      }
      return result
    },
    sumByCount(array,count) {
      if (!Array.isArray(array) || array.length === 0 || typeof count !== 'number' || count <= 0) return [] // Invalid input, nothing to do
      const result = []
      let sum = 0
      let val = 0

      for (let i = 0; i < array.length; i++) {
        val = Number(array[i])
        sum += val
        if ((i + 1) % count === 0 || i === array.length - 1) {
          result.push(sum)
          sum = 0
        }
      }
      return result
    },
    weightedAverageByCount(values,calls,count) {
      if (
        !Array.isArray(values)
        || values.length === 0
        || !Array.isArray(calls)
        || calls.length === 0
        || typeof count !== 'number'
        || count <= 0
      ) return [] // Invalid input, nothing to do

      const result = []
      let callCount = 0
      let averageValue = 0
      let totalWeightedValue = 0
      let totalCallCount = 0
      for (let i = 0; i < values.length; i++) {
        callCount = Number(calls[i]) || 0
        averageValue = Number(values[i]) || 0
        totalWeightedValue += callCount * averageValue;
        totalCallCount += callCount;
        if ((i + 1) % count === 0 || i === values.length - 1) {
          result.push((totalCallCount!=0) ? totalWeightedValue / totalCallCount : 0)
          callCount = 0
          averageValue = 0
          totalWeightedValue = 0
          totalCallCount = 0
        }
      }
      return result
    },
    checkAgentSelectionLimits() {
      if( Number(this.agentId) > 0 ) {
        // we are looking at a specific agent - special rules apply...
        // more than one is selected...
        if(this.currentSelectedAgentIds.length>1) {
          // disable all unselected ones
          this.agents = this.agents.map(o => {
            if( this.currentSelectedAgentIds.indexOf(o.agentId)==-1 ) { o.disabled = true }
            return o
          })
        } else {
          // otherwise, enable all
          this.agents = this.agents.map(o => {
            o.disabled = false
            return o
          })
        }
        // this agent cannot be un-selected
        this.agents = this.agents.map(o => {
          if(o.agentId == this.agentId) { o.disabled = true }
          return o
        })
      } else {
        // we are looking at "All Agents" - different special rules apply
        // if "All Agents" is selected, then two additional agents may be selected
        if(this.currentSelectedAgentIds.indexOf(0)!=-1) {
          // if more than one agent is selected - everyone else, except agent 0, is disabled
          if(this.currentSelectedAgentIds.length>2) {
            this.agents = this.agents.map(o => {
              if( this.currentSelectedAgentIds.indexOf(o.agentId)==-1 && o.agentId!=0 ) { o.disabled = true }
              return o
            })
          } else {
            // none or one agent is selected...
            // if no agent is selected, then select "All Agents"...
            if(this.currentSelectedAgentIds.length==0) {
              this.currentSelectedAgentIds = [0]
            }
            // ...and enable everyone
            this.agents = this.agents.map(o => {
              o.disabled = false
              return o
            })
          }
        } else {
          // we are only looking at individual agents
          // if more than one agent is selected - everyone else is disabled
          if(this.currentSelectedAgentIds.length>1) {
            this.agents = this.agents.map(o => {
              if( this.currentSelectedAgentIds.indexOf(o.agentId)==-1 && o.agentId!=0 ) { o.disabled = true }
              return o
            })
          } else {
            // none or one agent is selected, so enable everyone
            this.agents = this.agents.map(o => {
              o.disabled = false
              return o
            })
          }
        }
      }

    },
    getAgentData() {
      let payload = {
        scorecard_id: this.$route.params.scorecardId,
        dynalabel_id: this.$route.params.dynalabelId,
      }
      this.retrieveScorecardAgentsList(payload);
    },
    setTrendSelections() {
      this.trendSelections = {
        selectedAgentIds: this.currentSelectedAgentIds,
        selectedTrend: this.selectedTrend,
        selectedCategory: this.selectedCategory
      }
    },
    retrieveData() {
      this.disableMe = true
      this.setTrendSelections()
      if(this.currentSelectedAgentIds.length>0) {
        CacheHandler.setItem('trendSelections_' + this.$route.params.scorecardId + '_' + this.$route.params.dynalabelId + '_' + this.$route.params.agentId, JSON.stringify(this.trendSelections));
        this.dataSetsLoaded = false
        this.agentSeriesData = {}
        this.series = []
        let payload = {
          scorecard_id: this.$route.params.scorecardId,
          dynalabel_id: this.$route.params.dynalabelId,
          agent_id: this.$route.params.agentId,
          start_date: moment(this.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.end_date).format("YYYY-MM-DD"),
          grouping: this.getGroupingDefault,
          call_type: this.$store.getters["filters/scorecardAdjustments"] || "all",
          endpoint: this.trends[this.currentSelectedTrend].endpoint
        }

        if(this.selectedCategory && this.currentSelectedTrend === 1) {
          payload.category_id = this.selectedCategory
        }

        let additional_agent_ids = this.currentSelectedAgentIds.filter(id => id != this.$route.params.agentId)
        if(additional_agent_ids.length>0) {
          payload.agents = additional_agent_ids
        }
        this.retrieveScorecardTrends(payload)
        this.retrieveScorecardCallVolume(payload)
        this.retrieveScorecardAllAgentTrends(payload)
        this.retrieveScorecardAllAgentCallVolume(payload)
        this.selectedAgentIds = [...this.currentSelectedAgentIds]
        this.selectedTrend = this.currentSelectedTrend
      }
    },
    computeChartDimensions() {
      // get 60% of browser window height in case we need to constrain 16/9 chart ratio when starting with
      let windowHeight = Math.round(window.innerHeight*.6)
      let chartWidth = this.$refs.timeSeriesChart.clientWidth;
      let chartHeight = Math.round(chartWidth / (1.78))
      if(chartHeight>windowHeight) {
        chartHeight = windowHeight
        chartWidth = Math.round(chartHeight * 1.78)
      }
      this.chartHeight = chartHeight
      this.chartWidth = chartWidth
    },
    setStruct(agent) {
      if(!this.agentSeriesData.hasOwnProperty(this.dataSource)) this.agentSeriesData[this.dataSource] = {}
      if(!this.agentSeriesData.hasOwnProperty('callVolume')) this.agentSeriesData['callVolume'] = {}
      if(!this.agentSeriesData.hasOwnProperty('callCount')) this.agentSeriesData['callCount'] = {}
      if(!this.agentSeriesData.hasOwnProperty('uniqueAgents')) this.agentSeriesData['uniqueAgents'] = {}
      if(!this.agentSeriesData[this.dataSource].hasOwnProperty(agent)) this.agentSeriesData[this.dataSource][agent] = []
      if(!this.agentSeriesData['callVolume'].hasOwnProperty(agent)) this.agentSeriesData['callVolume'][agent] = []
      if(!this.agentSeriesData['callCount'].hasOwnProperty(agent)) this.agentSeriesData['callCount'][agent] = []
      if(!this.agentSeriesData['uniqueAgents'].hasOwnProperty(agent)) this.agentSeriesData['uniqueAgents'][agent] = []
    },
    dataUpdated() {
      // we have a $41*&^ ton of data to get from various API requests, this ensures we only proceed once everything has come back
      if (
        this.scorecardTrendsDataLoadingStatus == "loaded"
        && this.scorecardCallVolumeDataLoadingStatus == "loaded"
        && this.scorecardAllAgentTrendsDataLoadingStatus == "loaded"
        && this.scorecardAllAgentCallVolumeDataLoadingStatus == "loaded"
        && this.scorecardAgentsListLoadingStatus == "loaded"
        && this.scorecardV2DataLoadingStatus == "loaded"
      ) {
        // store the data locally
        let trendsResults = JSON.parse(JSON.stringify(this.scorecardTrendsData.data));
        let callVolumeResults = JSON.parse(JSON.stringify(this.scorecardCallVolumeData.data));
        let allAgentTrendsResults = JSON.parse(JSON.stringify(this.scorecardAllAgentTrendsData.data));
        let allAgentCallVolumeResults = JSON.parse(JSON.stringify(this.scorecardAllAgentCallVolumeData.data));
        let agent = ''
        let agentCount = 0
        let currentAgentId = null
        // if there's only a single agent selected then store the data as is into its key
        if(this.currentSelectedAgentIds.length==1) {
          currentAgentId = this.currentSelectedAgentIds[0]
          agent = 'agent' + currentAgentId
          this.setStruct(agent)
          if(currentAgentId == 0||Number(this.$route.params.agentId)!==0) {
            this.agentSeriesData[this.dataSource][agent] = trendsResults.map(o => o[this.dataSource])
            this.agentSeriesData['callVolume'][agent] = callVolumeResults.map(o => o['totalCalls'])
            this.agentSeriesData['callCount'][agent] = callVolumeResults.map(o => {
              agentCount = (o['agentsWithCalls'] == null) ? 0 : o['agentsWithCalls'].split(",").length
              return (agentCount==0) ? 0 : o['totalCalls']
            })
            this.agentSeriesData['uniqueAgents'][agent] = callVolumeResults.map(o => {
              agentCount = (o['agentsWithCalls'] == null) ? 0 : o['agentsWithCalls'].split(",").length
              return (agentCount==0) ? 0 : o['agentsWithCalls'].split(",")
            })
          } else {
            this.agentSeriesData[this.dataSource][agent] = trendsResults[currentAgentId].map(o => o[this.dataSource])
            this.agentSeriesData['callVolume'][agent] = callVolumeResults[currentAgentId].map(o => o['totalCalls'])
          }
        } else {
          // we have multiple, which means we need to walk through each one
          this.currentSelectedAgentIds.forEach( (val) => {
            let agent = 'agent' + val
            this.setStruct(agent)
            if(agent != 'agent0') {
              if(trendsResults.hasOwnProperty(val)) this.agentSeriesData[this.dataSource][agent] = trendsResults[val].map(o => o[this.dataSource])
              if(callVolumeResults.hasOwnProperty(val)) this.agentSeriesData['callVolume'][agent] = callVolumeResults[val].map(o => o['totalCalls'])
            }
          })
        }
        // now do "all agents" if 0 is in the list of selected agents, but at a minimum, do the all agent call volume
        agent = 'agent0'
        this.setStruct(agent)
        if(this.selectedAgentIds.indexOf(0) != -1) {
          this.agentSeriesData[this.dataSource][agent] = allAgentTrendsResults.map(o => o[this.dataSource])
        }
        this.agentSeriesData['callVolume'][agent] = allAgentCallVolumeResults.map(o => o['totalCalls'])
        this.agentSeriesData['callCount'][agent] = allAgentCallVolumeResults.map(o => {
          return (o['agentsWithCalls']==0||o['agentsWithCalls'] == null) ? 0 : o['totalCalls']
        })
        this.agentSeriesData['uniqueAgents'][agent] = allAgentCallVolumeResults.map(o => {
          return (o['agentsWithCalls']==0||o['agentsWithCalls'] == null) ? [] : o['agentsWithCalls'].split(",")
        })
        this.computeChartDimensions()
        this.checkAgentSelectionLimits()
        this.updateDataset()
        this.dataSetsLoaded = true
      }
    },
    scorecardAgentsListUpdated() {
      // this return gives us the agent list for the scorecard
      if (this.scorecardAgentsListLoadingStatus == "loaded" && this.scorecardV2DataLoadingStatus == "loaded") {
        if(Number(this.$route.params.agentId)!==0) {
          this.agents = this.scorecardAgentsList
            .filter(o => {
              return Number(o.id) === Number(this.$route.params.agentId)
            })
            .map(o => { return { 'agentId': o.id, 'agentName': o.name, 'disabled': false }
          })
        } else {
          this.agents = this.scorecardAgentsList.map(o => {
            return { 'agentId': o.id, 'agentName': o.name, 'disabled': false }
          })
        }
        this.agents.unshift({ 'agentName': 'All Agents', 'agentId': 0, 'disabled': false })
        this.agentsCopy = [...this.agents]
        this.categories = [...this.scorecardV2Data.items[0].metrics.categories.map(c => { return {categoryId: c.id, categoryName: c.title} })]
        this.selectedCategory = this.categories[0].categoryId
        this.retrieveData()
      }
    },
    reMount() {
      // it's a hack to get everything loaded either on "mount" or on demand...
      this.dataSetsLoaded = false
      this.computeDateRangeDays()

      // handle drop-down selections
      this.trendSelections = JSON.parse(CacheHandler.getItem('trendSelections_' + this.$route.params.scorecardId + '_' + this.$route.params.dynalabelId + '_' + this.$route.params.agentId)) || {}

      if(Number(this.$route.params.agentId) != 0) {
        if(this.trendSelections.hasOwnProperty('selectedAgentIds')) {
          this.selectedAgentIds = [... new Set(this.trendSelections['selectedAgentIds'].concat(Number(this.$route.params.agentId)))]
        } else {
          this.selectedAgentIds = [Number(this.$route.params.agentId)]
        }
      } else {
        if(this.trendSelections.hasOwnProperty('selectedAgentIds')) {
          this.selectedAgentIds = this.trendSelections['selectedAgentIds']
        } else {
          this.selectedAgentIds = [0]
        }
      }

      if(this.trendSelections.hasOwnProperty('selectedTrend')) {
        this.selectedTrend = this.trendSelections['selectedTrend']
      }

      if(this.trendSelections.hasOwnProperty('selectedCategory')) {
        this.selectedCategory = this.trendSelections['selectedCategory']
      }

      this.currentSelectedAgentIds = [...this.selectedAgentIds]
      this.getAgentData()
      this.retrieveData()
      this.updateTimeSpanControl()
      this.computeChartDimensions()
    },
  },
  mounted() {
    this.reMount()
  },
  watch: {
    currentSelectedAgentIds: "checkAgentSelectionLimits",
    timeSpanControl: "updateTimeSpanControl",
    scorecardTrendsData: "dataUpdated",
    scorecardCallVolumeData: "dataUpdated",
    scorecardAllAgentTrendsData: "dataUpdated",
    scorecardAllAgentCallVolumeData: "dataUpdated",
    scorecardAgentsList: "scorecardAgentsListUpdated",
    scorecardV2Data: "scorecardAgentsListUpdated",
  }
};
</script>

<style scoped>
</style>
