
var subCatText = {
    pass: "Automatic",
    fail: "Manually Adjusted",
    threshold: "See Category",
}

var subCatIcons = {
    subCatHitAuto: "mdi-checkbox-marked-outline",
    subCatHitManual: "mdi-checkbox-marked-circle-outline",
    subCatFailAuto: "mdi-close-box-outline",
    subCatFailManual: "mdi-close-circle-outline",
}

var subCatColors = {
    subPassCatApp: "green lighten-1",
    subFailCatApp: "red lighten-1",
    subCatNonApp: "blue-grey lighten-3",
}
import callCategoryIndex from "@/mixins/callCategoryIndex";
export default {
    mixins: [callCategoryIndex],
    methods: {
        getSubCatText(row) {
            if (row.catScoringRule == 1) {
                if (row.subcatManualPass == 1 || row.subcatManualFail == 1) {
                    if (row.subcatUpdated) {
                        return subCatText.pass;
                    } else {
                        return '<span>' + subCatText.threshold + '</span><br><span>Adjusted by <i>' + row.subcatOverriddenBy + '</i> on ' + row.subcatOverrideDate + '</span>'
                    }
                } else {
                    if (row.subcatUpdated) {
                        return subCatText.fail;
                    } else {
                        return subCatText.threshold;
                    }
                }
            } else {
                //scoring rule is weight
                if (row.subcatManualPass == 1) {
                    if (row.subcatUpdated) {
                        return subCatText.pass;
                    } else {
                        let percent = 0
                        if (row.totalSubcatWeight > 0) {
                            percent = row.subcatWeight / row.totalSubcatWeight
                        }
                        return '<span>Subcategory accounts for ' + this.formatPercent(percent) + ' of overall category score</span><br><span>Adjusted by <i>' + row.subcatOverriddenBy + '</i> on ' + row.subcatOverrideDate + '</span>'
                    }
                } else if (row.subcatManualFail == 1) {
                    if (row.subcatUpdated) {
                        return subCatText.pass;
                    } else {
                        let percent = 0
                        if (row.totalSubcatWeight > 0) {
                            percent = row.subcatWeight / row.totalSubcatWeight
                        }
                        return '<span>Subcategory accounts for ' + this.formatPercent(percent) + ' of overall category score</span><br><span>Adjusted by <i>' + row.subcatOverriddenBy + '</i> on ' + row.subcatOverrideDate + '</span>'
                    }
                } else {
                    if (row.subcatUpdated) {
                        return subCatText.fail;
                    } else {
                        let percent = 0
                        if (row.totalSubcatWeight > 0) {
                            percent = row.subcatWeight / row.totalSubcatWeight
                        }
                        return '<span>Subcategory accounts for ' + this.formatPercent(percent) + ' of overall category score</span>'
                    }

                }
            }
        },
        getSubCatIcon(row) {
            let updated = row.subcatUpdated;
            if (row.subcatManualPass == 1) {
                if (updated) {
                    return (row.subcatHit==1) ? subCatIcons.subCatHitAuto : subCatIcons.subCatFailAuto
                } else {
                    return subCatIcons.subCatHitManual
                }
            } else if (row.subcatManualFail == 1) {
                if (updated) {
                    return (row.subcatFail==1) ? subCatIcons.subCatFailAuto : subCatIcons.subCatHitAuto
                } else {
                    return subCatIcons.subCatFailManual
                }
            } else if (row.subcatHit == 1) {
                return (updated) ? subCatIcons.subCatFailManual : subCatIcons.subCatHitAuto
            } else {
                return (updated) ? subCatIcons.subCatHitManual : subCatIcons.subCatFailAuto
            }
        },
        getSubCatColor(row, excluded) {
            let updated = row.subcatUpdated
            if (this.categoryIsApplicable(row) && !excluded) {
                if (row.subcatManualPass == 1) {
                    if (updated) {
                        return (row.subcatHit==1) ? subCatColors.subPassCatApp : subCatColors.subFailCatApp
                    } else {
                        return subCatColors.subPassCatApp
                    }
                } else if (row.subcatManualFail == 1) {
                    if (updated) {
                        return (row.subcatFail==1) ? subCatColors.subFailCatApp : subCatColors.subPassCatApp
                    } else {
                        return  subCatColors.subFailCatApp
                    }
                } else if (row.subcatHit == 1) {
                    return (row.subcatUpdated) ? subCatColors.subFailCatApp : subCatColors.subPassCatApp
                } else {
                    return (row.subcatUpdated) ? subCatColors.subPassCatApp : subCatColors.subFailCatApp
                }
            } else {
                return subCatColors.subCatNonApp;
            }
        },
        categoryIsApplicable(row) {
            if (row.catUpdated) {
                if (this.isCatManualApplicable(row)) {
                    return (row.catApplicable==0) ? false : true
                } else {
                    return row.catApplicable != 1
                }
            } else {
                if (this.isCatManualApplicable(row)) {
                    return row.catManualApplicable == 1
                } else {
                    return row.catApplicable == 1
                }
            }
        },
    }
}