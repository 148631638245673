<template>
  <div>
    <v-row>
      <v-col cols="6" class="pl-4">
        <fieldset class="fieldset-padding">
          <legend><setup-tip :section="'category_dynalabels'"></setup-tip> Category Dynamic Labels</legend>
          <v-data-table
            :loading="dynaLabelsListLoadingStatus == 'loading'"
            loading-text="Loading... Please wait"
            v-model="dynaLabelsSelected"
            :headers="dynaLabelHeaders"
            :items="selectableDynaLabels"
            :search="dynaLabelSearch"
            :hide-default-footer="true"
            @item-selected="selectItem"
            @toggle-select-all="selectAll"
            item-key="id"
            show-select
            disable-pagination
            class="dl-dt"
            dense
            height="33.3vh"
          >
            <template v-slot:top>
              <v-text-field
                v-model="dynaLabelSearch"
                label="Find Dynamic Labels..."
                class="mx-4"
                single-line
                dense
                hide-details
                clearable
                append-icon="mdi-magnify"
              ></v-text-field>
            </template>
            <template v-slot:header.data-table-select="{ props, on }">
              <v-simple-checkbox
                :value="props.value || props.indeterminate"
                v-on="on"
                :indeterminate="props.indeterminate"
                color="primary"
                :ripple="false"
              />
            </template>
          </v-data-table>
        </fieldset>
        <span class="d-inline caption mt-4 ml-4"
          >Select one or more dynamic labels to assign to this category
          (optional)
        </span>
      </v-col>
      <v-col cols="5" class="pl-4">
        <v-row>
          <fieldset class="fieldset-padding">
            <legend><setup-tip :section="'category'"></setup-tip> Category Settings</legend>
            <v-row align="center">
              <v-col cols="4" offset="2">
                <h4 class="selection-headers">Category Weight</h4>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-text-field
                  dense
                  class="mt-4"
                  style="max-width:40%"
                  single-line
                  v-model="weight"
                  type="number"
                  hint="Must be 0-10"
                  label="0-10"
                  @input="enableButtons"
                  outlined
                  :max="10"
                  :min="0"
                  :rules="numberSelectRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="4" offset="2">
                <h4 class="selection-headers">Scoring Rule</h4>
              </v-col>
              <v-col cols="6">
                <v-btn-toggle
                  v-model="scoringRule"
                  mandatory
                >
                  <v-btn @click="setScoringRule(1)" x-small>Threshold</v-btn>
                  <v-btn @click="setScoringRule(0)" x-small>Weight</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row
              v-if="!weightScoring"
              class="pl-10 pr-8 pt-4 pb-2"
              align="center"
            >
              <h4 class="selection-headers">Threshold Scoring</h4>
            </v-row>
            <span v-if="!weightScoring" class="d-inline caption pl-6"
              >Pass or fail on subcategory count threshold</span
            >
            <v-row v-if="!weightScoring" align="center" class="pl-5 pr-5">
              <v-col cols="4">
                <v-radio-group
                  column
                  v-model="thresholdPassFail"
                  @change="radioChanged"
                >
                  <v-radio class="black-text" value="thresholdPass"
                    ><template v-slot:label>
                      <strong class="black-text">Pass</strong>
                    </template></v-radio
                  >
                  <v-radio value="thresholdFail"
                    ><template v-slot:label>
                      <strong class="black-text">Fail</strong>
                    </template></v-radio
                  >
                </v-radio-group>
              </v-col>
              <v-col cols="4">
                <v-radio-group
                  @change="setThresholdCount"
                  column
                  v-model="thresholdCountSelect"
                >
                  <v-radio label="All" value="thresholdAll">
                    <template v-slot:label>
                      <strong class="black-text">All</strong>
                    </template>
                  </v-radio>
                  <v-radio label="Some" value="thresholdSome"
                    ><template v-slot:label>
                      <strong class="black-text">Some</strong>
                    </template></v-radio
                  >
                </v-radio-group>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-if="thresholdCountSelect != 'thresholdAll'"
                  dense
                  class="threshold-count"
                  :error="checkThreshold"
                  single-line
                  v-model="thresholdCount"
                  type="number"
                  :disabled="selectThresholdCount"
                  label="1-10"
                  hint="Must be 1-10"
                  :error-messages="thresholdErrorMessage"
                  @input="enableButtons"
                  dense
                  outlined
                  :max="10"
                  :min="1"
                  :rules="numberSelectRule"
                ></v-text-field>
              </v-col>
            </v-row>
          </fieldset>
        </v-row>
        <v-row>
          <fieldset class="fieldset-padding">
            <legend><setup-tip :section="'subcategory'"></setup-tip> Subcategory Settings</legend>
            <v-row align="center" class="pl-10 pr-8  pt-2  pb-8">
              <h4 style="width:60%;" class="selection-headers">
                Scoring Type
              </h4>
              <v-btn-toggle
                v-model="subCategoryScoringType"
                mandatory
                style="width:40%;"
              >
                <v-btn
                  @click="updateSubcategoryScoringType(0)"
                  x-small
                  :disabled="this.searchData.length < 1"
                  >Automated</v-btn
                >
                <v-btn @click="updateSubcategoryScoringType(1)" x-small
                  >Manual</v-btn
                >
              </v-btn-toggle>
            </v-row>
            <v-row
              v-if="subCategoryScoringType == 1"
              align="center"
              class="pl-10 pr-8 pt-4 pb-10"
            >
              <h4 style="width:60%;" class="selection-headers">
                Manual Default
              </h4>
              <v-btn-toggle
                v-if="subCategoryScoringType == 1"
                style="width:40%;"
                v-model="manualScoringChoice"
                mandatory
              >
                <v-btn @click="setManualScoringChoice(1)" x-small
                  >CHECKED</v-btn
                >
                <v-btn @click="setManualScoringChoice(0)" x-small
                  >UNCHECKED</v-btn
                >
              </v-btn-toggle>
            </v-row>
          </fieldset>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="11" class="d-flex">
        <v-btn
          x-small
          color="blue-grey"
          class="ma-2 mt-4 white--text"
          @click="createNewSubCategory"
          :disabled="hasMaxSubcategories"
          >Add Subcategory</v-btn
        >
        <v-btn
          x-small
          color="normal"
          class="ma-2 mt-4"
          :disabled="disabledButtons"
          @click="cancelEdits"
          ><span v-if="category.new">discard</span>
          <span v-else>cancel edits</span></v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          x-small
          color="error"
          class="ma-2 mt-4"
          @click="deleteCategoryFromScorecard"
          :disabled="category.new"
          >delete category</v-btn
        >
      </v-col>
    </v-row>
    <confirm ref="confirm"></confirm>
    <confirm-alert ref="confirmalert"> </confirm-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Confirm from "../Widgets/Confirm.vue";
import ConfirmAlert from "../Widgets/ConfirmAlert.vue";
import SetupTip from "@/components/SetupTip.vue";

const MAX_SUBCATEGORIES = 20;

export default {
  props: {
    category: Object,
    scorecardId: Number,
    selectedDynaLabels: Array,
    selectedThreshold: Number,
    categoryCount: Number,
    mediaType: String
  },
  components: {
    Confirm,
    ConfirmAlert,
    SetupTip
  },
  data() {
    return {
      numberSelectRule: [
        v =>
          (!isNaN(parseFloat(v)) && Number(v) % 1 === 0 && v >= 0 && v <= 10) ||
          "Number must be between 0-10"
      ],
      thresholdRule: [
        v =>
          (!isNaN(parseFloat(v)) && v >= 0 && v <= 10) ||
          "Number must be between 1-10"
      ],
      categoryInfo: Object.assign({}, this.category),
      subCategoryScoringType: 0,
      weightScoring: false,
      scoringRule: 0,
      manualScoring: 0,
      manualScoringChoice: 0,
      thresholdPassFail: "radio-1",
      weight: 0,
      selectThresholdCount: false,
      threshold: 0,
      thresholdCount: 1,
      thresholdCountSelect: "thresholdAll",
      dialog: false,
      showModal: false,
      disabledButtons: false,
      dynaLabelsSelected: [],
      selectableDynaLabels: [],
      dynaLabelSearch: "",
      dynaLabelHeaders: [
        {
          text: "Dynamic Labels",
          align: "start",
          sortable: false,
          value: "title"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("resources", ["searchData", "searchDataLoadingStatus"]),
    ...mapGetters("scorecardDefinitions", ["originalCategoryInfo"]),
    ...mapGetters("dynaLabels", [
      "dynaLabelsList",
      "dynaLabelsListLoadingStatus",
      "dynaLabelsListLoadingError",
      "dynaLabelsListLoadingErrorStatus",
      "dynaLabelsEditorList"
    ]),
    hasMaxSubcategories() {
      return this.category.sub_categories.length >= MAX_SUBCATEGORIES;
    },
    thresholdErrorMessage() {
      return (this.checkThreshold) ? 'Must be <= count of subcategories' : ''
    },
    checkThreshold() {
      return (this.category.sub_categories.length<this.thresholdCount) ? true : false
    },
    tileTextClass() {
      return "tile-text tile-text-" + this.$vuetify.breakpoint.name;
    },
    canApply() {
      return (
        this.disabledButtons ||
        (!(
          this.weight >= 0 &&
          this.weight <= 10 &&
          Number(this.weight) % 1 === 0
        ) ||
          this.weight === "") ||
        (!(this.thresholdCount >= 1 && this.thresholdCount <= 10) ||
          this.thresholdCount === "")
      );
    }
  },
  methods: {
    radioChanged() {
      this.disabledButtons = false;
    },
    enableButtons() {
      this.disabledButtons = false;
    },
    setPassFail() {
      if (this.thresholdPassFail == "thresholdPass") {
        this.$emit("updateThresholdPassFail", 0);
      } else {
        this.$emit("updateThresholdPassFail", 1);
      }
    },
    async cancelEdits() {
      if (
        await this.$refs.confirm.open(
          "Cancel Edits?",
          "About to cancel category edits. This action cannot be undone. Are you sure you want to proceed?",
          { color: "red" }
        )
      ) {
        this.category.cancel = false;
        let params = {
          scorecard_id: this.scorecardId,
          category_id: this.category.id
        };
        this.disabledButtons = true;
        if (this.category.new && this.categoryCount > 1) {
          this.removeNewCategory(params);
        } else {
          this.setOriginalCategoryInfo(params);
          let orig = Object.assign({}, this.originalCategoryInfo);
          this.loadCategoryInfo(orig);
          this.$emit("cancelSelections", orig);
          this.loadOriginalDynaLabelSelections(orig);
        }
      }
    },
    async deleteCategoryFromScorecard() {
      if (this.categoryCount < 2) {
        if (
          await this.$refs.confirmalert.open(
            "Cannot Delete Category",
            "Sorry, you may not remove the only remaining category within a scorecard.",
            { color: "red" }
          )
        ) {
        }
      } else {
        if (
          await this.$refs.confirm.open(
            "Delete Category?",
            "About to delete a category. This action cannot be undone. Are you sure you want to proceed?",
            { color: "red" }
          )
        ) {
          let params = {
            scorecard_id: this.scorecardId,
            category_id: this.category.id
          };
          this.disabledButtons = this.category.cancel ? false : true;
          this.deleteCategory(params);
          this.$emit("deletedCategory");
        }
      }
    },
    saveCategory() {
      this.$emit("saveCategory");
    },
    ...mapActions({
      createNewSubCategoryForCategory:
        "scorecardDefinitions/createNewSubCategoryForCategory",
      deleteCategory: "scorecardDefinitions/deleteCategory",
      setOriginalCategoryInfo: "scorecardDefinitions/setOriginalCategoryInfo",
      removeNewCategory: "scorecardDefinitions/removeNewCategory"
    }),
    getSortedDynamicLabels(options, selections) {
      let selected = options
        .filter(d => selections.some(s => d.id == s.dynalabel_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      let unselected = options
        .filter(d => !selections.some(s => d.id == s.dynalabel_id))
        .sort((a, b) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
        );
      return Array(...selected, ...unselected);
    },
    createNewSubCategory() {
      let params = {
        scorecard_id: this.scorecardId,
        category_id: this.category.id,
        scoring_type: this.subCategoryScoringType
      };
      this.createNewSubCategoryForCategory(params);
      this.$emit("subCategoryAdded", this.category.id);
      this.saveCategory();
    },
    setScoringRule(value) {
      this.disabledButtons = false;
      this.scoringRule = value;
      if (value == 0) {
        this.thresholdCountSelect = "thresholdAll";
        this.thresholdPassFail = "thresholdPass";
        this.selectThresholdCount = true;
        this.weightScoring = true;
      } else {
        this.weightScoring = false;
      }
      this.$emit("updateScoringRule", this.scoringRule);
      this.saveCategory();
    },
    setThresholdCount() {
      this.disabledButtons = false;
      if (this.thresholdCountSelect == "thresholdAll") {
        this.category.threshold_count = null;
        this.$emit("updateThresholdCount", null);
        this.selectThresholdCount = true;
      } else {
        this.selectThresholdCount = false;
        this.$emit("updateThresholdCount", this.thresholdCount);
      }
      this.saveCategory();
    },
    setManualScoringChoice(value) {
      this.disabledButtons = false;
      this.manualScoringChoice = value;
      this.$emit("updateManualScoringChoice", this.manualScoringChoice);
      this.saveCategory();
    },
    selectItem(value) {
      this.$emit("updateCancelProp", true);
      this.disabledButtons = false;
    },
    selectAll() {
      this.$emit("updateCancelProp", true);
      this.disabledButtons = false;
    },
    updateDynalabels() {
      this.$emit("updateDynalabelSelections", this.dynaLabelsSelected);
    },
    updateWeight() {
      this.$emit("updateWeight", Math.round(this.weight));
      this.saveCategory();
    },
    updateThresholdCount() {
      this.$emit("updateThresholdCount", this.thresholdCount);
      this.saveCategory();
    },
    updateSubcategoryScoringType(scoringType) {
      if (scoringType == 1) {
        if (this.manualScoringChoice == 0) {
          this.$emit("updateManualScoringChoice", 1);
        } else {
          this.$emit("updateManualScoringChoice", 0);
        }
      }
      this.$emit("updateSubcategoryScoringType", scoringType);
      this.disabledButtons = false;
      this.saveCategory();
    },
    setScoreDefaults() {
      this.weight = this.category.weight != null ? this.category.weight : 1;
      this.thresholdCount =
        this.category.threshold_count != null
          ? this.category.threshold_count
          : 1;
      this.saveCategory();
    },
    loadCategoryInfo(info) {
      this.categoryInfo = Object.assign({}, info);
      this.dynaLabelsSelected = [];
      this.selectableDynaLabels = [];
      this.subCategoryScoringType = info.manual_scoring == 0 ? 0 : 1;
      if (this.searchData.length < 1) {
        this.subCategoryScoringType = 1;
        this.$emit("updateSubcategoryScoringType", this.subCategoryScoringType);
      }
      this.weightScoring = info.scoring_rule == 0 ? true : false;
      this.scoringRule = info.scoring_rule == 0 ? 1 : 0;
      this.manualScoring = info.manual_scoring == "0" ? 0 : 1;
      this.manualScoringChoice = info.manual_scoring_choice == "0" ? 1 : 0;
      this.thresholdPassFail =
        info.threshold_passfail == 0 || info.threshold_passfail == null
          ? "thresholdPass"
          : "thresholdFail";
      this.weight = info.weight != null ? info.weight : 1;
      this.selectThresholdCount = info.threshold_count == null ? true : false;
      this.thresholdCount =
        info.threshold_count != null ? info.threshold_count : 1;
      this.thresholdCountSelect =
        info.threshold_count == null ? "thresholdAll" : "thresholdSome";
    },
    loadDynaLabelSelections() {
      let dynalabelOptions = [];
      this.myDynaLabels = this.selectedDynaLabels;
      dynalabelOptions = this.dynaLabelsEditorList.map(obj => {
        return {
          id: obj.id,
          new: true,
          title: obj.title + " (" + obj.id + ")",
          description: obj.description,
          media_type: obj.media_type
        };
      });

      //filter on media type
      dynalabelOptions = dynalabelOptions.filter(d => 
        d.media_type == this.mediaType
      )

      //need a way to send the database id back as well - so we can update the record there
      dynalabelOptions.forEach((option, index) => {
        this.myDynaLabels.forEach((selected, index) => {
          if (selected.dynalabel_id == option.id && selected.id) {
            option.new = false;
            option.db_id = selected.id;
          }
        });
      });

      this.selectableDynaLabels = this.getSortedDynamicLabels(
        dynalabelOptions,
        this.selectedDynaLabels
      );
      this.dynaLabelsSelected = this.selectableDynaLabels.filter(d =>
        this.myDynaLabels.some(s => d.id == s.dynalabel_id)
      );
    },
    setCancelState() {
      if (this.category.cancel) {
        this.disabledButtons = false;
      } else {
        this.disabledButtons = true;
      }
    },
    loadOriginalDynaLabelSelections(orig) {
      let dynalabelOptions = [];
      this.myDynaLabels = orig.dynalabels;
      dynalabelOptions = this.dynaLabelsEditorList.map(obj => {
        return {
          id: obj.id,
          new: false,
          title: obj.title + " (" + obj.id + ")",
          description: obj.description
        };
      });

      //need a way to send the database id back as well - so we can update the record there
      dynalabelOptions.forEach((option, index) => {
        this.myDynaLabels.forEach((selected, index) => {
          if (selected.dynalabel_id == option.id && selected.id) {
            option.db_id = selected.id;
          }
        });
      });

      this.selectableDynaLabels = this.getSortedDynamicLabels(
        dynalabelOptions,
        orig.dynalabels
      );
      this.dynaLabelsSelected = this.selectableDynaLabels.filter(d =>
        this.myDynaLabels.some(s => d.id == s.dynalabel_id)
      );
    },
  },
  mounted() {
    this.loadCategoryInfo(this.category);
    this.loadDynaLabelSelections();
    this.disabledButtons = this.category.cancel ? false : true;
  },
  watch: {
    dynaLabelsSelected: "updateDynalabels",
    weight: "updateWeight",
    thresholdCount: "updateThresholdCount",
    thresholdPassFail: "setPassFail",
    weightScoring: "setScoreDefaults",
    disabledButtons: "setCancelState",
    mediaType: "loadDynaLabelSelections"
  }
};
</script>

<style scoped>

  fieldset {
    -moz-border-radius: 0.2rem;
    border-radius: 0.2rem;
    -webkit-border-radius: 0.2rem;
    border: 1px solid #c2c2c2;
    padding: 0.4rem;
  }

  .fieldset-padding {
    width: 100%;
    margin-bottom: .5rem;
  }

  .black-text {
    color: black;
  }

  .threshold-count {
    height: 1.5rem;
  }

  .selection-headers {
    color: black;
    font-size: .9rem;
    text-align: left;
  }

</style>
