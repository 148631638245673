import { store } from "@/store/index.js";

export default {
  determineDemoCall(callId) {
    if (store.getters["users/userDataLoadingStatus"] == "loaded") {
        let userData = store.getters["users/userData"];
        //user data resources will always have the "accounts" property...
        if (userData.resources.accounts.includes(Number(process.env.VUE_APP_DEMO_ACCOUNT_ID))) {
            return process.env.VUE_APP_DEMO_CALL_ID;
        }
        return callId;
    }
    //do not want to accidentally show a customer call...
    return 0
  }
};
