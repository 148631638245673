export const filters = {
  namespaced: true,
  state: {
    active: [],
    accounts: null,
    agents: null,
    dnis: null,
    emotionSet: null,
    subjectiveSet: null,
    pots: null,
    ani: null,
    tags: null,
    to: null,
    from: null,
    direction: "",
    callSentiments: null,
    segmentEmotions: null,
    scorecardAdjustments: "all"
  },
  mutations: {
    setActive(state, active) {
      state.active = active;
    },
    setAccounts(state, accounts) {
      state.accounts = accounts;
    },
    setAgents(state, agents) {
      state.agents = agents;
    },
    setEmotionSet(state, emotionSet) {
      state.emotionSet = emotionSet;
    },
    setSubjectiveSet(state, subjectiveSet) {
      state.subjectiveSet = subjectiveSet;
    },
    setDnis(state, dnis) {
      state.dnis = dnis;
    },
    setPots(state, pots) {
      state.pots = pots;
    },
    setAni(state, ani) {
      state.ani = ani;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    setTo(state, to) {
      state.to = to;
    },
    setFrom(state, from) {
      state.from = from;
    },
    setDirection(state, direction) {
      state.direction = direction;
    },
    setCallSentiments(state, callSentiments) {
      state.callSentiments = callSentiments;
    },
    setSegmentEmotions(state, segmentEmotions) {
      state.segmentEmotions = segmentEmotions;
    },
    setScorecardAdjustments(state, adjustments) {
      state.scorecardAdjustments = adjustments;
    }
  },
  getters: {
    all: state => {
      let all = {};
      const keys = Object.keys(state);
      keys.forEach(function(key) {
        if (state[key]) {
          Object.assign(all, state[key]);
        }
      });
      return all;
    },
    active: state => {
      return state.active;
    },
    // returns all filters with selections in a format ready for axios request
    request: state => {
      const active = state.active
        .map(filter => {
          return state[filter];
        })
        .filter(filter => {
          return (
            filter !== null &&
            typeof filter !== undefined &&
            filter !== "" &&
            filter !== undefined
          );
        });
      let all = {};
      const keys = Object.keys(active);
      keys.forEach(function(key) {
        if (active[key]) {
          Object.assign(all, active[key]);
        }
      });

      return all;
    },
    accounts: state => {
      return state.accounts;
    },
    agents: state => {
      return state.agents;
    },
    dnis: state => {
      return state.dnis;
    },
    emotionSet: state => {
      return state.emotionSet;
    },
    subjectiveSet: state => {
      return state.subjectiveSet;
    },
    pots: state => {
      return state.pots;
    },
    ani: state => {
      return state.ani;
    },
    tags: state => {
      return state.tags;
    },
    to: state => {
      return state.to;
    },
    from: state => {
      return state.from;
    },
    length: state => {
      return state.length;
    },
    direction: state => {
      return state.direction;
    },
    callSentiments: state => {
      return state.callSentiments;
    },
    segmentEmotions: state => {
      return state.segmentEmotions;
    },
    scorecardAdjustments: state => {
      return state.scorecardAdjustments;
    }
  }
};
