<template>
  <div>
    <div class="svg-sentiment-info">
      <icon-base
        width="20"
        height="20"
        icon-name="sentiment"
        :iconTitle="transformSentiment + ' Sentiment'"
        :class="makeClassName(transformSentiment)"
      ><icon-sentiment /></icon-base>
    </div>
    <h4 class="subtitle-2">
      {{ agent }}
    </h4>
    <div class="caption">{{ formatCallNumber(callDirection) }}</div>
    <div class="caption">{{ formatDate(callDate) }}</div>
    <div class="caption">{{ formatAccount(accountName, accountNumber) }}</div>
  </div>
</template>

<script>
import IconBase from '@/components/IconBase.vue';
import IconSentiment from '@/components/Icons/IconSentiment.vue';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


export default {
  components: {
    IconBase,
    IconSentiment,
  },
  props: {
    agent: {
      type: String,
      required: true,
    },
    callerNumber: {
      type: String,
      required: true,
    },
    calledNumber: {
      type: String,
      required: false,
    },
    callDate: {
      type: String,
      required: true,
    },
    callDirection: {
      type: String,
      required: true,
    },
    accountNumber: {
      type: Number,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    sentiment: {
      type: String,
      required: true,
    },
    callId: {
      type: String,
      required: true,
    },
  },
  methods: {
    formatNumber: function(direction, number) {
      var num = parsePhoneNumberFromString(String(number), 'US');
      if (num) {
        if (direction == "Inbound") {
          return direction + ' call from ' + num.formatNational() + ' - (' + this.callId + ')';
        } else {
          return direction + ' call to ' + num.formatNational() + ' - (' + this.callId + ')';
        }
      }
    },
    formatCallNumber(direction) {
        direction = direction == "in" ? "Inbound" : "Outbound";
        if (direction == "Inbound") {
          return this.formatNumber(direction, this.callerNumber)
        } else {
          return this.formatNumber(direction, this.calledNumber)
        }
    },
    formatDate: function(date) {
      var displayDate = moment(date);
      if (displayDate.isValid()) {
        return displayDate.format('dddd, MMMM D, YYYY, h:mmA') || '';
      }
    },
    formatAccount: function(name, number) {
      if (name && number) return 'For ' + name + ' (' + number + ')';
    },
    makeClassName: function(sentiment) {
      if (sentiment !== null && typeof sentiment !== "undefined") {
        return "svg-sentiment-" + sentiment.replace(/\s+/g, "-").toLowerCase();
      } else {
        return "svg-sentiment-neutral";
      }
    },
  },
  computed: {
    transformSentiment() {
      let response = ''
      switch (this.sentiment) {
        case 'Positive':
          response = 'Strong Positive';
          break;
        case 'Mostly Positive':
          response = 'Positive';
          break;
        case 'Mostly Negative':
          response = 'Negative';
          break;
        case 'Negative':
          response = 'Strong Negative';
          break;
        case 'Mixed':
          response = 'Mixed';
          break;
        default:
          response = "Neutral"
      }
      return response;
    },
  }
};
</script>

<style scoped>

  .svg-sentiment-strong-positive {
    --color-chevron-north-upper: green;
    --color-chevron-north-middle: lightgreen;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-positive {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: green;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-neutral {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: lightgrey;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-negative {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: orangered;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-strong-negative {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgrey;
    --color-chevron-south-middle: orange;
    --color-chevron-south-lower: orangered;
  }

  .svg-sentiment-mixed {
    --color-chevron-north-upper: lightgrey;
    --color-chevron-north-middle: lightgreen;
    --color-chevron-south-middle: orange;
    --color-chevron-south-lower: lightgrey;
  }

  .svg-sentiment-info {
    display: inline-table;
    float: left;
    padding-top: 2px;
    margin-right: 5px;
    cursor: help;
  }

  .subtitle-2 {
    display: inline-block;
  }

  .caption {
    margin-left: 1.5rem;
    color: rgba(0,0,0,.5) !important;
  }
</style>
