<template>
  <div>
    <v-dialog v-model="dialog" persistent width="50vw">
      <v-card>
        <v-form>
          <v-card-title class="pb-0">
            {{ editorHeader }}
          </v-card-title>
          <v-card-title class="pt-0">
            <v-select
              outlined
              dense
              hide-details
              :items="mySelectors"
              v-model="mySelector"
              placeholder="select a property"
              @input="getSelectorData"
            >
              <template v-slot:selection="data">
                <!-- HTML that describe how select should render selected items -->
                <v-chip :id="getId(data.item.text)" label :color="getItemColor(data.item)"><v-icon v-if="getType(data.item.key)!=''" :title="getType(data.item.key)" x-small left style="opacity: .66;">{{getIcon(data.item.key)}}</v-icon> {{ data.item.text }}</v-chip>
              </template>
              <template v-slot:item="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <v-chip :id="getId(data.item.text)" label :color="getItemColor(data.item)"><v-icon v-if="getType(data.item.key)!=''" :title="getType(data.item.key)" x-small left style="opacity: .66;">{{getIcon(data.item.key)}}</v-icon> {{ data.item.text }}</v-chip>
              </template>
            </v-select>
          </v-card-title>
          <v-card-text>
            <v-alert v-if="mySelector==''" dense text border="left" class="small-alert">Selection options loading complete, please select an option from the drop-down above&hellip;</v-alert>
            <dyna-label-selections
              ref="dynaLabelSelections"
              :mySelector="mySelector"
              :mySelectors="mySelectors"
              :myType="myType"
              :myKind="myKind"
              :mySearch="mySearch"
              :myGlueOptions="getGlueOptions"
              :originalSelector="selector"
              :originalSelection="selection"
              :originalGlue="glue"
              :originalKind="kind"
              :originalType="type"
              :dataTableSelectedRoot="dataTableSelected"
              :searchActionClicked="searchActionClicked"
              @searchSelectors="retrieveSelectorData"
              @setSearch="setMySearch"
              @selectedDataChanged="updateSelectedData"
              @searchActionClicked="updateSearchAction"
            ></dyna-label-selections>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="closeCancelBtn" small color="primary" @click="closeCancel">Close/Cancel</v-btn>
            <v-btn id="saveSelectionsBtn" small color="primary" @click="save">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import DynaLabelSelections from "@/components/DynaLabels/DynaLabelSelections.vue";
  import {mapGetters, mapActions} from "vuex";
  import utils from "@/mixins/utils";
  import moment from "moment";

  export default {
    components: {
      DynaLabelSelections,
    },
    props: {
      mode: String,
      name: String,
      pathArray: Array,
      glue: String,
      kind: String,
      type: String,
      selector: String,
      selection: Array,
      isRoot: Boolean,
    },
    mixins: [
      utils
    ],
    data: () => ({
      dialog: false,
      mySelectors: [],
      myGlue: '',
      myKind: '',
      myType: '',
      mySelector: '',
      mySearch: '',
      alwaysValidSelector: ['Agent', 'Number'],
      rangeSelectors: ['number', 'timesec'],
      dataTableSelected: [],
      searchActionClicked: false
    }),
    computed: {
      ...mapGetters('dynaLabels', [
        'dynaLabelsList',
        'dynaLabelsListLoadingStatus',
        'dynaLabelsListLoadingError',
        'dynaLabelsListLoadingErrorStatus',
        'dynaLabelsSelectors',
        'dynaLabelsSelectorsLoadingStatus',
        'dynaLabelsSelectorsLoadingError',
        'dynaLabelsSelectorsLoadingErrorStatus',
        'dynaLabelsSelections',
        'dynaLabelsSelectionsLoadingStatus',
        'dynaLabelsSelectionsLoadingError',
        'dynaLabelsSelectionsLoadingErrorStatus',
        'dynaLabelData',
        'dynaLabelDataLoadingStatus',
        'dynaLabelDataLoadingError',
        'dynaLabelDataLoadingErrorStatus',
      ]),
      editorHeader() {
        if(this.mode) {
          if(this.mode=='edit') {
            return "Edit Dynamic Label Property (" + this.dynaLabelsSelectors[this.selector].description + ")"
          } else {
            return "Add New Dynamic Label Property"
          }
        } else {
          return ''
        }
      },
      computeColor() {
        return this.getDynaLabelSelectorColor(this.mySelector)
      },
      getGlueOptions() {
        if(this.mySelector=='') {
          return []
        } else if(this.rangeSelectors.includes(this.dynaLabelsSelectors[this.mySelector].type)) {
          return [
            {value: 'gte', description: 'greater than or equal to'},
            {value: 'lte', description: 'less than or equal to'},
            {value: 'eq', description: 'equal to'},
          ]
        } else if(this.dynaLabelsSelectors[this.mySelector].type == 'datetime') {
          return [
            {value: 'gte', description: 'on or after'},
            {value: 'lte', description: 'on or before'},
            {value: 'between', description: 'within range'},
          ]
        } else {
          return [
            {value:'include',description:'include'},
            {value:'exclude',description:'exclude'},
          ]
        }
      },
    },
    methods: {
      ...mapActions('dynaLabels', [
        'retrieveDynaLabelsSelectors',
        'retrieveDynaLabelsSelections',
        'addDynaLabelNode',
        'deleteDynaLabelNode',
        'appendDynaLabelNode',
        'replaceDynaLabelNode',
        'updateDynaLabelRootProp',
      ]),
      updateSearchAction(actionClicked) {
        this.searchActionClicked = actionClicked
      },
      getSelectorData() {
        this.retrieveSelectorData({
          search_string: null,
          columns: [this.mySelector]
        })
      },
      setMySearch(search) {
        this.mySearch = search
      },
      retrieveSelectorData(params) {
        this.retrieveDynaLabelsSelections(params)
      },
      getItemColor(item) {
        if(item.disabled) return "White"
        else return this.getDynaLabelSelectorColor(item.value)
      },
      getType(key) {
        let simplified_key = key.replace(/[0-9]/g, '')
        switch(simplified_key) {
          case 'acctnmbr':
          case 'acd_group':
          case 'adjusteddatetime':
          case 'agent_id':
          case 'campaign':
          case 'channel_count':
          case 'direction':
          case 'disposition':
          case 'handle_time':
          case 'hold_time':
          case 'language':
          case 'skill_name':
          case 'transfer':
          case 'wrapup_time':
          case 'media_type':
            return 'standard - '+key
          case 'extra':
            return 'text extra - '+key
          case 'numextra':
            return 'number extra - '+key
          default:
            return ''
        }
      },
      getId(key) {
        return key.replace(/[ ]/g, '_').toLowerCase() + '_selector'
      },
      getIcon(key){
        let simplified_key = key.replace(/[0-9]/g, '')
        switch(simplified_key) {
          case 'extra':
          case 'numextra':
            return "mdi-flare"
          default:
            return ''
        }
      },
      isValidSelector(selector, name) {
        return ((selector.count > 0 || (this.alwaysValidSelector.includes(selector.description))) && name != 'media_type')
      },
      closeCancel() {
        this.mySearch = ""
        this.dialog = false;
        this.searchActionClicked = false;
      },
      save() {
        let selection = null
        let mode = this.mode
        let pathArray = this.pathArray.slice()
        let isRoot = this.isRoot
        let kind = 'dynalabel'
        let type = this.$refs.dynaLabelSelections.myType
        let glue = this.$refs.dynaLabelSelections.myGlue
        let selector = this.$refs.dynaLabelSelections.mySelector
        if(type=='list') {
          selection = this.$refs.dynaLabelSelections.dataTableSelected.map(o => {return o.value});
        } else if(type=='number') {
          selection =[Number(this.$refs.dynaLabelSelections.numberField)]
        } else if(type=='timesec') {
          selection =[Number(this.$refs.dynaLabelSelections.timeSec)]
        } else if(type=='datetime') {
          selection = [(glue == 'between' ? moment(this.$refs.dynaLabelSelections.selectedDate.start).format('YYYY-MM-DD') + ' - ' +
            moment(this.$refs.dynaLabelSelections.selectedDate.end).format('YYYY-MM-DD') : moment(this.$refs.dynaLabelSelections.selectedDate).format('YYYY-MM-DD'))]
        }

        // build the new node
        if(mode=='add') {

          let obj = null
          let path = ''
          // build the node object
          if(isRoot) {

            obj = {
              "kind": "container",
              "glue": "and",
              "parts": [{
                "kind": kind,
                "type": type,
                "glue": glue,
                "selector": selector,
                "selection": selection
              }]
            }
            path = pathArray.join('.') +'.parts'

          } else {

            obj = {
              "kind": kind,
              "type": type,
              "glue": glue,
              "selector": selector,
              "selection": selection
            }
            path = pathArray.join('.') +'.parts'
          }
          this.appendDynaLabelNode({
            'pathToItem': path,
            'newNode': obj,
          })

        }

        // remove/replace existing node
        else if(mode=='edit') {

          // build the node object
          let obj = {
            "kind": kind,
            "type": type,
            "glue": glue,
            "selector": selector,
            "selection": selection
          }
          // delete the current node
          let idx = pathArray.pop()
          let path = pathArray.join('.')
          this.replaceDynaLabelNode({
            'pathToItem': path,
            'newNode': obj,
            'idx': idx
          })

        }

        this.dialog = false;
        this.searchActionClicked = false;

      },
      setMySelectors() {
        let itemKeys = Object.keys(this.dynaLabelsSelectors)
        let enabled = []
        let disabled = []
        itemKeys.forEach(key => {
          let obj = this.dynaLabelsSelectors[key]
          if(obj.description) {
            if(this.isValidSelector(obj,key)) {
              enabled.push({text: obj.description, value: key, type: obj.type, disabled: false, key: key})
            } else {
              disabled.push({text: obj.description, value: key, type: obj.type, disabled: true, key: key})
            }
          }
        })
        enabled.sort((a, b) => (a.text > b.text) ? 1 : -1)
        disabled.sort((a, b) => (a.text > b.text) ? 1 : -1)
        this.mySelectors = Array(...enabled, ...disabled)
        if(this.mode=='edit') {
          this.mySelector = this.selector
        }
      },
      dialogStateChanged() {
        if(this.dialog) {
          this.dataTableSelected = []
          this.setMySelectors()
          this.mySearch = ""
          if (this.dynaLabelsSelectors[this.mySelector]) {
            this.myType = this.dynaLabelsSelectors[this.mySelector].type
          }
          this.retrieveSelectorData({
            search_string: null,
            columns: [this.mySelector]
          })
        }
      },
      updateSelector() {
        if(this.dialog) {
          this.dataTableSelected = []
          this.myType = this.dynaLabelsSelectors[this.mySelector].type
        }
      },
      updateSelectedData(selected) {
        this.dataTableSelected = selected
      }
    },
    mounted() {
      this.mySelector = ''
      this.originalSelector = this.selector
      this.setMySelectors()
    },
    watch: {
      mySelector: 'updateSelector',
      dynaLabelsSelectors: 'setMySelectors',
      dialog: 'dialogStateChanged',
    }
  }

</script>

<style scoped>

  .item-type {
    font-size: .75rem;
    opacity: .4;
  }

</style>
