<template>
  <v-card tile elevation="0" :class="[(isRoot ? 'is-root-' : 'is-child-') + container.kind]">
    <v-card-text class="pa-0 ma-0 text-center" :class="[(isRoot ? 'root-' : 'child-') + 'background']">
      <glue-button-and-or
        v-if="idx>0"
        :glue="glue"
        :pathToParent="pathToParent"
      ></glue-button-and-or>
      <v-row :class="getClasses">
        <v-col cols="11">
          <v-icon v-if="getType()!=''" :title="getType()" left small style="opacity: .66;">{{this.getIcon()}}</v-icon>
          <v-label v-if="container.kind!='container'"><span class="dl-label" v-html="itemDescription"></span></v-label>
          <template v-if="container.parts && container.parts.length > 0">
            <dyna-label-container
              v-for="(child,idx) in container.parts"
              :key="idx"
              :container="child"
              :idx="idx"
              :glue="container.glue"
              :peerCount="container.parts.length"
              :isRoot="false"
              :pathToParent="pathToMe"
              :pathToMe="pathToMe + 'parts.' + String(idx) + '.'"
            ></dyna-label-container>
          </template>
        </v-col>
        <kebab-menu
          :dynaLabelId="dynaLabelId"
          :container="container"
          :name="container.kind"
          :items="getMenuItems()"
          :isRoot="isRoot"
          :pathToParent="pathToMe"
          :pathToMe="pathToMe + 'parts.' + String(idx) + '.'"
          :selector="getStringVal('selector')"
          :selection="getArrayVals('selection')"
          :glue="getStringVal('glue')"
          :kind="getStringVal('kind')"
          :type="getStringVal('type')"
          :containerKind="'dynalabel'"
          :peerCount="peerCount"
        ></kebab-menu>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

  import KebabMenu from "@/components/DynaLabels/KebabMenu.vue"
  import GlueButtonAndOr from "@/components/DynaLabels/GlueButtonAndOr.vue"
  import {mapGetters} from "vuex";
  import utils from "@/mixins/utils";
  import moment from "moment";

  export default {
    name: "dyna-label-container",
    components: {
      KebabMenu,
      GlueButtonAndOr,
    },
    props: {
      dynaLabelId: Number,
      container: Object,
      peerCount: Number,
      glue: String,
      isRoot: Boolean,
      idx: Number,
      pathToParent: String,
      pathToMe: String,
    },
    mixins: [
      utils
    ],
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters('dynaLabels', [
        'dynaLabelData',
        'dynaLabelsSelectors',
      ]),
      itemDescription() {
        if(this.container.kind=='dynalabel') {
          if(this.container.glue=='include'||this.container.glue=='exclude') {
            return '<strong>' + this.dynaLabelsSelectors[this.container.selector].description + '</strong> (' + this.getArrayVals('selection').length + ' ' + this.container.glue + 'd)'
          } else {
            return '<strong>' + this.dynaLabelsSelectors[this.container.selector].description + '</strong> (' + this.glueSymbol(this.container.glue.toUpperCase()) + this.getValueDisplay(this.getArrayVals('selection')[0]) + ')'
          }
        } else return ''
      },
      itemBackgroundClass() {
        return (this.container.hasOwnProperty('selector')) ? ('dl-' + this.container.selector.replace(/_/g,'-')) : ''
      },
      getClasses() {
        if(this.isRoot) {
          return 'is-root-' + this.container.kind + '-wrapper pl-8'
        } else {
          return 'is-child-' + this.container.kind + '-wrapper pl-8 mt-1 mb-1 ' + this.itemBackgroundClass
        }
      },
    },
    methods: {
      glueSymbol(v) {
        switch(v) {
          case 'EQ':
            return '== '
            break;
          case 'LTE':
            return '<= '
            break;
          case 'GTE':
            return '>= '
            break;
        }
        return ''
      },
      getMenuItems() {
        let items = []
        switch (this.container.kind) {
          case 'container':
            items = [{ title: 'Add', action: 'add' }]
            if(!this.isRoot) items.push({ title: 'Delete', action: 'delete' })
            break;
          case 'dynalabel':
            items = [
              { title: 'Edit', action: 'edit' },
              { title: 'Delete', action: 'delete' },
            ]
            break;
        }
        return items;
      },
      getIcon(){
        switch(this.container.selector) {
          case 'extra1':
          case 'extra2':
          case 'extra3':
          case 'extra4':
          case 'extra5':
          case 'extra6':
          case 'extra7':
          case 'extra8':
          case 'extra9':
          case 'extra10':
          case 'extra11':
          case 'extra12':
          case 'extra13':
          case 'extra14':
          case 'extra15':
          case 'extra16':
          case 'extra17':
          case 'extra18':
          case 'extra19':
          case 'extra20':
          case 'numextra1':
          case 'numextra2':
          case 'numextra3':
          case 'numextra4':
          case 'numextra5':
          case 'numextra6':
          case 'numextra7':
          case 'numextra8':
          case 'numextra9':
          case 'numextra10':
            return "mdi-flare"
            break;
          default:
            return ''
        }
      },
      getType(){
        switch(this.container.selector) {
          case 'extra1':
          case 'extra2':
          case 'extra3':
          case 'extra4':
          case 'extra5':
          case 'extra6':
          case 'extra7':
          case 'extra8':
          case 'extra9':
          case 'extra10':
          case 'extra11':
          case 'extra12':
          case 'extra13':
          case 'extra14':
          case 'extra15':
          case 'extra16':
          case 'extra17':
          case 'extra18':
          case 'extra19':
          case 'extra20':
            return "text extra - " + this.container.selector
          case 'numextra1':
          case 'numextra2':
          case 'numextra3':
          case 'numextra4':
          case 'numextra5':
          case 'numextra6':
          case 'numextra7':
          case 'numextra8':
          case 'numextra9':
          case 'numextra10':
            return "number extra - " + this.container.selector
            break;
          default:
            return ''
        }
      },
      getStringVal(str) {
        return this.container.hasOwnProperty(str) ? this.container[str] : ''
      },
      getArrayVals(str) {
        return this.container.hasOwnProperty(str) ? this.container[str] : []
      },
      getValueDisplay(val) {
        let type = this.getStringVal('type')
        if(type=='timesec') {
          let timeArray = this.formatHMS(val)
          return timeArray.join(':')
        } else {
          return val
        }
      },
      formatHMS(seconds) {
        return moment("1900-01-01 00:00:00")
          .add(seconds, "seconds")
          .format("HH:mm:ss")
          .split(":");
      },
    },
    mounted() {
    },
  }

</script>

<style>

  .is-root-container-wrapper {
    border-radius: 3px;
    background-color: rgba(253, 253, 253, 1);
    color: rgba(0,0,0,.66);
    min-height: 10vh;
  }

  .is-child-container-wrapper {
    border: 1px solid rgba(173, 206, 255, 1);
    border-radius: 3px;
    background-color: rgba(252, 252, 252, 1);
    color: rgba(0,0,0,.66);
  }

  .is-child-dynalabel-wrapper {
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: rgba(224, 224, 224, 1);
  }

  .root-background {
    background-color: rgba(253, 253, 253, 1);
  }

  .child-background {
    background-color: rgba(253, 253, 253, 1);
  }

  span.dl-label {
    color: rgba(0,0,0,1) !important;
  }

</style>
