var catColors = {
    catApplicable: "blue darken-1",
    catNonApplicable: "blue-grey lighten-3",
}
var catIcons = {
    applicableAuto: "mdi-checkbox-intermediate",
    applicableManual: "mdi-circle-slice-8",
    notApplicableAuto: "mdi-checkbox-blank-off-outline",
    notApplicableManual: "mdi-circle-off-outline",
}
var catText = {
    adjusted: "Automatic",
    manual_adjusted: "Manually Adjusted",
}
export default {
    methods: {
        isCatManualApplicable(category) {
            return category.catManualApplicable != undefined &&
                category.catManualApplicable != null
        },
        getCatIcon(category) {
            let updated = category.catUpdated;
            if (this.isCatManualApplicable(category)) {
                if (category.catManualApplicable == 1) {
                    if (updated) {
                        return (category.catApplicable==1) ? catIcons.applicableAuto:catIcons.notApplicableAuto
                    } else {
                        return catIcons.applicableManual
                    }
                } else if (category.catManualApplicable == 0) {
                     if (updated) {
                        return (category.catApplicable==1) ? catIcons.applicableAuto:catIcons.notApplicableAuto
                    } else {
                        return catIcons.notApplicableManual
                    }
                }
            } else {
                if (category.catApplicable == 1) {
                    return (updated) ? catIcons.notApplicableManual : catIcons.applicableAuto
                } else if (category.catApplicable == 0) {
                    return (updated) ? catIcons.applicableManual : catIcons.notApplicableAuto
                }
            }
        },
        getCatColor(category, excluded) {
            let updated = category.catUpdated;
            if (excluded) {
                return catColors.catNonApplicable;
            } else if (this.isCatManualApplicable(category)) {
                if (category.catManualApplicable == 1) {
                    if (updated) {
                        return (category.catApplicable==1) ? catColors.catApplicable : catColors.catNonApplicable
                    } else {
                        return catColors.catApplicable
                    }
                } else if (category.catManualApplicable == 0) {
                     if (updated) {
                        return (category.catApplicable==1) ? catColors.catApplicable : catColors.catNonApplicable
                    } else {
                        return catColors.catNonApplicable
                    }
                }
            } else {
                if (category.catApplicable == 1) {
                    return (updated) ? catColors.catNonApplicable : catColors.catApplicable
                } else if (category.catApplicable == 0) {
                    return (updated) ? catColors.catApplicable : catColors.catNonApplicable
                }
            }
        },
        getCatText(category) {
            let catType = (category.catManualScoring == 1) ? 'Manual' : 'Automated'
            let adjusted = (category.catManualApplicable == 1 || category.catManualApplicable == 0) ? 
                '<br><span>Adjusted by <i>' + category.categoryOverriddenBy + '</i> on ' + category.catOvverrideDate + '</span>' : " "
            if (category.catUpdated) {
                return (category.catManualApplicable == 1 || category.catManualApplicable == 0) ? catText.adjusted : catText.manual_adjusted
            }
            if (category.catScoringRule == 0) {
                //scoring rule weight
                return '<span>' + catType + ' category accounts for ' + this.formatPercent(category.catContribution) + ' of overall call score</span>' +
                    '<br><span>Category score dependent on subcategory scoring rule weight % and subcategory results.  </span>' + adjusted
            } else {
                //scoring rule threshold
                let subcatString = (category.threshold_count == 1) ? "subcategory" : "subcategories"
                if (category.threshold_passfail == 0) {
                    //threshold pass
                    if (category.threshold_count != null) {
                        //pass on threshold count
                        return '<span>' + catType + ' category accounts for ' + this.formatPercent(category.catContribution) + ' of overall call score</span>' +
                            '<br><span>' + category.threshold_count + ' checked ' + subcatString + ' will result in a 100% category score</span>' + adjusted
                    } else {
                        //pass all
                        return '<span>' + catType + ' category accounts for  ' + this.formatPercent(category.catContribution) + ' of overall call score</span>' +
                            '<br><span>All subcategories must be checked for a 100% category score</span>' + adjusted
                    }
                } else {
                    //threshold fail
                    if (category.threshold_count != null) {
                        
                        //fail on threshold count
                        return '<span>' + catType + ' category accounts for  ' + this.formatPercent(category.catContribution) + ' of overall call score</span>' +
                            '<br><span>' + category.threshold_count + ' checked ' + subcatString + ' will result in a 0% category score</span>' + adjusted
                    } else {
                        //fail all
                        return '<span>' + catType + ' category accounts for  ' + this.formatPercent(category.catContribution) + ' of overall call score</span>' +
                            '<br><span>All checked subcategories will result in a 0% category score</span>' + adjusted
                    }
                }
            }
        },
        formatPercent(percent) {
            percent = percent ? percent : 0; // prevents NaN
            return Math.round(percent * 100) + "%";
        },
    },
   
}