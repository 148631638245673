<template>
  <div class="insight-expanded-outer-container elevation-1">
    <v-row>
      <v-col class="pt-2 pb-0">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
        <div
          class="pt-4 pb-12"
          style="border-bottom: 1px dotted rgb(204, 204, 204)"
        >
          <v-skeleton-loader
            type="image"
            :min-width="Math.round(expandedContainerWidth * 0.5)"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="image"
            :min-width="Math.round(expandedContainerWidth * 0.5)"
          ></v-skeleton-loader>
        </div>

        <div
          class="expanded-insight-tile-inner-container pt-2 pb-2"
          :style="{ height: expandedContainerHeight * 0.25 + 'px' }"
        >
          <v-skeleton-loader
            class="insight-data-tile"
            type="image"
            max-height="150"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="insight-data-tile"
            max-height="150"
            type="image"
          ></v-skeleton-loader>
          <div class="insight-data-tile">
            <v-skeleton-loader
              class="insight-data-tile"
              type="image"
              max-height="150"
            ></v-skeleton-loader>
          </div>
          <v-skeleton-loader
            class="insight-data-tile"
            max-height="150"
            type="image"
          ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      heightTimeout: null,
      widthTimeout: null,
      debouncedHeight: 0,
      debouncedWidth: 0,
      e: null
    };
  },
  computed: {
    myHeight: {
      get() {
        return this.debouncedHeight;
      },
      set(val) {
        if (this.heightTimeout) clearTimeout(this.heightTimeout);
        this.heightTimeout = setTimeout(() => {
          this.debouncedHeight = val;
        }, 25);
      }
    },
    myWidth: {
      get() {
        return this.debouncedWidth;
      },
      set(val) {
        if (this.widthTimeout) clearTimeout(this.widthTimeout);
        this.widthTimeout = setTimeout(() => {
          this.debouncedWidth = val;
        }, 25);
      }
    },
    expandedContainerHeight() {
      return Math.round(this.myHeight - 310);
    },
    expandedContainerWidth() {
      return Math.round(this.myWidth - 310);
    }
  },
  methods: {
    resizeHandler() {
      this.myHeight = window.innerHeight;
      this.myWidth = window.innerWidth;
    }
  },

  mounted() {
    this.myHeight = window.innerHeight;
    this.myWidth = window.innerWidth;
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {
    // do stuff
  }
};
</script>

<style></style>
