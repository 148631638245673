<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        scrollable
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="warning" v-bind="attrs" v-on="on"
            >mdi-information</v-icon
          >
          <span class="pl-2 caption grey--text text--darken-2"
            >Disabled due to scorecard/insight associations</span
          >
        </template>
        <v-card>
          <v-toolbar color="warning" dark flat>
            <h2 style="color:white">Deleting Disabled</h2>
          </v-toolbar>
          <v-card-text class="pa-4" style="height: 200px;">
            <h3 class="pt-2 pb-2" style="color:black">
              You may not delete this {{ title }} until it is removed from the
              following referenced locations &hellip;
            </h3>
            <v-row>
              <v-col>
                <h4 class="pt-2 pb-2" style="color:black">
                  {{ problemOneTitle }}:
                </h4>
                <span
                  v-if="problemOneList.length < 1"
                  class="d-inline caption mt-4 "
                  >No associated scorecard definitions
                </span>
                <ul v-else>
                  <li
                    v-for="problem in problemOneList"
                    :key="problem.scorecard_id"
                  >
                    {{ problem.title }}
                  </li>
                </ul>
              </v-col>
              <v-col>
                <h4 class="pt-2 pb-2" style="color:black">
                  {{ problemTwoTitle }}:
                </h4>

                <span
                  v-if="problemTwoList.length < 1"
                  class="d-inline caption mt-4 "
                  >No associated insight definitions
                </span>
                <ul v-else>
                  <li
                    v-for="problem in problemTwoList"
                    :key="problem.insight_id"
                  >
                    {{ problem.title }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn color="primary" small @click="dialog = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    problemOneList: Array,
    problemTwoList: Array,
    problemOneTitle: String,
    problemTwoTitle: String
  },
  data: () => ({
    dialog: false
  })
};
</script>
