
export default {
  methods: {
    getOrdinalWithSuffix(number) {

      // coerce the number to a Number (just in case it's a string)
      number = Number(number)

      const lastDigit = number % 10;
      const lastTwoDigits = number % 100;

      // Special cases for 11, 12, and 13
      if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return number + '<sup>th</sup>'
      }

      // Determine the suffix based on the last digit
      switch (lastDigit) {
        case 1:
          return number + '<sup>st</sup>'
        case 2:
          return number + '<sup>nd</sup>'
        case 3:
          return number + '<sup>rd</sup>'
        default:
          return number + '<sup>th</sup>'
      }

    }
  }
}
