import axios from "@/utils/AxiosInstance.js";
import CacheHandler from "@/utils/CacheHandler";
import router from "../../router";
import { store } from "@/store/index.js";
import moment from "moment";

export const dynaLabels = {
  namespaced: true,
  state: {

    autosavePaused: false,

    dynaLabelsList: [],
    dynaLabelsListLoadingStatus: 'notLoading',
    dynaLabelsListLoadingError: '',
    dynaLabelsListLoadingErrorStatus: false,

    dynaLabelsEditorList: [],
    dynaLabelsEditorListLoadingStatus: 'notLoading',
    dynaLabelsEditorListLoadingError: '',
    dynaLabelsEditorListLoadingErrorStatus: false,

    dynaLabelsSelectors: [],
    dynaLabelsSelectorsLoadingStatus: 'notLoading',
    dynaLabelsSelectorsLoadingError: '',
    dynaLabelsSelectorsLoadingErrorStatus: false,

    dynaLabelsSelections: [],
    dynaLabelsSelectionsLoadingStatus: 'notLoading',
    dynaLabelsSelectionsLoadingError: '',
    dynaLabelsSelectionsLoadingErrorStatus: false,

    dynaLabelData: {},
    dynaLabelDataLoadingStatus: 'notLoading',
    dynaLabelDataLoadingError: '',
    dynaLabelDataLoadingErrorStatus: false,
    dynaLabelProcessingStatus: 0,

    dynalabelHitsForCall: {},
    dynalabelHitsForCallLoadingStatus: 'notLoading',
    dynalabelHitsForCallLoadingError: '',
    dynalabelHitsForCallLoadingErrorStatus: false,

    dynalabelSearches: [],
    dynalabelSearchesLoadingStatus: 'notLoading',
    dynalabelSearchesLoadingError: '',

    dynalabelAssociatedInsights: [],
    dynalabelAssociatedScorecards: [],

  },
  getters: {

    dynalabelAssociatedInsights: state => state.dynalabelAssociatedInsights,
    dynalabelAssociatedScorecards: state => state.dynalabelAssociatedScorecards,

    autosavePaused: state => state.autosavePaused,

    dynaLabelsList: state => state.dynaLabelsList,
    dynaLabelsListLoadingStatus: state => state.dynaLabelsListLoadingStatus,
    dynaLabelsListLoadingError: state => state.dynaLabelsListLoadingError,
    dynaLabelsListLoadingErrorStatus: state => state.dynaLabelsListLoadingErrorStatus,

    dynaLabelsEditorList: state => state.dynaLabelsEditorList,
    dynaLabelsEditorListLoadingStatus: state => state.dynaLabelsEditorListLoadingStatus,
    dynaLabelsEditorListLoadingError: state => state.dynaLabelsEditorListLoadingError,
    dynaLabelsEditorListLoadingErrorStatus: state => state.dynaLabelsEditorListLoadingErrorStatus,

    dynaLabelsSelectors: state => state.dynaLabelsSelectors,
    dynaLabelsSelectorsLoadingStatus: state => state.dynaLabelsSelectorsLoadingStatus,
    dynaLabelsSelectorsLoadingError: state => state.dynaLabelsSelectorsLoadingError,
    dynaLabelsSelectorsLoadingErrorStatus: state => state.dynaLabelsSelectorsLoadingErrorStatus,

    dynaLabelsSelections: state => state.dynaLabelsSelections,
    dynaLabelsSelectionsLoadingStatus: state => state.dynaLabelsSelectionsLoadingStatus,
    dynaLabelsSelectionsLoadingError: state => state.dynaLabelsSelectionsLoadingError,
    dynaLabelsSelectionsLoadingErrorStatus: state => state.dynaLabelsSelectionsLoadingErrorStatus,

    dynaLabelData: state => state.dynaLabelData,
    dynaLabelDataLoadingStatus: state => state.dynaLabelDataLoadingStatus,
    dynaLabelDataLoadingError: state => state.dynaLabelDataLoadingError,
    dynaLabelDataLoadingErrorStatus: state => state.dynaLabelDataLoadingErrorStatus,
    dynaLabelProcessingStatus: state => state.dynaLabelProcessingStatus,

    dynalabelHitsForCall: state => state.dynalabelHitsForCall,
    dynalabelHitsForCallLoadingStatus: state => state.dynalabelHitsForCallLoadingStatus,
    dynalabelHitsForCallLoadingError: state => state.dynalabelHitsForCallLoadingError,
    dynalabelHitsForCallLoadingErrorStatus: state => state.dynalabelHitsForCallLoadingErrorStatus,

    dynalabelSearches: state => state.dynalabelSearches,
    dynalabelSearchesLoadingStatus: state => state.dynalabelSearchesLoadingStatus,
    dynalabelSearchesLoadingError: state => state.dynalabelSearchesLoadingError,
  },
  mutations: {

    SET_DYNALABEL_ASSOCIATED_INSIGHTS(state, associates) {
      state.dynalabelAssociatedInsights = associates
    },
    SET_DYNALABEL_ASSOCIATED_SCORECARDS(state, associates) {
      state.dynalabelAssociatedScorecards = associates
    },

    SET_AUTOSAVE_PAUSED_STATUS(state, fact) {
      state.autosavePaused = fact
    },

    SET_DYNA_LABELS_LIST(state, dynaLabelsList) {
      state.dynaLabelsList = dynaLabelsList
    },
    SET_DYNA_LABELS_LIST_LOADING_STATUS(state, status) {
      state.dynaLabelsListLoadingStatus = status
    },
    SET_DYNA_LABELS_LIST_LOADING_ERROR(state, error) {
      state.dynaLabelsListLoadingError = error
    },
    SET_DYNA_LABELS_LIST_LOADING_ERROR_STATUS(state, status) {
      state.dynaLabelsListLoadingErrorStatus = status
    },
    SET_DYNA_LABELS_EDITOR_LIST(state, dynaLabelsEditorList) {
      state.dynaLabelsEditorList = dynaLabelsEditorList
    },
    SET_DYNA_LABELS_EDITOR_LIST_LOADING_STATUS(state, status) {
      state.dynaLabelsEditorListLoadingStatus = status
    },
    SET_DYNA_LABELS_EDITOR_LIST_LOADING_ERROR(state, error) {
      state.dynaLabelsEditorListLoadingError = error
    },
    SET_DYNA_LABELS_EDITOR_LIST_LOADING_ERROR_STATUS(state, status) {
      state.dynaLabelsEditorListLoadingErrorStatus = status
    },

    UPDATE_DYNA_LABELS_LIST_ITEM(state, dynaLabel) {
      for (const obj of state.dynaLabelsList) {
        if (obj.id === dynaLabel.id) {
          obj.title = dynaLabel.title
          obj.description = dynaLabel.description
          obj.media_type = dynaLabel.media_type
          obj.definition = dynaLabel.definition
          obj.processing = dynaLabel.processing
          obj.draft = dynaLabel.draft
          obj.published = dynaLabel.published
          obj.new = dynaLabel.new
          break;
        }
      }
    },
    REMOVE_DYNA_LABELS_LIST_ITEM(state, id) {
      let idx = state.dynaLabelsList.findIndex(item => item.id == id)
      if (idx >= 0) {
        state.dynaLabelsList.splice(idx, 1)
      }
    },

    SET_DYNA_LABELS_SELECTORS(state, dynaLabelsSelectors) {
      state.dynaLabelsSelectors = dynaLabelsSelectors
    },
    SET_DYNA_LABELS_SELECTORS_LOADING_STATUS(state, status) {
      state.dynaLabelsSelectorsLoadingStatus = status
    },
    SET_DYNA_LABELS_SELECTORS_LOADING_ERROR(state, error) {
      state.dynaLabelsSelectorsLoadingError = error
    },
    SET_DYNA_LABELS_SELECTORS_LOADING_ERROR_STATUS(state, status) {
      state.dynaLabelsSelectorsLoadingErrorStatus = status
    },

    SET_DYNA_LABELS_SELECTIONS(state, dynaLabelsSelections) {
      state.dynaLabelsSelections = dynaLabelsSelections
    },
    SET_DYNA_LABELS_SELECTIONS_LOADING_STATUS(state, status) {
      state.dynaLabelsSelectionsLoadingStatus = status
    },
    SET_DYNA_LABELS_SELECTIONS_LOADING_ERROR(state, error) {
      state.dynaLabelsSelectionsLoadingError = error
    },
    SET_DYNA_LABELS_SELECTIONS_LOADING_ERROR_STATUS(state, status) {
      state.dynaLabelsSelectionsLoadingErrorStatus = status
    },

    SET_DYNA_LABEL_DATA(state, dynaLabelData) {
      state.dynaLabelData = dynaLabelData
    },
    SET_DYNA_LABEL_DATA_LOADING_STATUS(state, status) {
      state.dynaLabelDataLoadingStatus = status
    },
    SET_DYNA_LABEL_DATA_LOADING_ERROR(state, error) {
      state.dynaLabelDataLoadingError = error
    },
    SET_DYNA_LABEL_DATA_LOADING_ERROR_STATUS(state, status) {
      state.dynaLabelDataLoadingErrorStatus = status
    },
    SET_DYNA_LABEL_PROCESSING_STATUS(state, val) {
      state.dynaLabelProcessingStatus = val
    },

    SET_DYNA_LABEL_HITS_FOR_CALL(state, dynalabelHitsForCall) {
      state.dynalabelHitsForCall[dynalabelHitsForCall.call_id] = dynalabelHitsForCall.dynalabel_ids;
    },
    SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_STATUS(state, status) {
      state.dynalabelHitsForCallLoadingStatus = status
    },
    SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_ERROR(state, status) {
      state.dynalabelHitsForCallLoadingError = status
    },
    SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_ERROR_STATUS(state, status) {
      state.dynalabelHitsForCallLoadingErrorStatus = status
    },

    SET_DYNA_LABEL_SEARCHES(state,status) {
      state.dynalabelSearches = status
    },
    SET_DYNA_LABEL_SEARCHES_LOADING_STATUS(state,status) {
      state.dynalabelSearchesLoadingStatus = status
    },
    SET_DYNA_LABEL_SEARCHES_LOADING_ERROR(state,status) {
      state.dynalabelSearchesLoadingError = status
    },
  },
  actions: {

    setDynaLabelProcessingStatus(context, val) {
      context.commit("SET_DYNA_LABEL_PROCESSING_STATUS", val);
    },

    setAutosavePausedStatus(context, fact) {
      context.commit("SET_AUTOSAVE_PAUSED_STATUS", fact);
    },
    setDynalabelHitsForCallLoadingStatus(context, state) {
      context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_STATUS", state);
    },
    retrieveDynaLabelsSelectors(context) {

      context.commit("SET_DYNA_LABELS_SELECTORS", [])
      context.commit("SET_DYNA_LABELS_SELECTORS_LOADING_STATUS", "loading")
      axios
        .get(process.env.VUE_APP_SETUP_DYNA_LABELS_SELECTORS + '?action=count')
        .then(response => {
          context.commit("SET_DYNA_LABELS_SELECTORS", response.data.data)
          context.commit("SET_DYNA_LABELS_SELECTORS_LOADING_ERROR_STATUS", false)
          context.commit("SET_DYNA_LABELS_SELECTORS_LOADING_ERROR", "")
          context.commit("SET_DYNA_LABELS_SELECTORS_LOADING_STATUS", "loaded")
        })
        .catch(function (error) {
          context.commit("SET_DYNA_LABELS_SELECTORS_LOADING_STATUS", "failed")
          context.commit("SET_DYNA_LABELS_SELECTORS_LOADING_ERROR_STATUS", true)
          let error_message = JSON.stringify(error)
          context.commit("SET_DYNA_LABELS_SELECTORS_LOADING_ERROR", 'retrieveDynaLabelsSelectors(): ' + error_message)
        })

      /********************************/
      /**** END SERVICE CALL BLOCK ****/
      /********************************/

    },

    retrieveDynaLabelsSelections(context, params) {

      context.commit("SET_DYNA_LABELS_SELECTIONS", [])
      context.commit("SET_DYNA_LABELS_SELECTIONS_LOADING_STATUS", "loading")

      let urlParams = "";
      if (params.columns) {
        params.columns.forEach(value => {
          urlParams += '&columns[]=' + value
        })
      }

      if (params.search_string) {
        urlParams += '&search_string=' + params.search_string
      }

      axios
        .get(process.env.VUE_APP_SETUP_DYNA_LABELS_SELECTORS + '?action=selections' + urlParams)
        .then(response => {
          context.commit("SET_DYNA_LABELS_SELECTIONS", response.data.data)
          context.commit("SET_DYNA_LABELS_SELECTIONS_LOADING_ERROR_STATUS", false)
          context.commit("SET_DYNA_LABELS_SELECTIONS_LOADING_ERROR", "")
          context.commit("SET_DYNA_LABELS_SELECTIONS_LOADING_STATUS", "loaded")
        })
        .catch(function (error) {
          context.commit("SET_DYNA_LABELS_SELECTIONS_LOADING_STATUS", "failed")
          context.commit("SET_DYNA_LABELS_SELECTIONS_LOADING_ERROR_STATUS", true)
          let error_message = JSON.stringify(error)
          context.commit("SET_DYNA_LABELS_SELECTIONS_LOADING_ERROR", 'retrieveDynaLabelsSelections(): ' + error_message)
        })

      /********************************/
      /**** END SERVICE CALL BLOCK ****/
      /********************************/

    },

    retrieveDynaLabelsList(context, parameters) {
      context.commit("SET_DYNA_LABELS_LIST_LOADING_STATUS", "loading")

      // initialize empty list
      let dyna_labels_draft_list = []
      let dyna_labels_not_draft_list = []

      // get local drafts
      let draft_keys = []
      let dyna_label = {}
      for (const property in localStorage) {
        let userId = store.getters["users/userData"].id
        if (property.slice(0, property.lastIndexOf("_") + 1) == userId + '_dyna_label_') {
          //check to see if the key has expired
          if (CacheHandler.isExpired(property.substring(property.indexOf("_") + 1))) continue;

          dyna_label = JSON.parse(CacheHandler.getItem(property.slice(property.indexOf("_") + 1)))
          dyna_label.draft = true
          dyna_labels_draft_list.push(dyna_label)
          draft_keys.push(Number(dyna_label.id))
        }
      }
      dyna_labels_draft_list.sort((a, b) => a.title > b.title && 1 || -1)

      context.commit("SET_DYNA_LABELS_LIST", dyna_labels_draft_list)

      let axios_params = {
        headers: {},
        params: {}
      }

      let axios_url = process.env.VUE_APP_SETUP_DYNA_LABELS

      if(parameters != undefined && parameters.selfSigned) {
        axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        axios_params['headers'] = {"Authorization": parameters.userid + " " + parameters.reckey}
        axios_params['params']['route'] = "retrieve_dynalabel_list"
        axios_params['params']['call_id'] = parameters.call_id
      }
      axios
        .get(axios_url, axios_params)
        .then(response => {
          // load the list from service
          let dyna_labels_service_list = response.data.data

          // get non drafts
          dyna_labels_not_draft_list =
            dyna_labels_service_list.filter(label => !(draft_keys.includes(label.id))).map(obj => ({
              "id": obj.id,
              "title": obj.title,
              "description": obj.description,
              "media_type": obj.media_type,
              "definition": JSON.parse(obj.definition),
              "processing": obj.processing,
              "media_type": obj.media_type,
              "created_at": obj.created_at,
              "updated_at": obj.updated_at,
              "draft": false,
              "published": true,
              "new": false,
            }))

          // pack the final list of drafts and non drafts and sort
          let final_list = dyna_labels_draft_list.concat(dyna_labels_not_draft_list)
          final_list.sort((a, b) => a.title > b.title && 1 || -1)

          context.commit("SET_DYNA_LABELS_EDITOR_LIST", dyna_labels_service_list)
          context.commit("SET_DYNA_LABELS_EDITOR_LIST_LOADING_ERROR_STATUS", false)
          context.commit("SET_DYNA_LABELS_EDITOR_LIST_LOADING_ERROR", "")
          context.commit("SET_DYNA_LABELS_EDITOR_LIST_LOADING_STATUS", "loaded")

          context.commit("SET_DYNA_LABELS_LIST", final_list)
          context.commit("SET_DYNA_LABELS_LIST_LOADING_ERROR_STATUS", false)
          context.commit("SET_DYNA_LABELS_LIST_LOADING_ERROR", "")
          context.commit("SET_DYNA_LABELS_LIST_LOADING_STATUS", "loaded")

          // need to know which dynamic labels are not drafts
          context.commit("SET_DYNA_LABELS_EDITOR_LIST", dyna_labels_service_list)
          context.commit("SET_DYNA_LABELS_EDITOR_LIST_LOADING_ERROR_STATUS", false)
          context.commit("SET_DYNA_LABELS_EDITOR_LIST_LOADING_ERROR", "")
          context.commit("SET_DYNA_LABELS_EDITOR_LIST_LOADING_STATUS", "loaded")

          context.commit("SET_DYNALABEL_ASSOCIATED_INSIGHTS", []);
          context.commit("SET_DYNALABEL_ASSOCIATED_SCORECARDS", []);
        })
        .catch(function (error) {
          context.commit("SET_DYNA_LABELS_LIST_LOADING_STATUS", "failed")
          context.commit("SET_DYNA_LABELS_LIST_LOADING_ERROR_STATUS", true)
          let error_message = JSON.stringify(error)
          context.commit("SET_DYNA_LABELS_LIST_LOADING_ERROR", 'retrieveDynaLabelsList(): ' + error_message)
        })

      /********************************/
      /**** END SERVICE CALL BLOCK ****/
      /********************************/

    },

    retrieveDynaLabelData(context, dyna_label_id) {

      context.commit("SET_DYNA_LABEL_DATA_LOADING_STATUS", 'loading');

      let dyna_label = {}

      /**********************************/
      /**** BEGIN SERVICE CALL BLOCK ****/
      /**********************************/
      context.commit("SET_DYNALABEL_ASSOCIATED_INSIGHTS", []);
      context.commit("SET_DYNALABEL_ASSOCIATED_SCORECARDS", []);

      let key = CacheHandler.setCacheKeyFromStore(dyna_label_id, 'dyna_label_')

      //check original key so we do not ruin current drafts a user might have...
      if (CacheHandler.getItem("dyna_label_" + dyna_label_id) !== null) {
        dyna_label = JSON.parse(CacheHandler.getItem("dyna_label_" + dyna_label_id))
        context.commit("SET_DYNA_LABEL_DATA", dyna_label);
        context.commit("SET_DYNA_LABEL_PROCESSING_STATUS", dyna_label.processing)
        context.commit("SET_DYNA_LABEL_DATA_LOADING_STATUS", "loaded");
        context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR", '');
        context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR_STATUS", false);
        context.commit("SET_AUTOSAVE_PAUSED_STATUS", false);
      //check new drafts with a timestamp on the key
      } else if (key != null && CacheHandler.getItem(key) !== null) {
        dyna_label = JSON.parse(CacheHandler.getItem(key))
        context.commit("SET_DYNA_LABEL_DATA", dyna_label);
        context.commit("SET_DYNA_LABEL_PROCESSING_STATUS", dyna_label.processing)
        context.commit("SET_DYNA_LABEL_DATA_LOADING_STATUS", "loaded");
        context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR", '');
        context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR_STATUS", false);
        context.commit("SET_AUTOSAVE_PAUSED_STATUS", false);
      } else {
        axios
          .get(process.env.VUE_APP_SETUP_DYNA_LABELS + dyna_label_id)
          .then(response => {
            let rData = response.data.data
            let dyna_label = {
              "id": rData.id,
              "title": rData.title,
              "description": rData.description,
              "media_type": rData.media_type,
              "definition": JSON.parse(rData.definition),
              "processing": rData.processing,
              "created_at": rData.created_at,
              "updated_at": rData.updated_at,
              "draft": false,
              "published": true,
              "new": false,
            }

            if (response.data.data.associated_insights != undefined) {
              context.commit("SET_DYNALABEL_ASSOCIATED_INSIGHTS", response.data.data.associated_insights);
            }
            if (response.data.data.associated_scorecards != undefined) {
              context.commit("SET_DYNALABEL_ASSOCIATED_SCORECARDS", response.data.data.associated_scorecards);
            }
            context.commit("SET_DYNA_LABEL_DATA", dyna_label);
            context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR_STATUS", false);
            context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR", '');
            context.commit("SET_DYNA_LABEL_DATA_LOADING_STATUS", "loaded");
            context.commit("SET_AUTOSAVE_PAUSED_STATUS", false);
          })
          .catch(function (error) {
            context.commit("SET_DYNA_LABEL_DATA_LOADING_STATUS", "failed");
            context.commit("SET_DYNA_LABEL_DATA", {});
            context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR_STATUS", true);
            let error_message = JSON.stringify(error)
            context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR", 'retrievedynaLabelData(): ' + error_message);
            context.commit("SET_AUTOSAVE_PAUSED_STATUS", false);
          });
      }

    },

    createNewDynaLabel(context, id) {

      context.commit("SET_AUTOSAVE_PAUSED_STATUS", true);

      context.commit("SET_DYNA_LABEL_DATA_LOADING_STATUS", 'loading');
      context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR_STATUS", false);
      context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR", '');

      context.commit("SET_DYNA_LABELS_LIST_LOADING_STATUS", "loading")
      context.commit("SET_DYNA_LABELS_LIST_LOADING_ERROR_STATUS", false)
      context.commit("SET_DYNA_LABELS_LIST_LOADING_ERROR", "")

      let dynaLabelsList = Array.from(context.getters.dynaLabelsList)
      let newLabelCount = dynaLabelsList.filter(label => label.title.slice(0, 17) == "New Dynamic Label").length
      let titleSuffix = (newLabelCount > 0) ? " (copy " + (newLabelCount + 1) + ")" : ""
      let did = Date.now()
      let ISO = (timeStamp = did) => { return new Date(timeStamp - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T') }
      let dt = ISO()
      let key = 'dyna_label_' + id + '.' + moment().unix()

      let dynaLabel = {
        id: id,
        title: "New Dynamic Label" + titleSuffix,
        description: "",
        new: true,
        draft: true,
        published: false,
        media_type: 'Call Recording',
        definition: {
          dynaLabels: {
            "glue": "and",
            "kind": "container",
            "type": "root",
            "parts": [],
          },
          searches: {
            "glue": "or",
            "kind": "container",
            "type": "root",
            "parts": []
          },
        },
        created_at: dt[0] + ' ' + dt[1],
        updated_at: dt[0] + ' ' + dt[1],
      }

      // store it locally
      CacheHandler.setItem(key, JSON.stringify(dynaLabel))
      context.commit("SET_DYNA_LABEL_DATA", dynaLabel);

      context.dispatch('retrieveDynaLabelsList',{})
      context.dispatch('retrieveDynaLabelData', id)

    },

    // save draft
    updateDynaLabel(context, dynaLabel) {

      //in case the original key exists without a timestamp...
      CacheHandler.removeKeyIfExists('dyna_label_' + String(dynaLabel.id))

      let key = 'dyna_label_' + String(dynaLabel.id) + '.' + moment().unix()
      let oldKey = CacheHandler.setCacheKeyFromStore(dynaLabel.id, 'dyna_label_');

      dynaLabel.draft = true

      // remove the draft copy, if it exists
      CacheHandler.removeKeyIfExists(oldKey)

      // save it
      CacheHandler.setItem(key, JSON.stringify(dynaLabel))

      context.commit("SET_DYNA_LABEL_DATA", dynaLabel);
      context.commit("UPDATE_DYNA_LABELS_LIST_ITEM", dynaLabel);

    },

    checkDynaLabelProcessing(context, dynaLabelId) {
      axios
        .get(process.env.VUE_APP_SETUP_DYNA_LABELS + dynaLabelId)
        .then(response => {
          let rData = response.data.data
          if (rData.hasOwnProperty('processing')) {
            context.commit("SET_DYNA_LABEL_PROCESSING_STATUS", rData.processing);
          }
        })
        .catch(function (error) { });
    },

    publishDynaLabel(context, dynaLabelId) {

      context.commit("SET_AUTOSAVE_PAUSED_STATUS", true);

      // set up some variables - assume this dynaLabel is published
      let key = CacheHandler.setCacheKeyFromStore(dynaLabelId, 'dyna_label_');
      let originalKey = 'dyna_label_' + String(dynaLabelId)

      let obj = JSON.parse(JSON.stringify(context.getters.dynaLabelData))
      let published = obj.published || false

      // set up parameters
      let params = {
        "title": obj.title,
        "description": obj.description,
        "definition": obj.definition,
        "media_type": obj.media_type,
      }

      if (!published) {

        axios
          .post(process.env.VUE_APP_SETUP_DYNA_LABELS, params)
          .then(response => {
            if (response !== undefined) {
              if (response.status >= 400) {
                alert('The new dynamic label definition was not saved. The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
              } else {
                // clean up local storage
                CacheHandler.removeKeyIfExists(key)
                CacheHandler.removeKeyIfExists(originalKey)
                router.replace('/setup/dyna-labels/' + response.data.data).catch(err => { })
              }
            } else {
              alert('The new dynamic label definition was not saved - probably a cross origin request denial');
            }
          })
          .catch(error => {
            // Error
            alert('The new dynamic label definition was not saved. The server returned an error: "' + JSON.stringify(error));
          })
          .then(function () {
            // console.log('now, do after-save (POST) stuff here')
          })

      } else {

        axios
          .put(process.env.VUE_APP_SETUP_DYNA_LABELS + dynaLabelId, params)
          .then(response => {
            if (response !== undefined) {
              if (response.status >= 400) {
                alert('The dynamic label (' + dynaLabelId + ') definition was not updated. The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
              } else {
                // clean up local storage
                CacheHandler.removeKeyIfExists(key)
                CacheHandler.removeKeyIfExists(originalKey)
                router.replace('/setup/dyna-labels/' + dynaLabelId).catch(err => { })
              }
            } else {
              alert('The updated dynamic label definition was not saved - probably a cross origin request denial');
            }
          })
          .catch(error => {
            // Error
            alert('The updated dynamic label definition was not saved. The server returned an error: "' + error);
          })
          .then(function () {
            // console.log('now, do after-update (PUT) stuff here')
          })

      }

    },

    cloneDynaLabel(context, params) {

      context.commit("SET_AUTOSAVE_PAUSED_STATUS", true);

      context.commit("SET_DYNA_LABEL_DATA_LOADING_STATUS", 'loading');
      context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR_STATUS", false);
      context.commit("SET_DYNA_LABEL_DATA_LOADING_ERROR", '');

      context.commit("SET_DYNA_LABELS_LIST_LOADING_STATUS", "loading")
      context.commit("SET_DYNA_LABELS_LIST_LOADING_ERROR_STATUS", false)
      context.commit("SET_DYNA_LABELS_LIST_LOADING_ERROR", "")

      let dynaLabelList = context.getters.dynaLabelsList
      let dynaLabel = Object.assign({}, params.definition)
      let dynaLabelTitle = dynaLabel.title

      //get titles from definition list - taken from backend
      let titles = []
      dynaLabelList.forEach((scorecard, index) => {
        titles.push(scorecard.title)
      })

      for (let idx = 1; idx <= 100; idx++) {
        // figure out clone number
        dynaLabelTitle = dynaLabelTitle + ' - clone' + (idx == 1 ? '' : ' ' + idx);
        if (!titles.includes(dynaLabelTitle)) {
            break;
        }
      }

      dynaLabel.id = params.id
      dynaLabel.published = false
      dynaLabel.processing = 0
      dynaLabel.new = true 
      dynaLabel.draft = true
      dynaLabel.title = dynaLabelTitle
      dynaLabel.created_at = moment().format('YYYY-MM-D hh:mm:ss')
      dynaLabel.updated_at = moment().format('YYYY-MM-D hh:mm:ss')
      dynaLabelList.push(dynaLabel)
      dynaLabelList.sort((a, b) => a.title > b.title && 1 || -1)

      // store it locally
      CacheHandler.setItem('dyna_label_' + params.id + '.' + moment().unix(), JSON.stringify(dynaLabel))
      context.commit("SET_DYNA_LABELS_LIST", dynaLabelList);
      context.commit("SET_DYNA_LABEL_DATA", dynaLabel);

      context.dispatch('retrieveDynaLabelsList',{})
      context.dispatch('retrieveDynaLabelData', params.id)
    },

    cancelDynaLabelEdits(context, dynaLabelId) {
      //just in case the original key exists
      CacheHandler.removeKeyIfExists('dyna_label_' + String(dynaLabelId))

      // get a reference to the draft
      let key = CacheHandler.setCacheKeyFromStore(dynaLabelId, 'dyna_label_');
      
      // remove the draft
      CacheHandler.removeKeyIfExists(key)

      context.dispatch('retrieveDynaLabelsList',{})

    },

    deleteDynaLabel(context, dynaLabelId) {

      context.commit("SET_AUTOSAVE_PAUSED_STATUS", true);

      //just in case the original key exists
      CacheHandler.removeKeyIfExists('dyna_label_' + String(dynaLabelId))

      // set up some variables - assume this dynaLabel is published
      let key = CacheHandler.setCacheKeyFromStore(dynaLabelId, 'dyna_label_');

      // delete the object from localStorage if it exists
      CacheHandler.removeKeyIfExists(key)

      context.commit("REMOVE_DYNA_LABELS_LIST_ITEM", dynaLabelId)

      axios
        .delete(process.env.VUE_APP_SETUP_DYNA_LABELS + dynaLabelId)
        .then(response => {
          if (response !== undefined) {
            if (response.status >= 400) {
              alert('An error occurred when deleting dynamic label id ' + dynaLabelId + '. The server returned an error: "' + response.data.error.message + ' (' + response.data.error.status + ')"');
            } else {
              // no fanfare
            }
          } else {
            alert('An error occurred when deleting dynamic label id ' + dynaLabelId + '. - probably a cross origin request denial');
          }
        })
        .catch(error => {
          // Error
          alert('An error occurred when deleting dynamic label id ' + dynaLabelId + '. The server returned an error: "' + error);
        })

      // we've taken care of things locally, so it's time to advance the route, the delete can finish on its own
      router.push('/setup/dyna-labels/').catch(err => { })

    },

    addDynaLabelNode(context, parameters) {

      let obj = Object.assign({}, context.getters.dynaLabelData);

      let pathToItem = parameters.pathToItem + '.parts'
      let isRoot = parameters.isRoot
      let newNode = null
      let did = Date.now()

      if (isRoot) {
        newNode = {
          kind: "container",
          glue: "and",
          parts: [{
            kind: "dynalabel",
          }]
        }
      } else {
        newNode = {
          kind: "dynalabel",
        }
      }

      let addNode = (path, node) => {
        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
          var elem = pList[i];
          if (!schema[elem]) schema[elem] = {}
          schema = schema[elem];
        }
        schema[pList[len - 1]].push(node);
      }

      addNode(pathToItem, newNode)
      context.dispatch('updateDynaLabel', obj)

    },

    appendDynaLabelNode(context, parameters) {

      let pathToItem = parameters.pathToItem
      let newNode = parameters.newNode

      let obj = Object.assign({}, context.getters.dynaLabelData);

      let appendNode = (path, node) => {
        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
          var elem = pList[i];
          if (!schema[elem]) schema[elem] = {}
          schema = schema[elem];
        }
        schema[pList[len - 1]].push(node);
      }

      appendNode(pathToItem, newNode)
      context.dispatch('updateDynaLabel', obj)

    },

    updateDynaLabelRootProp(context, parameters) {

      let obj = Object.assign({}, context.getters.dynaLabelData);
      obj[parameters.prop] = parameters.val
      context.dispatch('updateDynaLabel', obj)

    },

    updateDynaLabelGlue(context, parameters) {

      let obj = Object.assign({}, context.getters.dynaLabelData);

      let glue = parameters.glue
      let pathToGlue = parameters.pathToParent + 'glue'

      let setProp = (path, value) => {
        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
          var elem = pList[i];
          if (!schema[elem]) schema[elem] = {}
          schema = schema[elem];
        }
        schema[pList[len - 1]] = value;
      }

      setProp(pathToGlue, glue)
      context.dispatch('updateDynaLabel', obj)

    },

    deleteDynaLabelNode(context, parameters) {

      let obj = Object.assign({}, context.getters.dynaLabelData)

      let pathToItem = parameters.pathToItem
      let idx = parameters.idx

      let pruneItem = (path, value) => {
        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
          var elem = pList[i];
          if (!schema[elem]) schema[elem] = {}
          schema = schema[elem];
        }
        schema[pList[len - 1]].splice(Number(value), 1);
      }

      pruneItem(pathToItem, idx)
      context.dispatch('updateDynaLabel', obj)

    },

    replaceDynaLabelNode(context, parameters) {

      let pathToItem = parameters.pathToItem
      let newNode = parameters.newNode
      let idx = parameters.idx
      let obj = Object.assign({}, context.getters.dynaLabelData)

      let replaceItem = (path, value, node) => {
        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
          var elem = pList[i];
          if (!schema[elem]) schema[elem] = {}
          schema = schema[elem];
        }
        schema[pList[len - 1]].splice(Number(value), 1, node);
      }

      replaceItem(pathToItem, idx, newNode)
      context.dispatch('updateDynaLabel', obj)

    },

    updateDynaLabelProcessingFlags(context) {

      let currentId = null
      let localList = Object.assign([], context.getters.dynaLabelsList)
      let serviceList = []
      let dynaLabelData = context.getters.dynaLabelData
      if (dynaLabelData.hasOwnProperty('id')) currentId = dynaLabelData.id
      axios
        .get(process.env.VUE_APP_SETUP_DYNA_LABELS)
        .then(response => {
          serviceList = response.data.data
          localList.forEach((item, index) => {
            let dl = serviceList.find(obj => { return obj.id == item.id })
            if (dl && dl.hasOwnProperty('processing')) {
              localList[index].processing = dl.processing
              if (item.id == currentId) {
                context.commit("SET_DYNA_LABEL_PROCESSING_STATUS", dl.processing);
              }
            }
          })
          context.commit("SET_DYNA_LABELS_LIST", localList)
        })
        .catch(function (error) { });
    },

    getDynalabelCallHit(context, parameters) {
      context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_STATUS", 'loading');
      let axios_params = {
        headers: {},
        params: {
          calls: [parameters.call_id],
          id : parameters.dynalabel_ids,
        }
      }

      let axios_url = process.env.VUE_APP_DYNALABEL_CALL_HIT

      if(parameters.selfSigned) {
        axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        axios_params['headers'] = {"Authorization": parameters.userid + " " + parameters.reckey}
        axios_params['params']['route'] = "dynalabel_call_hit"
        axios_params['params']['call_id'] = parameters.call_id
      }
      let hitsByCall = {'call_id': parameters.call_id, 'dynalabel_ids': []}
      if(parameters.dynalabel_ids.length == 0) {
        // if there is nothing to check dont even call the service
        context.commit("SET_DYNA_LABEL_HITS_FOR_CALL",hitsByCall)
        context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_STATUS", "loaded")
      } else {
        axios
          .get(axios_url,axios_params)
          .then(response => {
            if(response.data) {
              response.data.data.forEach(data_element => {
                hitsByCall['dynalabel_ids'].push(data_element['dynalabel_id'])
              })
            }
            context.commit("SET_DYNA_LABEL_HITS_FOR_CALL",hitsByCall)
            context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_STATUS", "loaded")
          })
          .catch(function (error) {
            context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_STATUS", "failed");
            context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_ERROR_STATUS", true);
            context.commit("SET_DYNA_LABEL_HITS_FOR_CALL",{})
            let error_message = JSON.stringify(error)
            context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_ERROR", 'getDynalabelCallHit(): ' + error_message);
          })
      }

    },
    getDynalabelSearches(context, parameters) {
      context.commit("SET_DYNA_LABEL_SEARCHES",[])
      context.commit("SET_DYNA_LABEL_SEARCHES_LOADING_STATUS", 'loading');
      context.commit("SET_DYNA_LABEL_SEARCHES_LOADING_ERROR", '');
      context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_ERROR_STATUS", false);

      let axios_params = {
        headers: {},
        params: {
          action: 'searches_by_dynalabel',
          ids : parameters.dynalabel_ids,
        }
      }

      let axios_url = process.env.VUE_APP_DYNALABEL_GATHER

      if(parameters.selfSigned) {
        axios_url = process.env.VUE_APP_AUTHENTICATE_ROUTE
        axios_params['headers'] = {"Authorization": parameters.userid + " " + parameters.reckey}
        axios_params['params']['route'] = "dynalabels-gather"
        axios_params['params']['call_id'] = parameters.callId
      }

      axios
        .get(axios_url,axios_params)
        .then(function (response) {
          context.commit("SET_DYNA_LABEL_SEARCHES",response.data)
          context.commit("SET_DYNA_LABEL_SEARCHES_LOADING_STATUS", 'loaded');
        })
        .catch(function (error) {
          context.commit("SET_DYNA_LABEL_SEARCHES_LOADING_STATUS", 'failed');
          context.commit("SET_DYNA_LABEL_HITS_FOR_CALL_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          context.commit("SET_DYNA_LABEL_SEARCHES_LOADING_ERROR", 'getDynalabelSearches(): ' + error_message);
        })

    }

  }

}