<template>
    <v-content>
      <v-container fill-height>
          <v-row justify="center">
            <div class="title text-center">
              loading...
            </div>
          </v-row>
      </v-container>
    </v-content>
</template>

<script>
import {mapActions} from "vuex";
import axios from "axios";
import { store } from "@/store/index";

export default {
  data() {
    return {
      onetime: this.$route.params.onetime,
    }
  },
  methods: {
    ...mapActions("users", [
      "retrieveUserData",
      "resetUserData",
    ]),
    checkOneTime: function() {
      if (this.onetime) {
        this.clearTokens();
        this.resetUserData();

        let ontime_url = process.env.VUE_APP_ONETIME + this.onetime;
        let redir_obj = this.getRedirObj();
        let self = this;

        axios
        .get(ontime_url)
        .then(response => {
          if(response && response.status) {
            if(response.status == 200 && response.data) {
              // set the login info and retrieve the user info
              localStorage.setItem("bearer", response.data.value);
              store.dispatch("authentication/setIsAuthenticated", true);
              self.retrieveUserData()
            }
          }
          self.$router.push(redir_obj);
        })
        .catch(error => {
          console.log(error);
          self.$router.push(redir_obj);
        });
      }
    },
    getRedirObj() {
      let redir_path = "/";
      let redir_query = {};

      if (this.$route.query.redir) {
        let redir_param = this.$route.query.redir;
        if(redir_param.charAt(0) != "/")
          redir_param = "/" + redir_param;

        let redirArray = redir_param.split("?");

        redir_path = redirArray[0];

        if(redirArray.length == 2){
          let search = redirArray[1];
          redir_query = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
        }
      }

      return { path: redir_path, query: redir_query };
    },
    clearTokens() {
      store.dispatch("authentication/setIsAuthenticated", false);
      localStorage.setItem("bearer", '');
      localStorage.setItem("authKey", '');
    }
  },
  mounted() {
    this.checkOneTime();
  }
}
</script>
