<template>
  <div>
      <v-icon :color="iconColor" :title="auditCallTitle" @click="sendToAudit">{{mdiIcon}}</v-icon>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import CookieHelper from "@/utils/CookieHelper.js";

  export default {
    props: {
      callId: {
        type: Number,
          required: true,
      },
      auditedBefore: {
        type: Boolean,
        required: false,
        default: false,
      },
      isScorecards: {
        type: Boolean,
        required: true,
      }
    },
    data: () => ({
    }),
    computed: {
      ...mapGetters("users", [
        "userData",
      ]),
      auditCallTitle() {
        return "Audit Call " + this.callId
      },
      iconColor() {
        return this.auditedBefore ? "rgba(85, 26, 139, .6)" : "rgb(130, 177, 255)"
      },
      mdiIcon() {
        return this.auditedBefore ? "mdi-text-box-check" : "mdi-text-box"
      }
    },
    methods: {
      sendToAudit() {
        if(this.isScorecards) {
          this.sendToAuditScorecards()
        } else {
          this.sendToAuditInsights()
        }
      },
      sendToAuditScorecards() {
        CookieHelper.setCookie(process.env.VUE_APP_R2_AUTH_COOKIE, localStorage.getItem("bearer"));
        let auditURL = new URL(process.env.VUE_APP_AUTH_TO_R2)

        auditURL.searchParams.append('csrep_username',this.userData.username)
        auditURL.searchParams.append('csrep_userid',this.userData.csrep_userid)
        auditURL.searchParams.append('call_id',this.callId)
        auditURL.searchParams.append('call_audit',true)
        auditURL.searchParams.append('scorecard_id',this.$route.params.scorecardId)
        auditURL.searchParams.append('dynalabel_id',this.$route.params.dynalabelId)
        auditURL.searchParams.append('agent_id',this.$route.params.agentId)
        auditURL.searchParams.append('start_date',this.$route.query.start_date)
        auditURL.searchParams.append('end_date',this.$route.query.end_date)
        auditURL.searchParams.append('call_type',(this.$store.getters["filters/scorecardAdjustments"] || "all"))

        window.open(auditURL);
      },
      sendToAuditInsights() {
        CookieHelper.setCookie(process.env.VUE_APP_R2_AUTH_COOKIE, localStorage.getItem("bearer"));
        let auditURL = new URL(process.env.VUE_APP_AUTH_TO_R2)

        auditURL.searchParams.append('csrep_username',this.userData.username)
        auditURL.searchParams.append('csrep_userid',this.userData.csrep_userid)
        auditURL.searchParams.append('call_id',this.callId)
        auditURL.searchParams.append('call_audit',true)
        auditURL.searchParams.append('insight_id',this.$route.params.id)
        auditURL.searchParams.append('dynalabel_id',this.$route.params.dynalabelId)
        auditURL.searchParams.append('agent_id',this.$route.params.agentId)
        auditURL.searchParams.append('start_date',this.$route.query.start_date)
        auditURL.searchParams.append('end_date',this.$route.query.end_date)

        window.open(auditURL);
      },
    }
  }

</script>

<style scoped>

.v-icon {
  cursor: pointer;
}

</style>
