import axios from "@/utils/AxiosInstance.js";
const CFONLY_USER = 35
const ADMINISTRATOR_USER = 41
const POWER_USER = 30
const POWER_PLUS_USER = 43

export const users = {
  namespaced: true,
  state: {
    userData: {},
    userDataLoadingStatus: 'notLoading',
    userDataLoadingError: '',
    userDataLoadingErrorStatus: false,

    userDataList: [],
    userDataListLoadingStatus: 'notLoading',
    userDataListLoadingError: '',
    userDataListLoadingErrorStatus: false,

    proxyUserList: [],
    proxyLookupFinished: false,
    proxyErrorMessage: '',
  },
  getters: {
    userData: state => state.userData,
    userDataLoadingStatus: state => state.userDataLoadingStatus,
    userDataLoadingError: state => state.userDataLoadingError,
    userDataLoadingErrorStatus: state => state.userDataLoadingErrorStatus,

    userDataList: state => state.userDataList,
    userDataListLoadingStatus: state => state.userDataListLoadingStatus,
    userDataListLoadingError: state => state.userDataListLoadingError,
    userDataListLoadingErrorStatus: state => state.userDataListLoadingErrorStatus,

    cfOnlyUser: state=>state.userData.groupid==CFONLY_USER,
    administratorUser: state=>state.userData.groupid==ADMINISTRATOR_USER,
    powerUser: state=>state.userData.groupid==POWER_USER,
    powerPlusUser: state=>state.userData.groupid==POWER_PLUS_USER,

    hasSetupAccess: state=>(state.userData.groupid==CFONLY_USER || state.userData.privileges.callFinderManageSetup),
    canCreateScorecards: state=>(state.userData.groupid==CFONLY_USER || state.userData.privileges.callFinderManageSetup),

    manualScoringAccess: state=>state.userData.settings.manualScoring,

    proxyUserList: state => state.proxyUserList,
    proxyLookupFinished: state => state.proxyLookupFinished,
    proxyErrorMessage: state => state.proxyErrorMessage,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.userData = userData
    },
    SET_USER_DATA_LOADING_STATUS(state, status) {
      state.userDataLoadingStatus = status
    },
    SET_USER_DATA_LOADING_ERROR(state, error) {
      state.userDataLoadingError = error
    },
    SET_USER_DATA_LOADING_ERROR_STATUS(state, status) {
      state.userDataLoadingErrorStatus = status
    },

    SET_USER_DATA_LIST(state, userDataList) {
      state.userDataList = userDataList
    },
    SET_USER_DATA_LIST_LOADING_STATUS(state, status) {
      state.userDataListLoadingStatus = status
    },
    SET_USER_DATA_LIST_LOADING_ERROR(state, error) {
      state.userDataListLoadingError = error
    },
    SET_USER_DATA_LIST_LOADING_ERROR_STATUS(state, status) {
      state.userDataListLoadingErrorStatus = status
    },
    SET_PROXY_USER_LIST(state, list) {
      state.proxyUserList = list
    },
    SET_PROXY_LOOKUP_FINISHED(state, status) {
      state.proxyLookupFinished = status;
    },
    SET_PROXY_ERROR_MESSAGE(state, message) {
      state.proxyErrorMessage = message;
    }
  },
  actions: {
    setProxyLookupFinished(context, status) {
      context.commit("SET_PROXY_LOOKUP_FINISHED", status)
    },
    setProxyErrorMessage(context, message) {
      context.commit("SET_PROXY_ERROR_MESSAGE", message)
    },
    retrieveUserData(context,params = {selfSignedTranscript: false}) {
      context.commit("SET_USER_DATA_LOADING_STATUS", 'loading');
      // prevent a race condition if a request is being made before
      // there's a bearer token or userid, we're going to just set
      // an error and return so the rest of the app can do its work

      if (!localStorage.getItem("bearer") && !params.selfSignedTranscript) {
        context.commit("SET_USER_DATA_LOADING_STATUS", "failed");
        context.commit("SET_USER_DATA", {});
        context.commit("SET_USER_DATA_LOADING_ERROR_STATUS", true);
        context.commit("SET_USER_DATA_LOADING_ERROR", 'missing userid or bearer token');
      } else {
        let url = process.env.VUE_APP_LEGACY_USERS
        let axios_params = {
          params: {}
        }
        if(params.selfSignedTranscript) {
          url = process.env.VUE_APP_AUTHENTICATE_ROUTE
          axios_params['params']['route'] = 'users'
          axios_params['params']['call_ids'] = [params.call_id]
          axios_params['headers'] = {"Authorization": params.userid + " " + params.reckey} 
        }
        axios
          .get(url,axios_params)
          .then(response => {
            let responseData = JSON.parse(JSON.stringify(response.data.data[0]));
            // see if someone is proxying
            context.commit("SET_USER_DATA", responseData);
            context.commit("SET_USER_DATA_LOADING_ERROR_STATUS", false);
            context.commit("SET_USER_DATA_LOADING_ERROR", '');
            context.commit("SET_USER_DATA_LOADING_STATUS", "loaded");
            // console.log('# SUCCESS vuex loaded user data', response);
          })
          .catch(function (error) {
            context.commit("SET_USER_DATA_LOADING_STATUS", "failed");
            context.commit("SET_USER_DATA", {});
            context.commit("SET_USER_DATA_LOADING_ERROR_STATUS", true);
            let error_message = ''
            if(error.hasOwnProperty('response')) {
              if(typeof error.response != 'undefined') {
                if (error.response.hasOwnProperty('data')) {
                  if (error.response.data.hasOwnProperty('message')) {
                    error_message = error.response.data.message
                  } else if(error.response.data.hasOwnProperty('error')) {
                    if(error.response.data.error.hasOwnProperty('message')) {
                      error_message = error.response.data.error.message
                    } else {
                      error_message = JSON.stringify(error.response.data.error)
                    }
                  } else {
                    error_message = JSON.stringify(error.response.data)
                  }
                } else if(error.response.hasOwnProperty('message')) {
                  error_message = error.response.message
                } else {
                  error_message = JSON.stringify(error.response)
                }
              } else {
                error_message = "unable to connect to " + error.config.url
              }
            } else if(error.hasOwnProperty('message')) {
              error_message = error.message
            } else {
              error_message = JSON.stringify(error)
            }
            context.commit("SET_USER_DATA_LOADING_ERROR",  'retrieveUserData(): ' + error_message);
            // console.log('# ERROR vuex loading user data', error);
          });
      }
    },
    retrieveAuthorizedUserListData(context, params) {
      context.commit("SET_USER_DATA_LIST_LOADING_STATUS", 'loading');
        axios
          .get(process.env.VUE_APP_AUTHORIZED_USERS + '?id='+ params)
          .then(response => {

            context.commit("SET_USER_DATA_LIST", response.data);
            context.commit("SET_USER_DATA_LIST_LOADING_ERROR_STATUS", false);
            context.commit("SET_USER_DATA_LIST_LOADING_ERROR", '');
            context.commit("SET_USER_DATA_LIST_LOADING_STATUS", "loaded");
          })
          .catch(function (error) {
            context.commit("SET_USER_DATA_LIST_LOADING_STATUS", "failed");
            context.commit("SET_USER_DATA_LIST", {});
            context.commit("SET_USER_DATA_LIST_LOADING_ERROR_STATUS", true);
            let error_message = ''
            if(error.hasOwnProperty('response')) {
              if(typeof error.response != 'undefined') {
                if (error.response.hasOwnProperty('data')) {
                  if (error.response.data.hasOwnProperty('message')) {
                    error_message = error.response.data.message
                  } else if(error.response.data.hasOwnProperty('error')) {
                    if(error.response.data.error.hasOwnProperty('message')) {
                      error_message = error.response.data.error.message
                    } else {
                      error_message = JSON.stringify(error.response.data.error)
                    }
                  } else {
                    error_message = JSON.stringify(error.response.data)
                  }
                } else if(error.response.hasOwnProperty('message')) {
                  error_message = error.response.message
                } else {
                  error_message = JSON.stringify(error.response)
                }
              } else {
                error_message = "unable to connect to " + error.config.url
              }
            } else if(error.hasOwnProperty('message')) {
              error_message = error.message
            } else {
              error_message = JSON.stringify(error)
            }
            context.commit("SET_USER_DATA_LIST_LOADING_ERROR",  'retrieveuserDataListData(): ' + error_message);
            // console.log('# ERROR vuex loading user data', error);
          });
    },

    resetUserData(context) {
      context.commit("SET_USER_DATA", {});
      context.commit("SET_USER_DATA_LOADING_STATUS", "notloading");
      context.commit("SET_USER_DATA_LOADING_ERROR", '');
      context.commit("SET_USER_DATA_LOADING_ERROR_STATUS", false);

      // clear the session storage if you log out
      sessionStorage.clear();
    },

    proxyAsUser(context, params) {
      // call lg-auth with the userid and csrep userid in order to proxy as that user
      // returns the new bearer token.
      // after this call getUserData again or whatever its called
      const userid = params.userid;
      const csrep_userid = params.csrep_userid;
      axios
        .post(process.env.VUE_APP_PROXY_R3,{
          userid: userid,
          csrep_userid: csrep_userid
        })
        .then(response => {
          const bearer = response.data.bearer;
          localStorage.setItem("bearer", bearer);
          localStorage.removeItem("accountHorizon");
          context.commit("SET_USER_DATA_LOADING_STATUS", "proxyLoading")
          context.commit("SET_PROXY_LOOKUP_FINISHED", true)
        })
        .catch(function (error) {
          let errorMessage = ""
          try {
            errorMessage = error.response.data.error.message != null ? "Server responded with error message: " + error.response.status + " " + error.response.data.error.message : "We had problems loading the user data for the account with an id of '" + userid + "' please try again later";
          } catch {
            errorMessage = "We had problems loading the user data for the account with an id of '" + userid + "' please try again later"
          }
          context.commit("SET_PROXY_ERROR_MESSAGE",errorMessage)
        });
    },

    retrieveProxyableUsers(context,params) {
      const lookup = params.lookup;
      const csrep_userid = params.csrep_userid;
      let url = process.env.VUE_APP_PROXY_R3 + "?lookup=" + lookup + "&csrep_userid=" + csrep_userid;

      axios
        .get(url)
        .then(response => {
          context.commit("SET_PROXY_USER_LIST", response.data);
        })
        .catch(function (error) {
          let errorMessage = ""
          try {
            errorMessage = error.response.data.error.message != null ? "Server responded with error message: " + error.response.status + " " + error.response.data.error.message : "We had problems loading users with the search '" + lookup + "'. Please try again later.";
          } catch {
            errorMessage = "We had problems loading users with the search '" + lookup + "'. Please try again later."
          }
          context.commit("SET_PROXY_ERROR_MESSAGE",errorMessage)
        });
    },
  }
}
