import axios from "@/utils/AxiosInstance.js"
import CacheHandler from "@/utils/CacheHandler";

export const scorecardsV2 = {
  namespaced: true,
  state: {

    newScorecardsListLoadingErrorStatus: false,
    newScorecardsListLoadingError: "",
    newScorecardsListLoadingStatus: "notLoading",
    newScorecardsList: [],

    newScorecardsListCountLoadingErrorStatus: false,
    newScorecardsListCountLoadingError: "",
    newScorecardsListCountLoadingStatus: "notLoading",
    newScorecardsListCount: [],

    scorecardLoadingItem: null,
    scorecardsLoadingItemErrorStatus: "",
    favoriteScorecardId: 0,

    doneProcessingList: [],
    doneProcessingListLoadingErrorStatus: false,
    doneProcessingListLoadingError: "",
    doneProcessingListLoadingStatus: "notLoading",

    scorecardsProcessing: [],
    scorecardsProcessingLoadingErrorStatus: false,
    scorecardsProcessingListLoadingError: "",
    scorecardsProcessingListLoadingStatus: "notLoading",

    scorecardAgentsListLoadingErrorStatus: false,
    scorecardAgentsListLoadingError: "",
    scorecardAgentsListLoadingStatus: "notLoading",
    scorecardAgentsList: [],

  },
  getters: {

    favoriteScorecardId: state => state.favoriteScorecardId,

    scorecardLoadingItem: state => state.scorecardLoadingItem,
    scorecardsLoadingItemErrorStatus: state => state.scorecardsLoadingItemErrorStatus,

    newScorecardsListLoadingErrorStatus: state => state.newScorecardsListLoadingErrorStatus,
    newScorecardsListLoadingError: state => state.newScorecardsListLoadingError,
    newScorecardsListLoadingStatus: state => state.newScorecardsListLoadingStatus,
    newScorecardsList: state => state.newScorecardsList,

    newScorecardsListCountLoadingErrorStatus: state => state.newScorecardsListCountLoadingErrorStatus,
    newScorecardsListCountLoadingError: state => state.newScorecardsListCountLoadingError,
    newScorecardsListCountLoadingStatus: state => state.newScorecardsListCountLoadingStatus,
    newScorecardsListCount: state => state.newScorecardsListCount,

    doneProcessingListLoadingErrorStatus: state => state.doneProcessingListLoadingErrorStatus,
    doneProcessingListLoadingError: state => state.doneProcessingListLoadingError,
    doneProcessingListLoadingStatus: state => state.doneProcessingListLoadingStatus,
    doneProcessingList: state => state.doneProcessingList,

    scorecardsProcessingLoadingErrorStatus: state => state.scorecardsProcessingLoadingErrorStatus,
    scorecardsProcessingListLoadingError: state => state.scorecardsProcessingListLoadingError,
    scorecardsProcessingListLoadingStatus: state => state.scorecardsProcessingListLoadingStatus,
    scorecardsProcessing: state => state.scorecardsProcessing,

    scorecardAgentsListLoadingErrorStatus: state => state.scorecardAgentsListLoadingErrorStatus,
    scorecardAgentsListLoadingError: state => state.scorecardAgentsListLoadingError,
    scorecardAgentsListLoadingStatus: state => state.scorecardAgentsListLoadingStatus,
    scorecardAgentsList: state => state.scorecardAgentsList,

  },
  mutations: {

    SET_FAVORITE_SCORECARD(state, id) {
      state.favoriteScorecardId = id;
    },
    SET_SCORECARD_LOADING_ITEM(state, item) {
      state.scorecardLoadingItem = item;
    },
    SET_SCORECARDS_ITEM_DATA_ERROR_STATUS(state, status) {
      state.scorecardsLoadingItemErrorStatus = status
    },

    SET_SCORECARDS_LIST_LOADING_ERROR_STATUS(state, status) {
      state.newScorecardsListLoadingErrorStatus = status
    },
    SET_SCORECARDS_LIST_LOADING_ERROR(state, error) {
      state.newScorecardsListLoadingError = error
    },
    SET_SCORECARDS_LIST_LOADING_STATUS(state, status) {
      state.newScorecardsListLoadingStatus = status
    },
    SET_SCORECARDS_LIST(state, scorecardsList) {
      state.newScorecardsList = scorecardsList
    },

    SET_SCORECARDS_LIST_COUNT_LOADING_ERROR_STATUS(state, status) {
      state.newScorecardsListCountLoadingErrorStatus = status
    },
    SET_SCORECARDS_LIST_COUNT_LOADING_ERROR(state, error) {
      state.newScorecardsListCountLoadingError = error
    },
    SET_SCORECARDS_LIST_COUNT_LOADING_STATUS(state, status) {
      state.newScorecardsListCountLoadingStatus = status
    },
    SET_SCORECARDS_LIST_COUNT(state, count) {
      state.newScorecardsListCount = count
    },

    SET_SCORECARDS_PROCESSING_LIST(state, scorecardsProcessing) {
      state.scorecardsProcessing = scorecardsProcessing
    },
    SET_SCORECARDS_PROCESSING_LIST_LOADING_ERROR_STATUS(state, status) {
      state.scorecardsProcessingLoadingErrorStatus = status
    },
    SET_SCORECARDS_PROCESSING_LIST_LOADING_ERROR(state, error) {
      state.scorecardsProcessingListLoadingError = error
    },
    SET_SCORECARDS_PROCESSING_LIST_LOADING_STATUS(state, status) {
      state.scorecardsProcessingListLoadingStatus = status
    },

    SET_DONE_PROCESSING_LIST(state, scorecardsList) {
      state.doneProcessingList = scorecardsList
    },
    SET_DONE_PROCESSING_LIST_LOADING_ERROR_STATUS(state, status) {
      state.doneProcessingListLoadingErrorStatus = status
    },
    SET_DONE_PROCESSING_LIST_LOADING_ERROR(state, error) {
      state.doneProcessingListLoadingError = error
    },
    SET_DONE_PROCESSING_LIST_LOADING_STATUS(state, status) {
      state.doneProcessingListLoadingStatus = status
    },

    SET_SCORECARD_AGENTS_LIST_LOADING_ERROR_STATUS(state, status) {
      state.scorecardAgentsListLoadingErrorStatus = status
    },
    SET_SCORECARD_AGENTS_LIST_LOADING_ERROR(state, error) {
      state.scorecardAgentsListLoadingError = error
    },
    SET_SCORECARD_AGENTS_LIST_LOADING_STATUS(state, status) {
      state.scorecardAgentsListLoadingStatus = status
    },
    SET_SCORECARD_AGENTS_LIST(state, scorecardAgentsList) {
      state.scorecardAgentsList = scorecardAgentsList
    },

  },
  actions: {
    checkScorecardProcessingFlags(context, payload) {
      //checks to see which scorecards are done processing
      let urlParams = '?'
      urlParams += '&start_date=' + payload.start_date
      urlParams += '&end_date=' + payload.end_date
      urlParams += '&grouping=' + payload.grouping
      urlParams += '&scorecards_editor=false'
      let localList = Object.assign([], context.getters.scorecardsProcessing)
      let finishedProcessing = []
      let serviceList = []
      let processList = context.getters.scorecardsProcessing
      context.commit("SET_DONE_PROCESSING_LIST", [])
      axios
        .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.id + urlParams)
        .then(response => {
          serviceList = response.data.data
          localList.forEach((item, index) => {
            let sd = serviceList.find(obj => {
              return obj.id == item.id
            })
            if (sd && sd.hasOwnProperty('processing')) {
              if (sd.processing != localList[index].processing) {
                //get the ids in the new list that are done processing
                let notInFinishedList = finishedProcessing.some(finished => finished.id == localList[index].id)
                if (!notInFinishedList) {
                  //replace the ids in the current list with the finished scorecard definitions
                  finishedProcessing.push(sd)
                  let index = processList.findIndex(object => {
                    return object.id == sd.id;
                  });
                  if (index > -1) {
                    //done processing - remove this id from the scorecard processing list...
                    processList.splice(index, 1)
                  }
                }
              }
            }
          })
          context.commit("SET_DONE_PROCESSING_LIST", finishedProcessing)
          context.commit("SET_DONE_PROCESSING_LIST_LOADING_ERROR_STATUS", false);
          context.commit("SET_DONE_PROCESSING_LIST_LOADING_ERROR", "");
          context.commit("SET_DONE_PROCESSING_LIST_LOADING_STATUS", "loaded");

        })
        .catch(function (error) {
          context.commit("SET_DONE_PROCESSING_LIST_LOADING_ERROR_STATUS", true);
          context.commit("SET_DONE_PROCESSING_LIST", [])
          context.commit("SET_DONE_PROCESSING_LIST_LOADING_STATUS", "failed");
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_DONE_PROCESSING_LIST_LOADING_ERROR", 'checkScorecardProcessingFlags(): ' + error_message)
        })
    },

    setFavoriteScorecardId(context, id) {
      let key = 'favorite_scorecard'
      context.commit("SET_FAVORITE_SCORECARD", id);
      if (CacheHandler.hasOwnProperty(key)) {
        CacheHandler.removeItem(key)
      }
      CacheHandler.setItem(key, id)
    },

    retrieveScorecard(context, payload) {
      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at
      urlParams += '&grouping=' + payload.grouping
      let url = process.env.VUE_APP_SCORECARDS_V2 + '/' + payload.scorecard.id + urlParams + '&scorecards_editor=false';

      axios
        .get(url)
        .then(response => {
          let localList = context.getters.newScorecardsList
          let itemData = response.data.data[0]
          itemData.loading = false

          let indexOfObject = localList.findIndex(object => {
            return object.id == payload.scorecard.id;
          });
          if (indexOfObject !== -1) {
            localList[indexOfObject] = itemData
          }
          context.commit("SET_SCORECARD_LOADING_ITEM", itemData)
        })
        .catch(function (error) {
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_SCORECARDS_ITEM_DATA_ERROR_STATUS", 'retrieveScorecard(): ' + error_message)
        })
    },

    retrieveScorecardsList(context, payload) {
      let scorecardsProcessing = []
      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at
      urlParams += '&grouping=' + payload.grouping
      context.commit("SET_SCORECARDS_PROCESSING_LIST", [])
      context.commit("SET_SCORECARDS_LIST_LOADING_STATUS", "loading");
      axios
        .get(process.env.VUE_APP_SCORECARDS_V2 + urlParams + '&scorecards_editor=false')
        .then(response => {

          let scorecardList = response.data.data
          scorecardList.forEach((item) => {
            if (item.processing > 0) {
              scorecardsProcessing.push(item)
            }
          })

          scorecardList = scorecardList.map(obj => ({...obj, loading: true}))
          scorecardList.sort(
            (a, b) => (a.title > b.title && 1) || -1
          );

          context.commit("SET_SCORECARDS_LIST", scorecardList);
          context.commit("SET_SCORECARDS_LIST_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARDS_LIST_LOADING_ERROR", "");
          context.commit("SET_SCORECARDS_LIST_LOADING_STATUS", "loaded");

          context.commit("SET_SCORECARDS_PROCESSING_LIST", scorecardsProcessing)
          context.commit("SET_SCORECARDS_PROCESSING_LIST_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARDS_PROCESSING_LIST_LOADING_ERROR", "");
          context.commit("SET_SCORECARDS_PROCESSING_LIST_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_SCORECARDS_LIST_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARDS_LIST", []);
          context.commit("SET_SCORECARDS_LIST_LOADING_ERROR_STATUS", true);

          context.commit("SET_SCORECARDS_PROCESSING_LIST_LOADING_ERROR_STATUS", true);
          context.commit("SET_SCORECARDS_PROCESSING_LIST", [])
          context.commit("SET_SCORECARDS_PROCESSING_LIST_LOADING_STATUS", "failed");
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_SCORECARDS_LIST_LOADING_ERROR", 'retrieveScorecardsList(): ' + error_message)
          context.commit("SET_SCORECARDS_PROCESSING_LIST_LOADING_ERROR", 'retrieveScorecardsList(): ' + error_message)
        });
    },

    retrieveScorecardAgentsList(context, payload) {
      let scorecard_id = payload.scorecard_id
      let dynalabel_id = payload.dynalabel_id
      context.commit("SET_SCORECARD_AGENTS_LIST_LOADING_STATUS", "loading");
      axios
        .get(process.env.VUE_APP_SCORECARDS_V2 + '/' + scorecard_id + '/dynalabels/' + dynalabel_id + '/agent-data/')
        .then(response => {
          let scorecardAgentsList = response.data.data
          context.commit("SET_SCORECARD_AGENTS_LIST", scorecardAgentsList);
          context.commit("SET_SCORECARD_AGENTS_LIST_LOADING_STATUS", "loaded");
          context.commit("SET_SCORECARD_AGENTS_LIST_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARD_AGENTS_LIST_LOADING_ERROR", "");
        })
        .catch(function (error) {
          context.commit("SET_SCORECARD_AGENTS_LIST", []);
          context.commit("SET_SCORECARD_AGENTS_LIST_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARD_AGENTS_LIST_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_SCORECARD_AGENTS_LIST_LOADING_ERROR", 'retrieveScorecardsList(): ' + error_message)
        });
    },

    getScorecardsListCount(context, payload) {
      let urlParams = '?'
      urlParams += '&start_date=' + payload.starts_at
      urlParams += '&end_date=' + payload.ends_at
      urlParams += '&grouping=' + payload.grouping
      context.commit("SET_SCORECARDS_LIST_COUNT", 0)
      context.commit("SET_SCORECARDS_LIST_COUNT_LOADING_STATUS", "loading");
      axios
        .get(process.env.VUE_APP_SCORECARDS_V2 + urlParams + '&scorecards_editor=false')
        .then(response => {
          context.commit("SET_SCORECARDS_LIST_COUNT", response.data.data.length);
          context.commit("SET_SCORECARDS_LIST_COUNT_LOADING_ERROR_STATUS", false);
          context.commit("SET_SCORECARDS_LIST_COUNT_LOADING_ERROR", "");
          context.commit("SET_SCORECARDS_LIST_COUNT_LOADING_STATUS", "loaded");
        })
        .catch(function (error) {
          context.commit("SET_SCORECARDS_LIST_COUNT_LOADING_STATUS", "failed");
          context.commit("SET_SCORECARDS_LIST_COUNT", 0);
          context.commit("SET_SCORECARDS_LIST_COUNT_LOADING_ERROR_STATUS", true);
          let error_message = JSON.stringify(error)
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('message')) error_message = error.error.message
          if (error.hasOwnProperty(error) && error.error.hasOwnProperty('code')) error_message += ` (${error.error.code})`
          context.commit("SET_SCORECARDS_LIST_COUNT_LOADING_ERROR", 'getScorecardsListCount(): ' + error_message)
        });
    },

  }
}
