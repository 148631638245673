<template>
  <v-container class="pt-0">
    <v-row>
      <v-col class="pt-0">
        <h2 class="blue-grey--text text--darken-1 text-center mt-0">
          Statistics
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-flex class="text-center">
          <score-radial
            v-if="dataAvailable"
            :score="averageCallScore"
            :radius="50"
          ></score-radial>
          <v-progress-circular
            v-else
            indeterminate
            size="30"
            color="primary"
          ></v-progress-circular>
        </v-flex>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <chart-team-a-t-t-card
          v-if="dataAvailable"
          class="pa-0"
          :timeDisplay="timeDisplay"
          :team_att="averageTalktime"
        ></chart-team-a-t-t-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <chart-team-a-c-v-card
          v-if="dataAvailable"
          class="pa-0"
          :team_acv="avgCallVolume"
        ></chart-team-a-c-v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ChartTeamACVCard from "../ChartTeamACVCard.vue";
import ChartTeamATTCard from "../ChartTeamATTCard.vue";
import ScoreRadial from "./ScoreRadial.vue";

export default {
  components: { ScoreRadial, ChartTeamATTCard, ChartTeamACVCard },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("scorecardsV2Calls", [
      "callScorecardDataV2",
      "allCallScorecardDataV2",
      "allCallScorecardDataV2LoadingStatus",
      "allCallScorecardDataV2LoadingErrorStatus",

      "aggregatedScorecardAgentData",
      "aggregatedScorecardAgentDataLoadingStatus",
      "aggregatedScorecardAgentDataLoadingError",
      "aggregatedScorecardAgentDataLoadingErrorStatus",

      "scorecardV2Data",
      "scorecardV2DataLoadingStatus",
      "scorecardV2DataLoadingError",
      "scorecardV2DataLoadingErrorStatus",

      "favoriteScorecardTab"
    ]),
    timeDisplay() {
      return (this.scorecardV2Data.hasOwnProperty("items") && this.scorecardV2Data.items.length > 0 ) ? this.scorecardV2Data.items[0].metrics.timeDisplay : 0;
    },
    dataAvailable() {
      return this.scorecardV2DataLoadingStatus == "loaded";
    },
    averageCallScore() {
      return this.scorecardHasItems
        ? this.scorecardV2Data.items[0].metrics.callScoreAvg
        : 0;
    },
    scorecardAvailable() {
      return this.scorecardV2Data.hasOwnProperty("items") &&
        this.scorecardV2Data.items.length > 0
        ? true
        : false;
    },

    averageTalktime() {
      //we need to access 'items' like this since presentation-service looks at an array at the aggregate level
      return this.scorecardHasItems
        ? Number(this.scorecardV2Data.items[0].metrics.averageDuration)
        : 0;
    },

    scorecardHasItems() {
      return (
        this.scorecardV2Data.hasOwnProperty("items") &&
        this.scorecardV2Data.items.length > 0
      );
    },
    avgCallVolume() {
      return this.scorecardHasItems
        ? Math.round(
            Number(this.scorecardV2Data.items[0].metrics.averageCallVolume)
          )
        : 0;
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
div.scorecard-tile-outer-container {
  padding: 1.25rem 1rem 0.25rem 1rem;
  margin-right: 1rem;
}
</style>
