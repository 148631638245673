import axios from "@/utils/AxiosInstance.js";

export const deliveries = {
    namespaced: true,

    state: {
        // delivery data
        deliveryList: [],
        deliveryListLoadingStatus:'Not Loading',
        updateDeliveriesFlag: true,
        reGetDeliveries: false,

        getDeliveryError: false,
        createDeliveryError: false,

        removeDelivery: false,
    },

    getters: {
        deliveryList: state => state.deliveryList,
        deliveryListLoadingStatus: state => state.deliveryListLoadingStatus,
        updateDeliveriesFlag: state => state.updateDeliveriesFlag,
        reGetDeliveries: state => state.reGetDeliveries,

        getDeliveryError: state => state.getDeliveryError,
        createDeliveryError: state => state.createDeliveryError,

        removeDelivery: state => state.removeDelivery,
    },

    mutations: {
        SET_DELIVERY_LIST(state, deliveryList) {
            state.deliveryList = deliveryList
        },
        SET_DELIVERY_LIST_LOADING_STATUS(state, status) {
            state.deliveryListLoadingStatus = status
          },
        UPDATE_DELIVERIES_FLAG(state,flag) {
            state.updateDeliveriesFlag = flag
        },
        RE_GET_DELIVERIES(state,flag) {
            state.reGetDeliveries = flag
        },
        CREATE_DELIVERY_ERROR(state, flag) {
            state.createDeliveryError = flag
        },
        GET_DELIVERY_ERROR(state, flag) {
            state.getDeliveryError = flag
        },
        REMOVE_DELIVERY(state, id) {
            state.removeDelivery = id
        },
    },
    actions: {
        setUpdateDeliveryFlag(context,flag) {
            context.commit("UPDATE_DELIVERIES_FLAG",flag);
        },
        setReGetDeliveries(context,flag) {
            context.commit("RE_GET_DELIVERIES",flag);
        },
        setCreateDeliveryError(context, flag) {
            context.commit("CREATE_DELIVERY_ERROR",flag);
        },
        setGetDeliveryError(context, flag) {
            context.commit("GET_DELIVERY_ERROR",flag);
        },
        setRemoveDelivery(context, id) {
            context.commit("REMOVE_DELIVERY",id);
        },
        getDeliveryList(context) {
            context.commit("SET_DELIVERY_LIST", []);
            context.commit("SET_DELIVERY_LIST_LOADING_STATUS", "loading")

            let url = process.env.VUE_APP_DELIVERIES

            axios
              .get(url, { params: {
                type: "user",
              }})
              .then(response => {
                // format response to have the information the frontend is looking for
                let deliveries= response.data.deliveries
                for(let i=0; i<deliveries.length; i++) {

                    deliveries[i].delivery_day_of_month = deliveries[i].delivery_day_of_month || 1

                    let frequencyList = ["Daily", "Weekly", "Monthly"]
                    deliveries[i].frequency = frequencyList[deliveries[i].frequency]
                    let daysList = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
                    deliveries[i].delivery_day = daysList[deliveries[i].delivery_day]
                    let delivery_type_list = ["Email","SFTP","Email (Per Agent)"]
                    deliveries[i].delivery_type = delivery_type_list[deliveries[i].delivery_type]

                    if(deliveries[i].delivery_type == "SFTP") {
                        deliveries[i].hostname = deliveries[i].host
                        deliveries[i].email = []
                        deliveries[i].port = deliveries[i].port == null ? "" : deliveries[i].port
                    }

                    if(deliveries[i].email == undefined) {
                        deliveries[i].email = []
                    }

                    let timezone_list = {"EST": "Eastern", "CST": "Central", "MST": "Mountain","PST": "Pacific"}
                    deliveries[i].timezone = timezone_list[deliveries[i].timezone]

                    if(deliveries[i].delivery_hour >= 12) {
                        deliveries[i].ampm = "PM"
                    } else {
                        deliveries[i].ampm = "AM"
                    }

                    if(deliveries[i].delivery_hour >= 13) {
                        deliveries[i].delivery_hour = deliveries[i].delivery_hour - 12
                    }

                    if(deliveries[i].delivery_hour == 0) {
                        deliveries[i].delivery_hour = 12
                    }

                    let routepath = "/"
                    deliveries[i].routepath = ""

                    if(deliveries[i].type == 0) {
                        routepath += "scorecards-v2/"
                    } else {
                        routepath += "insights/"
                    }
                    routepath += deliveries[i].type_id + "/"
                    if(deliveries[i].export_path > 0) {
                        routepath += "dynalabels/"
                    }
                    if(deliveries[i].export_path > 1) {
                        if(deliveries[i].dynalabel_id != null) {
                            routepath += deliveries[i].dynalabel_id + "/"
                        }
                        routepath += "agents/"
                    }
                    if(deliveries[i].export_path > 2) {
                        // calls
                        if(deliveries[i].agent_id != null) {
                            routepath += deliveries[i].agent_id + "/"
                        }
                        routepath += "calls/"
                    }

                    deliveries[i].routepath = routepath

                    if (deliveries[i].filters == null ) {
                        deliveries[i].filters = "{}"
                    }

                    if(deliveries[i].delivery_files == null) {
                      if(deliveries[i].type == 2) {
                        deliveries[i].delivery_files = {detail: true, summary: true}
                      } else {
                        deliveries[i].delivery_files = {detail: true, summary: true, tags: true, custom_metadata: true}
                      }
                    } else {
                      deliveries[i].delivery_files = JSON.parse(deliveries[i].delivery_files)
                    }

                  deliveries[i].delivery_files = reorderObjKeys(deliveries[i].delivery_files)

                }
                context.commit("SET_DELIVERY_LIST", deliveries);
                context.commit("UPDATE_DELIVERIES_FLAG",true);
                context.commit("SET_DELIVERY_LIST_LOADING_STATUS", "loaded")

              })
              .catch(function (error) {
                context.commit("GET_DELIVERY_ERROR", true);
              });

        },
        async createDelivery(context,reportSchedule) {
            // get the route stuff from the routepath
            let path_array = reportSchedule.routepath.split("?")[0].split("/");
            let export_path = reportSchedule.export_path
            let type = path_array.includes("insights") ? 1 : 0;
            let type_id = path_array[2]
            let dynalabel_id = path_array[4]==undefined ? null : path_array[4]
            let agent_id = path_array[6]==undefined ? null : path_array[6]


            let hour = formatDeliveryTime(reportSchedule.delivery_hour, reportSchedule.ampm)

            let filters = "";
            if(type == 1) {
                filters = reportSchedule.filters.subjectiveSet==undefined ? "{}" : JSON.stringify(reportSchedule.filters.subjectiveSet[0])
            } else {
                filters = reportSchedule.filters
            }

            let params = {
                "name": reportSchedule.name,
                "description": reportSchedule.description,
                "filters": filters,
                "type": type,
                "delivery_type": {"Email": 0, "SFTP": 1, "Email (Per Agent)": 2}[reportSchedule.delivery_type],
                "frequency": {"Daily": 0,"Weekly": 1,"Monthly": 2}[reportSchedule.frequency],
                "delivery_day_of_month": reportSchedule.delivery_day_of_month,
                "delivery_hour": hour,
                "delivery_day": {"Sunday":0,"Monday":1,"Tuesday":2,"Wednesday":3,"Thursday":4,"Friday":5,"Saturday":6}[reportSchedule.delivery_day],
                "delivery_files": reportSchedule.delivery_files,
                "timezone": {"Eastern": "EST", "Central": "CST", "Mountain": "MST", "Pacific": "PST"}[reportSchedule.timezone],
                "type_id": type_id,
                "dynalabel_id": dynalabel_id,
                "agent_id": agent_id,
                "export_path": export_path,
                "speaker": reportSchedule.speaker,
                "phrase": reportSchedule.phrase,
                "pdf_delivery": reportSchedule.pdf_delivery,
            }

            if(reportSchedule.delivery_type == "Email") {
                params = Object.assign(params, {
                    "email": reportSchedule.email
                })
            } else if(reportSchedule.delivery_type == "SFTP"){
                params = Object.assign(params, {
                    "host": reportSchedule.hostname,
                    "username": reportSchedule.username,
                    "password": reportSchedule.password,
                    "port": reportSchedule.port,
                    "folder": reportSchedule.folder,
                })
            }
            let url = process.env.VUE_APP_DELIVERIES
            let rtn = true
            await axios
                .post(url,params)
                .then(response => {
                    // need to get the deliveries again.
                    context.commit("RE_GET_DELIVERIES",true);
                }).catch(function (error) {
                    // catch an error and display? maybe just say no deliveries?
                    rtn = false
                });

            return rtn
            // format data from frontend to become what the backend wants
            // needs all the stuff but not id
        },

        async updateDelivery(context,reportSchedule) {

            let type_id = reportSchedule.type_id
            let dynalabel_id = reportSchedule.dynalabel_id==undefined ? null : reportSchedule.dynalabel_id
            let agent_id = reportSchedule.agent_id==undefined ? null : reportSchedule.agent_id

            let hour = formatDeliveryTime(reportSchedule.delivery_hour, reportSchedule.ampm)

            let params = {
                "name": reportSchedule.name,
                "description": reportSchedule.description,
                "filters": reportSchedule.filters,
                "type": reportSchedule.type,
                "delivery_type": {"Email": 0, "SFTP": 1, "Email (Per Agent)": 2}[reportSchedule.delivery_type],
                "frequency": {"Daily": 0,"Weekly": 1,"Monthly": 2}[reportSchedule.frequency],
                "delivery_day_of_month": reportSchedule.delivery_day_of_month,
                "delivery_hour": hour,
                "delivery_day": {"Sunday":0,"Monday":1,"Tuesday":2,"Wednesday":3,"Thursday":4,"Friday":5,"Saturday":6}[reportSchedule.delivery_day],
                "delivery_files": reportSchedule.delivery_files,
                "timezone": {"Eastern": "EST", "Central": "CST", "Mountain": "MST", "Pacific": "PST"}[reportSchedule.timezone],
                "type_id": type_id,
                "dynalabel_id": dynalabel_id,
                "agent_id": agent_id,
                "export_path": reportSchedule.export_path,
                "speaker": reportSchedule.speaker,
                "phrase": reportSchedule.phrase,
                "pdf_delivery": reportSchedule.pdf_delivery,
            }

            if(reportSchedule.delivery_type == "Email") {
                params = Object.assign(params, {
                    "email": reportSchedule.email
                })
            } else if(reportSchedule.delivery_type == "SFTP"){
                params = Object.assign(params, {
                    "host": reportSchedule.hostname,
                    "username": reportSchedule.username,
                    "password": reportSchedule.password,
                    "port": reportSchedule.port,
                    "folder": reportSchedule.folder,
                })
            }
            let url = process.env.VUE_APP_DELIVERIES + reportSchedule.id
            let rtn = true

            await axios
                .put(url,params)
                .then(response => {
                    // need to get the deliveries again.
                    context.commit("RE_GET_DELIVERIES",true);
                }).catch(function (error) {
                    // catch an error and display? maybe just say no deliveries?
                    rtn = false
                });

            return rtn
        },
        async deleteDelivery(context, delivery_id) {
            let url = process.env.VUE_APP_DELIVERIES + delivery_id
            let rtn = true
            axios
            .delete(url)
            .then(response => {
                // need to get the deliveries again.
                if(response.status==200 && response.data.success == true) {
                  context.commit("REMOVE_DELIVERY",delivery_id);
                  context.dispatch('getDeliveryList')
                }
            }).catch(function (error) {
                rtn = false
            });

            return rtn
        },
        getDeliveryHistory(context) {
            // TODO: get the delivery history. phase two?
        },
        deleteStrandedDeliveries(context, params) {
            let url = process.env.VUE_APP_VALIDATE_DELIVERIES
            let axios_params = {
                request_type: params.requestType,
                delivery_type: params.deliveryType,
                id: params.scorecardId,
            }

            if(params.requestType == 'update') {
                axios_params['dynalabel_ids'] = params['dynalabelIds']
            }
            axios
                .post(url, axios_params)
                .then(response => {
                    if(response.data.num_deleted > 0) {
                        // need to get the deliveries again.
                        context.commit("RE_GET_DELIVERIES",true);
                    }
                })
        }
    },
};

function formatDeliveryTime(hour,ampm) {
    if(ampm == "PM" && hour != 12) {
        hour += 12;
    } else if(ampm == "AM" && hour == 12) {
        hour -= 12;
    }
    return hour
}

function reorderObjKeys(obj) {
  let orderedKeys = ['detail', 'summary']
  if (obj.hasOwnProperty('tags')) orderedKeys.push('tags')
  if (obj.hasOwnProperty('custom_metadata')) orderedKeys.push('custom_metadata')
  let reorderedObj = {}

  orderedKeys.forEach(key => {
    if (obj.hasOwnProperty(key)) {
      reorderedObj[key] = obj[key]
    }
  })

  return reorderedObj
}
