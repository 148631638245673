<template>
  <v-row style="flex-wrap: nowrap;">
    <v-col
      cols="1"
      style="min-width: 100px; max-width: 100%;"
      class="flex-grow-0 flex-shrink-0"
    >
      <v-card
        flat
        v-text="channelSpeaker"
        class="speaker pa-1"
        :class="channelClass"
      ></v-card>
    </v-col>
    <v-col
      cols="11"
      class="flex-grow-1 flex-shrink-0">
      <v-card
        flat
        :data-startsAt="startsAt"
        :data-endsAt="endsAt"
        :data-confidence="confidence"
        :class="'pa-1 phrase sentiment-' + sentiment"
        v-html="modText"
      >
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LoadingMask from "@/components/LoadingMask";

export default {
  name: "SearchHit",
  components: {
    LoadingMask
  },

  props: {
    channel: {
      type: Number,
      default: 0
    },
    confidence: {
      type: Number,
      default: 0.0
    },
    emotion: String,
    endsAt: {
      type: Number,
      default: 0.0
    },
    text: {
      type: String,
      default: ""
    },
    sentiment: {
      type: String,
      default: "Neutral"
    },
    startsAt: {
      type: Number,
      default: 0.0
    }
  },

  computed: {
    modText() {
      return this.text.replace(/\<em\>/g,'<span style="background-color: rgba(255,255,51,.5);font-weight: bold;padding:.5em;">').replace(/\<\/em\>/g,'</span>')
    },
    channelSpeaker() {
      return "Speaker " + (parseInt(this.channel) + 1) + ": ";
    },
    channelClass() {
      return this.channel == 1 ? "agent" : "customer";
    }
  }
};
</script>

<style lang="scss" scoped>

  .speaker {
    line-height: 2rem;
    font-size: .8rem;
    color: rgba(0,0,0,.5);
    font-weight: 900;
    font-variant: all-petite-caps;
  }

  .speaker.customer {
    opacity: 0.6;
  }

  .phrase {
    line-height: 2rem;
    font-size: 1rem;
  }



</style>
