<template>
  <div>
    <h3 class="option-style"
    :class="channels +'-header'">
      {{ getDisplayFormat(instance) }}
    </h3>
    <v-select
      dense
      class="option-style"
      :class="channels + '-select'"
      outlined
      :items="options"
      v-model="value"
      @change="toggleSelected(instance, value)"
    ></v-select>
  </div>
</template>

<script>
import DropdownMultiselect from "@/components/Filters/DropdownMultiselect.vue";
import {mapGetters} from "vuex"
export default {
  name: "SubjectiveSelector",
  components: {
    DropdownMultiselect
  },
  props: {
    channels: {
      type: String,
      default: ""
    },
    instance: {
      type: String,
      default: ""
    },
    selectedValue: {
      type: String,
      default: "Any"
    },
    options: {
      type: Array,
      default: function() { return [] }
    }
  },
  data() {
    return {
      selected: "",
      value: "Any"
    };
  },
  computed: {
    ...mapGetters('filters',
        [ 
          'subjectiveSet',
        ]),
    bindValue() {
      value = this.selectedValue
    }
  },
  methods: {
    getDisplayFormat(display) {
      let newDisplay = display.charAt(0).toUpperCase() + display.slice(1);
      newDisplay = newDisplay.replace(/([A-Z])/g, " $1").trim();
      return newDisplay;
    },
    toggleSelected(item, value) {
      let key = item;
      let arr = {};
      arr[key] = value.trim();
      this.selected = arr;
      this.$emit("update-selections", this.selected);
    },
    setValue(o) {
      this.value = this.selectedValue
    }
  },
  mounted() {
    if (this.subjectiveSet!=null) {
      this.setValue()
    }
  },
  watch: {
    selectedValue: "setValue"
  }
};
</script>

<style scoped>

.option-style {
  display: inline-block;
  padding-left: 4rem;
  padding-right: 4rem;
  width: 20rem;
}
h3 {
  font-size: .9rem;
  font-weight: 600;
  padding-bottom: 1rem;
}


.single-header {
  margin-left: 10rem;
  margin-right: 10rem;
}
.single-select {
  width: 25rem;
  margin-left: 10rem;
  margin-left: 10rem;
}


</style>
