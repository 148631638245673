<template>
  <div id="Paginator">
    <vue-ads-pagination
      :total-items="totalResults"
      :max-visible-pages="maxVisiblePages"
      :page="page"
      :loading="loading"
      :items-per-page="itemsPerPage"
      @page-change="pageChange"
      @range-change="rangeChange"
    >
      <template slot-scope="props">
        <div class="vue-ads-pr-2 vue-ads-leading-loose">
          Items {{ props.start }} to {{ props.end }} out of {{ props.total }}
        </div>
      </template>
      <template slot="buttons" slot-scope="props">
        <vue-ads-page-button
          v-for="(button, key) in props.buttons"
          :key="key"
          v-bind="button"
          @page-change="pageChange(button.page)"
        />
      </template>
    </vue-ads-pagination>
  </div>
</template>

<script>
import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css";

import VueAdsPagination, { VueAdsPageButton } from "vue-ads-pagination";

export default {
  name: "Paginator",

  components: {
    VueAdsPagination,
    VueAdsPageButton
  },

  data() {
    return {
      page: 0
    };
  },

  props: {
    totalItems: {
      type: Number,
      default: 25
    },
    maxVisiblePages: {
      type: Number,
      default: 3
    },
    itemsPerPage: {
      type: Number,
      default: 5
    },
    loading: Boolean
  },

  methods: {
    pageChange(page) {
      this.page = page;
      this.$emit("PAGE_CHANGED", page);
    },
    rangeChange(start, end) {
      this.$emit("RANGE_CHANGED", start, end);
    },
    resetPagination() {
      this.page = 0;
    }
  },
  computed: {
    totalResults() {
      return this.totalItems || 1;
    }
  },
  created() {
    this.$root.$on("RESET_PAGINATION", this.resetPagination);
  }
};
</script>
