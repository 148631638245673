import axios from "axios";
import CacheHandler from "@/utils/CacheHandler";
import { store } from "@/store/index.js";

export const notifications = {
    namespaced: true,
    state: {
        notificationsList: [],
        notificationsListLoadingStatus: 'notLoading',
        notificationsListLoadingError: '',
        notificationsListLoadingErrorStatus: false,

        transcriptFileInfo: "",
        insightsFileInfo: "",
        scorecardFileInfo: "",
    },
    getters: {
        notificationsList: state => state.notificationsList,
        notificationsListLoadingStatus: state => state.notificationsListLoadingStatus,
        notificationsListLoadingError: state => state.notificationsListLoadingError,
        notificationsListLoadingErrorStatus: state => state.notificationsListLoadingErrorStatus,

        transcriptFileInfo: state => state.transcriptFileInfo,
        insightsFileInfo: state => state.insightsFileInfo,
        scorecardFileInfo: state => state.scorecardFileInfo,

    },
    mutations: {
        SET_NOTIFICATIONS_LIST(state, notificationsList) {
            state.notificationsList = notificationsList
        },
        SET_NOTIFICATIONS_LIST_LOADING_STATUS(state, status) {
            state.notificationsListLoadingStatus = status
        },
        SET_NOTIFICATIONS_LIST_LOADING_ERROR(state, error) {
            state.notificationsListLoadingError = error
        },
        SET_NOTIFICATIONS_LIST_LOADING_ERROR_STATUS(state, status) {
            state.notificationsListLoadingErrorStatus = status
        },

        SET_TRANSCRIPT_FILE_INFO(state, transcriptFileInfo) {
            state.transcriptFileInfo = transcriptFileInfo
        },
        SET_INSIGHTS_FILE_INFO(state, insightsFileInfo) {
            state.insightsFileInfo = insightsFileInfo
        },
        SET_SCORECARD_FILE_INFO(state, scorecardFileInfo) {
          state.scorecardFileInfo = scorecardFileInfo
        },

    },
    actions: {
        checkTranscriptExportProcess(context, params) {
            // every 30 seconds
            // check if process checker exists
            // processsing flag for dyna labels as an example
            let processChecker = setInterval(interval => {
                let headers = {headers: { Authorization: 'bearer ' + localStorage.getItem("bearer") }}
                let process_url =
                    process.env.VUE_APP_EXPORT_PROCESSING +
                    params.id +
                    "?process=transcripts";
                axios.get(process_url, headers).then(response => {

                    let processing = response.data.processing
                    if (processing==undefined) {

                        let contents = {
                            processing: false,
                            url: response.data.url,
                            id: params.id,
                            date: params.date,
                            user_id: params.user_id,
                            accountNumber: params.accountNumber,
                            accountName: params.accountName,
                            agent: params.agent,
                            callerNumber: params.callerNumber,
                            callDirection: params.callDirection,
                            calledNumber: params.calledNumber,
                            callDate: params.callDate,
                            type: "transcript"
                        }

                        context.commit("SET_TRANSCRIPT_FILE_INFO", contents)
                        clearInterval(processChecker);
                    }
                });
            }, 30000);
        },
      checkInsightsExportProcess(context, params) {
        // check if process checker exists
        // processsing flag for dyna labels as an example
        let processChecker = setInterval(interval => {
          let headers = {headers: { Authorization: 'bearer ' + localStorage.getItem("bearer") }}
          let process_url =
            process.env.VUE_APP_EXPORT_PROCESSING +
            params.id +
            "?process=insights&guid=" + params.guid;
          axios.get(process_url, headers).then(response => {
            let processing = response.data.processing
            if (processing == undefined) {
              let contents = {
                id: params.id,
                guid: params.guid,
                processing: false,
                url: response.data.url,
                user_id: params.user_id,
                title: params.title,
                dateRange: params.dateRange,
                type: 'insights'
              }
              context.commit("SET_INSIGHTS_FILE_INFO", contents)
              clearInterval(processChecker);
            }
          });
        }, 30000);
      },
      checkScorecardExportProcess(context, params) {
        // check if process checker exists
        // processsing flag for dyna labels as an example
     
        let processChecker = setInterval(interval => {
          let headers = {headers: { Authorization: 'bearer ' + localStorage.getItem("bearer") }}
          let process_url =
            process.env.VUE_APP_EXPORT_PROCESSING +
            params.id +
            "?process=scorecard&guid=" + params.guid;
          
          axios.get(process_url, headers).then(response => {
            let processing = response.data.processing
            if (processing == undefined) {
              let contents = {
                id: params.id,
                guid: params.guid,
                processing: false,
                url: response.data.url,
                user_id: params.user_id,
                title: params.title,
                dateRange: params.dateRange,
                type: 'scorecard'
              }
              context.commit("SET_SCORECARD_FILE_INFO", contents)
              clearInterval(processChecker);
            }
          });
        }, 30000);
      },
      retrieveNotificationList(context, user_id) {
        let processList = []
        let notification = {}
        let keys = ['transcript_', 'insights_', 'scorecard_', 'label']
        if (user_id != undefined) {
          for (const property in localStorage) {
          
            let userId = store.getters["users/userData"].id
            let firstIndexOf = property.indexOf("_")
            let lastIndexOf = property.lastIndexOf("_")
            let key = property.slice(firstIndexOf + 1, lastIndexOf + 1) ;
            if(property.slice(0, firstIndexOf) == userId && keys.includes(key)) {
              notification = JSON.parse(CacheHandler.getItem(property.slice(firstIndexOf + 1)))
              let date = new Date(notification.date)
              let comparison_date = new Date()
              let msBetweenDates = Math.abs(comparison_date.getTime() - date.getTime());
              let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
              if (notification.processing && key == 'transcript_') {
                context.dispatch('checkTranscriptExportProcess', notification)
              }

              if (notification.processing && key == 'insights_') {
                context.dispatch('checkInsightsExportProcess', notification)
              }

              if (notification.processing && key == 'scorecard_') {
                context.dispatch('checkScorecardExportProcess', notification)
              }

              if (hoursBetweenDates >= 24) {
                if (key == 'transcript_')
                  CacheHandler.removeItem(key + notification.id)
                else
                  CacheHandler.removeItem(key + notification.guid)
              } else if (notification.user_id == user_id) {
                processList.push(notification)
              }
            }
          }
        }
        context.commit("SET_NOTIFICATIONS_LIST", processList)
      },
      clearNotificationsList(context) {
          context.commit("SET_NOTIFICATIONS_LIST", [])
      },
      updateNotificationsList(context, params) {
          let notificationsList = context.getters.notificationsList
          let notification = (notificationsList.find(c => (c.type == 'transcript' ? c.id === params.id : c.guid === params.guid)));
          if (notification == undefined) {
              notificationsList.push(params)
              if (params.type == 'transcript') {
                CacheHandler.setItem('transcript_' + params.id, JSON.stringify(params));
              } else {
                CacheHandler.setItem(params.type + '_' + params.guid, JSON.stringify(params));
              }
              
          } else {
              for (let i = 0; i < notificationsList.length; i++) {
                  let type = notificationsList[i].type
                  if (type == 'transcript') {
                      if (notificationsList[i].id == params.id) {
                        //processing and file used in transcript downloads
                        notificationsList[i].processing = params.processing
                        if (!params.processing) {
                          notificationsList[i].url = params.url
                          CacheHandler.setItem('transcript_' + params.id, JSON.stringify(params));
                        }
                      }
                  } else {
                    if (notificationsList[i].guid == params.guid) {
                      //processing and file used in downloads
                      notificationsList[i].processing = params.processing
                      if (!params.processing) {
                        notificationsList[i].url = params.url
                        CacheHandler.setItem(type + '_' + params.guid, JSON.stringify(params));
                      }
                     
                    }
                  }
              }
          }
          context.commit("SET_NOTIFICATIONS_LIST", notificationsList)
          if (params.type == 'transcript' && params.processing) {
              context.dispatch('checkTranscriptExportProcess', params)
          }
          if (params.type == 'insights' && params.processing) {
            context.dispatch('checkInsightsExportProcess', params)
          }
          if (params.type == 'scorecard' && params.processing) {
            context.dispatch('checkScorecardExportProcess', params)
          }
      },
      removeNotificationsListItem(context, params) {
          let indexOfObject = context.getters.notificationsList.findIndex(object => {
            return (params.type == "transcript" ? object.id == params.id : object.guid == params.guid);
          });
          let localList = context.getters.notificationsList
          localList.splice(indexOfObject, 1);

          if (params.type == "transcript") {
            CacheHandler.removeItem('transcript_' + params.id);
          }
          if (params.type == "insights") {
            CacheHandler.removeItem('insights_' + params.guid);
          }
          if (params.type == "scorecard") {
            CacheHandler.removeItem('scorecard_' + params.guid);
          }
          context.commit("SET_NOTIFICATIONS_LIST", localList)

      },
    }
}
