<template>
  <div>
    <v-dialog v-model="dialog" persistent width="50vw">
      <v-card>
        <v-form>
          <v-card-title class="pb-0">
            {{ editorHeader }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="d-flex justify-center pl-6 pr-6 ma-0">
                <glue-button-hit-non-hit
                  ref="searchGlue"
                  :glue="glue"
                ></glue-button-hit-non-hit>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="justify-center ml-6 mr-6 pl-6 pr-6 ma-0 rounded border">
                <v-text-field
                  dense
                  v-model="dataTableSearch"
                  placeholder="find stuff"
                  append-icon="mdi-magnify"
                  clearable
                  @click:clear="clearSearch()"
                ></v-text-field>
                <v-data-table
                  v-model="dataTableSelected"
                  :headers="dataTableHeaders"
                  :items="dataTableData"
                  show-select
                  item-key="value"
                  :single-select="true"
                  height="40vh"
                  disable-pagination
                  :hide-default-footer="true"
                  :search="dataTableSearch"
                  class="dl-dt"
                >
                  <template v-slot:header.name="{ header }">
                    {{ header.text }}
                  </template>
                  <template v-slot:header.search_class="{ header }">
                    {{ header.text }}
                  </template>
                  <template v-slot:item.search_class="{ item }">
                    <v-icon v-if="item.search_class=='phonetic'" color="purple" name='phonetic-search' title="Search Type: Phonetic">mdi-access-point</v-icon>
                    <v-icon v-else-if="item.search_class=='transcription'" color="teal" title="Search Type: Transcript">mdi-text-box</v-icon>
                    <v-icon v-else color="primary" title="Search Type could not be identified">mdi-help-circle-outline</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="closeCancel">Close/Cancel</v-btn>
            <v-btn small color="primary" @click="save" :disabled="!canSave">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import GlueButtonHitNonHit from "@/components/DynaLabels/GlueButtonHitNonHit.vue";
  import {mapGetters, mapActions} from "vuex";
  import utils from "@/mixins/utils";

  export default {
    components: {
      GlueButtonHitNonHit,
    },
    props: {
      mode: String,
      name: String,
      pathArray: Array,
      glue: String,
      kind: String,
      type: String,
      selector: String,
      selection: Array,
      isRoot: Boolean,
    },
    mixins: [
      utils
    ],
    data: () => ({
      dialog: false,
      mySelectors: ['search'],
      myGlue: '',
      myKind: '',
      myType: '',
      mySelector: 'search',
      dataTableSearch: '',
      dataTableData: [],
      dataTableSelected: [],
    }),
    computed: {
      ...mapGetters('dynaLabels', [
        'dynaLabelsList',
        'dynaLabelsListLoadingStatus',
        'dynaLabelsListLoadingError',
        'dynaLabelsListLoadingErrorStatus',
        'dynaLabelsSelectors',
        'dynaLabelsSelectorsLoadingStatus',
        'dynaLabelsSelectorsLoadingError',
        'dynaLabelsSelectorsLoadingErrorStatus',
        'dynaLabelData',
        'dynaLabelDataLoadingStatus',
        'dynaLabelDataLoadingError',
        'dynaLabelDataLoadingErrorStatus',
      ]),
      ...mapGetters('resources', [
        'searchData'
      ]),
      canSave() {
        return this.dataTableSelected.length>0
      },
      editorHeader() {
        if(this.mode) {
          if(this.mode=='edit') {
            return "Edit Search Selection"
          } else {
            return "Add New Search Selection"
          }
        } else {
          return ''
        }
      },
      computeColor() {
        return this.getDynaLabelSelectorColor(this.mySelector)
      },
      dataTableHeaders() {
        return [{text: 'Searches', value: 'name', class: 'overline'}, {text: 'Search Type', value: 'search_class', class: 'overline', width:'20%', align: 'center'}]
      },
    },
    methods: {
      ...mapActions('dynaLabels', [
        'retrieveDynaLabelsSelectors',
        'addDynaLabelNode',
        'deleteDynaLabelNode',
        'appendDynaLabelNode',
        'replaceDynaLabelNode',
        'updateDynaLabelRootProp',
      ]),
      clearSearch() {
        this.dataTableSearch = "";
      },
      getItemColor(item) {
        if(item.disabled) return "White"
        else return this.getDynaLabelSelectorColor(item.value)
      },
      closeCancel() {
        this.dialog = false;
      },
      save() {

        let mode = this.mode
        let pathArray = this.pathArray.slice()
        let kind = 'search'
        let glue = this.$refs.searchGlue.toggleGroup
        let searchId = (this.dataTableSelected.length>0) ? this.dataTableSelected[0].value : 0

        // build the new node
        if(mode=='add') {

          // build the node object
          let obj = {
            "glue": glue,
            "kind": kind,
            "searchId": searchId
          }
          let path = pathArray.join('.') +'.parts'
          let params = {'pathToItem': path, 'newNode': obj}
          this.appendDynaLabelNode(params)

        }

        // remove/replace existing node
        else if(mode=='edit') {

          // build the node object
          let obj = {
            "glue": glue,
            "kind": kind,
            "searchId": searchId
          }
          // delete the current node
          let idx = pathArray.pop()
          let path = pathArray.join('.')
          let params = {'pathToItem': path, 'newNode': obj, 'idx': idx}
          this.replaceDynaLabelNode(params)

        }

        this.dialog = false;

      },
      updateDataTableData() {
        let all =
          this.searchData.map(
            obj => {
              return {'name': obj.name + ' (' + obj.id + ')', 'value': obj.id, 'search_class': obj.search_class}
            }
          ).sort((a, b) => (a.name > b.name) ? 1 : -1)

        let first = all.filter(o=>o.value==this.selection[0])
        let rest = all.filter(o=>o.value!=this.selection[0])
        this.dataTableData = first.concat(rest)
      },
      dialogStateChanged() {
        if(this.dialog) {
          this.updateDataTableData()
          this.dataTableSelected = [this.dataTableData.find(o=>{return o.value==this.selection[0]})]
        }
      },
    },
    mounted() {
      this.updateDataTableData()
    },
    watch: {
      searchData: 'updateDataTableData',
      dialog: 'dialogStateChanged',
    }
  }

</script>

<style scoped>

</style>
