import {store} from "@/store/index.js";
import moment from "moment";

const TWENTY_FOUR_HOURS = 24;

export default {

  setItem(item, value) {
    if (store.getters["users/userDataLoadingStatus"] == 'loaded') {
      localStorage.setItem(store.getters["users/userData"].id + "_" + item, value);
    }
  },
  getItem(item) {
    return (store.getters["users/userDataLoadingStatus"] == 'loaded') ? localStorage.getItem(store.getters["users/userData"].id + "_" + item) : null;
  },
  removeItem(item) {
    return (store.getters["users/userDataLoadingStatus"] == 'loaded') ? localStorage.removeItem(store.getters["users/userData"].id + "_" + item) : null;
  },
  hasOwnProperty(item) {
    return (store.getters["users/userDataLoadingStatus"] == 'loaded') ? localStorage.hasOwnProperty(store.getters["users/userData"].id + "_" + item) : null;
  },
  setCacheKeyFromStore(currentId, cacheKey) {
    let key = null;
    //need to find the key based on the id of the item since keys now have a timestamp on them...
    for (const property in localStorage) {
      // need to check the user id of the object in localstorage
      let userId = store.getters["users/userData"].id
      if (property.slice(0, property.lastIndexOf("_") + 1) == userId + '_' + cacheKey) {
        let baseProp = property.substring(property.indexOf("_") + 1)
        let storedId = baseProp.substring(baseProp.lastIndexOf("_") + 1, baseProp.lastIndexOf("."))
        if (storedId == currentId) {
          key = baseProp
        }
      }
    }
    return key;
  },
  isExpired(key) {
    if (store.getters["users/userDataLoadingStatus"] == 'loaded') {
      //current timestamp - millisecond equivalent of 24 hours ago
      let timestamp24HoursAgo = moment().subtract(TWENTY_FOUR_HOURS, 'hours').unix();
      let keyLastUpdated = Number(key.substring(key.lastIndexOf(".") + 1));

      //compare the timestamps to see if item needs to be removed
      if (timestamp24HoursAgo > keyLastUpdated) {
        //clear draft after 24 hours of no update
        this.removeItem(key);
        return true;
      }
    }
    return false
  },
  removeKeyIfExists(key) {
    if (this.hasOwnProperty(key)) this.removeItem(key)
  }
};
