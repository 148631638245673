<template>
  <div ref="infoBox" :class="`scorecard-tile-outer-container elevation-1 `">
    <v-row>
      <v-col cols="10" class="pt-0 scorecard-title" :title="scorecard.title + ' (' + scorecard.id + ')'">
        {{ scorecard.title }}
      </v-col>
      <v-col cols="2" class="pt-0 pb-1 d-flex justify-end">
        <div>
          <v-btn v-if="scorecard.info.dynalabelCount > 1" text small color="primary" :to="{name: 'scorecards-v2-dynalabels', params: { scorecardId: scorecard.id },query: $route.query}">Select Scorecard</v-btn>
          <v-btn v-else text small color="primary" :to="{name: 'scorecards-v2-agents',params: { scorecardId: scorecard.id, dynalabelId: 0 },query: $route.query}">Select Scorecard</v-btn>
        </div>
        <v-btn icon x-small color="primary">
          <v-icon @click="toggleExpansion">mdi-arrow-expand</v-icon>
        </v-btn>
        <v-tooltip max-width="30rem" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isProcessing" color="primary" icon x-small v-bind="attrs" v-on="on">
              <v-icon class="processing" v-text="'mdi-autorenew'" small></v-icon>
            </v-btn>
            <v-btn v-else color="primary" icon x-small v-bind="attrs" v-on="on">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>
            <span v-if="isProcessing">{{scorecardProcessingMessage}}</span><br v-if="isProcessing"/>
            <span v-if="checkMinTalktime">{{ formatHMS(scorecard.info.minimumTalkTime) }} </span><span v-else>No </span>minimum talk time<br />
            <span v-if="checkMaxTalktime">{{ formatHMS(scorecard.info.maximumTalkTime) }} </span><span v-else>No </span>maximum talk time<br />
            <span v-if="checkMinCallScore">{{ scorecard.info.minimumCallScore }}% </span><span v-else>No </span>minimum call score<br />
            <span v-if="checkMaxCallScore">{{ scorecard.info.maximumCallScore }}% </span><span v-else>No </span>maximum call score<br />
            Shared with: {{ sharedAccessRoles }}<br />
            Last published: {{ scorecard.info.updated_at }}<br />
            Scorecard ID: {{ scorecard.id }} <br />
            Created by: {{ scorecard.owner }}
            <span v-if="properDescription"><br />Description: {{ scorecard.description }}</span>
          </span>
        </v-tooltip>
        <v-btn :color="pinColor" icon x-small>
          <v-icon @click="pinFavorite">mdi-pin</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 mr-4">
        <v-container v-if="isLoading" class="fill-height">
          <v-row>
            <v-col class="d-flex justify-center py-8">
              <v-progress-circular style="opacity: .25;" class="my-8 mr-2" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
        <scorecards-chart-details
          v-else
          :chartHeight="Math.round(expandedContainerHeight * 0.65)"
          :chartWidth="Math.round(expandedContainerWidth * 0.65)"
          :scorecard="scorecard"
        ></scorecards-chart-details>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12">
        <div class="scorecard-tile-inner-container">

          <div class="scorecard-score-tile scorecard-data-tile">
            <div class="scorecard-score-block">
              <span v-if="isLoading">
                  <v-progress-circular style="opacity: .25;" class="my-8 mr-2" color="white" indeterminate></v-progress-circular>
              </span>
              <score-radial v-else :score="scorecard.info.scorecardScore" :radius="50"></score-radial>
            </div>
            <v-icon dark :class="vScoreIconClass">mdi-bullseye-arrow</v-icon>
            <span :class="tileTextClass">Average Score</span>
          </div>

          <div class="scorecard-totalcalls-tile scorecard-data-tile">
            <v-icon dark :class="vIconClass">mdi-finance</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
                <v-progress-circular style="opacity: .25;" class="my-8 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else :class="tileValueClass">{{thousands(scorecard.info.totalCalls)}}</span>
            <span :class="tileTextClass">Total Calls</span>
          </div>

          <div class="scorecard-callsperday-tile scorecard-data-tile">
            <v-icon dark :class="vIconClass">mdi-calendar-multiselect</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
                <v-progress-circular style="opacity: .25;" class="my-8 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else :class="tileValueClass">{{thousands(rounded(scorecard.info.averageCallsOverTime, 0))}}</span>
            <span :class="tileTextClass">Calls/{{ capitalize(grouping) }}</span>
          </div>

          <div class="scorecard-talktime-tile scorecard-data-tile">
            <v-icon dark :class="vIconClass">mdi-headset</v-icon>
            <span v-if="isLoading" :class="tileValueClass">
                <v-progress-circular style="opacity: .25;" class="my-8 mr-2" color="white" indeterminate></v-progress-circular>
            </span>
            <span v-else :class="tileValueClass">{{formatHMS(scorecard.info.averageDuration)}}</span>
            <span :class="tileTextClass">{{timeDisplayWords}}</span>
          </div>

        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ScorecardsChartDetails from "./ScorecardsChartDetails.vue";
import ScoreRadial from "../Scorecards/ScoreRadial.vue";

export default {
  props: {
    processing: Number,
    scorecard: Object,
    favorite: Number,
    grid: Boolean,
    expanded: Number,
    grouping: String,
    loading: Boolean,
  },
  components: {
    ScorecardsChartDetails,
    ScoreRadial
  },
  data() {
    return {
      heightTimeout: null,
      widthTimeout: null,
      debouncedHeight: 0,
      debouncedWidth: 0,
      err: {},
      chartId: 0,
      e: null,
      scorecardProcessingMessage: process.env.VUE_APP_SCORECARD_PROCESSING_MESSAGE
    };
  },
  computed: {
    ...mapGetters("users", [
      "userData",
      "userDataLoadingStatus",
      "userDataLoadingError",
      "userDataLoadingErrorStatus"
    ]),
    vScoreIconClass() {
      return "v-score-icon-" + this.$vuetify.breakpoint.name;
    },
    sharedAccessRoles() {
      if(!this.scorecard.shares) return ""
      let roles = "";
      this.scorecard.shares.forEach((item, index) => {
        if (index < this.scorecard.shares.length - 1) {
          roles += item.title + ", ";
        } else {
          roles += item.title;
        }
      });
      return roles;
    },
    isProcessing() {
      return this.scorecard.processing > 0;
    },
    isLoading() {
      return this.loading;
    },
    timeDisplayWords() {
      // 0 = talk time, 1 = handle time
      return (this.scorecard.info.timeDisplay) ? 'Handle Time' : 'Talk Time'
    },
    properDescription() {
      return (
        this.scorecard.description &&
        this.scorecard.description.length > 0 &&
        this.scorecard.description.toLowerCase() != "description"
      );
    },
    checkMaxTalktime() {
      return this.scorecard.info.maximumTalkTime != null;
    },
    checkMinTalktime() {
      return this.scorecard.info.minimumTalkTime != null;
    },
    checkMaxCallScore() {
      return this.scorecard.info.maximumCallScore != null;
    },
    checkMinCallScore() {
      return this.scorecard.info.minimumCallScore != null;
    },
    charts() {
      if (this.sChCC > 0 && this.dChCC > 0) {
        return ["Overall", "Customer", "Agent"];
      } else if (this.sChCC > 0) {
        return ["Overall"];
      } else {
        return ["Overall", "Customer", "Agent"];
      }
    },
    sChCC() {
      return this.scorecard.info.single_channel_call_count;
    },
    dChCC() {
      return this.scorecard.info.dual_channel_call_count;
    },
    titleCols() {
      if (this.$vuetify.breakpoint.mdAndDown) return 8;
      else if (this.$vuetify.breakpoint.lgAndDown) return 9;
      else return 10;
    },
    iconCols() {
      if (this.$vuetify.breakpoint.mdAndDown) return 4;
      else if (this.$vuetify.breakpoint.lgAndDown) return 3;
      else return 2;
    },
    pinColor() {
      return this.favorite != this.scorecard.id ? "accent" : "warning";
    },
    myHeight: {
      get() {
        return this.debouncedHeight;
      },
      set(val) {
        if (this.heightTimeout) clearTimeout(this.heightTimeout);
        this.heightTimeout = setTimeout(() => {
          this.debouncedHeight = val;
        }, 25);
      }
    },
    myWidth: {
      get() {
        return this.debouncedWidth;
      },
      set(val) {
        if (this.widthTimeout) clearTimeout(this.widthTimeout);
        this.widthTimeout = setTimeout(() => {
          this.debouncedWidth = val;
        }, 25);
      }
    },
    expandedContainerHeight() {
      return Math.round(this.myHeight - 310);
    },
    expandedContainerWidth() {
      return Math.round(this.myWidth + 150);
    },
    tileTextClass() {
      return "tile-text tile-text-" + this.$vuetify.breakpoint.name;
    },
    tileValueClass() {
      return "tile-value tile-value-" + this.$vuetify.breakpoint.name;
    },
    tileValueSubClass() {
      return "tile-value";
    },
    vIconClass() {
      return "v-icon-" + this.$vuetify.breakpoint.name;
    }
  },
  methods: {
    ...mapActions("scorecardsV2", ["setFavoriteScorecardId"]),

    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    toggleExpansion() {
      this.$emit("toggle-expansion", { id: 0 });
    },
    pinFavorite() {
      if (this.scorecard.id == this.favorite) {
        this.setFavoriteScorecardId(0);
      } else {
        this.setFavoriteScorecardId(this.scorecard.id);
      }
    },
    getClass(position) {
      return position;
    },
    thousands(num) {
      return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    rounded(num, places) {
      return Number(num).toFixed(places);
    },
    formatHMS(seconds) {
      seconds = Math.round(seconds);
      return moment("1900-01-01 00:00:00")
        .add(seconds, "seconds")
        .format("HH:mm:ss")
        .replace(/^0(?:0:0?)?/, "");
    },
    resizeHandler() {
      this.myHeight = window.innerHeight;
      this.myWidth = this.$refs.infoBox.clientWidth * 0.95;
      // this.myWidth = window.innerWidth;
    }
  },
  updated() {
    // do stuff
  },
  mounted() {
    this.myHeight = window.innerHeight;
    this.myWidth = this.$refs.infoBox.clientWidth * 0.95;
    // this.myWidth = window.innerWidth;
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {
    // do stuff
  }
};
</script>

<style scoped>
div.scorecard-tile-outer-container {
  padding: 1.25rem 1rem 0.25rem 1rem;
  margin-right: 1rem;
}

div.scorecard-tile-outer-container div.actions {
  padding: 0.25rem;
  text-align: right;
}

div.scorecard-tile-outer-container div.scorecard-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
  color: rgba(1, 106, 175, 1);
  font-weight: bold;
}

div.scorecard-tile-inner-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "a b c d";
  grid-gap: 0.5rem;
}

div.expanded-insight-tile-inner-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}

div.scorecard-score-tile {
  grid-area: a;
}

div.scorecard-totalcalls-tile {
  grid-area: b;
}

div.scorecard-callsperday-tile {
  grid-area: c;
}

div.scorecard-talktime-tile {
  grid-area: d;
}

div.scorecard-data-tile {
  position: relative;
  overflow: hidden;
  display: grid;
}

div.scorecard-data-tile i.v-icon {
  opacity: 0.2;
  position: absolute;
  bottom: 0;
}

div.scorecard-data-tile span.tile-value {
  font-weight: bold;
  text-align: right;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(1, 106, 175, 0.85);
  border-radius: 0.25rem 0.25rem 0 0;
}

div.scorecard-data-tile span.tile-text {
  text-align: right;
  color: rgba(1, 106, 175, 1);
  background-color: rgba(1, 106, 175, 0.25);
  border-radius: 0 0 0.25rem 0.25rem;
}

div.scorecard-data-tile i.v-score-icon-xs {
  font-size: 4.5rem;
  left: -2rem;
  bottom: -0.4rem;
}
div.scorecard-data-tile i.v-score-icon-sm {
  font-size: 5rem;
  left: -2rem;
  bottom: -0.4rem;
}
div.scorecard-data-tile i.v-score-icon-md {
  font-size: 6rem;
  left: -2.6rem;
  bottom: -1rem;
}
div.scorecard-data-tile i.v-score-icon-lg {
  font-size: 7rem;
  left: -3rem;
  bottom: -1.2rem;
}
div.scorecard-data-tile i.v-score-icon-xl {
  font-size: 8rem;
  left: -2rem;
  bottom: 0rem;
}

div.scorecard-data-tile i.v-icon-xs {
  font-size: 4.5rem;
  left: -1rem;
  bottom: 2rem;
}
div.scorecard-data-tile i.v-icon-sm {
  font-size: 4.5rem;
  left: -0.75rem;
  bottom: 2rem;
}
div.scorecard-data-tile i.v-icon-md {
  font-size: 4.5rem;
  left: -0.75rem;
  bottom: 2rem;
}
div.scorecard-data-tile i.v-icon-lg {
  font-size: 4.5rem;
  left: -0.75rem;
  bottom: 2rem;
}
div.scorecard-data-tile i.v-icon-xl {
  font-size: 7.5rem;
  left: -1.5rem;
  bottom: 2rem;
}

div.scorecard-data-tile span.tile-text-xs {
  padding: 0.5rem;
  font-size: 1rem;
}
div.scorecard-data-tile span.tile-text-sm {
  padding: 0.5rem;
  font-size: 1rem;
}
div.scorecard-data-tile span.tile-text-md {
  padding: 0.5rem;
  font-size: 0.9rem;
}
div.scorecard-data-tile span.tile-text-lg {
  padding: 0.5rem;
  font-size: 1rem;
}
div.scorecard-data-tile span.tile-text-xl {
  padding: 1rem;
  font-size: 1.2rem;
}

div.scorecard-data-tile span.tile-value-xs {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-sm {
  padding: 0.5rem;
  font-size: 1.2rem;
}
div.scorecard-data-tile span.tile-value-md {
  padding: 1.25rem 0.75rem 3.6rem;
  font-size: 2.25rem;
}
div.scorecard-data-tile span.tile-value-lg {
  padding: 0.75rem 0.75rem 3.6rem;
  font-size: 2.6rem;
}
div.scorecard-data-tile span.tile-value-xl {
  padding: 0.5rem 0.75rem 3.6rem;
  font-size: 2.8rem;
}

div.highlight-bar {
  border-left: 4px solid rgb(34, 124, 213);
}

.scorecard-score-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 106, 175, 0.85);
  padding: 1rem;
}

@media print {
  div.scorecard-data-tile {
    -webkit-print-color-adjust: exact;
  }
}

.animate-stroke {
  animation: dash-animation 600ms linear forwards;
}

@keyframes dash-animation {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
