<!-- Component begins here -->
<template>
  <v-expansion-panels multiple v-model="panelShown">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h3>
          Call Scorecard Control Icons Legend
          <template v-if="panelShown.length == 0"
            ><v-icon color="blue darken-1">mdi-checkbox-intermediate</v-icon>
            <v-icon color="blue-grey lighten-3"
              >mdi-checkbox-blank-off-outline</v-icon
            >
            <v-icon color="green lighten-1">mdi-checkbox-marked-outline</v-icon>
            <v-icon color="red lighten-1">mdi-close-box-outline</v-icon>
            &hellip;</template
          >
        </h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container fill-height fluid>
          <v-row no-gutters>
            <v-col cols="2">
              <v-card class="pa-2" outlined tile>
                <v-col>&nbsp;</v-col>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="pa-2" outlined tile>
                <v-col>
                  Category Controls
                </v-col>
                <!-- <p>Category Controls</p> -->
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="pa-2" outlined tile>
                <v-col>
                  Subcategory Controls
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2">
              <v-card class="pa-2 text-center" outlined tile height="100%">
                <v-col>
                  Automatic
                </v-col>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="pa-2" outlined tile>
                <v-icon color="blue darken-1">mdi-checkbox-intermediate</v-icon>
                Applicable
                <br />
                <v-icon color="blue-grey lighten-3"
                  >mdi-checkbox-blank-off-outline</v-icon
                >
                Not Applicable
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="pa-2" outlined tile>
                <v-icon color="green lighten-1"
                  >mdi-checkbox-marked-outline</v-icon
                >
                <v-icon color="blue-grey lighten-3"
                  >mdi-checkbox-marked-outline</v-icon
                >
                Checked (applicable/not applicable category)
                <br />
                <v-icon color="red lighten-1">mdi-close-box-outline</v-icon>
                <v-icon color="blue-grey lighten-3"
                  >mdi-close-box-outline</v-icon
                >
                Unchecked (applicable/not applicable category)
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2">
              <v-card class="pa-2 text-center" outlined tile height="100%">
                <v-col>
                  Adjusted
                </v-col>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card class="pa-2" outlined tile>
                <v-icon color="blue darken-1">mdi-circle-slice-8</v-icon>
                Applicable
                <br />
                <v-icon color="blue-grey lighten-3"
                  >mdi-circle-off-outline</v-icon
                >
                Not Applicable
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="pa-2" outlined tile>
                <v-icon color="green lighten-1"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-icon color="blue-grey lighten-3"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Checked (applicable/not applicable category)
                <br />
                <v-icon color="red lighten-1">mdi-close-circle-outline</v-icon>
                <v-icon color="blue-grey lighten-3"
                  >mdi-close-circle-outline</v-icon
                >
                Unchecked (applicable/not applicable category)
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data: () => ({
    panelShown: []
  })
};
</script>
<style>
.pending-override {
  padding: 0.25rem;
  border: 1px rgb(255, 191, 0) solid;
  border-radius: 0.5rem;
}
</style>
